export const SET_PAYMENT_DONE_STATUS = 'SET_PAYMENT_DONE_STATUS';
export const DELETE_PACKAGE_ERROR_STATUS = 'DELETE_PACKAGE_ERROR_STATUS';
export const DELETE_PAYMENT_DONE_STATUS = 'DELETE_PAYMENT_DONE_STATUS';
export const GET_PRICE_PACKAGE = 'GET_PRICE_PACKAGE';
export const GET_PRICE_PACKAGES = 'GET_PRICE_PACKAGES';
export const GET_PRICE_PACKAGES_STATUS = 'GET_PRICE_PACKAGES_STATUS';
export const GET_PRICE_PACKAGE_ID = 'GET_PRICE_PACKAGE_ID';
export const UPDATE_PRICE_PACKAGE_STATUS = 'UPDATE_PRICE_PACKAGE_STATUS';
export const PROFILE_SERVICE_STATUS = 'PROFILE_SERVICE_STATUS';
export const ADD_COURSE = 'ADD_COURSE';
export const ADD_COURSE_ERROR = 'ADD_COURSE_ERROR';
export const IS_ADD_COURSE_SUCCESS = "IS_ADD_COURSE_SUCCESS"
export const EDIT_COURSE = 'EDIT_COURSE';
export const ADD_ACTIVITY = 'ADD_ACTIVITY';
export const ADD_ACTIVITY_ERROR = 'ADD_ACTIVITY_ERROR';
export const EDIT_ACTIVITY = 'EDIT_ACTIVITY';
export const DELETE_COURSE = 'DELETE_COURSE';
export const DELETE_ACTIVITY = 'DELETE_ACTIVITY';
export const ADD_PACKAGE = "ADD_PACKAGE";
export const ADD_PACKAGE_ERROR = "ADD_PACKAGE_ERROR";
export const EDIT_PACKAGE = "EDIT_PACKAGE";
export const ADD_OFFICE_HOURS = "ADD_OFFICE_HOURS";
export const GET_SUBSCRIBED_ADVISORS = "GET_SUBSCRIBED_ADVISORS"
export const SUBSCRIBED_ADVISORS = "SUBSCRIBED_ADVISORS"
export const SUBSCRIBED_ADVISORS_ERROR = "SUBSCRIBED_ADVISORS_ERROR"
export const EVENT_CONFIRMATION_STATUS = "EVENT_CONFIRMATION_STATUS"
export const LOAD_SUBSCRIBED_STUDENTS = "LOAD_SUBSCRIBED_STUDENTS"
export const LOAD_SUBSCRIBED_STUDENTS_STATUS = "LOAD_SUBSCRIBED_STUDENTS_STATUS"
export const LOAD_CURRENT_SUBSCRIBERS = "LOAD_CURRENT_SUBSCRIBERS"
export const LOAD_CURRENT_SUBSCRIBERS_SUCCESS = "LOAD_CURRENT_SUBSCRIBERS_SUCCESS"
export const LOAD_PAST_SUBSCRIBERS = "LOAD_PAST_SUBSCRIBERS"
export const LOAD_PAST_SUBSCRIBERS_SUCCESS = "LOAD_PAST_SUBSCRIBERS_SUCCESS"
export const CREATE_STUDENT_PLAN = "CREATE_STUDENT_PLAN"
export const NEWLY_CREATED_PLAN = "NEWLY_CREATED_PLAN"
export const OFFER_EXTRA_SESSION = "OFFER_EXTRA_SESSION"
export const SUGGEST_TO_DROP_PLAN = "SUGGEST_TO_DROP_PLAN"
export const UNDO_PLAN_SUGGESTION_STATUS = "UNDO_PLAN_SUGGESTION_STATUS"
export const UNDO_PLAN_DROP_SUGGESTION_STATUS = "UNDO_PLAN_DROP_SUGGESTION_STATUS"
export const UNDO_COURSE_SUGGESTION_STATUS = "UNDO_COURSE_SUGGESTION_STATUS"
export const UNDO_COURSE_SUGGESTION_ERROR = "UNDO_COURSE_SUGGESTION_ERROR"
export const UNDO_COURSE_DROP_SUGGESTION_STATUS = "UNDO_COURSE_DROP_SUGGESTION_STATUS"
export const SUGGEST_TO_DROP_COURSE = "SUGGEST_TO_DROP_COURSE"
export const SUGGEST_TO_DROP_ACTIVITY = "SUGGEST_TO_DROP_ACTIVITY"
export const UNDO_ACTIVITY_SUGGESTION_STATUS = "UNDO_ACTIVITY_SUGGESTION_STATUS"
export const UNDO_ACTIVITY_SUGGESTION_ERROR = "UNDO_ACTIVITY_SUGGESTION_ERROR"
export const UNDO_ACTIVITY_DROP_SUGGESTION_STATUS = "UNDO_ACTIVITY_DROP_SUGGESTION_STATUS"
export const EXPLORE_STUDENT_STATUS = "EXPLORE_STUDENT_STATUS"
export const EXPLORE_STUDENT_DATA = "EXPLORE_STUDENT_DATA"
export const GET_GOALS_ADVISOR_COUNT = "GET_GOALS_ADVISOR_COUNT"
export const GET_EARNINGS_COUNT = "GET_EARNINGS_COUNT"
export const GET_ADVISOR_DASHBOARD_COUNT = "GET_ADVISOR_DASHBOARD_COUNT"
export const MILESTONE_COUNT_DATA = "MILESTONE_COUNT_DATA"
export const MILESTONE_COUNT_DATA_STATUS = "MILESTONE_COUNT_DATA_STATUS"
export const GET_ADVISOR_STATE = 'GET_ADVISOR_STATE'