import React, { Component } from "react";
import Tab from "react-bootstrap/Tab";
import { Col, Row, Form ,Button} from "react-bootstrap";
import Nav from "react-bootstrap/Nav"; 
import Documents from "./Documents";
import Archived from "./Archived";
import drive from "../../../assets/images/icons/drive.png";
import docs from "../../../assets/images/icons/docs.png";
import Avatar1 from "../../../assets/images/icons/Avatar1.png"
import Avatar2 from "../../../assets/images/icons/Avatar2.png"
import "../../../assets/css/network.less";


class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: "Documents",
    };
  }

  setKey = (k) => {
    this.setState({
      key: k,
    });
  };

  render() {
    const LibraryData = [
      {
        'AvatarIcon': Avatar1,
        image: drive,
        DocumentName: "Cambridge.link",
        DocumentExtension: "link",
        DocumentDate: "1 October, 2021",
        DocumentTime: "12:38",
        LastUpdated: "13 hours ago",
        Description:
          "This is the document you should use for the 1 milestone... ",
      },

      {
        'AvatarIcon': Avatar2,
        image: docs,
        DocumentName: "MIT.doc",
        DocumentExtension: "link",
        DocumentDate: "1 October, 2021",
        DocumentTime: "12:38",
        LastUpdated: "13 hours ago",
        Description:
          "This is the document you should use for the 1 milestone... ",
      },
      {
        'AvatarIcon': Avatar1,
        image: drive,
        DocumentName: "Cambridge.link",
        DocumentExtension: "link",
        DocumentDate: "1 October, 2021",
        DocumentTime: "12:38",
        LastUpdated: "13 hours ago",
        Description:
          "This is the document you should use for the 1 milestone... ",
      },
      {
        'AvatarIcon': Avatar2,
        image: docs,
        DocumentName: "MIT.doc",
        DocumentExtension: "link",
        DocumentDate: "1 October, 2021",
        DocumentTime: "12:38",
        LastUpdated: "13 hours ago",
        Description:
          "This is the document you should use for the 1 milestone... ",
      },
      {
        'AvatarIcon': Avatar1,
        image: drive,
        DocumentName: "Cambridge.link",
        DocumentExtension: "link",
        DocumentDate: "1 October, 2021",
        DocumentTime: "12:38",
        LastUpdated: "13 hours ago",
        Description:
          "This is the document you should use for the 1 milestone... ",
      },
      {
        'AvatarIcon': Avatar2,
        image: docs,
        DocumentName: "MIT.doc",
        DocumentExtension: "link",
        DocumentDate: "1 October, 2021",
        DocumentTime: "12:38",
        LastUpdated: "13 hours ago",
        Description:
          "This is the document you should use for the 1 milestone... ",
      },
      {
        'AvatarIcon': Avatar1,
        image: drive,
        DocumentName: "Cambridge.link",
        DocumentExtension: "link",
        DocumentDate: "1 October, 2021",
        DocumentTime: "12:38",
        LastUpdated: "13 hours ago",
        Description:
          "This is the document you should use for the 1 milestone... ",
      },
      {
        'AvatarIcon': Avatar2,
        image: docs,
        DocumentName: "MIT.doc",
        DocumentExtension: "link",
        DocumentDate: "1 October, 2021",
        DocumentTime: "12:38",
        LastUpdated: "13 hours ago",
        Description:
          "This is the document you should use for the 1 milestone... ",
      },
    ];
const ArchivedData =[
  {
    'AvatarIcon': Avatar1,
    image: drive,
    DocumentName: "Cambridge.zip",
    DocumentExtension: "link",
    DocumentDate: "1 October, 2021",
    DocumentTime: "12:38",
    LastUpdated: "13 hours ago",
    Description:
      "This is the document you should use for the 1 milestone... ",
  },

  {
    'AvatarIcon': Avatar2,
    image: docs,
    DocumentName: "MIT.zip",
    DocumentExtension: "link",
    DocumentDate: "1 October, 2021",
    DocumentTime: "12:38",
    LastUpdated: "13 hours ago",
    Description:
      "This is the document you should use for the 1 milestone... ",
  },
  {
    'AvatarIcon': Avatar1,
    image: drive,
    DocumentName: "Cambridge.zip",
    DocumentExtension: "link",
    DocumentDate: "1 October, 2021",
    DocumentTime: "12:38",
    LastUpdated: "13 hours ago",
    Description:
      "This is the document you should use for the 1 milestone... ",
  },
  {
    'AvatarIcon': Avatar2,
    image: docs,
    DocumentName: "MIT.zip",
    DocumentExtension: "link",
    DocumentDate: "1 October, 2021",
    DocumentTime: "12:38",
    LastUpdated: "13 hours ago",
    Description:
      "This is the document you should use for the 1 milestone... ",
  },
  {
    'AvatarIcon': Avatar1,
    image: drive,
    DocumentName: "Cambridge.zip",
    DocumentExtension: "link",
    DocumentDate: "1 October, 2021",
    DocumentTime: "12:38",
    LastUpdated: "13 hours ago",
    Description:
      "This is the document you should use for the 1 milestone... ",
  },
  {
    'AvatarIcon': Avatar2,
    image: docs,
    DocumentName: "MIT.zip",
    DocumentExtension: "link",
    DocumentDate: "1 October, 2021",
    DocumentTime: "12:38",
    LastUpdated: "13 hours ago",
    Description:
      "This is the document you should use for the 1 milestone... ",
  },
  {
    'AvatarIcon': Avatar1,
    image: drive,
    DocumentName: "Cambridge.zip",
    DocumentExtension: "link",
    DocumentDate: "1 October, 2021",
    DocumentTime: "12:38",
    LastUpdated: "13 hours ago",
    Description:
      "This is the document you should use for the 1 milestone... ",
  },
  {
    'AvatarIcon': Avatar2,
    image: docs,
    DocumentName: "MIT.zip",
    DocumentExtension: "link",
    DocumentDate: "1 October, 2021",
    DocumentTime: "12:38",
    LastUpdated: "13 hours ago",
    Description:
      "This is the document you should use for the 1 milestone... ",
  },
];

  return (
      <>

        <div className="librarytable">
          <div className="display-view">
            <div className="row">
              <div className="col-sm-8 col-6">
                <h3 className="library-top-text mb-3 " >My Library</h3>
              </div>

              <div className="col-sm-4 col-6 text-rights">
                <Button className="btn cta--rounded cta-primary" >
                  <span>Add Document</span>
                </Button>
              </div>
            </div>
          </div>
          <div className="none-view">
            <div className="tab-heading">
                <div className="row">
                    <div className="topHeader-text">
                        <h3>My Library</h3>
                        {/* <p class="p-0 m-0"><span>It’s good to see you again.</span></p> */}
                    </div>
                </div>
            </div>
          </div>


          {/* <div className="tab-heading">
           
            </div> */}
          <div className="main-wrap">
            <div className="tab-container">
              <div className="row none-view">
                <div className="col-sm-12 col-md-12 col-12 mt-3 text-rights">
                  <Button className="btn cta--rounded cta-primary" >
                      <span>Add Document</span>
                  </Button>
                </div>
              </div>

              <Tab.Container
                id="left-tabs-example"
                activeKey={this.state.key}
                onSelect={(k) => this.setKey(k)}
              >
                <Row>
                  <Col sm={12}>
                    <Nav variant="pills">
                      <Nav.Item className="cursorshow">
                        <Nav.Link eventKey="Documents">All Documents</Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="cursorshow">
                        <Nav.Link eventKey="Archived">Archived</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={12}>
                    <Tab.Content>
                      <Tab.Pane eventKey="Documents">
                        <Documents LibraryData={LibraryData}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="Archived">
                      <Archived ArchivedData={ArchivedData}
                        />
                        </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
             
            </div>
          </div>

        </div>
      </>
    );
  }
}
export default Index;
