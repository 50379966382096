import React, { useEffect, useState } from 'react';
import "./myWork.less";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { Box, Grid, Tab, Tabs, Typography, } from '@mui/material';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import Select, { components } from "react-select";
import Plans from "./Plans";
import CurrentCourses from "./CurrentCourses";
import CurrentActivities from "./CurrentActivities";
import CurrentMilestones from "./CurrentMilestones";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CreatePlanModal from "../../../componentsNew/Common/MyworkModal/Plans";
import Phasepopup from "../../../componentsNew/Common/MyworkModal/Phase";
import { createPlanForStudent, createPlanForStudentStatus, resetNewlyCreatedPlanId, GetStudentPlan } from "../../../redux/actions";
import { connect } from 'react-redux';


const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder}</Placeholder>
            {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
        </ValueContainer>
    );
};
const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <Typography component="div" role="tabpanel" hidden={value !== index} id={`scrollable-auto-tabpanel-${index}`} aria-labelledby={`scrollable-auto-tab-${index}`} {...other} >
            {value === index && (
				<Box className="myWorkContentSec" p={3}>{children}</Box>
			)}
        </Typography>
    );
}
TabPanel.propTypes = { children: PropTypes.node, index: PropTypes.any.isRequired, value: PropTypes.any.isRequired };
const a11yProps = (index) => {
    return { id: `scrollable-auto-tab-${index}`, "aria-controls": `scrollable-auto-tabpanel-${index}` };
}
const menulist = [
    { value: 0, label: "Plans" }, 
    { value: 1, label: "Current Courses" }, 
    { value: 2, label: "Current Activities" }, 
    { value: 3, label: "Current Milestones" }
]

const Index = (props) => {
    const [tabs, setTabs] = useState(0);
    const [showCreatePlanModal, setShowCreatePlanModal] = useState(false);
    const [showPhase, setShowPhase] = useState(false);
    const [selectedOption, setSelectedOption] = useState(menulist[0]);
	const { id } = useParams();
	const { state } = useLocation();
	const navigate = useNavigate();
	
	useEffect(() => {
		let payload = {
            searchString: "",
            pageNo: 1,
            limit: 10,
            status: [],
            sortOrder: "latest",
            studentId: state?.id
        }
		if(props?.createStudentPlanStatus){
			props?.GetStudentPlan(payload);
			props.createPlanForStudentStatus(false);
			setShowCreatePlanModal(false);
			handleOpenAddSemester(true);
		}
	}, [props.createStudentPlanStatus])
	
	const findOptionByValue = (value) => {
        return menulist.find((option) => option.value === value);
    }
	const handleSwitchTabs = (event, newValue) => {
        setTabs(newValue);
        setSelectedOption(findOptionByValue(newValue));
    }
	const handleSelectTabs = (e) => {
        setTabs(e.value);
        setSelectedOption(e);
    }
	const handleOpenCreatePlan = (status) => {
		setShowCreatePlanModal(status);
	}
	const handleOpenAddSemester = (status) => {
		if (!status) {
			props?.resetNewlyCreatedPlanId();
		}
		setShowPhase(status)
	}
	const handleCreatePlan = (data) =>{
		let payload = {
			improveGpa: data?.improveGpa,
			planName: data?.planName,
			plannedProfessions: data?.plannedProfessions || [],
			plannedUniversities: data?.plannedUniversities || [],
			specificProfession: data?.specificProfession,
			specificUniversity: data?.specificUniversity,
			studentId: id,
			unweighted: data?.unweighted,
			weighted: data?.weighted
		}
		console.log(payload, "payload");
		props.createPlanForStudent(payload)

    }
	return (
		<>
            {showCreatePlanModal && <CreatePlanModal show={showCreatePlanModal} onHide={() => handleOpenCreatePlan(false)} PlanApi={handleCreatePlan} />}
            {showPhase && <Phasepopup show={showPhase} onHide={() => handleOpenAddSemester(false)} /> }
			<Grid container spacing={5} className="yourStudentSection">
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='yourStudentSection__topSection'>
					<div className="yourStudentSection__topSection__titleDesktop">
						<ArrowBackOutlinedIcon className='yourStudentSection__topSection__titleDesktop__goBackIcon' onClick={() => navigate(-1)}/>
						<p className="yourStudentSection__topSection__titleDesktop__title">{state?.studentName}</p>
					</div>
					<div className="yourStudentSection__topSection__titleMobile">
						<p className="yourStudentSection__topSection__titleMobile__title">Your Students</p>
					</div>
					<div className="yourStudentSection__topSection__subTitleMobile">
						<p className="yourStudentSection__topSection__subTitleMobile__title">
							<span style={{ "marginRight": "20px", "cursor": "pointer" }} onClick={() => navigate(-1)}>
								<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fillRule="evenodd" clipRule="evenodd" d="M20.8333 12.5002C20.8333 11.9249 20.3669 11.4585 19.7916 11.4585H5.20825C4.63295 11.4585 4.16658 11.9249 4.16658 12.5002C4.16658 13.0755 4.63295 13.5418 5.20825 13.5418H19.7916C20.3669 13.5418 20.8333 13.0755 20.8333 12.5002Z" fill="#1B1C1E" />
									<path fillRule="evenodd" clipRule="evenodd" d="M13.2367 4.4716C12.8299 4.0648 12.1703 4.0648 11.7635 4.4716L4.47184 11.7633C4.06505 12.1701 4.06505 12.8296 4.47184 13.2364L11.7635 20.5281C12.1703 20.9349 12.8299 20.9349 13.2367 20.5281C13.6434 20.1213 13.6434 19.4617 13.2367 19.0549L6.68155 12.4998L13.2367 5.94474C13.6434 5.53794 13.6434 4.8784 13.2367 4.4716Z" fill="#1B1C1E" />
								</svg>
							</span>
							{state?.studentName}
						</p>
					</div>
					<Button className="yourStudentSection__topSection__ctaButton" onClick={() => handleOpenCreatePlan(true)}>
						Suggest new plan
					</Button>
				</Grid>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='yourStudentSection__midSection'>
					<div className="yourStudentSection__midSection__mobileTabView">
						<Select options={menulist} className='yourStudentSection__midSection__mobileTabView__selectMenu'
							value={selectedOption} isSearchable= {false} onChange={handleSelectTabs}
							getOptionLabel={e => (
								<div style={{ display: 'flex', alignItems: 'center' }}>
									{e.icon}
									<span style={{ marginLeft: 10 }}>{e.label}</span>
								</div>
							)}
							placeholder=" " components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
							styles={{ option: (provided, state) => ({ ...provided, cursor: 'pointer', }), container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }}
						/>
						<Button className="yourStudentSection__midSection__mobileTabView__primaryBtn" onClick={() => handleOpenCreatePlan(true)}>Suggest new plan</Button>
					</div>
					<div className="yourStudentSection__midSection__desktopTabView">
						<Tabs value={tabs} onChange={handleSwitchTabs}>
							<Tab disableRipple label="Plans" className="Tab" {...a11yProps(0)} />
							<Tab disableRipple label="Current Courses" className="Tab" {...a11yProps(1)} />
							<Tab disableRipple label="Current Activities" className="Tab" {...a11yProps(2)} />
							<Tab disableRipple label="Current Milestones" className="Tab" {...a11yProps(3)} />
						</Tabs>
					</div>
					<div className="yourStudentSection__midSection__tabPannelSec">
						<TabPanel value={tabs} index={0} className="yourStudentSection__midSection__tabPannelSec__pannel">
							<Plans />
						</TabPanel>
						<TabPanel value={tabs} index={1} className="yourStudentSection__midSection__tabPannelSec__pannel">
							<CurrentCourses />
						</TabPanel>
						<TabPanel value={tabs} index={2} className="yourStudentSection__midSection__tabPannelSec__pannel">
							<CurrentActivities />
						</TabPanel>
						<TabPanel value={tabs} index={3} className="yourStudentSection__midSection__tabPannelSec__pannel">
							<CurrentMilestones />
						</TabPanel>
					</div>
				</Grid>
			</Grid>
		</>
	)
}


const mapStateToProps = ({ advisorData }) => {
    const { createStudentPlanStatus } = advisorData
    return { createStudentPlanStatus }
};

export default connect(mapStateToProps, { createPlanForStudent, createPlanForStudentStatus, resetNewlyCreatedPlanId, GetStudentPlan })(Index);
