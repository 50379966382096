import React, { useEffect, useState } from 'react'
import "./semesterDetails.less";
import { connect } from 'react-redux';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Semester from "./Semester";
import { GetLoadPhase, GetLoadPhaseStatus } from "../../../../redux/actions";
import Loader from '../../../../components/Loader';
import CustomAlert from '../../../../components/CustomAlert';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <Box component="div" role="tabpanel" hidden={value !== index} id={`scrollable-auto-tabpanel-${index}`} aria-labelledby={`scrollable-auto-tab-${index}`} {...other} >
            {value === index && (
				<Box className='semContentSec' p={3}>{children}</Box>
			)}
        </Box>
    );
}
const a11yProps = (index) => {
    return { id: `scrollable-auto-tab-${index}`, "aria-controls": `scrollable-auto-tabpanel-${index}` };
}
TabPanel.propTypes = { children: PropTypes.node, index: PropTypes.any.isRequired, value: PropTypes.any.isRequired };
const Index = (props) => {
    const [tabs, setTabs] = useState(0);
    const [loading, setLoading] = useState(false);
    const { state } = useLocation();
	const navigate = useNavigate();
    const {planId} = useParams();

    useEffect(() => {
        setLoading(true);
        let payload = {
            planId: planId
        }
        props?.GetLoadPhase(payload)
    }, [])
    useEffect(() => {
        if(props?.getplanphasestatus && props?.getplanphase?.length > 0){
            setTabs(props?.getplanphase?.findIndex(phase => phase?.id == state?.activePhaseId) >= 0 ? props?.getplanphase?.findIndex(phase => phase?.id == state?.activePhaseId) : 0)
            props?.GetLoadPhaseStatus(false);
            setLoading(false);
        }
    }, [props?.getplanphasestatus])
    
    const handleSwitchTabs = (event, newValue) => {
        setTabs(newValue);
    }
    
    return (
        <Grid container spacing={5} className="semesterDetailSec">
            {loading && <Loader />}
            <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                {!Object.keys(props.errorList)?.length && <CustomAlert />}
            </div>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='semesterDetailSec__topSection'>
                <div className="semesterDetailSec__topSection__titleDesktop">
                    <ArrowBackOutlinedIcon className='semesterDetailSec__topSection__titleDesktop__goBackIcon' onClick={() => navigate(-1)}/>
                    <p className="semesterDetailSec__topSection__titleDesktop__title">{state?.planName || "plan Name"}</p>
                </div>
                <div className="semesterDetailSec__topSection__titleMobile">
                    <p className="semesterDetailSec__topSection__titleMobile__title">Your Students</p>
                </div>
                <div className="semesterDetailSec__topSection__subTitleMobile">
                    <p className="semesterDetailSec__topSection__subTitleMobile__title">
                        <span style={{ "marginRight": "20px", "cursor": "pointer" }} onClick={() => navigate(-1)}>
                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M20.8333 12.5002C20.8333 11.9249 20.3669 11.4585 19.7916 11.4585H5.20825C4.63295 11.4585 4.16658 11.9249 4.16658 12.5002C4.16658 13.0755 4.63295 13.5418 5.20825 13.5418H19.7916C20.3669 13.5418 20.8333 13.0755 20.8333 12.5002Z" fill="#1B1C1E" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M13.2367 4.4716C12.8299 4.0648 12.1703 4.0648 11.7635 4.4716L4.47184 11.7633C4.06505 12.1701 4.06505 12.8296 4.47184 13.2364L11.7635 20.5281C12.1703 20.9349 12.8299 20.9349 13.2367 20.5281C13.6434 20.1213 13.6434 19.4617 13.2367 19.0549L6.68155 12.4998L13.2367 5.94474C13.6434 5.53794 13.6434 4.8784 13.2367 4.4716Z" fill="#1B1C1E" />
                            </svg>
                        </span>
                        {state?.planName}
                    </p>
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='semesterDetailSec__midSection'>
                <div className="semesterDetailSec__midSection__desktopTabView">
                    <Tabs value={tabs} onChange={handleSwitchTabs} variant="scrollable"
                        scrollButtons allowScrollButtonsMobile
                        aria-label="scrollable force tabs example">
                        {props?.getplanphase?.map((sem, index) => {
                            return(    
                                <Tab wrapped key={sem.id} label={sem?.title} disableRipple className="semTab" {...a11yProps(0)}/>
                            )
                        })}
                    </Tabs>
                </div>
                <div className="semesterDetailSec__midSection__tabPannelSec">
                    <TabPanel className="yourStudentSection__midSection__tabPannelSec__pannel">
                        <Semester semData={props.getplanphase?.[tabs]}/>
                    </TabPanel>
                </div>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = ({ commonData, studentData }) => {
    const { message, errorList,courseNames,course } = commonData;
    const { getplanphase, getplanphasestatus } = studentData;

    return { message, errorList, getplanphase,courseNames,course, getplanphasestatus };
};
export default connect(mapStateToProps, { GetLoadPhase, GetLoadPhaseStatus })(Index);
