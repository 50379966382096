import React from "react";
import "./reviewSec.less";
import { Rating } from "@mui/material";

const index = (props) => {

    function formatDate(inputDate) {
        const options = { year: "numeric", month: "long", day: "numeric" };
        const date = new Date(inputDate);
        return date.toLocaleDateString(undefined, options);
    }


    return (
        <div className="review-sec">
            {props?.review.map((data, key) => {

                return (
                    <div className="user-Review">
                        <img
                            className="user-Review__avatar"
                            src={require("../../../../../assets/images/profileEdit.png")}
                            alt=""
                        />
                        <div className="user-Review__reviewDetails">
                            <div className="d-flex align-items-center mb-2">
                                <p className="user-Review__reviewDetails__userName">
                                    Daniel
                                </p>
                                <Rating max={1} defaultValue={1} readOnly className="ms-2" />
                                <p className="user-Review__reviewDetails__userName">
                                    {data?.rating}
                                </p>

                            </div>
                            <p className="user-Review__reviewDetails__date">
                                {formatDate(data?.reviewedAt)}
                            </p>
                            <p className="user-Review__reviewDetails__review-msg">
                                {data?.reviewDescription}
                            </p>
                        </div>
                    </div>

                );
            })}


            {/* <div className="review-sec__morebtn-sec">
                <button className="review-sec__morebtn-sec__moreBtn" >More reviews</button>
            </div> */}
        </div>
    );
};

export default index;
