import React, { Component } from "react";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip ,Legend,ResponsiveContainer} from 'recharts';

class Areachart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data : [
                {
                  name: "01",
                  sep: 40,
                  nov: 24,
                  height: 100,
                },
                {
                  name: "05",
                  sep: 130,
                  nov: 138,
                  height: 200,
                },
                {
                  name: "10",
                  sep: 20,
                  nov: 98,
                  height: 500,
                },
                {
                  name: "15",
                  sep: 80,
                  nov: 39,
                  height: 1000,
                },
                {
                  name: "20",
                  sep: 190,
                  nov: 280,
                  height: 1500,
                },
                {
                  name: "25",
                  sep: 290,
                  nov: 800,
                  height: 2000,
                }
              ]
        };
    }
    render() {
        let {data} = this.state
        const CustomTooltip = ({ active, payload, label }) => {
          if (active && payload && payload.length) {
            return (
              <div className="custom-tooltip">
                <p className="label"></p>
                <p className="label"><span><div className="colorbar"></div>{`${"$"} ${payload[0].value}`}</span></p>
              </div>
            );
          }
        
          return null;
        };
        return (
            <>
            <ResponsiveContainer width={"100%"} height={200}>
                <AreaChart data={data} >
                    <CartesianGrid strokeDasharray="20 20" />
                    <XAxis dataKey="name" />
                    <YAxis  dataKey="height"  ></YAxis>
                    <Tooltip cursor={false} content={<CustomTooltip />} />
                    <Area type="monotone" dataKey="sep" stackId="1" stroke="#007EFF" fill="#1C84EE" />
                    <Area type="monotone" dataKey="nov" stackId="1" stroke="#FF4D00" fill="#FA5F1C" />
                </AreaChart>
                </ResponsiveContainer>
            </>
        );
    }
}

export default Areachart;