import React, { useState, useCallback, useEffect } from "react";
import { Button, Modal, Box, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { userConnect, userConnectStatus } from "../../redux/actions";
import CloseIcon from '@mui/icons-material/Close';
import { connect } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import Select, { components } from "react-select";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PersonIcon from '@mui/icons-material/Person';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 600,
    minWidth: 270,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 10,
};

const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder}</Placeholder>
            {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
        </ValueContainer>
    );
};


const validation = Yup.object({
    childData: Yup.object().required("* Required").nullable(),
});


const Index = (props) => {
    const [connect, setConnect] = useState(false);
    const [selectedStudentId, setSelectedStudentId] = useState(null);

    const { register, handleSubmit, watch, reset, setValue, clearErrors, control, formState: { errors } } = useForm({ mode: "onSubmit", resolver: yupResolver(validation), });


    const handleConnectOpen = () => setConnect(true);
    const handleConnectClose = () => setConnect(false);


    const handleClose = () => {
        props?.handleClose()
        reset()
    }

    const onSubmit = (data) => {
        handleConnectOpen()
        props?.handleConnectValues(data)
        handleClose()
    };

    useEffect(() => {
        if (props.userConnectSuccess) {
            reset()
            props?.userConnectStatus(false)
        }
    }, [props.userConnectSuccess])

    useEffect(() => {
        reset()
    }, [props])


    return (
        <>

        
            <Modal
                open={props?.show}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <form id='my-course' onSubmit={handleSubmit(onSubmit)}>
                        <div className="p-4 ">
                           
                            <Grid container spacing={2}>
                                <Grid item xs={11}>
                                    <p className="profileAboutCard__title mb-1">Select Child to Connect Advisor</p>

                                </Grid>
                                <Grid item xs={1}>
                                    <div style={{ textAlign: 'right' }}>
                                        <div onClick={handleClose}><CloseIcon sx={{ fill: 'black', cursor: 'pointer' }} /></div>
                                    </div>
                                </Grid>

                            </Grid>
                            <div className="row mt-5">
                                <div className="col-md-12 col-sm-12 col-12 p-0">
                                    <Controller
                                        control={control}
                                        name="childData"
                                        render={({ field }) => (
                                            <div className="input-floating-label">
                                                <Select placeholder={"Select Child"} {...field} getOptionLabel={option => option.fullName} getOptionValue={option => option.id} closeMenuOnSelect={true} isClearable={false}
                                                    isSearchable={false} classNamePrefix="mySelect" options={props?.childprofile}
                                                    onChange={(selectedOption) => {
                                                        setSelectedStudentId(selectedOption?.id || null);
                                                        field.onChange(selectedOption);
                                                    }} components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                                    styles={{ option: (provided, state) => ({ ...provided, cursor: 'pointer', }), container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }} />
                                                <div className="error-text" style={{ "position": "absolute" }} >{errors.childData?.message}</div>
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="resConnect d-flex" style={{ justifyContent: 'end' }}>
                                <div className='netWork-card__button-sec profileConnect sendConnect mt-0' >
                                    <button type="submit" className='netWork-card__button-sec__primary-btn'tyle={{ width: '7rem' }}>Confirm</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Box>
            </Modal>
        </>
    );
};

const mapStateToProps = ({ commonData, userProfileData }) => {
    const { userConnectSuccess } = commonData
    const { childprofile } = userProfileData
    return { userConnectSuccess, childprofile }
};

export default connect(mapStateToProps, { userConnect, userConnectStatus })(Index);