import React from "react";

const Index = (props) => {
    let { children } = props;

    return (
        <div className="buyPackageCard">
            <div className="buyPackageCard__packageTitle">{props.name}</div>
            <div className="buyPackageCard__detail-sec">
                <div className="buyPackageCard__detail-sec__packageDetail">
                    <div className="buyPackageCard__detail-sec__packageDetail__title">
                        Service
                    </div>
                    <div className="buyPackageCard__detail-sec__packageDetail__value">
                        One time service
                    </div>
                </div>
                <div className="buyPackageCard__detail-sec__packageDetail">
                    <div className="buyPackageCard__detail-sec__packageDetail__title">
                        price
                    </div>
                    <div className="buyPackageCard__detail-sec__packageDetail__value">
                        15$
                    </div>
                </div>
            </div>
            {children}
        </div>
    );
};

export default Index;
