import React from 'react'
import "./connectedStudents.less";
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import StarIcon from '@mui/icons-material/Star';
import PersonIcon from '@mui/icons-material/Person';
import PillSection from "../../PillSection"
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import { Grid } from '@mui/material';
import { useImageUrl } from '../../../../utils/UseImageURL';

const Index = (props) => {
	let { name, country, school, weightgpa, unweightgpa, universities, professions, courseAndActivities, profile, message, children } = props
	const gpaText = (weightgpa, unweightgpa) => {
		if (weightgpa, unweightgpa) {
			return `Aspiring to achieve unweighted ${unweightgpa} and weighted ${weightgpa} GPA`
		} else if (weightgpa) {
			return `Aspiring to achieve weighted ${weightgpa} GPA`
		} else if (unweightgpa) {
			return `Aspiring to achieve unweighted ${unweightgpa} GPA`
		} else return null;
	}
	const imageUrl = useImageUrl(profile);
	return (
		<div className='connectedStudentCard'>
			<div className="connectedStudentCard__topSec">
				{profile ? (
					<div className='connectedStudentCard__topSec__avatarImage'>
						<img className="connectedStudentCard__topSec__avatarImage--img"
							src={imageUrl} alt="userprofile"
						/>
					</div>
				) :
					<PersonIcon color="action" sx={{ border: '1px solid grey', borderRadius: "6px", fontSize: '1rem', "width": "65px", "height": "65px !important" }} />
				}
				<div className='connectedStudentCard__topSec__userInfo'>
					<span className='connectedStudentCard__topSec__userInfo__nameSec'>
						<p className='connectedStudentCard__topSec__userInfo__nameSec__primaryText'>{name}</p>
					</span>
					<p className='connectedStudentCard__topSec__userInfo__secondaryText'>{country}</p>
				</div>
			</div>
			<div className="connectedStudentCard__midSec">
				<div className="connectedStudentCard__midSec__educationSec">
					<SchoolOutlinedIcon className='connectedStudentCard__midSec__educationSec__schoolIcon' />
					<p className='connectedStudentCard__midSec__educationSec__schoolName'>{school}</p>
				</div>
				<div className="connectedStudentCard__midSec__goalsSec">
					{universities?.length > 0 &&
						<>
							<div className="connectedStudentCard__midSec__goalsSec__targetUniversitySec">
								<StarIcon className='connectedStudentCard__midSec__goalsSec__targetUniversitySec__starIcon' />
								<p className='connectedStudentCard__midSec__goalsSec__targetUniversitySec__label'> Aspiring to attend </p>
							</div>
							<PillSection data={universities || []} maxWidth={300} maxHeight={100} />
						</>
					}
					{professions?.length > 0 &&
						<>
							<div className="connectedStudentCard__midSec__goalsSec__targetUniversitySec">
								<StarIcon className='connectedStudentCard__midSec__goalsSec__targetUniversitySec__starIcon' />
								<p className='connectedStudentCard__midSec__goalsSec__targetUniversitySec__label'> Aspiring to become  </p>
							</div>
							<PillSection data={professions} maxWidth={170} maxHeight={100} />
						</>
					}
					{courseAndActivities?.length > 0 &&
						<>
							<div className="connectedStudentCard__midSec__goalsSec__targetUniversitySec">
								<StarIcon className='connectedStudentCard__midSec__goalsSec__targetUniversitySec__starIcon' />
								<p className='connectedStudentCard__midSec__goalsSec__targetUniversitySec__label'>Courses & activities</p>
							</div>
							<PillSection data={courseAndActivities} maxWidth={170} maxHeight={100} />
						</>
					}
					{gpaText(weightgpa, unweightgpa) &&
						<div className="connectedStudentCard__midSec__goalsSec__targetUniversitySec">
							<StarIcon className='connectedStudentCard__midSec__goalsSec__targetUniversitySec__starIcon' />
							<p className='connectedStudentCard__midSec__goalsSec__targetUniversitySec__label'>{gpaText(weightgpa, unweightgpa)}</p>
						</div>
					}
				</div>
			</div>
			<Grid className='mt-2 studentmsgcnt'>
				{message && (
					<div className='advisor-card-description'>
						<p>{message}</p>
					</div>
				)
				}
			</Grid>
			<div className="connectedStudentCard__buttonSec">
				{children}
			</div>
		</div>
	)
}

export default Index