import React, { useEffect } from 'react'
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import "./rejectReasonModal.less";

const validation = Yup.object({
    rejectReason: Yup.string().required('*Required').max(250, "Reason should not exceed more than 250 characters")
})


const Index = (props) => {
    const { register,  handleSubmit, reset, watch, control, formState: { errors }, setValue, clearErrors,  } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(
            validation
        ),
    });
    
    const onSubmit = (data) => {
        props?.rejectRequest(data);
        reset()
    }
    
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            className='rejectReasonModal'
            style={{"zIndex":"2000", backgroundColor: "rgb(0,0,0, 0.1)"}}
            centered >
            <Modal.Header closeButton className='rejectReasonModal__header'>
                <p className="rejectReasonModal__header__text">Please provide a reason for rejecting this session request</p>
            </Modal.Header>
            <Modal.Body className="rejectReasonModal__confirmationModal">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="input-floating-label">
                        <textarea className="textarea--primary-bg textbox--rounded input"
                            rows={5} cols={100} maxLength={300} id='rejectReason'
                            name='rejectReason' {...register('rejectReason')}
                            />
                        <label>Reason for rejecting</label>
                        <div className="error-text" >{errors?.rejectReason?.message}</div>
                        </div>
                    <div className="d-flex justify-content-end">
                        <button className="rejectReasonModal__confirmationModal__danger-btn" id='rejectBtn'>
                            Reject
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default Index