import React, { useEffect, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction"
import timeGridPlugin, { TimeColsView } from "@fullcalendar/timegrid";
import '../../../assets/css/schedule.less';
import 'bootstrap/dist/css/bootstrap.min.css';
import momentPlugin from '@fullcalendar/moment';
import moment from "moment";
import Backdrop from '@mui/material/Backdrop';
import Loader from '../../../components/Loader';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Search from '../../../components/Search';
import Select, { components } from "react-select";
import { getplanAdvisor, clearMessage, listScheduledEvents, confirmScheduledEventStatus, getFreeSessionRemaining, bookSessionErrorStatus, setCancelStatus, getChildProfile, listSwitchEvent, getConnectedAdvisorsParents, setConnectedAdvisorStatus, bookPaidSession, bookSession, bookPaidSessionErrorStatus, bookSessionStatus, bookPaidSessionStatus } from "../../../redux/actions"
import { connect } from "react-redux";
import { withRouter } from "../../../redux/store/navigate";
import DetailPopup from "./Popup/SessionDetailPopu"
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import BookSessionPopup from "../../../componentsNew/Common/BookSession/BookSessionPopup";
import CustomAlert from "../../../components/CustomAlert";
import { GetCurrentTimezone, convertUTCTodateTimeZone } from '../../../utils/CommonUtils';
import dayjs from 'dayjs';

const selectStyle = {
    option: (styles, { data, isDisabled, isFocused, isSelected, }) => {
        return {
            ...styles,
            fontWeight: isFocused ? '600' : '400',
            color: isFocused ? 'rgba(0, 0, 0, 1)' : 'rgba(92, 92, 92, 1)',
            backgroundColor: "white",
            backgroundColor: isFocused ? "white" : "white",
            cursor: isDisabled ? 'not-allowed' : 'default',
        };
    },
}

const CalendarViews = [
    { value: 'dayGridMonth', label: 'Month' },
    { value: 'timeGridWeek', label: 'Week' },
    { value: 'timeGridDay', label: 'Day' }
];

function renderDayCell(dayCellContent) {
    return (
        <>
            <div className="daycell-head">
                <div>  {dayCellContent.dayNumberText}</div>
                <button className="daycell-event-add-btn p-month" >+</button>
            </div>
        </>
    );
}

const Index = (props) => {

    const [showdetails, setshowdetails] = useState(false)
    const [eventData, setEventData] = useState({})
    const [differentUserIds, setDifferentUserIds] = useState([]);
    const [AdvisorProfile, setAdvisorProfile] = useState([])
    const [TimeSession, setTimeSession] = useState([]);
    const [bookshow, setbookshow] = useState(false);
    const [advisorid, setAdvisorid] = useState("");
    const [advisorName, setAdvisorName] = useState("");
    const [loading, setloading] = useState(false)
    const [paidSession, setPaidSession] = useState(null);
    const [advisorTime, setAdvisorTime] = useState();


    useEffect(() => {
        setloading(true)
        props?.getChildProfile()
    }, [])
    useEffect(() => {
        if (props?.childprofile?.length > 0) {
            props?.getConnectedAdvisorsParents(localStorage.getItem("parentViaChild"))
            props.listSwitchEvent(localStorage.getItem("parentViaChild"))
            if (props?.connectedAdvisors?.length === 1) {
                props?.getFreeSessionRemaining(localStorage.getItem("parentViaChild"), props?.connectedAdvisors[0]?.userInfo?.id)

            }
        }
        setloading(true)
    }, [props?.childprofile])

    useEffect(() => {
        if (props?.connectedAdvisorsStatus && props?.connectedAdvisors) {
            props?.setConnectedAdvisorStatus(false)
            setloading(false)

        }

    }, [props?.connectedAdvisors])

    console.log("props?.childprofile", props?.childprofile);

    useEffect(() => {
        const getDifferentUserIds = () => {
            const userProfileIds = props.userProfile.id;

            const differentIds = [];

            props?.eventSwitchList?.data.forEach((event) => {
                event.userInvitationStatuses.forEach((invitationStatus) => {
                    if (!userProfileIds.includes(invitationStatus.userId)) {
                        differentIds.push(invitationStatus.userId);
                    }
                });
            });

            return differentIds;
        };

        if (Array.isArray(props?.eventSwitchList?.data) && props.userProfile && props.userProfile.id) {
            const differentIds = getDifferentUserIds();
            setDifferentUserIds(differentIds);
        }
    }, [props.eventSwitchList, props.userProfile]);


    useEffect(() => {
        if (differentUserIds && differentUserIds?.length > 0) {
            const removeDuplicates = (arr) => {
                return arr.filter((item, index) => arr.indexOf(item) === index);
            };

            const uniqueArray = removeDuplicates(differentUserIds);
            let payload = {
                userIds: uniqueArray
            }
            props?.getplanAdvisor(payload)
        }

    }, [differentUserIds])


    const CloseBookSession = () => {
        props?.clearMessage()
        setbookshow(false)
        setTimeSession([])
        setAdvisorid("")
        setAdvisorName("")
    }



    const handleDetailShow = (arg) => {
        const { title, start, end, extendedProps } = arg.event;
        const Starttime = start;
        const Endtime = end;

        setEventData({
            ['title']: title,
            ['start']: Starttime,
            ['end']: Endtime,
            ...extendedProps.details,
            ...extendedProps,
            ['AdvisorData']: extendedProps?.eventParticipents
        })
        setshowdetails(true)
    }
    const handleDetailHide = () => {
        setshowdetails(false)
    }

    let events = props?.eventSwitchList?.data?.map(event => {
        // let advisorNames = event.userIds.map(userId => {
        //     let advisor = props?.advisorlistdata && props?.advisorlistdata?.length > 0 && props?.advisorlistdata.find(advisor => advisor?.id === userId);
        //     return advisor ? advisor.fullName === "null null" ? "" : advisor.fullName : "";
        // });
        let eventParticipents = [];
        let advisorNames = event?.userIds?.map(userId => {
            let advisor = props?.advisorlistdata?.length > 0 && props?.advisorlistdata?.find(advisor => advisor?.id === userId);
            if (advisor && advisor?.currentRole === "advisor") {
                eventParticipents.push(advisor);
            }
            return advisor ? advisor?.fullName : "";
        });
        return ({
            title: advisorNames ? advisorNames.filter(name => name !== "").join(', ') : "Advisor Name",
            eventParticipents: eventParticipents?.filter(data => data) || [],
            start: convertUTCTodateTimeZone(event.eventTime, props?.userProfile?.timezone)?.format('YYYY-MM-DD HH:mm:ss'),
            end: convertUTCTodateTimeZone(event.eventClosingTime, props?.userProfile?.timezone)?.format('YYYY-MM-DD HH:mm:ss'),
            details: {
                id: event?.id,
                createdBy: event?.createdBy,
                eventType: event?.eventType,
                eventDuration: event?.eventDuration,
                eventStatus: event?.eventStatus,
                meetingDescription: event?.meetingDescription,
                meetingLocation: event?.meetingLocation,
                password: event?.password
            },
            rescheduleLog: event?.eventLog,
            invitationStatus: event.userInvitationStatuses
        })
    })

    var calendar = new FullCalendar();

    const selectStyle = {
        menu: (provided) => ({
            ...provided,
            zIndex: 99,
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected, }) => {
            return {
                ...styles,
                fontWeight: isFocused ? '600' : '400',
                color: isFocused ? 'rgba(0, 0, 0, 1)' : 'rgba(92, 92, 92, 1)',
                backgroundColor: "white",
                backgroundColor: isFocused ? "white" : "white",
                cursor: isDisabled ? 'not-allowed' : 'default',
            };
        },
    }

    const OpenBookSession = (e, data) => {
       
        props?.clearMessage()
        e.preventDefault()
        setPaidSession(getNoOfPaidSessions(data) ? true : null)
        setTimeSession(data)
        setAdvisorid(data?.userInfo?.id)
        setAdvisorTime([data?.userInfo?.advisorProfile?.advisorWorkingHours])
        setAdvisorName({ label: data?.userInfo?.fullName, value: data?.userInfo?.id })
        setbookshow(true)
    }

    const HandleNavigate = (e) => {
        e.preventDefault();
        // navigate('/student/mynetwork')
    }


    const handleSelect = (valObj, action) => {
        if (action === "clear") {
            return;
        } else {
            calendar.current.getApi().changeView(valObj.value);
        }
    }

    function convertTo24HourFormat(time12h) {
        const [time, modifier] = time12h.split(' ');
        let [hours, minutes] = time.split(':');
        hours = parseInt(hours, 10);
        if (modifier === 'PM' && hours !== 12) {
            hours += 12;
        } else if (modifier === 'AM' && hours === 12) {
            hours = 0;
        }
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.padStart(2, '0');
        return `${formattedHours}:${formattedMinutes}:00`;
    }

    const SessionBook = (data, value) => {
        const moment = require('moment');
        const SelectHours = value
        const startTime = value.split(' - ')[0];
        const selectedDate = moment(data.Selectdate, "YYYY-MM-DD").format("YYYY-MM-DD");
        let currentTime = selectedDate + ' ' + convertTo24HourFormat(startTime)


        // Time Gap
        const [startTimes, endTime] = SelectHours.split(' - ');
        const parsedStartTime = moment(startTimes, 'h:mm A');
        const parsedEndTime = moment(endTime, 'h:mm A');
        const timeDiffInMillis = parsedEndTime.diff(parsedStartTime);
        const timeDiffInMinutes = moment.duration(timeDiffInMillis).asMinutes();
        let currDate = new Date(data?.Selectdate)

        let payload = {
            "advisorId": advisorid,
            "eventTime": currentTime,
            "eventDuration": 30,
            "meetingLocation": data?.Location,
            "meetingDescription": data?.Description,
            "studentId": localStorage.getItem("parentViaChild"),
            "password": data?.password
        }
        if (data?.package) {
            payload["packageId"] = data?.package?.value;
            props?.bookPaidSession(payload)
        } else {
            props?.bookSession(payload)

        }
        // setLoading(true)
    }

    useEffect(() => {
        if (props?.sessionBooked) {
            // setLoading(false)
            CloseBookSession()
            props?.bookSessionStatus(false)
            props.listSwitchEvent(localStorage.getItem("parentViaChild"))
        }
        if (props?.booksessionerror) {
            // setLoading(false)
            CloseBookSession()
            props?.bookSessionErrorStatus(false)
        }
        if (props?.cancelSession) {
            handleDetailHide()
            props.listSwitchEvent(localStorage.getItem("parentViaChild"))
            props?.setCancelStatus(false)
        }
    }, [props?.sessionBooked, props?.booksessionerror, props?.cancelSession])

    useEffect(() => {
        if (props?.paidSessionBooked) {
            props.listSwitchEvent(localStorage.getItem("parentViaChild"))
            CloseBookSession()
            props?.bookPaidSessionStatus(false)
        }
        if (props?.bookPaidSessionError) {
            // setLoading(false)
            CloseBookSession()
            props?.bookPaidSessionErrorStatus(false)
        }
    }, [props?.paidSessionBooked, props?.bookPaidSessionError])



    const renderEventContent = (arg) => {
        const { start } = arg.event;
        const currentDate = GetCurrentTimezone(props?.userProfile?.timezone)
        const isPastEvent = (currentDate > dayjs(start).format('YYYY-MM-DDTHH:mm:ss'));
        let eventStatus = arg.event?.extendedProps?.invitationStatus?.find((status) => status?.userId === localStorage.getItem("parentViaChild"))
        const backgroundColor = eventStatus?.status === "pending" ? '#3297FF' : eventStatus?.status === "rejected" ? '#FF6565' : eventStatus?.status === 'reScheduled' ? "#FDB560" : '#3297FF';
        let eventStyle = {};
        if (isPastEvent) {
            eventStyle['borderTop'] = `.3px solid ${backgroundColor}`;
            eventStyle['borderBottom'] = `.3px solid ${backgroundColor}`;
            eventStyle['backgroundColor'] = '#fff';
            eventStyle['color'] = backgroundColor;
        } else {
            eventStyle['backgroundColor'] = backgroundColor;
        }
        return (
            <div className="event" style={eventStyle}>
                <div className="eventMonthview">
                    <div className="fc-event-time" style={isPastEvent ? { color: backgroundColor } : {}}>
                        {moment(arg.event.start).format("h:mm")}
                    </div>
                    <div className="fc-event-title" style={isPastEvent ? { color: backgroundColor } : {}}>{arg.event.title} - Consulting Session</div>
                </div>
                <div className="eventTimeGridview">
                    {/* <img src={arg.event.extendedProps.image_url} /> */}
                    <div className="eventTime">
                        {moment(arg.event.start).format("h:mm")} -{" "}
                        {moment(arg.event.end).format("h:mm A")}
                    </div>
                    <div className="eventTitle">{arg.event.title}- Consulting Session</div>
                </div>
            </div>
        );
    };

    const getNoOfPaidSessions = (advisorData) => {
        const subscriptionDetails = advisorData?.subscriptiondetails?.[0];
        return subscriptionDetails ? `(${subscriptionDetails.noOfPaidSessions} remaining)` : '';
    };
    const getNoOffreeSessions = (advisorData) => {
        const subscriptionDetails = advisorData?.userInfo?.advisorProfile?.noOfFreeConsultation
        return subscriptionDetails ? `(${subscriptionDetails} free remaining)` : '';
    }


    return (
        <>
            {loading && <Loader />}
            {<div style={{ "width": "100%", "display": "flex", "justifyContent": "center", }}> {<CustomAlert />}</div>}
            {(bookshow && advisorTime) && <BookSessionPopup show={bookshow} userTime={advisorTime}  selectedAdvisor={advisorName} advisorList={props?.connectedAdvisors} paidSession={paidSession} onHide={CloseBookSession} Time={TimeSession} HandleSumit={SessionBook} />}
            {props?.connectedAdvisors && props?.connectedAdvisorsStatus &&
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={props?.connectedAdvisors?.length === 0} style={{ "marginLeft": "100px" }} >
                    <div className='Backdrop__container'>
                        <div className='Backdrop__container__card'>
                            <div className='title_cnt'>
                                <p>You currently have no connected advisors Explore and connect with advisors to book your <b>free</b> session</p>
                                {/* <p>You currently have used all your available sessions Explore and connect with more advisors to book your <b>free</b> session or purchase additional session from your existing advisors</p> */}
                            </div>
                            <div className='btn_cnt'>
                                <p onClick={(e) => HandleNavigate(e)}>
                                    <span>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="11" cy="11" r="6" stroke="#222222" />
                                            <path d="M11 8C10.606 8 10.2159 8.0776 9.85195 8.22836C9.48797 8.37913 9.15726 8.6001 8.87868 8.87868C8.6001 9.15726 8.37913 9.48797 8.22836 9.85195C8.0776 10.2159 8 10.606 8 11" stroke="#222222" stroke-linecap="round" />
                                            <path d="M20 20L17 17" stroke="#222222" stroke-linecap="round" />
                                        </svg>
                                    </span>
                                    Explore Advisors
                                </p>
                            </div>
                        </div>
                    </div>
                </Backdrop>
            }
            <div className="schedule-mobile-top mt-2">
                <div className="dashboardtop">
                    <div className="row">
                        <div className="col-md-8 col-sm-11 col-10">
                            <div className="dashboard-top-text">
                                <h2>Schedule</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="schedule ">
                <div className="calendar-outer">
                    <div className="calendar-box">
                        <div className="schedule-head-box">
                            <div className="right">
                                <div className="cal-select-month mt-2" >
                                    <Select
                                        closeMenuOnSelect={true}
                                        options={CalendarViews}
                                        placeholder={"Month"}
                                        styles={selectStyle}
                                        onChange={(value, { action }, name) => handleSelect(value, action)}
                                    />
                                </div>
                            </div>
                            <div className="left">
                                <div className="top-cal">
                                    {
                                        props?.connectedAdvisors?.length === 1 && (
                                            <p>You have {props?.getRemainingFreeSession?.availableFreeSession} free session remaining with {props?.connectedAdvisors[0]?.userInfo?.fullName}</p>

                                        )
                                    }
                                    <div className="cal-add-btn">
                                        {props?.connectedAdvisors?.length > 1 ? (
                                            <DropdownButton className='Dropdown' id="dropdown-basic-button" title="Book Session" >
                                                {props?.connectedAdvisors?.length > 0 && props?.connectedAdvisors?.map((data, index) => (
                                                    <>
                                                        <Dropdown.Item onClick={(e) => OpenBookSession(e, data)} >{data?.userInfo?.fullName}{" "}{getNoOfPaidSessions(data) ? getNoOfPaidSessions(data) : getNoOffreeSessions(data)}</Dropdown.Item>
                                                    </>
                                                ))}
                                            </DropdownButton>
                                        ) :
                                            <>
                                                {props?.connectedAdvisors?.length > 0 && props?.connectedAdvisors?.map((data,) => (
                                                    <Button className='Booksession-btn' onClick={(e) => OpenBookSession(e, data)}>Book Session</Button>
                                                ))}
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="small-cal">
                            <div className="left mt-3" style={{ zIndex: '999' }}>
                                <div className="cal-select-month">
                                    <Select
                                        closeMenuOnSelect={true}
                                        options={CalendarViews}
                                        placeholder={"Month"}
                                        styles={selectStyle}
                                        onChange={(value, { action }, name) => handleSelect(value, action)}
                                    />
                                </div>
                            </div>

                            {/* <div className="right">
                                <svg width="39" height="39" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M17 13C17 12.4477 17.4477 12 18 12H19C19.5523 12 20 12.4477 20 13V14H27C27.5523 14 28 14.4477 28 15C28 15.5523 27.5523 16 27 16H20V17C20 17.5523 19.5523 18 19 18H18C17.4477 18 17 17.5523 17 17V16H13C12.4477 16 12 15.5523 12 15C12 14.4477 12.4477 14 13 14H17V13ZM13 24C12.4477 24 12 24.4477 12 25C12 25.5523 12.4477 26 13 26H22V27C22 27.5523 22.4477 28 23 28H24C24.5523 28 25 27.5523 25 27V26H27C27.5523 26 28 25.5523 28 25C28 24.4477 27.5523 24 27 24H25V23C25 22.4477 24.5523 22 24 22H23C22.4477 22 22 22.4477 22 23V24H13Z" fill="#1B1C1E" /></svg>
                                <div className="cal-add-btn"><button className="btn cta--rounded cta-primary" onClick={handleDateClick}>+</button></div>
                            </div> */}
                            {
                                props?.connectedAdvisors?.length === 1 && (
                                    <div>You have {props?.getRemainingFreeSession?.availableFreeSession} free session remaining with {props?.connectedAdvisors[0]?.userInfo?.fullName}</div>

                                )
                            }
                            <div className="cal-add-btn">
                                {props?.connectedAdvisors?.length > 1 ? (
                                    <DropdownButton className='Dropdown' id="dropdown-basic-button" title="Book Session" >
                                        {props?.connectedAdvisors?.length > 0 && props?.connectedAdvisors?.map((data, index) => (
                                            <>
                                                <Dropdown.Item onClick={(e) => OpenBookSession(e, data)} >{data?.userInfo?.fullName}{" "}{getNoOfPaidSessions(data) ? getNoOfPaidSessions(data) : getNoOffreeSessions(data)}</Dropdown.Item>
                                            </>
                                        ))}
                                    </DropdownButton>
                                ) :
                                    <>
                                        {props?.connectedAdvisors?.length > 0 && props?.connectedAdvisors?.map((data,) => (
                                            <Button className='Booksession-btn' onClick={(e) => OpenBookSession(e, data)}>Book Session</Button>
                                        ))}
                                    </>
                                }
                            </div>
                        </div>

                        <FullCalendar
                            ref={calendar}
                            customButtons={{
                                myCustomButton: { icon: 'calendar', }
                            }}
                            plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, momentPlugin]}
                            eventContent={renderEventContent}
                            dayCellContent={renderDayCell}
                            views={{
                                month: {
                                    titleFormat: "MMM['] YYYY",
                                    eventTimeFormat: 'h:mm',
                                    eventClassNames: "monthview-event-class",
                                    dayMaxEventRows: 2
                                },
                                week: {
                                    type: "weekgrid",
                                    dayHeaderFormat: "ddd  D",
                                    dayHeaderContent: ({ date }) => {
                                        const fd = date.getDay()
                                        const fdate = date.getDate()
                                        const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
                                        return (
                                            <div>
                                                <div className="week-event-Day">{weekdays[fd]}</div>
                                                <div className="week-event-Date">{fdate}</div>
                                            </div>
                                        );
                                    },
                                    titleFormat: "MMM['] YYYY",
                                    slotDuration: '01:00:00',
                                    slotLabelClassNames: 'time-hours',
                                    timeFormat: 'H(:mm)',
                                    scrollTime: '09:00:00',
                                    slotLabelFormat: 'H  A',
                                    allDaySlot: false,
                                    eventClassNames: "week-view-event-css",
                                    dayHeaderClassNames: "weekview-day-header",
                                    titleRangeSeparator: "-",
                                },
                                day: {
                                    titleFormat: "MMM['] YYYY",
                                    allDaySlot: false,
                                    scrollTime: '09:00:00',
                                    slotDuration: '01:00:00',
                                    slotLabelClassNames: "day-slot-class",
                                    slotLabelFormat: 'H  A',
                                    type: "daygrid",
                                    eventTimeFormat: 'H : mm a',
                                    dayHeaderClassNames: "dayview-header",
                                    // slotLaneClassNames: "dayview-slot-class",
                                    slotLaneContent: ({ arg }) => {
                                        return (
                                            <div className="day-slot-add">
                                                <button className="daycell-event-add-btn p-day" >+
                                                </button>
                                            </div>
                                        );
                                    },
                                    eventClassNames: "day-view-event-css"
                                }
                            }}

                            events={events}
                            eventClick={handleDetailShow}
                            themeSystem="bootstrapFontAwesome"
                            height="930px"
                            editable={false}
                            selectable="true"
                            weekNumberCalculation="ISO"
                            titleFormat={{ year: "numeric", month: "short" }}
                            headerToolbar={{
                                left: "title,",
                                center: "prev,myCustomButton,next",
                                right: "",
                            }}
                        />
                    </div>
                </div>
            </div>
            <DetailPopup show={showdetails} onHide={handleDetailHide} data={eventData} childId={props?.childprofile} />
        </>
    )
}

const mapStateToProps = ({ commonData, advisorData, userProfileData, studentData }) => {
    const { scheduledEvents, eventSwitchList, connectedAdvisors, paidSessionBooked, bookPaidSessionError, sessionBooked, booksessionerror, connectedAdvisorsStatus, cancelSession, getRemainingFreeSession } = commonData;
    const { eventConfirmationStatus } = advisorData;
    const { advisorlistdata } = studentData
    const { userProfile, childprofile } = userProfileData;
    return { scheduledEvents, eventSwitchList, eventConfirmationStatus, userProfile, advisorlistdata, connectedAdvisors, childprofile, paidSessionBooked, bookPaidSessionError, cancelSession, sessionBooked, booksessionerror, connectedAdvisorsStatus, getRemainingFreeSession };
};

export default connect(mapStateToProps, { getplanAdvisor, clearMessage, getConnectedAdvisorsParents, getFreeSessionRemaining, bookSessionErrorStatus, setCancelStatus, listSwitchEvent, getChildProfile, setConnectedAdvisorStatus, bookPaidSession, bookSession, listScheduledEvents, confirmScheduledEventStatus, bookPaidSessionErrorStatus, bookSessionStatus, bookPaidSessionStatus })(withRouter(Index));

