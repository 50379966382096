import React from 'react'
import "../../../componentsNew/Dashboard/MoneyStatsCard/moneyStatscard.less"
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
const index = (props) => {
    console.log(props?.earningsCount, "hsfbghsdgf")
    return (
        <>
            <div className='cardContentMain'>
                <div className="card-money">
                    <MonetizationOnOutlinedIcon style={{ color: "green" ,fontSize:"26px"}} />
                    <p title='LifeTime Earnings'>LifeTime Earnings</p>
                    <h2>{props?.earningsCount?.totalEarnings}</h2>
                </div>
            </div>
            <div className='cardContentMain'>
                <div className="card-money">
                    <MonetizationOnOutlinedIcon style={{ color: "green",fontSize:"26px" }} />
                    <p title='Number Of active Subscriptions'>Number Of active Subscriptions</p>
                    <h2>{props?.earningsCount?.noOfActiveSubscriptions}</h2>
                </div>

            </div>
            <div className='cardContentMain'>
                <div className="card-money">
                    <MonetizationOnOutlinedIcon style={{ color: "green",fontSize:"26px" }} />
                    <p title='Pending earings For this year'>Pending earings For this year</p>
                    <h2>{props?.earningsCount?.pendingEarnings}</h2>
                </div>

            </div>
            <div className='cardContentMain'>
                <div className="card-money">
                    <MonetizationOnOutlinedIcon style={{ color: "green",fontSize:"26px" }} />
                    <p title='Earnings so far this year'>Earnings so far this year</p>
                    <h2>{props?.earningsCount?.thisYearEarning}</h2>
                </div>

            </div>
        </>
    )
}

export default index

