import React, { Component } from "react";
import Viewer from "../Viewer";
import Modal from "react-bootstrap/Modal";


class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {

        return (

            <>
                <div className="view-document">
                    <Modal show={this.props.show} fullscreen={this.props.fullscreen} onHide={this.props.onHide}>
                        <Modal.Header className="view-document__doc-top " closeButton >

                            {/* <div className="row"> */}
                            <div className="me-5 view-document__top-heading">
                                <div className="view-document__heading">
                                    Cambridge.doc
                                </div>
                                <div className="view-document__subheading">
                                    Created by you in 2 September, 2021
                                </div>
                            </div>
                            <div className="">
                                <div className="view-document__docIcon">
                                    <div className="icon1 ms-2 me-2">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.8523 15.4922C11.8699 15.5146 11.8923 15.5327 11.9179 15.5452C11.9435 15.5577 11.9715 15.5641 12 15.5641C12.0285 15.5641 12.0565 15.5577 12.0821 15.5452C12.1077 15.5327 12.1301 15.5146 12.1477 15.4922L14.7727 12.1711C14.8687 12.0492 14.782 11.8687 14.625 11.8687H12.8883V3.9375C12.8883 3.83437 12.8039 3.75 12.7008 3.75H11.2945C11.1914 3.75 11.107 3.83437 11.107 3.9375V11.8664H9.375C9.21797 11.8664 9.13125 12.0469 9.22734 12.1688L11.8523 15.4922ZM20.5781 14.6719H19.1719C19.0688 14.6719 18.9844 14.7563 18.9844 14.8594V18.4688H5.01562V14.8594C5.01562 14.7563 4.93125 14.6719 4.82812 14.6719H3.42188C3.31875 14.6719 3.23438 14.7563 3.23438 14.8594V19.5C3.23438 19.9148 3.56953 20.25 3.98438 20.25H20.0156C20.4305 20.25 20.7656 19.9148 20.7656 19.5V14.8594C20.7656 14.7563 20.6812 14.6719 20.5781 14.6719Z" fill="#1B1C1E" />
                                        </svg>
                                    </div>
                                    <div className="icon2 ms-2 me-2">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.4375 4.3125H8.25C8.35313 4.3125 8.4375 4.22812 8.4375 4.125V4.3125H15.5625V4.125C15.5625 4.22812 15.6469 4.3125 15.75 4.3125H15.5625V6H17.25V4.125C17.25 3.29766 16.5773 2.625 15.75 2.625H8.25C7.42266 2.625 6.75 3.29766 6.75 4.125V6H8.4375V4.3125ZM20.25 6H3.75C3.33516 6 3 6.33516 3 6.75V7.5C3 7.60313 3.08437 7.6875 3.1875 7.6875H4.60312L5.18203 19.9453C5.21953 20.7445 5.88047 21.375 6.67969 21.375H17.3203C18.1219 21.375 18.7805 20.7469 18.818 19.9453L19.3969 7.6875H20.8125C20.9156 7.6875 21 7.60313 21 7.5V6.75C21 6.33516 20.6648 6 20.25 6ZM17.1398 19.6875H6.86016L6.29297 7.6875H17.707L17.1398 19.6875Z" fill="#1B1C1E" />
                                        </svg>

                                    </div>
                                    <div className="icon3 ms-2 me-2">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.4297 9.86719C12.8883 9.86719 12.4688 10.2867 12.4688 10.8047C12.4688 11.3227 12.8883 11.7422 13.4297 11.7422C13.9242 11.7422 14.3438 11.3227 14.3438 10.8047C14.3438 10.2867 13.9242 9.86719 13.4297 9.86719ZM6.86719 9.86719C6.32578 9.86719 5.90625 10.2867 5.90625 10.8047C5.90625 11.3227 6.32578 11.7422 6.86719 11.7422C7.36172 11.7422 7.78125 11.3227 7.78125 10.8047C7.78125 10.2867 7.36172 9.86719 6.86719 9.86719Z" fill="#1B1C1E" />
                                            <path d="M20.9538 8.08699C19.8264 6.54011 18.2514 5.50652 16.5241 5.04011V5.04245C16.1233 4.59714 15.671 4.18699 15.1647 3.82136C11.328 1.0323 5.94207 1.88308 3.14129 5.7198C0.884257 8.83699 0.980351 13.03 3.28191 16.0089L3.30066 19.1167C3.30066 19.1917 3.31238 19.2667 3.33582 19.337C3.46004 19.7331 3.88191 19.951 4.27566 19.8268L7.24285 18.8917C8.02801 19.1706 8.83895 19.33 9.6452 19.3745L9.63348 19.3839C11.7218 20.905 14.4593 21.362 16.9694 20.5323L19.9483 21.5026C20.0233 21.526 20.1007 21.5401 20.1804 21.5401C20.5952 21.5401 20.9304 21.205 20.9304 20.7901V17.6495C22.9952 14.8464 23.0491 10.9721 20.9538 8.08699ZM7.57098 17.2276L7.28973 17.1104L4.96941 17.837L4.94598 15.3995L4.75848 15.1885C2.77566 12.7698 2.64441 9.28464 4.50066 6.72761C6.76004 3.62917 11.0913 2.9448 14.1804 5.18074C17.2788 7.43308 17.9655 11.7573 15.7272 14.837C13.8499 17.4128 10.4889 18.3643 7.57098 17.2276ZM19.4069 16.8292L19.2194 17.0635L19.2429 19.501L16.946 18.7276L16.6647 18.8448C15.3522 19.3323 13.953 19.3721 12.6569 19.0089L12.6522 19.0065C14.3843 18.4745 15.9546 17.3964 17.0866 15.8448C18.8772 13.3768 19.1679 10.276 18.1272 7.6323L18.1413 7.64167C18.6804 8.02839 19.1749 8.5112 19.5944 9.0948C21.296 11.4292 21.1999 14.6073 19.4069 16.8292Z" fill="#1B1C1E" />
                                            <path d="M10.1484 9.86719C9.60703 9.86719 9.1875 10.2867 9.1875 10.8047C9.1875 11.3227 9.60703 11.7422 10.1484 11.7422C10.643 11.7422 11.0625 11.3227 11.0625 10.8047C11.0625 10.2867 10.643 9.86719 10.1484 9.86719Z" fill="#1B1C1E" />
                                        </svg>

                                    </div>

                                </div>
                            </div>
                            {/* </div> */}
                        </Modal.Header>
                        <Modal.Body className="ps-0 pe-0">
                            <div className="view-document__doc-background">
                            <Viewer />
                            </div>
                            
                        </Modal.Body>
                        <Modal.Footer>

                        </Modal.Footer>
                    </Modal>
                </div>
            </>

        );

    }
}
export default Index;
