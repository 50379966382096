import React from "react";
import { Routes, Route } from "react-router-dom";
import LoginLayout from '../layouts/Login';

// Auth pages
import Login from "../pages/Auth/Login";
import UserProfile from "../pages/UserProfile";
import Signup from "../pages/Auth/SignUp";

import Notavailable from "../pages/Common/404"
import Forbidden from "../pages/Common/Forbidden"
import Newpassword from "../pages/Common/NewPassword";
import Resetpassword from "../pages/Common/ResetPassword";
import RecieveInvites from "../pages/Common/RecieveInvites";
import PublicStudentProfile from "../pages/Common/PublicStudentProfile";

const CommonRoutes = () => (
  <Routes>
    {/* Common Routes */}
    <Route exact path="/" element={<LoginLayout />} >
      <Route path="/" element={<Login />} />
      <Route path="signup" element={<Signup />} />
      <Route path="notfound" element={<Forbidden />} />
      <Route path="forgotpassword" element={<Newpassword />} />
      <Route path="/forgotpassword/verify/:key" element={<Resetpassword />} />
      <Route path="/userinvitation/verify/:key" element={<RecieveInvites />} />
    </Route>
    <Route  path="view/profile/:studentId" element={<PublicStudentProfile />} ></Route>
    <Route  path="userProfile/:userAdvisorId" element={<UserProfile />} ></Route>
  </Routes>
);
export default CommonRoutes;