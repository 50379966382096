import React, { useState, useEffect, useRef } from "react";
import "../../../../assets/css/New_Profile.less";
import { Grid, Box, Button, Stack, TextField, } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { styled } from '@mui/material/styles';
import { clearMessage } from "../../../../redux/actions";
import { connect } from "react-redux";


const Index = (props) => {
    const useStyless = styled(theme => ({
        root: {
            flexGrow: 1,
        },
        form: {
            margin: theme.spacing(2),
        },
        title: {
            fontWeight: 'bold',
        },
        leftForm: {
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
            },
        },
        rightForm: {
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
            },
        },
        icon: {
            marginRight: theme.spacing(1),
        },
    }));
    const classes = useStyless();
   
    useEffect(() => {
        props.clearMessage()
    }, [])


    return (
        <>
            <div className="Billing_Card">
                <p className="Billing_Card_Title">My Cards</p>
                <div className="Debit_Card_Container">
                    <div className="Debit_Card">
                        <div className="Debit_Card_Number_Cnt">
                            <p className="cardnumber">**** {" "}****{" "}****{" "}<span>1121</span></p>
                        </div>
                        <div className="Debit_Card_Expiry_Cnt">
                            <div className="row">
                                <div className="col-5">
                                    <p className="Expire">EXPIRE</p>
                                    <p className="carddate">01/25</p>
                                </div>
                                <div className="col-7">
                                    <div className="edit_row">
                                        <p className="Edit_btn">EDIT</p>
                                        <p className="Delete_btn">DELETE</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="Billing_Card_Form_Card">
                <p className="Billing_Card_Form_Title">Add Another Card Info</p>
                <div className="Debit_Card_Container">
                    <div className="Debit_Card_Container_Form">
                         <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} className={classes.leftForm}>
                                <h3 className={classes.title}>Card Details</h3>
                                <Grid container spacing={2}>
                                    <Grid item xs={1}>
                                        <CreditCardOutlinedIcon className={classes.icon} />
                                    </Grid>
                                    <Grid item xs={11}>
                                        <TextField
                                            id="debit-card"
                                            label="Debit Card"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <GroupAddOutlinedIcon className={classes.icon} />
                                    </Grid>
                                    <Grid item xs={11}>
                                        <TextField
                                            id="card-owner"
                                            label="Card Owner"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.rightForm}>
                                <h3 className={classes.title}>Expiry Date</h3>
                                <Grid container spacing={2}>
                                    <Grid item xs={1}>
                                        <CalendarTodayOutlinedIcon className={classes.icon} />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField
                                            id="month"
                                            label="Month"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField
                                            id="year"
                                            label="Year"
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid> 
                        
                        
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Grid item xs={6}><p className="card_number">Card Number</p></Grid>
                                    <Grid item xs={6}>
                                        <div className="input-non-floating-label" style={{ display: 'flex' }}>
                                            <CreditCardOutlinedIcon sx={{ fill: '#919293', marginTop: '10px', marginRight: '10px' }} />
                                            <input type="text" {...register("cardNumber")} className={"textbox--primary textbox--rounded input"}
                                                name="cardNumber" placeholder="0000 0000 0000 0000" />
                                            <div className="error-text">{errors.cardNumber?.message}</div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} className="mt-4">
                                        <div className="input-non-floating-label" style={{ display: 'flex' }}>
                                            <GroupAddOutlinedIcon sx={{ fill: '#919293', marginTop: '10px', marginRight: '10px' }} />
                                            <input type="text" {...register("cardHolder")} className={"textbox--primary textbox--rounded input"}
                                                name="cardHolder" placeholder="Card holder name" />
                                            <div className="error-text">{errors.cardHolder?.message}</div>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid item xs={6}>
                                        <p className="card_number">Expiry Date</p>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6} sm={5} md={6} lg={6} xl={6}>
                                                <div className="input-non-floating-label" style={{ display: 'flex' }}>
                                                    <CreditCardOutlinedIcon sx={{ fill: '#919293', marginTop: '10px', marginRight: '20px' }} />
                                                    <input type="text" {...register("monthData")} className={"textbox--primary textbox--rounded input"}
                                                        name="monthData" placeholder="month" style={{ width: "100px" }} />
                                                    <div className="error-text">{errors.monthData?.message}</div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={6} sm={5} md={5} lg={5} xl={6}>
                                                <div className="col-4" style={{ "marginLeft": "30px" }}>
                                                    <div className="input-non-floating-label" style={{ display: 'flex' }}>
                                                        <input type="text" {...register("yearData")} className={"textbox--primary textbox--rounded input"}
                                                            name="yearData" placeholder="year" style={{ width: "100px" }} />
                                                        <div className="error-text">{errors.yearData?.message}</div>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} className="mt-4">
                                        <div className="input-non-floating-label" style={{ display: 'flex' }}>
                                            <LockOutlinedIcon sx={{ fill: '#919293', marginTop: '10px', marginRight: '10px' }} />
                                            <input type="text" {...register("cvvNumber")} className={"textbox--primary textbox--rounded input"}
                                                name="cvvNumber" placeholder="CVV" />
                                            <div className="error-text">{errors.cvvNumber?.message}</div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <div className="Billing_Save_Btn">
                                <Button className="Billing_Save" type="submit"> Save</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div> */}
        </>
    );
}
const mapStateToProps = ({ commonData, userProfileData, studentData }) => {
    const {  } = commonData
    const {  } = userProfileData;
    const {  } = studentData;
    return { };
};
export default connect(mapStateToProps, { clearMessage })(Index);
