import React, { Component } from "react";
import "../../assets/css/loading.less"
class Loader extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <>
                <div className="loading"></div>
            </>
        );
    }
}

export default Loader;