import React, { useEffect } from 'react'
import "./currentMilestone.less";
import { GetMilestoneList, getExploreStudents, getExploreStudentStatus, getSavedUserProfiles, advisorSaveStatus, setSavedUserProfileStatus, saveUserProfile, unSaveUser } from "../../../../redux/actions";
import { connect } from 'react-redux';
import MilestoneCard from "../../../../componentsNew/Common/Cards/MyWork/MilestoneCard"
import CourseCard from "../../../../componentsNew/Common/Cards/MyWork/CourseCard"
import { withRouter } from '../../../../redux/store/navigate';
import { useLocation, useNavigate } from 'react-router-dom';


const Index = (props) => {
  const { state } = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    let value = {
      searchString: '',
      pageNo: 0,
      limit: 10,
      milestoneStatus: [],
      planIds: [],
      sortOrder: "latest",
      studentId: localStorage.getItem("parentViaChild")
    }
    props?.GetMilestoneList(value)
  }, [])

  const handleNavigate = (e, data) => {
    if (data?.courseInfo !== null) {
        navigate(`plan/${data?.planInfo?.id}/milestones/${data?.courseInfo?.id}`, { state: { courseData: data?.courseInfo, milestoneData: data?.milestone } })
    } else {
      navigate(`plan/${data?.planInfo?.id}/activity/milestone` , { state: { activityData: data?.activityInfo, milestoneData: data?.milestone  } })
    }
}	

  return (
    <div className='myWork-studentMileStoneSec'>
      {props?.getmilestoneData?.map(data => {
        return (
          <MilestoneCard key={data?.milestone?.id}
            planName={data?.planInfo?.planName}
            milestoneName={data?.milestone?.milestoneTitle}
            course={data?.courseInfo?.courseName}
            activity={data?.activityInfo?.activityName}
            type={data?.milestone?.milestoneType}
            date={`${data?.milestone?.startingDate?.split(' ')?.[0]} - ${data?.milestone?.closingDate?.split(' ')?.[0]}`}
            semester={data?.courseInfo?.planPhase?.title}
            status={data?.milestone?.status}
          >
            <div className="myWork-courseCard__ctaSec__primaryBtnSec">
              <p className='myWork-courseCard__ctaSec__primaryBtnSec__helperText'>11 updates</p>
              <div className="myWork-courseCard__ctaSec__primaryBtnSec__primaryBtn" onClick={(e) => handleNavigate(e, data)}>
                View Updates
              </div>
            </div>
          </MilestoneCard>
        )
      })}
      {props?.getmilestoneData?.length === 0 &&
        <div className='myWork-studentMileStoneSec__noDataSec'>
          <p className='myWork-studentMileStoneSec__noDataSec__textBlack'>You don't have any current milestones</p>
        </div>
      }
    </div>
  )
}

const mapStateToProps = ({ studentData, commonData }) => {
  const { message, errorList } = commonData;
  const { getmilestoneData } = studentData;
  return {  message, errorList, getmilestoneData }
}

export default connect(mapStateToProps, { getExploreStudents, GetMilestoneList, getExploreStudentStatus, getSavedUserProfiles, advisorSaveStatus, setSavedUserProfileStatus, saveUserProfile, unSaveUser })(withRouter(Index));