import React, { useEffect, useState } from "react";
import { Modal, Box, Grid, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useImageUrl } from "../../../../utils/UseImageURL";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    minHeight: "340px",
    maxHeight: "60%",
    borderRadius: "5px"
};


const stripHTMLTags = (html) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || "";
};

function formatDate(dateTimeString) {
    const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
    };
    const date = new Date(dateTimeString);
    const formattedDate = date.toLocaleDateString("en-US", options);
    return `${formattedDate} `;
}

const Index = (props) => {
    const imgUrl = useImageUrl(props?.filterUser?.avatarPath)  
    
    return (
        <>
            <Modal
                open={props?.show}
                onClose={props?.handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container spacing={2}>
                        <Grid item xs={10}>
                            <List >
                                <ListItem>
                                    {props?.filterUser && props?.filterUser?.avatarPath ? 
                                        <img style={{ width: '35px', height: '35px', objectFit: "cover", borderRadius: '50%', marginRight: '10px'  }} src={imgUrl} /> : 
                                        <AccountCircleOutlinedIcon sx={{ fontSize: '2.5rem', fill: 'lightgray', marginRight: '10px' }} 
                                        />
                                    }
                                    <ListItemText primary={props?.filterUser?.firstName || ""}
                                        secondary={
                                            <>
                                                Updated: <span>{formatDate(props?.data?.modifiedAt)}</span>
                                            </>
                                        } />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={2}>
                            <CloseIcon onClick={props?.handleClose} />
                        </Grid>

                    </Grid>
                    <Grid item xs={12} className="p-4" >
                        <p style={{ wordWrap: 'break-word', overflow: "scroll", height: "13rem" }}> {stripHTMLTags(props?.data?.update)}</p>
                    </Grid>
                </Box>
            </Modal>
        </>

    );
}
export default Index;