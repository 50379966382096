import { GET_EXPLORE_ADVISOR_STATUS, GET_EXPLORE_ADVISOR, GET_EXPLORE_ADVISOR_BY_SECTION,EXPLORE_ADVISOR_BY_SECTION, EXPLORE_ADVISOR_BY_UNIVERSITY, 
  EXPLORE_ADVISOR_BY_UNIVERSITY_STATUS, EXPLORE_ADVISOR_BY_PROFESSION, EXPLORE_ADVISOR_BY_PROFESSION_STATUS, EXPLORE_ADVISOR_BY_COURSES, 
  EXPLORE_ADVISOR_BY_COURSES_STATUS, GET_EXPLORE_ADVISOR_BY_PROFESSION_SECTION,GET_EXPLORE_ADVISOR_BY_UNIVERSITY_SECTION, 
  GET_EXPLORE_ADVISOR_BY_COURSE_SECTION,GET_EXPLORE_ADVISOR_BY_ACTIVITY_SECTION,EXPLORE_ADVISOR_BY_ACTIVITY_STATUS, 
  EXPLORE_ADVISOR_BY_ACTIVITY, EXPLORE_STUDENT_BY_UNIVERSITY, EXPLORE_STUDENT_BY_UNIVERSITY_STATUS, EXPLORE_STUDENT_BY_PROFESSION, 
  EXPLORE_STUDENT_BY_PROFESSION_STATUS, EXPLORE_STUDENT_BY_COURSES, EXPLORE_STUDENT_BY_COURSES_STATUS, EXPLORE_STUDENT_BY_ACTIVITY, 
  EXPLORE_STUDENT_BY_ACTIVITY_STATUS, GET_EXPLORE_STUDENT_BY_PROFESSION_SECTION, GET_EXPLORE_STUDENT_BY_UNIVERSITY_SECTION,
  GET_EXPLORE_STUDENT_BY_SECTION, GET_EXPLORE_STUDENT_BY_COURSE_SECTION, GET_EXPLORE_STUDENT_BY_ACTIVITY_SECTION
} from '../constants/ExploreTypes'

const INIT_STATE = {
  error: "",
  loading: false,
  message: '',
  errorList: {},
  exploreAdvisorData : [],
  exploreAdvisorStatus : false,
  exploreUniversityAdvisorData: [],
  loadUniversityAdvisor: false,
  exploreProfessionAdvisorData: [],
  loadProfessionAdvisor: false,
  exploreCoursesAdvisorData: [],
  loadCoursesAdvisor: false,
  exploreActivitiesAdvisorData: [],
  loadActivitiesAdvisor: false,
  loadAdvisorBySection: false,
  // ************* Student **************
  exploreUniversityStudentData: [],
  loadUniversityStudent: false,
  exploreProfessionStudentData: [],
  loadProfessionStudent: false,
  exploreCoursesStudentData: [],
  loadCoursesStudent: false,
  exploreActivitiesStudentData: [],
  loadActivitiesStudent: false,
  loadStudentBySection: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
   
    case GET_EXPLORE_ADVISOR: {
      return {
        ...state,
        exploreAdvisorData : action.payload
      }
    }
    case  GET_EXPLORE_ADVISOR_STATUS: {
      return {
        ...state,
        exploreAdvisorStatus : action.payload
      }
    }
    case EXPLORE_ADVISOR_BY_SECTION: {
      return {
        ...state,
        loadAdvisorBySection: false
      }
    }
    case GET_EXPLORE_ADVISOR_BY_SECTION: {
      let index = state.exploreAdvisorData.findIndex(data => data.section === action.payload?.[0]?.section && data.sectionValue === action.payload?.[0]?.sectionValue)
      if(index !== -1) {
        let newOject = {
          description: state.exploreAdvisorData[index]?.description,
          limit: 10,
          pageNo: action?.payload[0]?.pageNo,
          searchString: "",
          section: action?.payload[0]?.section,
          sectionIndex: 0,
          sectionValue: action?.payload[0]?.sectionValue,
          total: 14,
          totalSection: 2,
          users: [...state.exploreAdvisorData[index]?.users, ...action?.payload[0]?.users]
        }
        const updatedItems = [...state.exploreAdvisorData];
        updatedItems[index] = newOject
      }
      return {
        ...state,
        // exploreAdvisorData : action.payload

      }
    }
    case GET_EXPLORE_ADVISOR_BY_PROFESSION_SECTION: {
      let index = state.exploreProfessionAdvisorData.findIndex(data => data.sectionValue === action.payload?.data?.[0]?.sectionValue)
      let newOject = {
        description: state.exploreProfessionAdvisorData[index]?.description,
        limit: 10,
        pageNo: action.payload?.data?.[0]?.pageNo,
        searchString: "",
        section: action.payload?.data?.[0]?.section,
        sectionIndex: action.payload?.data?.[0]?.sectionIndex,
        sectionValue: action.payload?.data?.[0]?.sectionValue,
        total: action.payload?.data?.[0]?.total,
        totalSection: action.payload?.data?.[0]?.totalSection,
        users: [...state.exploreProfessionAdvisorData[index]?.users, ...action.payload?.data?.[0]?.users]
      }
      let updatedItems = [...state.exploreProfessionAdvisorData];
      updatedItems[index] = newOject
      return {
        ...state,
        exploreProfessionAdvisorData : updatedItems
      }
    }
    case GET_EXPLORE_ADVISOR_BY_UNIVERSITY_SECTION : {
      let index = state.exploreUniversityAdvisorData.findIndex(data => data.sectionValue === action.payload?.data?.[0]?.sectionValue)
      let newOject = {
        description: state.exploreUniversityAdvisorData[index]?.description,
        limit: 10,
        pageNo: action.payload?.data?.[0]?.pageNo,
        searchString: "",
        section: action.payload?.data?.[0]?.section,
        sectionIndex: action.payload?.data?.[0]?.sectionIndex,
        sectionValue: action.payload?.data?.[0]?.sectionValue,
        total: action.payload?.data?.[0]?.total,
        totalSection: action.payload?.data?.[0]?.totalSection,
        users: [...state.exploreUniversityAdvisorData[index]?.users, ...action.payload?.data?.[0]?.users]
      }
      let updatedItems = [...state.exploreUniversityAdvisorData];
      updatedItems[index] = newOject
      return {
        ...state,
        exploreUniversityAdvisorData : updatedItems
      }
    }
    
    case  EXPLORE_ADVISOR_BY_UNIVERSITY: {
      return {
        ...state,
        exploreUniversityAdvisorData : action.payload
      }
    }
    case  EXPLORE_ADVISOR_BY_UNIVERSITY_STATUS: {
      return {
        ...state,
        loadUniversityAdvisor : action.payload
      }
    }
    case  EXPLORE_ADVISOR_BY_PROFESSION: {
      return {
        ...state,
        exploreProfessionAdvisorData : action.payload
      }
    }
    case  EXPLORE_ADVISOR_BY_PROFESSION_STATUS: {
      return {
        ...state,
        loadProfessionAdvisor : action.payload
      }
    }
    case  EXPLORE_ADVISOR_BY_COURSES: {
      return {
        ...state,
        exploreCoursesAdvisorData : action.payload
      }
    }
    case GET_EXPLORE_ADVISOR_BY_COURSE_SECTION : {
      let index = state.exploreCoursesAdvisorData.findIndex(data => data.sectionValue === action.payload?.data?.[0]?.sectionValue)
      let newOject = {
        description: state.exploreCoursesAdvisorData[index]?.description,
        limit: 10,
        pageNo: action.payload?.data?.[0]?.pageNo,
        searchString: "",
        section: action.payload?.data?.[0]?.section,
        sectionIndex: action.payload?.data?.[0]?.sectionIndex,
        sectionValue: action.payload?.data?.[0]?.sectionValue,
        total: action.payload?.data?.[0]?.total,
        totalSection: action.payload?.data?.[0]?.totalSection,
        users: [...state.exploreCoursesAdvisorData[index]?.users, ...action.payload?.data?.[0]?.users]
      }
      let updatedItems = [...state.exploreCoursesAdvisorData];
      updatedItems[index] = newOject
      return {
        ...state,
        exploreCoursesAdvisorData : updatedItems
      }
    }
    case  EXPLORE_ADVISOR_BY_COURSES_STATUS: {
      return {
        ...state,
        loadCoursesAdvisor : action.payload
      }
    }
    case  EXPLORE_ADVISOR_BY_ACTIVITY: {
      return {
        ...state,
        exploreActivitiesAdvisorData : action.payload
      }
    }
    case GET_EXPLORE_ADVISOR_BY_ACTIVITY_SECTION : {
      let index = state.exploreActivitiesAdvisorData.findIndex(data => data.sectionValue === action.payload?.data?.[0]?.sectionValue)
      let newOject = {
        description: state.exploreActivitiesAdvisorData[index]?.description,
        limit: 10,
        pageNo: action.payload?.data?.[0]?.pageNo,
        searchString: "",
        section: action.payload?.data?.[0]?.section,
        sectionIndex: action.payload?.data?.[0]?.sectionIndex,
        sectionValue: action.payload?.data?.[0]?.sectionValue,
        total: action.payload?.data?.[0]?.total,
        totalSection: action.payload?.data?.[0]?.totalSection,
        users: [...state.exploreActivitiesAdvisorData[index]?.users, ...action.payload?.data?.[0]?.users]
      }
      let updatedItems = [...state.exploreActivitiesAdvisorData];
      updatedItems[index] = newOject
      return {
        ...state,
        exploreActivitiesAdvisorData : updatedItems
      }
    }
    case  EXPLORE_ADVISOR_BY_ACTIVITY_STATUS: {
      return {
        ...state,
        loadActivitiesAdvisor : action.payload
      }
    }
    // ******************** Explore Students **************************
    case  EXPLORE_STUDENT_BY_UNIVERSITY: {
      return {
        ...state,
        exploreUniversityStudentData : action.payload
      }
    }
    case GET_EXPLORE_STUDENT_BY_UNIVERSITY_SECTION: {
      let index = state.exploreUniversityStudentData?.findIndex(data => data.sectionValue === action.payload?.data?.[0]?.sectionValue)
      let newOject = {
        description: state.exploreUniversityStudentData[index]?.description,
        limit: 10,
        pageNo: action.payload?.data?.[0]?.pageNo,
        searchString: "",
        section: action.payload?.data?.[0]?.section,
        sectionIndex: action.payload?.data?.[0]?.sectionIndex,
        sectionValue: action.payload?.data?.[0]?.sectionValue,
        total: action.payload?.data?.[0]?.total,
        totalSection: action.payload?.data?.[0]?.totalSection,
        users: [...state.exploreUniversityStudentData[index]?.users, ...action.payload?.data?.[0]?.users]
      }
      let updatedItems = [...state.exploreUniversityStudentData];
      updatedItems[index] = newOject
      return {
        ...state,
        exploreUniversityStudentData : updatedItems
      }
    }
    case  EXPLORE_STUDENT_BY_UNIVERSITY_STATUS: {
      return {
        ...state,
        loadUniversityStudent : action.payload
      }
    }
    case  EXPLORE_STUDENT_BY_PROFESSION: {
      return {
        ...state,
        exploreProfessionStudentData : action.payload
      }
    }
    case GET_EXPLORE_STUDENT_BY_PROFESSION_SECTION: {
      let index = state.exploreProfessionStudentData?.findIndex(data => data.sectionValue === action.payload?.data?.[0]?.sectionValue)
      let newOject = {
        description: state.exploreProfessionStudentData[index]?.description,
        limit: 10,
        pageNo: action.payload?.data?.[0]?.pageNo,
        searchString: "",
        section: action.payload?.data?.[0]?.section,
        sectionIndex: action.payload?.data?.[0]?.sectionIndex,
        sectionValue: action.payload?.data?.[0]?.sectionValue,
        total: action.payload?.data?.[0]?.total,
        totalSection: action.payload?.data?.[0]?.totalSection,
        users: [...state.exploreProfessionStudentData[index]?.users, ...action.payload?.data?.[0]?.users]
      }
      let updatedItems = [...state.exploreProfessionStudentData];
      updatedItems[index] = newOject
      return {
        ...state,
        exploreProfessionStudentData : updatedItems
      }
    }
    case  EXPLORE_STUDENT_BY_PROFESSION_STATUS: {
      return {
        ...state,
        loadProfessionStudent : action.payload
      }
    }
    case  EXPLORE_STUDENT_BY_COURSES: {
      return {
        ...state,
        exploreCoursesStudentData : action.payload
      }
    }
    case GET_EXPLORE_STUDENT_BY_COURSE_SECTION: {
      let index = state.exploreCoursesStudentData?.findIndex(data => data.sectionValue === action.payload?.data?.[0]?.sectionValue)
      let newOject = {
        description: state.exploreCoursesStudentData[index]?.description,
        limit: 10,
        pageNo: action.payload?.data?.[0]?.pageNo,
        searchString: "",
        section: action.payload?.data?.[0]?.section,
        sectionIndex: action.payload?.data?.[0]?.sectionIndex,
        sectionValue: action.payload?.data?.[0]?.sectionValue,
        total: action.payload?.data?.[0]?.total,
        totalSection: action.payload?.data?.[0]?.totalSection,
        users: [...state.exploreCoursesStudentData[index]?.users, ...action.payload?.data?.[0]?.users]
      }
      let updatedItems = [...state.exploreCoursesStudentData];
      updatedItems[index] = newOject
      return {
        ...state,
        exploreCoursesStudentData : updatedItems
      }
    }
    case  EXPLORE_STUDENT_BY_COURSES_STATUS: {
      return {
        ...state,
        loadCoursesStudent : action.payload
      }
    }
    case  EXPLORE_STUDENT_BY_ACTIVITY: {
      return {
        ...state,
        exploreActivitiesStudentData : action.payload
      }
    }
    case GET_EXPLORE_STUDENT_BY_ACTIVITY_SECTION: {
      let index = state.exploreActivitiesStudentData?.findIndex(data => data.sectionValue === action.payload?.data?.[0]?.sectionValue)
      let newOject = {
        description: state.exploreActivitiesStudentData[index]?.description,
        limit: 10,
        pageNo: action.payload?.data?.[0]?.pageNo,
        searchString: "",
        section: action.payload?.data?.[0]?.section,
        sectionIndex: action.payload?.data?.[0]?.sectionIndex,
        sectionValue: action.payload?.data?.[0]?.sectionValue,
        total: action.payload?.data?.[0]?.total,
        totalSection: action.payload?.data?.[0]?.totalSection,
        users: [...state.exploreActivitiesStudentData?.[index]?.users, ...action.payload?.data?.[0]?.users]
      }
      let updatedItems = [...state.exploreActivitiesStudentData];
      updatedItems[index] = newOject
      return {
        ...state,
        exploreActivitiesStudentData : updatedItems
      }
    }
    case  EXPLORE_STUDENT_BY_ACTIVITY_STATUS: {
      return {
        ...state,
        loadActivitiesStudent : action.payload
      }
    }
    case  GET_EXPLORE_STUDENT_BY_SECTION: {
      return {
        ...state,
        loadStudentBySection : action.payload
      }
    }
    default:
      return state;
  }
}
