import React, { useEffect, useState } from 'react'
import "./advisor.less";
import { Grid } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import PillSection from "../../../../../componentsNew/Common/PillSection"
import { connect } from "react-redux";
import { saveUserProfile, advisorSaveStatus, getSavedUserProfiles, advisorSaveErrorStatus, clearMessage, unSaveUser, setSavedUserProfileStatus } from '../../../../../redux/actions';
import Loader from '../../../../../components/Loader';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import { useImageUrl } from '../../../../../utils/UseImageURL';

const Index = (props) => {
  const [loading, setLoading] = useState(false)
  const advisorActivities = props?.data?.advisorProfile?.advisorActivities || [];
  const advisorCourses = props?.data?.advisorProfile?.advisorCourses || [];
  const combinedData = [...advisorActivities, ...advisorCourses];

  useEffect(() => {
    if (props?.isUserProfileSaved) {
      props?.advisorSaveStatus(false)
      setLoading(false)
      let payloads = {
        "userRole": "advisor"
      }
      props?.getSavedUserProfiles(payloads)
    }
    if (props?.savedAdvisorError) {
      props?.advisorSaveErrorStatus(false)
      setLoading(false)
      props?.clearMessage()
    }
    if (props?.isUserProfileSaved) {
      setLoading(false)
      props?.advisorSaveStatus(false);
    }
  }, [props?.isUserProfileSaved, props?.savedAdvisorError,])

  const mappedData = combinedData.map(item => {
    if (item.activityName) {
      return item.activityName;
    } else if (item.courseName) {
      return item.courseName;
    }
    return null;
  });

  const handleSaveProfile = () => {
    setLoading(true)
    let payload = {
      "userId": props?.data?.id
    }
    props?.saveUserProfile(payload)
  }
  const handleUnSaveProfile = () => {
    setLoading(true)
    let payload = {
      "userId": props?.data?.id
    }
    props?.unSaveUser(payload)
  }
  const imgUrl = useImageUrl(props?.data?.avatarPath)
  return (
  

      <div className='exploreAdvisor'>
        <p className="advisorEyebrow" >
          {props?.data?.matchingParams && props?.data?.matchingParams[0]}
        </p>
        <div className='advisorCard '>
          <Grid container spacing={2}>
            <Grid item xs={3} >
              {
                props?.data?.avatarPath ? (
                  <img className="profile-img" style={{ "width": "60px", "height": "60px", borderRadius: "6px" }}
                    src={imgUrl} alt="userprofile"
                  />
                ) :
                  <PersonIcon color="action" sx={{ border: '1px solid grey', borderRadius: "6px", fontSize: '1rem', "width": "60px", "height": "60px !important" }} />
              }
            </Grid>
            <Grid item xs={6} className="owlTitle">
              <p className="profile-heading" style={{ "marginBottom": "2px", "fontSize": "16px", "fontWeight": "500" }} >{props?.data?.fullName}</p>
              <p className="advisor-country" style={{ "marginBottom": "0px" }} > {props?.data?.country}
              </p>
            </Grid>
            <Grid item xs={3} className="owlTitle" style={{ "alignItems": "center" }} >
              <p className="rating-heading" style={{ "marginBottom": "0px" }} >
                <StarBorderIcon sx={{ fontSize: '15px', fill: '#E64A19', }} /><span style={{ "marginLeft": "5px" }} >{props?.data?.advisorProfile?.advisorReviewSummary?.averageRating.toFixed(1) || 0}{"/5.0"}</span>
              </p>
            </Grid>
            <Grid container justifyContent="flex-end">
              {/* {props?.loadSavedUsers?.map(user => user?.id)?.includes(props?.data?.id) ? (
             
                <BookmarkOutlinedIcon style={{ "cursor": "pointer" }} onClick={handleUnSaveProfile}/> 
              ) : (

                 <BookmarkBorderOutlinedIcon onClick={handleSaveProfile} style={{ "cursor": "pointer" }}/>
              )} */}
                <div className='bookmark' style={{marginLeft: 'auto', cursor: "pointer"}} >{props?.saveProfileIcon}</div>
            </Grid>
          </Grid>
          <Grid className='mt-2'>
            {props?.data?.advisorProfile?.currentlyIn === "highschool" && (
              <p className='advisor-subText'>  <HomeOutlinedIcon sx={{ fontSize: '14px', fill: "#212224" }} /> Attending  {props?.data?.advisorProfile?.highschoolName}</p>
            )}
            {props?.data?.advisorProfile?.currentlyIn === "university" && (
              <>
                <p className='advisor-subText'>  <SchoolOutlinedIcon sx={{ fontSize: '14px', fill: "#212224" }} /> Attending  {props?.data?.advisorProfile?.universityName}</p>
              </>
            )}
            {props?.data?.advisorProfile?.currentlyIn === "professional" && (
              <>
                <p className='advisor-subText'>  <WorkOutlineOutlinedIcon sx={{ fontSize: '14px', fill: "#212224" }} /> Professional  {props?.data?.advisorProfile?.targetProfession}</p>
              </>
            )}
          </Grid>
          <Grid className='mt-2 '>
            <p className="profile-heading" style={{ "marginBottom": "5px", "fontSize": "16px" }} >{"Services offered"}</p>
            <div className='advisor-card-content'>
              <p className='advisor-subText'>  <StarRoundedIcon sx={{ fill: "#1C84EE", fontSize: '17px', }} />{props?.data?.advisorProfile?.noOfFreeConsultation} free session</p>
              {props?.data?.advisorProfile?.helpWithPlan === true && (<p className='advisor-subText'>  <StarRoundedIcon sx={{ fill: "#1C84EE", fontSize: '17px' }} />  Create and manage plan</p>)}
              <p className='advisor-subText'>  <StarRoundedIcon sx={{ fill: "#1C84EE", fontSize: '17px' }} /> Mentoring for</p>
              <PillSection data={mappedData} maxWidth={150} maxHeight={80} />
            </div>
          </Grid>
          <Grid>
            {props?.children}
          </Grid>
        </div>
      </div>

  )
}

const mapStateToProps = ({ commonData }) => {
  const { isUserProfileSaved, savedAdvisorError, loadSavedUsers, loadSavedUserStatus } = commonData;
  return { isUserProfileSaved, savedAdvisorError, loadSavedUsers, loadSavedUserStatus };
};
export default connect(mapStateToProps, { saveUserProfile, advisorSaveStatus, getSavedUserProfiles, setSavedUserProfileStatus, advisorSaveErrorStatus, clearMessage, unSaveUser })(Index);