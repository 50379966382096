
import {
  COPY_INVITE_LINK, SENT_INVITE_STATUS, SET_ISGOAL_SUCCESS_STATUS, VERIFY_INVITES_STATUS, CREATE_PLAN_STATUS,GET_STUDENT_PLAN_ERROR_STATUS,
  CREATE_PLAN_PHASE_STATUS, GET_STUDENT_PLAN, GET_STUDENT_PLAN_PHASE, Edit_PLAN_STATUS, GET_STUDENT_PLAN_PHASE_STATUS,
  GET_STUDENT_PLAN_DATA_ID, GET_STUDENT_PLAN_DATA_ID_STATUS, GET_STUDENT_PLAN_LOAD, GET_STUDENT_PLAN_LOAD_STATUS,
  GET_STUDENT_PLAN_DATA_ID_NEXT, GET_STUDENT_PLAN_DATA_ID_NEXT_STATUS, GET_COURSE_LIST, GET_COURSE_LIST_STATUS, GET_MILESTONE_LIST, GET_MILESTONE_STATUS, GET_STUDENT_PLAN_STATUS,
  GET_USER_GOALS_STATUS, GET_USER_GOALS, GET_ACTIVITY_LIST_STATUS, GET_ACTIVITY_LIST, CREATE_PLAN_COURSE, GET_INVITE_ADVISOR, EDIT_PLAN_COURSE, EDIT_PLAN_COURSE_ERROR, CREATE_PLAN_ACTIVITY,
  EDIT_PLAN_ACTIVITY, EDIT_PLAN_PHASE, DELETE_PLAN_PHASE, DELETE_PLAN_COURSE, DELETE_PLAN_ACTIVITY, EDIT_PLAN_PHASE_ERROR, INVITE_ADVISOR_BY,
  GET_INVITE_ADVISOR_STATUS, INVITE_ADVISOR_ERROR, CREATE_PLAN_PHASE_STATUS_ERROR, GET_CONNECTED_PARENT, NEW_PLAN_ID, NEW_PARENT_PLAN_ID, GET_PHASE_COURSE_ID, GET_PHASE_COURSE_ID_STATUS,
  GET_PHASE_ACTIVITY_ID_STATUS, GET_PHASE_ACTIVITY_ID, GET_MILESTONE_BY_COURSE, GET_MILESTONE_BY_COURSE_STATUS, CREATE_COURSE_MILESTONE, UPDATE_MILESTONE_NOTES, GET_MILESTONE_TYPE, CREATE_COURSE_MILESTONE_STATUS, UPDATE_COURSE_MILESTONE, UPDATE_COURSE_MILESTONE_STATUS,
  DELETE_MILESTONE, EDIT_MILESTONE_NOTES, DELETE_MILESTONE_NOTES, GET_MILESTONE_BY_ACTIVITY, GET_MILESTONE_BY_ACTIVITY_STATUS, CREATE_ACTIVITY_MILESTONE, UPDATE_MILESTONE_RESOURCE, DELETE_MILESTONE_RESOURCE, ADD_NEW_MILESTONE_RESOURCE, GET_COURSE_BY_ID, GET_COURSE_BY_ID_STATUS, READ_UPDATE_STATUS,
  CREATE_PLAN_COURSE_ERROR, CREATE_PLAN_ACTIVITY_ERROR,ADD_RESOURCE_ERROR, GET_PLAN_STATUS, DELETE_PLAN,ERROR_DELETE_PLAN, UPDATE_PLAN_STATUS,  UPDATE_PHASE_STATUS, MILESTONE_STATUS_UPDATE,PHASE_ERROR,UPDATE_PLAN_ERROR_STATUS, REPLY_MILESTONE_NOTES, UPDATE_MILESTONE_ERROR,
  PLAN_ADVISOR_STATUS,PLAN_ADVISOR_DATA, STUDENT_COURSE_DATA, STUDENT_COURSE_STATUS,PLAN_APPROVE_STATUS,PLAN_APPROVE_ERROR, COURSE_APPROVE_STATUS, COURSE_APPROVE_ERROR,PLAN_ACESS_ADVISOR_ERROR,PLAN_ACESS_ADVISOR,ADD_PLAN_ADVISOR_ERROR,ADD_PLAN_ADVISOR,REMOVE_PLAN_ADVISOR_ERROR,REMOVE_PLAN_ADVISOR,
  PLAN_REJECT_STATUS, PLAN_ACESS_STUDENT_STATUS, PLAN_ACESS_STUDENT,  PLAN_ACESS_STUDENT_ERROR, PLAN_CONFIRM_DROP_STATUS, PLAN_CONFIRM_DROP_ERROR, ACTIVITIES_APPROVE_STATUS, ACTIVITIES_APPROVE_ERROR, COURSE_REJECT_STATUS, ACTIVITY_REJECT_STATUS,
  COURSE_DROP_ERROR_STATUS,COURSE_DROP_STATUS,ACTIVITY_DROP_STATUS,ACTIVITY_DROP_ERROR_STATUS, RESET_STUDENT_PLAN, GET_STUDENT_PLAN_PAGINATED, 
  GET_COURSE_LIST_PAGINATED,
  GET_DASHBOARD_COUNT_STATUS,
  GET_DASHBOARD_COUNT_ERROR_STATUS,
  GET_DASHBOARD_COUNT,
  GET_EXPLORE_ADVISOR_COUNT_FOR_STUDENT,
  GET_RESET_COUNTS_STUDENT,
  UPDATES_VIEW_STUDENT_COUNT} from "../constants/StudentActionTypes"

const INIT_STATE = {
  pagination: {},
  isGoalSuccess: false,
  isInviteSentSucess: false,
  isInvitelink: '',
  inviteProfile: {},
  iscreateplan: false,
  iscreateplanphase: false,
  isCourseCreated: false,
  iseditplanphase: false,
  isgetplanphase: false,
  isEditCourse: false,
  isActivityCreated: false,
  isEditActivity: false,
  isEditPhase: false,
  isPhaseDeleted: false,
  isCourseDeleted: false,
  isActivityDeleted: false,
  isInviteAdvisor: false,
  invitedAdvisorList: [],
  isInviteAdvisorerror: false,
  iscreatephaseerror: false,
  getstudentplan: [],
  courseDataById: [],
  courseDataByIdStatus: false,
  newplanid: "",
  parentPlanId: "",
  createCourseMilestoneStatus: false,
  mileCourseUpdata: false,
  isCourseMileStone: [],
  isMileStoneCourseStatus: false,
  isUpdateNotes: false,
  milestoneTypes: [],
  isMilestoneDeleted: false,
  editNotes: false,
  deleteNotes: false,
  createActivityMile: false,
  activityMilestone: [],
  isactivityMilestoneStatus: false,
  isUpdateResource: false,
  isDeleteResource: false,
  isCourseError: false,
  isAddCourseError: false,
  isActivityError: false,
  isMilestoneError: false,
  planStatus: [],
  isPlanDeleted: false,
  iserrorPlanDeleted: false,
  isPlanStatusUpdate: false,
  isPhaseStatusUpdate: false,
  isMileStatus: false,
  isphaseerror: false,
  isPlanStatusError: false,
  isReplyNotes: false,
  notesError: false,
  advisorlistdatastatus: false,
  studentDetails: [],
  studentDetailsStatus: false,
  planapproveerror: false,
  planapprove: false,
  deleteplan: false,
  deleteplanerror: false,
  isCourseApprove: false,
  accessbleAdvisor: [],
  accessbleAdvisorStatus: false,
  accessbleAdvisorError: false,
  addAdvisorPlanError: false,
  addAdvisorPlan: false,
  removeAdvisorPlan: false,
  removeAdvisorPlanError: false,
  accessbleStudent: [],
  accessbleStudentStatus: false,
  accessbleStudentError: false,
  isPlanRejected : false,
  isDropPlan : false,
  isDropPlanError : false,
  isActivityAproved : false,
  isCourseRejected: false,
  isactivityRejected : false,
  courseDropStatus: false,
  courseDropError: false,
  isActivityAprovedError: false,
  activityDropStatus: false,
  activityDropError: false,
  getplancourseliststatus: false,
  dashboardCount : [],
  studentplanError: false,
  updatesStudentViewCount : []
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_USER_GOALS: {
      return {
        ...state,
        isgetusergoals: action.payload
      }
    }
    case GET_USER_GOALS_STATUS: {
      return {
        ...state,
        isgetusergoalsstatus: action.payload
      }
    }
    case SET_ISGOAL_SUCCESS_STATUS: {
      return {
        ...state,
        isGoalSuccess: action.payload,
      };
    }
    case SENT_INVITE_STATUS: {
      return {
        ...state,
        isInviteSentSucess: action.payload,
      };
    }
    case VERIFY_INVITES_STATUS: {
      return {
        ...state,
        isInvitelink: action.payload,
      };
    }
    case CREATE_PLAN_STATUS: {
      return {
        ...state,
        iscreateplan: action.payload,
      };
    }
    case NEW_PLAN_ID: {
      return {
        ...state,
        newplanid: action.payload
      }
    }
    case NEW_PARENT_PLAN_ID: {
      return {
        ...state,
        parentPlanId: action.payload
      }
    }
    case CREATE_PLAN_PHASE_STATUS: {
      return {
        ...state,
        iscreateplanphase: action.payload,
      };
    }
    case CREATE_PLAN_PHASE_STATUS_ERROR: {
      return {
        ...state,
        iscreatephaseerror: action.payload,
      }
    }
    case CREATE_PLAN_COURSE: {
      return {
        ...state,
        isCourseCreated: action.payload,
      };
    }
    case GET_STUDENT_PLAN: {
      console.log(action.payload?.data,"action.payload?.data");
      
      return {
        ...state,
        getstudentplan: [...action.payload?.data],
        pagination: action?.payload
      };
    }
    case GET_STUDENT_PLAN_ERROR_STATUS:{
      return {
        ...state,
        studentplanError: action?.payload,
      };
    }
    case GET_STUDENT_PLAN_PAGINATED: {
      
      console.log("GET_STUDENT_PLAN_ERROR_STATUS",action?.payload)
      return {
        ...state,
        getstudentplan: [...state.getstudentplan, ...action.payload?.data],
        pagination: action?.payload
      };
    }
    case RESET_STUDENT_PLAN: {
      return {
        ...state,
        getstudentplan: [],
        pagination: {}
      };
    }
    case GET_STUDENT_PLAN_STATUS: {
      return {
        ...state,
        studentplanstatus: action.payload,
      };
    }
    case GET_STUDENT_PLAN_PHASE: {
      return {
        ...state,
        getstudentplanphase: action.payload,
      };
    }
    case Edit_PLAN_STATUS: {
      return {
        ...state,
        iseditplanphase: action.payload,
      }
    }
    case GET_STUDENT_PLAN_PHASE_STATUS: {
      return {
        ...state,
        isgetplanphase: action.payload,
      }
    }
    case GET_STUDENT_PLAN_DATA_ID: {
      return {
        ...state,
        getplanid: action.payload,
      }
    }
    case GET_STUDENT_PLAN_DATA_ID_STATUS: {
      return {
        ...state,
        getplanidstatus: action.payload,
      }
    }
    case GET_STUDENT_PLAN_LOAD: {
      return {
        ...state,
        getplanphase: action.payload,
      }
    }
    case GET_STUDENT_PLAN_LOAD_STATUS: {
      return {
        ...state,
        getplanphasestatus: action.payload,
      }
    }
    case GET_STUDENT_PLAN_DATA_ID_NEXT: {
      return {
        ...state,
        getnextplan: action.payload,
      }
    }
    case GET_STUDENT_PLAN_DATA_ID_NEXT_STATUS: {
      return {
        ...state,
        getnextplanstatus: action.payload,
      }
    }
    case GET_COURSE_LIST: {
      return {
        ...state,
        getplancourselist: [...action.payload?.data],
        pagination: action?.payload
      }
    }
    case GET_COURSE_LIST_PAGINATED: {
      return {
        ...state,
        // getplancourselist: [...state.getplancourselist, ...action?.payload?.data],
        getplancourselist: action?.payload?.data,
        pagination: action?.payload
      }
    }
    case GET_COURSE_LIST_STATUS: {
      console.log(action.payload, "action.payload")
      return {
        ...state,
        getplancourseliststatus: action.payload,
      }
    }
    case GET_MILESTONE_LIST: {
      return {
        ...state,
        getmilestoneData: action.payload,
      }
    }
    case GET_MILESTONE_STATUS: {
      return {
        ...state,
        getmilestoneliststatus: action.payload,
      }
    }
    case GET_ACTIVITY_LIST: {
      return {
        ...state,
        getactivitylist: action.payload,
      }
    }
    case GET_ACTIVITY_LIST_STATUS: {
      return {
        ...state,
        getactivityliststatus: action.payload,
      }
    }
    case EDIT_PLAN_COURSE: {
      return {
        ...state,
        isEditCourse: action.payload,
      }
    }
    case EDIT_PLAN_COURSE_ERROR: {
      return {
        ...state,
        isEditCourseError: action.payload,
      }
    }
    case CREATE_PLAN_ACTIVITY: {
      return {
        ...state,
        isActivityCreated: action.payload,
      };
    }
    case EDIT_PLAN_ACTIVITY: {
      return {
        ...state,
        isEditActivity: action.payload,
      }
    }
    case EDIT_PLAN_PHASE: {
      return {
        ...state,
        isEditPhase: action.payload,
      }
    }
    case EDIT_PLAN_PHASE_ERROR: {
      return {
        ...state,
        isEditPhaseerror: action.payload,
      }
    }
    case DELETE_PLAN: {
      return {
        ...state,
        deleteplan: action.payload,
      };
    }
    case ERROR_DELETE_PLAN: {
      return {
        ...state,
        deleteplanerror: action.payload,
      };
    }

    case DELETE_PLAN_PHASE: {
      return {
        ...state,
        isPhaseDeleted: action.payload,
      };
    }

    case DELETE_PLAN_COURSE: {
      return {
        ...state,
        isCourseDeleted: action.payload,
      };
    }

    case DELETE_PLAN_ACTIVITY: {
      return {
        ...state,
        isActivityDeleted: action.payload,
      };
    }

    case INVITE_ADVISOR_BY: {
      return {
        ...state,
        isInviteAdvisor: action.payload,
      };
    }
    case INVITE_ADVISOR_ERROR: {
      return {
        ...state,
        isInviteAdvisorerror: action.payload,
      };
    }
    case GET_INVITE_ADVISOR: {
      return {
        ...state,
        invitedAdvisorList: action.payload,
      };
    }
    case GET_INVITE_ADVISOR_STATUS: {
      return {
        ...state,
        invitedAdvisorListstatus: action.payload,
      };
    }
    case GET_CONNECTED_PARENT: {
      return {
        ...state,
        connectedparent: action.payload,
      }
    }
    case GET_PHASE_COURSE_ID: {
      return {
        ...state,
        getphasecourse: action.payload,
      }
    }
    case GET_PHASE_COURSE_ID_STATUS: {
      return {
        ...state,
        getphasecoursestatus: action.payload,
      }
    }
    case GET_PHASE_ACTIVITY_ID: {
      return {
        ...state,
        getphaseactivity: action.payload,
      }
    }
    case GET_PHASE_ACTIVITY_ID_STATUS: {
      return {
        ...state,
        getphaseactivitystatus: action.payload,
      }
    }
    case CREATE_COURSE_MILESTONE: {
      return {
        ...state,
        createCourseMilestoneStatus: action.payload,
      }
    }
    case CREATE_COURSE_MILESTONE_STATUS: {
      return {
        ...state,
        createCourseMilestoneStatus: action.payload,
      }
    }
    case UPDATE_COURSE_MILESTONE: {
      return {
        ...state,
        mileCourseUpdata: action.payload,
      }
    }
    case UPDATE_COURSE_MILESTONE_STATUS: {
      return {
        ...state,
        mileCourseUpdata: action.payload,
      }
    }
    case GET_MILESTONE_BY_COURSE: {
      return {
        ...state,
        isCourseMileStone: action.payload,
      }
    }
    case GET_MILESTONE_BY_COURSE_STATUS: {
      return {
        ...state,
        isMileStoneCourseStatus: action.payload,
      }
    }
    case UPDATE_MILESTONE_NOTES: {
      return {
        ...state,
        isUpdateNotes: action.payload,
      }
    }
    case GET_MILESTONE_TYPE: {
      return {
        ...state,
        milestoneTypes: action.payload,
      }
    }
    case GET_COURSE_BY_ID: {
      return {
        ...state,
        courseDataById: action.payload,
      }
    }
    case GET_COURSE_BY_ID_STATUS: {
      return {
        ...state,
        courseDataByIdStatus: action.payload,
      }
    }
    case DELETE_MILESTONE: {
      return {
        ...state,
        isMilestoneDeleted: action.payload,
      };
    }
    case EDIT_MILESTONE_NOTES: {
      return {
        ...state,
        editNotes: action.payload,
      };
    }
    case READ_UPDATE_STATUS: {
      return {
        ...state,
        viewedUpdate: action.payload,
      };
    }

    case DELETE_MILESTONE_NOTES: {
      return {
        ...state,
        deleteNotes: action.payload,
      };
    }

    case GET_MILESTONE_BY_ACTIVITY: {
      return {
        ...state,
        activityMilestone: action.payload,
      };
    }

    case GET_MILESTONE_BY_ACTIVITY_STATUS: {
      return {
        ...state,
        isactivityMilestoneStatus: action.payload,
      };
    }

    case CREATE_ACTIVITY_MILESTONE: {
      return {
        ...state,
        createActivityMile: action.payload,
      };
    }

    case ADD_NEW_MILESTONE_RESOURCE: {
      return {
        ...state,
        isaddNewResource: action.payload,
      };
    }
    case UPDATE_MILESTONE_RESOURCE: {
      return {
        ...state,
        isUpdateResource: action.payload,
      };
    }

    case DELETE_MILESTONE_RESOURCE: {
      return {
        ...state,
        isDeleteResource: action.payload,
      };
    }

    case CREATE_PLAN_COURSE_ERROR: {
      return {
        ...state,
        isAddCourseError: action.payload,
      };
    }
    case CREATE_PLAN_ACTIVITY_ERROR: {
      return {
        ...state,
        isActivityError: action.payload,
      };
    }
    case ADD_RESOURCE_ERROR: {
      return {
        ...state,
        isMilestoneError: action.payload,
      }
    }
    case GET_PLAN_STATUS: {
      return {
        ...state,
        planStatus: action.payload,
      }
    }
    case DELETE_PLAN: {
      return {
        ...state,
        isPlanDeleted: action.payload
      }
    }
    case ERROR_DELETE_PLAN: {
      return {
        ...state,
        iserrorPlanDeleted: action.payload
      }
    }
    case UPDATE_PLAN_STATUS: {
      return {
        ...state,
        isPlanStatusUpdate: action.payload
      }
    }
    case UPDATE_PHASE_STATUS: {
      return {
        ...state,
        isPhaseStatusUpdate: action.payload
      }
    }
    case PHASE_ERROR: {
      return {
        ...state,
        isphaseerror: action.payload
      }
    }
    case MILESTONE_STATUS_UPDATE: {
      return {
        ...state,
        isMileStatus: action.payload
      }
    }
    case UPDATE_PLAN_ERROR_STATUS: {
      return {
        ...state,
        isPlanStatusError: action.payload
      }
    }
    case REPLY_MILESTONE_NOTES: {
      return {
        ...state,
        isReplyNotes: action.payload
      }
    }
    case UPDATE_MILESTONE_ERROR: {
      return {
        ...state,
        notesError: action.payload
      }
    }
    case PLAN_ADVISOR_DATA: {
      return {
        ...state,
        advisorlistdata: action.payload
      }
    }
    case PLAN_ADVISOR_STATUS: {
      return {
        ...state,
        advisorlistdatastatus: action.payload
      }
    }
    case STUDENT_COURSE_DATA: {
      return {
        ...state,
        studentDetails: action.payload
      }
    }
    case STUDENT_COURSE_STATUS: {
      return {
        ...state,
        studentDetailsStatus: action.payload
      }
    }
    case PLAN_APPROVE_STATUS: {
      return {
        ...state,
        planapprove: action.payload
      }
    }
    case PLAN_APPROVE_ERROR: {
      return {
        ...state,
        planapproveerror: action.payload
      }
    }

    case COURSE_APPROVE_STATUS: {
      return {
        ...state,
        isCourseApprove: action.payload
      }
    }
    case COURSE_APPROVE_ERROR: {
      return {
        ...state,
        isCourseError: action.payload
      }
    }
    case PLAN_ACESS_ADVISOR: {
      return {
        ...state,
        accessbleAdvisor: action.payload,
        accessbleAdvisorStatus: action.status

      }
    }
    case PLAN_ACESS_ADVISOR_ERROR: {
      return {
        ...state,
        accessbleAdvisorError: action.payload
      }
    }
    case ADD_PLAN_ADVISOR: {
      return {
        ...state,
        addAdvisorPlan: action.payload,
      }
    }
    case ADD_PLAN_ADVISOR_ERROR: {
      return {
        ...state,
        addAdvisorPlanError: action.payload
      }
    }
    case REMOVE_PLAN_ADVISOR: {
      return {
        ...state,
        removeAdvisorPlan: action.payload
      }
    }
    case REMOVE_PLAN_ADVISOR_ERROR: {
      return {
        ...state,
        removeAdvisorPlanError: action.payload
      }
    }
    case PLAN_ACESS_STUDENT_ERROR: {
      return {
        ...state,
        removeAdvisorPlanError: action.payload
      }
    }
    case PLAN_ACESS_STUDENT: {
      return {
        ...state,
        accessbleStudent: action.payload,

      }
    }
    case PLAN_ACESS_STUDENT_STATUS: {
      return {
        ...state,
        accessbleStudentStatus: action.status
      }
    }
    case PLAN_ACESS_STUDENT_ERROR: {
      return {
        ...state,
        accessbleStudentError: action.payload
      }
    }
    case PLAN_REJECT_STATUS: {
      return {
        ...state,
        isPlanRejected: action.payload
      }
    }
    case PLAN_CONFIRM_DROP_STATUS: {
      return {
        ...state,
        isDropPlan: action.payload
      }
    }
    case PLAN_CONFIRM_DROP_ERROR: {
      return {
        ...state,
        isDropPlanError: action.payload
      } 
    }
    case ACTIVITIES_APPROVE_STATUS : {
      return {
        ...state,
        isActivityAproved: action.payload
      } 
    }
    case ACTIVITIES_APPROVE_ERROR : {
      return {
        ...state,
        isActivityAprovedError: action.payload
      } 
    }
    case COURSE_REJECT_STATUS : {
      return {
        ...state,
        isCourseRejected: action.payload
      } 
    }
    case ACTIVITY_REJECT_STATUS : {
      return {
        ...state,
        isactivityRejected: action.payload
      } 
    }
    case COURSE_DROP_STATUS : {
      return {
        ...state,
        courseDropStatus: action.payload
      } 
    }
    case COURSE_DROP_ERROR_STATUS : {
      return {
        ...state,
        courseDropError: action.payload
      } 
    }
    case ACTIVITY_DROP_STATUS : {
      return {
        ...state,
        activityDropStatus: action.payload
      } 
    }
    case ACTIVITY_DROP_ERROR_STATUS : {
      return {
        ...state,
        activityDropError: action.payload
      } 
    }
    case GET_DASHBOARD_COUNT : {
      console.log(action.payload,"action.payload");
      return {
        ...state,
        dashboardCount: action.payload
      } 
    }
    case GET_DASHBOARD_COUNT_STATUS : {
      console.log(action.payload,"action.payload");
      return {
        ...state,
        dashboardCountStatus: action.payload
      } 
    }
    case GET_DASHBOARD_COUNT_ERROR_STATUS : {
      return {
        ...state,
        dashboardCountErrorStatus: action.payload
      } 
    }
    case GET_EXPLORE_ADVISOR_COUNT_FOR_STUDENT : {
      return {
        ...state,
        adviserCountForStudent: action.payload
      } 
    }
    case GET_RESET_COUNTS_STUDENT : {
      return {
        ...state,
        resetCountWhileLogout: action.payload
      } 
    }
    case UPDATES_VIEW_STUDENT_COUNT : {
      console.log(action.payload,"action.payload");
      
      return {
        ...state,
        updatesStudentViewCount: action.payload
      } 
    }
    default:
      return state;
  }

}