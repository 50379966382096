import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Button, Modal } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import Select, { components } from "react-select";
import CreatableSelect from 'react-select/creatable';
import { withRouter } from "../../../../../redux/store/navigate";
import { useForm, Controller, useFormState } from "react-hook-form";
import { ACTIVITY_NAME_LABEL, ACTIVITY_TYPE_LABEL } from "../../../../../Constants/commonLabels";
import { yupResolver } from "@hookform/resolvers/yup";
import Loader from "../../../../../components/Loader";
import { CreateActivity, clearMessage, clearErrorList } from '../../../../../redux/actions'
import { ALERT_HIDE } from "../../../../../redux/constants/CommonTypes";
import Alert from 'react-bootstrap/Alert';
import ReactQuill from "react-quill";
import CustomAlert from "../../../../../components/CustomAlert";

const validation = Yup.object({
    activityName: Yup.object().required("*Required").nullable(),
    activityType: Yup.object().required("*Required").nullable(),
    gradeAchieved: Yup.object().nullable(),
    semester: Yup.object().required("*Required").nullable(),
    description: Yup.string().transform((value) => {
        return value.replace(/<[^>]+>/g, '').replace(/\&nbsp;+/g, ' ')
    }).max(750, "Description should not exceed 750 characters").nullable()
})

const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder}</Placeholder>
            {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
        </ValueContainer>
    );
};

const Index = (props) => {
    const [loading, setLoading] = useState(false)
    const [errorList, setErrorList] = useState([])
    const [activityNameOpts, setActivityNameOpts] = useState([])
    const [activityTypeOpts, setActivityTypeOpts] = useState([])
    const [gradeOpts, setGradeOpts] = useState([])
    const [semesterOpts, setSemesterOpts] = useState([]);

    const dispatch = useDispatch()

    const { handleSubmit, watch, reset, control, formState: { errors }, setValue, clearErrors } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(
            validation
        ),
    });
    const { dirtyFields } = useFormState({
        control
    });

    let descLength = watch("description")
    let activityTypeObserver = watch("activityType")
    let activityNameObserver = watch("activityName")
    const selectedSemester = watch("semester");
    let currentSemester = props?.getstudentplan[0]?.planInfo?.activePhaseId === selectedSemester?.value

    useEffect(() => {
        setInitialValues()
        props?.clearMessage()
        
        return (() => {
            reset();
            props?.clearMessage()
            props?.clearErrorList()
        })
    }, [])
    const filterObjectCourse = (title, options, value) => {
        if (value === "" || value === undefined) return;
        let filtered =
            options &&
            options?.filter(
                (options) => options?.activityName?.toString() === value?.toString()
            );
        if (filtered && filtered[0]) {
            setValue(title, { label: filtered[0]?.activityName, value: filtered[0]?.activityName });
        } else {
            setValue(title, { label: value, value: value })
        }
    };

    const filterObjectType = (title, options, value) => {
        if (value === "" || value === undefined) return;
        let filtered = options?.filter(
            (options) => options?.activityType?.toString() === value?.toString()
        );
        if (filtered && filtered[0]) {
            setValue(title, { label: filtered[0]?.activityType, id: filtered[0]?.id });
        } else {
            setValue(title, { label: value?.label, value: value?.value })
        }
    };

    const filterObjectGrade = (title, options, value) => {
        if (value === "" || value === undefined) return;
        let filtered = options?.filter(
            (options) => options?.grade?.toString() === value?.toString()
        );
        if (filtered && filtered[0]) {
            setValue(title, { label: filtered[0]?.grade, value: filtered[0]?.id });
        } else {
            setValue(title, { label: value?.label, value: value?.value })
        }
    };
    const filterObject = (title, options, value, phaseId) => {
        if (value === "" || value === undefined) return;
        let filtered = options?.filter((option) => option?.label?.toString() === value?.toString());
        if (filtered[0]) {
            setValue(title, { label: filtered[0].label, value: filtered[0].value, isPassed: filtered[0]?.isPassed });
        } else {
            setValue(title, { label: value, value: phaseId?.id, isPassed: phaseId?.isPassed  });
        }
    };
    useEffect(() => {
        if (props?.EditData) {
            filterObjectCourse("activityName", props?.activityNames, props?.EditData?.activityName);
            filterObjectType("activityType", props?.activity, props?.EditData?.activityType);
            filterObjectGrade("gradeAchieved", props?.studentGrades, props?.EditData?.grade);
            setValue("description", props?.EditData?.description)

            let activityNameOptions = [];
            let activityTypeObserver = watch("activitytype")

            props?.activityNames?.length > 0 && props?.activityNames?.filter(x => x?.activityTypeId === activityTypeObserver?.id).map((x, key) => {
                let temp = { label: x.activityName, value: x.activityName }
                activityNameOptions.push(temp);
            })
            setActivityNameOpts(activityNameOptions)

        }
        
        if (semesterOpts != undefined) {
            filterObject("semester", semesterOpts, props?.semesterData?.title, props?.semesterData);
        }
    }, [props?.EditData])
    useEffect(() => {
        setInitialValues()
        if (activityTypeObserver && dirtyFields.activityType && activityNameObserver) {
            setValue('activityName', null)
        }
    }, [activityTypeObserver])

    useEffect(() => {
        clearErrors()
        if (errorList) {
            setErrorList([])
        }
    }, [activityTypeObserver, activityNameObserver])

    useEffect(() => {
        if (props?.errorList !== errorList) {
            setErrorList(props?.errorList)
        } else {
            setErrorList([])
        }

    }, [props?.errorList])

    const hideModel = (e) => {
        reset()
        props.clearMessage()
        dispatch({ type: ALERT_HIDE })
        setErrorList([])
        props?.hideModel();
    }

    const setInitialValues = () => {
        let activityNameOptions = [];
        let activityTypeOptions = [];
        let semOptions = [];
        let gradeOptions = [];

        if (props?.errorList?.length === 0) {
            setErrorList([])
        }
        props?.activityNames?.length > 0 && props?.activityNames?.filter(x => x?.activityTypeId === activityTypeObserver?.id).map((x, key) => {
            let temp = { label: x.activityName, value: x.activityName }
            activityNameOptions.push(temp);
        })
        props?.activity?.length > 0 && props?.activity?.map((x) => {
            let temp = { label: x.activityType, value: x.activityType, id: x.id }
            activityTypeOptions.push(temp);
        })
        props?.getphasetime?.length > 0 && props?.getphasetime?.map((x) => {
            let temp = { label: x.phaseTitle, value: x.phaseId, isPassed: x.isPassed}
            if (props?.userProfile?.currentRole === "advisor") {
                if (!x.isPassed) {
                    semOptions.push(temp);
                }
            } else {
                semOptions.push(temp);
            }
        })
        props?.studentGrades?.map(x => {
            let temp = { label: x.grade, value: x.grade }
            gradeOptions.push(temp);
        })
        setActivityNameOpts(activityNameOptions)
        setActivityTypeOpts(activityTypeOptions)
        setSemesterOpts(semOptions)
        setGradeOpts(gradeOptions)
    }

    const onSubmit = (data) => {
        let { activityName, activityType, gradeAchieved, description } = data;
        let payload = {
            accessibleAdvisors: [],
            activityName: activityName.value,
            activityType: activityType?.label,
            description: description,
            grade: gradeAchieved?.value,
            phaseId: data?.semester?.value,
            planId: props?.semData?.planId
        };
        props?.handleSubmit(payload);
    }


    return (
        <Modal size={'md'} show={props?.showAddActivity} onHide={(e) => hideModel(e)} centered={true} dialogclassname={"mywork-popup"} style={{ "zIndex": "2000", backgroundColor: "rgb(0,0,0, 0.5)" }}>
            {Object.keys(props?.errorList) == 0 &&
                <div style={{ "width": "100%", "display": "flex", "justifyContent": "center" }}>
                    <CustomAlert />
                </div>
            }
            <Modal.Header closeButton>
                <Modal.Title>{Object.keys(props?.EditData || {})?.length > 0 ? 'Edit Activity' : 'Add Activity'}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {loading && <Loader />}
                {(loading || props?.loading) && <Loader />}
                {props.alertShow ?
                    ((!props?.AddActivity || !props?.EditActivity) && Object.keys(props?.errorList) == 0) &&
                    <Alert
                        {...props}
                        variant={props.customAlertSuccess ? 'success' : 'danger'}
                        onClose={() => dispatch({ type: ALERT_HIDE })}
                        dismissible
                    >
                        {props.customAlertMsg}

                    </Alert> : <></>
                }

                <form id="advData" onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Controller
                                name="activityType"
                                control={control}
                                render={({ field }) =>
                                    <Select name="activityType" {...field} id="activityType"
                                        placeholder={ACTIVITY_TYPE_LABEL} className="goals__form__select mb-0" classNamePrefix="mySelect" closeMenuOnSelect={true} isClearable={false}
                                        options={activityTypeOpts}
                                        components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                        styles={{
                                            container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible", zIndex: "3" }),
                                            valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', width: '170px' }),
                                            placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10 })
                                        }}
                                    />
                                }
                            />
                            <div className="error-text" style={{ "position": "absolute" }} >{errors?.activityType?.message}</div>
                            {errorList?.activityType && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.activityType}</div>}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Controller
                                name="activityName"
                                control={control}
                                render={({ field }) =>
                                    <CreatableSelect name="activityName" {...field} options={activityNameOpts} id="activityName"
                                        placeholder={ACTIVITY_NAME_LABEL} className="goals__form__select mb-0" classNamePrefix="mySelect" closeMenuOnSelect={true} isClearable={false}
                                        components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                        styles={{
                                            container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible", zIndex: "2" }),
                                            valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', width: '170px' }),
                                            placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10 })
                                        }}
                                    />
                                }
                            />
                            <div className="error-text" style={{ "position": "absolute" }} >{errors?.activityName?.message}</div>
                            {errorList?.activityName && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.activityName}</div>}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Controller control={control} name="semester"
                                render={({ field }) => (
                                    <>
                                        <Select name="semester" options={semesterOpts} placeholder="Semester"  {...field} className="goals__form__select mb-0" classNamePrefix="mySelect"
                                            closeMenuOnSelect={true} isClearable={false} isDisabled={props?.EditData ? true : false}
                                            components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                            styles={{
                                                option: (provided, state) => ({ ...provided, cursor: 'pointer', }),
                                                menu: (provided, state) => ({ ...provided, zIndex: '999' }),
                                                container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }),
                                                valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }),
                                                placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', })
                                            }}
                                        />
                                        {errors?.semester && <div className="error-text" >{errors?.semester?.message}</div>}
                                    </>
                                )}
                            />
                        </Grid>
                        {(watch("semester")?.isPassed === true &&
                            <>
                                {props?.userProfile?.currentRole !== "advisor" &&
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Controller
                                            name="gradeAchieved"
                                            control={control}
                                            render={({ field }) =>
                                                <>
                                                    <Select name="gradeAchieved" {...field} options={gradeOpts} id="gradeAchieved"
                                                        placeholder="Grade Achieved" className="goals__form__select mb-0" classNamePrefix="mySelect"
                                                        closeMenuOnSelect={true} isClearable={true}
                                                        components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                                        styles={{
                                                            container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible", zIndex: "1" }),
                                                            valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', }),
                                                            placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10 })
                                                        }}
                                                    />
                                                    <p className="cta--text" style={{ "fontSize": "12px", "marginLeft": "20px", "marginBottom": 0 }}>Optional</p>
                                                </>
                                            }
                                        />
                                        {errorList?.gradeAchieved && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.gradeAchieved}</div>}
                                    </Grid>
                                }
                            </>
                        )}
                        {(watch("semester")?.isPassed !== true || currentSemester) && (
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className="input-floating-label">
                                    <Controller
                                        name="description"
                                        control={control}
                                        render={({ field }) =>
                                            <ReactQuill className="textarea--primary textbox--rounded input" theme="snow" {...field} id="description"
                                                onKeyPress={(event) => { if (!/^[a-zA-Z0-9!@#\$%\^\ \&*\)\(+._-]+$/.test(event.key)) { event.preventDefault(); } }}
                                            />
                                        }
                                    />
                                    <label >Specify how you can help</label>
                                    <div className="d-flex justify-content-between me-1">
                                        {(errors?.description && <span className="error-text">Description only allow 750 Characters</span>) ||
                                            <p className="cta--text mb-2" style={{ "fontSize": "12px", "marginLeft": "20px", marginBottom: 0 }}>Optional</p>
                                        }
                                        <div className="subtext"><span>{descLength?.replace(/<[^>]+>/g, '').replace(/\&nbsp;+/g, ' ')?.length || 0}</span>/750</div>
                                    </div>
                                    {errorList?.description && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.description}</div>}
                                </div>
                            </Grid>
                        )}
                    </Grid>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="modalBtn" type="submit" form="advData" >Add</Button>
            </Modal.Footer>
        </Modal>
    )
}

const mapStateToProps = ({ commonData, userProfileData,studentData }) => {
    const { message, errorList, activityNames, activity, studentGrades, getphasetime } = commonData;
    const { userProfile } = userProfileData
    const { getstudentplan } = studentData
    return { message, errorList, activityNames, activity, studentGrades, getphasetime, userProfile,getstudentplan };
};
export default connect(mapStateToProps, { CreateActivity, clearMessage, clearErrorList })(withRouter(Index));
