import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { forgetPassword } from "../../../redux/actions";
import { connect } from "react-redux";
import CustomAlert from "../../../components/Alert";
import { withRouter } from "../../../redux/store/navigate";
import Loader from "../../../components/Loader"
import { EMAIL_LABEL } from "../../../Constants/commonLabels";

const IDENTIFIER = process.env.REACT_APP_IDENTIFIER;
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            errors: {},
            fields: {},
            isVerify: false,
            buttonstatus: false,
            errorList: [],
        };
        this.handleValidation = this.handleValidation.bind(this)
    }
    componentDidMount() {
        this.setState({ errorList: [] })
    }
    componentDidUpdate() {
        if (this.props.isNewPassword) {
            //this.props.navigate('/');
        }
    }
    componentWillReceiveProps(newProps) {
        this.setState({
            errorList: newProps.errorList,
        });
    }
    handleChange(field, e) {
        let { errors } = this.state;
        let fields = this.state.fields;
        fields[field] = e.target.value;
        if (e.target.value.length >= 0) {
            errors[field] = "";
        }
        this.setState({ fields, errors: errors });
    }
    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (typeof fields["email"] !== "undefined") {
            let lastAtPos = fields["email"].lastIndexOf("@");
            let lastDotPos = fields["email"].lastIndexOf(".");
            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    fields["email"].indexOf("@@") == -1 &&
                    lastDotPos > 2 &&
                    fields["email"].length - lastDotPos > 2
                )
            ) {
                formIsValid = false;
                errors["email"] = "Not a valid email";
            }
        }

        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Email cannot be empty";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    handleSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true })
        let fields = this.state.fields;
        if (this.handleValidation()) {
            let values = {
                email: fields["email"],
                identifier: IDENTIFIER
            }
            this.props.forgetPassword(values)
            this.setState({ loading: false })
        } else {
            e.preventDefault();
        }
    };
    render() {
        let { errorList } = this.state;
        return (
            <>
                <CustomAlert />
                {this.state.loading || this.props.loading && <Loader />}
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="login">
                                <div className="Verification_card">
                                    <h1 className="login_title text-center">Forgot your password? <br></br>We can help.</h1>
                                    <p className="login_desc text-center">Enter your email below and we'll send you a reset link.</p>
                                    <Form onSubmit={(e) => this.handleSubmit(e)} className="login_card_form mb-5 mt-5 text-center" autoComplete="off">
                                        <div className="login_card">
                                            <div className="input-floating-label">
                                                <input refs="email" type="text" className={this.state.errors["email"] ? "textbox--primary textbox--rounded input w-100 error-input" : "textbox--primary textbox--rounded input"}
                                                    name="email"
                                                    onChange={this.handleChange.bind(this, "email")}
                                                    value={this.state.fields["email"]}
                                                    placeholder="Email" />
                                                <label >{EMAIL_LABEL}</label>
                                                {this.state.errors["email"] && <span className="error-text">{this.state.errors["email"]}</span>}
                                                {errorList && errorList.email && <span className="error-text">{errorList.email}</span>}
                                            </div>
                                            <Button type="submit" className="btn cta--rounded cta-primary w-100"><span>Send</span></Button>
                                            <div className="mt-4"><p className="text-center">Remember your password?<Link className="cta--underline ms-2" to="/">Sign In</Link></p></div>
                                        </div>
                                    </Form>
                                </div>
                                <div className="Verification_card">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <div className="row m-0">
                        <div className="footer__left col-md-4 col-sm-4 col-4 text-center p-0">
                            <p>
                                <Link to="/" className="footer__left__cta">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M13.334 8.00065C13.334 7.63246 13.0355 7.33398 12.6673 7.33398H3.33398C2.96579 7.33398 2.66732 7.63246 2.66732 8.00065C2.66732 8.36884 2.96579 8.66732 3.33398 8.66732H12.6673C13.0355 8.66732 13.334 8.36884 13.334 8.00065Z" fill="#1B1C1E" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M8.47075 2.86128C8.2104 2.60093 7.78829 2.60093 7.52794 2.86128L2.86128 7.52794C2.60093 7.78829 2.60093 8.2104 2.86128 8.47075L7.52794 13.1374C7.78829 13.3978 8.2104 13.3978 8.47075 13.1374C8.7311 12.8771 8.7311 12.455 8.47075 12.1946L4.27549 7.99935L8.47075 3.80409C8.7311 3.54374 8.7311 3.12163 8.47075 2.86128Z" fill="#1B1C1E" />
                                    </svg>
                                    <span>Go back</span>
                                </Link>
                            </p>
                        </div>
                        <div className="footer__center col-md-4 col-sm-4 col-4 text-center">
                        </div>
                        <div className="footer__right col-md-4 col-sm-4 col-4 text-center">
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = ({ auth, commonData }) => {
    const { message, errorList, loading } = commonData
    const { isNewPassword } = auth
    return { isNewPassword, message, errorList, loading }
};

export default connect(mapStateToProps, { forgetPassword })(withRouter(Index));