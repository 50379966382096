import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ConfirmationPopup from "../../../../componentsNew/Common/ConfirmationPopup";
import { getConnectedStudents, setConnectedStudentStatus, disconnectUser,disconnectUserStatus  } from "../../../../redux/actions"
import SuggestedStudent from "../../../../componentsNew/Common/Cards/Student/SuggestedStudent";
import ConnectedStudents from "../../../../componentsNew/Common/Cards/Common/ConnectedStudents";
import ViewStudentProfile from "../../../../componentsNew/Common/ViewStudentProfile"
import { useNavigate } from 'react-router-dom';

const Index = (props) => {
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [showProfile, setShowProfile] = useState(false);
    const [profileData, setProfileData] = useState({});
    const [showDisconnectModal, setShowDisconnectModal] = useState(false);

    useEffect(() => {
        setLoading(true)
        props.getConnectedStudents()
    }, [])
    useEffect(() => {
        if (props.connectedStudentsStatus) {
            props.setConnectedStudentStatus(false)
            setLoading(false)
        }
        if (props?.isDisconnectUser) {
            openDisconnectModal(false)
            props?.getConnectedStudents()
            props?.disconnectUserStatus(false)
            setLoading(false)
        }
    }, [props?.connectedStudents, props.connectedStudentsStatus, props?.isDisconnectUser])

    const handleViewProfile = (data) => {
        setProfileData(data);
        showProfileModal(true);
    }
    const showProfileModal = (status) => {
        if (!status) {
            setProfileData({})
        }
        setShowProfile(status)
    }
    const HandleNavigate = (e) => {
        e.preventDefault();
        navigate('/student/mynetwork/exploreStudent')
    }
    const openDisconnectModal = (status, data) => {
        console.log("openDisconnectModal", data);
        setProfileData(data)
        setShowDisconnectModal(status);
    }
    const handleDelete = () => {
        let userdata = profileData
        if (userdata?.id) {
            setLoading(true)
            let payload = {
                userId: userdata?.id
            }
            props?.disconnectUser(payload)
        }
    }
    return (
        <>
            {showDisconnectModal &&
                <ConfirmationPopup show={showDisconnectModal} handleSubmit={() => handleDelete()} message={`You and ${profileData?.fullName} will no longer be connected to each other`} btnText="confirm" onHide={() => openDisconnectModal(false)} />
            }
            {showProfile &&
                <ViewStudentProfile show={showProfile} profileData={profileData} handleClose={() => showProfileModal(false)} />
            }
            {props?.connectedStudents?.length > 0 ? (
                <>
                    <div className='Network__Content' >
                        {props.connectedStudents?.map(userInfo => {
                            return (
                                <>
                                    <ConnectedStudents key={userInfo?.id}
                                        name={userInfo?.fullName}
                                        country={userInfo?.country}
                                        school={userInfo?.studentProfile?.currrentSchoolName}
                                        gpa={userInfo?.studentProfile?.desiredGpa}
                                        universities={userInfo?.studentProfile?.universities?.sort((a, b) => { return a.length - b.length })}
                                        professions={userInfo?.studentProfile?.professions?.sort((a, b) => { return a.length - b.length })}
                                        courseAndActivities={userInfo?.courses?.concat(userInfo?.activities) ?? userInfo?.studentProfile?.courses?.concat(userInfo?.studentProfile?.activities)}     
                                        weighted={userInfo?.studentProfile?.targetWeighted}
                                        unWeighted={userInfo?.studentProfile?.targetUnweighted}
                                        avatarUrl={userInfo?.avatarPath}
                                        recipientUrl='/inbox'
                                        reDirectUrl = 'mywork'
                                        studentId = {userInfo?.id}
                                    >
                                        <button className="suggestedStudentCard__buttonSec__danger-btn" onClick={() => openDisconnectModal(true, userInfo)}>
                                            Disconnect
                                        </button>
                                        <button className="suggestedStudentCard__buttonSec__primary-btn" onClick={() => handleViewProfile({ id: userInfo?.id })}>
                                            View Profile
                                        </button>
                                    </ConnectedStudents>
                                </>
                            )
                        })}
                    </div>
                </>
            ) :
                <div className='Empty__Container'>
                    <div className='Backdrop__container__card'>
                        <div className='title_cnt'>
                            <p>Let’s get you connected with some students who can help you achieve your goals !</p>
                        </div>
                        <div className='btn_cnt'>
                            <p onClick={(e) => HandleNavigate(e)}>
                                <span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="11" cy="11" r="6" stroke="#222222" />
                                        <path d="M11 8C10.606 8 10.2159 8.0776 9.85195 8.22836C9.48797 8.37913 9.15726 8.6001 8.87868 8.87868C8.6001 9.15726 8.37913 9.48797 8.22836 9.85195C8.0776 10.2159 8 10.606 8 11" stroke="#222222" stroke-linecap="round" />
                                        <path d="M20 20L17 17" stroke="#222222" stroke-linecap="round" />
                                    </svg>
                                </span>
                                Explore Students
                            </p>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

const mapStateToProps = ({ commonData, userProfileData }) => {
    const { message, errorList, connectedStudents, connectedStudentsStatus } = commonData;
    const { isDisconnectUser } = userProfileData
    return { message, errorList, connectedStudents, connectedStudentsStatus, isDisconnectUser };
};
export default connect(mapStateToProps, { getConnectedStudents, setConnectedStudentStatus, disconnectUser,disconnectUserStatus  })(Index);