import React, { useState } from 'react'
import { Tab, Nav } from "react-bootstrap";
import Select, { components } from "react-select";
import Barchart from "../../../../../components/Barchart";
import Areachart from "../../../../../components/Areachart";

const Index = () => {
    const [key, setKey] = useState( "Table" )
    const [selectoption, setSelectoption] = useState([
        { label: "month", value: "month" },
        { label: "year", value: "year" },
        { label: "week", value: 'week' },
    ])

    const handleSetKey = (k) => {
        setKey(k)
    }

    const { ValueContainer, Placeholder } = components;
        const CustomValueContainer = ({ children, ...props }) => {
            return (
                <ValueContainer {...props}>
                    <Placeholder {...props} isFocused={props.isFocused}>
                        {props.selectProps.placeholder}
                    </Placeholder>
                    {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
                </ValueContainer>
            );
        };

    return (
        <div className="Earningcnt">
            <div className="Earningbox">
                <div className="EarningTop">
                    <div className="row">
                        <div className="col-sm-12 col-md-3 col-lg-4 col-xl-3">
                            <p className="earningheading" >Earnings</p>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-6 col-xl-4">
                            <Tab.Container id="left-tabs-example" activeKey={key} onSelect={(k) => handleSetKey(k)}>
                                <div className="row">
                                    <div className="col-12">
                                        <Nav variant="pills" >
                                            <Nav.Item>
                                                <Nav.Link eventKey="Table">Table</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="Chart">Chart</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                </div>
                            </Tab.Container>
                        </div>
                        <div className="col-sm-12 col-md-5 col-lg-12 col-xl-5">
                            <div className="row">
                                <div className="col-6" style={{ "display": "flex", "justifyContent": "end" }}><p className="comparetext">Compare to last</p></div>
                                <div className="col-6">
                                    <Select className="goals__form__select mb-3" classNamePrefix="mySelect" name="graduationYear" options={selectoption} closeMenuOnSelect={true} isClearable={false} components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }} placeholder="Earnings" styles={{ container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10 }) }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Earningmid">
                    <Tab.Container id="left-tabs-example" activeKey={key} onSelect={(k) => handleSetKey(k)}>
                        <div className="col-12">
                            <Tab.Content>
                                <Tab.Pane eventKey="Table">
                                    <Barchart />
                                </Tab.Pane>
                                <Tab.Pane eventKey="Chart">
                                    <Areachart />
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </Tab.Container>
                </div>
            </div>
        </div>
    )
}

export default Index