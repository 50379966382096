import React from 'react'
import ChangeCard from "../Changes"
import { connect } from 'react-redux';
const Index = (props) => {
    const filteredData = props?.notificationdata.filter(data => data.notificationType !== "network");

    return (
        <div className='GenerlTabs'>
            <div className='row'>
                { filteredData && filteredData?.length > 0 ? (
                        <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8'>
                            <ChangeCard notificationdata={filteredData} type={props?.type} tabdata={"general"} />
                        </div>
                    ) :
                        <div className="Plan_Card_Empty_Cnt">
                            <div className="Empty_Card">
                                <p>Currently you have no notifications</p>
                            </div>
                        </div>
                }
                <div className='col-12 col-sm-12 col-md-12 col-lg-4'></div>
            </div>
        </div>
    )
}
const mapStateToProps = ({ commonData }) => {
    const { notificationdata } = commonData;
    return { notificationdata }
};

export default connect(mapStateToProps, {})(Index);