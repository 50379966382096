import { FETCH_DATA, FETCH_DATA_SSSS, FETCH_DATA_SUCCESS, FETCH_GENERIC_DATA_SECTION_SUCCESS, FETCH_GENERIC_DATA_SUCCESS, FETCH_STUDENT_BY_SECTION, RESET_DATA } from "../constants/ExploreNetwork";

const INIT_STATE = {
    loadUsers: false,
    allDatasLoaded: true,
    data: [],
    genericSearchData: [],
    universityFilterData: [],
    professionFilterData: [],
    courseFilterData: [],
    activityFilterData: []
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case  RESET_DATA: {
            return {
                loadUsers: false,
                allDatasLoaded: true,
                data: [],
                genericSearchData:[],
                universityFilterData: [],
                professionFilterData: [],
                courseFilterData: [],
                activityFilterData: []
            }
        }
        case  FETCH_DATA: {
            return {
                ...state,
                loadUsers : true,
                allDatasLoaded: false
            }
        }
        case FETCH_DATA_SUCCESS: {
            const upCommingUniversityFilters = [... new Set(action?.payload?.filter(data => data?.sectionFilters !== null)?.map(section => section?.sectionFilters[0])?.flat(), ...state?.universityFilterData?.map(data => data?.label))];
            const upCommingProfessionFilters = [... new Set(action?.payload?.filter(data => data?.sectionFilters !== null)?.map(section => section?.sectionFilters[1])?.flat(), ...state?.professionFilterData?.map(data => data?.label))];
            const upCommingCourseFilters = [... new Set(action?.payload?.filter(data => data?.sectionFilters !== null)?.map(section => section?.sectionFilters[2])?.flat(), ...state?.courseFilterData?.map(data => data?.label))];
            const upCommingActivityFilters = [... new Set(action?.payload?.filter(data => data?.sectionFilters !== null)?.map(section => section?.sectionFilters[3])?.flat(), ...state?.activityFilterData?.map(data => data?.label))];
            return {
              ...state,
              data : action.payload?.filter(data => data?.users?.length > 0),
              loadUsers: false,
              allDatasLoaded: true,
              universityFilterData: upCommingUniversityFilters?.map(data => ({label: data, value: data})),
              professionFilterData: upCommingProfessionFilters?.map(data => ({label: data, value: data})),
              courseFilterData: upCommingCourseFilters?.map(data => ({label: data, value: data})),
              activityFilterData: upCommingActivityFilters?.map(data => ({label: data, value: data}))
            }
        }
        case FETCH_DATA_SSSS: {
            return {
                ...state,
                allDatasLoaded: action?.payload

            }
        }
        case FETCH_STUDENT_BY_SECTION: {
            let oldObj = state?.data?.find(section => section?.section == action.payload?.section && section?.sectionValue == action.payload?.sectionValue && section?.sectionIndex == action.payload?.sectionIndex)
            let oldObjIndex = state?.data?.findIndex(section => section?.section == action.payload?.section && section?.sectionValue == action.payload?.sectionValue && section?.sectionIndex == action.payload?.sectionIndex)
            let newOject = {
                ...oldObj,
                pageNo: action.payload?.pageNo,
                searchString: "",
                section: action.payload?.section,
                users: [...oldObj?.users, ...action.payload?.users]
            }
            console.log(newOject, "newOject");
            
            let updatedItems = [...state.data];
            updatedItems[oldObjIndex] = newOject;
            console.log(updatedItems, "updatedItems");
            
            return {
              ...state,
              data : updatedItems,
              loadUsers: false,
            }
        }
        case FETCH_GENERIC_DATA_SUCCESS: {
            return {
              ...state,
              genericSearchData : action.payload?.filter(data => data?.users?.length > 0),
              loadUsers: false,
              allDatasLoaded: true,
            }
        }
        case FETCH_GENERIC_DATA_SECTION_SUCCESS: {
            let newObject = {
                ...state?.genericSearchData?.[0],
                pageNo: action.payload?.[0]?.pageNo,
                users: [...state?.genericSearchData?.[0]?.users, ...action?.payload?.[0]?.users]
            }
            return {
              ...state,
              genericSearchData : [newObject],
              loadUsers: false,
              allDatasLoaded: true,
            }
        }
        default:
            return state;
    }
}