import React, { useEffect, useState } from 'react'
import * as Yup from "yup";
import { connect } from "react-redux";
import { Form, Button } from "react-bootstrap";
import { CONFIRM_PASSWORD_LABEL, CREATE_NEW_PASSWORD_LABEL, CURRENT_PASSWORD_LABEL } from '../../../../../Constants/commonLabels';
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { changePassword } from "../../../../../redux/actions";
import Loader from '../../../../../components/Loader';

const validation =Yup.object({
    password: Yup.string().required("* Required").nullable().min(6, 'Minimum 6 characters required')
    .matches(/[a-z]/, "Password requires atleast 1 lowercase")
    .matches(/[A-Z]/, "Password requires atleast 1 uppercase")
    .matches(/[0-9]/, "Password requires atleast 1 digit")
    .matches(/(?=.*?[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/, "Password requires 1 Special character"),
    newPassword: Yup.string().required("* Required").nullable()
    .matches(/[a-z]/, "Password requires atleast 1 lowercase")
    .matches(/[A-Z]/, "Password requires atleast 1 uppercase")
    .matches(/[0-9]/, "Password requires atleast 1 digit")
    .matches(/(?=.*?[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/, "Password requires 1 Special character")
    .min(6, 'Minimum 6 characters required')
    .test('passwords-match', 'New password must be different from current password', function (value) {
        return this.parent.password !== value;
    }),
    confirmPassword: Yup.string().required("* Required").nullable().oneOf([Yup.ref('newPassword'), null], 'Mismatch in confirm password with new password'),
  });


const Index = (props) => {
    const [loading, setLoading] = useState(false)
    const [errorList, setErrorList] = useState([]);
    useEffect(() => {
        if(props?.isPasswordUpdate){
            reset()
            setLoading(false)
        }
        if(props?.isPasswordUpdateerror){
            setLoading(false)
        }
    }, [props.errorlist,props.isPasswordUpdate,props.isPasswordUpdateerror,props.errorList,props.message,loading])
    
    useEffect(() => {
        if (props?.errorList !== errorList){
            setErrorList(props.errorList)
        } else {
            setErrorList({})
        }
        
    }, [props.errorList])

    const { register,  handleSubmit,reset, clearErrors, formState: { errors} } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(
            validation
            ),
    });

    const onValidationChange = e => {
        clearErrors('newPassword')
        clearErrors('password')
        clearErrors('confirmPassword')
        if (props?.errorList) {
          setErrorList([])
        } 
      }

	const onSubmit = data => {
        setLoading( true )
        props?.changePassword(data)
	}

    return (
        <div className="passwordcnt">
            {loading && <Loader />}
            <div className="changepasswordsection">
                <p className="profile__form__sub-title">Password</p>
                <Form
                    className="login_card_form mb-4"
                    autoComplete="off"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="col-md-11 col-sm-11 col-11 ps-0 p-mob-0">
                        <div className="input-floating-label">
                            <input refs="password" type="password" {...register('password')}
                                className={ "textbox--primary textbox--rounded input" }
                                name="password" placeholder=" " onChange={(e) => { onValidationChange(e) }}
                            />
                            <label>{CURRENT_PASSWORD_LABEL}</label>
                            {errors.password && (
                                <span className="error-text" style={{ "position": "absolute" }}>
                                    {errors?.password?.message}
                                </span>
                            )}
                            {errorList && errorList?.password && (
                                <span className="error-text">
                                    {errorList?.password}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="col-md-11 col-sm-11 col-11 ps-0 p-mob-0">
                        <div className="input-floating-label">
                            <input
                                refs="newPassword" type="password" name="newPassword" {...register('newPassword')}
                                className={ "textbox--primary textbox--rounded input" } placeholder=" "
                                onChange={(e) => { onValidationChange(e) }}
                            />
                            <label>{CREATE_NEW_PASSWORD_LABEL}</label>
                            <p className="cta--text mb-1" style={{ fontSize: "13px" }} >
                                Must contain 1 uppercase, 1 lowercase, 1
                                digit, 1 special character and atleast 6 characters
                            </p>
                            {errors.newPassword && (
                                <span className="error-text">
                                    {errors?.newPassword?.message}
                                </span>
                            )}
                            {errorList && errorList?.newPassword && (
                                <span className="error-text">
                                    {errorList?.newPassword}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="col-md-11 col-sm-11 col-11 ps-0 p-mob-0">
                        <div className="input-floating-label">
                            <input refs="confirmPassword" type="password" name="confirmPassword" {...register('confirmPassword')}
                                className={ "textbox--primary textbox--rounded input" } placeholder=" "
                                onChange={(e) => { onValidationChange(e) }}
                            />
                            <label>{CONFIRM_PASSWORD_LABEL}</label>
                            {errors.confirmPassword && (
                                <span className="error-text"> {errors.confirmPassword?.message} </span>
                            )}
                            {errorList && errorList?.password && ( <span className="error-text"> {errorList?.confirmPassword} </span> )} 
                        </div>
                    </div>
                    <div  className="col-lg-8 col-md-8 col-sm-11 col-11 ps-0 p-mob-0"
                        style={{ display: "flex", justifyContent: "center", marginInline: 'auto' }}
                    >
                        <Button type="submit" className="btn cta--rounded cta-primary w-100"  >
                            <span>Reset password</span>
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
  )
}

const mapStateToProps = ({ commonData, userProfileData }) => {
    const { message, errorList } = commonData;
    const { isPasswordUpdateerror, isPasswordUpdate } = userProfileData;
    return { isPasswordUpdate, message, errorList, isPasswordUpdateerror };
};
export default connect(mapStateToProps, { changePassword })(Index);
