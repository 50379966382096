//Common Module const
export const FETCH_START = 'FETCH_START';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_ERROR = 'FETCH_ERROR';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const SET_COPY_FLAG = 'SET_COPY_FLAG';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';
export const SET_PAGINATION = 'SET_PAGINATION';
export const GET_USER_ROLES = 'GET_USER_ROLES';
export const GET_UNIVERSITIES = 'GET_UNIVERSITIES';
export const GET_SCHOOLS = 'GET_SCHOOLS';
export const GET_ZIPCODES = 'GET_ZIPCODES';
export const GET_PROFESSIONS = 'GET_PROFESSIONS';
export const GET_GENDER = 'GET_GENDER';
export const GET_STATUS = 'GET_STATUS';
export const GET_COUNTRY = 'GET_COUNTRY';
export const GET_ACTIVITY = 'GET_ACTIVITY';
export const GET_ACTIVITIES = 'GET_ACTIVITIES';
export const GET_COURSE = 'GET_COURSE';
export const GET_COURSES = 'GET_COURSES';
export const GET_STUDENT_GRADES = 'GET_STUDENT_GRADES';
export const GET_PHASE_TIME = "GET_PHASE_TIME"
export const SET_PHASE_TIME_STATUS = "SET_PHASE_TIME_STATUS"
export const FORGET_PASSWORD_STATUS = 'FORGET_PASSWORD_STATUS'
export const CUSTOM_ALERT_SHOW = "CUSTOM_ALERT_SHOW"
export const CUSTOM_ALERT_CLOSE = "CUSTOM_ALERT_CLOSE"
export const ALERT_SHOW = "ALERT_SHOW"
export const ALERT_HIDE = "ALERT_HIDE"
export const CLEAR_MESSAGE = "CLEAR_MESSAGE"
export const GET_GRADUATION_YEAR = "GET_GRADUATION_YEAR"
export const CLEAR_ERROR_MESSAGE = "CLEAR_ERROR_MESSAGE"
export const GET_ADVISOR_RECOMMENDED = "GET_ADVISOR_RECOMMENDED"
export const GET_ADVISOR_RECOMMENDED_STATUS = "GET_ADVISOR_RECOMMENDED_STATUS"
export const GET_RECOMMENDED_STUDENTS = "GET_RECOMMENDED_STUDENTS"
export const GET_RECOMMENDED_STUDENTS_STATUS = "GET_RECOMMENDED_STUDENTS_STATUS"
export const USER_CONNECT_STATUS = "USER_CONNECT_STATUS"
export const USER_CONNECT_ERROR_STATUS = "USER_CONNECT_ERROR_STATUS"
export const GET_PENDING_REQUESTS = "GET_PENDING_REQUESTS"
export const RESET_PENDING_REQUESTS = "RESET_PENDING_REQUESTS"
export const SET_PENDING_REQUESTS_STATUS = "SET_PENDING_REQUESTS_STATUS"
export const GET_DETAILED_ADVISOR_PROFILE = "GET_DETAILED_ADVISOR_PROFILE"
export const GET_RECOMMENDED_STUDENTS_ADVISOR = "GET_RECOMMENDED_STUDENTS_ADVISOR"
export const GET_RECOMMENDED_STUDENTS_ADVISOR_STATUS = "GET_RECOMMENDED_STUDENTS_ADVISOR_STATUS"
export const GET_DETAILED_USER_PROFILE = "GET_DETAILED_USER_PROFILE"
export const SET_DETAILED_USER_PROFILE_STATUS = "SET_DETAILED_USER_PROFILE_STATUS"
export const SEND_RESPOND_TO_REQUEST = "SEND_RESPOND_TO_REQUEST"
export const SEND_RESPOND_TO_REQUEST_STATUS = "SEND_RESPOND_TO_REQUEST_STATUS"
export const GET_CONNECTED_PARENTS = "GET_CONNECTED_PARENTS"
export const SET_CONNECTED_PARENT_STATUS = "SET_CONNECTED_PARENT_STATUS"
export const GET_CONNECTED_STUDENTS = "GET_CONNECTED_STUDENTS"
export const SET_CONNECTED_STUDENTS_STATUS = "SET_CONNECTED_STUDENTS_STATUS"
export const GET_CONNECTED_ADVISORS = "GET_CONNECTED_ADVISORS"
export const SET_CONNECTED_ADVISORS_STATUS = "SET_CONNECTED_ADVISORS_STATUS"
export const GET_CONNECTED_FRIENDS = "GET_CONNECTED_FRIENDS"
export const SET_CONNECTED_FRIEND_STATUS = "SET_CONNECTED_FRIEND_STATUS"
export const GET_NOTIFICATION = "GET_NOTIFICATION"
export const VIEW_NOTIFICATION = "VIEW_NOTIFICATION"
export const BOOK_SESSION = "BOOK_SESSION"
export const LIST_SCHEDULED_EVENTS = "LIST_SCHEDULED_EVENTS"
export const LIST_SCHEDULED_EVENTS_STATUS = "LIST_SCHEDULED_EVENTS_STATUS"
export const GET_CONNECTED_ADVISORS_SWITCH = "GET_CONNECTED_ADVISORS_SWITCH"
export const SET_CONNECTED_ADVISORS_SWITCH_STATUS = "SET_CONNECTED_ADVISORS_SWITCH_STATUS"
export const GET_CONNECTED_FRIEND_SWITCH = "GET_CONNECTED_FRIEND_SWITCH"
export const SET_CONNECTED_CHILD_STATUS = "SET_CONNECTED_CHILD_STATUS"
export const RESCHEDULE_SESSION = "RESCHEDULE_SESSION"
export const RESCHEDULE_SESSION_ERROR = "RESCHEDULE_SESSION_ERROR"
export const RESCHEDULE_PAID_SESSION = "RESCHEDULE_PAID_SESSION"
export const BOOK_SESSION_ERROR = "BOOK_SESSION_ERROR"
export const GET_CONNECTED_PARENT_SWITCH = "GET_CONNECTED_PARENT_SWITCH"
export const LIST_SCHEDULED_EVENTS_SWITCH = "LIST_SCHEDULED_EVENTS_SWITCH"
export const GET_ADVISOR_SUGGESTED = "GET_ADVISOR_SUGGESTED"
export const GET_ADVISOR_SUGGESTED_STATUS = "GET_ADVISOR_SUGGESTED_STATUS"
export const STUDENT_PLANS_INFO = "STUDENT_PLANS_INFO"
export const STUDENT_PLANS_INFO_RESET = "STUDENT_PLANS_INFO_RESET"
export const STUDENT_PLANS_INFO_STATUS = "STUDENT_PLANS_INFO_STATUS"
export const PARENT_PLANS_DETAIL = "PARENT_PLANS_DETAIL"
export const PARENT_PLANS_INFO_STATUS = "PARENT_PLANS_INFO_STATUS"
export const GET_SENT_REQUESTS = "GET_SENT_REQUESTS"
export const ERROR_CANCEL_REQUEST = "ERROR_CANCEL_REQUEST"
export const CANCEL_REQUEST = "CANCEL_REQUEST"
export const ADD_ADVISOR_STATUS_ERROR = "ADD_ADVISOR_STATUS_ERROR"
export const ADD_ADVISOR_STATUS = "ADD_ADVISOR_STATUS"
export const LOAD_ADVISOR_REVIEW = "LOAD_ADVISOR_REVIEW"
export const ADVISOR_FEADBACK_SUBMIT = "ADVISOR_FEADBACK_SUBMIT"
export const HIDE_CONNECTION_REQUEST = "HIDE_CONNECTION_REQUEST"
export const VIEW_PUBLIC_PROFILE = "VIEW_PUBLIC_PROFILE"
export const GET_SENT_REQUESTS_STATUS = "GET_SENT_REQUESTS_STATUS"
export const GET_PENDING_REQUESTS_STATUS = "GET_PENDING_REQUESTS_STATUS"
export const BOOK_PAID_SESSION = "BOOK_PAID_SESSION"
export const BOOK_PAID_SESSION_ERROR = "BOOK_PAID_SESSION_ERROR"
export const SCHEDULED_SESSION_DETAILS = "SCHEDULED_SESSION_DETAILS"
export const SCHEDULED_SESSION_DETAILS_STATUS = "SCHEDULED_SESSION_DETAILS_STATUS"
export const CANCEL_SCHEDULE_SESSION = "CANCEL_SCHEDULE_SESSION"
export const CANCEL_SCHEDULE_SESSION_ERROR = "CANCEL_SCHEDULE_SESSION_ERROR"
export const SCHEDULED_FREESESSION_DETAILS = "SCHEDULED_FREESESSION_DETAILS"
export const INVITATION_REVOKE_ERROR = "INVITATION_REVOKE_ERROR"
export const INVITATION_REVOKE = "INVITATION_REVOKE"
export const FREE_SESSION_REMAINING = "FREE_SESSION_REMAINING"
export const ADD_STUDENT_STATUS_ERROR = "ADD_STUDENT_STATUS_ERROR"
export const ADD_STUDENT_STATUS = "ADD_STUDENT_STATUS"
export const REMOVE_STUDENT_STATUS_ERROR = "REMOVE_STUDENT_STATUS_ERROR"
export const REMOVE_STUDENT_STATUS = "REMOVE_STUDENT_STATUS"
export const LOAD_SPECIFIC_EXPLORE_ADVISOR = "LOAD_SPECIFIC_EXPLORE_ADVISOR"
export const LOAD_SPECIFIC_EXPLORE_ADVISOR_STATUS = "LOAD_SPECIFIC_EXPLORE_ADVISOR_STATUS"
export const SAVE_USER_PROFILE = "SAVE_USER_PROFILE"
export const LOAD_SAVED_USER_PROFILE = "LOAD_SAVED_USER_PROFILE"
export const LOAD_SAVED_USER_PROFILE_STATUS = "LOAD_SAVED_USER_PROFILE_STATUS"
export const ADVISOR_PROFILE_SAVE_ERROR = "ADVISOR_PROFILE_SAVE_ERROR"
export const  GET_EXPLORE_STUDENT = " GET_EXPLORE_STUDENT"
export const GET_ADVISOR_AVAILABILITY = "GET_ADVISOR_AVAILABILITY"
export const SCHEDULED_FREESESSION_DETAILS_STATUS = "SCHEDULED_FREESESSION_DETAILS_STATUS"
export const SEND_RESPOND_TO_PARENT_REQUEST = "SEND_RESPOND_TO_PARENT_REQUEST"
export const GENERIC_SEARCH_ADVISOR = "GENERIC_SEARCH_ADVISOR"
export const GENERIC_SEARCH_STUDENT = "GENERIC_SEARCH_STUDENT"
export const GENERIC_SEARCH_STUDENT_SECTION = "GENERIC_SEARCH_STUDENT_SECTION"
export const GENERIC_SEARCH_STUDENT_STATUS = "GENERIC_SEARCH_STUDENT_STATUS"
export const UNSUBSCRIBE_ADVISOR = "UNSUBSCRIBE_ADVISOR"
export const GET_EXPLORE_STUDENT_STATUS = "GET_EXPLORE_STUDENT_STATUS"
export const EXPLORE_ADVISOR_PAGINATION = "EXPLORE_ADVISOR_PAGINATION"
export const EXPLORE_ADVISOR_PAGINATION_STATUS = "EXPLORE_ADVISOR_PAGINATION_STATUS"
export const GET_USER_COUNT = "GET_USER_COUNT"
export const ADVISOR_FEADBACK_EDIT = "ADVISOR_FEADBACK_EDIT"
export const ADVISOR_FEADBACK_DELETE = "ADVISOR_FEADBACK_DELETE"
export const GET_NETWORK_USER_COUNT = "GET_NETWORK_USER_COUNT"
export const GET_NETWORK_USER_COUNT_STATUS = "GET_NETWORK_USER_COUNT_STATUS"
export const SET_ARCHIVE_PLAN_ERROR = "SET_ARCHIVE_PLAN_ERROR"
export const SET_ARCHIVE_PLAN = "SET_ARCHIVE_PLAN"
export const SET_UNARCHIVE_PLAN = "SET_UNARCHIVE_PLAN"
export const TOTAL_ADVISOR_REVIEW = "TOTAL_ADVISOR_REVIEW"
export const LOAD_ADVISOR_REVIEW_STATUS = "LOAD_ADVISOR_REVIEW_STATUS"
export const RESET_REVIEW = "RESET_REVIEW"
export const SET_ARCHIVE_COURSE = "SET_ARCHIVE_COURSE"
export const SET_ARCHIVE_COURSE_ERROR = "SET_ARCHIVE_COURSE_ERROR"
export const SET_UNARCHIVE_COURSE = "SET_UNARCHIVE_COURSE"
export const SET_UNARCHIVE_COURSE_ERROR = "SET_UNARCHIVE_COURSE_ERROR"
export const SET_ARCHIVE_ACTIVITY = 'SET_ARCHIVE_ACTIVITY'
export const SET_ARCHIVE_ACTIVITY_ERROR = 'SET_ARCHIVE_ACTIVITY_ERROR'
export const SET_UNARCHIVE_ACTIVITY = 'SET_UNARCHIVE_ACTIVITY'
export const SET_UNARCHIVE_ACTIVITY_ERROR = 'SET_UNARCHIVE_ACTIVITY_ERROR'
export const GET_EXPLORE_ADVISOR_COUNT = "GET_EXPLORE_ADVISOR_COUNT"
export const GET_UPDATES_VIEWED_COUNT = "GET_UPDATES_VIEWED_COUNT"
export const CURRENT_COURSES_DASHBOARD = "CURRENT_COURSES_DASHBOARD"
export const USER_INVITE_ADVISOR = "USER_INVITE_ADVISOR"
export const USER_INVITE_STUDENT = "USER_INVITE_STUDENT"
export const CURRENT_ACTIVITY_DASHBOARD = "CURRENT_ACTIVITY_DASHBOARD"
export const USER_INVITE_STUDENT_ERROR = "USER_INVITE_STUDENT_ERROR"
