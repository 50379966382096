import React, { useEffect, useState } from 'react'
import "./planCard.less"
import PillSection from "../../../PillSection"
import Tooltip from '@mui/material/Tooltip';

const Index = ({ children, planData, profileView }) => {

	const [courseNames, setCourseNames] = useState([]);
	const [activityNames, setActivityNames] = useState([]);

	useEffect(() => {
		if (planData && planData?.phases?.length > 0) {
			const extractedCourseNames = planData?.phases?.reduce((acc, phase) => {
				phase?.courses?.forEach((course) => {
					acc?.push(course?.courseName);
				});
				return acc;
			}, []);
			setCourseNames(extractedCourseNames);
		}
		if (planData && planData?.phases?.length > 0) {
			const extractedActivityNames = planData?.phases?.reduce((acc, phase) => {
				phase?.activities?.forEach((activity) => {
					acc.push(activity?.activityName);
				});
				return acc;
			}, []);
			setActivityNames(extractedActivityNames);
		}
	}, [planData]);

	const filteredPlanInfo = profileView ? (planData?.planInfo?.isApproved === true ? planData?.planInfo : null) : planData?.planInfo;
	return (
		<>
			{filteredPlanInfo != null && (
				<div className='planDetailCard'>
					<div className='planDetailCard__Header'>
						<div className='planDetailCard__Header__Heading'>
							<Tooltip title={filteredPlanInfo?.planName} arrow slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [0, 0], }, },], }, }}>
								<p>{filteredPlanInfo?.planName}</p>
							</Tooltip>
						</div>
						<div className='planDetailCard__Header__Menu'>
							{children}
						</div>
					</div>
					<div className='planDetailCard__Body'>
						{filteredPlanInfo?.universities?.length > 0 &&
							<div className='ChipSections mb-1' >
								<div>
									<p className='ChipSection__header'>{filteredPlanInfo?.universities?.length} Target Universities</p>
								</div>
								<PillSection data={filteredPlanInfo?.universities} maxWidth={300} maxHeight={120} />
							</div>
						}
						{filteredPlanInfo?.professions?.length > 0 &&
							<div className='ChipSections mb-1' >
								<div>
									<p className='ChipSection__header'>{filteredPlanInfo?.professions?.length} Target Professions</p>
								</div>
								<PillSection data={filteredPlanInfo?.professions} maxWidth={220} maxHeight={120} />
							</div>
						}
						{(filteredPlanInfo?.weighted !== null && filteredPlanInfo?.weighted !== undefined) &&
							<div className='ChipSections mb-1' >
								<div>
									<p className='ChipSection__header'>Target weighted GPA</p>
								</div>
								<PillSection data={[filteredPlanInfo?.weighted]} maxWidth={260} maxHeight={120} />
							</div>
						}
						{(filteredPlanInfo?.unweighted !== null && filteredPlanInfo?.unweighted !== undefined) &&
							<div className='ChipSections mb-1' >
								<div>
									<p className='ChipSection__header'>Target unweighted GPA</p>
								</div>
								<PillSection data={[filteredPlanInfo?.unweighted]} maxWidth={260} maxHeight={120} />
							</div>
						}
						{courseNames?.length > 0 &&
							<div className='ChipSections mb-1' >
								<div>
									<p className='ChipSection__header'>Courses</p>
								</div>
								<PillSection data={courseNames} maxWidth={200} maxHeight={120} />
							</div>
						}
						{activityNames?.length > 0 &&
							<div className='ChipSections mb-1' >
								<div>
									<p className='ChipSection__header'>Activities</p>
								</div>
								<PillSection data={activityNames} maxWidth={200} maxHeight={120} />
							</div>
						}
					</div>
				</div>)}
		</>
	)
}

export default Index