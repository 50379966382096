import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Select, { components } from "react-select";
import { Button } from "react-bootstrap";
import { Box, Autocomplete, Grid, TextField } from "@mui/material";
import { connect } from "react-redux";
import { COUNTRY_NAME_LABEL, EMAIL_LABEL, FIRST_NAME_LABEL, LAST_NAME_LABEL, MEET_PASSCODE, MEET_URL, ZIP_CODE_LABEL } from "../../../../../Constants/commonLabels";
import { updateGeneralInfo, setIsUpdateGeneralInfoSuccess, setIsUpdateGeneralInfoError, resendVerifyEmail, clearErrorList, getUserProfile, setResendVerifyEmail } from "../../../../../redux/actions";
import "./generalForm.less";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Loader from "../../../../../components/Loader";
import { loadZipcode } from "../../../../../utils/AutoComplete";
import dayjs from "dayjs";

const validation = Yup.object({
	firstName: Yup.string().trim().required("*Required").min(2, "Minimum 2 characters required").max(50, "Maximum 50 characters Allowed").nullable(),
	lastName: Yup.string().trim().required("*Required").max(50, "Minimum 50 characters Allowed").nullable(),
	email: Yup.string().required("* Required")
		.matches( /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			"Please enter a valid email"
		),
	country: Yup.object().required("*Required").nullable(),
	zipCode: Yup.string()
    .when('country', (country, schema) => {
        // If country is US, validate with 5 digits, else with 6 digits
        return country && country.value === 'US'
            ? schema.matches(/^[0-9]{5}$/, { message: 'Must contain exactly 5 digits', excludeEmptyString: true })
            : schema.matches(/^[0-9]{6}$/, { message: 'Must contain exactly 6 digits', excludeEmptyString: true });
    })
    .required("*Required"),
	defaultMeetingURL: Yup.string().url("Link must be valid URl").nullable(),
})

const timezonesOptions = [
    { label: 'Hawaii Time', value: 'Pacific/Honolulu' },
    { label: 'Alaska Time', value: 'America/Anchorage' },
    { label: 'Pacific Time', value: 'America/Los_Angeles' },
    { label: 'Mountain Daylight Time', value: 'America/Denver' },
    { label: 'Mountain Standard Time', value: 'America/Phoenix' },
    { label: 'Central Time', value: 'America/Chicago' },
    { label: 'Eastern Time', value: 'America/New_York' },
];

const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
	return (<ValueContainer {...props}><Placeholder {...props} isFocused={props.isFocused}>{" "}{props.selectProps.placeholder}{" "}</Placeholder>{React.Children.map(children, (child) => child && child.type !== Placeholder ? child : null)}</ValueContainer>);
};

const Index = (props) => {
	const [loading, setLoading] = useState(false)
	const [errorList, setErrorList] = useState({})
	const [countryOption, setCountryOption] = useState([])
	const [loadZipcodes, setLoadZipcodes] = useState(false);
	const [zipCodeOption, setZipCodeOption] = useState([])

	useEffect(() => {
		setLoading(true)
	}, [])

	useEffect(() => {
		setInitialValues()
	}, [ props.country, props.userProfile ])
	
	useEffect(() => {
		if ( props?.isSetGeneralInfoSuccess ){
			setLoading(false)
		} else if ( props?.isSetGeneralInfoError === true ) {
			props?.setIsUpdateGeneralInfoError(false)
			setLoading(false)
		}
		if(props.isResendEmailSuccess){
            props.setResendVerifyEmail(false);
            props.getUserProfile();
        }
	}, [ props.isSetGeneralInfoSuccess, props?.isSetGeneralInfoError, props.isResendEmailSuccess ])
	
	useEffect(() => {
        if (props?.errorList !== errorList){
			setErrorList(props.errorList)
        } else {
            setErrorList({})
        }
        
    }, [props.errorList])

	const { register,  handleSubmit, control, formState: { errors }, setValue, clearErrors } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(
            validation
            ),
    });

	const setInitialValues = () => {
		let countryOptions = [];
		if ( props.country != undefined && props.userProfile !== undefined ) {

			let userInfo = props?.userProfile
			let selectedCountryOption = props?.country?.map((countryItem) => {
				return {label: countryItem?.countryName,value: countryItem?.isoCode2Digit};}
			).find((x) => x.value === userInfo?.country);
			
			let selectedTimezone = timezonesOptions?.find((x) => {
				return x.value === userInfo?.timezone
			});

			props?.country?.length > 0 && props?.country?.map((x, key) => {
				let temp = { label: x.countryName, value: x.isoCode2Digit }
				countryOptions.push(temp);
			})	
			setValue('firstName',userInfo?.firstName)
			setValue('lastName',userInfo?.lastName)
			if(userInfo?.temporaryEmail){
				setValue('email',userInfo?.temporaryEmail)
			}else{
				setValue('email',userInfo?.email)
			}
			setValue('country',selectedCountryOption )
			setValue('zipCode', userInfo?.zipCode)
			setValue('timeZone', selectedTimezone)
			setValue('defaultMeetingURL',userInfo?.advisorProfile?.defaultMeetingURL)
			setValue('defaultMeetingPassword',userInfo?.advisorProfile?.defaultMeetingPassword)
			setCountryOption(countryOptions)
			setLoading(false)
		}
	}

	const handleChange = (e) => {
		clearErrors()
		if (errorList) {
			setErrorList({})
		}
	}

	const loadZipcodeOptions = (value) => {
		if (value === "") {
			setZipCodeOption([]);
			return;
		}
		if (value) {
			setLoadZipcodes(true);
			let payload = {
				searchString: value,
				pageNo: 1,
				limit: 80,
			};
			loadZipcode(payload)
				.then((res) => {
					const list = res?.data?.data.map((item) => ({
						label: item.zip,
						value: item.zip
					}));
					setZipCodeOption(list);
					setLoadZipcodes(false);
				})
				.catch((error) => {
					console.error("Error loading zip code options:", error);
					setZipCodeOption([]);
					setLoadZipcodes(false);
				});
		}
	};
	const handleVerifyEmail = (e) => {
		props.resendVerifyEmail();
	}

	function convertTimeFormat(timeStr) {
		let parts = timeStr.split(':');
		let newTime = parts.slice(0, 2).join(':');
		return newTime;
	}

	const onSubmit = data => {
		console.log(data);
		let payLoad = {}
		let {mondayTimesheet, tuesdayTimesheet, wednesdayTimesheet, thursdayTimesheet, fridayTimesheet, saturdayTimesheet, sundayTimesheet} = props?.userProfile?.advisorProfile?.advisorWorkingHours;

		let {firstName, lastName, email, country, zipCode, timeZone, defaultMeetingPassword, defaultMeetingURL } = data
		payLoad.firstName = firstName
		payLoad.lastName = lastName
		payLoad.email = email
		payLoad.country = country.value
		payLoad.zipCode = zipCode
		payLoad.timeZone = timeZone?.value
		payLoad.defaultMeetingPassword = defaultMeetingPassword
		payLoad.defaultMeetingURL = defaultMeetingURL
		payLoad.mondayAvailableTimes = mondayTimesheet?.map(date => {return {startTime: convertTimeFormat(date?.startingTime),endTime:convertTimeFormat(date?.closingTime)}})
		payLoad.tuesdayAvailableTimes = tuesdayTimesheet?.map(date => {return {startTime: convertTimeFormat(date?.startingTime),endTime:convertTimeFormat(date?.closingTime)}})
		payLoad.wednesdayAvailableTimes = wednesdayTimesheet?.map(date => {return {startTime: convertTimeFormat(date?.startingTime),endTime:convertTimeFormat(date?.closingTime)}})
		payLoad.thursdayAvailableTimes = thursdayTimesheet?.map(date => {return {startTime: convertTimeFormat(date?.startingTime),endTime:convertTimeFormat(date?.closingTime)}})
		payLoad.fridayAvailableTimes = fridayTimesheet?.map(date => {return {startTime: convertTimeFormat(date?.startingTime),endTime:convertTimeFormat(date?.closingTime)}})
		payLoad.saturdayAvailableTimes = saturdayTimesheet?.map(date => {return {startTime: convertTimeFormat(date?.startingTime),endTime:convertTimeFormat(date?.closingTime)}})
		payLoad.sundayAvailableTimes = sundayTimesheet?.map(date => {return {startTime: convertTimeFormat(date?.startingTime),endTime:convertTimeFormat(date?.closingTime)}})
		console.log(payLoad);
		props.updateGeneralInfo(payLoad)
		setLoading(true)
	}
    return (
		<>
			{ loading && <Loader />}
            <div className="generalForm--sec">
				<p className="profile__form__sub-title">General</p>
				<div className="generalForm--sec__form--sec">
					<Box sx={{ flexGrow: 1 }}>
						<form onSubmit={handleSubmit(onSubmit)}>
							<Grid container xs={12} sm= {11} md={12} lg={12} columnSpacing={1}>
								<Grid item xs={12} sm= {12} md={12} lg={6} >
									<div className="input-floating-label">
										<input refs="firstName" type="text" {...register('firstName')} className={"textbox--primary textbox--rounded input"}  name="firstName" placeholder=" " onChange={(e) => handleChange(e)}/>
										<label>{FIRST_NAME_LABEL}</label>
										<div className="error-text" style={{ "position": "absolute" }} >{errors?.firstName?.message}</div>
										{ props?.errorList?.firstName && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.firstName}</div> }
									</div>
								</Grid>
								<Grid item xs={12} sm= {12} md={12} lg={6} >
									<div className="input-floating-label">
										<input refs="lastName" type="text" name="lastName" {...register('lastName')} className={"textbox--primary textbox--rounded input"}  placeholder=" " onChange={(e) => handleChange(e)}/>
										<label>{LAST_NAME_LABEL}</label>
										<div className="error-text" style={{ "position": "absolute" }} >{errors?.lastName?.message}</div>
										{ errorList?.lastName && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.lastName}</div> }
									</div>
								</Grid>
								<Grid item xs={12} sm= {12} md={12} lg={12} >
									<div className="input-floating-label">
										<input refs="email" type="text" name="email" {...register('email')} className={"textbox--primary textbox--rounded input"}  placeholder=" " onChange={(e) => handleChange(e)}/>
										<label>{EMAIL_LABEL}</label>
										<div className="error-text" style={{ "position": "absolute" }} >{errors?.email?.message}</div>
										{ errorList?.email && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.email}</div> }
									</div>
									{ props?.userProfile?.temporaryEmail &&
										<span className="generalForm--sec__form--sec__emailResend">
											<p className="generalForm--sec__form--sec__emailResend__text">Please check your email to confirm this address {props?.userProfile?.email} will be used until confirmed</p>
											<div className="generalForm--sec__form--sec__emailResend__advisoryBtn" onClick={(e) => handleVerifyEmail(e)}>Resend Email</div>
										</span>
									}
								</Grid>
								<Grid item xs={12} sm= {6} md={12} lg={6} >
									<div className="input-floating-label">
										<Controller
											name="country" control={control} render={({ field }) =>
												<Select className="goals__form__select mb-0" classNamePrefix="mySelect" {...field}  name="country" options={countryOption} closeMenuOnSelect={true}
													isClearable={false} placeholder={COUNTRY_NAME_LABEL} components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
													styles={{ container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), 
														valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', }), 
														placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue 
															? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, 
															background: '#fff', paddingLeft: 10, paddingRight: 10, 
															display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) 
													}} 
												/>
											}
										/>
										<div className="error-text" style={{ "position": "absolute" }} >{errors?.country?.message}</div>
									</div>
								</Grid>
								<Grid item xs={12} sm= {6} md={12} lg={6} >
									<Controller
                                        name="zipCode"
                                        control={control}
                                        defaultValue=""
                                        render={({ field, onChange }) => (
											<Autocomplete {...field}
												className="goals__form__select input-floating-label" id="zipCode" name="zipCode" type='text'
												freeSolo clearIcon={false} filterOptions={(options, state) => options} 
												options={zipCodeOption?.map((option) => {
													return { label: option.label, value: option.value }
												}) ?? []}
												getOptionLabel={(option) => {
													if (typeof option === 'object' && option !== null && 'label' in option) {
														return option.label.toString();
													} else {
														return option.toString();
													}
												}}
												onChange={(event, option) => field.onChange(option ? option.value : '')}
												onInputChange={async (event, value) => { loadZipcodeOptions(value) }}
												renderInput={(params) => (
													<div className="input-floating-label" ref={params.InputProps.ref} style={{marginBottom: '4px'}}>
														<input type="text" {...params.inputProps} className={"textbox--primary textbox--rounded input"} id="zipCode" name="zipCode" placeholder=" " />
														<label>{ZIP_CODE_LABEL}</label>
														<div className="error-text" style={{ "position": "absolute" }} >{errors?.zipCode?.message}</div>
														{ errorList?.zipCode && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.zipCode}</div> }
													</div>
												)}
											/>
										)}
									/>
								</Grid>
								<Grid item xs={12} sm= {12} md={12} lg={12} >
									<Controller
										control={control}
										name="timeZone"
										render={({ field }) => (
											<div className="input-floating-label">
												<Select placeholder={"Time Zone"} {...field}  closeMenuOnSelect={true} isClearable={false}
													isSearchable={false} classNamePrefix="mySelect" options={timezonesOptions} components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
													styles={{ option: (provided, state) => ({ ...provided, cursor: 'pointer', }), container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }} />
												{/* <div className="error-text" style={{ "position": "absolute" }} >{errors.timeZone?.message}</div> */}
											</div>
										)}
									/>
								</Grid>
								<Grid item xs={12} sm= {12} md={12} lg={12} >
									<div className="input-floating-label">
										<input refs="defaultMeetingURL" type="text" id="defaultMeetingURL" {...register('defaultMeetingURL')} 
											className={"textbox--primary textbox--rounded input"} name="defaultMeetingURL" placeholder=" " onChange={(e) => handleChange(e)} />
										<label>{MEET_URL}</label>
										<p className="cta--text" style={{ "fontSize": "12px", "marginLeft": "20px", marginBottom: 0 }}>Optional</p>
										<div className="error-text" style={{ "position": "absolute" }} >{errors?.defaultMeetingURL?.message}</div>
										{ errorList?.defaultMeetingURL && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.defaultMeetingURL}</div> }
									</div>
								</Grid>
								<Grid item xs={12} sm= {12} md={12} lg={12} >
									<div className="input-floating-label">
										<input refs="defaultMeetingPassword" type="text" id="defaultMeetingPassword" {...register('defaultMeetingPassword')} className={"textbox--primary textbox--rounded input"} name="defaultMeetingPassword" placeholder=" " 
											onChange={(e) => handleChange(e)} />
										<label>{MEET_PASSCODE}</label>
										<p className="cta--text" style={{ "fontSize": "12px", "marginLeft": "20px", marginBottom: 0 }}>Optional</p>
										<div className="error-text" style={{ "position": "absolute" }} >{errors?.defaultMeetingPassword?.message}</div>
										{ errorList?.defaultMeetingPassword && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.defaultMeetingPassword}</div> }
									</div>
								</Grid>
								<Grid item xs={12} sm= {12} md={12} lg={12} >
									<div className="generalForm--sec__form--sec__dltprofilefcnt">
										<Button type="submit" className="btn cta--rounded generalForm--sec__form--sec__dltprofilefcnt__profilesavebtn" >
											Save
										</Button>
									</div>
								</Grid>
							</Grid>
						</form>
					</Box>
				</div>
			</div>
        </>
    );
};

const mapStateToProps = ({ commonData, userProfileData, auth }) => {
    const { message, errorList, country, loading } = commonData
    const { userProfile, isSetGeneralInfoSuccess, isProfileSuccess, isSetGeneralInfoError } = userProfileData;
    const { isResendEmailSuccess } = auth;
    return { message, errorList, country, userProfile, loading, isSetGeneralInfoSuccess,isProfileSuccess, 
		isSetGeneralInfoError, isResendEmailSuccess };
};
export default connect(mapStateToProps, { updateGeneralInfo, setIsUpdateGeneralInfoSuccess, setIsUpdateGeneralInfoError, 
	setResendVerifyEmail, getUserProfile, resendVerifyEmail, clearErrorList })(Index);
