import React, { useState, useEffect } from "react";
import "../../../../../assets/css/New_Profile.less";
import { Button } from "react-bootstrap";
import { Grid, Box, } from "@mui/material";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { changePassword, changePasswordStatus, changePasswordErrorStatus } from "../../../../../redux/actions";
import { connect } from "react-redux";
import Loader from "../../../../../components/Loader";

const validation = Yup.object({
    password: Yup.string().required("* Required").trim().nullable().min(6, 'Minimum 6 characters required')
    .matches(/[a-z]/, "Password requires atleast 1 lowercase")
        .matches(/[A-Z]/, "Password requires atleast 1 uppercase")
        .matches(/[0-9]/, "Password requires atleast 1 digit"),
    newPassword: Yup.string().required("* Required").trim().nullable()
        .matches(/[a-z]/, "Password requires atleast 1 lowercase")
        .matches(/[A-Z]/, "Password requires atleast 1 uppercase")
        .matches(/[0-9]/, "Password requires atleast 1 digit")
        .matches(/(?=.*?[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/, "Password requires atleast 1 Special character")
        .min(6, 'Minimum 6 characters required')
        .test('passwords-match', 'New password must be different from current password', function (value) {
            return this.parent.password !== value;
        }),
    confirmPassword: Yup.string().required("* Required").trim().nullable().oneOf([Yup.ref('newPassword'), null], 'Mismatch in confirm password with new password'),
});

const Index = (props) => {
    const [loading, setloading] = useState(false)
    const [errorList, setErrorList] = useState([]);

    const { register, handleSubmit, formState: { errors, }, reset, clearErrors } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(
            validation
        ),
    });

    useEffect(() => {
        if (props.isPasswordUpdateerror) {
            setloading(false)
            props.changePasswordErrorStatus(false)
        }
    }, [props.errorlist, props.isPasswordUpdate, props.isPasswordUpdateerror, props.errorList, props.message])

    useEffect(() => {
        if (props.isPasswordUpdate === true) {
            setloading(false)
            reset();
            props.changePasswordStatus(false)
        }
    }, [props.isPasswordUpdate])

    useEffect(() => {
        if (props.isPasswordUpdateerror) {
            setloading(false)
            props.changePasswordErrorStatus(false)
        }
    }, [props.isPasswordUpdateerror])

    const onValidationChange = e => {
        clearErrors('newPassword')
        clearErrors('password')
        clearErrors('confirmPassword')
        if (props?.errorList) {
            setErrorList([])
        }
    }
    useEffect(() => {
        if (props?.errorList) {
            setErrorList(props?.errorList)
        }
    }, [props?.errorList]);

    const onSubmit = data => {
        setloading(true)
        props.changePassword(data)
        // reset();
    }
    return (
        <>
            {loading && <Loader />}
            <div className="Change_Password_Card">
                <p className="Change_Password_Card_Title">Change Password</p>
                <div className="Change_Password_Card_Form">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={11} md={12} lg={12}>
                                <div className="input-floating-label">
                                    <input refs="password" type="password" id="currentpassword" {...register("password")} onChange={(e) => { onValidationChange(e); }}
                                        className={"textbox--primary textbox--rounded input"}
                                        name="password" placeholder=" " />
                                    <label>Current password</label>
                                    <div className="error-text" style={{ "position": "absolute" }} >{errors.password?.message}</div>
                                    {errorList?.password && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.password}</div>}
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={11} md={12} lg={12} >
                                <div className="input-floating-label">
                                    <input refs="newPassword" type="password" id="newpassword" {...register("newPassword")} onChange={(e) => { onValidationChange(e); }} className={"textbox--primary textbox--rounded input"}
                                        name="newPassword" placeholder=" " />
                                    <label>Create new password</label>
                                    <p className="cta--text mb-0" style={{ "fontSize": "13px", }}>Must contain 1 uppercase, 1 lowercase, 1 digit, 1 special character and least 6 or more character</p>
                                    <div className="error-text" style={{ "position": "absolute" }}>{errors.newPassword?.message}</div>
                                    {errorList?.newPassword && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.newPassword}</div>}
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={11} md={12} lg={12} >
                                <div className="input-floating-label">
                                    <input refs="confirmPassword" id="confirmpassword" {...register("confirmPassword")} onChange={(e) => { onValidationChange(e); }} type="password" className={"textbox--primary textbox--rounded input"}
                                        name="confirmPassword" placeholder=" " />
                                    <label>Confirm password</label>
                                    <div className="error-text" style={{ "position": "absolute" }}>{errors.confirmPassword?.message}</div>
                                    {errorList?.confirmPassword && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.confirmPassword}</div>}
                                </div>
                            </Grid>
                        </Grid>
                        <div className="col-lg-8 col-md-8 col-sm-11 col-11 ps-0 p-mob-0"
                            style={{ display: "flex", justifyContent: "center", marginInline: 'auto', marginTop: '20px',marginBottom: '20px' }}
                        >
                            <Button type="submit" id="Resetsubmit" className="btn cta--rounded cta-primary w-80" style={{"borderRadius":"10px"}}  >
                                <span>Reset password</span>
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
const mapStateToProps = ({ userProfileData, commonData }) => {
    const { message, errorList } = commonData
    const { isPasswordUpdate, isPasswordUpdateerror } = userProfileData;
    return { isPasswordUpdate, isPasswordUpdateerror, message, errorList };
};
export default connect(mapStateToProps, { changePassword, changePasswordStatus, changePasswordErrorStatus })(Index);
