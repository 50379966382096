import React, { useState, useEffect } from "react";
import "../profile.less"
import { Button, Modal, Typography, Box, TextField, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ConnectChildForm from "../connectedChildForm";
import ConnectGoals from "../connectedGoalsPopup";
import { connect } from "react-redux";
import { getGoalsFromStudent, reInviteVerifyEmail, setReInviteVerifyEmail } from "../../../../redux/actions";
import ConfirmPopup from "../../../../componentsNew/Common/ConfirmationPopup";



const Index = (props) => {
    const theme = useTheme();
    const [addOnopen, setAddOnOpen] = useState(false);
    const [editData, seteditData] = useState([]);
    const [disconnectData, setDisconnectData] = useState([]);
    const [confirmopen, setOpen] = useState(false);
    const [disconnectOpen, setDisconnectOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const calculateTimeAgo = (lastLogin) => {
        const currentDateTime = new Date();
        const loginDateTime = new Date(lastLogin); // Make sure your lastLogin includes the time zone information
    
        const timeDifference = currentDateTime - loginDateTime;
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
    
        if (hours >= 24) {
          return `${Math.floor(hours / 24)} ${Math.floor(hours / 24) === 1 ? 'day' : 'days'} ago`;
        } else if (hours >= 1) {
          return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
        } else if (minutes >= 1) {
          return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
        } else {
          return `${seconds} ${seconds === 1 ? 'second' : 'seconds'} ago`;
        }
      };


    const handleAddOnOpen = (data) => {
        setLoading(true)
        let getStudentId = {
            "studentId": data?.id
        }
        props.getGoalsFromStudent(getStudentId)
        seteditData(data)
        setAddOnOpen(true);
    }

    const handleSetLoad = (data) => {
        setLoading(data)
    }

    const handleConfirmOpen = (data) => {
        setDisconnectData(data)
        setOpen(true);
    }
    const handleDisconnectOpen = (data) => {
        setDisconnectData(data)
        setDisconnectOpen(true);
    }

    const handleReInvite = () => {
        props?.reInviteVerifyEmail(disconnectData?.id)
    }

    const handleAddOnClose = () => setAddOnOpen(false);
    const handleClose = () => setOpen(false);
    const handleDisConnectClose = () => setDisconnectOpen(false);
    useEffect(() => {
        if (props?.isReInvite) {
            handleClose()
            props?.setReInviteVerifyEmail(false)
        }
        if (props?.isDisconnectUser) {
            handleDisConnectClose()
        }
    }, [props?.isReInvite, props?.isDisconnectUser]);
    console.log(props.childDetails, "props.childData")

    return (
        <>
           {addOnopen && <ConnectGoals errorList={props?.errorList} openloader={loading} handleLoaderFalse={(data) => handleSetLoad(data)} show={addOnopen} data={editData} handleClose={handleAddOnClose} graduationyear={props?.graduationyear} status={props?.status} gender={props?.gender} countryOption={props?.countryOption} />}
            <ConfirmPopup show={confirmopen} message={`We have sent another invite email to ${disconnectData?.fullName}`} btnText={"Got it "} onHide={handleClose} handleSubmit={() => handleReInvite()} />
            <ConfirmPopup show={disconnectOpen} message={"You will no longer be connected to this account. The student will continue to have access to their account"} btnText={"Confirm"} onHide={handleDisConnectClose} handleSubmit={() => props?.handleDisconnectUser(disconnectData)} />

            <p className="profile__form__sub-title">Your child’s accounts</p>
            {props && props.childData?.length > 0 && props?.childData?.map((data, key) => {
                const matchingActivity = props?.childDetails?.find(activity => activity?.userId === data?.id);
                const lastActivity = matchingActivity ? matchingActivity?.lastActivity : null;
                console.log(lastActivity, "lastActivity")
                return (
                    <Card className="cardContent mb-3" key={data?.id}>
                        <Grid container spacing={2} >
                            <Grid item sm={6} xs={12} className=" m-0 p-0">
                                <div className="d-flex">
                                    <div className="d-flex align-items-center p-3">
                                        {data?.avatarUrl ?
                                            <img src={data && data?.avatarUrl} alt="profileimage" className="profileimage" />
                                            :
                                            <AccountBoxIcon sx={{ fontSize: '5rem', fill: 'grey' }} className="profileimage" />
                                        }
                                    </div>

                                    <div className="">
                                        <p className="profile__form__sub-title d-flex align-items-center" style={{ marginTop: '1.5rem' }}>{data?.firstName}</p>
                                        {lastActivity && <p>Last login {calculateTimeAgo(lastActivity)}</p>}
                                    </div>
                                </div>



                            </Grid>
                            <Grid item sm={6} xs={12} className="m-0 p-0">
                                <div style={{ textAlign: 'right', marginRight: '2rem' }}><Button variant="outlined" onClick={() => handleAddOnOpen(data)} className="outLineBtn" sx={{ marginTop: '1rem', width: '7rem' }}>Edit</Button></div>
                                {!data?.isEmailVerified && <div style={{ textAlign: 'right', marginRight: '2rem' }}><Button variant="outlined" onClick={() => handleConfirmOpen(data)} className="outLineBtn" sx={{ marginTop: '1rem', width: '7rem' }}>Re-invite</Button></div>}
                                <div className=" mb-2" style={{ textAlign: 'right', marginRight: '2rem' }}><Button variant="outlined" onClick={() => handleDisconnectOpen(data)} className="outLineBtnRed" sx={{ marginTop: '1rem' }}>Disconnect</Button></div>
                            </Grid>

                        </Grid>
                    </Card>

                );
            })}



        </>
    )
}
const mapStateToProps = ({ userProfileData, commonData, auth }) => {
    const { userProfile, isProfileSuccess, childprofile, childDetails, parentProfile, isChildProfileSucess, isEditChildError, isCreateChildError, editChildProfileSucess, isDisconnectUser } = userProfileData;
    const { status, gender, country, graduationyear, schools, professions } = commonData;
    const { isReInvite } = auth;
    return { userProfile, isProfileSuccess, status, gender, schools, country, childDetails, isCreateChildError, professions, isEditChildError, childprofile, isChildProfileSucess, editChildProfileSucess, parentProfile, graduationyear, isReInvite, isDisconnectUser };
};

export default connect(mapStateToProps, { getGoalsFromStudent, reInviteVerifyEmail, setReInviteVerifyEmail })(Index);

