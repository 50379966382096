import axios from 'axios'
import { FETCH_DATA, FETCH_DATA_SSSS, FETCH_DATA_SUCCESS, FETCH_GENERIC_DATA_SECTION_SUCCESS, FETCH_GENERIC_DATA_SUCCESS, FETCH_STUDENT_BY_SECTION, RESET_DATA } from '../constants/ExploreNetwork';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const fetchData = () => {
    return (dispatch) => {
		dispatch({ type: FETCH_DATA });
    }
};
export const resetData = () => {
    return (dispatch) => {
		dispatch({ type: RESET_DATA });
    }
};
export const fetchDataSuccess = (data) => {
    return (dispatch) => {
		dispatch({ type: FETCH_DATA_SUCCESS, payload: data });
    }
};

export const setAllDatasLoaded = (data) => {
    return (dispatch) => {
		dispatch({ type: FETCH_DATA_SSSS, payload: data });
    }
};

export const resetGenericStudentsData = data => {
	return (dispatch) => {
		dispatch({ type: FETCH_GENERIC_DATA_SUCCESS, payload: data });
	}
}

export const fetchAllData = (requestParams) => async (dispatch) => {
    try {
        dispatch(fetchData());
        const [universityData, professionData, courseData, activityData] = await Promise.all([
            axios.post(BASE_URL + '/user-service/v1/search/suggestedstudents/categories/university', requestParams),
            axios.post(BASE_URL + '/user-service/v1/search/suggestedstudents/categories/profession', requestParams),
            axios.post(BASE_URL + '/user-service/v1/search/suggestedstudents/categories/course', requestParams),
            axios.post(BASE_URL + '/user-service/v1/search/suggestedstudents/categories/activity', requestParams)
        ]);
        
        dispatch(fetchDataSuccess([...universityData?.data?.data, ...professionData?.data?.data, ...courseData?.data?.data, ...activityData?.data?.data]));
    } catch ({response}) {
        // dispatch(fetchDataError(error.message));
        console.log(response);
        
    }
};

export const fetchStudentsBySection = (requestParams) => async (dispatch) => {
    try {
        console.log("API");
        // dispatch(fetchData());
        const { data } = await axios.post(BASE_URL + '/user-service/v1/search/suggestedstudentsforparents/bysection', requestParams);
        dispatch({ type: FETCH_STUDENT_BY_SECTION, payload: data?.data?.[0] });
    } catch (error) {
        // dispatch(fetchDataError(error.message));
        console.log(error,"reeor");
    }
};

export const genericSearchStudents = (search, requestParams) => {
	return async (dispatch) => {
        dispatch(fetchData());
		try {
			const { data } = await axios.post(BASE_URL + '/user-service/v1/search/genericsearchforstudents/'+ search, requestParams)
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				console.log(data, "dataaaa");
                dispatch({ type: FETCH_GENERIC_DATA_SUCCESS, payload: data?.data })
			} 
		} catch (error) {
			// if (error.response) {
			// 	const { headers: { message, statusCode }, errorlist } = error.response.data;
			// 	dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			// } else {
			// 	dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
			// }
            console.log(error, "error");
		}
	};
};

export const genericSearchStudentsBySection = (search, requestParams) => {
	return async (dispatch) => {
        dispatch(fetchData());
		try {
			const { data } = await axios.post(BASE_URL + '/user-service/v1/search/genericsearchforstudents/'+ search, requestParams)
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				console.log(data, "Section dataaaa");
                dispatch({ type: FETCH_GENERIC_DATA_SECTION_SUCCESS, payload: data?.data })
			} 
		} catch (error) {
            console.log(error, "error");
		}
	};
};

export const fetchAllDataStudents = (requestParams) => async (dispatch) => {
    try {
        dispatch(fetchData());
        const [universityData, professionData, courseData, activityData] = await Promise.all([
            axios.post(BASE_URL + '/user-service/v1/search/suggestedstudentsforparent/categories/university', requestParams),
            axios.post(BASE_URL + '/user-service/v1/search/suggestedstudentsforparent/categories/profession', requestParams),
            axios.post(BASE_URL + '/user-service/v1/search/suggestedstudentsforparent/categories/course', requestParams),
            axios.post(BASE_URL + '/user-service/v1/search/suggestedstudentsforparent/categories/activity', requestParams)
        ]);
        
        dispatch(fetchDataSuccess([...universityData?.data?.data, ...professionData?.data?.data, ...courseData?.data?.data, ...activityData?.data?.data]));
    } catch (error) {
        // dispatch(fetchDataError(error.message));
        console.log(error);
        
    }
};

export const fetchBySectionForStudent = (requestParams) => async (dispatch) => {
    try {
        console.log("API");
        // dispatch(fetchData());
        const { data } = await axios.post(BASE_URL + '/user-service/v1/search/suggestedstudentsforparents/bysection', requestParams);
        dispatch({ type: FETCH_STUDENT_BY_SECTION, payload: data?.data?.[0] });
    } catch (error) {
        // dispatch(fetchDataError(error.message));
        console.log(error,"reeor");
    }
};

export const fetchAllAdvisors = (requestParams) => async (dispatch) => {
    try {
        dispatch(fetchData());
        const [universityData, professionData, courseData, activityData] = await Promise.all([
            axios.post(BASE_URL + '/user-service/v1/search/suggestedadvisors/categories/university', requestParams),
            axios.post(BASE_URL + '/user-service/v1/search/suggestedadvisors/categories/profession', requestParams),
            axios.post(BASE_URL + '/user-service/v1/search/suggestedadvisors/categories/course', requestParams),
            axios.post(BASE_URL + '/user-service/v1/search/suggestedadvisors/categories/activity', requestParams)
        ]);
        
        dispatch(fetchDataSuccess([...universityData?.data?.data, ...professionData?.data?.data, ...courseData?.data?.data, ...activityData?.data?.data]));
    } catch (error) {
        // dispatch(fetchDataError(error.message));
        console.log(error);
        
    }
};

export const fetchBySectionAdvisors = (requestParams) => async (dispatch) => {
    try {
        console.log("API");
        // dispatch(fetchData());
        const { data } = await axios.post(BASE_URL + '/user-service/v1/search/suggestedadvisors/bysection', requestParams);
        dispatch({ type: FETCH_STUDENT_BY_SECTION, payload: data?.data?.[0] });
    } catch (error) {
        // dispatch(fetchDataError(error.message));
        console.log(error,"reeor");
    }
};

export const genericSearchAdvisors = (search, requestParams) => {
	return async (dispatch) => {
        dispatch(fetchData());
		try {
			const { data } = await axios.post(BASE_URL + '/user-service/v1/search/genericsearchforadvisors/'+ search, requestParams)
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				console.log(data, "dataaaa");
                dispatch({ type: FETCH_GENERIC_DATA_SUCCESS, payload: data?.data })
			} 
		} catch (error) {
			// if (error.response) {
			// 	const { headers: { message, statusCode }, errorlist } = error.response.data;
			// 	dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			// } else {
			// 	dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
			// }
            console.log(error, "error");
		}
	};
};

export const genericSearchAdvisorsBySection = (search, requestParams) => {
	return async (dispatch) => {
        dispatch(fetchData());
		try {
			const { data } = await axios.post(BASE_URL + '/user-service/v1/search/genericsearchforadvisors/'+ search, requestParams)
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				console.log(data, "Section dataaaa");
                dispatch({ type: FETCH_GENERIC_DATA_SECTION_SUCCESS, payload: data?.data })
			} 
		} catch (error) {
            console.log(error, "error");
		}
	};
};
