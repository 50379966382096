import React, { useState, useEffect } from "react";
import Popover from '@mui/material/Popover';
import { Typography, Grid, Chip } from '@mui/material';
import "./minipills.less";

const Index = (props) => {
    const [remainingData, setRemainingData] = useState([]);

    useEffect(() => {
        if (props?.data && props.data.length > 5) {
            setRemainingData(props.data.slice(5));
        }
    }, [props.data]);

    return (
        <>
            <div className="pillsection">
                {props?.data?.slice(0, 5).map((data, key) => (
                    <Chip size="small" label={data} className="pillchips" key={key} />
                ))}
                {remainingData.length > 0 && (
                    <RemainingChip remainingData={remainingData} />
                )}
            </div>
        </>
    );
};

const RemainingChip = ({ remainingData }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <Chip
                size="small"
                label={`+${remainingData.length}`}
                className="pillchips"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            />
                       <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Grid container direction="column" spacing={1} sx={{ p: 1 }}>
                    {remainingData.map((item, index) => (
                        <Grid item key={index}>
                            <Typography>{item}</Typography>
                        </Grid>
                    ))}
                </Grid>
            </Popover>
        </>
    );
};

export default Index;
