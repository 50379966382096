import React, { useState, useEffect } from "react";
import "../../../../../assets/css/New_Profile.less";
import { Button } from "@mui/material";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DeleteProfile from '../../../../../componentsNew/Common/DeleteProfile'

const Index = (props) => {
    const [showModal, setshowModal] = useState(false)

    const handleConfirmModel = e => setshowModal(showModal => !showModal)
    return (
        <>
            <div className="Delete_Profile_Card">
                <div className="Delete_Profile_Card_Top">
                    <p className="Delete_Profile_Card_Title">This will erase all your data on the platform</p>
                </div>
                <div className="Delete_Profile_Btm mb-2">
                    <Button className="DeleteBtn" startIcon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" > {" "}
                        <path d="M10 2.25C9.58579 2.25 9.25 2.58579 9.25 3V3.75H5C4.58579 3.75 4.25 4.08579 4.25 4.5C4.25 4.91421 4.58579 5.25 5 5.25H19C19.4142 5.25 19.75 4.91421 19.75 4.5C19.75 4.08579 19.4142 3.75 19 3.75H14.75V3C14.75 2.58579 14.4142 2.25 14 2.25H10Z"
                            fill="#E64A19"
                        />{" "}
                        <path d="M13.0607 15L14.5303 16.4697C14.8232 16.7626 14.8232 17.2374 14.5303 17.5303C14.2374 17.8232 13.7626 17.8232 13.4697 17.5303L12 16.0607L10.5303 17.5303C10.2374 17.8232 9.76257 17.8232 9.46968 17.5303C9.17678 17.2374 9.17678 16.7626 9.46968 16.4697L10.9393 15L9.46967 13.5303C9.17678 13.2374 9.17678 12.7626 9.46967 12.4697C9.76256 12.1768 10.2374 12.1768 10.5303 12.4697L12 13.9393L13.4697 12.4697C13.7626 12.1768 14.2374 12.1768 14.5303 12.4697C14.8232 12.7626 14.8232 13.2374 14.5303 13.5303L13.0607 15Z"
                            fill="#E64A19"
                        />{" "}
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M5.99142 7.91718C6.03363 7.53735 6.35468 7.25 6.73684 7.25H17.2632C17.6453 7.25 17.9664 7.53735 18.0086 7.91718L18.2087 9.71852C18.5715 12.9838 18.5715 16.2793 18.2087 19.5446L18.189 19.722C18.045 21.0181 17.0404 22.0517 15.7489 22.2325C13.2618 22.5807 10.7382 22.5807 8.25108 22.2325C6.95954 22.0517 5.955 21.0181 5.81098 19.722L5.79128 19.5446C5.42846 16.2793 5.42846 12.9838 5.79128 9.71852L5.99142 7.91718ZM7.40812 8.75L7.2821 9.88417C6.93152 13.0394 6.93152 16.2238 7.2821 19.379L7.3018 19.5563C7.37011 20.171 7.84652 20.6612 8.45905 20.747C10.8082 21.0758 13.1918 21.0758 15.5409 20.747C16.1535 20.6612 16.6299 20.171 16.6982 19.5563L16.7179 19.379C17.0685 16.2238 17.0685 13.0394 16.7179 9.88417L16.5919 8.75H7.40812Z"
                            fill="#E64A19"
                        />{" "}
                    </svg>} onClick={(e) => handleConfirmModel(e)} id="deleteaccntbtn" >Delete account</Button>
                </div>
                <div style={{ "display": "flex", "justifyContent": "center" }} >
                    <p style={{ "fontSize": "12px" }} >Users who purchased access to your course or activity data will continue to have access.</p>
                </div>
            </div>
            <DeleteProfile show={showModal} handleClose={handleConfirmModel} />
        </>
    );
}
export default Index;