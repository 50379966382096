import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { updatePassword, verifyPassword,verifyPasswordstatus } from "../../../redux/actions";
import { connect } from "react-redux";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { withRouter } from "../../../redux/store/navigate";
import { CONFIRM_PASSWORD_LABEL, NEW_PASSWORD_LABEL } from "../../../Constants/commonLabels";

const IDENTIFIER = process.env.REACT_APP_IDENTIFIER;

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            errors: {},
            fields: {},
        };
        this.handleValidation = this.handleValidation.bind(this)
    }
    handleChange(field, e) {
        let { errors } = this.state;
        let fields = this.state.fields;
        fields[field] = e.target.value;
        if (e.target.value.length >= 0) {
            errors[field] = "";
        }
        this.setState({ fields, errors: errors });
    }
    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (typeof fields["password"] !== "undefined") {
            const uppercaseRegExp = /(?=.*?[A-Z])/;
            const lowercaseRegExp = /(?=.*?[a-z])/;
            const digitsRegExp = /(?=.*?[0-9])/;
            const specialCharRegExp = /(?=.*?[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/;
            const minLengthRegExp = /.{6,}/;

            const uppercasePassword = uppercaseRegExp.test(fields["password"]);
            const digitsPassword = digitsRegExp.test(fields["password"]);
            const specialCharPassword = specialCharRegExp.test(fields["password"]);
            const lowercasePassword = lowercaseRegExp.test(fields["password"]);
            const minLengthPassword = minLengthRegExp.test(fields["password"]);
            if (!uppercasePassword) {
                formIsValid = false;
                errors["password"] = "Password requires atleast 1 uppercase";
            } else if (!lowercasePassword) {
                formIsValid = false;
                errors["password"] = "Password requires atleast 1 lowercase";
            } else if (!digitsPassword) {
                formIsValid = false;
                errors["password"] = "Password requires atleast 1 digit";
            } else if (!specialCharPassword) {
                formIsValid = false;
                errors["password"] = "Password requires 1 Special character";
            } else if (!minLengthPassword) {
                formIsValid = false;
                errors["password"] = "Password requires atleast 6 or more character";
            }
        }
        if (!fields["password"]) {
            formIsValid = false;
            errors["password"] = "Password cannot be empty";
        }

        if (fields["confirm-password"] != fields["password"]) {
            formIsValid = false;
            errors["confirm-password"] = "Mismatch in confirm password with password";
        }

        if (!fields["confirm-password"]) {
            formIsValid = false;
            errors["confirm-password"] = "Confirm password cannot be empty";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    componentDidMount() {
        const paramskey = window.location.pathname.split("/").pop()
        let values = {
            passwordResetKey: paramskey,
            identifier: IDENTIFIER
        }
        if (paramskey && IDENTIFIER === null || undefined) {
            this.props.navigate('/')
        }
        this.props.verifyPassword(values)
    }

    componentDidUpdate(){
        let verifylink = this.props.isverifyPassword
        if(this.props.isverifyPassword === false){
           this.props.navigate('/')
           this.props.verifyPasswordstatus(false)
        }
        let UpdatePassword = this.props.isUpdatePassword
        if(this.props.isUpdatePassword){
            this.props.navigate('/')
        }
        if(this.props.isUpdatePasswordstatus===false){
            this.props.navigate('/')
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        const paramskey = window.location.pathname.split("/").pop()
        let fields = this.state.fields;
        if (this.handleValidation()) {
            let values = {
                newPassword: fields["password"],
                confirmNewPassword: fields["confirm-password"],
                identifier: IDENTIFIER,
                passwordResetKey: paramskey
            }
            this.props.updatePassword(values)
        } else {
            e.preventDefault();
        }
    };
    render() {
        const errorList = this.props.errorList;
        return (
            <>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="login">
                                <div className="Verification_card">
                                    <h1 className="login_title text-center">Create new password</h1>
                                    <p className="login_desc text-center"></p>
                                    <Form className="login_card_form mb-5 mt-5 text-center" autoComplete="off">
                                        <div className="login_card">
                                            <div className="input-floating-label">
                                                <input refs="password" type="password" name="password" placeholder=" "
                                                    className={this.state.errors["password"] ? "textbox--primary textbox--rounded input w-100 error-input" : "textbox--primary textbox--rounded input"}
                                                    onChange={this.handleChange.bind(this, "password")} value={this.state.fields["password"]} />
                                                <p className="cta--text mb-1" style={{ "fontSize": "13px", "marginTop": "10px", "letterSpacing":"0.1px"}}>Must contain 1 uppercase, 1 lowercase, 1 digit, 1 special character & least 6 character</p>
                                                <label>{NEW_PASSWORD_LABEL}</label>
                                                {this.state.errors["password"] && <span className="error-text">{this.state.errors["password"]}</span>}
                                                {errorList && errorList.password && <span className="error-text">{errorList.password}</span>}
                                            </div>
                                            <div className="input-floating-label">
                                                <input refs="confirm-password" type="password" className={this.state.errors["confirm-password"] ? "textbox--primary textbox--rounded input error-input" : "textbox--primary textbox--rounded input"}
                                                    name="confirm-password" placeholder="Confirm Password" onChange={this.handleChange.bind(this, "confirm-password")} value={this.state.fields["confirm-password"]} />
                                                <label >{CONFIRM_PASSWORD_LABEL}</label>
                                                {this.state.errors["confirm-password"] && <span className="error-text">{this.state.errors["confirm-password"]}</span>}
                                            </div>
                                            <Link to="#">
                                                <Button onClick={(e) => this.handleSubmit(e)} className="btn cta--rounded cta-primary w-100" >
                                                    <span>Save</span>
                                                </Button>
                                            </Link>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <div className="row m-0">
                        <div className="footer__left col-md-4 col-sm-4 col-4 text-center p-0">
                            <p>
                                <Link to="/" className="footer__left__cta">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M13.334 8.00065C13.334 7.63246 13.0355 7.33398 12.6673 7.33398H3.33398C2.96579 7.33398 2.66732 7.63246 2.66732 8.00065C2.66732 8.36884 2.96579 8.66732 3.33398 8.66732H12.6673C13.0355 8.66732 13.334 8.36884 13.334 8.00065Z" fill="#1B1C1E" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M8.47075 2.86128C8.2104 2.60093 7.78829 2.60093 7.52794 2.86128L2.86128 7.52794C2.60093 7.78829 2.60093 8.2104 2.86128 8.47075L7.52794 13.1374C7.78829 13.3978 8.2104 13.3978 8.47075 13.1374C8.7311 12.8771 8.7311 12.455 8.47075 12.1946L4.27549 7.99935L8.47075 3.80409C8.7311 3.54374 8.7311 3.12163 8.47075 2.86128Z" fill="#1B1C1E" />
                                    </svg>
                                    <span>Go back</span>
                                </Link>
                            </p>
                        </div>
                        <div className="footer__center col-md-4 col-sm-4 col-4 text-center">
                        </div>
                        <div className="footer__right col-md-4 col-sm-4 col-4 text-center">
                        </div>
                    </div>
                </div>
                <NotificationContainer />
            </>
        );
    }
}

const mapStateToProps = ({ auth, commonData }) => {
    const { message, errorList } = commonData
    const { isUpdatePassword, isverifyPassword } = auth;
    return { isUpdatePassword, isverifyPassword, message, errorList }
};

export default connect(mapStateToProps, { updatePassword, verifyPassword,verifyPasswordstatus })(withRouter(Index));