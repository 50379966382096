import React, { useState } from "react";
import "./totalRating.less"
import { Rating } from '@mui/material'
import { Button } from 'bootstrap'
import FeedBackPopup from "../../../../../components/FeedBackPopup";


const Index = (props) => {
  const [feedBack, setFeedBack] = useState(false);

  const showFeedBack = (e) => {
    e.preventDefault();
    setFeedBack(true)
  }

  return (
    <>
      <FeedBackPopup size={"lg"}
        dialogClassName=""
        fullscreen={true}
        centered={false}
        showFeedBack={feedBack}
        advisorDetail = {props?.advisorDetail}
        onHide={() => setFeedBack(false)}
        // onsetFeedBack={(isfeedBack) => this.setFeedBack(isfeedBack)}
         />

      <div className='totalRating-sec'>
        <p className='totalRating-sec__rate-value'>{props?.averageRating}</p>
        <Rating name="size-medium" defaultValue={props?.averageRating} readOnly />
        <p className='totalRating-sec__review-count'>{props?.totalReviews} Review</p>
        <button onClick={(e) => showFeedBack(e)} className='totalRating-sec__feedBack-btn'><p className='totalRating-sec__feedBack-btn__text'>Give Feedback</p></button>
      </div>
    </>
  )
}

export default Index


