import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getSentRequests, resetDetailedUserProfileStatus, sendRespondToRequest, CancelRequest, 
    CancelRequestStatus, clearMessage, setSentRequestsStatus, hideConnectionRequest, 
    setHideConnectionRequestStatus, getNetworkUserCount } from "../../../../redux/actions";
import Loader from "../../../../components/Loader"
import CustomAlert from "../../../../components/CustomAlert";
import ConfirmationPopup from "../../../../componentsNew/Common/ConfirmationPopup";
import SentRequestCard from "../../../../componentsNew/Common/Cards/Common/SentRequestCard";
import ViewStudentProfile from "../../../../componentsNew/Common/ViewStudentProfile";
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { Link } from "react-router-dom";

const Index = (props) => {
    const [loading, setLoading] = useState(false)
    const [showProfile, setShowProfile] = useState(false);
    const [profileData, setProfileData] = useState({});
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [rejectData, setRejectData] = useState({});


    useEffect(() => {
        props?.clearMessage()
        props?.getSentRequests()
        setLoading(true)
    }, [])

    useEffect(() => {
        if (props.sendRequestStatus) {
            setLoading(false)
            props.setSentRequestsStatus(false)
        }
        if (props.isRequestRespond) {
            props.getSentRequests()
        }
        if (props.hideRequestStatus) {
            props.setHideConnectionRequestStatus(false);
            props?.getNetworkUserCount()

            // setLoading(false);
        }
        if(props?.cancelrequest){
            props?.getSentRequests()
            props?.CancelRequestStatus(false);
            handleShowCancelModal(false);
        }
    }, [props.sendRequestStatus, props.isRequestRespond, props?.cancelrequest, props.hideRequestStatus])
    
    const handleViewProfile = (userInfo) => {
        setProfileData(userInfo);
        showProfileModal(true);
    }
    const showProfileModal = (status) => {
        if(!status) {
            setProfileData({})
            props?.resetDetailedUserProfileStatus();
        }
        setShowProfile(status)
    }
    const handleShowCancelModal = (status, data ={}) => {
        setShowCancelModal(status);
        setRejectData(data);
    }
    const handleCancelRequest = () => {
        let payload = {
            requestId: rejectData?.id
        }
        props?.CancelRequest(payload);
    }
    const handleHideCard = (data) => {
        let {requestInfo} = data
        
		let payload = {
			requestId: requestInfo?.id,
			userId: requestInfo?.userId
		}
        setLoading(true)
		props?.hideConnectionRequest(payload);
	}

    const hideIcon = (data) => {
        return(
            <div className="sentRequestCard__topSec__cta">
                <HighlightOffOutlinedIcon className='sentRequestCard__topSec__cta__cancelIcon' id="hideIcon" onClick={() => handleHideCard(data)}/>
            </div>
        )
    }

    return (
        <>
            {loading && <Loader />}
            {<div style={{ "width": "100%", "display": "flex", "justifyContent": "center" }}> {<CustomAlert />}</div>}
            {props?.sendedRequestList?.students?.length > 0 ? (
                    props?.sendedRequestList?.students?.map((data, index) => {
                        return(
                            <SentRequestCard data={data} key={data?.requestInfo?.id} hideIcon={() => hideIcon(data)}>
                                {data?.requestInfo?.status === "pending" &&
                                    <button className="sentRequestCard__btnSec__danger-btn" id={`cancel-${index}`} onClick={() => handleShowCancelModal(true, data?.requestInfo)}>
                                        Cancel Request
                                    </button>
                                }
                                <button className="sentRequestCard__btnSec__primary-btn" id={`viewProfile-${index}`}
                                    onClick={() => handleViewProfile({id: data?.requestReceivedUser?.id})}
                                >
                                    View Profile
                                </button>
                            </SentRequestCard>
                        
                        
                    )})
                ) :  !loading && 
                    <div className="networkNoDataSec">
                        <div className="networkNoDataSec__nodataCard">
                            <p className="networkNoDataSec__nodataCard__textBlack">Let’s get you connected with some Students who you can Collaborate with</p>
                            <span className="networkNoDataSec__nodataCard__cta">
                                <svg xmlns="http://www.w3.org/2000/svg" className="networkNoDataSec__nodataCard__cta__searchIcon" viewBox="0 0 24 24" fill="none">
                                    <circle cx="11" cy="11" r="6" stroke="#222222"/>
                                    <path d="M11 8C10.606 8 10.2159 8.0776 9.85195 8.22836C9.48797 8.37913 9.15726 8.6001 8.87868 8.87868C8.6001 9.15726 8.37913 9.48797 8.22836 9.85195C8.0776 10.2159 8 10.606 8 11" stroke="#222222" stroke-linecap="round"/>
                                    <path d="M20 20L17 17" stroke="#222222" stroke-linecap="round"/>
                                </svg>
                                <Link to={'exploreStudents'}>
                                    <p className="networkNoDataSec__nodataCard__cta__blueText" id="connect">
                                        Explore Students
                                    </p>
                                </Link>
                            </span>
                        </div>
                    </div>
                
            }
            <ConfirmationPopup show={showCancelModal} handleSubmit={() => handleCancelRequest()} message={`Please confirm that you would like to revoke this connection request for this student`} btnText="confirm" onHide={() => handleShowCancelModal(false)} />
            {showProfile &&
                <ViewStudentProfile show={showProfile} profileData={profileData} handleClose={() => showProfileModal(false)}/>
            }
        </>
    );
};

const mapStateToProps = ({ userProfileData, commonData }) => {
    const { sendedRequestList, isRequestRespond, sendRequestStatus, cancelrequest, hideRequestStatus } = commonData
    const { userProfile, isDisconnectUser } = userProfileData;
    return { sendedRequestList, userProfile, isDisconnectUser, isRequestRespond, sendRequestStatus, cancelrequest, hideRequestStatus }
};

export default connect(mapStateToProps, { getSentRequests, resetDetailedUserProfileStatus, sendRespondToRequest, 
    CancelRequest, CancelRequestStatus, clearMessage, setSentRequestsStatus, hideConnectionRequest, setHideConnectionRequestStatus,
    getNetworkUserCount })(Index);