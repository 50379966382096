import { SET_PAYMENT_DONE_STATUS, GET_PRICE_PACKAGE, GET_PRICE_PACKAGE_ID, ADD_COURSE, EDIT_COURSE, ADD_ACTIVITY, EDIT_ACTIVITY, DELETE_COURSE, DELETE_ACTIVITY, ADD_PACKAGE, EDIT_PACKAGE, GET_PRICE_PACKAGES, ADD_PACKAGE_ERROR, ADD_OFFICE_HOURS, UPDATE_PRICE_PACKAGE_STATUS, GET_SUBSCRIBED_ADVISORS, SUBSCRIBED_ADVISORS, SUBSCRIBED_ADVISORS_ERROR, EVENT_CONFIRMATION_STATUS, LOAD_SUBSCRIBED_STUDENTS_STATUS, LOAD_SUBSCRIBED_STUDENTS, CREATE_STUDENT_PLAN, OFFER_EXTRA_SESSION, PROFILE_SERVICE_STATUS, GET_PRICE_PACKAGES_STATUS, SUGGEST_TO_DROP_PLAN, UNDO_PLAN_SUGGESTION_STATUS, UNDO_COURSE_SUGGESTION_STATUS, SUGGEST_TO_DROP_COURSE, SUGGEST_TO_DROP_ACTIVITY, UNDO_ACTIVITY_SUGGESTION_STATUS, EXPLORE_STUDENT_DATA, EXPLORE_STUDENT_STATUS, UNDO_PLAN_DROP_SUGGESTION_STATUS, UNDO_COURSE_DROP_SUGGESTION_STATUS, UNDO_ACTIVITY_DROP_SUGGESTION_STATUS, DELETE_PACKAGE_ERROR_STATUS, NEWLY_CREATED_PLAN, LOAD_CURRENT_SUBSCRIBERS, LOAD_CURRENT_SUBSCRIBERS_SUCCESS, LOAD_PAST_SUBSCRIBERS, LOAD_PAST_SUBSCRIBERS_SUCCESS, UNDO_COURSE_SUGGESTION_ERROR, UNDO_ACTIVITY_SUGGESTION_ERROR, GET_GOALS_ADVISOR_COUNT, GET_EARNINGS_COUNT, GET_ADVISOR_DASHBOARD_COUNT, MILESTONE_COUNT_DATA, MILESTONE_COUNT_DATA_STATUS,
    GET_ADVISOR_STATE
} from "../constants/AdvisorActionTypes";


const INIT_STATE = {
    paymentstatus: false,
    deletePackageErrorStatus: false,
    packagepackage: [],
    AddPackage: false,
    addPackageError: false,
    loadPackagesStatus: false,
    addPackageStatus: false,
    pricingData: [],
    EditPackage: false,
    AddCourse: false,
    manageServiceStatus: true,
    AddActivity: false,
    AddOfficeHours: false,
    updatePackageStatus: false,
    singlePackageData: [],
    subscribedAdvisorList: [],
    subscribeadvisor: false,
    subscribeerror: false,
    eventConfirmationStatus: false,
    subscribedStudentData: [],
    getScribedStudentsStatus: false,
    currentSubscribers: [],
    loadCurrentSubscribers: false,
    pastSubscribers: [],
    loadPastSubscribers: false,
    createStudentPlanStatus: false,
    newPlanId: undefined,
    offerExtraSessionStatus: false,
    planDropSuggestionStatus: false,
    undoPlanSuggestionStatus: false,
    undoPlanDropSuggestionStatus: false,
    undoCourseSuggestionStatus: false,
    undoCourseSuggestionErrorStatus: false,
    courseDropSuggestionStatus: false,
    undoCourseDropSuggestionStatus: false,
    activityDropSuggestionStatus: false,
    undoActivitySuggestionStatus: false,
    undoActivityuggestionErrorStatus: false,
    undoActivityDropSuggestionStatus: false,
    suggestedStudentDetails: [],
    suggestedStudentStatus: false,
    dashboardGoalsCount: [],
    earningsCount : [],
    advisorDashboardCount : [],
    milestoneCountData : [],
    ismilestoneCountDataStatus : false,
    getAdvisorCurrentState: []
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_PAYMENT_DONE_STATUS: {
            return { ...state, paymentstatus: action.payload, };
        }
        case DELETE_PACKAGE_ERROR_STATUS: {
            return { ...state, deletePackageErrorStatus: action.payload, };
        }
        case GET_PRICE_PACKAGE: {
            return { ...state, packagepackage: action.payload, };
        }
        case GET_PRICE_PACKAGES: {
            return {
                ...state,
                pricingData: action.payload?.data,
            };
        }
        case GET_PRICE_PACKAGES_STATUS: {
            return { ...state, loadPackagesStatus: action?.payload };
        }
        case GET_PRICE_PACKAGE_ID: {
            return { ...state, packagepackage: action.payload, };
        }
        case UPDATE_PRICE_PACKAGE_STATUS: {
            return { ...state, updatePackageStatus: action.payload };
        }
        case PROFILE_SERVICE_STATUS: {
            return { ...state, manageServiceStatus: action.payload, };
        }
        case ADD_COURSE: {
            return { ...state, AddCourse: action.payload, };
        }
        case EDIT_COURSE: {
            return { ...state, EditCourse: action.payload, };
        }
        case ADD_ACTIVITY: {
            return { ...state, AddActivity: action.payload, };
        }
        case EDIT_ACTIVITY: {
            return { ...state, EditActivity: action.payload, };
        }
        case DELETE_COURSE: {
            return { ...state, deleteCourse: action.payload, };
        }
        case DELETE_ACTIVITY: {
            return { ...state, deleteActivity: action.payload, };
        }
        case ADD_PACKAGE: {
            return { ...state, addPackageStatus: action.payload };
        }
        case ADD_PACKAGE_ERROR: {
            return { ...state, addPackageError: action.payload };
        }
        case EDIT_PACKAGE: {
            return { ...state, EditPackage: action.payload, };
        }
        case ADD_OFFICE_HOURS: {
            return { ...state, AddOfficeHours: action.payload, };
        }
        case GET_SUBSCRIBED_ADVISORS: {
            return { ...state, subscribedAdvisorList: action.payload, };
        }
        case SUBSCRIBED_ADVISORS: {
            return { ...state, subscribeadvisor: action.payload }
        }
        case SUBSCRIBED_ADVISORS_ERROR: {
            return { ...state, subscribeerror: action.payload }
        }
        case EVENT_CONFIRMATION_STATUS: {
            return { ...state, eventConfirmationStatus: action.payload }
        }
        case LOAD_SUBSCRIBED_STUDENTS: {
            return {
                ...state,
                subscribedStudentData: action.payload.data,
                getScribedStudentsStatus: action.payload.status
            }
        }
        case LOAD_SUBSCRIBED_STUDENTS_STATUS: {
            return { ...state, getScribedStudentsStatus: action.payload }
        }
        case LOAD_CURRENT_SUBSCRIBERS: {
            return {
                ...state,
                currentSubscribers: action.payload.data,
                loadCurrentSubscribers: action.payload.status
            }
        }
        case LOAD_CURRENT_SUBSCRIBERS_SUCCESS: {
            return { ...state, loadCurrentSubscribers: action.payload }
        }
        case LOAD_PAST_SUBSCRIBERS: {
            return {
                ...state,
                pastSubscribers: action.payload.data,
                loadPastSubscribers: action.payload.status
            }
        }
        case LOAD_PAST_SUBSCRIBERS_SUCCESS: {
            return { ...state, loadCurrentSubscribers: action.payload }
        }
        case CREATE_STUDENT_PLAN: {
            return { ...state, createStudentPlanStatus: action.payload }
        }
        case NEWLY_CREATED_PLAN: {
            return { ...state, newPlanId: action.payload }
        }
        case OFFER_EXTRA_SESSION: {
            return { ...state, offerExtraSessionStatus: action.payload }
        }
        case SUGGEST_TO_DROP_PLAN: {
            return { ...state, planDropSuggestionStatus: action.payload }
        }
        case UNDO_PLAN_SUGGESTION_STATUS: {
            return { ...state, undoPlanSuggestionStatus: action.payload }
        }
        case UNDO_PLAN_DROP_SUGGESTION_STATUS: {
            return { ...state, undoPlanDropSuggestionStatus: action.payload }
        }
        case UNDO_COURSE_SUGGESTION_STATUS: {
            return { ...state, undoCourseSuggestionStatus: action.payload }
        }
        case UNDO_COURSE_SUGGESTION_ERROR: {
            return { ...state, undoCourseSuggestionErrorStatus: action.payload }
        }
        case SUGGEST_TO_DROP_COURSE: {
            return { ...state, courseDropSuggestionStatus: action.payload }
        }
        case UNDO_COURSE_DROP_SUGGESTION_STATUS: {
            return { ...state, undoCourseDropSuggestionStatus: action.payload }
        }
        case SUGGEST_TO_DROP_ACTIVITY: {
            return { ...state, activityDropSuggestionStatus: action.payload }
        }
        case UNDO_ACTIVITY_SUGGESTION_STATUS: {
            return { ...state, undoActivitySuggestionStatus: action.payload }
        }
        case UNDO_ACTIVITY_SUGGESTION_ERROR: {
            return { ...state, undoActivityuggestionErrorStatus: action.payload }
        }
        case UNDO_ACTIVITY_DROP_SUGGESTION_STATUS: {
            return { ...state, undoActivityDropSuggestionStatus: action.payload }
        }
        case EXPLORE_STUDENT_DATA: {
            return {
                ...state,
                suggestedStudentStatus: action?.status,
                suggestedStudentDetails: action.payload
            }
        }
        case EXPLORE_STUDENT_STATUS: {
            return { ...state, suggestedStudentStatus: action.payload }
        }
        case GET_GOALS_ADVISOR_COUNT: {
            return { ...state, dashboardGoalsCount: action.payload }
        }
        case GET_EARNINGS_COUNT : {
            return { ...state, earningsCount: action.payload } 
        }
        case GET_ADVISOR_DASHBOARD_COUNT : {
            return { ...state, advisorDashboardCount: action.payload } 
        }
        case MILESTONE_COUNT_DATA : {
            console.log(action.payload,"action.payload");
            
            return { ...state, milestoneCountData: action.payload } 
        }
        case MILESTONE_COUNT_DATA_STATUS : {
            return { ...state, ismilestoneCountDataStatus: action.payload } 
        }
        case GET_ADVISOR_STATE : {
            return { ...state, getAdvisorCurrentState: action.payload } 
        }

        default:
            return state;
    }
}