import React, { useEffect, useState } from 'react'
import "./advisorcard.less"
import { Grid } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import PillSection from "../../../../componentsNew/Common/PillSection"
import { useImageUrl } from '../../../../utils/UseImageURL';

const Index = (props) => {
    const [courseNames, setCourseNames] = useState([]);

    useEffect(() => {
        if (props?.data?.requestSentUser?.advisorProfile?.advisorCourses) {
            const course = props?.data?.requestSentUser?.advisorProfile?.advisorCourses.map(
                (data) => data.courseName
            );
            const activites = props?.data?.requestSentUser?.advisorProfile?.advisorActivities.map(
                (data) => data.activityName
            );
            const combinedArray = [...course, ...activites];
            setCourseNames(combinedArray);
        }
    }, [props.data]);
    const imgUrl = useImageUrl(props?.data?.requestSentUser?.avatarPath)
    return (
        <>
            <div className='AdvisorCard'>
                <Grid container spacing={2}>
                    <Grid item xs={3} >
                        {props?.data?.requestSentUser?.avatarPath ? (
                            <img className="profile-img" src={imgUrl} alt="userprofile" />
                        ) :
                            <PersonIcon color="action" sx={{ border: '1px solid grey', borderRadius: "6px", fontSize: '1rem', "width": "60px", "height": "60px !important" }} />
                        }
                    </Grid>
                    <Grid item xs={5} className="owlTitle">
                        <p className="profile-heading" style={{ "marginBottom": "2px", "fontSize": "16px", "fontWeight": "500" }} >{props?.data?.requestSentUser?.fullName}</p>
                        <p className="advisor-country" style={{ "marginBottom": "0px" }} >{props?.data?.requestSentUser?.country}</p>
                    </Grid>
                    <Grid item xs={4} className="owlTitle">
                        <p className="rating-heading" style={{ "marginBottom": "0px" }} >
                            <StarBorderIcon sx={{ fontSize: '15px', fill: '#E64A19', }} />{props?.data?.requestSentUser?.advisorProfile?.advisorReviewSummary?.averageRating.toFixed(1) || 0}{"/5.0"}
                        </p>
                    </Grid>
                </Grid>
                <Grid className='mt-2' style={{ "height": "70px" }}>
                    {props?.data?.requestSentUser?.advisorProfile?.currentlyIn === "highschool" && (
                        <p className='advisor-subText'>  <HomeOutlinedIcon sx={{ fontSize: '14px', fill: "#212224" }} /> Attending  {props?.data?.requestSentUser?.advisorProfile?.highschoolName}</p>
                    )}
                    {props?.data?.requestSentUser?.advisorProfile?.currentlyIn === "university" && (
                        <>
                            <p className='advisor-subText'>  <SchoolOutlinedIcon sx={{ fontSize: '14px', fill: "#212224" }} /> Attending  {props?.data?.requestSentUser?.advisorProfile?.universityName}</p>
                        </>
                    )}
                    {props?.data?.requestSentUser?.advisorProfile?.currentlyIn === "professional" && (
                        <>
                            <p className='advisor-subText'>  <WorkOutlineOutlinedIcon sx={{ fontSize: '14px', fill: "#212224" }} /> Professional  {props?.data?.requestSentUser?.advisorProfile?.targetProfession}</p>
                        </>
                    )}
                </Grid>
                <Grid className='mt-2 '>
                    <p className="profile-heading" style={{ "marginBottom": "5px", "fontSize": "16px", "fontWeight": "500" }} >{"Services offered"}</p>
                    <div className='advisor-card-content'>
                        {props?.data?.requestSentUser?.advisorProfile?.helpWithPlan === true && (<p className='advisor-subText'>  <StarRoundedIcon sx={{ fill: "#1C84EE", fontSize: '17px', }} />Create plan based on goals</p>)}
                        {props?.data?.requestSentUser?.advisorProfile?.helpWithSpecificCourseActivity === true && (<p className='advisor-subText'>  <StarRoundedIcon sx={{ fill: "#1C84EE", fontSize: '17px' }} />Review existing plan based on goals</p>)}
                        {courseNames?.length > 0 &&
                            <>
                                <p className='advisor-subText'>  <StarRoundedIcon sx={{ fill: "#1C84EE", fontSize: '17px' }} /> Mentoring for</p>
                                <PillSection data={courseNames} maxWidth={180} maxHeight={80} />
                            </>
                        }
                    </div>
                </Grid>
                <Grid className='messagecnt mt-2'>
                    {props?.data?.requestInfo?.message && (
                        <div className='advisor-card-description'>
                            <p>{props?.data?.requestInfo?.message}</p>
                        </div>
                    )}
                </Grid>
                <Grid>
                    {props?.children}
                </Grid>
            </div>
        </>
    )
}

export default Index
