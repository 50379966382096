import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../../assets/css/login.less";
import { connect } from "react-redux";
import { withRouter } from "../../../redux/store/navigate";
import { LoginSocialGoogle, LoginSocialFacebook } from 'reactjs-social-login'
import { isUserAuthenticated } from "../../../utils/AuthUtils";
import { clearErrorList,forgetPassword, verifyPassword, userSignIn, getUserProfile, verifyEmail, googleLogin, facebookLogin, clearMessage } from "../../../redux/actions";
import CustomAlert from "../../../components/Alert";
import Loader from "../../../components/Loader"
import { EMAIL_LABEL, PASSWORD_LABEL } from "../../../Constants/commonLabels";

const IDENTIFIER = process.env.REACT_APP_IDENTIFIER;
const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
const GOOGLE_APP_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

let user = isUserAuthenticated();

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            alertBox: true,
            fields: {email: "", password: ""},
            errors: {},
            isOnboarded: false,
            errorList: [],
            buttonstatus: false,
        };
        this.handleValidation = this.handleValidation.bind(this)
    }
    componentDidMount() {
        sessionStorage.clear();
        this.props.clearMessage();
        this.props.clearErrorList();
        if(user){
            this.setState({loading: true})
            // axios.defaults.headers.common['Authorization'] = "Bearer " + getUserData('accessToken');
            // this.props.getUserProfile();
        }
        else{
            this.props.navigate('/');  
        }
        this.setState({ errorList: [] })
        if (this.props.isNewPassword) {
            this.setState({ sentpassword: true })
        }
        if (this.props.isverifyPasswordstatus == false) {
            this.setState({ passwordurlerror: true })
        }
        else if (this.props.isverifyPasswordstatus === true) {
            this.setState({ passwordurlerror: false })
        }
        if (this.props.isEmailConfirm == false) {
            this.setState({ verifyemailerror: true })
        }
        if (this.props.isEmailVerified == true) {
            this.setState({ verifyemailsuccess: true })
        }
        if (this.props.userProfile && this.props.userProfile && this.props.userProfile.isOnboarded !== undefined && !this.props.userProfile.isOnboarded) {
            this.props.navigate('/onboarding/roles');
        }
    }
    componentDidUpdate(){
        if (this.props.userProfile && this.props.userProfile.isOnboarded !== undefined && !this.props.userProfile.isOnboarded) {
            this.props.navigate('/onboarding/roles');
        }
        let studentId = localStorage.getItem("parentViaChild")
       
    }
    componentWillUnmount() {
        this.setState({
            errorList: [],
        });
    }
    handleChange(field, e) {
        this.props.clearErrorList();
        let { errors } = this.state;
        let fields = this.state.fields;
        fields[field] = e.target.value;
        if (e.target.value.length >= 0) {
            errors[field] = "";
        }
        this.setState({ fields, errors: errors });
    }
    handleClear() {
        this.setState({alertBox:false})
        this.props.clearMessage()
    }
    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (fields["email"] !== "") {
            // const textcaseRegExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
            // const emailvalid = textcaseRegExp.test(fields["email"]);
            // if (!emailvalid) {
            //     formIsValid = false;
            //     errors["email"] = "Email is invalid";
            // }
            let lastAtPos = fields["email"].lastIndexOf("@");
            let lastDotPos = fields["email"].lastIndexOf(".");
            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    fields["email"].indexOf("@@") === -1 &&
                    lastDotPos > 2 &&
                    fields["email"].length - lastDotPos > 2
                )
            ) {
                formIsValid = false;
                errors["email"] = "Not a valid email";
            }
        }
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Email cannot be empty";
        }
        if (!fields["password"]) {
            formIsValid = false;
            errors["password"] = "Password cannot be empty";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    handleSubmit(e) {
        e.preventDefault();
        let fields = this.state.fields;
        if (this.handleValidation()) {
            let values = {
                username: fields["email"],
                password: fields["password"],
                identifier: IDENTIFIER
            }
            this.props.userSignIn(values)
        }
    };
    
    render() {
        let { buttonstatus } = this.state;
        let { errorList } = this.props;
        const message = this.props.message;
        return (
            <>
                <div className="login">
                    {this.state.alertBox && <CustomAlert />}
                    {this.state.loading || this.props.loading && <Loader />}
                    <div className="login_card">
                        <h1 className="login_title text-center">Log In</h1>
                        <div>
                            <div className="row">
                                <div className="col-6" style={{ "display": "flex", "justifyContent": "end" }}>
                                    <div className="pointer">
                                        <LoginSocialGoogle
                                            scope="https://www.googleapis.com/auth/userinfo.email"
                                            client_id={GOOGLE_APP_ID || ''}
                                            onLoginStart={this.onLoginStart}
                                            onResolve={(provider, data) => {
                                                if (provider && provider.data.access_token) {
                                                    let googleToken = {
                                                        identifier: IDENTIFIER,
                                                        token: provider.data.access_token
                                                    }
                                                    this.props.googleLogin(googleToken)
                                                }
                                            }}
                                            onReject={(err) => {
                                                console.log(err)
                                            }} >
                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="0.5" y="0.5" width="47" height="47" rx="9.5" fill="white" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M35.04 24.2605C35.04 23.445 34.9668 22.6609 34.8309 21.9082H24V26.3566H30.1891C29.9225 27.7941 29.1123 29.0121 27.8943 29.8275V32.713H31.6109C33.7855 30.7109 35.04 27.7627 35.04 24.2605Z" fill="#4285F4" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M23.9995 35.5005C27.1045 35.5005 29.7077 34.4708 31.6104 32.7144L27.8938 29.829C26.864 30.519 25.5467 30.9267 23.9995 30.9267C21.0042 30.9267 18.469 28.9037 17.5647 26.1855H13.7227V29.1651C15.6149 32.9235 19.504 35.5005 23.9995 35.5005Z" fill="#34A853" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M17.5652 26.1855C17.3352 25.4955 17.2045 24.7584 17.2045 24.0005C17.2045 23.2425 17.3352 22.5055 17.5652 21.8155V18.8359H13.7232C12.9443 20.3884 12.5 22.1448 12.5 24.0005C12.5 25.8562 12.9443 27.6125 13.7232 29.165L17.5652 26.1855Z" fill="#FBBC05" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M23.9995 17.0739C25.6879 17.0739 27.2038 17.6541 28.3956 18.7936L31.694 15.4952C29.7024 13.6395 27.0992 12.5 23.9995 12.5C19.504 12.5 15.6149 15.077 13.7227 18.8355L17.5647 21.815C18.469 19.0968 21.0042 17.0739 23.9995 17.0739Z" fill="#EA4335" />
                                                <rect x="0.5" y="0.5" width="47" height="47" rx="9.5" stroke="#EBEBEB" />
                                            </svg>
                                        </LoginSocialGoogle>
                                    </div>
                                </div>
                                <div className="col-6"  style={{ "display": "flex", "justifyContent": "start"}}>
                                    <div className="pointer">
                                        <LoginSocialFacebook
                                            appId={FACEBOOK_APP_ID || ''}
                                            onLoginStart={this.onLoginStart}
                                            onResolve={(provider, data) => {
                                                if (provider && provider.data.accessToken) {
                                                    let facebokkToken = {
                                                        identifier: IDENTIFIER,
                                                        token: provider.data.accessToken
                                                    }
                                                    this.props.facebookLogin(facebokkToken)
                                                }
                                            }}
                                            onReject={(err) => {
                                                console.log(err)
                                            }}>
                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="0.5" y="0.5" width="47" height="47" rx="9.5" fill="white" />
                                                <rect width="24" height="24" transform="translate(12 12)" fill="white" />
                                                <path d="M35.5 24.0694C35.5 17.7181 30.3513 12.5694 24 12.5694C17.6487 12.5694 12.5 17.7181 12.5 24.0694C12.5 29.8094 16.7054 34.567 22.2031 35.4297V27.3936H19.2832V24.0694H22.2031V21.5358C22.2031 18.6536 23.92 17.0616 26.5468 17.0616C27.805 17.0616 29.1211 17.2862 29.1211 17.2862V20.1163H27.671C26.2424 20.1163 25.7969 21.0027 25.7969 21.9122V24.0694H28.9863L28.4765 27.3936H25.7969V35.4297C31.2946 34.567 35.5 29.8094 35.5 24.0694Z" fill="#1877F2" />
                                                <rect x="0.5" y="0.5" width="47" height="47" rx="9.5" stroke="#EBEBEB" />
                                            </svg>
                                        </LoginSocialFacebook>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className="or"><span>or</span></p>
                        <Form className="login_card_form mb-5" autoComplete="off">
                            <div className="input-floating-label">
                                <input refs="email" type="email" name="email" placeholder=" "
                                    className={this.state.errors["email"] ? "textbox--primary textbox--rounded input w-100 error-input" : "textbox--primary textbox--rounded input"}
                                    onChange={this.handleChange.bind(this, "email")} value={this.state.fields["email"]} />
                                <label >{EMAIL_LABEL}</label>
                                {errorList?.username && <span className="error-text">{errorList?.username}</span>}
                                {this.state.errors["email"] && <span className="error-text">{this.state.errors["email"]}</span>}
                            </div>
                            <div className="input-floating-label">
                                <input refs="password" type="password" name="password" placeholder=" "
                                    className={this.state.errors["password"] ? "textbox--primary textbox--rounded input w-100 error-input" : "textbox--primary textbox--rounded input"}
                                    onChange={this.handleChange.bind(this, "password")} value={this.state.fields["password"]} />
                                <label>{PASSWORD_LABEL}</label>
                                {errorList && errorList.password && <span className="error-text">{errorList.password}</span>}
                                {this.state.errors["password"] && <span className="error-text">{this.state.errors["password"]}</span>}
                            </div>
                            <p className="rememberlogin">
                                <input className="styled-checkbox" id="styled-checkbox-2" type="checkbox" value="value2" />
                                <label htmlFor="styled-checkbox-2"><span>Keep me logged in</span></label>
                            </p>
                            <Button type="submit" disabled={buttonstatus} onClick={(e) => { this.handleSubmit(e)  }}  className="btn cta--rounded cta-primary w-100"><span>Continue</span></Button>
                        </Form>
                        <p className="cta cta--center"><Link className="login_text" to="/forgotpassword" onClick={() => { this.setState({alertBox:false})  }} >Forgot password?</Link></p>
                        <p className="cta cta--center login_text">Don’t have an account? <Link className="cta--underline" to="/signup" onClick={() =>{this.handleClear()} }><span className="mob-d-block">Create one</span></Link></p>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = ({ auth, commonData, userProfileData }) => {
    const { message, errorList, isNewPassword, isverifyPasswordstatus, loading } = commonData
    const { isLoginError, isEmailVerified, isEmailConfirm } = auth;
    const { userProfile } = userProfileData;
    return { message, errorList, userProfile, isLoginError, isEmailVerified, isNewPassword, isverifyPasswordstatus, isEmailConfirm, loading }
};
export default connect(mapStateToProps, { clearErrorList,userSignIn, getUserProfile, verifyEmail, forgetPassword, verifyPassword, googleLogin, facebookLogin, clearMessage })(withRouter(Index));