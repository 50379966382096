import React, { useEffect, useState } from 'react'
import ConnectedAdvisorCard from "../../../../componentsNew/Common/Cards/Advisor/ConnectedAdvisor"
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { connect } from "react-redux";
import { clearMessage, getFreeSessionStatus, getConnectedAdvisors, getAdvisorSchedule,bookSessionErrorStatus, getFreeSessionupdateStatus, 
    setConnectedAdvisorStatus, bookSession, bookPaidSession, bookSessionStatus, bookPaidSessionErrorStatus, bookPaidSessionStatus, 
    disconnectUser, disconnectUserStatus,getNetworkUserCount } from '../../../../redux/actions'
import Loader from '../../../../components/Loader';
import AdvisorProfile from "../../../../componentsNew/Common/advisorProfile";
import BookSessionPopup from "../../../../componentsNew/Common/BookSession/BookSessionModal";
import { useNavigate } from 'react-router-dom';
import ConfirmationPopup from "../../../../componentsNew/Common/ConfirmationPopup";
import { convertUTCTodateTimeZone, convertUtcToEventTime } from '../../../../utils/CommonUtils';

const Index = (props) => {
    let navigate = useNavigate();
    const [loading, setloading] = useState(false)
    const [bookshow, setbookshow] = useState(false);
    const [TimeSession, setTimeSession] = useState([]);
    const [advisorid, setAdvisorid] = useState("");
    const [profilePopup, setProfiePopup] = useState(false);
    const [profileData, setProfileData] = useState({});
    const [showDisconnectModal, setShowDisconnectModal] = useState(false);
    const [advisorName, setAdvisorName] = useState("");
    const [advisorTime, setAdvisorTime] = useState();
    const [purchaseSubscriptionFlag,setPurchaseSubscriptionFlag] = useState(false);

    console.log(purchaseSubscriptionFlag,"purchaseSubscriptionFlag");
    

    const profileShow = (data) => {
        setProfileData(data)
        setProfiePopup(true);
    }

    const profileShowPurchase = (data) => {
        setProfileData(data)
        setProfiePopup(true);
        setPurchaseSubscriptionFlag(true);
    }
     
    useEffect(() => {
        props?.clearMessage()
        props?.getConnectedAdvisors()
    }, [])

    useEffect(() => {
        if (props?.isDisconnectUser) {
            openDisconnectModal(false)
            props?.getConnectedAdvisors()
            props?.disconnectUserStatus(false)
            setloading(false)
            props?.getNetworkUserCount()
        }
    }, [props?.isDisconnectUser,props?.userProfile])

    useEffect(() => {
        if (props?.connectedAdvisorsStatus) {
            const advisorId = props?.connectedAdvisors?.length > 0 && props?.connectedAdvisors?.map(item => item.userInfo?.id);
            let StudentId = props?.userProfile?.id
            let payload = {
                advisorIds: advisorId,
                studentId: StudentId
            }
            if (advisorId?.length > 0 && StudentId) {
                setloading(true)
                props?.getFreeSessionStatus(payload)
            }
            props?.setConnectedAdvisorStatus(false)
        }
        if (props?.scheduleupdatestatus) {
            props?.getFreeSessionupdateStatus(false)
            setloading(false)
        }
    }, [props?.connectedAdvisorsStatus, props?.scheduleupdatestatus])

    useEffect(() => {
        if (props?.sessionBooked) {
            props?.getConnectedAdvisors()
            CloseBookSession()
            props?.bookSessionStatus(false)
            if (props?.userProfile?.id) {
                props?.getAdvisorSchedule(props?.userProfile?.id)
            }
        }
        if (props?.booksessionerror) {
            props?.getConnectedAdvisors()
            CloseBookSession()
            props?.bookSessionStatus(false)
            props?.bookSessionErrorStatus(false)
            if (props?.userProfile?.id) {
                props?.getAdvisorSchedule(props?.userProfile?.id)
            }
        }
    }, [props?.sessionBooked, props?.booksessionerror])

    useEffect(() => {
        if (props?.paidSessionBooked) {
            props?.getConnectedAdvisors()
            CloseBookSession()
            props?.bookPaidSessionStatus(false)
            if (props?.userProfile?.id) {
                props?.getAdvisorSchedule(props?.userProfile?.id)
            }
        }
        if (props?.bookPaidSessionError) {
            props?.getConnectedAdvisors()
            CloseBookSession()
            props?.bookPaidSessionErrorStatus(false)
            if (props?.userProfile?.id) {
                props?.getAdvisorSchedule(props?.userProfile?.id)
            }
        }
    }, [props?.paidSessionBooked, props?.bookPaidSessionError])

    const profileHide = () => {
        setProfiePopup(false)
        setloading(true)
        props?.getConnectedAdvisors();
        setPurchaseSubscriptionFlag(false);
    };

    const OpenBookSession = (e, data) => {
        props?.clearMessage()
        e.preventDefault()
        setTimeSession(data)        
        setAdvisorTime([data?.userInfo?.advisorProfile?.advisorWorkingHours])
        setAdvisorid(data?.userInfo?.id)
        setAdvisorName({ label: data?.userInfo?.fullName, value: data?.userInfo?.id })
        setbookshow(true)
    }

    const CloseBookSession = () => {
        setbookshow(false)
    }
    function convertTo24HourFormat(time12h) {
        const [time, modifier] = time12h.split(' ');
        let [hours, minutes] = time.split(':');
        hours = parseInt(hours, 10);
        if (modifier === 'PM' && hours !== 12) {
            hours += 12;
        } else if (modifier === 'AM' && hours === 12) {
            hours = 0;
        }
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.padStart(2, '0');
        return `${formattedHours}:${formattedMinutes}:00`;
    }

    const SessionBook = (data, timeString) => {
        const startTime = timeString.split(' - ')[0];
        const endTime = timeString.split(' - ')[1];
        const moment = require('moment');
        const selectedDate = moment(data.Selectdate, "YYYY-MM-DD").format("YYYY-MM-DD");
        let currentTime = selectedDate + ' ' + convertTo24HourFormat(startTime)
        let payload = {
            "advisorId": advisorid,
            "eventDuration": '30',
            "eventTime": currentTime,
            "meetingLocation": data?.Location,
            "meetingDescription": data?.Description,
            "password": data?.password,
            "studentId": null,
        }
        if (data?.package) {
            payload["packageId"] = data?.package?.value;
            props?.bookPaidSession(payload)
        } else {
            props?.bookSession(payload)
        }
    }

    const openDisconnectModal = (status, data) => {
        setProfileData(data)
        setShowDisconnectModal(status);
    }
    const HandleNavigate = (e) => {
        e.preventDefault();
        navigate('/student/mynetwork/exploreAdvisor')
    }
  
    const handleDelete = () => {
        let userdata = profileData
        if (userdata?.userInfo?.id) {
            setloading(true)
            let payload = {
                userId: userdata?.userInfo?.id
            }
            props?.disconnectUser(payload)
        }
    }

    const getScheduledTime = (userId, packageId) => {
        let eventDetails = props?.scheduledSessionDetails?.find(data => data?.userId === userId && data?.packageId === packageId);
        if (eventDetails) {
            let date = convertUTCTodateTimeZone(eventDetails?.eventTime,props?.userProfile?.timezone);
            let formattedDate = date.format('DD/MM/YYYY')
            return `${formattedDate} at ${convertUtcToEventTime(eventDetails?.eventTime, props?.userProfile?.timezone)?.format('hh:mm A')}`
        } else {
            return null;
        }
    }

    return (
        <>
            {loading && <Loader />}
            {showDisconnectModal &&
                <ConfirmationPopup show={showDisconnectModal} handleSubmit={() => handleDelete()} message={`You and ${profileData?.userInfo?.fullName} will no longer be connected to each other`} btnText="confirm" onHide={() => openDisconnectModal(false)} />
            }
            {(bookshow && advisorTime) &&
                <BookSessionPopup show={bookshow} userTime={advisorTime} UserData={props?.userProfile} advisorData={profileData} selectedAdvisor={advisorName} advisorList={props?.connectedAdvisors} onHide={CloseBookSession} Time={TimeSession} HandleSumit={SessionBook} />
            }
            {profilePopup &&
                <AdvisorProfile show={profilePopup} detailedInfo={props?.detailedAdvisorData} data={profileData} handleClose={profileHide} studentNetwork={true} purchaseSubscriptionFlag = {purchaseSubscriptionFlag}/>
            }
            {props?.connectedAdvisors?.length > 0 ? (
                <div className='Network__Content' >
                    {props?.connectedAdvisors && props?.connectedAdvisors?.sort((a, b) => b?.subscriptiondetails?.length - a?.subscriptiondetails?.length).map((data) => {
                        let filtered = props?.freeSessionStatus?.length > 0 && props?.freeSessionStatus?.filter(
                            (freeSessionData) => freeSessionData?.advisorId === data?.userInfo?.id
                        );
                        return (
                            <ConnectedAdvisorCard data={data} >
                                <div className="netWork-card__button-container">
                                    {data?.subscriptiondetails?.length === 0 &&
                                        <>                                        
                                            {filtered && filtered?.length > 0 && (filtered[0]?.freeSessionCompleted === false && filtered[0]?.totalCount === filtered[0]?.remainingCount ) && (
                                                <div className="netWork-card__button-sec">
                                                    {getScheduledTime(data?.userInfo?.id) ? (
                                                        <span className="meetTimeZone" style={{ color: "#545454", fontSize: "9.4px" }}>
                                                            <CalendarTodayOutlinedIcon sx={{ fontSize: '0.7rem' }} /> You are scheduled to meet on {getScheduledTime(data?.userInfo?.id)}
                                                        </span>
                                                    ) : ''}
                                                    {!getScheduledTime(data?.userInfo?.id) &&
                                                        <button className="netWork-card__button-sec__primes-btn" onClick={(e) => OpenBookSession(e, data)} style={{ "width": "90%" }}>
                                                            Book Free Session
                                                        </button>
                                                    }
                                                </div>
                                            )}                                                                                    
                                            {filtered && filtered?.length > 0 && (filtered[0]?.acceptedFreeSession === 1 && filtered[0]?.totalCount != filtered[0]?.remainingCount) && (
                                                <div className="netWork-card__button-sec">
                                                    <button className="netWork-card__button-sec__primes-btn" onClick={(e) => profileShowPurchase(data)} style={{ "width": "90%", "height": "42px" }}>
                                                        Purchase Subscription
                                                    </button>
                                                </div>
                                            )}
                                            <div className="netWork-card__button-sec">
                                                <button className="netWork-card__button-sec__secondary-btn" onClick={() => openDisconnectModal(true, data)} style={{ "width": "90%", "height": "42px" }} >
                                                    Disconnect
                                                </button>
                                            </div>
                                        </>
                                    }
                                    <div className="netWork-card__button-sec">
                                        <button className="netWork-card__button-sec__primary-btn" onClick={(e) => profileShow(data)} style={{ "width": "90%", "height": "42px" }} >
                                            View Profile
                                        </button>
                                    </div>
                                </div>
                            </ConnectedAdvisorCard>
                        );
                    })}
                </div>
            ) :
                <div className='Empty__Container'>
                    <div className='Backdrop__container__card'>
                        <div className='title_cnt'>
                            <p>Let’s get you connected with some advisors who can help you achieve your goals !</p>
                        </div>
                        <div className='btn_cnt'>
                            <p onClick={(e) => HandleNavigate(e)}>
                                <span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="11" cy="11" r="6" stroke="#222222" />
                                        <path d="M11 8C10.606 8 10.2159 8.0776 9.85195 8.22836C9.48797 8.37913 9.15726 8.6001 8.87868 8.87868C8.6001 9.15726 8.37913 9.48797 8.22836 9.85195C8.0776 10.2159 8 10.606 8 11" stroke="#222222" stroke-linecap="round" />
                                        <path d="M20 20L17 17" stroke="#222222" stroke-linecap="round" />
                                    </svg>
                                </span>
                                Explore Advisors
                            </p>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

const mapStateToProps = ({ commonData, userProfileData }) => {
    const { message, errorList, connectedAdvisors, connectedAdvisorsStatus, paidSessionBooked, bookPaidSessionError, sessionBooked, booksessionerror, scheduledSessionDetails, scheduledSessionDetailStatus, freeSessionStatus, scheduleupdatestatus } = commonData;
    const { isDisconnectUser, userProfile } = userProfileData
    return { message, errorList, connectedAdvisors, connectedAdvisorsStatus, paidSessionBooked, bookPaidSessionError, sessionBooked, booksessionerror, scheduledSessionDetails, scheduledSessionDetailStatus, isDisconnectUser, userProfile, freeSessionStatus, scheduleupdatestatus };
};
export default connect(mapStateToProps, { clearMessage, getFreeSessionStatus, getFreeSessionupdateStatus,bookSessionErrorStatus, getConnectedAdvisors, getAdvisorSchedule, setConnectedAdvisorStatus, bookSession, bookPaidSession, bookSessionStatus, bookPaidSessionErrorStatus, bookPaidSessionStatus, disconnectUser, disconnectUserStatus ,getNetworkUserCount})(Index);
