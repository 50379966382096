import React, { useEffect, useState } from 'react'
import "../../../assets/css/NewNotification.less";
import { Tabs, Tab } from "@mui/material";
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Loader from "../../../components/Loader";
import GeneralTab from "./GeneralTab"
import ChangeTab from "./ChangeTab"
import { connect } from "react-redux";
import { getnotification, viewedNotificationStatus } from "../../../redux/actions";



// Tabs Css
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography component="div" role="tabpanel" hidden={value !== index} id={`scrollable-auto-tabpanel-${index}`} aria-labelledby={`scrollable-auto-tab-${index}`} {...other} >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}
TabPanel.propTypes = { children: PropTypes.node, index: PropTypes.any.isRequired, value: PropTypes.any.isRequired };
function a11yProps(index) {
  return { id: `scrollable-auto-tab-${index}`, "aria-controls": `scrollable-auto-tabpanel-${index}` };
}
// Css

const Notification = (props) => {

  const [loading, setloading] = useState(false);
  const [tabs, settabs] = useState(0);

  function handleChange(event, newValue) {
    settabs(newValue);
  }

  useEffect(() => {
    let payload = {
      "pageNo": 1,
      "limit": 50
    }
    props.getnotification(payload)
    setloading(true)
  }, [])

  useEffect(() => {
    if (props?.notificationSuccess === true) {
      setloading(false)
    }
    if(props?.viewednotfication){
      let payload = {
        "pageNo": 1,
        "limit": 50
      }
      props.getnotification(payload)
      props?.viewedNotificationStatus(false);
    }
  }, [props?.notificationSuccess, props?.viewednotfication])


  return (
    <>
      {loading && <Loader />}
      <div className='Notification_Page'>
        <div className='Notification_Page_Top'>
          <div className='Notification_Page_Title_Cnt'>
            <p className='Notification_Page_Title'>Notification</p>
          </div>
          <div className='Notification_Mobile_Page_Title_Cnt'>
            <div className="My_Work_Top_Mobile">
              <div className="My_Work_Top_Mobile_Title">
                <p>Notification</p>
              </div>
            </div>
          </div>
        </div>
        <div className='Notification_Page_Mid'>
          <div className="Notification_Page_Mid_Desktop_Tabs">
            <Tabs value={tabs} onChange={handleChange} variant="scrollable" scrollButtons allowScrollButtonsMobile >
              <Tab disableRipple label="Network" className="Tab" {...a11yProps(0)} />
              <Tab disableRipple label="General" className="Tab" {...a11yProps(1)} />
            </Tabs>
          </div>
          <div className="Notification_Page_Mid_Desktop_Tab_Panels">
            <TabPanel value={tabs} index={0} className="TabPanels">
              <ChangeTab type={props?.type} />
            </TabPanel>
            <TabPanel value={tabs} index={1} className="TabPanels">
              <GeneralTab type={props?.type} />
            </TabPanel>
          </div>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = ({ commonData, userProfileData }) => {
  const { notificationSuccess, viewednotfication } = commonData;
  const {childprofile} = userProfileData
  return { notificationSuccess, childprofile, viewednotfication }
};

export default connect(mapStateToProps, { getnotification, viewedNotificationStatus })(Notification);