import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
    clearMessage, getChildProfile, bookSession, bookPaidSession, bookSessionStatus,
    bookPaidSessionErrorStatus, getUserCount, bookPaidSessionStatus, getDashboardCountStatus,
    GetStudentPlan, GetStudentPlanStatus,
} from '../../../redux/actions'
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import CarouselComponent from './CarouselComponent';
import { Card, Tooltip } from '@mui/material';
import PillSection from "../../../componentsNew/Common/PillSection"
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import "./dashboard.less";
import ScheduleCard from "../../../componentsNew/Dashboard/SessionRemainingCard";
import ScheduleListCard from "../../../componentsNew/Dashboard/ScheduleListCard";
import InviteModal from "../../../componentsNew/Common/InviteModal";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";
import { GetCurrentTimezone } from "../../../utils/CommonUtils";
import dayjs from "dayjs";
import SelectableTags from "../../../componentsNew/Common/dashboardSelectableTags"
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SearchIcon from '@mui/icons-material/Search';
import PeopleIcon from '@mui/icons-material/People';
import CustomAlert from "../../../components/CustomAlert";

const Index = (props) => {

    const [studentDashboardCount, setStudentDashboardCount] = useState([])
    const [remainingSession, setRemainingSession] = useState([])
    const [exploreCountForAdvisor, setExploreAdvisorCount] = useState([])
    const [exploreCountForStudent, setExploreStudentCount] = useState([])
    const [openAdvisorInvitePopUp, setOpenAdvisorInvitePopUp] = useState(false)
    const [loading, setLoading] = useState(false);
    const [gotoplan, setGotoplan] = useState(false);
    const [inviteType, setInviteType] = useState("");

    const pillsData = ["Standford Universities", "Abcott College", "MIT College",]
    const advisorActivities = props?.isgetusergoals?.activities || [];
    const advisorCourses = props?.isgetusergoals?.courses || [];

    const combinedData = [...advisorActivities, ...advisorCourses];

    const navigate = useNavigate();

    useEffect(() => {
        if (props?.dashboardCount) {
            let advisorCountData = [
                ...(Array.isArray(props?.dashboardCount?.receivedRequests)
                    ? props?.dashboardCount?.receivedRequests?.map((request, index) => {
                        return {
                            type: request?.type,
                            count: request?.count,
                            id: `request-${index}`
                        };
                    })
                    : []),
                ...(Array.isArray(props?.dashboardCount?.milestoneUpdates)
                    ? props?.dashboardCount?.milestoneUpdates.map(update => {
                        console.log(update, "Milestone Update");
                        return {
                            count: update?.totalUpdates,
                            type: "milestone updates",
                            id: update?._id,
                            milestone: update?.milestones
                        };
                    })
                    : []),
                ...(Array.isArray(props?.dashboardCount?.courseUpdates)
                    ? props?.dashboardCount?.courseUpdates.map(update => {
                        console.log(update, "Milestone Update");
                        return {
                            count: update?.totalUpdates,
                            type: "course updates",
                            id: update?._id,
                            courses: update?.courses
                        };
                    })
                    : []),
                ...(Array.isArray(props?.dashboardCount?.activityUpdates)
                    ? props?.dashboardCount?.activityUpdates?.map(update => {
                        return {
                            count: update?.totalUpdates,
                            type: "activity updates",
                            id: update?._id,
                            activity: update?.activities
                        };
                    })
                    : []),
            ];

            setStudentDashboardCount(advisorCountData)
            props?.getDashboardCountStatus(false);
        }
        if (props?.connectedAdvisors) {
            let availableSessionFilter = props?.connectedAdvisors?.filter((advisor) => {
                // Check if subscriptiondetails array exists and has items
                if (advisor?.subscriptiondetails?.length > 0) {
                    // Check if any subscription has available paid sessions
                    return advisor?.subscriptiondetails?.some(subscription => subscription?.noOfPaidSessions > 0);
                } else {
                    // If subscriptiondetails array is empty, check for free consultations
                    return advisor?.userInfo?.advisorProfile?.noOfFreeConsultation > 0;
                }
            });
            setRemainingSession(availableSessionFilter)

        }
        if (props?.adviserCountForStudent?.advisor) {
            let advisorExploreData = [
                ...(Array.isArray(props?.adviserCountForStudent?.advisor?.universities)
                    ? props?.adviserCountForStudent?.advisor?.universities?.map((universities, index) => {
                        console.log(universities, "dfhbdhfb")
                        return {
                            count: universities?.count,
                            id: index,
                            goalName : universities?.goalName,
                            description: "Attend " + universities?.goalName
                        };
                    })
                    : []),
                ...(Array.isArray(props?.adviserCountForStudent?.advisor?.professions)
                    ? props?.adviserCountForStudent?.advisor?.professions?.map((professions, index) => {
                        return {
                            count: professions?.count,
                            id: index,
                            goalName : professions?.goalName,
                            description: "Aspire to become " + professions?.goalName
                        };
                    })
                    : []),
                ...(Array.isArray(props?.adviserCountForStudent?.advisor?.courses)
                    ? props?.adviserCountForStudent?.advisor?.courses?.map((courses, index) => {
                        return {
                            count: courses?.count,
                            id: index,
                            goalName : courses?.goalName,
                            description: "Can help with " + courses?.goalName
                        };
                    })
                    : []),
                ...(Array.isArray(props?.adviserCountForStudent?.advisor?.activities)
                    ? props?.adviserCountForStudent?.advisor?.activities?.map((activities, index) => {
                        return {
                            count: activities?.count,
                            id: index,
                            goalName : activities?.goalName,
                            description: "Can help with " + activities?.goalName
                        };
                    })
                    : []),

            ];
            setExploreAdvisorCount(advisorExploreData)
        }
        if (props?.adviserCountForStudent?.student) {
            let studentExploreData = [
                ...(Array.isArray(props?.adviserCountForStudent?.student?.universities)
                    ? props?.adviserCountForStudent?.student?.universities?.map((universities, index) => {
                        return {
                            count: universities?.count,
                            id: index,
                            goalName : universities?.goalName,
                            description: "Targeting " + universities?.goalName
                        };
                    })
                    : []),
                ...(Array.isArray(props?.adviserCountForStudent?.student?.professions)
                    ? props?.adviserCountForStudent?.student?.professions?.map((professions, index) => {
                        return {
                            count: professions?.count,
                            id: index,
                            goalName : professions?.goalName,
                            description: "Aspire to be " + professions?.goalName
                        };
                    })
                    : []),
                ...(Array.isArray(props?.adviserCountForStudent?.student?.courses)
                    ? props?.adviserCountForStudent?.student?.courses?.map((courses, index) => {
                        return {
                            count: courses?.count,
                            goalName : courses?.goalName,
                            id: index,
                            description: "Taking " + courses?.goalName
                        };
                    })
                    : []),
                ...(Array.isArray(props?.adviserCountForStudent?.student?.activities)
                    ? props?.adviserCountForStudent?.student?.activities?.map((activities, index) => {
                        return {
                            count: activities?.count,
                            goalName : activities?.goalName,
                            id: index,
                            description: "Taking " + activities?.goalName
                        };
                    })
                    : []),

            ];
            setExploreStudentCount(studentExploreData)
        }
    }, [props?.dashboardCountStatus, props?.connectedAdvisors, props?.dashboardCount,
    props?.adviserCountForStudent?.advisor, props?.adviserCountForStudent?.student
    ])

    useEffect(() => {
        if (props?.studentplanstatus && gotoplan) {
            if (props?.getstudentplan?.length === 1) {
                console.log("props?.getstudentplan", props?.getstudentplan);
                let PlanId = props?.getstudentplan[0]?.planInfo?.id
                console.log("PlanId", PlanId);
                if (PlanId) {
                    navigate('/student/mywork/plan/' + PlanId);
                }
            } else {
                navigate('/student/mywork');
            }
            props?.GetStudentPlanStatus(false);
            setLoading(false)
            setGotoplan(false)
        }

    }, [props?.studentplanstatus, props?.getstudentplan, gotoplan])

    const currentCourses = props?.currentDashboardCourses || [];
    const currentActivities = props?.currentDashboardActivity || [];
    const combinedDashboardData = [...currentCourses, ...currentActivities];

    const mappedData = combinedDashboardData.map(item => {
        if (item?.courseName) {
            return item?.courseName;
        } else {
            return item?.activityName;
        }
        return null;
    });

    const scheduleData = [
        { id: 1, title: 'Session with Brian', time: '10:00 AM - 11:00 AM' },
        { id: 2, title: 'Meeting with Team', time: '12:00 PM - 01:00 PM' },
        { id: 3, title: 'Client Call', time: '03:00 PM - 04:00 PM' },
    ];

    const handleNavigateToSchedule = () => {
        navigate('/student/schedule');
    }

    const handleNavigateToProfile = (e) => {
        console.log("hiiiiiii");
        e?.preventDefault();
        navigate('/student/myprofile', { state: { type: "goals" } });
    }

    const handleNavigateToPlans = (e) => {
        e?.preventDefault();
        let payload = {
            "searchString": "",
            "pageNo": 1,
            "limit": 40,
            "status": [],
            "sortOrder": "latest",
            "studentId": null
        }
        props?.GetStudentPlan(payload);
        setLoading(true)
        setGotoplan(true)
    }

    const handleInviteAdvisor = (type) => {
        setInviteType(type)
        setOpenAdvisorInvitePopUp(true)
    }

    const handleShow = () => {
        setInviteType("")
        setOpenAdvisorInvitePopUp(false);
    }

    const getNoOfPaidSessions = (advisorData) => {
        const subscriptionDetails = advisorData?.subscriptiondetails?.[0];
        return subscriptionDetails ? `${subscriptionDetails.noOfPaidSessions}` : '';
    };
    const getNoOffreeSessions = (advisorData) => {
        const subscriptionDetails = advisorData?.userInfo?.advisorProfile?.noOfFreeConsultation
        return subscriptionDetails ? `${subscriptionDetails}` : '';
    }

    const filteredData = props?.scheduledEvents?.filter((schedule) => {
        const currentDate = GetCurrentTimezone(props?.userProfile?.timezone);
        const eventStartTime = dayjs(schedule.eventTime); // Convert the eventTime to a dayjs object
        const isPastEvent = currentDate > eventStartTime.format('YYYY-MM-DDTHH:mm:ss');

        return !isPastEvent; // Keep only future events
    });

    console.log(props?.currentDashboardCourses, "props?.currentDashboardCourses");



    const sectionOrder = remainingSession?.length > 0
        ? ["A", "F", "B", "C", "D", "E", "G",]
        : ['A', 'B', 'C', 'D', 'E', 'F', 'G',];

    const sectionMap = {
        A: (
            <div className="studentDashboard_Dashboard_NetworkCarousel">
                {studentDashboardCount?.length > 0 &&
                    <>
                        <h3 className="studentDashboard_Dashboard_Title">Build your network and follow updates 🕸️</h3>
                        <CarouselComponent data={studentDashboardCount} />
                    </>
                }
            </div>
        ),
        B: (
            <div className="studentDashboard_Dashboard_GoalsCarousel">
                <h6 className="studentDashboard_Dashboard_Title">Set your goals ! We recommend the best advisors for you based on these goals 👇</h6>
                <div class="row">
                    {props?.isgetusergoals?.universities?.length > 0 ?
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                            <Card variant="outlined" sx={{ textAlign: 'center', borderRadius: "10px", marginBottom: "10px" }}>
                                <div className="dashSubContainer" >
                                    <h6 className="dashTitle">
                                        Set your target universities 🎯
                                    </h6>
                                    <Button onClick={() => handleNavigateToProfile()} className="btn cta--rounded cta-primary" style={{ borderRadius: '10px', margin: '10px' }}>
                                        <span style={{ paddingLeft: '5px', paddingRight: '5px' }}>Update</span>
                                        <svg width="18" height="20" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.08058 12.4194C3.95466 13.2935 5.0683 13.8888 6.28069 14.1299C7.49307 14.3711 8.74973 14.2473 9.89177 13.7742C11.0338 13.3012 12.0099 12.5001 12.6967 11.4723C13.3834 10.4445 13.75 9.23613 13.75 8C13.75 6.76387 13.3834 5.55549 12.6967 4.52769C12.0099 3.49988 11.0338 2.6988 9.89177 2.22575C8.74973 1.75271 7.49307 1.62893 6.28069 1.87009C5.0683 2.11125 3.95466 2.7065 3.08058 3.58058" stroke="white" stroke-width="2" />
                                            <path d="M9.375 8L10.1559 7.3753L10.6556 8L10.1559 8.6247L9.375 8ZM1.875 9C1.32271 9 0.875 8.55228 0.875 8C0.875 7.44772 1.32271 7 1.875 7V9ZM7.65587 4.2503L10.1559 7.3753L8.59413 8.6247L6.09413 5.4997L7.65587 4.2503ZM10.1559 8.6247L7.65587 11.7497L6.09413 10.5003L8.59413 7.3753L10.1559 8.6247ZM9.375 9H1.875V7H9.375V9Z" fill="white" />
                                        </svg>
                                    </Button>
                                </div>
                                <div className="p-3">
                                    <PillSection data={props?.isgetusergoals?.universities} maxWidth={300} maxHeight={75} />
                                </div>
                            </Card>
                        </div>
                        :
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                            <Card variant="outlined" sx={{ textAlign: 'center', borderRadius: "10px", marginBottom: "10px" }}>
                                <div className="dashSubContainer" >
                                    <h6 className="dashTitle">
                                        Set your target universities 🎯
                                    </h6>
                                    <Button onClick={() => handleNavigateToProfile()} className="btn cta--rounded cta-primary" style={{ borderRadius: '10px', margin: '10px' }}>
                                        <span style={{ paddingLeft: '20px', paddingRight: '5px' }}>Update</span>
                                        <svg width="18" height="20" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.08058 12.4194C3.95466 13.2935 5.0683 13.8888 6.28069 14.1299C7.49307 14.3711 8.74973 14.2473 9.89177 13.7742C11.0338 13.3012 12.0099 12.5001 12.6967 11.4723C13.3834 10.4445 13.75 9.23613 13.75 8C13.75 6.76387 13.3834 5.55549 12.6967 4.52769C12.0099 3.49988 11.0338 2.6988 9.89177 2.22575C8.74973 1.75271 7.49307 1.62893 6.28069 1.87009C5.0683 2.11125 3.95466 2.7065 3.08058 3.58058" stroke="white" stroke-width="2" />
                                            <path d="M9.375 8L10.1559 7.3753L10.6556 8L10.1559 8.6247L9.375 8ZM1.875 9C1.32271 9 0.875 8.55228 0.875 8C0.875 7.44772 1.32271 7 1.875 7V9ZM7.65587 4.2503L10.1559 7.3753L8.59413 8.6247L6.09413 5.4997L7.65587 4.2503ZM10.1559 8.6247L7.65587 11.7497L6.09413 10.5003L8.59413 7.3753L10.1559 8.6247ZM9.375 9H1.875V7H9.375V9Z" fill="white" />
                                        </svg>
                                    </Button>
                                </div>
                                <div className="p-3" style={{ textAlign: 'center' }}>
                                    Helps us recommend top university student advisors from these universities !                            </div>
                            </Card>
                        </div>
                    }
                    {props?.isgetusergoals?.professions?.length > 0 ?
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                            <Card variant="outlined" sx={{ textAlign: 'center', borderRadius: "10px", marginBottom: "10px", minHeight: '156px' }}>
                                <div className="dashSubContainer" >
                                    <h6 className="dashTitle">
                                        Set your career goals 🎯
                                    </h6>
                                    <Button onClick={() => handleNavigateToProfile()} className="btn cta--rounded cta-primary updateDashboard" style={{ borderRadius: '10px', margin: '10px' }}>
                                        <span style={{ paddingLeft: '5px', paddingRight: '5px' }}>Update</span>
                                        <svg width="18" height="20" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.08058 12.4194C3.95466 13.2935 5.0683 13.8888 6.28069 14.1299C7.49307 14.3711 8.74973 14.2473 9.89177 13.7742C11.0338 13.3012 12.0099 12.5001 12.6967 11.4723C13.3834 10.4445 13.75 9.23613 13.75 8C13.75 6.76387 13.3834 5.55549 12.6967 4.52769C12.0099 3.49988 11.0338 2.6988 9.89177 2.22575C8.74973 1.75271 7.49307 1.62893 6.28069 1.87009C5.0683 2.11125 3.95466 2.7065 3.08058 3.58058" stroke="white" stroke-width="2" />
                                            <path d="M9.375 8L10.1559 7.3753L10.6556 8L10.1559 8.6247L9.375 8ZM1.875 9C1.32271 9 0.875 8.55228 0.875 8C0.875 7.44772 1.32271 7 1.875 7V9ZM7.65587 4.2503L10.1559 7.3753L8.59413 8.6247L6.09413 5.4997L7.65587 4.2503ZM10.1559 8.6247L7.65587 11.7497L6.09413 10.5003L8.59413 7.3753L10.1559 8.6247ZM9.375 9H1.875V7H9.375V9Z" fill="white" />
                                        </svg>
                                    </Button>
                                </div>
                                <div className="p-3">
                                    <PillSection data={props?.isgetusergoals?.professions} maxWidth={369} maxHeight={75} />
                                </div>
                            </Card>
                        </div>
                        :
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                            <Card variant="outlined" sx={{ textAlign: 'center', borderRadius: "10px", marginBottom: "10px", minHeight: '156px' }}>
                                <div className="dashSubContainer" >
                                    <h6 className="dashTitle">
                                        Set your career goals 🎯
                                    </h6>
                                    <Button onClick={() => handleNavigateToProfile()} className="btn cta--rounded cta-primary updateDashboard" style={{ borderRadius: '10px', marginRight: '1rem' }}>
                                        <span style={{ paddingLeft: '5px', paddingRight: '5px' }}>Update</span>
                                        <svg width="18" height="20" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.08058 12.4194C3.95466 13.2935 5.0683 13.8888 6.28069 14.1299C7.49307 14.3711 8.74973 14.2473 9.89177 13.7742C11.0338 13.3012 12.0099 12.5001 12.6967 11.4723C13.3834 10.4445 13.75 9.23613 13.75 8C13.75 6.76387 13.3834 5.55549 12.6967 4.52769C12.0099 3.49988 11.0338 2.6988 9.89177 2.22575C8.74973 1.75271 7.49307 1.62893 6.28069 1.87009C5.0683 2.11125 3.95466 2.7065 3.08058 3.58058" stroke="white" stroke-width="2" />
                                            <path d="M9.375 8L10.1559 7.3753L10.6556 8L10.1559 8.6247L9.375 8ZM1.875 9C1.32271 9 0.875 8.55228 0.875 8C0.875 7.44772 1.32271 7 1.875 7V9ZM7.65587 4.2503L10.1559 7.3753L8.59413 8.6247L6.09413 5.4997L7.65587 4.2503ZM10.1559 8.6247L7.65587 11.7497L6.09413 10.5003L8.59413 7.3753L10.1559 8.6247ZM9.375 9H1.875V7H9.375V9Z" fill="white" />
                                        </svg>
                                    </Button>
                                </div>
                                <div className="p-3" style={{ textAlign: 'center' }}>
                                    Helps us recommend top advisors who are currently working on these career goals !                        </div>
                            </Card>
                        </div>
                    }
                </div>

            </div>
        ),
        C: (mappedData?.length > 0 ?
            <Card variant="outlined" sx={{ textAlign: 'center', borderRadius: "10px", marginBottom: "10px" }}>
                <div className="dashSubContainer" >
                    <h6 className="dashTitle">
                        Set your courses and Activities 🎯
                    </h6>
                    <Button onClick={() => handleNavigateToPlans()} className="btn cta--rounded cta-primary updateDashboard" style={{ borderRadius: '10px', marginRight: '1rem' }}>
                        <span style={{ paddingLeft: '5px', paddingRight: '5px' }}>Update</span>
                        <svg width="18" height="20" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.08058 12.4194C3.95466 13.2935 5.0683 13.8888 6.28069 14.1299C7.49307 14.3711 8.74973 14.2473 9.89177 13.7742C11.0338 13.3012 12.0099 12.5001 12.6967 11.4723C13.3834 10.4445 13.75 9.23613 13.75 8C13.75 6.76387 13.3834 5.55549 12.6967 4.52769C12.0099 3.49988 11.0338 2.6988 9.89177 2.22575C8.74973 1.75271 7.49307 1.62893 6.28069 1.87009C5.0683 2.11125 3.95466 2.7065 3.08058 3.58058" stroke="white" stroke-width="2" />
                            <path d="M9.375 8L10.1559 7.3753L10.6556 8L10.1559 8.6247L9.375 8ZM1.875 9C1.32271 9 0.875 8.55228 0.875 8C0.875 7.44772 1.32271 7 1.875 7V9ZM7.65587 4.2503L10.1559 7.3753L8.59413 8.6247L6.09413 5.4997L7.65587 4.2503ZM10.1559 8.6247L7.65587 11.7497L6.09413 10.5003L8.59413 7.3753L10.1559 8.6247ZM9.375 9H1.875V7H9.375V9Z" fill="white" />
                        </svg>
                    </Button>
                </div>
                <div className="p-3">
                    <PillSection data={mappedData} maxWidth={800} maxHeight={75} />
                </div>
            </Card>
            :
            <Card variant="outlined" sx={{ textAlign: 'center', borderRadius: "10px", marginBottom: "10px" }}>
                <div className="dashSubContainer" >
                    <h6 className="dashTitle">
                        Set your courses and Activities 🎯
                    </h6>
                    <Button onClick={() => handleNavigateToPlans()} className="btn cta--rounded cta-primary" style={{ borderRadius: '10px', marginRight: '1rem' }}>
                        <span style={{ paddingLeft: '5px', paddingRight: '5px' }}>Update</span>
                        <svg width="18" height="20" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.08058 12.4194C3.95466 13.2935 5.0683 13.8888 6.28069 14.1299C7.49307 14.3711 8.74973 14.2473 9.89177 13.7742C11.0338 13.3012 12.0099 12.5001 12.6967 11.4723C13.3834 10.4445 13.75 9.23613 13.75 8C13.75 6.76387 13.3834 5.55549 12.6967 4.52769C12.0099 3.49988 11.0338 2.6988 9.89177 2.22575C8.74973 1.75271 7.49307 1.62893 6.28069 1.87009C5.0683 2.11125 3.95466 2.7065 3.08058 3.58058" stroke="white" stroke-width="2" />
                            <path d="M9.375 8L10.1559 7.3753L10.6556 8L10.1559 8.6247L9.375 8ZM1.875 9C1.32271 9 0.875 8.55228 0.875 8C0.875 7.44772 1.32271 7 1.875 7V9ZM7.65587 4.2503L10.1559 7.3753L8.59413 8.6247L6.09413 5.4997L7.65587 4.2503ZM10.1559 8.6247L7.65587 11.7497L6.09413 10.5003L8.59413 7.3753L10.1559 8.6247ZM9.375 9H1.875V7H9.375V9Z" fill="white" />
                        </svg>
                    </Button>
                </div>
                <div className="p-3" style={{ textAlign: 'center' }}>
                    Helps us recommend advisors who can help you achieve best results !
                </div>
            </Card>
        ),
        D: (
            exploreCountForAdvisor?.length > 0 &&
            (
                <div className="mt-3">
                    <div className="studentDashboard_Dashboard_ExploreCarousel">
                        <div className="dashSubContainer">
                            <h6 className="dashTitle" style={{ textAlign: 'left' }}>
                                Connect with advisors who have achieved your goals 🚀
                            </h6>
                            <div className="d-flex justify-content-center align-items-center">

                                {/* Invite a Friend Button */}
                                {/* Invite a Friend Button */}
                                <Button onClick={() => handleInviteAdvisor("student")}
                                    className="btn cta--rounded cta-primary mb-1 mt-2"
                                    style={{ borderRadius: '10px', marginRight: '1rem' }}
                                >
                                    {/* Text for desktop view */}
                                    <span className="button-text">Invite an Advisor</span>
                                    {/* SVG for mobile/tablet view */}
                                    <Tooltip title="Invite an Advisor">

                                        <span className="button-svg">
                                            <GroupAddIcon />
                                        </span>
                                    </Tooltip>
                                </Button>

                                {/* Your Advisors Button */}
                                <Button
                                    className="btn cta--rounded cta-primary mb-1 mt-2"
                                    style={{ borderRadius: '10px', marginRight: '1rem' }}
                                    onClick={() => navigate('/student/mynetwork')}
                                >
                                    {/* Text for desktop view */}
                                    <span className="button-text">Your Advisors</span>
                                    {/* SVG for mobile/tablet view */}
                                    <Tooltip title="Your Advisors">

                                        <span className="button-svg">
                                            <PeopleIcon />
                                        </span>
                                    </Tooltip>
                                </Button>
                                {/* Explore Advisors Button */}
                                <Button
                                    className="btn cta--rounded cta-primary mb-1 mt-2"
                                    style={{ borderRadius: '10px' }}
                                    onClick={() => navigate('/student/mynetwork/exploreAdvisor')}
                                >
                                    {/* Text for desktop view */}
                                    <span className="button-text">Explore Advisors</span>
                                    {/* SVG for mobile/tablet view */}
                                    <Tooltip title="Explore Advisors">
                                        <span className="button-svg">
                                            <SearchIcon />
                                        </span>
                                    </Tooltip>
                                </Button>
                            </div>

                        </div>
                        <CarouselComponent data={exploreCountForAdvisor} advisor={"Advisor"} />
                    </div>

                </div>
            )

        ),
        E: (
            exploreCountForStudent?.length > 0 &&
            (<div className="mt-3">
                <div className="studentDashboard_Dashboard_ExploreCarousel">
                    {/* <div className="Explore_CardTitle_Section" > */}
                    {/* <div className="ExploreCarousel_btnContainer"> */}
                    <div className="dashSubContainer">
                        <h6 className="dashTitle" style={{ textAlign: 'left' }}>
                            Connect with students with similar goals 🎓
                        </h6>
                        {/* Invite a Friend Button */}
                        <Button onClick={() => handleInviteAdvisor("student")}
                            className="btn cta--rounded cta-primary mb-1 mt-2"
                            style={{ borderRadius: '10px', marginRight: '1rem' }}
                        >
                            <span className="buttonText">Invite a Friend</span>
                            <Tooltip title="Invite a Friend" placement="top">
                                <span className="buttonIcon">
                                    <GroupAddIcon />
                                </span>
                            </Tooltip>
                        </Button>

                        {/* Your Friends Button */}
                        <Button
                            className="btn cta--rounded cta-primary mb-1 mt-2"
                            style={{ borderRadius: '10px', marginRight: '1rem' }}
                            onClick={() => navigate('/student/mynetwork', { state: { type: "student" } })}
                        >
                            <span className="buttonText">Your Friends</span>
                            <Tooltip title="Your Friends" placement="top">
                                <span className="buttonIcon">
                                    <PeopleIcon />
                                </span>
                            </Tooltip>
                        </Button>

                        {/* Explore Students Button */}
                        <Button
                            className="btn cta--rounded cta-primary mb-1 mt-2"
                            style={{ borderRadius: '10px' }}
                            onClick={() => navigate('/student/mynetwork/exploreStudent')}
                        >
                            <span className="buttonText">Explore Students</span>
                            <span className="buttonIcon">
                                <Tooltip title="Explore Students" placement="top">
                                    <SearchIcon />

                                </Tooltip>
                            </span>
                        </Button>
                    </div>
                    {/* </div> */}
                    {/* </div> */}
                    <CarouselComponent data={exploreCountForStudent} students={"Students"} />
                </div>
            </div>
            )

        ),
        F: (
            <div class="row mt-3">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                    <Card variant="outlined" sx={{ textAlign: 'center', borderRadius: "10px" }}>
                        <h6 className="dashTitle mt-3 ms-3" style={{ textAlign: 'center' }}>
                            Schedule available advisor sessions  🗓️
                        </h6>
                        {remainingSession?.length > 0 ?

                            <div className="p-3" style={{ height: "380px", overflow: "auto" }}>
                                {remainingSession?.length > 0 && remainingSession?.map((data, index) => (
                                    <>
                                        <ScheduleCard advisorName={data?.userInfo?.firstName} session={data?.subscriptiondetails?.length > 0 ? "paid" : "free"} remainingSession={getNoOfPaidSessions(data) ? getNoOfPaidSessions(data) : getNoOffreeSessions(data)} />
                                    </>
                                ))}
                            </div>
                            :
                            <div className="p-3" style={{ maxHeight: "fitContent", overflow: "auto", textAlign: 'center' }}>
                                You can book your free consultation sessions after you connect with advisors
                                <div>
                                    <Button
                                        className="btn cta--rounded cta-primary mb-1 mt-2"
                                        style={{ borderRadius: '10px' }}
                                        onClick={() => navigate('/student/mynetwork/exploreAdvisor')}
                                    >
                                        {/* Text for desktop view */}
                                        <span className="button-tex">Explore Advisors</span>
                                        {/* SVG for mobile/tablet view */}
                                        <Tooltip title="Explore Advisors">
                                            <span className="ms-2">
                                                <svg width="18" height="20" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.08058 12.4194C3.95466 13.2935 5.0683 13.8888 6.28069 14.1299C7.49307 14.3711 8.74973 14.2473 9.89177 13.7742C11.0338 13.3012 12.0099 12.5001 12.6967 11.4723C13.3834 10.4445 13.75 9.23613 13.75 8C13.75 6.76387 13.3834 5.55549 12.6967 4.52769C12.0099 3.49988 11.0338 2.6988 9.89177 2.22575C8.74973 1.75271 7.49307 1.62893 6.28069 1.87009C5.0683 2.11125 3.95466 2.7065 3.08058 3.58058" stroke="white" stroke-width="2" />
                                                    <path d="M9.375 8L10.1559 7.3753L10.6556 8L10.1559 8.6247L9.375 8ZM1.875 9C1.32271 9 0.875 8.55228 0.875 8C0.875 7.44772 1.32271 7 1.875 7V9ZM7.65587 4.2503L10.1559 7.3753L8.59413 8.6247L6.09413 5.4997L7.65587 4.2503ZM10.1559 8.6247L7.65587 11.7497L6.09413 10.5003L8.59413 7.3753L10.1559 8.6247ZM9.375 9H1.875V7H9.375V9Z" fill="white" />
                                                </svg>
                                            </span>
                                        </Tooltip>
                                    </Button>
                                </div>
                            </div>
                        }
                    </Card>
                </div>
                {filteredData?.length > 0 &&
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                        <Card variant="outlined" sx={{ textAlign: 'center', borderRadius: "10px" }}>
                            <h6 className="dashTitle  mt-3" style={{ textAlign: 'left', marginLeft: '17px' }}>
                                Scheduled sessions  🗓️
                            </h6>
                            <div className="p-3" style={{ height: "300px", overflow: "auto" }}>
                                {filteredData?.map((schedule) => (
                                    <ScheduleListCard key={schedule.id} data={schedule} />
                                ))}
                            </div>
                            <div className="d-flex justify-content-center align-items-center" >
                                <Button className="btn cta--rounded cta-primary m-3" style={{ borderRadius: '10px', padding: "12px" }}>
                                    <span onClick={() => handleNavigateToSchedule()}>View schedule
                                        <svg width="18" height="20" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.08058 12.4194C3.95466 13.2935 5.0683 13.8888 6.28069 14.1299C7.49307 14.3711 8.74973 14.2473 9.89177 13.7742C11.0338 13.3012 12.0099 12.5001 12.6967 11.4723C13.3834 10.4445 13.75 9.23613 13.75 8C13.75 6.76387 13.3834 5.55549 12.6967 4.52769C12.0099 3.49988 11.0338 2.6988 9.89177 2.22575C8.74973 1.75271 7.49307 1.62893 6.28069 1.87009C5.0683 2.11125 3.95466 2.7065 3.08058 3.58058" stroke="white" stroke-width="2" />
                                            <path d="M9.375 8L10.1559 7.3753L10.6556 8L10.1559 8.6247L9.375 8ZM1.875 9C1.32271 9 0.875 8.55228 0.875 8C0.875 7.44772 1.32271 7 1.875 7V9ZM7.65587 4.2503L10.1559 7.3753L8.59413 8.6247L6.09413 5.4997L7.65587 4.2503ZM10.1559 8.6247L7.65587 11.7497L6.09413 10.5003L8.59413 7.3753L10.1559 8.6247ZM9.375 9H1.875V7H9.375V9Z" fill="white" />
                                        </svg>
                                    </span>
                                </Button>
                            </div>
                        </Card>
                    </div>
                    // :
                    // <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                    //     <Card variant="outlined" sx={{ textAlign: 'center', borderRadius: "10px" }}>
                    //         <h6 className="dashTitle  mt-3" style={{ textAlign: 'left', marginLeft: '17px' }}>
                    //             Scheduled sessions  🗓️
                    //         </h6>
                    //         <div className="p-3" style={{ height: "300px", overflow: "auto" }}>
                    //            Nodata
                    //         </div>
                    //         <div className="d-flex justify-content-center align-items-center" >
                    //             <Button className="btn cta--rounded cta-primary m-3" style={{ borderRadius: '10px', padding: "12px" }}>
                    //                 <span onClick={() => handleNavigateToSchedule()}>View schedule
                    //                     <svg width="18" height="20" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    //                         <path d="M3.08058 12.4194C3.95466 13.2935 5.0683 13.8888 6.28069 14.1299C7.49307 14.3711 8.74973 14.2473 9.89177 13.7742C11.0338 13.3012 12.0099 12.5001 12.6967 11.4723C13.3834 10.4445 13.75 9.23613 13.75 8C13.75 6.76387 13.3834 5.55549 12.6967 4.52769C12.0099 3.49988 11.0338 2.6988 9.89177 2.22575C8.74973 1.75271 7.49307 1.62893 6.28069 1.87009C5.0683 2.11125 3.95466 2.7065 3.08058 3.58058" stroke="white" stroke-width="2" />
                    //                         <path d="M9.375 8L10.1559 7.3753L10.6556 8L10.1559 8.6247L9.375 8ZM1.875 9C1.32271 9 0.875 8.55228 0.875 8C0.875 7.44772 1.32271 7 1.875 7V9ZM7.65587 4.2503L10.1559 7.3753L8.59413 8.6247L6.09413 5.4997L7.65587 4.2503ZM10.1559 8.6247L7.65587 11.7497L6.09413 10.5003L8.59413 7.3753L10.1559 8.6247ZM9.375 9H1.875V7H9.375V9Z" fill="white" />
                    //                     </svg>
                    //                 </span>
                    //             </Button>
                    //         </div>
                    //     </Card>
                    // </div>
                }
            </div>
        ),
        G: (
            <div className="mt-3">
                <Card variant="outlined" sx={{ textAlign: 'center', borderRadius: "10px", marginBottom: "10px" }}>
                    <div className="CardTitle_Section" >
                        <h6 className="studentDashboard_Dashboard_Title">
                            Create milestones and post updates on current work ✍️
                        </h6>
                    </div>
                    <div className="CardBody_Section">
                        <h6>🌟 Show your work and receive feedback from your network</h6>
                        <h6>💰 Monetize your work after the semester ends</h6>
                    </div>
                    <div className="p-3">
                        <SelectableTags bold text={combinedDashboardData} user={"student"} />

                    </div>
                </Card>
            </div>
        ),

    };


    return (
        <>
            {loading &&
                <Loader />
            }
            {openAdvisorInvitePopUp &&
                <InviteModal type={inviteType} openAdvisorInvitePopUp={openAdvisorInvitePopUp} handleShow={handleShow} />
            }
            <div style={{ "width": "100%", "display": "flex", "justifyContent": "center", }}> {<CustomAlert />}</div>

            <div className="studentDashboard">
                <div className="studentDashboard_Mobile">
                    <div className="studentDashboard_Mobile_Section">
                        <div className="studentDashboard_Mobile_Section_Title">
                            <p>Dashboard</p>
                        </div>
                    </div>
                </div>
                <div className="studentDashboard_Dashboard">
                    <div className="studentDashboard_Dashboard_TopText">
                        <h2>Welcome {props.userProfile && props.userProfile.firstName}! </h2>
                        <p className="studentDashboard_Dashboard_SubText"><span>Your next steps 👇</span></p>
                    </div>
                    {sectionOrder?.map(sectionKey => sectionMap[sectionKey])}
                </div>
            </div>

        </>
    );
};

const mapStateToProps = ({ commonData, userProfileData, studentData, }) => {
    const { userProfile } = userProfileData;
    const { isgetusergoals, dashboardCount, dashboardCountStatus, studentplanstatus, getstudentplan, adviserCountForStudent } = studentData;
    const { message, errorList, connectedAdvisors, paidSessionBooked, bookPaidSessionError, sessionBooked, booksessionerror, totalUserCount, scheduledEvents, currentDashboardCourses, currentDashboardActivity } = commonData;
    return {
        message, errorList, connectedAdvisors, isgetusergoals, userProfile, paidSessionBooked, bookPaidSessionError, sessionBooked, booksessionerror,
        totalUserCount, dashboardCount, dashboardCountStatus, scheduledEvents, studentplanstatus, getstudentplan, adviserCountForStudent, currentDashboardCourses, currentDashboardActivity
    };
};
export default connect(mapStateToProps, {
    clearMessage, bookPaidSessionErrorStatus, getChildProfile, getUserCount, bookSession,
    bookPaidSession, bookSessionStatus, bookPaidSessionStatus, getDashboardCountStatus, GetStudentPlan, GetStudentPlanStatus,
})(Index);
