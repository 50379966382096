import React, { useRef, useEffect, useState } from 'react';
import { DeleteCourse, DeleteActivity } from '../../../redux/actions';
import { connect } from 'react-redux';

const Index = (props) => {
  const textRef = useRef(null);
  const [width, setWidth] = useState(0);

  const deleteCourse = (deleteId) => {
    if (props?.activityDelete) {
      let payload = {
        planId: props?.totalData?.planId,
        phaseId: props?.totalData?.id,
      };
      props.DeleteActivity(payload, deleteId, 'dashboard');
    } else {
      let payload = {
        planId: props?.totalData?.planId,
        phaseId: props?.totalData?.id,
      };
      props.DeleteCourse(payload, deleteId, 'dashboard');
    }
  };

  useEffect(() => {
    if (textRef.current) {
      setWidth(textRef.current.offsetWidth + 10); // Adding 10px for padding
    }
  }, []);

  const ellipsizeText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text.slice(0, maxLength) + '...'; // Add ellipsis
    }
    return text;
  };

  const ellipsizedText = ellipsizeText(props.text, 23); // Adjust the maximum length as needed

  useEffect(() => {
    if (textRef.current) {
      setWidth(textRef.current.offsetWidth + 10); // Adding 10px for padding
    }
  }, []);

  return (
    <div
      className="tag__tags-chip"
      style={{
        maxWidth: 'fit-content',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        height: '36px',
        minWidth: 'fit-content',
      }}
    >
      <p
        ref={textRef}
        className={`Chips tag__tags-chip__tag-text${props.bold ? '-bold' : '-light'
          }`}
        title={props?.text}
      >
        {ellipsizedText}
      </p>
      {props?.close && (
        <svg
          onClick={() => deleteCourse(props.deleteId)}
          style={{ cursor: 'pointer' }}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM12 13.4142L8.70711 16.7071L7.29289 15.2929L10.5858 12L7.29289 8.70711L8.70711 7.29289L12 10.5858L15.2929 7.29289L16.7071 8.70711L13.4142 12L16.7071 15.2929L15.2929 16.7071L12 13.4142Z"
            fill="#4a4848"
          />
        </svg>
      )}
    </div>
  );
};

const mapStateToProps = ({ studentData }) => {
  const {
    isCourseMileStone,
    createCourseMilestoneStatus,
    mileCourseUpdata,
    isMilestoneDeleted,
    isUpdateResource,
    isaddNewResource,
    isDeleteResource,
    courseDataById,
    courseDataByIdStatus,
    isMileStatus,
    isMileStoneCourseStatus,
  } = studentData;
  return {
    isCourseMileStone,
    createCourseMilestoneStatus,
    mileCourseUpdata,
    isMilestoneDeleted,
    isUpdateResource,
    isaddNewResource,
    isDeleteResource,
    courseDataById,
    courseDataByIdStatus,
    isMileStatus,
    isMileStoneCourseStatus,
  };
};

export default connect(mapStateToProps, { DeleteCourse, DeleteActivity })(Index);
