import React from "react";

const Index = (props) => {
    return (
        <div className="search">
            <span className="searchIcon">
                <svg width="20" height="21" viewBox="0 0 22 21" fill="none" xmlns="http:www.w3.org/2000/svg">
                    <path
                        d="M14.5046 3.22982C11.3749 0.183809 6.28112 0.183809 3.15144 3.22982C0.0224364 6.2765 0.0224364 11.2335 3.15144 14.2801C5.93851 16.992 10.2792 17.2826 13.4069 15.1649C13.4727 15.468 13.6233 15.7572 13.8655 15.993L18.4233 20.4289C19.0875 21.0741 20.1608 21.0741 20.8216 20.4289C21.4852 19.7832 21.4852 18.7385 20.8216 18.0947L16.2638 13.6575C16.023 13.4237 15.7251 13.2765 15.4137 13.2124C17.5909 10.1677 17.2923 5.9437 14.5046 3.22982ZM13.0656 12.8796C10.729 15.1537 6.92633 15.1537 4.59043 12.8796C2.25521 10.6055 2.25521 6.90511 4.59043 4.631C6.92633 2.35755 10.729 2.35755 13.0656 4.631C15.4022 6.90511 15.4022 10.6055 13.0656 12.8796Z"
                        fill="#1C84EE"
                    />
                </svg>
            </span>
            <input
                type="text"
                className="searchTerm"
                placeholder={props.placeholder ? props.placeholder : ""}
                onChange={props.onSearch}
                value={props.value}
            />
        </div>
    );
};

export default Index;
