import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import TwoUserIcons from "../TwoUserIcons";
import { Dropdown } from "react-bootstrap";
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view: false,
        };
    }
    openDropDown = () => {
        this.setState({
            showDropDown: !this.state.showDropDown,
        });
    };

    closeDropDown = () => {
        this.setState({
            showDropDown: false,
        });
    };
    render() {
        const dropDownItems = [
            " Start conversation",
            " Book a meeting",
            "  End cooperation",
        ];
        let DropDownDataList =
            dropDownItems &&
            dropDownItems.length > 0 &&
            dropDownItems.map((x) => {
                return <li className="pb-2">{x}</li>;
            });
        let { TableData, TableHeader, AvatarIconData, headerShow, Archived } = this.props;
        var TableDataList = TableData && TableData.map((element, key) => {
            return (
                <>
                    <tr>
                        <td>
                            <div className="w-100 me-5">
                                <div className="row">
                                    <div className="col-3 col-sm-2 col-md-3 ">
                                        {Archived === true ?
                                            <div className="zip DocumentsList__List__items__Extension ">zip</div>
                                            :
                                            <div className="fileType ">
                                                <img src={element.image} style={{ width: "20px", height: "20px" }} />
                                            </div>
                                        }
                                    </div>
                                    <div className="col-9 col-sm-10  col-md-9 fileExtension-desc" >
                                        <h2 className="document-title">{element.DocumentName}</h2>
                                        <p className="document-date"> {element.DocumentDate} - {element.DocumentTime} </p>
                                    </div>
                                </div>
                            </div>
                              </td>

                        <td> <p className="document-update">{element.LastUpdated}</p></td>
                        <td> <p className="document-desc">{element.Description}</p> </td>
                        {headerShow === false ?
                            <td>
                                <div class="fileSize">
                                    <span>2.3 MB</span>
                                </div>
                            </td>

                            :
                            <td>
                                <button className="btn cta--libbutton2 mt-1">GDrive</button>
                                <button className="btn  cta--libbutton1 mt-1">Phase1</button>
                            </td>
                        }

                        {headerShow === false ?
                            <td>
                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.46875 9.28125C0.59307 9.28125 0.712299 9.33064 0.800206 9.41854C0.888114 9.50645 0.9375 9.62568 0.9375 9.75V12.0938C0.9375 12.3424 1.03627 12.5808 1.21209 12.7567C1.3879 12.9325 1.62636 13.0312 1.875 13.0312H13.125C13.3736 13.0312 13.6121 12.9325 13.7879 12.7567C13.9637 12.5808 14.0625 12.3424 14.0625 12.0938V9.75C14.0625 9.62568 14.1119 9.50645 14.1998 9.41854C14.2877 9.33064 14.4069 9.28125 14.5312 9.28125C14.6556 9.28125 14.7748 9.33064 14.8627 9.41854C14.9506 9.50645 15 9.62568 15 9.75V12.0938C15 12.591 14.8025 13.0679 14.4508 13.4196C14.0992 13.7712 13.6223 13.9688 13.125 13.9688H1.875C1.37772 13.9688 0.900806 13.7712 0.549175 13.4196C0.197544 13.0679 0 12.591 0 12.0938V9.75C0 9.62568 0.049386 9.50645 0.137294 9.41854C0.225201 9.33064 0.34443 9.28125 0.46875 9.28125Z" fill="#303F9F"></path><path d="M7.16823 11.1131C7.21177 11.1568 7.2635 11.1914 7.32045 11.215C7.3774 11.2387 7.43845 11.2508 7.5001 11.2508C7.56176 11.2508 7.62281 11.2387 7.67976 11.215C7.73671 11.1914 7.78844 11.1568 7.83198 11.1131L10.6445 8.30062C10.7325 8.21261 10.7819 8.09323 10.7819 7.96875C10.7819 7.84427 10.7325 7.72489 10.6445 7.63687C10.5565 7.54886 10.4371 7.49941 10.3126 7.49941C10.1881 7.49941 10.0687 7.54886 9.98073 7.63687L7.96885 9.64969V1.40625C7.96885 1.28193 7.91947 1.1627 7.83156 1.07479C7.74365 0.986886 7.62442 0.9375 7.5001 0.9375C7.37578 0.9375 7.25656 0.986886 7.16865 1.07479C7.08074 1.1627 7.03135 1.28193 7.03135 1.40625V9.64969L5.01948 7.63687C4.93146 7.54886 4.81208 7.49941 4.6876 7.49941C4.56313 7.49941 4.44375 7.54886 4.35573 7.63687C4.26771 7.72489 4.21826 7.84427 4.21826 7.96875C4.21826 8.09323 4.26771 8.21261 4.35573 8.30062L7.16823 11.1131Z" fill="#303F9F">
                                    </path>
                                </svg>
                            </td>
                            :
                            <td>
                                <div className="two-icon">
                                    <div className="Two-Avatar-icon">
                                        <TwoUserIcons AvatarIconData={AvatarIconData} />
                                    </div>
                                </div>
                            </td>
                        }

                        <td className="cursorshow">
                            
                            <div className="advisor-card">
                                <div className="advisor-card__card adv-lib">
                                <div
                                    className="cardgrid__dot"
                                    onClick={(e) => this.openDropDown(e)}
                                >
                                    <Dropdown >
                                        <Dropdown.Toggle
                                            id="dropdown-basic "
                                            drop={"start"}
                                        >
                                            <svg
                                                width="16"
                                                height="4"
                                                viewBox="0 0 16 4"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M1.6 3.2C0.716344 3.2 0 2.48366 0 1.6C0 0.716344 0.716344 0 1.6 0C2.48366 0 3.2 0.716344 3.2 1.6C3.2 2.48366 2.48366 3.2 1.6 3.2ZM7.99844 3.2C7.11478 3.2 6.39844 2.48366 6.39844 1.6C6.39844 0.716344 7.11478 0 7.99844 0C8.88209 0 9.59844 0.716344 9.59844 1.6C9.59844 2.48366 8.88209 3.2 7.99844 3.2ZM12.8 1.6C12.8 2.48366 13.5163 3.2 14.4 3.2C15.2837 3.2 16 2.48366 16 1.6C16 0.716344 15.2837 0 14.4 0C13.5163 0 12.8 0.716344 12.8 1.6Z"
                                                    fill="#1B1C1E"
                                                />
                                            </svg>
                                        </Dropdown.Toggle>
                                        {dropDownItems &&
                                            dropDownItems.length > 0 && (
                                                <div
                                                    classname="dropDowns"
                                                    style={{ width: "50px" }}
                                                >
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item>
                                                            {DropDownDataList}
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </div>
                                            )}
                                    </Dropdown>
                                </div>
                                </div>
                            </div>
                           
                        </td>
                    </tr>
                </>
            );
        });
        return (
            <>

                {TableData && TableData.length > 0 && (
                    <div className="Getdocs">
                        <div style={{overflow:"auto"}}>
                            <Table  responsive="sm md lg xl" className="libtable table-borderless">
                                <thead>
                                    <tr>
                                        <th>{TableHeader.header1}</th>
                                        <th>{TableHeader.header2}</th>
                                        <th>{TableHeader.header3}</th>
                                        <th>{TableHeader.header4}</th>
                                        <th>{TableHeader.header5}</th>
                                    </tr>
                                </thead>
                                <tbody className="hovertbl ">{TableDataList}</tbody>
                            </Table>
                        </div>
                    </div>
                )}
            </>
        );
    }
}
export default Index;
