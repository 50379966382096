import React, { useEffect, useState } from 'react'
import "./publicStudentProfile.less";
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from "react-redux";
import { withRouter } from "../../../redux/store/navigate";
import { sharedUserProfile, resetPublicUserProfile, getStudentCourseActivityDetails, resetPublicProfileStatus } from "../../../redux/actions";
import PersonIcon from '@mui/icons-material/Person';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import PlanCard from "../../../componentsNew/Common/Cards/Common/PlanCard";
import CourseCard from "../../../componentsNew/Common/Cards/Common/CourseCard";
import ActivityCard from "../../../componentsNew/Common/Cards/Common/ActivityCard";
import Loader from '../../../components/Loader';
import { isUserAuthenticated } from '../../../utils/AuthUtils';
import { VIEW_PUBLIC_PROFILE } from '../../../redux/constants/CommonTypes';
import { useLocation } from 'react-router-dom';
import { useImageUrl } from '../../../utils/UseImageURL';


const Index = (props) => {
    const [loading, setLoading] = useState(true);
    const { studentId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setLoading(true);
        props?.sharedUserProfile(studentId);
        props.getStudentCourseActivityDetails(studentId);
    }, [studentId])
    useEffect(() => {
        return () => {
            props?.resetPublicUserProfile();
        }
    }, [])

    let { userInfo, plans, userInfo: { studentProfile } = {} } = props?.sharedData || {};
    let { courses, activities } = props?.publicStudentCoursesActivities;

    // useEffect(() => {
    //     if (Object.keys(props?.sharedData)?.length > 0) {
    //         setLoading(false);
    //     }
    //     if (props?.loadPublicProfileSuccess) {
    //         let isAuthenticated = isUserAuthenticated();
    //         if (isAuthenticated && studentId) {
    //             console.log(props?.sharedData, "userInfo");
    //             navigate('/advisor/mynetwork', {
    //                 state: {
    //                     type: VIEW_PUBLIC_PROFILE,
    //                     userId: studentId
    //                 }
    //             })
    //         }
    //         console.log(isAuthenticated, 'isAuthenticated');
    //         props?.resetPublicProfileStatus(false);
    //     }
    // }, [userInfo, props?.loadPublicProfileSuccess])

    useEffect(() => {
        if (Object.keys(props?.sharedData)?.length > 0) {
            setLoading(false);
        }

        if (props?.loadPublicProfileSuccess) {
            const isAuthenticated = isUserAuthenticated();
            const studentId = props?.sharedData?.userId;

            if (isAuthenticated && studentId) {
                if (location.pathname !== `/view/profile/${studentId}`) {
                    console.log(props?.sharedData, "userInfo");
                    navigate('/advisor/mynetwork', {
                        state: {
                            type: VIEW_PUBLIC_PROFILE,
                            userId: studentId,
                        },
                    });
                }
            }
            props?.resetPublicProfileStatus(false);
        }
    }, [props?.loadPublicProfileSuccess, props?.sharedData, location.pathname]);


    const gpaText = (weighted, unWeigted) => {
        if (weighted && unWeigted) {
            return `Achieved unweighted ${weighted} and weighted ${unWeigted} GPA`
        } else if (weighted) {
            return `Achieved weighted ${weighted} GPA`
        } else if (unWeigted) {
            return `Achieved unweighted ${unWeigted} GPA`
        } else return null;
    }

    const checkIfActivePhase = (phaseId) => {
        return !!(plans?.find(planData => planData?.planInfo?.activePhaseId == phaseId));
    }

    const getCourseStatus = (phaseData) => {
        if (phaseData?.isPassed) {
            return <div className="courseFullViewCard__topSection__successStatus">Completed</div>
        } else if (checkIfActivePhase(phaseData?.id)) {
            return <div className="courseFullViewCard__topSection__inProgressStatus">Inprogress</div>
        } else {
            return <div className="courseFullViewCard__topSection__upCommingStatus">Up Coming</div>
        }
    }

    const handleConnect = () => {
        navigate('/', {
            state: {
                type: VIEW_PUBLIC_PROFILE,
                userId: studentId
            }
        })
    }

    const imageUrl = useImageUrl(userInfo?.avatarPath);

    return (
        <>
            {loading ? <Loader /> :
                <div className='studentPublicProfile'>
                    <div className="studentPublicProfile__studentProfileInfo">
                        <div className="studentPublicProfile__studentProfileInfo__userDetail">
                            {(userInfo?.avatarPath ) ? (
                                <div class="studentPublicProfile__studentProfileInfo__userDetail__imgContainer" >
                                    <img src={imageUrl} alt="user" className='studentPublicProfile__studentProfileInfo__userDetail__imgContainer__profileImg' />
                                </div>
                            ) :
                                <div class="advisorProfileCard__info-sec__sub-img" >
                                   <PersonIcon color="action" className='studentPublicProfile__studentProfileInfo__userDetail__placeholderImg' />
                                </div>
                            }
                            <div className="studentPublicProfile__studentProfileInfo__userDetail__info">
                                <p className='studentPublicProfile__studentProfileInfo__userDetail__info__userName'>{userInfo?.fullName}</p>
                                <p className='studentPublicProfile__studentProfileInfo__userDetail__info__country'>{userInfo?.country}</p>
                            </div>
                        </div>
                        <div className="studentPublicProfile__studentProfileInfo__ctaSection">
                            <button className="studentPublicProfile__studentProfileInfo__ctaSection__btn primary-btn" onClick={handleConnect}>
                                Connect
                            </button>
                        </div>
                    </div>
                    <div className="studentPublicProfile__studentAboutSection">
                        <p className='studentPublicProfile__studentAboutSection__subTitle'>About {userInfo?.fullName}</p>
                        <div className="studentPublicProfile__studentAboutSection__studentAboutDescp">
                            <span >
                                <SchoolOutlinedIcon className='descpIcon' />
                                <p className=''>Graduating from {studentProfile?.currrentSchoolName} in {studentProfile?.graduationYear} </p>
                            </span>
                            {studentProfile?.universities?.length > 0 &&
                                <span >
                                    <EmojiEventsOutlinedIcon className='descpIcon' />
                                    <p className=''>Aspiring to attend {studentProfile?.universities?.join(', ')}</p>
                                </span>
                            }
                            {studentProfile?.professions?.length > 0 &&
                                <span >
                                    <EmojiEventsOutlinedIcon className='descpIcon' />
                                    <p className=''>Aspiring to become {studentProfile?.professions?.join(', ')}</p>
                                </span>
                            }
                            {console.log("studentProfile", studentProfile)}
                            {gpaText(studentProfile?.weighted, studentProfile?.unweighted) &&
                                <span >
                                    <EmojiEventsOutlinedIcon className='descpIcon' />
                                    <p className=''>{gpaText(studentProfile?.unweighted, studentProfile?.weighted)}</p>
                                </span>
                            }
                        </div>
                    </div>
                    <div className="studentPublicProfile__studentInterestSection">
                        <p className='studentPublicProfile__studentInterestSection__subTitle'>{userInfo?.fullName} is interested in</p>
                        <div className="studentPublicProfile__studentInterestSection__studentInterestDescp">
                            {studentProfile?.studentsCollab &&
                                <span >
                                    <StarBorderOutlinedIcon className='descpIcon' />
                                    <p className=''>Collaborating with Students with similar goals</p>
                                </span>
                            }
                            {studentProfile?.helpWithCoursesAndActivities &&
                                <span >
                                    <StarBorderOutlinedIcon className='descpIcon' />
                                    <p className=''>Hiring advisors to help select courses and activities to meet goals</p>
                                </span>
                            }
                            {studentProfile?.helpWithMilestones &&
                                <span >
                                    <StarBorderOutlinedIcon className='descpIcon' />
                                    <p className=''>Hiring advisors to help with course and activity milestones throughout the semester</p>
                                </span>
                            }
                        </div>
                    </div>
                    {courses?.length > 0 &&
                        <div className="studentPublicProfile__studentCoursesSection">
                            <p className='studentPublicProfile__studentCoursesSection__subTitle'>Courses</p>
                            <div className="studentPublicProfile__studentCoursesSection__courses">
                                {courses?.map(course => {
                                    return (
                                        <CourseCard data={course} key={course?.id}>
                                            {getCourseStatus(course?.planPhase)}
                                        </CourseCard>
                                    )
                                })}
                            </div>
                        </div>
                    }
                    {activities?.length > 0 &&
                        <div className="studentPublicProfile__studentCoursesSection">
                            <p className='studentPublicProfile__studentCoursesSection__subTitle'>Activities</p>
                            <div className="studentPublicProfile__studentCoursesSection__courses">
                                {activities?.map(activity => {
                                    return (
                                        <ActivityCard data={activity} key={activity?.id}>
                                            {getCourseStatus(activity?.planPhase)}
                                        </ActivityCard>
                                    )
                                })}
                            </div>
                        </div>
                    }
                    {plans?.length > 0 &&
                        <div className="studentPublicProfile__studentPlanSection">
                            <p className='studentPublicProfile__studentPlanSection__subTitle'>Plans</p>
                            <div className="studentPublicProfile__studentPlanSection__plans">
                                {plans?.map(planData => <PlanCard key={planData?.planInfo?.id} planData={planData} studentdata={studentProfile} />)}
                            </div>
                        </div>
                    }
                </div>
            }
        </>
    )
}

const mapStateToProps = ({ auth, commonData, userProfileData }) => {
    const { message, errorList, loading } = commonData;
    const { sharedData, loadPublicProfileSuccess } = auth;
    const { userProfile, publicStudentCoursesActivities, isPublicCourseActivityLoaded } = userProfileData;
    return { message, errorList, userProfile, publicStudentCoursesActivities, isPublicCourseActivityLoaded, loading, sharedData, loadPublicProfileSuccess };
};

export default connect(mapStateToProps, { sharedUserProfile, resetPublicUserProfile, getStudentCourseActivityDetails, resetPublicProfileStatus })(withRouter(Index));
