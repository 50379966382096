import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import PersonIcon from '@mui/icons-material/Person';
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import { Grid, Button, Typography } from '@mui/material';
import Rating from '@mui/material/Rating';
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import ActivityCard from "../../componentsNew/Common/Cards/Common/ActivityCard";
import CourseCard from "../../componentsNew/Common/Cards/Common/CourseCard";
import PillSection from "./minipills";
import { clearErrorList, clearMessage, facebookLogin, forgetPassword, getUserProfile, googleLogin, sharedUserProfile, verifyEmail, verifyPassword, } from "../../redux/actions";
import { withRouter } from "../../redux/store/navigate";
import WorkingHours from './WorkingHours';
import "./profileuser.less";
import { VIEW_PUBLIC_PROFILE } from '../../redux/constants/CommonTypes';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import { useImageUrl } from '../../utils/UseImageURL';


const Index = (props) => {
  const { studentId } = useParams();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [descriptions, setDescription] = useState(null)
  const [viewMore, setViewMore] = useState(false)
  const { userAdvisorId } = useParams();
  const [Ratings, setRatings] = useState(null);

  const handlePopoverOpen = (event, data) => {
    setAnchorEl(event.currentTarget);
    if (data !== undefined) {
      setDescription(data)
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setDescription(null)
  };
  const open = Boolean(anchorEl);
  let { userInfo, userInfo: { advisorProfile } = {} } = props?.sharedData;

  const stripHTMLTags = (html) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || "";
  };

  const advisorActivities = advisorProfile?.advisorActivities || [];
  const advisorCourses = advisorProfile?.advisorCourses || [];

  const combinedData = [...advisorActivities, ...advisorCourses];

  const mappedData = combinedData.map(item => {
    if (item.activityName) {
      return item.activityName;
    } else if (item.courseName) {
      return item.courseName;
    }
    return null;
  });

  useEffect(() => {
    props?.sharedUserProfile(userAdvisorId)
  }, [userAdvisorId])

  const handleConnect = () => {
    navigate('/', {
      state: {
        type: VIEW_PUBLIC_PROFILE,
        userId: studentId
      }
    })
  }
  const imageUrl = useImageUrl(userInfo?.avatarPath);

  useEffect(() => {
    if (advisorProfile?.advisorReviewSummary?.averageRating !== undefined) {
      setRatings(advisorProfile.advisorReviewSummary.averageRating);
    }
  }, [advisorProfile]);

  console.log(Ratings, "userInfo")

  return (

    <div className="profileUser">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="advisorProfileCard">
            <Grid container className="advisorProfileCard__container">
              <div className="advisorProfileCard__info-sec">
                {(userInfo?.avatarPath) ? (
                  <div class="advisorProfileCard__info-sec__sub-img advisor-sub-img">
                    <img src={imageUrl} alt="user" style={{ borderRadius: '10px', width: "100%", height: '100%', "objectFit": "cover" }} />
                  </div>
                ) :
                  <div class="advisorProfileCard__info-sec__sub-img" >
                    <PersonIcon color="action" sx={{ border: '1px solid grey', width: "100%", height: "100% !important", borderRadius: '10px' }} />
                  </div>
                }
                <div className="advisorProfileCard__info-sec__profileCont">
                  <p className="profileName">
                    {props?.sharedData?.userInfo?.fullName}
                  </p>
                  <div className="advisorProfileCard__info-sec__profileCont__about-sec">
                    <span>
                      <RoomOutlinedIcon color="#515050" fontSize="12px" />
                      <p className="label">{userInfo?.country}</p>
                    </span>
                  </div>
                  <div className="advisorProfileCard__info-sec__profileCont__about-sec mt-2">
                    <div style={{ "display": "flex", "flexWrap": "wrap" }} >
                      <p className="label">{"Ratings :"}</p>
                      <Rating name="read-only" value={Ratings} precision={0.5} readOnly size="small" sx={{ color: '#AEAEAE' }} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="advisorProfileCard__connect-btn d-grid mt-3">
                <button className='advisorProfileCard__connect-btn__primary-btn' onClick={handleConnect}>Connect</button>
              </div>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="advisorCoursesCard">
            <p className="advisorCoursesCard__heading">
              About {userInfo?.firstName}
            </p>
          </div>
          <div className="advisorProfileCard">
            <div className="advisorProfileCard__info-sec__profileCont__about-sec">
              {
                advisorProfile?.currentlyIn === "university" && (
                  <>
                    <span>
                      <SchoolOutlinedIcon color="#515050" fontSize="12px" />
                      <p className="label">
                        Enrolled in {advisorProfile?.universityName} graduating in {advisorProfile?.universityGraduationYear}
                      </p>
                    </span>
                    {(advisorProfile?.unWeighted && advisorProfile?.weighted) &&
                      <span>
                        <SchoolOutlinedIcon color="#515050" fontSize="12px" />
                        <p className="label">
                          Has a current unweighted GPA of {advisorProfile?.unWeighted} and weighted GPA of {advisorProfile?.weighted}
                        </p>
                      </span>
                    }
                    <span>
                      <SchoolOutlinedIcon color="#515050" fontSize="12px" />
                      <p className="label">
                        Went to {advisorProfile?.highschoolName}
                      </p>
                    </span>
                  </>
                )
              }
              {
                advisorProfile?.currentlyIn === "highschool" && (
                  <>
                    <span>
                      <SchoolOutlinedIcon color="#515050" fontSize="12px" />
                      <p className="label">
                        {advisorProfile?.highschoolStatus} in {advisorProfile?.highschoolName}
                      </p>
                    </span>
                    {(advisorProfile?.unWeighted && advisorProfile?.weighted) &&
                      <span>
                        <SchoolOutlinedIcon color="#515050" fontSize="12px" />
                        <p className="label">
                          Has a current unweighted GPA of {advisorProfile?.unWeighted} and weighted GPA of {advisorProfile?.weighted}
                        </p>
                      </span>
                    }
                    <span>
                      <SchoolOutlinedIcon color="#515050" fontSize="12px" />
                      <p className="label">
                        Aspiring to attend {advisorProfile?.targetUniversities}
                      </p>
                    </span>
                  </>
                )
              }
              {
                advisorProfile?.currentlyIn === "professional" && (
                  <>
                    <span>
                      <SchoolOutlinedIcon color="#515050" fontSize="12px" />
                      <p className="label">
                        Went to {advisorProfile?.highschoolName} and {advisorProfile?.universityName}
                      </p>
                    </span>
                  </>
                )
              }
              {advisorProfile?.satScore &&
                <span>
                  <WorkspacePremiumOutlinedIcon
                    color="#515050"
                    fontSize="12px"
                  />
                  <p className="label">
                    Scored {advisorProfile?.satScore} in SAT
                  </p>
                </span>
              }
              {advisorProfile?.actScore &&
                <span>
                  <WorkspacePremiumOutlinedIcon
                    color="#515050"
                    fontSize="12px"
                  />
                  <p className="label">
                    Scored {advisorProfile?.actScore} in ACT
                  </p>
                </span>
              }
              {advisorProfile?.currentlyIn === "professional" ? (
                <span>
                  <WorkOutlineOutlinedIcon
                    color="#515050"
                    fontSize="12px"
                  />
                  <p className="label d-flex">
                    Operating as <span style={{ color: '#1C84EE' }}> &nbsp; {advisorProfile?.companyName}</span> &nbsp; since {advisorProfile?.operatingSince}
                  </p>
                </span>
              ) : (
                <>
                  <span>
                    <EmojiEventsOutlinedIcon color="#515050" fontSize="12px" />
                    <p className="label aspirngtext">Aspiring to become {advisorProfile?.aspiringToBecome}</p>
                  </span>
                </>
              )
              }
              <div className="">
                <p className={`courseFullViewCard__bottomSec${viewMore ? '__description' : '__detailedDescription'}`} onClick={() => setViewMore(!viewMore)}>
                  {advisorProfile?.accomplishments ? stripHTMLTags(advisorProfile?.accomplishments) : ""}
                </p>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="advisorCoursesCard">
            <p className="advisorCoursesCard__heading">Services Provided</p>
          </div>
          <div className="advisorProfileCard__Services">
            <div className="servicelist">
              <StarBorderIcon color="#515050" fontSize="12px" />
              <p className="label">
                {userInfo?.firstName} Offers {advisorProfile?.noOfFreeConsultation} free consultation session
              </p>
            </div>
            {advisorProfile?.helpWithPlan === true && (
              <div className="servicelist">
                <StarBorderIcon color="#515050" fontSize="12px" />
                <p className="label">
                  {userInfo?.firstName} can suggests courses and activities based on your{" "} <span style={{ color: '#1C84EE', "marginLeft": "4px" }}> goals</span>
                </p>
              </div>
            )}
            {advisorProfile?.helpWithSpecificCourseActivity === true && (
              <div className="servicelist">
                <StarBorderIcon color="#515050" fontSize="12px" />
                <p className="label-pills">
                  {userInfo?.firstName} can provide mentorship and support for{" "} &nbsp;<PillSection data={mappedData} />
                </p>
              </div>
            )}
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className="advisorCoursesCard">
            {advisorProfile?.advisorCourses?.length > 0 && <p className="advisorCoursesCard__heading">Course Details</p>}
            {advisorProfile?.advisorCourses.map((data, key) => (
              <CourseCard data={data} student />
            ))}
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className="advisorCoursesCard">
            {advisorProfile?.advisorActivities?.length > 0 && <p className="advisorCoursesCard__heading">Activity Details</p>}
            {advisorProfile?.advisorActivities.map((data, key) => (
              <ActivityCard data={data} student />
            ))}
          </div>
        </Grid>

        {props?.sharedData?.advisorFeePackages?.length > 0 && (
          <Grid item xs={12}>
            <div className="advisorPackageCard">
              <p className="advisorPackageCard__heading">Pricing Packages</p>
              <div className="advisorPackageCard__packCard-sec">
                <div className="packageAdvisor">

                  {props.sharedData?.advisorFeePackages?.map((data, key) => {
                    // Filter advisorCourses based on data?.packageCourses
                    const filteredCourses = advisorProfile?.advisorCourses?.filter(course => data?.courses.includes(course.id));
                    const filteredAcyivity = advisorProfile?.advisorActivities?.filter(activity => data?.activities.includes(activity.id));
                    // const subscriptionDetail = props.detailedUserData.subscriptiondetails.find(
                    //   (subscription) => subscription.PackageId === data.id
                    // );
                    const subscriptionCount = props?.sharedData?.packageSubscriptionCount?.[data.id] || 0;
                    const monthstatus = data?.paymentType && data.paymentType === "monthly" ? "/Month" : "";
                    const advisorActivities = filteredAcyivity || [];
                    const advisorCourses = filteredCourses || [];

                    const combinedData = [...advisorActivities, ...advisorCourses];

                    const mappedPackageData = combinedData.map(item => {
                      if (item.activityName) {
                        return item.activityName;
                      } else if (item.courseName) {
                        return item.courseName;
                      }
                      return null;
                    });

                    return (
                      <>
                        <div className="Advisor_PackageCard" key={data?.id}>
                          <div className="Advisor_PackageCard_TopSection">
                            <div className="Advisor_PackageCard_TopSection_Title">
                              <p className="Advisor_PackageCard_TopSection_Title_Name">{data?.packageName}</p>
                            </div>
                            <div className="Advisor_PackageCard_TopSection_subText">
                              <p className="Advisor_PackageCard_TopSection_subText_Name">{subscriptionCount} {data.paymentType === "monthly" ? "Subscribed" : "Bought"}</p>
                            </div>
                          </div>

                          <div className="Advisor_PackageCard_MidSection">
                            <div className="Advisor_PackageCard_MidSection_Price">
                              <p className="Advisor_PackageCard_MidSection_Price_Text">Price<span>{data?.price}${monthstatus}</span></p>
                            </div>
                            <div className="Advisor_PackageCard_MidSection_PackageList">
                              <div className="Advisor_PackageCard_MidSection_PackageList_Section">
                                <p className="Advisor_PackageCard_MidSection_PackageList_Section_Text">
                                  <span><CheckCircleOutlinedIcon sx={{ color: '#00AA11', fontSize: '16px' }} /></span>
                                  Includes {data?.noOfPaidSessions} Sessions of {data?.sessionLength} mins duration
                                </p>
                              </div>
                              <div className="Advisor_PackageCard_MidSection_PackageList_Section">
                                <p className="Advisor_PackageCard_MidSection_PackageList_Section_Text">
                                  <span><CheckCircleOutlinedIcon sx={{ color: '#00AA11', fontSize: '16px' }} /></span>
                                  Includes {data?.messageLimit === 0 ? "Unlimited" : data?.messageLimit} Chat Messages
                                </p>
                              </div>
                              {data?.helpWithPlan === true &&
                                <div className="Advisor_PackageCard_MidSection_PackageList_Section">
                                  <p className="Advisor_PackageCard_MidSection_PackageList_Section_Text">
                                    <span><CheckCircleOutlinedIcon sx={{ color: '#00AA11', fontSize: '16px' }} /></span>
                                    Suggest courses and activities based on your goals
                                  </p>
                                </div>
                              }
                              {data?.helpWithSpecificCourseActivity === true && (
                                <>
                                  <div className="Advisor_PackageCard_MidSection_PackageList_Section">
                                    <p className="Advisor_PackageCard_MidSection_PackageList_Section_Text">
                                      <span><CheckCircleOutlinedIcon sx={{ color: '#00AA11', fontSize: '16px' }} /></span>
                                      Provide mentorship and support for
                                    </p>
                                  </div>
                                  <div className="Advisor_PackageCard_MidSection_PackageList_Section">
                                    <div className="Advisor_PackageCard_MidSection_PackageList_Section_CourseContainer">
                                      <PillSection data={mappedPackageData} maxWidth={240} maxHeight={80} />
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="Advisor_PackageCard_FooterSection" style={{ "marginTop": "30px" }}>
                            <div className="Advisor_PackageCard_FooterSection_Description">
                              <p className="Advisor_PackageCard_FooterSection_Description_Text" aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={(e) => handlePopoverOpen(e, data?.description !== "" ? data?.description : "")} onMouseLeave={handlePopoverClose}>{data?.description ? stripHTMLTags(data?.description) : ""}</p>
                            </div>
                            <div className="Advisor_PackageCard_FooterSection_Container">
                              <Button className="Advisor_PackageCard_FooterSection_Container_PurchaseBtn" disabled={true} style={{ opacity: 0.5 }}>
                                Purchase
                              </Button>
                            </div>
                          </div>
                        </div>
                        {descriptions !== null &&
                          <Popover id="mouse-over-popover" sx={{ pointerEvents: 'none', width: "60%" }} open={open} anchorEl={anchorEl} anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} onClose={handlePopoverClose} disableRestoreFocus>
                            <Typography sx={{ p: 1 }} >{descriptions ? stripHTMLTags(descriptions) : ""}</Typography>
                          </Popover>
                        }
                      </>
                    );
                  })}
                </div>


              </div>
            </div>
          </Grid>
        )}
        {advisorProfile?.advisorWorkingHours &&
          <Grid item xs={12}>
            <>

              {
                (advisorProfile?.advisorWorkingHours?.mondayTimesheet?.length || advisorProfile?.advisorWorkingHours?.tuesdayTimesheet.length || advisorProfile?.advisorWorkingHours?.wednesdayTimesheet.length ||
                  advisorProfile?.advisorWorkingHours?.thursdayTimesheet.length || advisorProfile?.advisorWorkingHours?.fridayTimesheet?.length || advisorProfile?.advisorWorkingHours?.saturdayTimesheet?.length ||
                  advisorProfile?.advisorWorkingHours?.sundayTimesheet?.length
                ) ? <>
                  <div className="workingHoursCard__title">
                    Working Hours {"(" + userInfo?.timezone + ")"}
                    <Tooltip arrow placement="top" title="Parents and students can schedule virtual sessions during these hours in your calendar">
                      <span style={{ "marginLeft": "10px" }}>
                        <svg width="16" height="16" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8.33333 0C3.73333 0 0 3.73333 0 8.33333C0 12.9333 3.73333 16.6667 8.33333 16.6667C12.9333 16.6667 16.6667 12.9333 16.6667 8.33333C16.6667 3.73333 12.9333 0 8.33333 0ZM8.33333 12.5C7.875 12.5 7.5 12.125 7.5 11.6667V8.33333C7.5 7.875 7.875 7.5 8.33333 7.5C8.79167 7.5 9.16667 7.875 9.16667 8.33333V11.6667C9.16667 12.125 8.79167 12.5 8.33333 12.5ZM9.16667 5.83333H7.5V4.16667H9.16667V5.83333Z" fill="black" fill-opacity="0.54" />
                        </svg>
                      </span>
                    </Tooltip>
                  </div>
                  <WorkingHours data={advisorProfile?.advisorWorkingHours} timezone={userInfo?.timezone} />
                </>

                  : <></>
              }
            </>
          </Grid>
        }
      </Grid>
    </div>

  );
};

const mapStateToProps = ({ auth, commonData, userProfileData }) => {
  const { message, errorList, isNewPassword, isverifyPasswordstatus, loading, } = commonData;
  const { isLoginError, isEmailVerified, isEmailConfirm, sharedData } = auth;
  const { userProfile } = userProfileData;
  return { message, errorList, userProfile, isLoginError, isEmailVerified, isNewPassword, isverifyPasswordstatus, isEmailConfirm, loading, sharedData };
};

export default connect(mapStateToProps, { clearErrorList, sharedUserProfile, getUserProfile, verifyEmail, forgetPassword, verifyPassword, googleLogin, facebookLogin, clearMessage, })(withRouter(Index));
