import React, { useState } from "react";
import { connect } from "react-redux";
import { Grid, Box } from "@mui/material";
import "../../../../assets/css/New_Profile.less"
import UserImage from "../../../../componentsNew/Common/Profile_UserImage_Upload"
// import ConnectParent from "./ConnectParent"
import GeneralForms from "./GeneralForm"
import Interest from "./Interest"
import ChangePassword from "./ChangePassword"
import SentInvite from "./SentInvite"
import DeleteProfile from "./DeleteProfile"
import Loader from "../../../../components/Loader";
import SocialBlockSec from "./SocialBlockSec";
import { getUserProfile } from "../../../../redux/actions";

const Index = (props) => {
    const [loading, setloading] = useState(false)

    React.useEffect(() => {
        setloading(true)
        props?.getUserProfile()
    }, [])

    React.useEffect(() => {
        if (props) {
            setloading(false)
        }
    }, [props])
    return (
        <>
            {loading && <Loader />}
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <div className="UserImageSection">
                            <UserImage />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={5}>
                        <div className="FormInterestSection">
                            <Interest />
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                        <div className="GeneralFormSection">
                            <GeneralForms />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={5} >
                        <div className="ChangePasswordSection">
                            {props.userProfile && props.userProfile?.signedupVia === "default" || "viainvite" ?
                                <ChangePassword />
                                :
                                <SocialBlockSec />
                            }
                        </div>
                        <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} >
                        <div className="SentInviteSection">
                            <SentInvite />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} >
                        <div className="DeleteSection">
                            <DeleteProfile />
                        </div>
                    </Grid>
                </Grid>
                    </Grid>
                </Grid>
                
            </Box>
        </>

    );
};

const mapStateToProps = ({ userProfileData }) => {
    const { userProfile } = userProfileData;
    return { userProfile };
};
export default connect(mapStateToProps, {getUserProfile})(Index);
