import jwtDecode from 'jwt-decode';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import { Navigate, useLocation } from "react-router-dom";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const authHeader = () => {
    const userAccessToken = getUserData('accessToken');
    return {
        'Authorization': `Bearer ${userAccessToken}`,
        'Content-Type': 'application/json'
    }
}

/**
 * Checks if user is authenticated
 */

const isUserAuthenticated = () => {
    let user = getLoggedInUser();
    if (!user || !user.accessToken) {
        return false;
    }
    else {
        const decoded = jwtDecode(user.accessToken);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            console.warn('access token expired');
            // refreshToken(user.refreshkey);
            // return false;
        } else {
            return true;
        }
    }
};

const isUserRoleValid = userType => ['DU','DA','DS'].includes(userType)

/**
 * Refresh page
 */
const refreshPage = () => {
    window.location.reload();
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    const user = localStorage.getItem('leapBrainsWebApp');
    let jsonData = user ? decryptData(user) : null;
    return jsonData ? (typeof jsonData == 'object' ? jsonData : JSON.parse(jsonData)) : null;
};

const getUserData = (key) => {
    let user = getLoggedInUser();
    return user ? user[key] : null;
}

/**
 * Clear Session and logout the user
 */
const clearSession = () => {
    localStorage.clear();
    refreshPage();
};

const refreshToken = (refreshkey) => {
    const refreshKey = getUserData('refreshKey')
    axios.post(BASE_URL+'/user-service/v1/auth/refresh', { refreshKey }).then(({ data }) => {
        const { headers: { statusCode, message }, accessToken, refreshKey } = data
        if (statusCode !== "200") {
            clearSession()
        } else {
            setSession(encryptData(JSON.stringify({ accessToken, refreshKey })));
            axios.defaults.headers.common['Authorization'] = "Bearer " + accessToken;
        }
    }).catch(function (error) {
        clearSession()
        console.log("Error****:", error.message);
    });
}

/**
 * Data Encryption
 */
const encryptData = (data) => {
    let salt = process.env.REACT_APP_SALT;
    if (data && salt) return CryptoJS.AES.encrypt(data, salt).toString();
    else return null;
};

/**
 * Data Decryption
 */
const decryptData = (data) => {
    let salt = process.env.REACT_APP_SALT;
    if (data && salt) {
        let bytes = CryptoJS.AES.decrypt(data, salt);
        return bytes.toString(CryptoJS.enc.Utf8);
    } else {
        return null;
    }
}

const setSession = user => {
    if (user) {
        localStorage.setItem('leapBrainsWebApp', user);
    }
    else
        localStorage.clear();
};

const RequireAuth = (  {children} ) => {
    let location = useLocation();
    let user = isUserAuthenticated();
    if (!user) {
        // localStorage.setItem("url", location.pathname )
        return <Navigate to="/" state={{ from: location }} replace />;
    }
    else {
      axios.defaults.headers.common['Authorization'] = "Bearer " + getUserData('accessToken');
    }
    return children;
}

export { isUserAuthenticated, getLoggedInUser, refreshToken, encryptData, getUserData, authHeader, setSession, isUserRoleValid, clearSession, RequireAuth };
