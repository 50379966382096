import React, { useState, useEffect } from "react";
import { Button, Modal, Typography, Box, Stack, Grid, TextField, Autocomplete, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import Select, { components } from "react-select";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useComponentDidMount, useComponentDidUpdate } from "../../../../utils/useEffectHooks";
import { schoolApi } from "../../../../utils/AutoComplete";
import { connect } from "react-redux";
import { SentInviteSpouse, GetStudentPlan } from "../../../../redux/actions";
import "../../../../assets/css/plans.less";
import { Plan_Name, ASSIGN_EXISTING_ADVISOR } from "../../../../Constants/commonLabels";

const validation = Yup.object({

});

const Index = (props) => {
    const [universityCheckBox, setUniversityCheckBox] = useState(false);
    const [professionsCheckBox, setProfessionsCheckBox] = useState(false);
    const [gPACheckBox, setgPACheckBox] = useState(false);

    const { register, handleSubmit, watch, reset, setValue, clearErrors, control, formState: { errors } } = useForm({ resolver: yupResolver(validation), });

    useComponentDidMount(() => {
        let value = { "searchString": "", "pageNo": 1, "limit": 10, "status": [], "sortOrder" : "latest", "studentId": null }
        props.GetStudentPlan(value)
    }, []);

    useComponentDidUpdate(() => {
        setValue("planName", props?.data?.firstName);
    }, [])


    const onSubmit = (data) => {
        console.log(data, "datas")
    };

console.log( props?.getstudentplan, "getstudentplassssssn")
    return (
        <>
            <div className="role">
                <div className="role_card" style={{ width: "430px !important", marginBottom: "100px" }}>
                    <h1 className="role_title text-center planTitleParent">Create new plan for {props?.childprofile.length > 0 ? props?.childprofile[0]?.firstName + " " + props?.childprofile[0]?.lastName : ""}</h1>
                    <form onSubmit={handleSubmit(onSubmit)} className=''>
                        <div className="row  goals__form" >
                            <div className="col-md-12 col-sm-12 col-12 p-0">
                                <div className="input-floating-label">
                                    <input type="text"  {...register("planName")} className={"textbox--primary textbox--rounded input"} name="planName" placeholder=" " />
                                    <label>{Plan_Name}</label>
                                    <div className="error-text">{errors.planName?.message}</div>
                                </div>
                            </div>
                        </div>
                        <div className="goals__form">
                            <p className="profile__form__sub-title_new" >Select one or more Goals for this Plan</p>
                        </div>
                        <div className="goals_page">
                            <div className="mb-2 role__btns">
                                <input type="checkbox" defaultChecked={universityCheckBox} className="btn-check" name="options1" id="btncheck1" autoComplete="off" />
                                <label className="btn cta--role--btn w-mob-100" htmlFor="btncheck1">Get into specific universities</label>
                            </div>
                            <div className=" mb-2 role__btns">
                                <input type="checkbox" defaultChecked={professionsCheckBox} className="btn-check" name="options2" id="btncheck2" autoComplete="off" />
                                <label className="btn cta--role--btn w-mob-100" htmlFor="btncheck2">Get into specific professions</label>
                            </div>
                            <div className=" mb-2 role__btns">
                                <input type="checkbox" defaultChecked={gPACheckBox} className="btn-check" name="options3" id="btncheck3" autoComplete="off" />
                                <label className="btn cta--role--btn w-mob-100" htmlFor="btncheck3">Improve GPA</label>
                            </div>
                        </div>





                    </form>
                </div>
            </div>
        </>
    );
}
const mapStateToProps = ({ userProfileData, commonData, studentData }) => {
    const { userProfile, isProfileSuccess, childprofile, parentProfile } = userProfileData;
    const { status, gender, country, graduationyear, schools } = commonData;
    const { iscreateplan, getstudentplan,  } = studentData;

    return { userProfile, isProfileSuccess, status, gender, schools, country, childprofile, parentProfile, graduationyear,getstudentplan  };
};

export default connect(mapStateToProps, { SentInviteSpouse, GetStudentPlan })(Index);
