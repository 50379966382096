import React from "react";
import { Grid } from "@mui/material";
import AdvCourses from "./AdvCourses";
import AdvActivity from "./AdvActivity";
import "./courseActivity.less";

const Index = () => {
    return (
        <Grid container spacing={5}>
            <Grid item xs= {12} sm= {12} md= {12} lg= {12} xl= {12}>
                <div className="advProfile--CourseSec">
                    <AdvCourses />
                </div>
            </Grid>
            <Grid item xs= {12} sm= {12} md= {12} lg= {12} xl= {12}>
                <div className="advProfile--ActivitySec">
                    <AdvActivity />
                </div>
            </Grid>
        </Grid>
    );
};

export default Index;
