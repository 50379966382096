import React, { useEffect, useState } from 'react'
import "../../../assets/css/NewCarouselPage.less"
import { Link } from 'react-router-dom'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { connect } from "react-redux";
import { withRouter } from "../../../redux/store/navigate";
import { getStudentDetail, getUserProfile, getStudentPlanDetails,getStudentPlanStatus, setUserOnboard, GetStudentPlan,GetStudentPlanPhase , getAdvisorRecommended, clearMessage, getAdvisorSuggested, resetSuggestedAdvisorData, getAdvisorSuggestedStatus } from "../../../redux/actions";
import Loader from "../../../components/Loader"
import SelectAdvisors from "../../../components/OwlCarousel"
import CustomAlert from '../../../components/CustomAlert';

const Index = (props) => {
    const [loading, setloading] = useState(false)

    useEffect(() => {
        setloading(true);
        props.getUserProfile()
        let payload = {
            "limit": 60,
            "pageNo": 1,
            "studentId": "null"
        }
        props?.getAdvisorSuggested(payload)
        let value = {
            "searchString": "",
            "pageNo": 1,
            "limit": 10,
            "status": [],
            "sortOrder": "latest",
            "studentId": null
        }
        props?.GetStudentPlan(value)
        props?.clearMessage()

        return(() => {
            props?.resetSuggestedAdvisorData();
        })
    }, [])

    useEffect(() => {
        if (props?.userProfile?.id !=="") {
            let User = props?.userProfile
            let users = {
                "userId": User?.id
            }
            props?.getStudentPlanDetails(users)
            props?.getStudentDetail(users)
        }
        if (props?.getstudentplan) {
            let plans = props?.getstudentplan[0]?.planInfo
            let plansId = plans?.id
            if (plansId != "") {
                let values = { "planId": plansId }
                props?.GetStudentPlanPhase(values)
            }
        }
    }, [props?.userProfile])

    useEffect(()=>{
        if(props?.loadStudentPlanDataStatus && props?.studentPlanData){
            props?.getStudentPlanStatus(false)
        }
    },[props?.studentPlanData, props?.loadStudentPlanDataStatus])

    useEffect(() => {
        if (props.suggestedAdvisor && props.getSuggestedStatus) {
            setloading(false)
            props?.getAdvisorSuggestedStatus(false)
        }
    }, [props?.suggestedAdvisor, props?.getSuggestedStatus])
    return (
        <>
            {loading && <Loader />}
            {<div style={{ "width": "100%", "display": "flex", "justifyContent": "center", }}> {<CustomAlert />}</div>}
            <div className="SelectAdvisors">
                <div className="SelectAdvisors__form">
                    <div className="container">
                        <h1 className="SelectAdvisors__form__title text-center mb-5">Suggested advisors <span className="bluetext">based on your goals</span></h1>
                        <div className="SelectAdvisors__list">
                            {props?.suggestedAdvisor && props?.suggestedAdvisor?.length > 0 ? (
                                <SelectAdvisors
                                    OwlCarouselData={props?.suggestedAdvisor}
                                    Carouselitems={4}
                                    islooping={true}
                                    role="advisor"
                                />
                            ) :
                                <div className='Recommend-Advisor'>
                                    <div className='Recommend-Advisor-card'>
                                        <div>
                                            <div className='Recommend-title'><p>You have No Recommended Advisor</p></div>
                                            <div className='Recommend-subtitle'><p >Please find more Plans for Advisor</p></div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer" style={{ zIndex: "999" }}>
                <div className="row m-0">
                    <div className="footer__left col-md-4 col-sm-4 col-4 text-center p-0">
                        <p>
                            <Link to={props?.getstudentplan?.length > 0 ? "/onboarding/intialplans" : "/onboarding/plans"} className="footer__left__cta">
                                <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M5.80474 0.195262C5.54439 -0.0650874 5.12228 -0.0650874 4.86193 0.195262L0.195262 4.86193C-0.0650873 5.12228 -0.0650873 5.54439 0.195262 5.80474L4.86193 10.4714C5.12228 10.7318 5.54439 10.7318 5.80474 10.4714C6.06509 10.2111 6.06509 9.78894 5.80474 9.52859L1.60948 5.33333L5.80474 1.13807C6.06509 0.877722 6.06509 0.455612 5.80474 0.195262Z" fill="#1B1C1E" />
                                </svg>
                                <span>Go back</span>
                            </Link>
                        </p>
                    </div>
                    <div className="footer__center col-md-4 col-sm-4 col-4 text-center">
                        {/* <p>5 out of 7 steps</p> */}
                    </div>
                    <div className="footer__right col-md-4 col-sm-4 col-4 text-center plansFooter justify-content-center">
                        {/* <Link className="skipButton pe-3" to="/onboarding/selectfriend">Skip</Link> */}
                        <Link className="footer__cta_plan" to="/onboarding/selectfriend">Explore Students
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.92893 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.0222 21.4135 8.08879 20.3147 6.4443C19.2159 4.79981 17.6541 3.51808 15.8268 2.76121C13.9996 2.00433 11.9889 1.8063 10.0491 2.19215C8.10929 2.578 6.32746 3.53041 4.92893 4.92893" strokeWidth="2" className="rolestrokes" />
                                <path d="M15 12L15.7809 11.3753L16.2806 12L15.7809 12.6247L15 12ZM3 13C2.44771 13 2 12.5523 2 12C2 11.4477 2.44771 11 3 11V13ZM11.7809 6.3753L15.7809 11.3753L14.2191 12.6247L10.2191 7.6247L11.7809 6.3753ZM15.7809 12.6247L11.7809 17.6247L10.2191 16.3753L14.2191 11.3753L15.7809 12.6247ZM15 13H3V11H15V13Z" className="rolefills" />
                            </svg>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = ({ userProfileData, studentData, commonData }) => {
    const { isUserOnboarded, childprofile, userProfile } = userProfileData
    const { getstudentplan, studentplanstatus } = studentData;
    const { recommendedAdvisor, recommendedAdvisorstatus, suggestedAdvisor, getSuggestedStatus,studentPlanData,loadStudentPlanDataStatus } = commonData

    return { studentPlanData,loadStudentPlanDataStatus,isUserOnboarded, childprofile, getstudentplan, studentplanstatus, userProfile, recommendedAdvisor, recommendedAdvisorstatus, suggestedAdvisor, getSuggestedStatus }
};

export default connect(mapStateToProps, { getStudentDetail,getStudentPlanStatus,getUserProfile, getStudentPlanDetails,GetStudentPlanPhase , getAdvisorSuggested, getAdvisorSuggestedStatus, resetSuggestedAdvisorData, setUserOnboard, GetStudentPlan, getAdvisorRecommended, clearMessage })(withRouter(Index));
