import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CopyInviteLink, showMessage, setCopyInviteFlag } from "../../../../../redux/actions";
import { Tooltip } from "@mui/material";
import Alert from 'react-bootstrap/Alert';

const Index = (props) => {
    const [link, setLink] = useState('')
    const [alert, setAlert] = useState(false)

    useEffect(() => {
        let values = {
            "role": null
        }
        if (props.copyInviteFlag) {
            props.CopyInviteLink(values)  
        }
    }, [])

    useEffect(() => {
        setLink(props.copyInvitelink ? props.copyInvitelink : '')
        props.setCopyInviteFlag(false)
    }, [props.copyInvitelink])

    useEffect(() => {
        if (props.copyInvitelink !== link) {
            let values = {
                "role": null
            }
            if (props.copyInviteFlag || alert){
                props.CopyInviteLink(values)
            }
        }
    }, [link])
    
    function myFunction() {
        let textfield = document.createElement("input");
        textfield.setAttribute('id', 'myInput')
        textfield.setAttribute('value', link)
        document.body.appendChild(textfield)
        let copyText = document.getElementById("myInput");
        copyText.focus();
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        if (document.execCommand('copy')){
            setAlert((alert) => !alert)
            setTimeout(() => {
                setAlert((alert) => !alert)
            }, 4000);
        };
        textfield.remove() 
    }

    const copyToClipboard =  async (e) => {
        myFunction()
        setLink('')
    };

    return (
        <>
            {alert && 
                <div style={{ position: 'fixed', top: '10px', zIndex: '9999',left: '50%', transform: 'translateX(-50%)' }} >
                    <Alert style={{ position: 'sticky', marginTop: '10px', }} 
                    variant="success" onClose={() => {setAlert((alert) => !alert)}} dismissible>Copied to clipboard</Alert>
                </div>
            }
            <div className="sentInviteSec">
                <p className="profile__form__sub-title">Send Invite Link</p>
                <p className="sentInviteSec__sentInviteCnt">
                    <span>
                        <svg
                            width="22"
                            height="22"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M13.5443 10.4584C12.7252 9.63964 11.6144 9.17969 10.4563 9.17969C9.29815 9.17969 8.18741 9.63964 7.3683 10.4584L4.2793 13.5464C3.46018 14.3655 3 15.4765 3 16.6349C3 17.7933 3.46018 18.9043 4.2793 19.7234C5.09842 20.5425 6.20939 21.0027 7.3678 21.0027C8.52621 21.0027 9.63718 20.5425 10.4563 19.7234L12.0003 18.1794"
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />{" "}
                            <path
                                d="M10.457 13.5443C11.2761 14.3631 12.3869 14.823 13.545 14.823C14.7032 14.823 15.8139 14.3631 16.633 13.5443L19.722 10.4563C20.5412 9.63718 21.0013 8.52621 21.0013 7.3678C21.0013 6.20939 20.5412 5.09842 19.722 4.2793C18.9029 3.46018 17.7919 3 16.6335 3C15.4751 3 14.3642 3.46018 13.545 4.2793L12.001 5.8233"
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>{" "}
                    </span>
                    <span
                        className="cpyLink" style={{ cursor: "pointer" }}
                        onClick={(e) => {copyToClipboard(e) ;setAlert(true)}}
                    >
                        Copy invite link for friend
                    </span>
                    <sup className="sentInviteSec__sentInviteCnt__infoSvg">
                        <Tooltip title="Copy Invite link" enterTouchDelay={0}>
                            <svg
                                width="16" height="16"
                                viewBox="0 0 16 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                {" "}
                                <path d="M7.99935 7.16797C8.27549 7.16797 8.49935 7.39183 8.49935 7.66797V11.0013C8.49935 11.2774 8.27549 11.5013 7.99935 11.5013C7.72321 11.5013 7.49935 11.2774 7.49935 11.0013V7.66797C7.49935 7.39183 7.72321 7.16797 7.99935 7.16797Z"
                                    fill="#919293"
                                />{" "}
                                <path d="M7.99935 6.0013C8.36754 6.0013 8.66602 5.70283 8.66602 5.33464C8.66602 4.96645 8.36754 4.66797 7.99935 4.66797C7.63116 4.66797 7.33268 4.96645 7.33268 5.33464C7.33268 5.70283 7.63116 6.0013 7.99935 6.0013Z"
                                    fill="#919293"
                                />{" "}
                                <path fillRule="evenodd" clipRule="evenodd"
                                    d="M2.16602 8.0013C2.16602 4.77964 4.77769 2.16797 7.99935 2.16797C11.221 2.16797 13.8327 4.77964 13.8327 8.0013C13.8327 11.223 11.221 13.8346 7.99935 13.8346C4.77769 13.8346 2.16602 11.223 2.16602 8.0013ZM7.99935 3.16797C5.32997 3.16797 3.16602 5.33193 3.16602 8.0013C3.16602 10.6707 5.32997 12.8346 7.99935 12.8346C10.6687 12.8346 12.8327 10.6707 12.8327 8.0013C12.8327 5.33193 10.6687 3.16797 7.99935 3.16797Z"
                                    fill="#919293"
                                />{" "}
                            </svg>
                        </Tooltip>
                    </sup>
                </p>
            </div>
        </>

    );
};

const mapStateToProps = ({ userProfileData, commonData }) => {
    const { copyInvitelink } = userProfileData;
    const { copyInviteFlag } = commonData;
    return { copyInvitelink, copyInviteFlag };
};

export default connect(mapStateToProps, { CopyInviteLink, showMessage, setCopyInviteFlag })(Index);
