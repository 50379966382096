
import React, { Component } from "react";
import Select, { components } from "react-select";
import { Link } from "react-router-dom";
import "../../../../assets/css/plans.less";
import { connect } from "react-redux";
import { withRouter } from "../../../../redux/store/navigate";
import { setUserOnboard } from "../../../../redux/actions";
import { setIsChildProfileSuccess } from "../../../../redux/actions";
import { COURES_NAME_LABEL, COURES_TYPE_LABEL, ACTIVITY_NAME_LABEL } from "../../../../Constants/commonLabels"
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isShowPan: false,
            isShowActivity: false,
            courses: [],
            activities: [],
            listOfPhases: [
                {
                    startsIn: "",
                    endsIn: "",
                    course: "",
                    courseType: "",
                    activityName: "",
                    showCourse: false,
                    showActivity: false,
                },
            ],
        };
    }

    componentDidMount() {
        if (this.props.isUserOnboarded) {
            this.props.navigate("/student/dashboard");
        }
    }
    componentDidUpdate() {
        if (this.props.isUserOnboarded) {
            this.props.navigate("/student/dashboard");
        }
    }
    handleClickSkip = () => {
        this.props.setUserOnboard();
    };
    handleShowPlan(e) {
        e.preventDefault();
        if (this.state.isShowPan == false) {
            this.setState({ isShowPan: true });
        } else {
            this.setState({ isShowPan: false });
        }
    }
    handleShowPlanActivity(e) {
        e.preventDefault();
        if (this.state.isShowActivity == false) {
            this.setState({ isShowActivity: true });
        } else {
            this.setState({ isShowActivity: false });
        }
    }
    handleClickAddPhase(e) {
        e.preventDefault();
        var count = document.getElementsByClassName(
            "initial_plan_parent"
        ).length;
        let menu = document.querySelector("#initial_plan_parent");

        let clonedMenu = menu.cloneNode(true);
        clonedMenu.id = "menu-mobile";
        var child = clonedMenu.firstChild;
        child.innerHTML = "Phase" + parseInt(parseInt(count) + 1) + ":";
        document.getElementById("initial_plan_clone").appendChild(clonedMenu);
    }

    addPhaseHandler(e) {
        e.preventDefault();
        let { listOfPhases } = this.state;
        listOfPhases.push({
            startsIn: "",
            endsIn: "",
            course: "",
            courseType: "",
            activityName: "",
            showCourse: false,
        });
        console.log("listOfPhases", listOfPhases);
        this.setState({ listOfPhases });
    }
    addSubFieldHandler(e, key, value) {
        e.preventDefault();
        let { listOfPhases } = this.state;
        if (value === "course") {
            listOfPhases[key].courseType = "";
            listOfPhases[key].showCourse = !listOfPhases[key].showCourse;
        } else if (value === "activity") {
            listOfPhases[key].activityName = "";
            listOfPhases[key].showActivity = !listOfPhases[key].showActivity;
        }
        this.setState({ listOfPhases });
    }

    handleTimePeriod(e, key, value) {
        let { listOfPhases } = this.state;
        if (value === "startsIn") {
            listOfPhases[key].startsIn = e;
        } else if (value === "endsIn") {
            listOfPhases[key].endsIn = e;
        }
        this.setState({ listOfPhases });
    }

    AddCourseFieldHandler(e, key, value) {
        let { listOfPhases } = this.state;
        if (value === "courseName") {
            listOfPhases[key].course = e;
        } else if (value === "courseType") {
            listOfPhases[key].courseType = e;
        } else if (value === "activityName") {
            listOfPhases[key].activityName = e;
        }
        this.setState({ listOfPhases });
    }
    render() {
        let { isShowPan, isShowActivity, listOfPhases } = this.state;

        const options = [
            { value: "2021 (1-st semester )", label: "2021 (1-st semester )" },
            { value: "2021 (2-nd semester )", label: "2021 (2-nd semester )" },
            { value: "2022 (1-st semester )", label: "2022 (1-st semester )" },
            { value: "2022 (2-nd semester )", label: "2022 (2-nd semester )" },
            { value: "2023 (1-st semester )", label: "2023 (1-st semester )" },
            { value: "2023 (2-nd semester )", label: "2023 (2-nd semester )" },
        ];

        const options1 = [
            { value: "2021 (1-st semester )", label: "2021 (1-st semester )" },
            { value: "2021 (2-nd semester )", label: "2021 (2-nd semester )" },
            { value: "2022 (1-st semester )", label: "2022 (1-st semester )" },
            { value: "2022 (2-nd semester )", label: "2022 (2-nd semester )" },
            { value: "2023 (1-st semester )", label: "2023 (1-st semester )" },
            { value: "2023 (2-nd semester )", label: "2023 (2-nd semester )" },
        ];

        const options2 = [
            { value: "Academic Regular", label: "Academic Regular" },
            { value: "Academic Accelerated", label: "Academic Accelerated" },
            { value: "Academic Honors", label: "Academic Honors" },
            { value: "Academic AP/College level", label: "Academic AP/College level", },
            { value: "Desired Grade", label: "Desired Grade" },
        ];

        const style1 = {
            control: (base, state) => ({ ...base, border: "0 !important", boxShadow: "0 !important", "&:hover": { border: "0 !important", }, }),
            menu: (provided, state) => ({ ...provided, width: "200px" }),
        };
        const { ValueContainer, Placeholder } = components;
        const CustomValueContainer = ({ children, ...props }) => {
            return (
                <ValueContainer {...props}><Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder} </Placeholder>
                    {React.Children.map(children, (child) => child && child.type !== Placeholder ? child : null)}
                </ValueContainer>
            );
        };
        return (
            <>
                <div className="set_initial_plan">
                    <div className="role_card">
                        <h1 className="role_title text-center"> Set up your initial plan </h1>
                        <div className="initial_plan">
                            <div className="row">
                                <div className="cols-m-12">
                                    <div id="initial_plan_clone">
                                        {listOfPhases.length > 0 && (
                                            <>
                                                {listOfPhases?.map(
                                                    (list, key) => {
                                                        return (
                                                            <div className="initial_plan_parent mb-3" id="initial_plan_parent" key={key}>
                                                                <h5 className="Phase_count"> Phase:{" "}{key + 1}</h5>
                                                                <div className="row m-0  mb-4">
                                                                    <div className="col-sm-12 col-md-6 col-12">
                                                                        <div className="row">
                                                                            <div className="col-4 p-0">
                                                                                <p className="initial_plan_label">Starts from:</p>
                                                                            </div>
                                                                            <div className="col-8 p-0">
                                                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.4688 3.23438H12.5156V2.10938C12.5156 2.03203 12.4523 1.96875 12.375 1.96875H11.3906C11.3133 1.96875 11.25 2.03203 11.25 2.10938V3.23438H6.75V2.10938C6.75 2.03203 6.68672 1.96875 6.60938 1.96875H5.625C5.54766 1.96875 5.48438 2.03203 5.48438 2.10938V3.23438H2.53125C2.22012 3.23438 1.96875 3.48574 1.96875 3.79688V15.4688C1.96875 15.7799 2.22012 16.0312 2.53125 16.0312H15.4688C15.7799 16.0312 16.0312 15.7799 16.0312 15.4688V3.79688C16.0312 3.48574 15.7799 3.23438 15.4688 3.23438ZM14.7656 14.7656H3.23438V8.08594H14.7656V14.7656ZM3.23438 6.89062V4.5H5.48438V5.34375C5.48438 5.42109 5.54766 5.48438 5.625 5.48438H6.60938C6.68672 5.48438 6.75 5.42109 6.75 5.34375V4.5H11.25V5.34375C11.25 5.42109 11.3133 5.48438 11.3906 5.48438H12.375C12.4523 5.48438 12.5156 5.42109 12.5156 5.34375V4.5H14.7656V6.89062H3.23438Z" fill="#BFBFBF" /></svg>
                                                                                <div className="react-select-noborder">
                                                                                    <Select placeholder="Choose year" styles={style1} value={list.startsIn} onChange={(e) => this.handleTimePeriod(e, key, "startsIn")} options={options} components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, }} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 col-12">
                                                                        <div className="row">
                                                                            <div className="col-4 p-0">
                                                                                <p className="initial_plan_label">Ends after:</p>
                                                                            </div>
                                                                            <div className="col-8 p-0">
                                                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M15.4688 3.23438H12.5156V2.10938C12.5156 2.03203 12.4523 1.96875 12.375 1.96875H11.3906C11.3133 1.96875 11.25 2.03203 11.25 2.10938V3.23438H6.75V2.10938C6.75 2.03203 6.68672 1.96875 6.60938 1.96875H5.625C5.54766 1.96875 5.48438 2.03203 5.48438 2.10938V3.23438H2.53125C2.22012 3.23438 1.96875 3.48574 1.96875 3.79688V15.4688C1.96875 15.7799 2.22012 16.0312 2.53125 16.0312H15.4688C15.7799 16.0312 16.0312 15.7799 16.0312 15.4688V3.79688C16.0312 3.48574 15.7799 3.23438 15.4688 3.23438ZM14.7656 14.7656H3.23438V8.08594H14.7656V14.7656ZM3.23438 6.89062V4.5H5.48438V5.34375C5.48438 5.42109 5.54766 5.48438 5.625 5.48438H6.60938C6.68672 5.48438 6.75 5.42109 6.75 5.34375V4.5H11.25V5.34375C11.25 5.42109 11.3133 5.48438 11.3906 5.48438H12.375C12.4523 5.48438 12.5156 5.42109 12.5156 5.34375V4.5H14.7656V6.89062H3.23438Z" fill="#BFBFBF" /></svg>
                                                                                <div className="react-select-noborder">
                                                                                    <Select placeholder="Choose year" value={list.endsIn} styles={style1} options={options} onChange={(e) => this.handleTimePeriod(e, key, "endsIn")} components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, }} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {list.showCourse && (
                                                                    <div className="row m-0">
                                                                        <div className="col-12 p-0 ">
                                                                            <div className="input-floating-label">
                                                                                <input refs="courseName" type="text" className={"textbox--primary textbox--rounded input"} name="courseName" placeholder=" " onChange={(e) => this.AddCourseFieldHandler(e.target.value, key, "courseName")} value={this.state.listOfPhases[0].courseName} />
                                                                                <label>{COURES_NAME_LABEL}</label>
                                                                            </div>
                                                                            {/* <div className="">
                                                                                <input refs="courseName" type="text" className={"col-12 textbox--primary textbox--rounded input"} name="courseName" placeholder="Course Name" onChange={(e) => this.AddCourseFieldHandler(e.target.value, key, "courseName")} value={this.state.listOfPhases[0].courseName} />
                                                                            </div> */}
                                                                        </div>
                                                                        <div className="col-12 p-0 mb-4">
                                                                            <div className="">
                                                                                <Select value={list.courseType} onChange={(e) => this.AddCourseFieldHandler(e, key, "courseType")} placeholder={COURES_TYPE_LABEL} className="goals__form__select mb-3" classNamePrefix="mySelect" options={options2} closeMenuOnSelect={true} isClearable={false} components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null, }} styles={{ container: (provided, state) => ({ ...provided, height: "48px", overflow: "visible", }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: "100%", minHeight: "48px", }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: "#fff", paddingLeft: 10, paddingRight: 10, }), }} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {list.showActivity && (
                                                                    <div className="row m-0">
                                                                        <div className="col-12 p-0">
                                                                            <div className="col-12">
                                                                                <div className="input-floating-label">
                                                                                    <input refs="activityName" type="text" className={"textbox--primary textbox--rounded input"} name="activityName" placeholder=" " onChange={(e) => this.AddCourseFieldHandler(e.target.value, key, "activityName")} value={this.state.listOfPhases[key].activityName} />
                                                                                    <label>{ACTIVITY_NAME_LABEL}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                <div className="row m-0">
                                                                    <div className="col-12 col-sm-6 mt-2">
                                                                        {!list.showCourse && (
                                                                            <a href="" className="cta cta--rounded cta--plan m-0 w-100 d-block text-center p-2 cta-primary text-white" onClick={(e) => this.addSubFieldHandler(e, key, "course")}>
                                                                                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.16658 2.33301C9.16658 2.06779 9.06123 1.81344 8.87369 1.6259C8.68615 1.43836 8.4318 1.33301 8.16658 1.33301C7.90137 1.33301 7.64701 1.43836 7.45948 1.6259C7.27194 1.81344 7.16658 2.06779 7.16658 2.33301V6.66634H2.83325C2.56804 6.66634 2.31368 6.7717 2.12615 6.95923C1.93861 7.14677 1.83325 7.40112 1.83325 7.66634C1.83325 7.93156 1.93861 8.18591 2.12615 8.37345C2.31368 8.56098 2.56804 8.66634 2.83325 8.66634H7.16658V12.9997C7.16658 13.2649 7.27194 13.5192 7.45948 13.7068C7.64701 13.8943 7.90137 13.9997 8.16658 13.9997C8.4318 13.9997 8.68615 13.8943 8.87369 13.7068C9.06123 13.5192 9.16658 13.2649 9.16658 12.9997V8.66634H13.4999C13.7651 8.66634 14.0195 8.56098 14.207 8.37345C14.3946 8.18591 14.4999 7.93156 14.4999 7.66634C14.4999 7.40112 14.3946 7.14677 14.207 6.95923C14.0195 6.7717 13.7651 6.66634 13.4999 6.66634H9.16658V2.33301Z" fill="#fff" /></svg>
                                                                                Add Course
                                                                            </a>
                                                                        )}
                                                                        {list.showCourse && (
                                                                            <a href="" className="cta cta--rounded cta--plan m-0 w-100 d-block text-center p-2 cta-primary text-white" onClick={(e) => this.addSubFieldHandler(e, key, "course")}>
                                                                                {/* <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.16658 2.33301C9.16658 2.06779 9.06123 1.81344 8.87369 1.6259C8.68615 1.43836 8.4318 1.33301 8.16658 1.33301C7.90137 1.33301 7.64701 1.43836 7.45948 1.6259C7.27194 1.81344 7.16658 2.06779 7.16658 2.33301V6.66634H2.83325C2.56804 6.66634 2.31368 6.7717 2.12615 6.95923C1.93861 7.14677 1.83325 7.40112 1.83325 7.66634C1.83325 7.93156 1.93861 8.18591 2.12615 8.37345C2.31368 8.56098 2.56804 8.66634 2.83325 8.66634H7.16658V12.9997C7.16658 13.2649 7.27194 13.5192 7.45948 13.7068C7.64701 13.8943 7.90137 13.9997 8.16658 13.9997C8.4318 13.9997 8.68615 13.8943 8.87369 13.7068C9.06123 13.5192 9.16658 13.2649 9.16658 12.9997V8.66634H13.4999C13.7651 8.66634 14.0195 8.56098 14.207 8.37345C14.3946 8.18591 14.4999 7.93156 14.4999 7.66634C14.4999 7.40112 14.3946 7.14677 14.207 6.95923C14.0195 6.7717 13.7651 6.66634 13.4999 6.66634H9.16658V2.33301Z" fill="#fff" />
                                                        </svg> */}Remove Course
                                                                            </a>
                                                                        )}
                                                                    </div>
                                                                    <div className="col-12 col-sm-6 mt-2">
                                                                        {!list.showActivity && (
                                                                            <a href="" className="cta cta--rounded cta--plan m-0 w-100 d-block text-center p-2 cta-primary text-white" onClick={(e) => this.addSubFieldHandler(e, key, "activity")} >
                                                                                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.16658 2.33301C9.16658 2.06779 9.06123 1.81344 8.87369 1.6259C8.68615 1.43836 8.4318 1.33301 8.16658 1.33301C7.90137 1.33301 7.64701 1.43836 7.45948 1.6259C7.27194 1.81344 7.16658 2.06779 7.16658 2.33301V6.66634H2.83325C2.56804 6.66634 2.31368 6.7717 2.12615 6.95923C1.93861 7.14677 1.83325 7.40112 1.83325 7.66634C1.83325 7.93156 1.93861 8.18591 2.12615 8.37345C2.31368 8.56098 2.56804 8.66634 2.83325 8.66634H7.16658V12.9997C7.16658 13.2649 7.27194 13.5192 7.45948 13.7068C7.64701 13.8943 7.90137 13.9997 8.16658 13.9997C8.4318 13.9997 8.68615 13.8943 8.87369 13.7068C9.06123 13.5192 9.16658 13.2649 9.16658 12.9997V8.66634H13.4999C13.7651 8.66634 14.0195 8.56098 14.207 8.37345C14.3946 8.18591 14.4999 7.93156 14.4999 7.66634C14.4999 7.40112 14.3946 7.14677 14.207 6.95923C14.0195 6.7717 13.7651 6.66634 13.4999 6.66634H9.16658V2.33301Z" fill="#fff" /></svg>
                                                                                Add Activity
                                                                            </a>
                                                                        )}
                                                                        {list.showActivity && (
                                                                            <a href="" className="cta cta--rounded cta--plan m-0 w-100 d-block text-center p-2 cta-primary text-white" onClick={(e) => this.addSubFieldHandler(e, key, "activity")}>
                                                                                {/* <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.16658 2.33301C9.16658 2.06779 9.06123 1.81344 8.87369 1.6259C8.68615 1.43836 8.4318 1.33301 8.16658 1.33301C7.90137 1.33301 7.64701 1.43836 7.45948 1.6259C7.27194 1.81344 7.16658 2.06779 7.16658 2.33301V6.66634H2.83325C2.56804 6.66634 2.31368 6.7717 2.12615 6.95923C1.93861 7.14677 1.83325 7.40112 1.83325 7.66634C1.83325 7.93156 1.93861 8.18591 2.12615 8.37345C2.31368 8.56098 2.56804 8.66634 2.83325 8.66634H7.16658V12.9997C7.16658 13.2649 7.27194 13.5192 7.45948 13.7068C7.64701 13.8943 7.90137 13.9997 8.16658 13.9997C8.4318 13.9997 8.68615 13.8943 8.87369 13.7068C9.06123 13.5192 9.16658 13.2649 9.16658 12.9997V8.66634H13.4999C13.7651 8.66634 14.0195 8.56098 14.207 8.37345C14.3946 8.18591 14.4999 7.93156 14.4999 7.66634C14.4999 7.40112 14.3946 7.14677 14.207 6.95923C14.0195 6.7717 13.7651 6.66634 13.4999 6.66634H9.16658V2.33301Z" fill="#fff" />
                                                        </svg> */}
                                                                                Remove Activity
                                                                            </a>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-12 p-0 text-center mt-4">
                                    <a href="" onClick={(e) => this.addPhaseHandler(e)} className="" >
                                        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" > <path d="M9.16658 2.33301C9.16658 2.06779 9.06123 1.81344 8.87369 1.6259C8.68615 1.43836 8.4318 1.33301 8.16658 1.33301C7.90137 1.33301 7.64701 1.43836 7.45948 1.6259C7.27194 1.81344 7.16658 2.06779 7.16658 2.33301V6.66634H2.83325C2.56804 6.66634 2.31368 6.7717 2.12615 6.95923C1.93861 7.14677 1.83325 7.40112 1.83325 7.66634C1.83325 7.93156 1.93861 8.18591 2.12615 8.37345C2.31368 8.56098 2.56804 8.66634 2.83325 8.66634H7.16658V12.9997C7.16658 13.2649 7.27194 13.5192 7.45948 13.7068C7.64701 13.8943 7.90137 13.9997 8.16658 13.9997C8.4318 13.9997 8.68615 13.8943 8.87369 13.7068C9.06123 13.5192 9.16658 13.2649 9.16658 12.9997V8.66634H13.4999C13.7651 8.66634 14.0195 8.56098 14.207 8.37345C14.3946 8.18591 14.4999 7.93156 14.4999 7.66634C14.4999 7.40112 14.3946 7.14677 14.207 6.95923C14.0195 6.7717 13.7651 6.66634 13.4999 6.66634H9.16658V2.33301Z" fill="#1B1C1E" /></svg>
                                        Add Phase
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <div className="row m-0">
                        <div className="footer__left col-md-4 col-sm-4 col-4 text-center p-0">
                            <p>
                                <Link to="/onboarding/intialplansParent" onClick={() => this.props.setIsChildProfileSuccess(false)} className="footer__left__cta">
                                    <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.80474 0.195262C5.54439 -0.0650874 5.12228 -0.0650874 4.86193 0.195262L0.195262 4.86193C-0.0650873 5.12228 -0.0650873 5.54439 0.195262 5.80474L4.86193 10.4714C5.12228 10.7318 5.54439 10.7318 5.80474 10.4714C6.06509 10.2111 6.06509 9.78894 5.80474 9.52859L1.60948 5.33333L5.80474 1.13807C6.06509 0.877722 6.06509 0.455612 5.80474 0.195262Z" fill="#1B1C1E" />
                                    </svg>
                                    <span>Go back</span>
                                </Link>
                            </p>
                        </div>
                        <div className="footer__center col-md-4 col-sm-4 col-4 text-center">
                            <p>7 out of 9 steps</p>
                        </div>
                        <div className="footer__right col-md-4 col-sm-4 col-4 text-center">
                            <Link className="d-inline-block pe-3" to="/onboarding/selectadvisor/child">Skip</Link>
                            <Link className="footer__cta d-inline-block" to="/onboarding/selectadvisor/child">Next step</Link>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
const mapStateToProps = ({ auth, commonData, userProfileData }) => {
    const { message, errorList, status, gender, country } = commonData
    const { isProfileSuccess, userProfile } = userProfileData;
    return { message, errorList, status, gender, isProfileSuccess, userProfile, country }
};

export default connect(mapStateToProps, { setUserOnboard, setIsChildProfileSuccess })(withRouter(Index));