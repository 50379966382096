import React, { Component } from "react";
class Index extends Component {
    constructor(props) {
        super(props);
    }
    state = {};
    render() {
        let { iconButton } = this.props;
        return (
            <>
                <div className="icon-button">
                    <div className={iconButton.button === "Declined" ?"icon-button__red-text " :"icon-button__text" }>
                        {iconButton.button === "Accepted" ? (
                            <svg
                          
                                width="8"
                                height="8"
                                viewBox="0 0 8 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M8 4C8 6.20914 6.20914 8 4 8C1.79086 8 0 6.20914 0 4C0 1.79086 1.79086 0 4 0C6.20914 0 8 1.79086 8 4ZM2.28537 4C2.28537 4.94696 3.05304 5.71463 4 5.71463C4.94696 5.71463 5.71463 4.94696 5.71463 4C5.71463 3.05304 4.94696 2.28537 4 2.28537C3.05304 2.28537 2.28537 3.05304 2.28537 4Z"
                                    fill="#34C38F"
                                />
                            </svg>
                        ) : (
                            <svg
                           
                                width="8"
                                height="8"
                                viewBox="0 0 8 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M8 4C8 6.20914 6.20914 8 4 8C1.79086 8 0 6.20914 0 4C0 1.79086 1.79086 0 4 0C6.20914 0 8 1.79086 8 4ZM2.28537 4C2.28537 4.94696 3.05304 5.71463 4 5.71463C4.94696 5.71463 5.71463 4.94696 5.71463 4C5.71463 3.05304 4.94696 2.28537 4 2.28537C3.05304 2.28537 2.28537 3.05304 2.28537 4Z"
                                    fill="#FA5F1C"
                                />
                            </svg>
                        )}
                        <span className="ps-1">{iconButton.button}</span>
                    </div>
                </div>
            </>
        );
    }
}

export default Index;
