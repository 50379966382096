import React from "react";

const Index = (props) => {
	console.log(props?.notification, "notiiiiiiiiii")
	return (
		<div className="upcomecnt_list">
			{props?.notification?.map((item, index) => {
				const loggedAtDate = new Date(item?.loggedAt);

				const formattedDate = loggedAtDate.toLocaleDateString('en-US', {
					month: 'short',
					day: '2-digit',
					year: 'numeric',
				});

				const formattedTime = loggedAtDate.toLocaleTimeString('en-US', {
					hour: 'numeric',
					minute: '2-digit',
					hour12: true
				});

				const formattedDateTime = `${formattedDate}, ${formattedTime}`;
				return (
					<div key={index} className="upcomecnt_list_item">
						<div className="upcomecnt_list_item-card">
							<div >
								<h6>{item.context}</h6>
								<p>{formattedDateTime}</p>
							</div>
						</div>
					</div>
				)
			}
			)}
		</div>
	);
};

export default Index;
