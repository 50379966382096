import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../../assets/css/sendInvite.less";
import ReactTooltip from "react-tooltip";
import CustomAlert from "../../../components/Alert"
import Loader from "../../../components/Loader";
import { EMAIL_LABEL } from "../../../Constants/commonLabels";
import { connect } from "react-redux";
import { withRouter } from "../../../redux/store/navigate";
import {setUserOnboard,CopyInviteErrorstatus, setIsUserProfileSuccess, SentInviteParent, getUserProfile, GetInviteParent, clearMessage, SentInviteParentstatus, getUserProfilestatus, CopyInviteLink, CopyInviteLinkstatus, SentInviteParentErrorstatus,setCopyInviteFlag } from "../../../../src/redux/actions";
import Alert from 'react-bootstrap/Alert';
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            fields: { email: '' },
            errors: {},
            alertVisible: false,
            selectedEmail: [],
            displayError: false,
            emailMatchs: [],
            isClickedNext: false,
            nextalertVisible: false,
            link: '',
            copiedlink: false,
            clicked: false,
            showalert: false
        }
    };
    componentDidMount = () => {
        this.props.setCopyInviteFlag(true)
        // this.setState({ loading: true })
        this.props.setIsUserProfileSuccess(false)
        this.handleCopySendInvite()
        this.props.getUserProfile()
        this.props.GetInviteParent();
        this.props.clearMessage();
        // this.setIntialValue();

    }
    componentDidUpdate = async() => {
        if (this.props.isInviteSentSucess) {
            this.setState({ loading: true })
            this.props.getUserProfile()
            this.setIntialValue()
        }
        if (this.props.getprofilestatus) {
            // this.setState({ loading: true })
            this.setIntialValue()
        }
        if (this.props.sentinviteerror) {
            this.setState({ loading: false, alertVisible: true })
            this.props.SentInviteParentErrorstatus(false)
        }
        if (this.props.invitedmailstatus) {
            this.props.GetInviteEmailStatus(false)
        }
        // if (this.state.link === '' && this.state.clicked === true) {
        //     this.handleCopySendInvite()
        // }
        if (this.props.copyInviteFlag && this.state.clicked === true && this.state.link === '') {
            this.handleCopySendInvite()
        }
        if (this.props.copyInvitelinkSucess) {
            this.copySetInvite()
        }
        if(this.props.copylinkerror && this.state.clicked === true){
            this.setState({showalert: false,copiedlink: false,clicked: false })
            this.props.CopyInviteErrorstatus(false)
        }
        if (this.props.isUserOnboarded) {
            this.props.navigate('/student/dashboard');
        }
    }

    copySetInvite = async() =>{
        let {link,clicked} = this.state
        if(this.props.copyInviteFlag && this.props.copyInvitelink){
            link = this.props?.copyInvitelink
            clicked = false
            this.setState({link,clicked }) 
            this.props.setCopyInviteFlag(false)
        }
    }

    handleChange = (field, e) => {
        let { errors } = this.state;
        let fields = this.state.fields;
        fields[field] = e.target.value;
        if (e.target.value.length >= 0) {
            errors[field] = "";
        }
        this.setState({ fields, errors: errors, displayError: true });
    }
    validateEmails = (emails) => {
        const re = /^[\w-\.+]+@([\w-]+\.)+[\w-]{2,4}$/;
        const emailArray = emails.split(',');
        for (let i = 0; i < emailArray.length; i++) {
            if (!re.test(emailArray[i].trim())) {
                return false;
            }
        }
        return true;
    }
    handleValidation = () => {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (this.state.displayError === false) {
            if (!fields["email"]) {
                formIsValid = false;
                errors["email"] = "*Required";
            }
        }
        if (fields["email"] !== "") {
            let lastAtPos = fields["email"].lastIndexOf("@");
            let lastDotPos = fields["email"].lastIndexOf(".");
            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    fields["email"].indexOf("@@") === -1 &&
                    lastDotPos > 2 &&
                    fields["email"].length - lastDotPos > 2
                )
            ) {

                formIsValid = false;
                errors["email"] = "Not a valid email";
            }

            if (this?.validateEmails(fields["email"])) {
            } else {
                formIsValid = false;
                errors["email"] = "Not a valid email";
            }

        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    handleSendInvite = (e) => {
        if (this.handleValidation()) {
            let fields = this.state.fields;
            let emailLst = []
            let Emailfield
            if (fields["email"]) {
                Emailfield = fields["email"]?.split(',')
                Emailfield?.map(element => {
                    if (element.trim() !== "") {
                        emailLst.push(element.trim())
                    }
                });
            }
            let values = {
                emails: emailLst,
            }
            this.setState({ loading: true })
            this.setState({ alertVisible: true, displayError: false, isClickedNext: false, nextalertVisible: false })
            this.props.SentInviteParent(values);
            this.setState({ loading: true })
        }
    }
    handleCopySendInvite = () => {
        let values = {
            "role": "parent"
        }
        if (this.props.copyInviteFlag) {
            this.props.CopyInviteLink(values);
        }
    }
    CopyClipboards = async () => {
        this.props.clearMessage()
        let { link } = this.state
        this.setState({ copiedlink: false, alertVisible: false })
        var textfield = document.createElement("input");
        textfield.setAttribute('id', 'myInput')
        textfield.setAttribute('value', link)
        document.body.appendChild(textfield)
        var copyText = document.getElementById("myInput");
        copyText.focus();
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand('copy');
        textfield.remove();
        this.props.CopyInviteLinkstatus(false)
        this.setState({ link: '', copiedlink: true, alertVisible: true, clicked: true, showalert: true })
        setTimeout(() => {
            this.setState({ showalert: false, alertVisible: false })
        }, 3000);
        this.props.setCopyInviteFlag(true)
    }
    setIntialValue = () => {
        if (this.state.loading === true) {
            if (this.props?.userProfile && this.props?.userProfile?.studentProfile !== undefined) {
                if (this.props?.userProfile?.studentProfile?.parentEmails?.length > 0) {
                    let mails = this.props?.userProfile?.studentProfile?.parentEmails;
                    let { fields, selectedEmail } = this.state;
                    fields["email"] = mails.join(", ");
                    if (mails !== undefined) {
                        mails?.map((email) => {
                            selectedEmail.push(email)
                        })
                    }
                    this.setState({ selectedEmail });
                    this.props.SentInviteParentstatus(false)
                    this.props.getUserProfilestatus(false)
                    this.setState({ loading: false })
                }
                if (this.props?.userProfile?.studentProfile?.parentEmails?.length === 0 || this.props?.userProfile?.studentProfile?.parentEmails=== null ) {
                    let { fields, } = this.state;
                    fields["email"] = "";
                    this.props.SentInviteParentstatus(false)
                    this.props.getUserProfilestatus(false)
                    this.setState({ loading: false })
                }
            }
        }
    }

    handleClickSkip = () => {
        this.props.setUserOnboard();
    }

    handleNext = (e) => {
        e.preventDefault();
        let { fields, emailMatchs } = this.state;
        if (this?.state?.displayError === true) {
            if (this.props?.userProfile?.studentProfile?.parentEmails !== undefined) {
                this.props?.userProfile?.studentProfile?.parentEmails?.map((email) => {
                    emailMatchs.push({
                        emails: email === fields["email"] ? true : false,
                    })
                })
                if (emailMatchs && emailMatchs[0]?.emails === true) {
                    // this.props.navigate('/onboarding/plans');
                    this.handleClickSkip()
                }
                else {
                    this.setState({ isClickedNext: true, nextalertVisible: true });
                }
            }
            if (this.props?.userProfile?.studentProfile?.parentEmails?.length === 0) {
                if (fields["email"] === "") {
                    // this.props.navigate('/onboarding/plans');
                    this.handleClickSkip()
                }
            }
            else if (this.props?.userProfile?.studentProfile?.parentEmails?.length !== 0) {
                if (fields["email"] === "") {
                    this.handleSendInvite()
                    // this.props.navigate('/onboarding/plans');
                    this.handleClickSkip()
                }
            }
        }
        else {
            // this.props.navigate('/onboarding/plans');
            this.handleClickSkip()
        }
    }
    render() {
        let { loading, errors, alertVisible, link, copiedlink, nextalertVisible, isClickedNext } = this.state
        return (
            <>
                {loading && <Loader />}
                <div className="sendInvite">
                    <div className="sendInvite__form">
                        <h1 className="sendInvite__form__title text-center">Would you like to invite your parents ?</h1>
                        <div className="sendInvite__form_inner">
                            <div className="row m-0">
                                <div className="col-md-12 col-sm-12 col-12 ps-0 p-mob-0">
                                    <div className="input-floating-label">
                                        <input type="text" className={"textbox--primary textbox--rounded input"}
                                            name="Email" placeholder=" " value={this.state.fields["email"]} onChange={(e) => this.handleChange("email", e)} />
                                        <label>{EMAIL_LABEL}</label>
                                        {errors["email"] && <span className="error-text ps-0">{errors["email"]}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-md-12 col-sm-12 col-12 ps-0 p-mob-0">
                                    <div className="text-center" >
                                        <Link to="#">
                                            <Button className="btn cta--rounded cta-primary mb-4 w-100" onClick={(e) => this.handleSendInvite(e)} style={{"borderRadius":"10px"}}  >
                                                <span>Send Invite</span>
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0">
                                {copiedlink === false ? (
                                    <ReactTooltip id="copy" place="bottom" effect="solid" className={"tooltip"}>Click to copy link</ReactTooltip>
                                ) : (
                                    <ReactTooltip id="copy" place="bottom" effect="solid" className={"tooltip"}>Link copied success</ReactTooltip>
                                )}
                                <div className="col-md-12 col-sm-12 col-12 ps-0 p-mob-0">
                                    <p data-tip data-for="copy" className="text-center" style={{ "cursor": "pointer" }} onClick={() => this.CopyClipboards(link)} >
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.5441 10.456C12.7249 9.6372 11.6142 9.17725 10.4561 9.17725C9.2979 9.17725 8.18716 9.6372 7.36805 10.456L4.27905 13.544C3.45993 14.3631 2.99976 15.474 2.99976 16.6325C2.99976 17.7909 3.45993 18.9018 4.27905 19.721C5.09818 20.5401 6.20914 21.0003 7.36755 21.0003C8.52597 21.0003 9.63693 20.5401 10.4561 19.721L12.0001 18.177" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M10.4561 13.5438C11.2752 14.3626 12.3859 14.8225 13.5441 14.8225C14.7022 14.8225 15.8129 14.3626 16.6321 13.5438L19.7211 10.4558C20.5402 9.63669 21.0004 8.52572 21.0004 7.36731C21.0004 6.2089 20.5402 5.09793 19.7211 4.27881C18.9019 3.45969 17.791 2.99951 16.6326 2.99951C15.4741 2.99951 14.3632 3.45969 13.5441 4.27881L12.0001 5.82281" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        Copy invite link
                                    </p>
                                </div>
                                {alertVisible && this.state.showalert &&
                                    <Alert className="alertcmp" dismissible variant="success" onClose={() => { this.setState({ showalert: false, alertVisible: false }) }}> Copied to clipboard </Alert>
                                }
                                {nextalertVisible && this.state.isClickedNext &&
                                    <Alert className="alertcmp" dismissible variant="danger" onClose={() => { this.setState({ isClickedNext: false, nextalertVisible: false }) }}>Please click the Send invite button</Alert>
                                }
                                {alertVisible &&
                                    <CustomAlert className="alertcmp" />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <div className="row m-0">
                        <div className="footer__left col-md-4 col-sm-4 col-4 text-center p-0">
                            <p>
                                <Link to="/onboarding/selectfriend" className="footer__left__cta">
                                    <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.80474 0.195262C5.54439 -0.0650874 5.12228 -0.0650874 4.86193 0.195262L0.195262 4.86193C-0.0650873 5.12228 -0.0650873 5.54439 0.195262 5.80474L4.86193 10.4714C5.12228 10.7318 5.54439 10.7318 5.80474 10.4714C6.06509 10.2111 6.06509 9.78894 5.80474 9.52859L1.60948 5.33333L5.80474 1.13807C6.06509 0.877722 6.06509 0.455612 5.80474 0.195262Z" fill="#1B1C1E" />
                                    </svg>
                                    <span>Go back</span>
                                </Link>
                            </p>
                        </div>
                        <div className="footer__center col-md-4 col-sm-4 col-4 text-center">
                            {/* <p>7 out of 7 steps</p> */}
                        </div>
                        <div className="footer__right col-md-4 col-sm-4 col-4 text-center">
                            {/* <Link className="d-inline-block footer__cta" to="/onboarding/plans">Next step</Link> */}
                            <Link className="d-inline-block footer__cta" to="#" onClick={(e) => this.handleNext(e)} style={{"width":"190px"}} >Go to dashboard</Link>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
const mapStateToProps = ({ userProfileData, commonData }) => {
    const { loading, copyInviteFlag } = commonData
    const { isUserOnboarded,isInviteSentSucess, sentinviteerror, userProfile, getprofilestatus, copyInvitelinkSucess, copyInvitelink,copylinkerror } = userProfileData;
    return { isUserOnboarded,loading, isInviteSentSucess, sentinviteerror, userProfile, getprofilestatus, copyInvitelinkSucess, copyInvitelink, copyInviteFlag,copylinkerror }
};

export default connect(mapStateToProps, { setUserOnboard,CopyInviteErrorstatus,setIsUserProfileSuccess, SentInviteParent, getUserProfile, GetInviteParent, clearMessage, SentInviteParentstatus, getUserProfilestatus, CopyInviteLink, CopyInviteLinkstatus, SentInviteParentErrorstatus,setCopyInviteFlag })(withRouter(Index));