import React, { Component } from "react";
import {Link } from "react-router-dom";

class Index extends Component {
    state = {}
    render() {
        return (
            <>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="login">
                                <div className="Verification_card text-center">
                                    <h1 className="login_title text-center">Error</h1>
                                    <p className="login_desc text-center">This page is not available</p>
                                    <Link className="cta--underline " to="/student/dashboard">Back to home</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <div className="row m-0">
                        <div className="footer__left col-md-4 col-sm-4 col-4 text-center p-0">
                            <p>
                                <Link to="/" className="footer__left__cta">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M13.334 8.00065C13.334 7.63246 13.0355 7.33398 12.6673 7.33398H3.33398C2.96579 7.33398 2.66732 7.63246 2.66732 8.00065C2.66732 8.36884 2.96579 8.66732 3.33398 8.66732H12.6673C13.0355 8.66732 13.334 8.36884 13.334 8.00065Z" fill="#1B1C1E"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M8.47075 2.86128C8.2104 2.60093 7.78829 2.60093 7.52794 2.86128L2.86128 7.52794C2.60093 7.78829 2.60093 8.2104 2.86128 8.47075L7.52794 13.1374C7.78829 13.3978 8.2104 13.3978 8.47075 13.1374C8.7311 12.8771 8.7311 12.455 8.47075 12.1946L4.27549 7.99935L8.47075 3.80409C8.7311 3.54374 8.7311 3.12163 8.47075 2.86128Z" fill="#1B1C1E"/>
                                    </svg>
                                    <span>Go back</span>
                                </Link>
                            </p>
                        </div>
                        <div className="footer__center col-md-4 col-sm-4 col-4 text-center">                            
                        </div>
                        <div className="footer__right col-md-4 col-sm-4 col-4 text-center">
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Index;