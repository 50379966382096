import React, { useCallback, useEffect, useRef, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import CountCard from "../../../../componentsNew/Dashboard/CountCard";
import "./dashboardCarosal.less"
import {
    saveUserProfile, unSaveUser, resetDetailedUserProfileStatus, advisorSaveStatus, setExploreStudentsByUniversityStatus,
    setExploreStudentsByProfessionStatus, setExploreStudentsByCourseStatus, setExploreStudentsByActivityStatus,
    getExploreStudentsBySection, setSavedUserProfileStatus, setExploreStudentsBySectionStatus, genericSearchStudent, setGenericSearchStudentStatus,

    fetchBySectionAdvisors, genericSearchStudents, genericSearchStudentsBySection,
} from "../../../../redux/actions";
import { connect, useDispatch, useSelector } from 'react-redux';


const Index = (props) => {

    let ref = useRef(0);
    const dispatch = useDispatch();

    const options = {
        nav: true,
        navText: ['<span class="prev-button"><</span>', '<span class="next-button">></span>'],  // Correct the property to `navText`
        autoplay: false,
        slideBy: 1,
        dots: false,
        dotsEach: false,
        dotData: true,
        loop: false,
        lazyLoadEager: 3,
        startPosition: ref.current,

        responsive: props?.reponsive || {
            1: { items: 1 },
            320: { items: 1 },
            430: { items: 2 },
            570: { items: 3 },
            700: { items: 4 },
            769: { items: 3 },
            900: { items: 4 },
            1000: { items: 2 },
            1100: { items: 3 },
            1300: { items: 4 },
            1330: { items: 5 },
            1600: { items: 6 },
            1900: { items: 8 },
            2300: { items: 10 },
            2561: { items: 10 }
        },
    };

    console.log(props?.data, "daradatdatdatdatda");


    let owlCarouselData = props?.data?.map(element => {
        return (
            <div className="owl__item" key={element?.id}>
                <a>
                    <CountCard data={element} key={element?.id} parentAdvisor={props?.parentAdvisor} parentStudents={props?.parentStudents}/>
                </a>
            </div>
        )
    })

    return (
        <>
            {console.log(owlCarouselData, "owlCarouselData")}
            <OwlCarousel
                className="owl owl-OwlCarousel owl-theme owlForDashboard"
                {...options} >
                {console.log(owlCarouselData, "owlCarouselData2")}
                {owlCarouselData}
            </OwlCarousel>

        </>
    )
}

const mapStateToProps = ({ commonData, exploreData }) => {
    const { message, errorList, loadSavedUsers, loadSavedUserStatus, genericSearchStudentData, genericSearchStudentStatus } = commonData;
    const { loadUniversityStudent, loadProfessionStudent, loadCoursesStudent, loadActivitiesStudent,
        exploreUniversityStudentData, exploreProfessionStudentData, exploreCoursesStudentData, exploreActivitiesStudentData, loadStudentBySection } = exploreData
    return {
        message, errorList, loadSavedUsers, loadSavedUserStatus, loadUniversityStudent, genericSearchStudentStatus,
        loadProfessionStudent, loadCoursesStudent, loadActivitiesStudent, exploreUniversityStudentData, genericSearchStudentData,
        exploreProfessionStudentData, exploreCoursesStudentData, exploreActivitiesStudentData, loadStudentBySection
    };
};
export default connect(mapStateToProps, {
    saveUserProfile, unSaveUser, resetDetailedUserProfileStatus, advisorSaveStatus,
    setExploreStudentsByUniversityStatus, setExploreStudentsByProfessionStatus, setExploreStudentsByCourseStatus,
    setExploreStudentsByActivityStatus, getExploreStudentsBySection, setSavedUserProfileStatus, setExploreStudentsBySectionStatus,
    genericSearchStudent, setGenericSearchStudentStatus,

    fetchBySectionAdvisors, genericSearchStudents, genericSearchStudentsBySection
})(Index);