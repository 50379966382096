import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../../../assets/css/role.less";
import { connect } from "react-redux";
import Alert from "@mui/material/Alert";
import { setUserRole, getUserProfile, getUserRoles, clearMessage } from "../../../redux/actions";
import { withRouter } from "../../../redux/store/navigate";
import Loader from "../../../components/Loader";
import CustomAlert from "../../../components/CustomAlert";
import Footer from '../../../componentsNew/Common/Footer'
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            dataLoaded: false,
            userRoles: [],
            selected: "",
            fields: {},
            errors: {},
            student: false,
            advisor: false,
            parent: false,
            showAlert: false,
            buttonname: "Specify your goals",
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.props.clearMessage()
        if (this.props?.userProfile?.currentRole === "student") {
            this.setState({ student: true, advisor: false, parent: false, buttonname: "Specify your goals" });
        } else if (this.props?.userProfile?.currentRole === "advisor") {
            this.setState({ advisor: true, parent: false, student: false, buttonname: "Create your profile" });
        } else if (this.props?.userProfile?.currentRole === "parent") {
            this.setState({ parent: true, student: false, advisor: false, buttonname: "Specify your goals" });
        }
    }

    componentDidUpdate() {
        if (this.state.showAlert) {
            setTimeout(() => {
                this.setState({
                    showAlert: false,
                });
            }, 4000);
        }

        if (!this.state.dataLoaded) {
            if (this.props.userRoles !== undefined && this.props.userRoles.length > 0) {
                this.setState({ dataLoaded: true, loading: false });
            }
        }
        if (!this.state.selected) {
            if (this.props.userProfile?.currentRole) {
                this.setState({
                    selected: this.props.userProfile?.currentRole ? this.props.userProfile?.currentRole : "",
                    loading: false,
                });
            }
        }
        if (this.props.userProfile?.roleLocked) {
            if (this.props.userProfile?.currentRole === "student") {
                this.props.navigate("/onboarding/goals");
            }
            if (this.props.userProfile?.currentRole === "advisor") {
                this.props.navigate("/onboarding/profile");
            }
            if (this.props.userProfile?.currentRole === "parent") {
                this.props.navigate("/onboarding/goals/child");
            }
        }
    }

    handleChange(event) {
        if (!this.props.userProfile?.roleLocked) {
            let selectedOption = event.target.value;

            if (selectedOption === "student") {
                this.setState({ student: true, advisor: false, parent: false, buttonname: "Specify your goals" });
            } else if (selectedOption === "advisor") {
                this.setState({ advisor: true, parent: false, student: false, buttonname: "Create your profile" });
            } else if (selectedOption === "parent") {
                this.setState({ parent: true, student: false, advisor: false, buttonname: "Specify your goals" });
            }
            this.setState({ selected: selectedOption });
            let values = { currentRole: selectedOption, };
            this.props.setUserRole(values);
        }
        if (event && this.props.userProfile?.roleLocked) {
            this.setState({ showAlert: true });
        }
        this.setState({ errors: "" });
    }

    handleValidation() {
        let errors = {};
        let formIsValid = true;

        if (!this.state.selected) {
            formIsValid = false;
            errors["role"] = "Please select any role";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    handleNext(e) {
        if (this.handleValidation()) {
            if (this.state.selected == "student") {
                this.props.navigate("/onboarding/goals");
                e.preventDefault();
            } else if (this.state.selected == "advisor") {
                this.props.navigate("/onboarding/profile");
                e.preventDefault();
            } else if (this.state.selected == "parent") {
                this.props.navigate("/onboarding/goals/child");
                e.preventDefault();
            }
        }
    }

    setShow = (e) => {
        this.setState({ showAlert: false });
    };

    render() {
        let { student, advisor, parent } = this.state;
        let roleselected = "";
        if (this.props.userProfile?.currentRole != undefined) {
            roleselected = this.props.userProfile.currentRole.charAt(0).toUpperCase() + this.props.userProfile.currentRole.slice(1);
        }
        let listItem = this.props.userRoles !== undefined && this.props.userRoles.length > 0 && this.props.userRoles.map((role, key) => {
            const rolesName = role.charAt(0).toUpperCase() + role.slice(1);
            return (
                <div className="mb-2 role__btns ">
                    <input
                        type="radio" className="btn-check"
                        key={rolesName} id={rolesName}
                        autoComplete="off"
                        name="options" value={role}
                        onChange={(e) => this.handleChange(e)}
                        checked={roleselected === rolesName ? true : false}
                    />
                    <label className="btn cta--role--btn w-mob-100" htmlFor={rolesName} >
                        {rolesName && rolesName === "Student" &&
                            <svg className="Studenticon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path className="iconstroke" d="M6.57517 12.3301L2.95169 10.8807C2.60805 10.7432 2.60805 10.2568 2.95169 10.1193L11.2572 6.79711C11.734 6.60638 12.266 6.60638 12.7428 6.79711L21.0483 10.1193C21.392 10.2568 21.392 10.7432 21.0483 10.8807L17.4248 12.3301" strokeLinecap="round" strokeLinejoin="round" />
                                <path className="iconfill" d="M14 13.5C14 13.7761 14.2239 14 14.5 14C14.7761 14 15 13.7761 15 13.5H14ZM14 7.5V13.5H15V7.5H14Z" />
                                <path className="iconstroke" d="M6.5 12.5V16.5L12 18.5L17.5 16.5V12.5C17.5 12.5 17 10.5 12 10.5C7 10.5 6.5 12.5 6.5 12.5Z" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        }
                        {rolesName && rolesName === "Parent" &&
                            <svg className="Studenticon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path className="iconstroke" d="M19.7274 20.4471C19.2716 19.1713 18.2672 18.0439 16.8701 17.2399C15.4729 16.4358 13.7611 16 12 16C10.2389 16 8.52706 16.4358 7.12991 17.2399C5.73276 18.0439 4.72839 19.1713 4.27259 20.4471" strokeWidth="1.2" strokeLinecap="round" />
                                <circle className="iconfill iconstroke" cx="12" cy="8" r="4"  fillOpacity="0.25" strokeWidth="1.2" strokeLinecap="round" />
                            </svg>

                        }
                        {rolesName && rolesName === "Advisor" &&
                            <svg className="Advisoricon"width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="12" cy="8" r="4" className="iconfill" />
                                <path className="iconfill" d="M5.33788 17.3206C5.99897 14.5269 8.77173 13 11.6426 13H12.3574C15.2283 13 18.001 14.5269 18.6621 17.3206C18.79 17.8611 18.8917 18.4268 18.9489 19.0016C19.0036 19.5512 18.5523 20 18 20H6C5.44772 20 4.99642 19.5512 5.0511 19.0016C5.1083 18.4268 5.20997 17.8611 5.33788 17.3206Z" fillOpacity="0.25" />
                            </svg>
                        }
                        {" "}
                         I’m {rolesName == "Advisor" ? "an" : "a"}{" "} {rolesName}
                    </label>
                </div>
            );
        });

        return (
            <>
                {this.state.loading && <Loader />}
                {this.state.loading || (this.props.loading && <Loader />)}
                <div className="role">
                    <div className="role_card">
                        <h1 className="role_title text-center">
                            Select your role
                        </h1>
                        {/* <p className="role_desc text-center bluetext">Choose one option as you want</p> */}
                        <div className="roles_page">
                            {this.props.userProfile && listItem}
                        </div>
                    </div>
                    {this.state.errors["role"] && (
                        <span className="error-text text-center" style={{ display: "table", margin: "0 auto" }} >
                            {this.state.errors["role"]}
                        </span>
                    )}
                </div>
                <CustomAlert />
                {this.state.showAlert && (
                    <div className="my-4">
                        <Alert icon={false} onClose={() => { this.setShow(); }} severity="error" >
                            Can't Switch, Your role has been locked
                        </Alert>
                    </div>
                )}
                <Footer>
                    <Link className="footers__rightSec__cta" to="#" onClick={(e) => this.handleNext(e)} >{this.state.buttonname}
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.92893 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.0222 21.4135 8.08879 20.3147 6.4443C19.2159 4.79981 17.6541 3.51808 15.8268 2.76121C13.9996 2.00433 11.9889 1.8063 10.0491 2.19215C8.10929 2.578 6.32746 3.53041 4.92893 4.92893" strokeWidth="2" className="rolestrokes" />
                            <path d="M15 12L15.7809 11.3753L16.2806 12L15.7809 12.6247L15 12ZM3 13C2.44771 13 2 12.5523 2 12C2 11.4477 2.44771 11 3 11V13ZM11.7809 6.3753L15.7809 11.3753L14.2191 12.6247L10.2191 7.6247L11.7809 6.3753ZM15.7809 12.6247L11.7809 17.6247L10.2191 16.3753L14.2191 11.3753L15.7809 12.6247ZM15 13H3V11H15V13Z" className="rolefills" />
                        </svg>
                    </Link>
                </Footer>
                {/* <div className="footer">
                    <div className="row m-0">
                        <div className="footer__left col-md-4 col-sm-4 d-sm-block d-none p-0"></div>
                        <div className="footer__center col-md-4 col-sm-4 col-6 text-center p-0"> */}
                {/* {student && <p>1 out of 8 steps</p>}
                            {advisor && <p>1 out of 4 steps</p>}
                            {parent && <p>1 out of 9 steps</p>} */}
                {/* </div>
                        <div className="footer__right col-md-4 col-sm-4 col-6 text-center p-0">
                            <Link className="footer__cta" to="#" onClick={(e) => this.handleNext(e)} > {this.state.buttonname}
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.92893 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.0222 21.4135 8.08879 20.3147 6.4443C19.2159 4.79981 17.6541 3.51808 15.8268 2.76121C13.9996 2.00433 11.9889 1.8063 10.0491 2.19215C8.10929 2.578 6.32746 3.53041 4.92893 4.92893" strokeWidth="2" className="rolestrokes" />
                                    <path d="M15 12L15.7809 11.3753L16.2806 12L15.7809 12.6247L15 12ZM3 13C2.44771 13 2 12.5523 2 12C2 11.4477 2.44771 11 3 11V13ZM11.7809 6.3753L15.7809 11.3753L14.2191 12.6247L10.2191 7.6247L11.7809 6.3753ZM15.7809 12.6247L11.7809 17.6247L10.2191 16.3753L14.2191 11.3753L15.7809 12.6247ZM15 13H3V11H15V13Z" className="rolefills" />
                                </svg>
                            </Link>
                        </div>
                    </div>
                </div> */}
            </>
        );
    }
}

const mapStateToProps = ({ commonData, userProfileData }) => {
    const { userRoles, loading } = commonData;
    const { userProfile } = userProfileData;
    return { userRoles, userProfile, loading };
};

export default connect(mapStateToProps, { getUserRoles, setUserRole, getUserProfile, clearMessage })(withRouter(Index));
