import React, { useEffect, useState } from 'react'
import AdvisorCards from "../AdvisorCards"
import StudentCards from "../StudentCards"
import { Button } from '@mui/material'
import { connect } from "react-redux";
import Loader from '../../../../components/Loader';
import { sendRespondToRequest, getPendingRequests,getNetworkUserCount,
	 getPendingRequestsParent, setPendingRequestStatus, setSendRespondToRequestStatus, 
	 sendRespondToParentRequest, respondToParentRequestStatus } from '../../../../redux/actions'
import ViewStudentProfile from "../../../../componentsNew/Common/ViewStudentProfile";
import AdvisorProfile from "../AdvisorProfile";
import ConfirmationPopup from '../../ConfirmationPopup';

const Index = (props) => {
  const [loading, setloading] = useState(false)
  const [advisordata, setadvisordata] = useState([])
  const [showProfile, setShowProfile] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [profilePopup, setProfiePopup] = useState(false)
  const [openConfirmation, setopenConfirmation] = useState(false)
  const [rejectConfirmation, setrejectConfirmation] = useState(false)
  const [message, setmessage] = useState('')

  useEffect(() => {
    if (props?.pendingRequestStatus) {
      setloading(false)
      props?.setPendingRequestStatus(false);
	  props?.getNetworkUserCount()
    }
  }, [props?.pendingList, props?.pendingRequestStatus])
  useEffect(() => {
    if (props?.isRequestRespond) {
      props?.getPendingRequests()
      props?.setSendRespondToRequestStatus(false)
      props?.getNetworkUserCount();
      setloading(false)
    }
    if (props?.parentRequest) {
      props?.respondToParentRequestStatus(false)
      setloading(false)
    }
  }, [props?.isRequestRespond, props?.parentRequest])

  const handleViewProfile = (data) => {
    setProfileData(data);
    showProfileModal(true);
  }
  const showProfileModal = (status) => {
    if (!status) {
      setProfileData({})
    }
    setShowProfile(status)
  }

  const profileShow = (data) => {
    let Payload = {
      userInfo: data?.requestSentUser
    }
    setProfileData(Payload)
    setProfiePopup(true);

  }
  const profileHide = () => setProfiePopup(false);

  const ProfileCall = () => {
    setProfiePopup(false);
    props?.getPendingRequests();
  }

  const ConfirmationSubmit = (e, data, value) => {
    e.preventDefault()
    if (value === "Advisor") {
      setadvisordata(data)
      setmessage('Accepting this request will enable you send messages to this advisor and book your free consultation session')
      setopenConfirmation(true)
    }
    if (value === "Student") {
      setadvisordata(data)
      setmessage('Accepting this request will enable you to send messages to this student and access each others work')
      setopenConfirmation(true)
    }
  }

  const CloseConfirmation = () => {
    setmessage('')
    setadvisordata([])
    setopenConfirmation(false)
  }

  const ConfirmtionAdd = () => {
    setloading(true)
    let payload = {
      "requestId": advisordata?.requestInfo?.id,
      "isAccepted": true
    }
    if (props?.userProfile?.currentRole === "parent") {
      props?.sendRespondToParentRequest(localStorage.getItem("parentViaChild"), payload)
    } else {
      props?.sendRespondToRequest(payload)
    }
    CloseConfirmation()
  }
  const RejectSubmit = (e, data, value) => {
    e.preventDefault()
    if (value === "Advisor") {
      setadvisordata(data)
      setmessage('Please confirm that you would like to reject this connection request from this advisor')
      setrejectConfirmation(true)
    }
    if (value === "Student") {
      setadvisordata(data)
      setmessage('Please confirm that you would like to reject this connection request from this student')
      setrejectConfirmation(true)
    }
  }
  const RejectConfirmtion = () => {
    setloading(true)
    let payload = {
      "requestId": advisordata?.requestInfo?.id,
      "isAccepted": false
    }
    if (props?.userProfile?.currentRole === "parent") {
      props?.sendRespondToParentRequest(localStorage.getItem("parentViaChild"), payload)
    } else {
      props?.sendRespondToRequest(payload)
    }
    RejectClose()
  }
  const RejectClose = () => {
    setmessage('')
    setadvisordata([])
    setrejectConfirmation(false)
  }

  return (
    <>
      {loading && <Loader />}
      {showProfile &&
        <ViewStudentProfile show={showProfile} profileData={profileData} handleClose={() => showProfileModal(false)} />
      }
      {profilePopup &&
        <AdvisorProfile show={profilePopup} detailedInfo={props?.detailedAdvisorData} data={profileData} handleClose={profileHide} HandleCloseApi={ProfileCall} />
      }
      {rejectConfirmation &&
        <ConfirmationPopup show={rejectConfirmation} handleSubmit={() => RejectConfirmtion()} message={message} btnText="confirm" onHide={() => RejectClose()} />
      }
      {openConfirmation &&
        <ConfirmationPopup show={openConfirmation} handleSubmit={() => ConfirmtionAdd()} message={message} btnText="confirm" onHide={() => CloseConfirmation()} />
      }
      {props?.pendingList && props?.pendingList?.advisors?.length > 0 || props?.pendingList && props?.pendingList?.students?.length > 0 ? (
        <div className='RecievedRequest'>
          {props?.pendingList && props?.pendingList?.advisors?.length > 0 ? (
            <>
              <div className='RecievedRequest__Title'>
                <p>These advisors want to connect with you</p>
              </div>
              <div style={{ "display": "flex", "flexWrap": "wrap", "gap": "20px" }} >
                {props?.pendingList && props?.pendingList?.advisors?.length > 0 && props?.pendingList && props?.pendingList?.advisors?.map((data) => (
                  <AdvisorCards data={data} key="" >
                    <div className='advisord-card-footer'>
                      <div className='advisord-card-footer__View'>
                        <Button className='ExploreButton w-100' onClick={(e) => profileShow(data)} >View Profile</Button>
                      </div>
                      <div className='advisord-card-footer__accept mt-2'>
                        <Button className='RejectButton w-100' onClick={(e) => RejectSubmit(e, data, "Advisor")}>Reject</Button>
                        <Button className='ExploreButton w-100' onClick={(e) => ConfirmationSubmit(e, data, "Advisor")} >Accept</Button>
                      </div>
                    </div>
                  </AdvisorCards>
                ))}
              </div>
            </>
          ) : (
            <>
              <div style={{ "marginTop": "100px", "display": "flex", "justifyContent": "center" }} >
                <div className="Plan_Card_Empty_Cnt">
                  <div className="Empty_Card" style={{ padding: '80px' }}>
                    <p>You haven't received any requests from advisor</p>
                  </div>
                </div>
              </div>
            </>
          )}
          {props?.pendingList && props?.pendingList?.students?.length > 0 ? (
            <>
              <div className='RecievedRequest__Title mt-5'>
                <p>These students want to connect with you</p>
              </div>
              <div className='Studentcard' style={{ "display": "flex", "flexWrap": "wrap", "gap": "20px" }} >
                {props?.pendingList && props?.pendingList?.students?.length > 0 && props?.pendingList && props?.pendingList?.students?.map((data) => (
                  <>
                    <StudentCards
                      key={data?.requestSentUser?.id}
                      profile={data?.requestSentUser?.avatarPath}
                      name={data?.requestSentUser?.fullName}
                      country={data?.requestSentUser?.country}
                      school={data?.requestSentUser?.studentProfile?.currrentSchoolName}
                      unweightgpa={data?.requestSentUser?.studentProfile?.targetUnweighted}
                      weightgpa={data?.requestSentUser?.studentProfile?.targetWeighted}
                      universities={data?.requestSentUser?.studentProfile?.universities?.sort((a, b) => { return a.length - b.length })}
                      professions={data?.requestSentUser?.studentProfile?.professions?.sort((a, b) => { return a.length - b.length })}
                      connectionstatus={data?.status}
                      courseAndActivities={data?.courses?.concat(data?.activities)}
                      message={data?.requestInfo?.message}
                    >
                      <div className='Studentcard__footer' style={{ "margin-top": "10px", "padding": "10px 30px" }} >
                        <div className=''>
                          <Button className='ExploreButton w-100' onClick={() => handleViewProfile({ id: data?.requestSentUser?.id })}>View Profile</Button>
                        </div>
                        <div className='Studentcard__footer__bottom'>
                          <Button className='RejectButton w-100' onClick={(e) => RejectSubmit(e, data, "Student")}>Reject</Button>
                          <Button className='ExploreButton w-100' onClick={(e) => ConfirmationSubmit(e, data, "Student")} >Accept</Button>
                        </div>
                      </div>
                    </StudentCards>
                  </>
                ))}
              </div>
            </>
          ) : (
            <>
              <div className='zeroStateReceived' style={{ "marginTop": "100px", "display": "flex", "justifyContent": "center", "marginInline": 'auto' }} >
                <div className="Plan_Card_Empty_Cnt">
                  <div className="Empty_Card" style={{ padding: '80px' }}>
                    <p>You haven't received any requests from student</p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div >
      ) :
        <div className="emptyState, zeroStateReceived" style={{ "marginTop": "100px", "display": "flex", "justifyContent": "center" }} >
          <div className="Plan_Card_Empty_Cnt">
            <div className="Empty_Card" style={{ padding: '80px' }}>
              <p className='mb-0'>You have not received any requests yet</p>
            </div>
          </div>
        </div>
      }
    </>
  )
}

const mapStateToProps = ({ commonData, userProfileData }) => {
  const { pendingList, pendingRequestStatus, isRequestRespond, parentRequest } = commonData;
  const { userProfile, } = userProfileData;

  return { pendingList, pendingRequestStatus, isRequestRespond, userProfile, parentRequest };
};
export default connect(mapStateToProps, { getPendingRequests, getPendingRequestsParent,getNetworkUserCount, sendRespondToRequest, sendRespondToParentRequest, setPendingRequestStatus, setSendRespondToRequestStatus, respondToParentRequestStatus })(Index);
