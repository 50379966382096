import React from 'react';
import "../../../assets/css/common.less";

const Index = (props) => {
  const isSelected = props?.isSelected;
  const isClickable = props?.isClickable !== undefined ? props.isClickable : true;

  const handleTagClick = () => {
    if (isClickable) {
      props.onTagClick(props.text);
    }
  };

  const tagStyles = {
    height: "40px",
    padding: '3px 10px',
    margin: '0 10px 10px 0',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: isSelected ? '#1C84EE' : '#E3E3E3',
    cursor: isClickable ? 'pointer' : 'not-allowed'
  };

  const tagTextStyles = {
    marginBottom: '0',
    fontSize: '13px',
    lineHeight: '22px',
    fontFamily: props.bold ? '@font--poppins-600' : '@font--poppins-300',
    color: isSelected ? 'white' : (isClickable ? '#000000' : '#7A7A7A')
  };

  return (
    <>
      <div className={`tag__tags-chip ${isSelected ? 'tag__tags-chip--clicked' : ''}`} style={tagStyles} onClick={handleTagClick} >
        <p className={`Chips tag__tags-chip__tag-text${props.bold ? "-bold" : "-light"}`} style={tagTextStyles}>
          {props.text}
        </p>
      </div>
    </>
  );
};

export default Index;
