import React, { useEffect, useState } from "react";
import "../../../../../assets/css/New_Profile.less"
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
// import ProfileImage from "../../../../../assets/images/icons/user.png"
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { disconnectUser, ConnectParent, clearMessage, revokeInvite, revokeInviteStatus, revokeInviteError, getUserProfile } from "../../../../../redux/actions";
import { Modal, Box, Stack, Grid, } from '@mui/material';
import Loader from "../../../../../components/Loader";
import ConfirmationPopup from "../../../../../componentsNew/Common/ConfirmationPopup";
import { useImageUrl } from "../../../../../utils/UseImageURL";
const style = { position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", bgcolor: "background.paper", boxShadow: 24, p: 4, };


const Index = (props) => {

    const [parentEmails, setParentEmails] = useState([])
    const [showmodal, setShowmodal] = useState(false)
    const [userName, setUsername] = useState("")
    const [userImage, setUserImage] = useState("")
    const [UserId, setUserId] = useState()
    const [loading, setloading] = useState(false)
    const [profile, setprofile] = useState([])
    const [showCancelModal, setShowCancelModal] = useState(false)

    useEffect(() => {
        if (props?.userProfile?.studentProfile?.parentEmails?.length > 0) {
            let res = props?.userProfile?.studentProfile?.parentEmails?.filter(checkIfExists)
            setParentEmails(res)
        }
        if (props?.userProfile?.studentProfile?.parentEmails?.length === 0) {
            setParentEmails([])
        }
    }, [props?.userProfile])

    useEffect(() => {
        if (props.isDisconnectUser === true) {
            props.ConnectParent();
            setloading(false);
            clearMessage();
        }
        if (props?.invitationRevoke) {
            setloading(false)
            handleShowCancelModal()
            props?.revokeInviteStatus(false)
            props?.getUserProfile()
        }
        if (props?.invitationRevokeError) {
            setloading(false)
            handleShowCancelModal()
            props?.getUserProfile()
            props?.revokeInviteError(false)
        }
    }, [props.isDisconnectUser, props?.invitationRevoke, props?.invitationRevokeError])


    const checkIfExists = (email) => {
        let emailExists = false;
        props?.connectedparent?.forEach((data) => {
            if (data.email === email) {
                emailExists = true;
            }
        });
        if (!emailExists) {
            return email;
        }
    }
    const disconnectParent = (e) => {
        e.preventDefault()
        let payload = {
            userId: UserId,
        };
        props.disconnectUser(payload)
        handleClose()
        setloading(true)
    }

    const handleOpen = () => {
        setShowmodal(true)
    }
    const handleClose = () => {
        setUsername("");
        setUserImage("")
        setUserId()
        setShowmodal(false)
    }
    const setdata = (e, data) => {
        e.preventDefault()
        setUsername(data?.firstName ? data?.firstName : data?.email);
        setUserImage(data && data?.avatarPath)
        setUserId(data && data?.id)
        handleOpen()
    }
    const handleCancelRequest = (data) => {
        setprofile(data)
        setShowCancelModal(true);
    }
    const handleShowCancelModal = () => {
        setShowCancelModal(false);
        setprofile({})
    }
    const handleRevokeInvite = () => {
        if (profile) {
            let payload = {
                "email": profile
            }
            props?.revokeInvite(payload)
            setloading(true)
        }
    }
    const AvatarWithUrl = ({ avatarUrl }) => {
        const imageUrl = useImageUrl(avatarUrl);
        if (imageUrl) {
            return <img src={imageUrl} alt="profileimage" className="profileimage" loading="lazy" style={{"width":"70px","height":"70px"}} />;
        }
    };
    const imageUrl = useImageUrl(userImage);
    return (
        <>
            {showCancelModal &&
                <ConfirmationPopup show={showCancelModal} handleSubmit={() => handleRevokeInvite()} message={`Please confirm that you would like to revoke this connection request for this parent`} btnText="confirm" onHide={() => handleShowCancelModal(false)} />
            }
            {loading && <Loader />}
            <div className="Connected_Account_Page">
                <div className="Connected_Account_Card">
                    <div className="Connected_Account_Card_Top">
                        <p className="Connected_Account_Card_Title">Connected Parents</p>
                    </div>
                    <div className="Connected_Account_Card_Mid">
                        <>
                            {props?.connectedparent && props?.connectedparent?.length > 0 ? (
                                <>
                                    {props?.connectedparent && props?.connectedparent?.map((data) => (
                                        <div className="Connected_Parent_Card">
                                            <div className="Connected_Parent_Profile">
                                                {data?.avatarPath ?
                                                    <AvatarWithUrl avatarUrl={data?.avatarPath}  />

                                                    :
                                                    <AccountBoxIcon sx={{ fontSize: '4rem', fill: 'grey' }} />
                                                }
                                            </div>
                                            <div className="Connected_Parent_Name_Cnt">
                                                <p className="Connected_Parent_Name">{data?.fullName ? data?.fullName : data?.email}</p>
                                            </div>
                                            <div className="Connected_Parent_Disconnect_Cnt">
                                                <Button className="Disconnect_Btn" onClick={(e) => setdata(e, data)} >Disconnect</Button>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <>
                                    <div className="Empty_Connected_Account_Card">
                                        <div className="Empty_Connected_Account_Card_Top">
                                            <p className="Empty_Connected_Account_Card_Title">You haven’t connected with Parent</p>
                                        </div>
                                        <div className="Empty_Connected_Account_Card_Mid">
                                            <p className="Empty_Connected_Account_Card_SubTitle">Invite your parent to link with your account</p>
                                        </div>
                                    </div>
                                </>
                            )}
                            {parentEmails && parentEmails?.length > 0 &&
                                <>
                                    <div className="Invite_Connected_Account_Card_Top">
                                        <p className="Invite_Connected_Account_Card_Title">Invited Parent</p>
                                    </div>
                                    {parentEmails?.map((data) => (
                                        <div className="Connected_Parent_Card">
                                            <div className="Connected_Parent_Profile">
                                                {/* <img src={ProfileImage} alt="profileimage" className="profileimage" /> */}
                                                <AccountBoxIcon sx={{ fontSize: '4rem', fill: 'grey' }} />

                                            </div>
                                            <div className="Connected_Parent_Name_Cnt">
                                                <p className="Connected_Parent_Name">{data}</p>
                                            </div>
                                            <div className="Connected_Parent_Disconnect_Cnt">
                                                <Button className="Disconnect_Btn" onClick={() => handleCancelRequest(data)}>Revoke</Button>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            }
                        </>
                    </div>
                </div>
            </div >
            <Modal open={showmodal} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style} className="bulkheight">
                    <form>
                        <div className="row m-0 mb-2">
                            <div className="col-md-12 col-sm-12 col-12 p-0">
                                <div style={{ "display": "flex", "justifyContent": "center", "textAlign": "center" }}>
                                    <p className="ModalTitle" >{userName} will be no longer be able to see your work. Please Confirm !</p>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 col-12 p-0">
                                <div style={{ "display": "flex", "justifyContent": "center" }}>
                                    {imageUrl ?
                                        <img src={imageUrl} alt="profileimage" className="profileimage" style={{"width":"80px","height":"80px"}} />
                                        :
                                        <AccountBoxIcon sx={{ fontSize: '7rem', fill: 'grey' }} />
                                    }
                                </div>
                            </div>
                        </div>
                        <Grid container className="justifyContent popupConfirm">
                            <Stack direction="row" className="flex-end btns--pay-stack" spacing={2}>
                                <Button variant="outlined" className="outLineBtn" onClick={handleClose} >No</Button>
                                <Button className="btn cta--rounded cta-primary" style={{ "borderRadius": "5px" }} onClick={(e) => disconnectParent(e)} >Yes</Button>
                            </Stack>
                        </Grid>
                    </form>
                </Box>
            </Modal>
        </>
    );
}
const mapStateToProps = ({ studentData, userProfileData, commonData }) => {
    const { connectedparent } = studentData;
    const { invitationRevoke, invitationRevokeError } = commonData
    const { userProfile, isDisconnectUser } = userProfileData;
    return { connectedparent, userProfile, isDisconnectUser, invitationRevoke, invitationRevokeError };
};
export default connect(mapStateToProps, { disconnectUser, ConnectParent, clearMessage, revokeInvite, getUserProfile, revokeInviteStatus, revokeInviteError })(Index);
