import React, { useState, useEffect, useRef } from "react";
import "../../../../assets/css/New_Profile.less";
import { Grid, Box, Button } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
const validation = Yup.object({

});

const Index = (props) => {
    const { register, handleSubmit, watch, control, formState: { errors } } = useForm({ resolver: yupResolver(validation), });
    const onSubmit = data => console.log(data);
    return (
        <>
            <div className="Billing_Card">
                <p className="Billing_Card_Title">My Cards</p>
                <div className="Debit_Card_Container">
                    <div className="Debit_Card">
                        <div className="Debit_Card_Number_Cnt">
                            <p className="cardnumber">**** {" "}****{" "}****{" "}<span>1121</span></p>
                        </div>
                        <div className="Debit_Card_Expiry_Cnt">
                            <div className="row">
                                <div className="col-5">
                                    <p className="Expire">EXPIRE</p>
                                    <p className="carddate">01/25</p>
                                </div>
                                <div className="col-7">
                                    <div className="edit_row">
                                        <p className="Edit_btn">EDIT</p>
                                        <p className="Delete_btn">DELETE</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          
        </>
    );
}
export default Index;