import React from 'react'
import "./milestoneCard.less"
import { datevalidator } from '../../../../../utils/CommonUtils';

const Index = ({ children, status, type, course, date, menu,activity, milestoneName }) => {
    const convertedDateRange = datevalidator(date);
    return (
        <div className={`myWork-milestoneCard`}>
            <div className="myWork-milestoneCard__topSec">
                <div className="myWork-milestoneCard__topSec__titleSec">
                    <span>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 13V5.5C6 5.2643 6 5.14645 6.07322 5.07322C6.14645 5 6.2643 5 6.5 5H10.382C10.6827 5 10.833 5 10.9538 5.07467C11.0747 5.14935 11.1419 5.28383 11.2764 5.55279L11.7236 6.44721C11.8581 6.71617 11.9253 6.85065 12.0462 6.92533C12.167 7 12.3173 7 12.618 7H17.5C17.7357 7 17.8536 7 17.9268 7.07322C18 7.14645 18 7.2643 18 7.5V14.5C18 14.7357 18 14.8536 17.9268 14.9268C17.8536 15 17.7357 15 17.5 15H12.618C12.3173 15 12.167 15 12.0462 14.9253C11.9253 14.8507 11.8581 14.7162 11.7236 14.4472L11.2764 13.5528C11.1419 13.2838 11.0747 13.1493 10.9538 13.0747C10.833 13 10.6827 13 10.382 13H6ZM6 13V19" stroke="white" stroke-linecap="round" />
                        </svg>
                    </span>
                    <p className='myWork-milestoneCard__topSec__titleSec__title'>{milestoneName}</p>
                    <span>
                        {status === "In progress" &&
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.8334 13.75L9.16669 17.4167L12.8334 21.0833" stroke="#DEEEFF" stroke-width="2" />
                                <path d="M16.557 7.79134C17.1202 8.7668 17.4167 9.87331 17.4167 10.9997C17.4167 12.126 17.1202 13.2326 16.557 14.208C15.9938 15.1835 15.1838 15.9935 14.2084 16.5567C13.2329 17.1198 12.1264 17.4163 11 17.4163" stroke="#DEEEFF" stroke-width="2" stroke-linecap="round" />
                                <path d="M9.16665 8.25L12.8333 4.58333L9.16665 0.916667" stroke="#DEEEFF" stroke-width="2" />
                                <path d="M5.44298 14.2087C4.8798 13.2332 4.58331 12.1267 4.58331 11.0003C4.58331 9.87397 4.8798 8.76745 5.44298 7.79199C6.00616 6.81654 6.81619 6.00651 7.79165 5.44333C8.7671 4.88015 9.87362 4.58366 11 4.58366" stroke="#DEEEFF" stroke-width="2" stroke-linecap="round" />
                            </svg>
                        }
                        {status === "Completed" &&
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11 19.25C15.5563 19.25 19.25 15.5563 19.25 11C19.25 6.44365 15.5563 2.75 11 2.75C6.44365 2.75 2.75 6.44365 2.75 11C2.75 15.5563 6.44365 19.25 11 19.25ZM15.4349 8.89018C15.7885 8.46591 15.7311 7.83534 15.3069 7.48178C14.8826 7.12821 14.252 7.18554 13.8984 7.60982L10.7172 11.4274C10.3704 11.8434 10.1755 12.0738 10.0199 12.2153L10.014 12.2207L10.0076 12.2159C9.83976 12.089 9.62479 11.8772 9.24183 11.4943L8.04044 10.2929C7.64992 9.90237 7.01675 9.90237 6.62623 10.2929C6.2357 10.6834 6.2357 11.3166 6.62623 11.7071L7.82762 12.9085L7.86844 12.9493L7.86846 12.9493C8.19495 13.276 8.50903 13.5902 8.80127 13.8111C9.12864 14.0587 9.55735 14.2915 10.1068 14.2665C10.6562 14.2416 11.0621 13.971 11.3657 13.6948C11.6367 13.4483 11.9211 13.1069 12.2166 12.7521L12.2536 12.7077L15.4349 8.89018Z" fill="#E5FFE1" />
                            </svg>
                        }
                        {status === "Not Started" &&
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="22" height="22" fill="#1C84EE" />
                                <circle cx="11" cy="11" r="7.25" stroke="white" stroke-width="2" />
                                <path d="M4.58331 2.56987C3.74721 3.05259 3.0529 3.7469 2.57017 4.58301" stroke="white" stroke-width="2" stroke-linecap="round" />
                                <path d="M17.4167 2.56987C18.2528 3.05259 18.9471 3.7469 19.4298 4.58301" stroke="white" stroke-width="2" stroke-linecap="round" />
                                <path d="M8.25 8.25H13.3879C13.5215 8.25 13.5884 8.41157 13.4939 8.50607L8.50607 13.4939C8.41157 13.5884 8.4785 13.75 8.61213 13.75H13.75" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        }
                    </span>
                </div>
            </div>
            <div className="myWork-milestoneCard__statusSec">
                <div className={`myWork-milestoneCard__statusSec${status === 'In Progress' ? '__inprogress' : status === 'Completed' ? '__completed' : '__notstarted'}`}>
                    <p className={`myWork-milestoneCard__statusSec${status === 'In Progress' ? '__inprogressText' : status === 'Completed' ? '__completedText' : '__notstartedText'}`}>{status}</p>
                </div>
                {menu &&
                    <div className="myWork-milestoneCard__topSec__menuSec">
                        {menu}
                    </div>
                }
            </div>
            <div className="myWork-milestoneCard__midSec">
                <div className="myWork-milestoneCard__midSec__content">
                    <p className="myWork-milestoneCard__midSec__content__label">Type</p>
                    <p className="myWork-milestoneCard__midSec__content__value">{type}</p>
                </div>
                {course &&
                    <div className="myWork-milestoneCard__midSec__content">
                        <p className="myWork-milestoneCard__midSec__content__label">Course</p>
                        <p className="myWork-milestoneCard__midSec__content__value">{course}</p>
                    </div>
                }
                {activity &&
                    <div className="myWork-milestoneCard__midSec__content">
                        <p className="myWork-milestoneCard__midSec__content__label">Activity</p>
                        <p className="myWork-milestoneCard__midSec__content__value">{activity}</p>
                    </div>
                }
                <div className="myWork-milestoneCard__midSec__timecontent">
                    <p className="myWork-milestoneCard__midSec__timecontent__label">{convertedDateRange}</p>
                </div>
            </div>
            <div className="myWork-milestoneCard__footersec">
                {children}
            </div>
        </div>
    )
}

export default Index