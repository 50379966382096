import React, { Component } from "react";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,ResponsiveContainer } from 'recharts';

class Barchart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data : [
                {
                  name: "01",
                  sep: 4000,
                  nov: 2400,
                  amnt: "$"
                },
                {
                  name: "05",
                  sep: "800",
                  nov: "800",
                  amnt: "$"
                },
                {
                  name: "10",
                  sep: 2000,
                  nov: 9800,
                  amnt: "$"
                },
                {
                  name: "15",
                  sep: 2780,
                  nov: 3908,
                  amnt: "$"
                },
                {
                  name: "20",
                  sep: 1890,
                  nov: 4800,
                  amnt: "$"
                },
                {
                  name: "25",
                  sep: 2390,
                  nov: 3800,
                  amnt: "$"
                },
              ]
        };
    }
    render() {
        let {data} = this.state
        const CustomTooltip = ({ active, payload, label }) => {
          if (active && payload && payload.length) {
            return (
              <div className="custom-tooltip">
                <p className="label"></p>
                <p className="label"><span><div className="colorbar"></div>{`${"$"} ${payload[0].value}`}</span></p>
              </div>
            );
          }
        
          return null;
        };
        

        return (
            <>
            <ResponsiveContainer width={"100%"} height={200}>
                <BarChart
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}>
                    <CartesianGrid strokeDasharray="20 20" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip cursor={false} content={<CustomTooltip />} />
                    {/* <Legend /> */}
                    <Bar dataKey="sep" fill="#1C84EE" radius={[2, 2, 0, 0]} barSize={30} />
                    <Bar dataKey="nov" fill="#959595" radius={[2, 2, 0, 0]} barSize={30} />
                </BarChart>
                </ResponsiveContainer>
            </>
        );
    }
}

export default Barchart;