import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../../assets/css/profile.less";

const Index = () => {
    const [show, setShow] = useState(true);
    let navigate = useNavigate();

    const handleClose = () => {
        navigate("../notfound");
        setShow(false);
    };

   
    const handleSubmit = () => {
        const data = new Response(JSON.stringify("Cookie Accepted"));
        if ('caches' in window) {
            caches.open("acceptCookies").then((cache) => {
                cache.put("/acceptCookies", data );
                setShow(false);
              });
        }
    };
    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Body>
                    This site uses cookies to enhance the site's performance and
                    to provide the most relevant information
                </Modal.Body>
                <Modal.Footer>
                    <div className="btnCookieClose m-0" onClick={handleClose}>
                        Close
                    </div>
                    <Button
                        className="modalBtnAccept ms-3"
                        onClick={handleSubmit}
                    >
                        Accept
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Index;
