import React, { useState, useEffect } from "react";
import "./profile.less"
import { Button, Modal, Typography, Box, Stack, Grid, TextField, Autocomplete, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { connect } from "react-redux";
import { deleteuser } from "../../../redux/actions";
import AccountBoxIcon from '@mui/icons-material/AccountBox';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,

};

const Index = (props) => {

  const handleClose = () => {
    props?.handleClose();
  };

  const handleClickedYes = () => {
    props?.handleDisconnectUser(props?.data)
    props?.handleClose()
  };


  return (
    <Modal
      open={props?.show}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="disconnectUser">
        <div className="row m-0">
          <div className="col-md-12 col-sm-12 col-12 p-0">
            
            <p className="profileAboutCard__title mb-1">Are you sure you want to Disconnect ?</p>

            <div className="textCenter">
              {props?.data?.avatarUrl ?
                <img src={props?.data && props?.data?.avatarUrl} alt="profileimage" className="profileimage" style={{ width: '112px', height: '112px', padding: '13px' }} />
                :
                <AccountBoxIcon sx={{ fontSize: '4rem', fill: 'grey' }} />
              }
              <p>{props?.data?.firstName || props?.data?.email}</p>
            </div>
          </div>
        </div>
        <Grid container className="justifyContent popupConfirm">
          <Stack
            direction="row"
            className="flex-end btns--pay-stack"
            spacing={2}
          >
            <Button variant="outlined" onClick={handleClose} className="outLineBtn" >No</Button>
            <Button className="btn cta--rounded cta-primary" style={{ "borderRadius": "5px", textTransform : 'capitalize' }} onClick={handleClickedYes}  >Yes</Button>
          </Stack>
        </Grid>

      </Box>
    </Modal>
  );
}
const mapStateToProps = ({ userProfileData, commonData }) => {
  const { userProfile, isProfileSuccess, childprofile, parentProfile } = userProfileData;
  const { status, gender, country, graduationyear, schools } = commonData;
  return { userProfile, isProfileSuccess, status, gender, schools, country, childprofile, parentProfile, graduationyear };
};

export default connect(mapStateToProps, { deleteuser })(Index);
