import React, { useState, useEffect } from "react";
import { Modal, Box, Grid, Button } from '@mui/material';
import { connect } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import "./BookSessionModal.less";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Select, { components } from "react-select";
import moment from "moment";
import Tags from '../SelectableTags'
import CustomAlert from "../../../components/CustomAlert"

const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder}</Placeholder>
            {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
        </ValueContainer>
    );
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 380,
    maxWidth: '100%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '5px',
    overflow: 'scroll',
};


const Index = (props) => {

    const handleClose = () => {
        props?.Close()
    };

    const onSubmit = () => {
        props?.ConfirmSubmit(props.Subscribe)
    }

    return (
        <>
            <Modal open={props?.show} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <div className="BookSessionModal">
                        <div className="BookSessionModal_Top">
                            <div className="row">
                                <div className="col-11">
                                    <p className="Modaltitle">Subscribe</p>
                                </div>
                                <div className="col-1">
                                    <CloseIcon className="closebtn" onClick={handleClose} />
                                </div>
                            </div>

                        </div>
                        <div className="BookSessionModal_Mid">
                            <div className="BookSessionModal_Mid_Cnt">
                                <p className="BookSessionModal_Mid_Title">Are you sure want to Subscribe <span>{props?.Subscribe?.packageName}</span> with Price <span>{props?.Subscribe?.priceCurrency} {props?.Subscribe?.price}</span></p>
                            </div>
                            <div className="BookSessionModal_footer">
                            <Button variant="contained" className="Bookclose" onClick={handleClose}>No</Button>
                                <Button variant="contained" className="BookBtn"  onClick={onSubmit}>Yes</Button>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
};

const mapStateToProps = ({ commonData, studentData }) => {
    const { } = commonData;
    const { } = studentData;
    return {};
};

export default connect(mapStateToProps, {})(Index);
