import React, { useState, useEffect, useCallback } from "react";
import { generatePresignedUrl } from "./S3-Util";

export const useImageUrl = (avatarPath) => {
  const [imageUrl, setImageUrl] = useState(null);

  const fetchImageUrl = useCallback(async () => {
    if (!avatarPath) {
      return;
    }

    try {
      const url = await generatePresignedUrl(avatarPath);
      setImageUrl(url);
    } catch (error) {
      console.error('Error generating presigned URL:', error);
    }
  }, [avatarPath, generatePresignedUrl]);

  useEffect(() => {
    fetchImageUrl();
  }, [avatarPath, fetchImageUrl]);

  return imageUrl;
};