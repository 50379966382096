import React, { useCallback, useEffect, useRef, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import RecomendedStudents from "../../../../../componentsNew/Common/Cards/Advisor/RecomendedStudents";
import ViewStudentProfile from '../../../../../componentsNew/Common/ViewStudentProfile';
import { saveUserProfile, unSaveUser, resetDetailedUserProfileStatus, advisorSaveStatus, setExploreStudentsByUniversityStatus, 
    setExploreStudentsByProfessionStatus, setExploreStudentsByCourseStatus, setExploreStudentsByActivityStatus,
    getExploreStudentsBySection, setSavedUserProfileStatus, setExploreStudentsBySectionStatus, genericSearchStudent, setGenericSearchStudentStatus,

    fetchStudentsBySection, genericSearchStudents, genericSearchStudentsBySection,
    setAllDatasLoaded, fetchData } from "../../../../../redux/actions";
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import { connect, useDispatch, useSelector } from 'react-redux';

const Index = (props) => {
    const [showProfile, setShowProfile] = useState(false);
    const [profileData, setProfileData] = useState({});
    const [lastViewedIndex, setLastViewedIndex] = useState(0);
    const [loading, setLoading] = useState(false);

    const { loadUsers, data, allDatasLoaded, genericSearchData } = useSelector(state => state?.networkData);
    let ref = useRef(0);
    let pageNoRef = useRef(props?.sectionDetails?.pageNo);
    const dispatch = useDispatch();
    
    useEffect(() => {
        if(!allDatasLoaded && loading ){
            setLoading(false)
            dispatch(setAllDatasLoaded(true))
        }
    }, [allDatasLoaded, loadUsers])

    const exploreUsersState = () => {
        let fetchedData = data?.find(section => {
            return ((section?.section == props?.sectionDetails?.section) && (section?.sectionValue == props?.sectionDetails?.sectionValue) && (section?.sectionIndex == props?.sectionDetails?.sectionIndex))
        })
        return props?.sectionDetails?.total > fetchedData?.users?.length
    }
    
    const exploreGenericUserState = () => {
        let fetchedData = genericSearchData?.[0]
        return props?.sectionDetails?.total > fetchedData?.users?.length
    }

    const options = {
        nav: true,
        navElement: ["ChevronLeftOutlinedIcon ", "ChevronRightIcon "],
        autoplay: false,
        slideBy: 1,
        dots: false,
        dotsEach: false,
        dotData: true,
        loop: false,
        lazyLoadEager: 3,
        startPosition: ref.current,
        onChanged: (event) => {
            if(allDatasLoaded && (loadUsers == false)) {
                if (event.item.index) {
                    ref.current = event.item.index;
                    setLastViewedIndex(event?.item?.index)
                }
                // if ((props?.sectionDetails?.total > 10) 
                //     && ((props?.loadStudentBySection === false))
                //     && (props?.sectionDetails?.users?.length < props?.sectionDetails?.total) 
                //     && (exploreUsersState[props?.sectionDetails?.section])
                //     && (event?.item?.index == (event.item?.count - 5))
                // ) {
                //     setLoading(true);
                //     setPageNo(props?.sectionDetails?.pageNo + 1)
                // }
                // let ddddd= data?.find(section => (section?.section == props?.sectionDetails?.section) && (section?.sectionValue == props?.sectionDetails?.sectionValue) && (section?.sectionIndex == props?.sectionDetails?.sectionIndex))
                if (props?.sectionDetails?.section === "Generic") {
                    if (!loadUsers && props?.sectionDetails?.total > 10 &&  exploreGenericUserState() && (event?.item?.index == (event.item?.count - 5)) ) {
                        let payload = {
                            limit: 10,
                            searchParams: {
                                searchString: "",
                                sectionSearch: []
                            },
                            sectionIndex: props?.sectionDetails?.sectionIndex,
                            studentId: "",
                            totalSection: 2
                        }
                        payload["pageNo"] = props?.sectionDetails?.pageNo + 1;
                        setLoading(true)
                        if (pageNoRef?.current  != payload["pageNo"]) {
                            dispatch(fetchData());
                            if(props?.sectionDetails?.searchString) {
                                props?.genericSearchStudentsBySection(props?.sectionDetails?.searchString, payload)
                            }
                        }
                        pageNoRef.current = payload["pageNo"]
                    }
                } else {
                    if (!loadUsers && props?.sectionDetails?.total > 10 &&  exploreUsersState() && (event?.item?.index == (event.item?.count - 5)) ) {
                        let payload = {
                            limit: 10,
                            // pageNo: 0,
                            total: props?.sectionDetails?.total,
                            searchParams: {
                                searchString: "",
                                sectionSearch: props?.filterData?.filter(data => data?.values)
                            },
                            section: props?.sectionDetails?.section,
                            sectionIndex: props?.sectionDetails?.sectionIndex,
                            sectionValue: props?.sectionDetails?.sectionValue,
                            studentId: null,
                            totalSection: props?.sectionDetails?.totalSection
                        }
                        payload["pageNo"] = props?.sectionDetails?.pageNo + 1;
                        setLoading(true)
                        if (pageNoRef?.current  != payload["pageNo"]) {
                            dispatch(fetchData());
                            props?.fetchStudentsBySection(payload);
                        }
                        pageNoRef.current = payload["pageNo"]
                    }
                }
            }
        },
        responsive: props?.reponsive || {
            1: { items: 1 },
            320: { items: 1 },
            700: { items: 2 },
            769: { items: 1 },
            900: { items: 2 },
            1230: { items: 3 },
            1600: { items: 4 },
            1900: { items: 5 },
            2300: { items: 6 }
        },
    };

    const showProfileModal = (status) => {
        if(!status) {
            setProfileData({})
            props?.resetDetailedUserProfileStatus();
        }
        setShowProfile(status)
    }
    const handleViewProfile = (userInfo) => {
        setProfileData(userInfo);
        showProfileModal(true);
    }
    const saveUserProfile = (userId) => {
        let payload = {
            userId: userId
        }
        props?.bookmarkUser(true, payload);
    }
    const unSaveUserProfile = (userId) => {
        let payload = {
            userId: userId
        }
        props?.bookmarkUser(false, payload);
    }

    let owlCarouselData = props?.data?.map( element => {
        return (
            <div className="owl__item" key={element?.id}>
                <a>
                    <RecomendedStudents
                        name = {element?.fullName}
                        country = {element?.country}
                        avatarUrl = {element?.avatarPath}
                        school = {element?.studentProfile?.currrentSchoolName}
                        gpa = {element?.studentProfile?.desiredGpa}
                        universities = {element?.studentProfile?.universities?.sort((a, b) => {return a.length - b.length} )}
                        professions = {element?.studentProfile?.professions?.sort((a, b) => {return a.length - b.length})}
                        matchParam = {element?.matchingParams?.[0]}
                        courseAndActivities = {element?.studentProfile?.courses?.concat(element?.studentProfile?.activities)}
                        createPlan = {element?.studentProfile?.helpWithCoursesAndActivities}
                        helpWithMilestones = {element?.studentProfile?.helpWithMilestones}
                        saveProfileIcon = {props?.loadSavedUsers?.map(user => user?.id)?.includes(element?.id) ? 
                            <BookmarkOutlinedIcon onClick= {() => unSaveUserProfile(element?.id)}/> 
                        :  
                            <BookmarkBorderOutlinedIcon onClick= {() => saveUserProfile(element?.id)}/>
                        }
                    >
                        <button className="recommendedStudentCard__buttonSec__primary-btn" onClick={() => handleViewProfile(element)}>
                            View Profile
                        </button>
                    </RecomendedStudents>
                </a>
            </div>
        )
    })

    return (
        <>
            {/* {loading && <Loader />}/// */}
            <OwlCarousel
                className="owl owl-OwlCarousel owl-theme"
                {...options} >
                    {owlCarouselData}
            </OwlCarousel>
            {showProfile &&
                <ViewStudentProfile show={showProfile} profileData={profileData} handleClose={() => showProfileModal(false)}/>
            }
        </>
    )
}

const mapStateToProps = ({ commonData, exploreData }) => {
    const { message, errorList, loadSavedUsers, loadSavedUserStatus, genericSearchStudentData, genericSearchStudentStatus } = commonData;
    const { loadUniversityStudent, loadProfessionStudent, loadCoursesStudent, loadActivitiesStudent, 
        exploreUniversityStudentData, exploreProfessionStudentData, exploreCoursesStudentData, exploreActivitiesStudentData, loadStudentBySection } = exploreData
    return {  message, errorList, loadSavedUsers, loadSavedUserStatus, loadUniversityStudent, genericSearchStudentStatus,
        loadProfessionStudent, loadCoursesStudent, loadActivitiesStudent, exploreUniversityStudentData, genericSearchStudentData,
        exploreProfessionStudentData, exploreCoursesStudentData, exploreActivitiesStudentData, loadStudentBySection };
  };
export default connect(mapStateToProps, { saveUserProfile, unSaveUser, resetDetailedUserProfileStatus, advisorSaveStatus, 
    setExploreStudentsByUniversityStatus, setExploreStudentsByProfessionStatus, setExploreStudentsByCourseStatus, 
    setExploreStudentsByActivityStatus, getExploreStudentsBySection, setSavedUserProfileStatus, setExploreStudentsBySectionStatus,
    genericSearchStudent, setGenericSearchStudentStatus, 

    fetchStudentsBySection, genericSearchStudents, genericSearchStudentsBySection})(Index);