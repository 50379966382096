import React, { useState, useEffect } from "react";
import "../../../../../assets/css/New_Profile.less";
import { Button, Tooltip, IconButton } from "@mui/material";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { connect, useSelector } from "react-redux";
import Loader from "../../../../../components/Loader";
import { useComponentDidMount, useComponentDidUpdate } from "../../../../../utils/useEffectHooks";
import { updateUserProfile, UserProfileErrorStatus, clearErrorList } from "../../../../../redux/actions";

const Index = (props) => {
    const [Collaborating, setCollaborating] = useState(false);
    const [counseling, setcounseling] = useState(false);
    const [subcounseling, setsubcounseling] = useState(false);
    const [loading, setloading] = useState(false)
    const [helpwith, setHelpWith] = useState(false);
    const [error, setError] = useState("")

    const handleCheckboxChange = (e, buttonName) => {
        setError("")
        props.clearErrorList()
        if (buttonName === "studentsCollab") {
            InterestsApi("studentsCollab", e.target.checked)
        } else if (buttonName === "counselingAllowed") {
            InterestsApi("helpWithCoursesAndActivities", e.target.checked)
        } else if (buttonName === "subCouncelingServices") {
            InterestsApi("setsubcounseling", e.target.checked)
        }
        else if (buttonName === "helpwith") {
            console.log(e.target.checked, "e.target.checked")
            InterestsApi("helpWithMilestones", e.target.checked)
        }
    }
    const InterestsApi = (name, value) => {
        let collab = Collaborating;
        let couns = counseling;
        let subcouns = subcounseling;
        let helpwithMile = helpwith
        if (name === "studentsCollab") {
            collab = value
        }
        else if (name === "helpWithCoursesAndActivities") {
            couns = value
        }
        else if (name === "setsubcounseling") {
            subcouns = value
        }
        else if (name === "helpWithMilestones") {
            helpwithMile = value
        }
        if (props?.userProfile !== undefined) {
            let UserProfile = props?.userProfile
            let StudentProfile = props?.userProfile?.studentProfile
            let value = {
                firstName: UserProfile?.firstName,
                lastName: UserProfile?.lastName,
                email: UserProfile?.email,
                graduationYear: StudentProfile?.graduationYear,
                zipCode: UserProfile?.zipCode,
                currentGpa: StudentProfile?.currentGpa,
                gender: UserProfile?.gender,
                status: StudentProfile?.myStatus,
                country: UserProfile?.country,
                highSchoolName: StudentProfile?.currrentSchoolName,
                studentsCollab: collab,
                counselingAllowed: false,
                subCouncelingServices: subcouns,
                helpWithMilestones: helpwithMile,
                helpWithCoursesAndActivities: couns,
            }
            props.updateUserProfile(value)
            setloading(true)
        }
    }
    useComponentDidMount(() => {
        setCollaborating(props?.userProfile?.studentProfile?.studentsCollab)
        setcounseling(props?.userProfile?.studentProfile?.helpWithCoursesAndActivities)
        setsubcounseling(props?.userProfile?.studentProfile?.subCouncellingServices)
        setHelpWith(props?.userProfile?.studentProfile?.helpWithMilestones)
    }, []);

    useComponentDidUpdate(() => {
        setCollaborating(props?.userProfile?.studentProfile?.studentsCollab)
        setcounseling(props?.userProfile?.studentProfile?.helpWithCoursesAndActivities)
        setsubcounseling(props?.userProfile?.studentProfile?.subCouncellingServices)
        setHelpWith(props?.userProfile?.studentProfile?.helpWithMilestones)
        setloading(false)

        if (props.isProfileSuccessError === true) {
            setloading(false)
            props.UserProfileErrorStatus(false)
        }
        if (props.isProfileSuccess === true) {
            setloading(false)
        }

    }, [props?.userProfile, props?.userProfile?.studentProfile, props.isProfileSuccessError, props.isProfileSuccess]);

    useEffect(()=>{
        if(props?.userProfile?.studentProfile){
            let UserProfile = props?.userProfile?.studentProfile
            if(!UserProfile?.studentsCollab && !UserProfile?.helpWithCoursesAndActivities && !UserProfile?.subCouncellingServices && !UserProfile?.helpWithMilestones){
                setError("*Required")
            }
        }
    },[props?.userProfile])

    return (
        <>
            {loading && <Loader />}
            <div className="Profile_Interest_Card">
                <p className="InterestTitle" style={{}} >I need help with</p>
                <div>
                    <FormControlLabel control={<Checkbox onClick={(e) => handleCheckboxChange(e, 'studentsCollab')} checked={Collaborating === true ? true : false} />} label="Collaborating with students with similar goals" id="checkbox1" />
                    <FormControlLabel control={<Checkbox onClick={(e) => handleCheckboxChange(e, 'counselingAllowed')} checked={counseling === true ? true : false} />} label="Selecting courses/activities to meet my goals" id="checkbox2" />
                    <FormControlLabel control={<Checkbox onClick={(e) => handleCheckboxChange(e, 'helpwith')} checked={helpwith === true ? true : false} id="checkbox3" />}
                        label={
                            <React.Fragment>
                                Help with course/activity milestones through school year
                                <Tooltip title="Assignments, homework, test prep etc" placement="right">
                                    <IconButton>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.00008 7.16699C8.27622 7.16699 8.50008 7.39085 8.50008 7.66699V11.0003C8.50008 11.2765 8.27622 11.5003 8.00008 11.5003C7.72394 11.5003 7.50008 11.2765 7.50008 11.0003V7.66699C7.50008 7.39085 7.72394 7.16699 8.00008 7.16699Z" fill="#919293" />
                                            <path d="M8.00008 6.00033C8.36827 6.00033 8.66675 5.70185 8.66675 5.33366C8.66675 4.96547 8.36827 4.66699 8.00008 4.66699C7.63189 4.66699 7.33341 4.96547 7.33341 5.33366C7.33341 5.70185 7.63189 6.00033 8.00008 6.00033Z" fill="#919293" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M2.16675 8.00033C2.16675 4.77866 4.77842 2.16699 8.00008 2.16699C11.2217 2.16699 13.8334 4.77866 13.8334 8.00033C13.8334 11.222 11.2217 13.8337 8.00008 13.8337C4.77842 13.8337 2.16675 11.222 2.16675 8.00033ZM8.00008 3.16699C5.3307 3.16699 3.16675 5.33095 3.16675 8.00033C3.16675 10.6697 5.3307 12.8337 8.00008 12.8337C10.6695 12.8337 12.8334 10.6697 12.8334 8.00033C12.8334 5.33095 10.6695 3.16699 8.00008 3.16699Z" fill="#919293" />
                                        </svg>
                                    </IconButton>
                                </Tooltip>
                            </React.Fragment>
                        }
                    />
                    <FormControlLabel control={<Checkbox onClick={(e) => handleCheckboxChange(e, 'subCouncelingServices')} checked={subcounseling === true ? true : false} />} label="Professional counseling services" id="checkbox4"/>
                    <div className="error-text" style={{ "position": "absolute" }} >{error}</div>
                </div>
            </div>
        </>
    );
}
const mapStateToProps = ({ commonData, userProfileData }) => {
    const { message, errorList, status, gender, country, loading, schools, graduationyear } = commonData
    const { isProfileSuccess, userProfile, isProfileSuccessError } = userProfileData;
    return { message, errorList, status, gender, isProfileSuccess, userProfile, isProfileSuccessError, country, loading, schools, graduationyear };
};
export default connect(mapStateToProps, { updateUserProfile, UserProfileErrorStatus, clearErrorList })(Index);
