import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../../assets/css/login.less";
import { withRouter } from "../../../redux/store/navigate";
import {clearErrorList,acceptuserSignUpstatus, userSignIn, verifyEmail, userSignUp, googleLogin, facebookLogin, forgetPassword, verifyPassword, clearMessage } from "../../../redux/actions";
import { connect } from "react-redux";
import { LoginSocialGoogle, LoginSocialFacebook } from 'reactjs-social-login';
import Loader from "../../../components/Loader";
import Alert from "../../../components/Alert";
import CookiePrompt from "../../../components/CookiePrompt";
import { CONFIRM_PASSWORD_LABEL, EMAIL_LABEL, PASSWORD_LABEL } from "../../../Constants/commonLabels";

const IDENTIFIER = process.env.REACT_APP_IDENTIFIER;
const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
const GOOGLE_APP_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            dataLoaded: false,
            alertBox: false,
            cookiePopup: false,
            fields: { email: '', password: '', 'confirm-password': '' },
            errors: {},
            isVerification: false,
            errorList: []
        };
        this.handleValidation = this.handleValidation.bind(this)
    }

    componentDidMount() {
        this.props.clearMessage();
        this.props.clearErrorList();
        this.setState({ errorList: [], isVerification: false, loading:true })
        this.getSingleCacheData()
    }

    componentDidUpdate() {
        if(!this.state.dataLoaded){
            if(!this.props.isNewRegister){
                this.setState({loading : false, dataLoaded:true})
            }
        }
        // if(this.props.message){
        //     this.props.navigate('/');
        // }
        if ( this.props.userProfile && this.props.userProfile?.isOnboarded !== undefined && !this.props.userProfile?.profileInfo?.isOnboarded) {
            this.props.navigate('/onboarding/roles');
        }
    }

    componentWillReceiveProps(newProps){
        this.setState({
            errorList: newProps.errorList,
        });
    }

    componentWillUnmount() {
        this.props.clearMessage()
    }

    handleChange(field, e) {
        this.props.clearErrorList();
        let { errors } = this.state;
        let fields = this.state.fields;
        fields[field] = e.target.value;
        if (e.target.value.length >= 0) {
            errors[field] = "";
        }
        this.setState({ fields, errors: errors });
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (fields["email"] !== "") {
            // const textcaseRegExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
            // const emailvalid = textcaseRegExp.test(fields["email"]);
            // if (!emailvalid) {
            //     formIsValid = false;
            //     errors["email"] = "Email is invalid";
            // }
            let lastAtPos = fields["email"].lastIndexOf("@");
            let lastDotPos = fields["email"].lastIndexOf(".");
            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    fields["email"].indexOf("@@") === -1 &&
                    lastDotPos > 2 &&
                    fields["email"].length - lastDotPos > 2
                )
            ) {
                formIsValid = false;
                errors["email"] = "Not a valid email";
            }
        }

        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Email cannot be empty";
        }

        if (typeof fields["password"] !== "undefined") {
            const uppercaseRegExp = /(?=.*?[A-Z])/;
            const lowercaseRegExp = /(?=.*?[a-z])/;
            const digitsRegExp = /(?=.*?[0-9])/;
            const specialCharRegExp = /(?=.*?[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/;
            const minLengthRegExp = /.{6,}/;

            const uppercasePassword = uppercaseRegExp.test(fields["password"]);
            const digitsPassword = digitsRegExp.test(fields["password"]);
            const specialCharPassword = specialCharRegExp.test(fields["password"]);
            const lowercasePassword = lowercaseRegExp.test(fields["password"]);
            const minLengthPassword = minLengthRegExp.test(fields["password"]);

            if (!uppercasePassword) {
                formIsValid = false;
                errors["password"] = "Password requires atleast 1 uppercase";
            } else if (!lowercasePassword) {
                formIsValid = false;
                errors["password"] = "Password requires atleast 1 lowercase";
            } else if (!digitsPassword) {
                formIsValid = false;
                errors["password"] = "Password requires atleast 1 digit";
            } else if (!specialCharPassword) {
                formIsValid = false;
                errors["password"] = "Password requires 1 Special character";
            } else if (!minLengthPassword) {
                formIsValid = false;
                errors["password"] = "Password requires atleast 6 or more character";
            }
        }

        if (!fields["password"]) {
            formIsValid = false;
            errors["password"] = "Password cannot be empty";
        }

        if (fields["confirm-password"] != fields["password"]) {
            formIsValid = false;
            errors["confirm-password"] = "Mismatch in confirm password with password";
        }

        if (!fields["confirm-password"]) {
            formIsValid = false;
            errors["confirm-password"] = "Confirm password cannot be empty";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    getSingleCacheData = async () => {
        if (typeof caches === 'undefined') return false;
        const cacheStorage = await caches?.open('acceptCookies');
        const cachedResponse = await cacheStorage.match('/acceptCookies');
        if (!cachedResponse || !cachedResponse.ok) {
            console.log('Fetched failed!');
            this.setState({cookiePopup : true})
        }
      };

    handleSubmit(e) {
        e.preventDefault();
        this.setState({alertBox:true})
        let fields = this.state.fields;
        if (this.handleValidation()) {
            let values = {
                email: fields["email"],
                password: fields["password"],
                confirmPassword: fields["password"],
                identifier: IDENTIFIER
            }
            this.props.userSignUp(values)
            this.setState({ isVerification: true })
        } else {
            e.preventDefault();
        }
    };
    
    render() {
        let { errorList } = this.props;
        if (this.props.isNewRegister) {
            return (
                <>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="login">
                                    <div className="Verification_card">
                                        <h1 className="login_title text-center">Verify your email</h1>
                                        <p className="login_desc text-center">Please verify your email by opening the link we have sent you!</p>
                                        <Form className="login_card_form mb-5 mt-5 text-center" autoComplete="off">
                                            <Link to="/" onClick={() => this.props.acceptuserSignUpstatus(false)}>
                                                <Button className="btn cta--rounded cta-primary cta-default" >
                                                    <span>OK</span>
                                                </Button>
                                            </Link>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
        else {
            return (
                <>
                {this.state.alertBox && <Alert /> }
                {this.state.loading && <Loader />}
                {this.state.cookiePopup && <CookiePrompt/>}
	  	        {this.state.loading || this.props.loading && <Loader />}

                    <div className="login">
                        <div className="login_card">
                            <h1 className="login_title text-center">Sign Up</h1>
                            <div>
                                <div className="row">
                                    <div className="col-6" style={{ "display": "flex", "justifyContent": "end" }}>
                                        <div className="pointer">
                                            <LoginSocialGoogle
                                                scope="https://www.googleapis.com/auth/userinfo.email"
                                                client_id={GOOGLE_APP_ID || ''}
                                                onLoginStart={this.onLoginStart}
                                                onResolve={(provider, data) => {
                                                    if (provider && provider.data.access_token) {
                                                        let googleToken = {
                                                            identifier: IDENTIFIER,
                                                            token: provider.data.access_token,
                                                            // attemptingFrom: "register"
                                                        }
                                                        this.setState({alertBox:true})
                                                        this.props.googleLogin(googleToken)
                                                    }
                                                }}
                                                onReject={(err) => {
                                                    console.log(err)
                                                }} >
                                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="0.5" y="0.5" width="47" height="47" rx="9.5" fill="white" />
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M35.04 24.2605C35.04 23.445 34.9668 22.6609 34.8309 21.9082H24V26.3566H30.1891C29.9225 27.7941 29.1123 29.0121 27.8943 29.8275V32.713H31.6109C33.7855 30.7109 35.04 27.7627 35.04 24.2605Z" fill="#4285F4" />
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M23.9995 35.5005C27.1045 35.5005 29.7077 34.4708 31.6104 32.7144L27.8938 29.829C26.864 30.519 25.5467 30.9267 23.9995 30.9267C21.0042 30.9267 18.469 28.9037 17.5647 26.1855H13.7227V29.1651C15.6149 32.9235 19.504 35.5005 23.9995 35.5005Z" fill="#34A853" />
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M17.5652 26.1855C17.3352 25.4955 17.2045 24.7584 17.2045 24.0005C17.2045 23.2425 17.3352 22.5055 17.5652 21.8155V18.8359H13.7232C12.9443 20.3884 12.5 22.1448 12.5 24.0005C12.5 25.8562 12.9443 27.6125 13.7232 29.165L17.5652 26.1855Z" fill="#FBBC05" />
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M23.9995 17.0739C25.6879 17.0739 27.2038 17.6541 28.3956 18.7936L31.694 15.4952C29.7024 13.6395 27.0992 12.5 23.9995 12.5C19.504 12.5 15.6149 15.077 13.7227 18.8355L17.5647 21.815C18.469 19.0968 21.0042 17.0739 23.9995 17.0739Z" fill="#EA4335" />
                                                    <rect x="0.5" y="0.5" width="47" height="47" rx="9.5" stroke="#EBEBEB" />
                                                </svg>
                                            </LoginSocialGoogle>
                                        </div>
                                    </div>
                                    <div className="col-6"  style={{ "display": "flex", "justifyContent": "start"}}>
                                        <div className="pointer">
                                            <LoginSocialFacebook
                                                appId={FACEBOOK_APP_ID || ''}
                                                onLoginStart={this.onLoginStart}
                                                onResolve={(provider, data) => {
                                                    if (provider && provider.data.accessToken) {
                                                        let facebokkToken = {
                                                            identifier: IDENTIFIER,
                                                            token: provider.data.accessToken,
                                                            // attemptingFrom: "register"
                                                        }
                                                        this.setState({alertBox:true})
                                                        this.props.facebookLogin(facebokkToken)
                                                    }
                                                }}
                                                onReject={(err) => {
                                                    console.log(err)
                                                }}>
                                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="0.5" y="0.5" width="47" height="47" rx="9.5" fill="white" />
                                                    <rect width="24" height="24" transform="translate(12 12)" fill="white" />
                                                    <path d="M35.5 24.0694C35.5 17.7181 30.3513 12.5694 24 12.5694C17.6487 12.5694 12.5 17.7181 12.5 24.0694C12.5 29.8094 16.7054 34.567 22.2031 35.4297V27.3936H19.2832V24.0694H22.2031V21.5358C22.2031 18.6536 23.92 17.0616 26.5468 17.0616C27.805 17.0616 29.1211 17.2862 29.1211 17.2862V20.1163H27.671C26.2424 20.1163 25.7969 21.0027 25.7969 21.9122V24.0694H28.9863L28.4765 27.3936H25.7969V35.4297C31.2946 34.567 35.5 29.8094 35.5 24.0694Z" fill="#1877F2" />
                                                    <rect x="0.5" y="0.5" width="47" height="47" rx="9.5" stroke="#EBEBEB" />
                                                </svg>
                                            </LoginSocialFacebook>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className="or"><span>or</span></p>
                            <Form className="login_card_form mb-5" autoComplete="off" onSubmit={this.handleSubmit.bind(this)}>
                                <div className="input-floating-label">
                                    <input refs="email" type="text" name="email" placeholder="Email" className={this.state.errors["email"] ? "textbox--primary textbox--rounded input w-100 error-input" : "textbox--primary textbox--rounded input"}
                                        onChange={this.handleChange.bind(this, "email")} value={this.state.fields["email"]} />
                                    <label >{EMAIL_LABEL}</label>
                                    {this.state.errors["email"] && <span className="error-text">{this.state.errors["email"]}</span>}
                                    {console.log("error",errorList)}
                                    {errorList && errorList.email && <span className="error-text">{errorList.email}</span>}
                                </div>
                                <div className="input-floating-label">
                                    <input refs="password" type="password" name="password" placeholder="password" className={this.state.errors["password"] ? "textbox--primary textbox--rounded input w-100 error-input" : "textbox--primary textbox--rounded input"}
                                        onChange={this.handleChange.bind(this, "password")} value={this.state.fields["password"]} />
                                    <p className="cta--text mb-1" style={{ "fontSize": "13px", "marginTop": "10px","letterSpacing":"0.7px" }}>Must contain 1 uppercase, 1 lowercase, 1 digit, 1 special character & least 6 or more character</p>
                                    <label>{PASSWORD_LABEL}</label>
                                    {this.state.errors["password"] && <span className="error-text">{this.state.errors["password"]}</span>}
                                    {errorList && errorList.password && <span className="error-text">{errorList.password}</span>}
                                </div>
                                <div className="input-floating-label">
                                    <input refs="confirm-password" type="password" name="confirm-password" placeholder="Confirm Password" className={this.state.errors["confirm-password"] ? "textbox--primary textbox--rounded input error-input" : "textbox--primary textbox--rounded input"}
                                        onChange={this.handleChange.bind(this, "confirm-password")} value={this.state.fields["confirm-password"]} />
                                    <label >{CONFIRM_PASSWORD_LABEL}</label>
                                    {this.state.errors["confirm-password"] && <span className="error-text">{this.state.errors["confirm-password"]}</span>}
                                </div>

                                <Button type="submit" className="btn cta--rounded cta-primary w-100" >
                                    <span>Continue</span>
                                </Button>
                            </Form>
                            <p className="cta cta--center login_text">Already have an account? <Link className="cta--underline" to="/">Sign In</Link></p>
                        </div>
                    </div>
                </>
            );
        }
    }
}

const mapStateToProps = ({ auth, commonData,userProfileData }) => {
    const { message, errorList, isRegistered,  isNewPassword, isverifyPasswordstatus, loading } = commonData
    const {  isLoginError, isEmailVerified, isEmailConfirm, isNewRegister,} = auth;
    const { userProfile } = userProfileData;
    return { isRegistered, message, errorList, isNewRegister, isNewPassword, isverifyPasswordstatus, isLoginError, isEmailVerified, isEmailConfirm, loading,userProfile }
};

export default connect(mapStateToProps, {clearErrorList,userSignIn, verifyEmail, forgetPassword, verifyPassword, userSignUp, googleLogin, facebookLogin, clearMessage,acceptuserSignUpstatus })(withRouter(Index));