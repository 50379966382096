import React, { useEffect, useState } from 'react';
import AdvisorCards from "./AdvisorCards";
import StudentCards from "./StudentCards";
import { Button } from '@mui/material';
import { connect } from "react-redux";
import ViewStudentProfile from "../../../../componentsNew/Common/ViewStudentProfile";
import AdvisorProfile from "../../../../componentsNew/Common/advisorProfile";
import { useNavigate } from 'react-router-dom';
import { CancelRequest, CancelRequestStatus, getSentRequests, getSentRequestsParent } from "../../../../redux/actions";
import Reasonpopup from "./ReasonPopup";
import ConfirmationPopup from "../../../../componentsNew/Common/ConfirmationPopup";


const Index = (props) => {
  let navigate = useNavigate();
  const [showProfile, setShowProfile] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [profilePopup, setProfiePopup] = useState(false);
  const [reasonpopup, setReasonpopup] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [cancelRequest, setCancelRequest] = useState({});
  const [message, setMessage] = useState("");


  useEffect(() => {

    if (props?.cancelrequest) {
      props?.CancelRequestStatus(false);
      setShowCancelModal(false);
      if (props?.userProfile?.currentRole === "parent") {
        props?.getSentRequestsParent(localStorage.getItem("parentViaChild"))
      } else {
        props?.getSentRequests();
      }
    }
    if (props?.errorcancelrequest) {
      props?.CancelRequestStatus(false);
    }
  }, [props?.cancelrequest, props?.errorcancelrequest])

  const handleViewProfile = (data) => {
    setProfileData(data);
    showProfileModal(true);
  }
  const showProfileModal = (status) => {
    if (!status) {
      setProfileData({})
    }
    setShowProfile(status)
  }

  const profileShow = (data) => {
    let Payload = {
      userInfo: data?.requestReceivedUser
    }
    setProfileData(Payload)
    setProfiePopup(true);

  }
  const profileHide = () => setProfiePopup(false);

  const HandleNavigate = (e, data) => {
    e.preventDefault();
    if (data === "Student") {
      navigate('/student/mynetwork/exploreStudent')
    }
    if (data === "Advisor") {
      navigate('/student/mynetwork/exploreAdvisor')
    }
  }

  const handleCancelRequest = (data) => {
    let payload = {
      requestId: cancelRequest?.requestInfo?.id,
    }
    if (props?.userProfile?.currentRole === "parent") {
      payload["studentId"] = localStorage.getItem("parentViaChild")
    }
    props?.CancelRequest(payload);
  }


  const handleShowCancelModal = (status, data) => {
    if (data?.requestReceivedUser?.currentRole === "advisor") {
      setMessage("Please confirm that you would like to revoke this connection request for this Advisor")
    }
    if (data?.requestReceivedUser?.currentRole === "student") {
      setMessage("Please confirm that you would like to revoke this connection request for this student")
    }
    setShowCancelModal(status);
    setCancelRequest(data);
  }

  const handleReason = (data) => {
    setProfileData(data)
    setReasonpopup(true)
  }
  const closehandlereason = () => {
    setProfileData({})
    setReasonpopup(false)
  }
  const screenSize = window.innerWidth;

  const getRecommendedAdvisors = (data) => {

  }

  return (
    <>
      {reasonpopup && <Reasonpopup show={reasonpopup} data={profileData} onHide={closehandlereason} />}
      {showProfile &&
        <ViewStudentProfile show={showProfile} profileData={profileData} handleClose={() => showProfileModal(false)} />
      }
      {(showCancelModal && message) &&
        <ConfirmationPopup show={showCancelModal} handleSubmit={() => handleCancelRequest()} message={message} btnText="confirm" onHide={() => handleShowCancelModal(false)} />
      }
      {profilePopup &&
        <AdvisorProfile show={profilePopup} handleCallApi={(data) => getRecommendedAdvisors(data)} detailedInfo={props?.detailedAdvisorData} data={profileData} handleClose={profileHide} />
      }
      <div className='SentRequest mt-4'>
        <div className='RecievedRequest__Title'>
          <p>You have sent connection requests to these advisors</p>
        </div>
        {props?.sendedRequestList && props?.sendedRequestList?.advisors?.length > 0 ? (
          <>
            {props?.sendedRequestList && props?.sendedRequestList?.advisors?.length > 0 &&
              <div style={{ "display": "flex", "flexWrap": "wrap", "gap": "20px" }} >
                {props?.sendedRequestList && props?.sendedRequestList?.advisors?.length > 0 && props?.sendedRequestList && props?.sendedRequestList?.advisors?.map((data) => (
                  <AdvisorCards data={data} key={data.id} >
                    <div className='SentRequest__AdvisorFooter'>
                      <div className='mt-3'>
                        {data?.requestInfo?.status === "pending" &&
                          <>
                            <Button className='RejectButton w-100 mb-3' onClick={(e) => handleShowCancelModal(true, data, "advisor")}>Cancel Request</Button>
                            <Button className='ExploreButton w-100' onClick={(e) => profileShow(data)}>View Profile</Button>
                          </>
                        }
                        {data?.requestInfo?.status === "accepted" &&
                          <>
                            <Button className='ExploreButton w-100' onClick={(e) => profileShow(data)}>View Profile</Button>
                          </>
                        }
                        {data?.requestInfo?.status === "rejected" &&
                          <>
                            <Button className='ExploreButton w-100 mb-3' onClick={() => handleReason(data)}>View Reason</Button>
                            <Button className='ExploreButton w-100' onClick={(e) => profileShow(data)}>View Profile</Button>
                          </>
                        }
                      </div>
                    </div>
                  </AdvisorCards>
                ))}
              </div>
            }
          </>
        ) :
          <>
            <div className='Empty__Container'>
              <div className='Backdrop__container__card'>
                <div className='title_cnt'>
                  <p>Let’s get you connected with some advisors who can help you achieve your goals !</p>
                </div>
                <div className='btn_cnt'>
                  <p onClick={(e) => HandleNavigate(e, "Advisor")}>
                    <span>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="11" cy="11" r="6" stroke="#222222" />
                        <path d="M11 8C10.606 8 10.2159 8.0776 9.85195 8.22836C9.48797 8.37913 9.15726 8.6001 8.87868 8.87868C8.6001 9.15726 8.37913 9.48797 8.22836 9.85195C8.0776 10.2159 8 10.606 8 11" stroke="#222222" stroke-linecap="round" />
                        <path d="M20 20L17 17" stroke="#222222" stroke-linecap="round" />
                      </svg>
                    </span>
                    Explore Advisors
                  </p>
                </div>
              </div>
            </div>
          </>
        }
        <div>
          <div className='RecievedRequest__Title mt-5'>
            <p>You have sent connection requests to these students</p>
          </div>
          {props?.sendedRequestList && props?.sendedRequestList?.students?.length > 0 ? (
            <>
              {props?.sendedRequestList && props?.sendedRequestList?.students?.length > 0 &&
                <div className='' style={{ "display": "flex", "flexWrap": "wrap", "gap": "20px" }} >
                  {props?.sendedRequestList && props?.sendedRequestList?.students?.length > 0 && props?.sendedRequestList && props?.sendedRequestList?.students?.map((data) => (
                    <>
                      <div>
                        <StudentCards key={data?.requestReceivedUser?.id}
                          name={data?.requestReceivedUser?.fullName}
                          country={data?.requestReceivedUser?.country}
                          school={data?.requestReceivedUser?.studentProfile?.currrentSchoolName}
                          gpa={data?.requestReceivedUser?.studentProfile?.desiredGpa}
                          universities={data?.requestReceivedUser?.studentProfile?.universities?.sort((a, b) => { return a.length - b.length })}
                          professions={data?.requestReceivedUser?.studentProfile?.professions?.sort((a, b) => { return a.length - b.length })}
                          connectionstatus={data?.requestInfo?.status}
                          avatarUrl={data?.requestReceivedUser?.avatarPath}
                          message={data?.requestInfo?.message}
                          unweightgpa={data?.requestReceivedUser?.studentProfile?.targetUnweighted}
                          weightgpa={data?.requestReceivedUser?.studentProfile?.targetWeighted}
                          courses={data?.courses}
                          activities={data?.activities}
                        >
                          <div className='advisord-card-footer myNetwork '>
                            <div className='advisord-card-footer__accept mt-2'>
                              {data?.requestInfo?.status === "pending" &&
                                <>
                                  <Button className='RejectButton w-100 mb-3' onClick={() => handleShowCancelModal(true, data)} >Cancel Request</Button>
                                  <Button className='ExploreButton w-100' onClick={() => handleViewProfile({ id: data?.requestReceivedUser?.id })} >View Profile</Button>
                                </>
                              }
                              {data?.requestInfo?.status === "accepted" &&
                                <>
                                  <Button className='ExploreButton ' onClick={() => handleViewProfile({ id: data?.requestReceivedUser?.id })} >View Profile</Button>
                                </>
                              }
                              {data?.requestInfo?.status === "rejected" &&
                                <>
                                  <Button className='ExploreButton w-100 mb-3' onClick={(data) => handleReason(data)} >View Reason</Button>
                                  <Button className='ExploreButton w-100' onClick={() => handleViewProfile({ id: data?.requestReceivedUser?.id })} >View Profile</Button>
                                </>
                              }
                            </div>
                          </div>
                        </StudentCards>
                      </div>
                    </>
                  ))}
                </div>
              }
            </>
          ) :
            <div className='Empty__Container'>
              <div className='Backdrop__container__card'>
                <div className='title_cnt'>
                  <p>Let’s get you connected with some students who can help you achieve your goals !</p>
                </div>
                <div className='btn_cnt'>
                  <p onClick={(e) => HandleNavigate(e, "Student")}>
                    <span>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="11" cy="11" r="6" stroke="#222222" />
                        <path d="M11 8C10.606 8 10.2159 8.0776 9.85195 8.22836C9.48797 8.37913 9.15726 8.6001 8.87868 8.87868C8.6001 9.15726 8.37913 9.48797 8.22836 9.85195C8.0776 10.2159 8 10.606 8 11" stroke="#222222" stroke-linecap="round" />
                        <path d="M20 20L17 17" stroke="#222222" stroke-linecap="round" />
                      </svg>
                    </span>
                    Explore Students
                  </p>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({ commonData, userProfileData }) => {
  const { sendedRequestList, cancelrequest, errorcancelrequest } = commonData;
  const { userProfile } = userProfileData;
  return { sendedRequestList, cancelrequest, errorcancelrequest, userProfile };
};
export default connect(mapStateToProps, { CancelRequest, CancelRequestStatus, getSentRequests, getSentRequestsParent })(Index);

