import React from 'react'
import "./connectedStudents.less";
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import StarIcon from '@mui/icons-material/Star';
import PersonIcon from '@mui/icons-material/Person';
import PillSection from "../../../PillSection"
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import { Grid } from '@mui/material';
import { useImageUrl } from '../../../../../utils/UseImageURL';

const Index = (props) => {
	let { name, country, school, universities, professions, courseAndActivities, children, connectionstatus, avatarUrl, message } = props

	const advisorActivities = props?.activities || [];
	const advisorCourses = props?.courses || [];

	const combinedData = [...advisorActivities, ...advisorCourses];

	const mappedData = combinedData.map(item => {
		if (item) {
			return item;
		}
		return null;
	});
	const imgUrl = useImageUrl(avatarUrl)
	return (
		<div className={`connectedStudentCard ${props?.connectionstatus === "pending" ? 'connectedStudentCard__Pending' : ''}
		${props?.connectionstatus === "accepted" ? 'connectedStudentCard__Accepted' : ''}
		${props?.connectionstatus === "rejected" ? 'connectedStudentCard__Rejected' : ''}`} >
			<div className="connectedStudentCard__topSec">
				{avatarUrl ?
					<div className='connectedStudentCard__topSec__avatarImage'>
						<img className="connectedStudentCard__topSec__avatarImage--img"
							src={imgUrl} alt="userprofile"
						/>
					</div>
					:
					<PersonIcon className="connectedStudentCard__topSec__userIcon" color="action" />
				}
				<div className='connectedStudentCard__topSec__userInfo'>
					<span className='connectedStudentCard__topSec__userInfo__nameSec'>
						<p className='connectedStudentCard__topSec__userInfo__nameSec__primaryText'>{name}</p>
					</span>
					<p className='connectedStudentCard__topSec__userInfo__secondaryText'>{country}</p>
					<div className='PillStatus'>
						{connectionstatus === "pending" &&
							<div className='PillStatus__Pending'><p>Request Pending</p></div>
						}
						{connectionstatus === "accepted" &&
							<div className='PillStatus__Accepted'><p>Request Accepted</p></div>
						}
						{connectionstatus === "rejected" &&
							<div className='PillStatus__Rejected'><p>Request Rejected</p></div>
						}
					</div>
				</div>
			</div>
			<div className="connectedStudentCard__midSec">
				<div className="connectedStudentCard__midSec__educationSec">
					<SchoolOutlinedIcon className='connectedStudentCard__midSec__educationSec__schoolIcon' />
					<p className='connectedStudentCard__midSec__educationSec__schoolName'>{school}</p>
				</div>
				<div className="connectedStudentCard__midSec__goalsSec">
					{universities?.length > 0 &&
						<>
							<div className="connectedStudentCard__midSec__goalsSec__targetUniversitySec">
								<StarIcon className='connectedStudentCard__midSec__goalsSec__targetUniversitySec__starIcon' />
								<p className='connectedStudentCard__midSec__goalsSec__targetUniversitySec__label'> Aspiring to attend </p>
							</div>
							<PillSection data={universities || []} maxWidth={170} maxHeight={100} />
						</>
					}
					{professions?.length > 0 &&
						<>
							<div className="connectedStudentCard__midSec__goalsSec__targetUniversitySec">
								<StarIcon className='connectedStudentCard__midSec__goalsSec__targetUniversitySec__starIcon' />
								<p className='connectedStudentCard__midSec__goalsSec__targetUniversitySec__label'> Aspiring to become  </p>
							</div>
							<PillSection data={professions} maxWidth={170} maxHeight={100} />
						</>
					}
					{mappedData?.length > 0 &&
						<>
							<div className="connectedStudentCard__midSec__goalsSec__targetUniversitySec">
								<StarIcon className='connectedStudentCard__midSec__goalsSec__targetUniversitySec__starIcon' />
								<p className='connectedStudentCard__midSec__goalsSec__targetUniversitySec__label'>Courses & activities</p>
							</div>
							<PillSection data={mappedData} maxWidth={170} maxHeight={100} />
						</>
					}
					{props?.weightgpa && props?.unweightgpa &&
						<div className="connectedStudentCard__midSec__goalsSec__targetUniversitySec">
							<StarIcon className='connectedStudentCard__midSec__goalsSec__targetUniversitySec__starIcon' />
							<p className='connectedStudentCard__midSec__goalsSec__targetUniversitySec__label'>Aspiring to achieve unweighted {props?.weightgpa} and weighted {props?.unweightgpa} GPA</p>
						</div>
					}
				</div>
				{message !== "" &&
					<div className='advisor-card-content'>
						<p className="advisor-subText" >{message}</p>
					</div>
				}
			</div>
			<div className="connectedStudentCard__buttonSec">
				{children}
			</div>
		</div>
	)
}

export default Index