import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Sidebar from "../../componentsNew/Parent/parentSidebar";
import ChatBox from "../../components/ChatBox";
import UpcommingSession from "../../components/UpCommingSession";
import { Calendar } from "react-calendar";
import CalendarComp from "../../components/Calendar";
import { connect } from "react-redux";
import {
    getUserProfile, getUserRoles, getUniversities, getProfessions, getGender, getCountry,
    getStatus, clearMessage, getCourse, getCourses, getActivities, getActivity, getStudentGrades,
    getPhasetime, getInviteAdvisor, getGraduationyear, ConnectParent, getUserGoals, getPlanStatus, getnotification, getExploreAdvisorCount,
    listScheduledEvents, getChildProfile, getDetailedUserProfile, getGoalsFromStudent, getAdvisorCountUpdates, getConnectedAdvisorsParents,
    listSwitchEvent, currentCoursesDashboard, currentActivitiesDashboard
} from "../../redux/actions";
import { withRouter } from "../../redux/store/navigate";
import { Topbar } from "../../componentsNew/ParentViaChild/parentChildTopbar";
import { getUserData } from "../../utils/AuthUtils";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { grey } from "@mui/material/colors";
import "./layout.less";


export const DashboardLayout = (props) => {
    let userProfile = useSelector((state) => state.userProfileData);
    let navigate = useNavigate();
    const callAPIs = () => {
        const { getDetailedUserProfile } = props;
        if (localStorage.getItem("parentViaChild")) {
            let payload = {
                pointOfViewStudentId: localStorage.getItem("parentViaChild"),
                userId: localStorage.getItem("parentViaChild")
            };
            getDetailedUserProfile(payload);
            let getStudentId = {
                "studentId": localStorage.getItem("parentViaChild")
            }
            let currentDash = {
                "studentId": localStorage.getItem("parentViaChild"),
                "userId": props?.userProfile?.id
            }
            let studentId = localStorage.getItem("parentViaChild")

            props?.getGoalsFromStudent(getStudentId)
            props?.getAdvisorCountUpdates(studentId)
            props?.getConnectedAdvisorsParents(studentId)
            props.listSwitchEvent(studentId)
            props?.getExploreAdvisorCount(studentId)
            props?.currentCoursesDashboard(currentDash)
            props?.currentActivitiesDashboard(currentDash)
            
        }
    };
    useEffect(() => {

        if (getUserData('accessToken')) {
            axios.defaults.headers.common['Authorization'] = "Bearer " + getUserData('accessToken');
            props.getChildProfile()
            props.getUserProfile();

            let payload = {
                "pageNo": 1,
                "limit": 50
            }
            props.getnotification(payload)
            callAPIs()
        }
        window.scrollTo(0, 0);   // Scrolls to the top left corner

    }, [])

    useEffect(() => {
        if (props.userProfile && props.userProfile.isOnboarded !== undefined && !props.userProfile.isOnboarded) {
            props.navigate('/onboarding/roles');
        }

        // Check if the current role is "parent" before setting local storage for parent
        if (props.userProfile?.currentRole === "parent" && props?.getchildstatus && props?.childprofile?.length > 0) {
            if (!localStorage.getItem("parentViaChild")) {
                let ChildId = props?.childprofile[0]?.id;
                if (ChildId) {
                    localStorage.setItem("parentViaChild", ChildId);
                    localStorage.setItem("childName", props?.childprofile[0]?.fullName);
                    callAPIs();
                }
            }
        } else {
            // If the user is not a parent, ensure parent-related localStorage is not set or remove them
            localStorage.removeItem("parentViaChild");
            localStorage.removeItem("childName");
        }
    }, [props.userProfile, props?.getchildstatus, props?.childprofile]);

    // useEffect(() => {
    //     if (props.userProfile?.currentRole !== "parent") {
    //         localStorage?.clear()
    //         navigate("/");
    //     }
    // }, [props.userProfile]);

    console.log(props?.notificationdata, "noygdsdhj")

    return (
        <div id="dashboard-layout switchLayout">
            <Sidebar userProfile={props?.userProfile} notification={props?.notificationdata} childprofile={props?.childprofile}
                userImage={props?.userProfile} callAPIs={callAPIs} />
            <div className="center-wrap-switch">
                <div className=''>
                    <Topbar userProfile={props.userProfile?.firstName} name={localStorage.getItem("childName") || props?.childprofile && props?.childprofile[0]?.fullName} />
                </div>

            </div>
            <div className="center-wrap">{userProfile && <Outlet />}</div>
            <div className="right-wrap">
                <div className="minicnt">
                    <div class="" style={{ backgroundColor: "#F4F5F8", borderRadius: "1px solid #DBDBDB", width: '100%', height: '350px' }}></div>
                </div>
                <div className="upcomecnt">
                    <h5>Notifications</h5>
                    <UpcommingSession  notification={props?.notificationdata}/>
                </div>
            </div>
            <CalendarComp />
            <ChatBox />
        </div>
    );
};
const mapStateToProps = ({ userProfileData, commonData, studentData }) => {
    const { isUserOnboarded, userProfile, childprofile, getchildstatus } = userProfileData;
    const { notificationdata } = commonData;
    const { isgetusergoals, } = studentData;

    return { isUserOnboarded, userProfile, notificationdata, childprofile, isgetusergoals, getchildstatus }
};

export default connect(mapStateToProps, {
    getUserProfile, getUserRoles, getUniversities, getProfessions, getGender, getCountry,
    getStatus, clearMessage, getCourse, getCourses, getActivities, getActivity, getStudentGrades,
    getPhasetime, getInviteAdvisor, getGraduationyear, ConnectParent, getUserGoals, getPlanStatus, getnotification,
    listScheduledEvents, getChildProfile, getDetailedUserProfile, getGoalsFromStudent, getAdvisorCountUpdates, getConnectedAdvisorsParents,
    listSwitchEvent, getExploreAdvisorCount, currentCoursesDashboard, currentActivitiesDashboard
})(withRouter(DashboardLayout));