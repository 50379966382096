import React, { useState } from 'react';
import "../CourseCard/courseCard.less";
import Tooltip from '@mui/material/Tooltip';

const Index = ({ children, data, mileStoneCount, mileStoneUpdateCount, student }) => {
    const [viewMore, setViewMore] = useState(false)

    const stripHTMLTags = (html) => {
        const tempElement = document.createElement("div");
        tempElement.innerHTML = html;
        return tempElement.textContent || tempElement.innerText || "";
    };

    return (
        <div className="courseFullViewCard mb-2">
            <div className="courseFullViewCard__topSection">
                <p className="courseFullViewCard__topSection__title">{data?.activityName}</p>
                {children}
            </div>
            <div className='courseFullViewCard__midSections'>
                <div className='row'>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4'>
                        <p className='label' title={data?.activityType} >Type<span>{data?.activityType}</span></p>
                    </div>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4'>
                        {(student && data?.gradeAchieved) ? (
                            <p className='label'>Grade Achieved<span>{data?.gradeAchieved}</span></p>
                        ) : (
                            <>
                                {data?.planPhase?.title &&
                                    <p className='label' title={data?.planPhase?.title}>Semester<span>{data?.planPhase?.title}</span></p>
                                }
                            </>
                        )}
                    </div>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4'>
                        {(student && data?.teacherName) ? (
                            <Tooltip title={data?.teacherName} arrow slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [0, -14], }, },], }, }}>
                                <p className='label'>High School Teacher Name<span>{data?.teacherName}</span></p>
                            </Tooltip>
                        ) : (
                            <>
                                {mileStoneCount &&
                                    <p className='label'>{mileStoneCount && `${mileStoneCount} Milestones`}<span>{mileStoneUpdateCount && `${mileStoneUpdateCount} Milestones Updates`}</span></p>
                                }
                            </>
                        )}
                    </div>
                </div>
            </div>

            {data?.description &&
                <div className="courseFullViewCard__bottomSec">
                    <p className={`courseFullViewCard__bottomSec${viewMore ? '__description' : '__detailedDescription'}`} onClick={() => setViewMore(!viewMore)}>
                        {stripHTMLTags(data?.description)}
                    </p>
                </div>
            }
        </div>
    )
}

export default Index