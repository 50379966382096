import axios from 'axios'

const BASE_URL = process.env.REACT_APP_BASE_URL;


function universityApi(params) {
    const body = {
        searchString: params
    }
    return axios.post(BASE_URL + "/config-service/v1/master/universities", body)
}

function schoolApi(params) {
    const body = {
        searchString: params
    }
    return axios.post(BASE_URL + "/config-service/v1/master/schools", body)
}

const loadZipcode = async(params) => {
    return await axios.post(BASE_URL + "/config-service/v1/master/zip", params)
}

async function copyInvite(params) {
    return Promise.resolve(axios.post(BASE_URL + "/user-service/v1/user-invite/generateurl", params))
}

export { universityApi, schoolApi, copyInvite, loadZipcode }