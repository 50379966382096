import { getUserData } from './AuthUtils';
import moment from 'moment';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';

const getYesterdayObj = () => {
    let date = new Date();
    return new Date(date.setDate(date.getDate() - 1));
}

const getTodayObj = () => {
    return new Date();
}

const formatDatetime = (date) => {
    return date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : null;
}

const sortArrayAlphabetically = (data, key) => {
    let result = data.sort((a, b) => {
        let sa = a[key].toLowerCase(),
            sb = b[key].toLowerCase();
        if (sa < sb) {
            return -1;
        }
        if (sa > sb) {
            return 1;
        }
        return 0;
    });
    return result;
}

const findArrayElement = (array, key, value) => {
    return array.find(obj => obj[key] === value);
}

const isPositiveNumber = (value) => {
    if (isNaN(value)) return false;
    if (Number(value) <= 0) return false;
    if (Number.isInteger(Number(value)) <= 0) return false;
    return true;
}

const arrayRemove = (arr, value) => {
    return arr.filter((ele) => ele !== value);
}

const getKeyByValue = (obj, value) => Object.keys(obj).find(key => obj[key] === value);

const formatDate = (dateObj) => {
    if (!dateObj)
        return null
    return moment(dateObj).format('MM-DD-YYYY')
}

const getRouterPrefix = () => process.env.REACT_APP_ROUTER_PREFIX;

const isEmpty = (obj) => {
    let hasOwnProperty = Object.prototype.hasOwnProperty;
    if (obj == null) return true;
    if (obj === undefined) return true;
    if (obj.length > 0) return false;
    if (obj.length === 0) return true;
    if (typeof obj !== "object") return true;
    for (var key in obj) {
        if (hasOwnProperty.call(obj, key)) return false;
    }
    return true;
}

const range = (min, max) => [...Array(max - min + 1).keys()].map(i => i + min);

const isRecordEditable = isAdmin => {
    let userType = getUserData('userType')
    switch (userType) {
        case 'DS':
            return false;
        case 'DA':
            return isAdmin ? true : false;
        case 'DU':
            return isAdmin ? false : true;
        default:
            return false;
    }
}

const isMobileNumberValid = (value) => {
    if (value.match(/^\+(?:[0-9] ?){6,14}[0-9]$/) || value.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)) {
        return true;
    } else {
        return false;
    }
}

const getName = (array, char) => array.join(char)

const disableFutureDates = current => current > getYesterdayObj()

const convertToUTCString = dateString => {
    // dateString format: Mon Mar date year 07:00:00 GMT+0530 (India Standard Time) 
    let date = new Date(dateString);

    let utcYear = date.getUTCFullYear();
    let utcMonth = date.getUTCMonth() + 1;
    let utcDay = date.getUTCDate();
    let utcHours = date.getUTCHours();
    let utcMinutes = date.getUTCMinutes();
    let utcSeconds = date.getUTCSeconds();
    if (utcMonth < 10) utcMonth = '0' + utcMonth;
    if (utcDay < 10) utcDay = '0' + utcDay;
    if (utcHours < 10) utcHours = '0' + utcHours;
    if (utcMinutes < 10) utcMinutes = '0' + utcMinutes;
    if (utcSeconds < 10) utcSeconds = '0' + utcSeconds;

    let utcDateString = `${utcYear}-${utcMonth}-${utcDay} ${utcHours}:${utcMinutes}:${utcSeconds}`;
    return utcDateString;
}

const convertToUTCTimeformat = localTimeString => {
    const utcTimeString = localTimeString + 'Z';
    return utcTimeString;
}

const convertTo12hrFormat = (timestamp) => {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    return formattedHours + ':' + formattedMinutes + ' ' + period;
}

// utils/dateUtils.js
const getMonthName = (monthIndex) => {
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    return months[monthIndex - 1];
};

const datevalidator = (dateString) => {
    const [start, end] = dateString.split(' - ');
    const [startDay, startMonth, startYear] = start.split('-');
    const [endDay, endMonth, endYear] = end.split('-');

    return `${parseInt(startDay)} ${getMonthName(parseInt(startMonth))} ${startYear} - ${parseInt(endDay)} ${getMonthName(parseInt(endMonth))} ${endYear}`;
};

// convert UTCtime to Timezone
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const convertUtcToEventTime = (utcTime, timezone) => {
    let dateTime = utcTime?.toString()?.split(' GMT')?.[0];
    const utcDayjs = dayjs(dateTime);
    let time = utcDayjs.format('HH:mm:ss');
    const timedformat = dayjs?.utc(time, 'HH:mm:ss', true);
    const timezoneDayjs = timedformat.tz(timezone);
    return timezoneDayjs;
};
const convertUTCTodateTimeZone = (utcDateString, timeZone) => {
    const zonedDate = dayjs.utc(utcDateString).tz(timeZone);
    return zonedDate;
};

const GetCurrentTimezone = (timeZone) => {
    const UTC = dayjs().utc().format()
    const CurrentTimezone = convertUTCTodateTimeZone(UTC,timeZone)
    const formattedTime = CurrentTimezone.format('YYYY-MM-DDTHH:mm:ss')
    return formattedTime;
};

export {
    getTodayObj, getYesterdayObj, sortArrayAlphabetically, arrayRemove, findArrayElement, isPositiveNumber, getKeyByValue, formatDate, getRouterPrefix, convertUTCTodateTimeZone,GetCurrentTimezone,
    isEmpty, range, isRecordEditable, isMobileNumberValid, disableFutureDates, getName, convertToUTCString, convertToUTCTimeformat, convertTo12hrFormat, datevalidator, convertUtcToEventTime
};