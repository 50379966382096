import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-notifications/lib/notifications.css';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import { ApolloProvider } from '@apollo/client';
import client from "./utils/ApolloClient";

import OnboardingRoutes from "./routes/OnboardingRoutes";
import CommonRoutes from "./routes/CommonRoutes";
import StudentRoutes from "./routes/StudentRoutes";
import AdvisorRoutes from "./routes/AdvisorRoutes";
import ParentRoutes from "./routes/ParentRoutes"

import Notavailable from "./pages/Common/404"

import { Provider } from "react-redux";
import configureStore, { history } from "./redux/store";
import MessagingRoutes from "./routes/MessagingRoutes";

export const store = configureStore();
class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <ApolloProvider client={client}>
                    <BrowserRouter>
                        {/* Onboarding Routes */}
                        <OnboardingRoutes />
                        {/* Common Routes */}
                        <CommonRoutes />
                        {/* Student page Routes */}
                        <StudentRoutes />
                        {/* Advisor page Routes */}
                        <AdvisorRoutes />
                        {/* Parent page Routes */}
                        {<ParentRoutes />}
                        <MessagingRoutes />


                        {/* 404 */}
                        {/* <Routes>
                        <Route exact path="/"  >
                            <Route path="*" element={<Notavailable />} />
                        </Route>
                    </Routes> */}
                    </BrowserRouter>
                </ApolloProvider>
            </Provider>
        );
    }
}

export default App;