import React, { useEffect, useState } from 'react'
import "../../../../assets/css/NewNotification.less";
import { Button } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { viewednotification, getUserProfile } from "../../../../redux/actions";
import Alert from 'react-bootstrap/Alert';
import { convertToUTCTimeformat, convertUTCTodateTimeZone } from '../../../../utils/CommonUtils';


const Index = (props) => {
    const [displayToast, setdisplayToast] = useState(false);

    let navigate = useNavigate();

    const handleNavigate = (e, data) => {
        e.preventDefault();
        console.log("handleNavigate data",data);
        
        if (data.notificationType === 'plans') {
            console.log("data?.dataIds", data);
            if (data?.dataIds?.planId && data?.dataIds?.phaseId && data?.dataIds?.courseId) {
                navigate(`/student/mywork/plan/${data?.dataIds?.planId}/courseMilestone/${data?.dataIds?.courseId}`, { state: { courseId: data?.dataIds?.courseId, phaseId: data?.dataIds?.phaseId, PhaseData:data?.dataIds  } })
            }
            else if (data?.dataIds?.planId && data?.dataIds?.phaseId && data?.dataIds?.activityId) {
                navigate(`/student/mywork/plan/${data?.dataIds?.planId}/activityMilestone/${data?.dataIds?.activityId}`, { state: { activityId: data?.dataIds?.activityId, phaseId: data?.dataIds?.phaseId, PhaseData:data?.dataIds } })
            }
            else if (data?.dataIds?.planId ) {
                navigate(`/student/mywork/plan/${data?.dataIds?.planId}`)
            }
            else {
                navigate('/student/mywork')
            }
            let payload = {
                notificationIds: [data.id]
            }
            props?.viewednotification(payload)
        }
        else if (data.notificationType === 'network') {
            navigate('/student/mynetwork', { state: { type: "recieveRequest" } });
            let payload = {
                notificationIds: [data.id]
            }
            props?.viewednotification(payload);
        } else if (data.notificationType === 'events') {
            navigate('/student/schedule');
            let payload = {
                notificationIds: [data.id]
            }
            props?.viewednotification(payload);
        }

    }
    const handleAdvisorNavigate = async (e, data) => {
        e.preventDefault()
        let payload = {
            notificationIds: [data.id]
        }
        if (!data?.viewed) {
            await Promise.resolve(props?.viewednotification(payload));
        }
        console.log(data, "NotifyData");
        if (data.notificationType === 'plans') {
            console.log(data?.dataIds, "NotifyData plans");

        }
        else if (data.notificationType === "network") {
            console.log(data, "NotifyData network");
            navigate('/advisor/mynetwork', { state: { type: "recieveRequest" } });
        } else {
            console.log(data, "NotifyData events");
            navigate('/advisor/schedule', { state: data?.dataIds });
        }

    }
    const handleSwitchNavigate = (e, data) => {
        console.log(data, "datyajhgjh")
        const matchingChild = props?.childprofile?.find(child => child.id === data.dataIds.changeMadeUserId);
        if (data.notificationType === 'plans') {

            if (data?.dataIds?.planId && data?.dataIds?.phaseId && data?.dataIds?.milestoneId) {
                if (data?.dataIds?.phaseId && data?.dataIds?.courseId) {
                    navigate(`/parent/mywork/plan/${data?.dataIds?.planId}/milestones/${data?.dataIds?.courseId}`, { state: { courseData: data?.dataIds } });
                }
                if (data?.dataIds?.phaseId && data?.dataIds?.activityId) {
                    navigate(`/parent/mywork/plan/${data?.dataIds?.planId}/activity/milestone/`, { state: { courseData: data?.dataIds } });
                }
            } else {
                navigate('/parent/mywork/plan/' + data?.dataIds?.planId)
            }
            let payload = {
                notificationIds: [data.id]
            }
            props?.viewednotification(payload);

        } else if (data.notificationType === 'network') {
            navigate('/parent/mynetwork');
            let payload = {
                notificationIds: [data.id]
            }
            props?.viewednotification(payload);
        } else if (data.notificationType === 'events') {
            navigate('/parent/schedule');
            let payload = {
                notificationIds: [data.id]
            }
            props?.viewednotification(payload);
        }
    }
    useEffect(() => {
        if (displayToast) {
            setTimeout(() => {
                setdisplayToast(false);
            }, 9000);
        }
    }, [displayToast]);



    return (
        <>
            {displayToast && <div style={{ "width": "100%", "display": "flex", "justifyContent": "center", }}> {
                <Alert key={"danger"} variant={"danger"}>
                    child profile not found
                </Alert>
            }</div>}

            {props?.notificationdata && props?.notificationdata.length > 0 && props?.notificationdata.map((data, index) => (

                <>
                    {
                        <div className={data?.viewed ? 'Request_Card' : 'Unread_Request_Card'} >
                            <div className='ProfileImage'>
                                {data?.userImage ? (
                                    <img src="https://picsum.photos/200/300?random=2" className='prfimg' />
                                ) :
                                    <AccountCircleOutlinedIcon className='prfimg' sx={{ fill: '#cdcdcd' }} />}
                            </div>
                            <div className='RequestContent'>
                                <div className='RequestContent_Name'><p><span>{data?.context}</span></p></div>
                                <div className='RequestContent_Time'>
                                    <p>{convertUTCTodateTimeZone(data?.loggedAt, props?.userProfile?.timezone).format('MMM DD, YYYY, hh:mm A')}</p>
                                </div>

                                <div className='RequestContent_btn_cnt' >
                                    {props?.type === "parent" ?
                                        <Button variant="contained" className='viewbtn' onClick={(e) => handleSwitchNavigate(e, data)}>View Changes</Button>
                                        : props?.type === "advisor" ?
                                            <Button variant="contained" className='viewbtn' onClick={(e) => handleAdvisorNavigate(e, data)}>View {data?.notificationType === "network" ? "Request" : data?.notificationType === "events" ? 'Schedule' : "Changes"}</Button>
                                            :
                                            <Button variant="contained" className='viewbtn' onClick={(e) => handleNavigate(e, data)}>View {data?.notificationType === "network" ? "Request" : data?.notificationType === "events" ? 'Schedule' : "Changes"}</Button>
                                    }
                                </div>
                            </div>
                        </div>


                    }
                </>
            ))}
        </>
    )
}

const mapStateToProps = ({ userProfileData }) => {
    const { childprofile, userProfile } = userProfileData
    return { userProfile, childprofile }
};

export default connect(mapStateToProps, { viewednotification, getUserProfile })(Index);