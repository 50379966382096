import React, { useEffect, useState } from 'react'
import {getSubscribedStudents, getSubscribedStudentStatus, resetDetailedUserProfileStatus, 
    getScheduledSessionDetails, setOfferExtraSessionStatus, offerExtraSession,
    getNetworkUserCount } from "../../../../redux/actions"
import { connect } from 'react-redux'
import * as Yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ConnectedStudents from "../../../../componentsNew/Common/Cards/Common/ConnectedStudents";
import ViewStudentProfile from '../../../../componentsNew/Common/ViewStudentProfile';
import Loader from '../../../../components/Loader';
import { Link } from 'react-router-dom';
import Select, { components } from "react-select";
import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ExtraSessionModal from "../../../../componentsNew/Advisor/ExtraSessionModal"
import CustomAlert from '../../../../components/CustomAlert';
import { convertUTCTodateTimeZone, convertUtcToEventTime } from '../../../../utils/CommonUtils';

const sessionLength = [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 }
]

const validation = Yup.object({
	sessionLength: Yup.number().required('*Required').nullable()
})

const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder}</Placeholder>
            {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
        </ValueContainer>
    );
};

const Index = (props) => {
    const [loading, setLoading] = useState(false)
    const [showProfile, setShowProfile] = useState(false);
    const [profileData, setProfileData] = useState({});
    const [showOfferSessionModal, setShowOfferSessionModal] = useState(false);
	const [extraSessionDetails, setExtraSessionDetails] = useState({});

    useEffect(() => {
        setLoading(true)
        props?.getScheduledSessionDetails();
        props?.getSubscribedStudents();
        props?.getNetworkUserCount()
    }, [])
    useEffect(() => {
        if(props.getScribedStudentsStatus){
            props?.getSubscribedStudentStatus(false);
            setLoading(false);
        }
        if(props?.offerExtraSessionStatus){
            props.setOfferExtraSessionStatus(false);
            props?.getSubscribedStudents()
            reset()
            setShowOfferSessionModal(false);
            setExtraSessionDetails({});
		}
    }, [props.getScribedStudentsStatus, props?.offerExtraSessionStatus])

    const { register,  handleSubmit, reset, watch, control, formState: { errors }, setValue, clearErrors,  } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(
            validation
        ),
    });

    const handleViewProfile = (userInfo) => {
        setProfileData(userInfo);
        showProfileModal(true);
    }
    const showProfileModal = (status) => {
        if(!status) {
            setProfileData({})
            props?.resetDetailedUserProfileStatus();
        }
        setShowProfile(status)
    }

    const getScheduledTime = (userId, packId) => {
        let eventDetails = props.scheduledSessionDetails
			?.find(data => (data?.userId == userId && packId === data?.packageId && data?.userInvitationStatuses?.find(invite => invite?.userId == userId && invite?.status == "accepted")))
        if(eventDetails){
			let date = new Date(eventDetails?.eventTime)
        	let formattedDate = date.toLocaleDateString('en-GB', {
				day: '2-digit',
            	month: '2-digit'
        	});
            return `${formattedDate} at ${convertUTCTodateTimeZone(eventDetails?.eventTime, props?.userProfile?.timezone)?.format('hh:mm A')}`
        } else {
            return null;
        }
	}

    const handleOfferAnotherSession = (event, status, data, firstName) => {
		event?.stopPropagation();
		let sessionInfo = {
			...data, firstName
		}
		setShowOfferSessionModal(status);
		setExtraSessionDetails(sessionInfo);
		console.log(sessionInfo);
	}

    const onSubmit = data => {
		let payload = {
			noOfExtraSessions: data?.sessionLength,
			studentId: extraSessionDetails?.userId,
			paymentPackageId: extraSessionDetails?.packageId
		}
		props?.offerExtraSession(payload);
	}

    const packageDetails = (subscribedPackages, firstName) => subscribedPackages?.map(packageInfo => {
        const subscribedAt = new Date(packageInfo?.subscribedAt);
        const formattedSubscribedDate = `${subscribedAt.getMonth() + 1}/${subscribedAt.getDate()}/${subscribedAt.getFullYear().toString().slice(-2)}`;
        let expiresAt;
        let formattedExpirationDate;
        if(packageInfo?.subscriptionEndsAt){
            expiresAt = new Date(packageInfo?.subscriptionEndsAt);
            formattedExpirationDate = `${expiresAt.getMonth() + 1}/${expiresAt.getDate()}/${expiresAt.getFullYear().toString().slice(-2)}`;
        }
        return(
            <div className="connectedStudentCard__midSec__packageSec" key={packageInfo?.packageInfo?.id}>
                <span className='connectedStudentCard__midSec__packageSec__section'>
                    <span className='connectedStudentCard__midSec__packageSec__section__packName'>
                        <DiamondOutlinedIcon className='connectedStudentCard__midSec__packageSec__section__packName__icon'/>
                        <p className='connectedStudentCard__midSec__packageSec__section__packName__primaryText'>{packageInfo?.packageInfo?.packageName}</p>
                    </span>
                    <p className='connectedStudentCard__midSec__packageSec__section__secondaryText'>{packageInfo?.subscriptionType} Package</p>
                </span>
                <span className='connectedStudentCard__midSec__packageSec__validitySection'>
                    <p className='connectedStudentCard__midSec__packageSec__validitySection__tertiaryText'>Purchased on {formattedSubscribedDate}</p>
                    {formattedExpirationDate &&
                        <p className='connectedStudentCard__midSec__packageSec__validitySection__tertiaryText'>Renews on {formattedExpirationDate}</p>
                    }
                </span>
                <span className='connectedStudentCard__midSec__packageSec__section'>
                    <p className='connectedStudentCard__midSec__packageSec__section__helperText'><span className='strongText'> {packageInfo?.noOfPaidSessions || 0}</span> sessions remaining</p>
                    <p className='connectedStudentCard__midSec__packageSec__section__helperText'><span className='strongText'>0</span> outgoing messages remaining</p>
                </span>
                {getScheduledTime(packageInfo?.userId, packageInfo?.packageInfo?.id) &&
                <span className='connectedStudentCard__midSec__packageSec__scheduleDetailSec'>
                    <CalendarTodayOutlinedIcon className='connectedStudentCard__midSec__packageSec__scheduleDetailSec__calendarIcon'/>
                    <p className='connectedStudentCard__midSec__packageSec__scheduleDetailSec__meetTime'>You are scheduled to meet on {getScheduledTime(packageInfo?.userId, packageInfo?.packageInfo?.id)}</p>
                </span>
                }
                {packageInfo?.noOfPaidSessions == 0 &&
                    <span className='connectedStudentCard__midSec__packageSec__section'>
                        <button className='connectedStudentCard__midSec__packageSec__section__sessionOfferBtn' id='offerSession' onClick={(event) => handleOfferAnotherSession(event, true, packageInfo, firstName)}>
                            Offer another Session
                        </button>
                    </span>
                }
            </div>
        )
    })

    return (
        <>
            { loading && <Loader/> }
            { !loading && props?.subscribedStudentData?.map((data, index) => {
                return(
                    <div key={data?.userInfo?.id}>
                        <ConnectedStudents
                            name = {data?.userInfo?.fullName}
                            firstName= {data?.userInfo?.firstName}
                            country = {data?.userInfo?.country}
                            avatarUrl= {data?.userInfo?.avatarPath}
                            school = {data?.userInfo?.studentProfile?.currrentSchoolName}
                            gpa = {data?.userInfo?.studentProfile?.desiredGpa}
                            universities = {data?.userInfo?.studentProfile?.universities?.sort((a, b) => {return a.length - b.length} )}
                            professions = {data?.userInfo?.studentProfile?.professions?.sort((a, b) => {return a.length - b.length})}
                            courseAndActivities = {data?.userInfo?.studentProfile?.courses?.concat(data?.userInfo?.studentProfile?.activities)?.toSorted((a, b) => {return a.length - b.length})}
                            weighted= {data?.userInfo?.studentProfile?.targetWeighted}
                            unWeighted= {data?.userInfo?.studentProfile?.targetUnweighted}
                            subscribedPackages = {packageDetails(data?.subscriptions, data?.userInfo?.firstName)}
                        >
                            <button className="suggestedStudentCard__buttonSec__primary-btn" id={`viewProfile-${index}`} onClick={() => handleViewProfile({id: data?.userInfo?.id})}>
                                View Profile    
                            </button>
                        </ConnectedStudents>
                    </div>
                )
            })}
            { !loading && props?.subscribedStudentData?.length === 0 &&
                <div className="networkNoDataSec">
                    <div className="networkNoDataSec__nodataCard">
                        <p className="networkNoDataSec__nodataCard__textBlack">Let's get you connected with some Students who you can Collaborate with</p>
                        <span className="networkNoDataSec__nodataCard__cta">
                            <svg xmlns="http://www.w3.org/2000/svg" className="networkNoDataSec__nodataCard__cta__searchIcon" viewBox="0 0 24 24" fill="none">
                                <circle cx="11" cy="11" r="6" stroke="#222222"/>
                                <path d="M11 8C10.606 8 10.2159 8.0776 9.85195 8.22836C9.48797 8.37913 9.15726 8.6001 8.87868 8.87868C8.6001 9.15726 8.37913 9.48797 8.22836 9.85195C8.0776 10.2159 8 10.606 8 11" stroke="#222222" stroke-linecap="round"/>
                                <path d="M20 20L17 17" stroke="#222222" strokeLinecap="round"/>
                            </svg>
                            <Link to={'exploreStudents'}>
                                <p className="networkNoDataSec__nodataCard__cta__blueText" id='connect'>
                                    Explore Students
                                </p>
                            </Link>
                        </span>
                    </div>
                </div>
            }
            {showProfile &&
                <ViewStudentProfile show={showProfile} profileData={profileData} isSubscribed handleClose={() => showProfileModal(false)}/>
            }
            {showOfferSessionModal && 
                <ExtraSessionModal 
                    show={showOfferSessionModal} 
                    message={`This will add additional sessions to ${extraSessionDetails?.packageInfo?.packageName} for ${extraSessionDetails?.firstName} expiring in 10 days`} 
                    btnText="confirm" 
                    onHide={(event) => handleOfferAnotherSession(event, false, {}, '')} 
                >
                    <div className="alertComponent">
                        {Object.keys(props.errorList).length === 0 && <CustomAlert />}
                    </div>
                    <form className="extraSessionModal__bottomSec"  onSubmit={handleSubmit(onSubmit)} >
                        <div className="input-floating-label extraSessionModal__bottomSec__extraSessionField">
                            <Controller
                                name="sessionLength"
                                control={control}
                                render={({field:{ onChange, value, ref }}) => (
                                    <Select className="goals__form__select max__messages mb-0"
                                        classNamePrefix="mySelect" name="sessionLength" id='sessionLength'
                                        options={sessionLength} closeMenuOnSelect={true}
                                        onChange={(val) => onChange(val?.value)}
                                        value={sessionLength.find((paidSession) => paidSession?.value === value)}
                                        isClearable={false} placeholder={"Session Length"}
                                        components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                        styles={{ 
                                            container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), 
                                            valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', }), 
                                            placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) 
                                        }} 
                                    />
                                )}
                            />
                            <p className='error-text'>{errors?.sessionLength?.message}</p>
                        </div>
                        <button className="extraSessionModal__bottomSec__button" id='confirmBtn'>
                            Confirm
                        </button>
                    </form>
			    </ExtraSessionModal>
            }
        </>
    )
}

const mapStateToProps = ({ commonData, advisorData, userProfileData }) => {
    const { message, errorList, scheduledSessionDetails } = commonData;
    const { userProfile } = userProfileData
    const { subscribedStudentData, getScribedStudentsStatus, offerExtraSessionStatus } = advisorData
    return {  message, errorList, subscribedStudentData, getScribedStudentsStatus, offerExtraSessionStatus, scheduledSessionDetails, userProfile };
  };
export default connect(mapStateToProps, { getSubscribedStudents, getSubscribedStudentStatus, resetDetailedUserProfileStatus, 
    getScheduledSessionDetails, setOfferExtraSessionStatus, offerExtraSession, getNetworkUserCount })(Index);