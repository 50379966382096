import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getSentRequestsParent, setConnectedStudentStatus, getStudentDetail, getStudentPlanDetails, getUserProfile } from "../../../../redux/actions"
import "../parentMyNetwork.less";
import SentRequest from "../../../../componentsNew/Common/RequestCards/SentRequest"

const Index = (props) => {
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        let childId = localStorage.getItem("parentViaChild")
        props?.getSentRequestsParent(childId)
       let payload = {
            userId: childId
        } 
        props.getStudentPlanDetails(payload);

    }, [])

    useEffect(() => {
        let payload = {
            userId: props?.userProfile?.id,
            pointOfViewStudentId : localStorage.getItem("parentViaChild")
        }
        props.getStudentDetail(payload);
    }, [props?.userProfile])

    return (
        <SentRequest />
    )
}

const mapStateToProps = ({ commonData, userProfileData }) => {
    const { message, errorList, connectedStudents, connectedStudentsStatus } = commonData;
    const { isDisconnectUser, childprofile, userProfile} = userProfileData
    return { message, errorList, connectedStudents, connectedStudentsStatus, isDisconnectUser, childprofile, userProfile };
};
export default connect(mapStateToProps, { getSentRequestsParent, setConnectedStudentStatus, getStudentDetail, getUserProfile, getStudentPlanDetails })(Index);