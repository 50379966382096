import React from 'react'
import "./advisor.less";
import { Grid } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import PillSection from "../../../PillSection"
import { useImageUrl } from '../../../../../utils/UseImageURL';


const Index = (props) => {
  const advisorActivities = props?.data?.userInfo?.advisorProfile?.advisorActivities || [];
  const advisorCourses = props?.data?.userInfo?.advisorProfile?.advisorCourses || [];

  const combinedData = [...advisorActivities, ...advisorCourses];

  const mappedData = combinedData.map(item => {
    if (item.activityName) {
      return item.activityName;
    } else if (item.courseName) {
      return item.courseName;
    }
    return null;
  });
  const imgUrl = useImageUrl(props?.data?.userInfo?.avatarPath)

  return (

    <div className='exploreAdvisor'>
      <p className="advisorEyebrow" style={{"minHeight":"40px" }} >
        {props?.data?.matchingParams && props?.data?.matchingParams[0]}
      </p>
      <div className='advisorCard '>
        <Grid container spacing={2}>
          <Grid item xs={3} >
            {
              props?.data?.userInfo?.avatarPath ? (
                <img className="profile-img" style={{ "width": "60px", "height": "60px", borderRadius: "6px" }}
                  src={imgUrl} alt="userprofile"
                />
              ) :
                <PersonIcon className="imagesection" color="action" sx={{ border: '1px solid grey', borderRadius: "6px", fontSize: '1rem', "width": "60px", "height": "60px !important" }} />
            }
          </Grid>
          <Grid item xs={5} className="owlTitle">
            <p className="profile-heading" style={{ "marginBottom": "2px", "fontSize": "16px", "fontWeight": "500" }} >{props?.data?.userInfo?.fullName}</p>
            <p className="advisor-country" style={{ "marginBottom": "0px" }} > {props?.data?.userInfo?.country}
            </p>
          </Grid>
          <Grid item xs={4} className="owlTitle" style={{"alignItems":"center"}} >
            <p className="rating-heading" style={{ "marginBottom": "0px" }} >
              <StarBorderIcon sx={{ fontSize: '15px', fill: '#E64A19', }} /><span style={{"marginLeft":"5px"}} >{props?.data?.userInfo?.advisorProfile?.advisorReviewSummary?.averageRating.toFixed(1) || 0}{"/5.0"}</span>
            </p>
          </Grid>
        </Grid>
        <Grid className='mt-2'>
          {props?.data?.userInfo?.advisorProfile?.currentlyIn === "highschool" && (
            <p className='advisor-subText'>  <HomeOutlinedIcon sx={{ fontSize: '14px', fill: "#212224" }} /> Attending  {props?.data?.userInfo?.advisorProfile?.highschoolName}</p>
          )}
          {props?.data?.userInfo?.advisorProfile?.currentlyIn === "university" && (
            <>
              <p className='advisor-subText'>  <SchoolOutlinedIcon sx={{ fontSize: '14px', fill: "#212224" }} /> Attending  {props?.data?.userInfo?.advisorProfile?.universityName}</p>
            </>
          )}
          {props?.data?.userInfo?.advisorProfile?.currentlyIn === "professional" && (
            <>
              <p className='advisor-subText'>  <WorkOutlineOutlinedIcon sx={{ fontSize: '14px', fill: "#212224" }} /> Professional  {props?.data?.userInfo?.advisorProfile?.targetProfession}</p>
            </>
          )}
        </Grid>
        <Grid className='mt-2 '>
          <p className="profile-heading" style={{ "marginBottom": "5px", "fontSize": "16px"}} >{"Services offered"}</p>
          <div className='advisor-card-content'>
            <p className='advisor-subText'>  <StarRoundedIcon sx={{ fill: "#1C84EE", fontSize: '17px', }} />{props?.data?.userInfo?.advisorProfile?.noOfFreeConsultation} free sessions</p>
            {props?.data?.userInfo?.advisorProfile?.helpWithPlan === true && (<p className='advisor-subText'>  <StarRoundedIcon sx={{ fill: "#1C84EE", fontSize: '17px' }} />  Create and manage plan</p>)}
            <p className='advisor-subText'>  <StarRoundedIcon sx={{ fill: "#1C84EE", fontSize: '17px' }} /> Mentoring for</p>
            <PillSection data={mappedData} maxWidth={193} maxHeight={80} />
          </div>
        </Grid>
        <Grid>
          {props?.children}
        </Grid>
      </div>
    </div>

  )
}

export default Index