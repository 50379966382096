import React, { useEffect, useState } from 'react'
import { connect } from "react-redux";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Dropdown, Modal, } from "react-bootstrap";
import Select, { components } from "react-select";
import CreatableSelect from 'react-select/creatable';
import ReactQuill from "react-quill";
import { COURES_NAME_LABEL, COURES_TYPE_LABEL } from "../../../../../Constants/commonLabels";
import { CreateCourse, clearMessage, clearErrorList } from '../../../../../redux/actions'
import { useLocation } from 'react-router-dom';
import CustomAlert from '../../../../../components/CustomAlert';

const validation = Yup.object({
    courseName: Yup.object().required("*Required").nullable(),    //Max Length Validation
    courseType: Yup.object().required("*Required").nullable(),
    gradeAchieved: Yup.object().nullable(),
    semester: Yup.object().required("*Required").nullable(),
    description: Yup.string().transform((value) => {
        return value.replace(/<[^>]+>/g, '').replace(/\&nbsp;+/g, ' ')
    }).max(750, "Description should not exceed 750 characters").nullable()
})

const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder}</Placeholder>
            {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
        </ValueContainer>
    );
};

const Index = (props) => {
    const [loading, setLoading] = useState(false)
    const [errorList, setErrorList] = useState([])
    const [courseNameOpts, setCourseNameOpts] = useState([])
    const [courseTypeOpts, setCourseTypeOpts] = useState([])
    const [gradeOpts, setGradeOpts] = useState([])
    const [semesterOpts, setSemesterOpts] = useState([])

    const { handleSubmit, watch, setValue, reset, control, formState: { errors } } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(
            validation
        ),
    });

    useEffect(() => {
        setLoading(true)
        setInitialValues();
        props?.clearMessage();
        props?.clearErrorList();
        return (() => {
            reset();
            props?.clearMessage()
        })
    }, [])

    useEffect(() => {
        if (props?.EditData) {
            filterObjectCourse("courseName", props?.courseNames, props?.EditData?.courseName);
            filterObjectType("courseType", props?.course, props?.EditData?.courseType);
            filterObjectGrade("gradeAchieved", props?.studentGrades, props?.EditData?.grade);
            setValue("helpwith", props?.EditData?.description)
        }
        if (semesterOpts != undefined) {
            filterObject("semester", semesterOpts, props?.semesterData?.title, props?.semesterData?.id);
        }
    }, [props?.EditData, semesterOpts, props?.semesterData])

    const filterObject = (title, options, value, phaseId) => {
        if (value === "" || value === undefined) return;
        let filtered = options?.filter((option) => option?.label?.toString() === value?.toString());
        console.log(filtered[0], "filtered[0]");
        if (filtered[0]) {
            setValue(title, { label: filtered[0].label, value: filtered[0].value, isPassed: filtered[0]?.isPassed });
        } else {
            setValue(title, { label: value, value: phaseId });
        }
    };

    const filterObjectCourse = (title, options, value) => {
        if (value === "" || value === undefined) return;
        let filtered = options?.filter((options) => options?.courseName?.toString() === value?.toString());
        if (filtered && filtered[0]) {
            setValue(title, { label: filtered[0]?.courseName, value: filtered[0]?.courseName });
        } else {
            setValue(title, { label: value, value: value })
        }
    };

    const filterObjectType = (title, options, value) => {
        if (value === "" || value === undefined) return;
        let filtered = options?.filter((options) => options?.courseType?.toString() === value?.toString());
        if (filtered && filtered[0]) {
            setValue(title, { label: filtered[0]?.courseType, value: filtered[0]?.courseType });
        } else {
            setValue(title, { label: value?.label, value: value?.value })
        }
    };

    const filterObjectGrade = (title, options, value) => {
        if (value === "" || value === undefined) return;
        let filtered = options?.filter((options) => options?.grade?.toString() === value?.toString());
        if (filtered && filtered[0]) {
            setValue(title, { label: filtered[0]?.grade, value: filtered[0]?.id });
        } else {
            setValue(title, { label: filtered[0]?.grade, value: value?.value })
        }
    };

    const setInitialValues = () => {
        console.log(props?.EditData, "EditData");
        let courseNameOptions = [];
        let courseTypeOptions = [];
        let gradeOptions = [];
        let semOptions = [];

        if (Object.keys(props?.errorList ?? {}).length === 0) {
            setErrorList([])
        }
        if (Object.keys(props?.EditData ?? {}).length > 0) {
            setValue('description', props?.EditData?.description)
        }
        props?.courseNames?.length > 0 && props?.courseNames?.map((x, key) => {
            let temp = { label: x.courseName, value: x.courseName }
            courseNameOptions.push(temp);
        })
        props?.course?.length > 0 && props?.course?.map((x) => {
            let temp = { label: x.courseType, value: x.courseType }
            courseTypeOptions.push(temp);
        })
        props?.getphasetime?.length > 0 && props?.getphasetime?.map((x) => {
            console.log("props?.getphasetime", props?.getphasetime);
            let temp = { label: x.phaseTitle, value: x.phaseId, isPassed: x.isPassed }
            if (props?.userProfile?.currentRole === "advisor") {
                if (!x.isPassed) {
                    semOptions.push(temp);
                }
            } else {
                semOptions.push(temp);
            }
        })
        props?.studentGrades?.length > 0 && props?.studentGrades?.map((x, key) => {
            let temp = { label: x.grade, value: x.grade }
            gradeOptions.push(temp);
        })
        setCourseNameOpts(courseNameOptions)
        setCourseTypeOpts(courseTypeOptions)
        setSemesterOpts(semOptions)
        setGradeOpts(gradeOptions)
    }
    const onSubmit = (data) => {
        let payload = {
            accessibleAdvisors: [],
            courseName: data?.courseName?.value,
            courseType: data?.courseType?.value,
            description: data?.description,
            grade: data?.gradeAchieved?.value,
            phaseId: data?.semester?.value,
        }
        props?.handleSubmit(payload);
    }
    let descLength = watch("description")
    const selectedSemester = watch("semester");
    let currentSemester = props?.getstudentplan[0]?.planInfo?.activePhaseId === selectedSemester?.value



    return (
        <Modal size={'md'} show={props?.showAddCourse} onHide={(e) => props?.hideModel(e)} centered={true} dialogclassname={"mywork-popup"} style={{ "zIndex": "2000", backgroundColor: "rgb(0,0,0, 0.5)" }}>
            {Object.keys(props?.errorList) == 0 &&
                <div style={{ "width": "100%", "display": "flex", "justifyContent": "center" }}>
                    <CustomAlert />
                </div>
            }
            <Modal.Header closeButton>
                <Modal.Title>{props?.EditData ? "Edit Course" : "Add Course"}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <form id="advData" onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-12 p-1" style={{ minHeight: '90px' }}>
                        <Controller
                            name="courseName"
                            control={control}
                            render={({ field }) =>
                                <CreatableSelect name="courseName" {...field} className="goals__form__select mb-0" classNamePrefix="mySelect"
                                    closeMenuOnSelect={true} isClearable={false} options={courseNameOpts}
                                    components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null, }} placeholder={COURES_NAME_LABEL}
                                    styles={{
                                        container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible", zIndex: "3" }),
                                        valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', width: '170px' }),
                                        placeholder: (provided, state) => ({
                                            ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%",
                                            fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10
                                        })
                                    }}
                                />
                            }
                        />
                        <div className="error-text" style={{ "position": "absolute" }} >{errors?.courseName?.message}</div>
                        {errorList?.courseName && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.courseName}</div>}
                    </div>
                    <div className="col-12 p-1" style={{ minHeight: '90px' }}>
                        <Controller
                            name="courseType"
                            control={control}
                            render={({ field }) =>
                                <Select name="courseType" {...field}
                                    options={courseTypeOpts}
                                    placeholder={COURES_TYPE_LABEL} className="goals__form__select mb-0" classNamePrefix="mySelect" closeMenuOnSelect={true} isClearable={false}
                                    components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                    styles={{
                                        container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible", zIndex: "2" }),
                                        valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', }),
                                        placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10 })
                                    }}
                                />
                            }
                        />
                        <div className="error-text" style={{ "position": "absolute" }} >{errors?.courseType?.message}</div>
                        {errorList?.courseType && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.courseType}</div>}
                    </div>
                    <div className="col-12 p-1" style={{ minHeight: '90px' }}>
                        <Controller control={control} name="semester"
                            render={({ field }) => (
                                <>
                                    <Select name="semester" options={semesterOpts} placeholder="Semester"  {...field} className="goals__form__select mb-0" classNamePrefix="mySelect"
                                        closeMenuOnSelect={true} isClearable={false} isDisabled={props?.EditData ? true : false}
                                        components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                        styles={{
                                            option: (provided, state) => ({ ...provided, cursor: 'pointer', }),
                                            container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }),
                                            menu: (provided, state) => ({ ...provided, zIndex: '999' }),
                                            valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }),
                                            placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', })
                                        }}
                                    />
                                    {errors?.semester && <div className="error-text" >{errors?.semester?.message}</div>}
                                    {console.log(errorList, "errorList")}
                                    {errorList?.phaseId && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.phaseId}</div>}
                                </>
                            )}
                        />
                    </div>
                    {(watch("semester")?.isPassed === true &&
                        <>
                            {props?.userProfile?.currentRole !== "advisor" &&
                                <div className="col-12 p-1" style={{ minHeight: '90px' }}>
                                    <div className="input-floating-label">
                                        <Controller
                                            name="gradeAchieved"
                                            control={control}
                                            render={({ field }) =>
                                                <>
                                                    <Select name="gradeAchieved" {...field}
                                                        options={gradeOpts} id="gradeAchieved"
                                                        placeholder="Grade Achieved" className="goals__form__select mb-0" classNamePrefix="mySelect"
                                                        closeMenuOnSelect={true} isClearable={true}
                                                        components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                                        styles={{
                                                            container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible", zIndex: "1" }),
                                                            valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', }),
                                                            placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10 })
                                                        }} />
                                                    <p className="cta--text mb-2" style={{ "fontSize": "12px", "marginLeft": "20px", marginBottom: 0 }}>Optional</p>
                                                </>
                                            }
                                        />
                                        <div className="error-text" style={{ "position": "absolute" }} >{errors?.gradeAchieved?.message}</div>
                                        {errorList?.gradeAchieved && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.gradeAchieved}</div>}
                                    </div>
                                </div>
                            }
                        </>
                    )}
                    {(watch("semester")?.isPassed !== true || currentSemester) && (
                        <div className="col-12 p-1" style={{ minHeight: '80px' }} >
                            <div className="input-floating-label" style={{ marginBottom: "20px" }}>
                                <Controller
                                    name="description"
                                    control={control}
                                    render={({ field }) =>
                                        <ReactQuill className="textarea--primary textbox--rounded input" theme="snow" {...field} id="description"
                                            onKeyPress={(event) => { if (!/^[a-zA-Z0-9!@#\$%\^\ \&*\)\(+._-]+$/.test(event.key)) { event.preventDefault(); } }}
                                        />
                                    }
                                />
                                <label>Specify how you can help</label>

                                <div className="d-flex justify-content-between me-1">
                                    {(errors?.description && <span className="error-text">Description only allow 750 Characters</span>) ||
                                        <p className="cta--text mb-2" style={{ "fontSize": "12px", "marginLeft": "20px", marginBottom: 0 }}>Optional</p>
                                    }
                                    <div className="subtext"><span>{descLength?.replace(/<[^>]+>/g, '').replace(/\&nbsp;+/g, ' ')?.length || 0}</span>/750</div>
                                </div>
                                {errorList?.description && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.description}</div>}
                            </div>
                        </div>
                    )}
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="modalBtn" type="submit" form="advData" >Add</Button>
            </Modal.Footer>
        </Modal>
    )
}

const mapStateToProps = ({ commonData, userProfileData, studentData }) => {
    const { message, errorList, courseNames, course, studentGrades, getphasetime } = commonData;
    const { userProfile } = userProfileData
    const { getstudentplan } = studentData
    return { message, errorList, courseNames, course, studentGrades, getphasetime, userProfile, getstudentplan };
};
export default connect(mapStateToProps, { CreateCourse, clearMessage, clearErrorList })(Index);