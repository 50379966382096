import React, { useCallback, useEffect, useRef, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import RecomendedAdvisors from "../../../../../componentsNew/Common/Cards/Advisor/RecomendedAdvisors";
import Profile from "../../../../../componentsNew/Common/advisorProfile"
import {
    saveUserProfile, unSaveUser, resetDetailedUserProfileStatus, advisorSaveStatus, setExploreStudentsByUniversityStatus,
    setExploreStudentsByProfessionStatus, setExploreStudentsByCourseStatus, setExploreStudentsByActivityStatus,
    getExploreStudentsBySection, setSavedUserProfileStatus, setExploreStudentsBySectionStatus, genericSearchStudent, setGenericSearchStudentStatus,

    fetchBySectionAdvisors, genericSearchStudents, genericSearchStudentsBySection,
    setAllDatasLoaded,
    fetchData,
    resetData
} from "../../../../../redux/actions";
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import { connect, useDispatch, useSelector } from 'react-redux';
import AdvisorCard from "../AdvisorCard"


const Index = (props) => {
    const [showProfile, setShowProfile] = useState(false);
    const [profileData, setProfileData] = useState({});
    const [lastViewedIndex, setLastViewedIndex] = useState(0);
    const [loading, setLoading] = useState(false);

    const { loadUsers, data, allDatasLoaded, genericSearchData } = useSelector(state => state?.networkData);
    let ref = useRef(0);
    let pageNoRef = useRef(props?.sectionDetails?.pageNo);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!allDatasLoaded && loading) {
            setLoading(false)
            dispatch(setAllDatasLoaded(true))
        }
    }, [allDatasLoaded])

    const exploreUsersState = () => {
        let fetchedData = data?.find(section => {
            return ((section?.section == props?.sectionDetails?.section) && (section?.sectionValue == props?.sectionDetails?.sectionValue) && (section?.sectionIndex == props?.sectionDetails?.sectionIndex))
        })
        return props?.sectionDetails?.total > fetchedData?.users?.length
    }

    const exploreGenericUserState = () => {
        let fetchedData = genericSearchData?.[0]
        return props?.sectionDetails?.total > fetchedData?.users?.length
    }

    const options = {
        nav: true,
        navElement: ["ChevronLeftOutlinedIcon ", "ChevronRightIcon "],
        autoplay: false,
        slideBy: 1,
        dots: false,
        dotsEach: false,
        dotData: true,
        loop: false,
        lazyLoadEager: 3,
        startPosition: ref.current,
        onChanged: (event) => {
            if (allDatasLoaded && (loadUsers == false)) {
                if (event.item.index) {
                    ref.current = event.item.index;
                    setLastViewedIndex(event?.item?.index)
                }
                if (props?.sectionDetails?.section === "Generic") {
                    if (!loadUsers && props?.sectionDetails?.total > 10 && exploreGenericUserState() && (event?.item?.index == (event.item?.count - 5))) {
                        let payload = {
                            limit: 10,
                            searchParams: {
                                searchString: "",
                                sectionSearch: []
                            },
                            sectionIndex: props?.sectionDetails?.sectionIndex,
                            studentId: localStorage.getItem("parentViaChild"),
                            totalSection: 2
                        }
                        payload["pageNo"] = props?.sectionDetails?.pageNo + 1;
                        setLoading(true)
                        if (pageNoRef?.current != payload["pageNo"]) {
                            dispatch(fetchData());
                            if (props?.sectionDetails?.searchString) {
                                props?.genericSearchStudentsBySection(props?.sectionDetails?.searchString, payload)
                            }
                        }
                        pageNoRef.current = payload["pageNo"]
                    }
                } else {
                    if (!loadUsers && props?.sectionDetails?.total > 10 && exploreUsersState() && (event?.item?.index == (event.item?.count - 5))) {
                        let payload = {
                            limit: 10,
                            // pageNo: 0,
                            total: props?.sectionDetails?.total,
                            searchParams: {
                                searchString: "",
                                sectionSearch: props?.filterData?.filter(data => data?.values)
                            },
                            section: props?.sectionDetails?.section,
                            sectionIndex: props?.sectionDetails?.sectionIndex,
                            sectionValue: props?.sectionDetails?.sectionValue,
                            studentId: localStorage.getItem("parentViaChild"),
                            totalSection: props?.sectionDetails?.totalSection
                        }
                        payload["pageNo"] = props?.sectionDetails?.pageNo + 1;
                        setLoading(true)
                        if (pageNoRef?.current != payload["pageNo"]) {
                            dispatch(fetchData());
                            props?.fetchBySectionAdvisors(payload);
                        }
                        pageNoRef.current = payload["pageNo"]
                    }
                }
            }
        },
        responsive: props?.reponsive || {
            1: { items: 1 },
            320: { items: 1 },
            700: { items: 2 },
            769: { items: 1 },
            900: { items: 2 },
            1230: { items: 3 },
            1600: { items: 3 },
            1900: { items: 4 },
            2300: { items: 5 }
        },
    };

    const showProfileModal = (status) => {
        if (!status) {
            setProfileData({})
            props?.resetDetailedUserProfileStatus();
        }
        setShowProfile(status)
    }
    const handleViewProfile = (userInfo) => {
        setProfileData(userInfo);
        showProfileModal(true);
    }
    const saveUserProfile = (userId) => {
        let payload = {
            userId: userId
        }
        props?.bookmarkUser(true, payload);
    }
    const unSaveUserProfile = (userId) => {
        let payload = {
            userId: userId
        }
        props?.bookmarkUser(false, payload);
    }

    let owlCarouselData = props?.data?.map(element => {
        return (
            <div className="owl__item" key={element?.id}>
                <a>
                    <RecomendedAdvisors
                        name = {element?.fullName}
                        country = {element?.country}
                        avatarUrl = {element?.avatarPath}
                        currentlyIn = {element?.advisorProfile?.currentlyIn}
                        data = {element?.advisorProfile}
                        gpa = {element?.advisorProfile?.desiredGpa}
                        universities = {element?.advisorProfile?.universities?.sort((a, b) => {return a.length - b.length} )}
                        professions = {element?.advisorProfile?.professions?.sort((a, b) => {return a.length - b.length})}
                        matchParam = {element?.matchingParams?.[0]}
                        userProfile={props?.userProfile}
                        courseAndActivities = {element?.advisorProfile?.advisorCourses?.concat(element?.advisorProfile?.advisorActivities)}
                        saveProfileIcon = {props?.loadSavedUsers?.map(user => user?.id)?.includes(element?.id) ? 
                            <BookmarkOutlinedIcon onClick= {() => unSaveUserProfile(element?.id)}/> 
                        :  
                            <BookmarkBorderOutlinedIcon onClick= {() => saveUserProfile(element?.id)}/>
                        }
                    >
                        <button className="recommendedStudentCard__buttonSec__primary-btn" onClick={() => handleViewProfile(element)}>
                            View Profile
                        </button>
                    </RecomendedAdvisors>
                   
                </a>
            </div>
        )
    })

    return (
        <>
            {/* {loading && <Loader />}/// */}
            <OwlCarousel
                className="owl owl-OwlCarousel owl-theme"
                {...options} >
                {owlCarouselData}
            </OwlCarousel>
            {showProfile &&
                <Profile show={showProfile} detailedInfo={props?.detailedAdvisorData} data={profileData} handleClose={() => showProfileModal(false)}  studentNetwork={true} />
            }
        </>
    )
}

const mapStateToProps = ({ commonData, exploreData,userProfileData }) => {
    const { message, errorList, loadSavedUsers, loadSavedUserStatus, genericSearchStudentData, genericSearchStudentStatus } = commonData;
    const { userProfile } = userProfileData;
    const { loadUniversityStudent, loadProfessionStudent, loadCoursesStudent, loadActivitiesStudent,
        exploreUniversityStudentData, exploreProfessionStudentData, exploreCoursesStudentData, exploreActivitiesStudentData, loadStudentBySection } = exploreData
    return {
        message, errorList, loadSavedUsers, loadSavedUserStatus, loadUniversityStudent, genericSearchStudentStatus,
        loadProfessionStudent, loadCoursesStudent, loadActivitiesStudent, exploreUniversityStudentData, genericSearchStudentData,
        exploreProfessionStudentData, exploreCoursesStudentData, exploreActivitiesStudentData, loadStudentBySection,userProfile
    };
};
export default connect(mapStateToProps, {
    saveUserProfile, unSaveUser, resetDetailedUserProfileStatus, advisorSaveStatus,
    setExploreStudentsByUniversityStatus, setExploreStudentsByProfessionStatus, setExploreStudentsByCourseStatus,
    setExploreStudentsByActivityStatus, getExploreStudentsBySection, setSavedUserProfileStatus, setExploreStudentsBySectionStatus,
    genericSearchStudent, setGenericSearchStudentStatus,

    fetchBySectionAdvisors, genericSearchStudents, genericSearchStudentsBySection
})(Index);