import { Button } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import "../../../assets/css/plans.less";
import CustomAlert from "../../../components/CustomAlert";
import Loader from '../../../components/Loader';
import SemesterCard from '../../../componentsNew/Common/Cards/Common/PhaseCard';
import Footer from '../../../componentsNew/Common/Footer';
import AddActivity from "../../../componentsNew/Common/PhaseModal/AddActivity";
import AddCourse from "../../../componentsNew/Common/PhaseModal/AddCourse";
import { withRouter } from "../../../redux/store/navigate";

import {
    CreateActivity, CreateActivityErrorStatus, CreateActivityStatus, getChildProfile, getChildProfilestatus,
    CreateCourse, CreateCourseErrorStatus, CreateCourseStatus, CreatePlanStatus, DeleteActivityStatus,
    DeleteCourseStatus, EditPhaseStatus, EditPlanStatus, GetStudentPlan, GetStudentPlanPhase, GetStudentPlanPhaseStatus, GetStudentPlanOfParent,
    clearMessage, getActivities, getActivity, getCourse, getCourses, EditActivity, getPhasetime, getStudentGrades, EditCourse, EditCourseStatus, GetStudentPlanStatus
} from "../../../redux/actions";


const Index = (props) => {
    const [loading, setloading] = useState(false);
    const [showSemesters, setShowSemesters] = useState(false);
    const [addcourse, setAddcourse] = useState(false)
    const [addactivity, setAddactivity] = useState(false)
    const [semesterData, setSemesterData] = useState([])
    const [displayAlert, setDisplayAlert] = useState(false)
    const [footerClicked, setFooterClick] = useState(false)
    const [isValid, setIsValid] = useState(false)
    const [SemesterError, setSemesterError] = useState(false)
    let navigate = useNavigate();


    // Popups
    const Coursepopupshow = () => {
        setAddcourse(true)
    }
    const Coursepopuphide = () => {
        setAddcourse(false)
    }
    const Activitypopupshow = () => {
        setAddactivity(true)
    }
    const Activitypopuphide = () => {
        setAddactivity(false)
    }
    // Switch
    const handleSwitchChange = () => {
        setShowSemesters(!showSemesters);
    };

    useEffect(() => {
        if (props?.userProfile?.currentRole !== "parent") {
            let value = {
                "searchString": "",
                "pageNo": 1,
                "limit": 10,
                "status": [],
                "sortOrder": "latest",
                "studentId": null
            }
            props?.GetStudentPlan(value)
        }
        if (props?.userProfile?.currentRole === "parent") {
            props?.getChildProfile()
        }
        props?.getStudentGrades();
        props?.clearMessage()
    }, [])

    useEffect(() => {
        if (props?.getchildstatus && props?.userProfile?.currentRole === "parent" && props?.childprofile?.length > 0) {
            let value = {
                "searchString": "",
                "pageNo": 1,
                "limit": 10,
                "status": [],
                "sortOrder": "latest",
                "studentId": props?.childprofile && props?.childprofile[0]?.id
            }
            props?.GetStudentPlanOfParent(value)
            props?.getChildProfilestatus(false)
        }

        if (props?.getstudentplan && props?.studentplanstatus) {
            setloading(true)
            let plans = props?.getstudentplan[0]?.planInfo
            let plansId = plans?.id
            if (plansId != "") {
                let values = { "planId": plansId }
                props?.GetStudentPlanPhase(values)
                props?.getPhasetime(plansId);
            }
            props?.GetStudentPlanStatus(false)
        }
    }, [props?.getstudentplan, props?.studentplanstatus, props?.getchildstatus, props?.childprofile])

    const PhaseApiCall = () => {
        if (props?.getstudentplan) {
            let plans = props?.getstudentplan[0]?.planInfo
            let plansId = plans?.id
            if (plansId != "") {
                let values = { "planId": plansId }
                props?.GetStudentPlanPhase(values)
                props?.getPhasetime(plansId);
            }
        }
    }

    useEffect(() => {
        if (props?.isgetplanphase) {
            setloading(false)
            props?.GetStudentPlanPhaseStatus(false)
        }
    }, [props?.isgetplanphase])

    useEffect(() => {
        if (props?.isCourseCreated || props?.isEditCourse) {
            // setloading(true)
            setDisplayAlert(true)
            PhaseApiCall()
            Coursepopuphide()
            props?.CreateCourseStatus(false)
            props?.EditCourseStatus(false)
        }
        if (props?.isCourseError) {
            props?.CreateCourseErrorStatus(false)
            setDisplayAlert(false)
        }
    }, [props?.isCourseCreated, props?.isCourseError, props?.isEditCourse])

    useEffect(() => {
        if (props?.isActivityCreated) {
            // setloading(true)
            setDisplayAlert(true)
            PhaseApiCall()
            Activitypopuphide()
            props?.CreateActivityStatus(false)
        }
        if (props?.isActivityError) {
            props?.CreateActivityErrorStatus(false)
            setDisplayAlert(false)
        }
    }, [props?.isActivityCreated, props?.isActivityError])

    useEffect(() => {
        if (props.isCourseDeleted) {
            setDisplayAlert(true)
            PhaseApiCall()
            props.DeleteCourseStatus(false);
        }
        if (props.isActivityDeleted) {
            setDisplayAlert(true)
            PhaseApiCall()
            props.DeleteActivityStatus(false);
        }
    }, [props.isCourseDeleted, props.isActivityDeleted])

    const handleCourseSubmit = (data, editCourse) => {
        let plans = props?.getstudentplan[0]?.planInfo
        let plansId = plans?.id
        let payload = {
            planId: plansId,
            phaseId: data?.semester?.value || "",
            courseName: data?.coursename?.value || "",
            courseType: data?.courselevel?.value || "",
            description: data?.helpwith || "",
            accessibleAdvisors: [],
            grade: data?.gradeachieved?.label || "",
        };
        if (editCourse) {
            props.EditCourse(payload, editCourse?.id)
        } else {
            props.CreateCourse(payload)
        }
    }
    const handleActivitySubmit = (data, editCourse) => {
        let plans = props?.getstudentplan[0]?.planInfo
        let plansId = plans?.id
        let payload = {
            planId: plansId,
            phaseId: data?.semester?.value || "",
            activityName: data?.activityname?.value || "",
            activityType: data?.activitytype?.label || "",
            description: data?.helpwith || "",
            accessibleAdvisors: [],
            grade: data?.gradeachieved?.label || "",
        };
        if (editCourse) {
            props.EditActivity(payload, editCourse?.id)
        } else {
            props.CreateActivity(payload)
        }
    }

    useEffect(() => {
        if (showSemesters) {
            setSemesterData(props?.getstudentplanphase)
        }
    }, [showSemesters, props?.getstudentplanphase])

    useEffect(() => {
        if (!showSemesters) {
            const filteredData = props?.getstudentplanphase?.length > 0 && props?.getstudentplanphase.filter(phase => phase.courses.length > 0 || phase.activities.length > 0) || [];
            setSemesterData(filteredData)
        }
    }, [showSemesters, props?.getstudentplanphase])

    useEffect(() => {
        if (props?.isEditPhase) {
            let plans = props?.getstudentplan[0]?.planInfo
            let plansId = plans?.id
            if (plansId != "") {
                let values = { "planId": plansId }
                // props?.GetStudentPlanPhase(values)
            }
            setDisplayAlert(true)
            // props?.EditPhaseStatus(false)

        }
    }, [props?.isEditPhase])

    const handleChangePsw = () => {
        setFooterClick(true);
    };
    const handleChangeDataPsw = (data) => {
        const filteredData = data?.filter(entry => entry?.startDateError !== '' || entry?.endDateError !== '');
        if (filteredData?.length > 0) {
            setIsValid(true)
            setFooterClick(false);
        } else {
            setIsValid(false)
            if (props?.userProfile?.currentRole === "parent") {
                navigate('/onboarding/selectadvisor/child')
            } else {
                navigate('/onboarding/selectadvisor')
            }
            setFooterClick(false);
        }
    };

    const ValidationError = (data) => {
        setSemesterError(data)
        setAddcourse(false)
        setAddactivity(false)
        if (data === false && addcourse || addactivity) {
            setAddcourse(false)
            setAddactivity(false)
        }

    }

    return (
        <>
            {loading && <Loader />}
            {displayAlert && <div style={{ "width": "100%", "display": "flex", "justifyContent": "center", }}> {<CustomAlert />}</div>}
            {(!SemesterError && addcourse) && <AddCourse show={addcourse} handleClose={Coursepopuphide} handleSubmit={handleCourseSubmit} />}
            {(!SemesterError && addactivity) && <AddActivity show={addactivity} handleClose={Activitypopuphide} handleSubmit={handleActivitySubmit} />}

            <div className="set_initial_plan">
                <div className="role_card phasesection">
                    <h1 className="role_title text-center">Add courses and activities to plan</h1>
                    <p className='phase_helpertext'>Add courses and activities you have completed, currently taking and planning to take up.  We will connect you to advisors who can review your plan and suggest changes to successfully meet your goals, We can also connect you with mentors for specific courses and activities. </p>
                    <div className="initial_plan">
                        <div className='Addcoursecnt'>
                            <div className='CourseBox'>
                                <div style={{ "display": "flex", "justifyContent": "center" }} >
                                    <Button className='AddBtn mb-2' onClick={() => Coursepopupshow()} id="addcourse" >Add a course</Button>
                                </div>
                                <div style={{ "display": "flex", "justifyContent": "center" }} >
                                    <div className='greycard' >
                                        <p>Add courses you plan to take and may need help with. You may also add completed and in progress courses for advisors to review and suggest future courses based on your current progress and future goals</p>
                                    </div>
                                </div>
                            </div>
                            <div className='ActvityBox'>
                                <div style={{ "display": "flex", "justifyContent": "center" }} >
                                    <Button className='AddBtn mb-2' onClick={() => Activitypopupshow()} id="addactivity" >Add an activity</Button>
                                </div>
                                <div style={{ "display": "flex", "justifyContent": "center" }} >
                                    <div className='greycard' >
                                        <p>Add activities like extra curriculors and College Prep (SAT, Apps etc)  </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='mt-1'>
                        <div className='semesterSwitch' style={{ "display": "flex", "justifyContent": "flex-end" }}>
                            <FormControlLabel
                                value="start"
                                control={<Switch color="primary" checked={showSemesters} onChange={handleSwitchChange} />}
                                label="Show all semesters"
                                labelPlacement="start"
                                id="showsemester"
                            />
                        </div>
                        <div className='mt-2'>
                            <div className='semester-section mt-4'>
                                <SemesterCard data={semesterData} semesterCardData={footerClicked} handleFormValues={(data) => handleChangeDataPsw(data)} handleCourseSubmit={(data, editCourse) => handleCourseSubmit(data, editCourse)} handleActivitySubmit={(data, editCourse) => handleActivitySubmit(data, editCourse)} SemesterError={(data) => ValidationError(data)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer currentStep={""} reverseLink={`${props?.userProfile?.currentRole === "parent" ? "/onboarding/intialplansParent" : "/onboarding/plans"}`}
                skipTo={props?.userProfile?.currentRole === "parent" ? "/onboarding/selectadvisor/child" : "/onboarding/selectadvisor"} skipText={"Add more later"}>
                <div onClick={(e) => handleChangePsw(e)} className="footers__rightSec__cta" >Explore Advisors
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.92893 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.0222 21.4135 8.08879 20.3147 6.4443C19.2159 4.79981 17.6541 3.51808 15.8268 2.76121C13.9996 2.00433 11.9889 1.8063 10.0491 2.19215C8.10929 2.578 6.32746 3.53041 4.92893 4.92893" strokeWidth="2" className="rolestrokes" />
                        <path d="M15 12L15.7809 11.3753L16.2806 12L15.7809 12.6247L15 12ZM3 13C2.44771 13 2 12.5523 2 12C2 11.4477 2.44771 11 3 11V13ZM11.7809 6.3753L15.7809 11.3753L14.2191 12.6247L10.2191 7.6247L11.7809 6.3753ZM15.7809 12.6247L11.7809 17.6247L10.2191 16.3753L14.2191 11.3753L15.7809 12.6247ZM15 13H3V11H15V13Z" className="rolefills" />
                    </svg>
                </div>
            </Footer>
        </>
    );
}

const mapStateToProps = ({ userProfileData, commonData, studentData }) => {
    const { courseNames, course, getphasetime, studentGrades, activity, activityNames } = commonData
    const { userProfile, childprofile, getchildstatus } = userProfileData;
    const { getstudentplanphase, getstudentplan, isCourseCreated, isCourseError, isActivityCreated, isActivityError,
        isEditPhase, isCourseDeleted, isActivityDeleted, isgetplanphase, isEditCourse, studentplanstatus } = studentData;

    return {
        userProfile, getstudentplanphase, getstudentplan, isCourseCreated, isCourseError, isEditCourse, isActivityCreated, isActivityError, isEditPhase,
        isCourseDeleted, isActivityDeleted, courseNames, course, getphasetime, studentGrades, activity, studentplanstatus, activityNames, isgetplanphase, childprofile, getchildstatus
    };
};

export default connect(mapStateToProps, { clearMessage, getChildProfile, getChildProfilestatus, EditCourseStatus, DeleteCourseStatus, CreatePlanStatus, EditPlanStatus, getCourse, getCourses, getActivities, getActivity, CreateCourse, CreateActivity, GetStudentPlanPhase, GetStudentPlan, getStudentGrades, getPhasetime, CreateCourseStatus, CreateCourseErrorStatus, CreateActivityStatus, CreateActivityErrorStatus, EditPhaseStatus, DeleteActivityStatus, GetStudentPlanPhaseStatus, EditCourse, EditActivity, GetStudentPlanStatus, GetStudentPlanOfParent })(withRouter(Index));
