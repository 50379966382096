import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Button, Dropdown, Modal,  } from "react-bootstrap";
import Select, { components } from "react-select";
import CreatableSelect from 'react-select/creatable';
import ReactQuill from "react-quill";
import { COURES_NAME_LABEL, COURES_TYPE_LABEL } from "../../../../../Constants/commonLabels";
import { CreateCourse, clearMessage } from '../../../../../redux/actions'
import { ALERT_HIDE } from "../../../../../redux/constants/CommonTypes";


const validation = Yup.object({
    // courseName: Yup.object().shape({
    //     value: Yup.string().nullable(),
    //     label: Yup.string().max(50, "Course name should not exceed 50 characters").required("*Required")
    // }).required("*Required").nullable(),
    courseName: Yup.object().required("*Required").nullable(),    //Max Length Validation
    courseType: Yup.object().required("*Required").nullable(),
    // gradeAchieved: Yup.object().nullable(),
    description: Yup.string().transform((value) => {
        return value.replace(/<[^>]+>/g, '').replace(/\&nbsp;+/g, ' ')
    }).max(750, "Description should not exceed 750 characters").nullable()
})

const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder}</Placeholder>
            {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
        </ValueContainer>
    );
};

const Index = (props) => {
    const [loading, setLoading] = useState(false)
    const [errorList, setErrorList] = useState({})
    const [courseNameOpts, setCourseNameOpts] = useState([])
    const [courseTypeOpts, setCourseTypeOpts] = useState([])
    const [gradeOpts, setGradeOpts] = useState([])
    const [editData, seteditData] = useState([])

    const dispatch = useDispatch()

    const { register,  handleSubmit, watch, control, formState: { errors } } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(
            validation
        ),
    });

    useEffect(() => {
        setErrorList({})
        setLoading(true)
        setInitialValues();
    }, [])

    useEffect(() => {
        console.log(props?.errorList, "props?.errorList");
        console.log(errors);
    }, [errors, props?.errorList])
    

    const setInitialValues = () => {
        let courseNameOptions = [];
        let courseTypeOptions = [];
        
        props?.courseNames?.length > 0 && props?.courseNames?.map((x, key) => {
            let temp = { label: x.courseName, value: x.courseName }
            courseNameOptions.push(temp);
        })

        props?.course?.length > 0 && props?.course?.map((x) => {
            let temp = { label: x.courseType, value: x.courseType }
            courseTypeOptions.push(temp);
        })

        // props?.studentGrades?.length > 0 && props?.studentGrades?.map((x, key) => {
        //     let temp = { label: x.grade, value: x.grade }
        //     gradeOptions.push(temp);
        // })
        setCourseNameOpts(courseNameOptions)
        setCourseTypeOpts(courseTypeOptions)
        // setGradeOpts(gradeOptions)
    }
    const onSubmit = (data) => {
        let payload = {
            accessibleAdvisors: [],
            courseName: data?.courseName?.value,
            courseType:  data?.courseType?.value,
            description: data?.description,
            // grade: data?.gradeAchieved?.value,
            phaseId: props?.semData?.id,
            planId: props?.semData?.planId
        }
        console.log(payload, "payload");
        props?.CreateCourse(payload, true);
    }
    let descLength = watch("description")

    return (
        <Modal size={'md'} show={props?.showAddCourse} onHide = {(e) => props?.hideModel(e)}  centered={true} dialogclassname={"mywork-popup"}>
            <Modal.Header closeButton>
                <Modal.Title>Add Course</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { props?.alertShow ? 
                    ((!props?.AddActivity || !props?.EditActivity ) && Object.keys(props?.errorList || {}) == 0) && 
                        <Alert 
                            {...props}
                            variant={props.customAlertSuccess ? 'success' : 'danger'}
                            onClose= {() => dispatch({ type: ALERT_HIDE })}
                            dismissible
                        >
                            {props.customAlertMsg}
                            
                        </Alert> : <></>
                }
                <form id="advData" onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-12 p-1" style={{ minHeight: '90px' }}>
                        <Controller
                            name="courseName"
                            control={control}
                            render={({ field }) =>
                                <CreatableSelect name="courseName" {...field} className="goals__form__select mb-0" classNamePrefix="mySelect"  
                                    closeMenuOnSelect={true} isClearable={false} options={courseNameOpts}
                                    components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null, }} placeholder={COURES_NAME_LABEL}
                                    styles={{
                                        container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible", zIndex: "3" }),
                                        valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', width: '170px' }),
                                        placeholder: (provided, state) => ({
                                            ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%",
                                            fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10
                                        })
                                    }} 
                                />
                            }
                        />
                        <div className="error-text" style={{ "position": "absolute" }} >{errors?.courseName?.label?.message}</div>
                        { errorList?.courseName && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.courseName}</div> }
                    </div>
                    <div className="col-12 p-1" style={{ minHeight: '90px' }}>
                        <Controller
                            name="courseType"
                            control={control}
                            render={({ field }) =>
                                <Select name="courseType" {...field}
                                    options={courseTypeOpts}
                                    placeholder={COURES_TYPE_LABEL} className="goals__form__select mb-0" classNamePrefix="mySelect"  closeMenuOnSelect={true} isClearable={false}
                                    components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                    styles={{
                                        container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible", zIndex: "2" }),
                                        valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', }),
                                        placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10 })
                                    }} 
                                />
                            }
                        />
                        <div className="error-text" style={{ "position": "absolute" }} >{errors?.courseType?.message}</div>
                        { errorList?.courseType && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.courseType}</div> }
                    </div>
                    {/* <div className="col-12 p-1" style={{ minHeight: '90px' }}>
                        <div className="input-floating-label">
                            <Controller
                                name="gradeAchieved"
                                control={control}
                                render={({ field }) =>
                                    <>
                                        <Select name="gradeAchieved" {...field}
                                        options={ gradeOpts } id="gradeAchieved"
                                        placeholder="Grade Achieved" className="goals__form__select mb-0" classNamePrefix="mySelect"
                                        closeMenuOnSelect={true} isClearable={false}
                                        components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                        styles={{
                                            container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible", zIndex: "1" }),
                                            valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', }),
                                            placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10 })
                                        }} />
                                        <p className="cta--text mb-2" style={{ "fontSize": "12px", "marginLeft": "20px", marginBottom: 0 }}>Optional</p>
                                    </>
                                }
                            />
                            <div className="error-text" style={{ "position": "absolute" }} >{errors?.gradeAchieved?.message}</div>
                            {errorList?.gradeAchieved && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.gradeAchieved}</div>}
                        </div>
                    </div> */}
                    <div className="col-12 p-1" style={{ minHeight: '80px' }} >
                        <div className="input-floating-label"  style={{ marginBottom: "20px"}}>
                            <Controller
                                name="description"
                                control={control}
                                render={({ field }) =>
                                    <ReactQuill className="textarea--primary textbox--rounded input" theme="snow" {...field} id="description"
                                        onKeyPress = {(event) => { if (!/^[a-zA-Z0-9!@#\$%\^\ \&*\)\(+._-]+$/.test(event.key)) { event.preventDefault(); } }}
                                    />
                                }
                            />
                            <label >Specify how you can help</label>

                            <div className="d-flex justify-content-between me-1">
                                {(errors?.description && <span className="error-text">Description only allow 750 Characters</span>) ||
                                    <p className="cta--text mb-2" style={{ "fontSize": "12px", "marginLeft": "20px", marginBottom: 0 }}>Optional</p>
                                } 
                                <div className="subtext"><span>{descLength?.replace(/<[^>]+>/g, '').replace(/\&nbsp;+/g, ' ')?.length || 0}</span>/750</div>
                            </div>
                            {errorList?.description && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.description}</div>}
                        </div>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="modalBtn" type="submit" form="advData" >Add</Button>
            </Modal.Footer>
        </Modal>
    )
}

const mapStateToProps = ({ commonData,  }) => {
    const { message, errorList, courseNames, course, studentGrades, alertShow, customAlertMsg, customAlertSuccess } = commonData;
    return { message, errorList, courseNames,course, studentGrades, alertShow, customAlertMsg, customAlertSuccess };
};
export default connect(mapStateToProps, { CreateCourse, clearMessage })(Index);