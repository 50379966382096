import React, { useState } from 'react'
import Notification from '../../../componentsNew/Common/Notification'

const Index = (props) => {

    return (
        <Notification type= "advisor"/>
    )
}

export default Index