import React, { useState } from 'react'
import Accordion from "react-bootstrap/Accordion";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { connect } from "react-redux";
import { } from "../../redux/actions";

const Index = (props) => {
    const [checkboxChecked, setCheckboxChecked] = useState(false);

    const handleChange = (e, data) => {
        setCheckboxChecked(e.target.checked);
        props.FilterChange(e.target.checked, data)
    }
    const PlanHandleChange = (e, data) =>{
        props.PlanFilter(e.target.checked, data?.planInfo?.id)
    }
    const clear = () => {
        props.ClearFilter()
        setCheckboxChecked(false)
    }
    return (
        <>
            <div className="accordion-top">
                <div className="row align-items-center">
                    <div className="col-6">
                        <div className="filter-name">Filter</div>
                    </div>
                    <div className="col-6 text-rights">
                        {/* <a className="cta--underline filter-underline" style={{"cursor":"pointer"}} onClick={clear} >Clear Filter</a> */}
                    </div>
                </div>
                <Accordion alwaysOpen>
                    {props?.courseFilter || props?.milesFilter ? (
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Plan</Accordion.Header>
                            <Accordion.Body>
                                <div className="accordion-list">
                                    <FormGroup>
                                    {props?.getstudentplan && props?.getstudentplan?.length > 0 && props?.getstudentplan?.map((data, index) => (
                                            <FormControlLabel control={<Checkbox />} label={data?.planInfo?.planName} onChange={(e) => PlanHandleChange(e, data)} key={index} />
                                        ))}
                                    </FormGroup>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    ) : null}
                    {props.planFilter || props.milesFilter ? (
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Status</Accordion.Header>
                            <Accordion.Body>
                                <div className="accordion-list">
                                    <FormGroup>
                                        {props?.planStatus && props?.planStatus?.length > 0 && props?.planStatus?.map((data, index) => (
                                            <FormControlLabel control={<Checkbox />} label={data} onChange={(e) => handleChange(e, data)} key={index} />
                                        ))}
                                    </FormGroup>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    ) : null}
                </Accordion>
            </div>
        </>
    )
}
const mapStateToProps = ({ studentData }) => {
    const { planStatus, getstudentplan } = studentData;
    return { planStatus, getstudentplan }
};

export default connect(mapStateToProps, {})(Index);
