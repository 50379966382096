import React, { useEffect, useState } from "react";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import "./timeline.less"
import ViewTimeLine from "./viewTimeLine";
import UpdateTimeLine from "./updateTimeLine";
import ReplyTimeLine from "./replyTimeLine";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { connect } from "react-redux";
import { GetMilestoneByCourse, getUserProfile, replyNotes, DeleteMileStoneNotes, DeleteMileStoneNotesStatus, GetMilestoneByActivity, resetActivityMilestoneData, updateReadUpdate, updateReadUpdateStatus } from "../../../redux/actions";
import { useComponentDidUpdate } from "../../../utils/useEffectHooks";
import ReplyIcon from '@mui/icons-material/Reply';
import { convertUTCTodateTimeZone } from "../../../utils/CommonUtils";
import { useImageUrl } from "../../../utils/UseImageURL";
import Loader from "../../../components/Loader";

const Index = (props) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const [replyOpen, setReplyOpen] = useState(false);

    useComponentDidUpdate(() => {
        if (props?.deleteNotes) {
            props?.DeleteMileStoneNotesStatus(false)

            setLoading(false);
        }
        if (props?.isMileStoneCourseStatus) {
            setLoading(false);
        }
        if (props?.isactivityMilestoneStatus) {
            setLoading(false);
        }
    }, [props?.deleteNotes, props.viewedUpdate, props?.isReplyNotes, props?.isMileStoneCourseStatus, props?.isactivityMilestoneStatus])

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleReplyOpen = (event) => {
        event.stopPropagation();
        setReplyOpen(true);
    }
    const handleReplyClose = () => setReplyOpen(false);

    const stripHTMLTags = (html) => {
        const tempElement = document.createElement("div");
        tempElement.innerHTML = html;
        return tempElement.textContent || tempElement.innerText || "";
    };

    const readUnread = () => {
        handleOpen()
        let payload = {
            milestoneStatusId: props?.data?.id,
            planId: props?.mileStoneData?.planId,
            milestoneId: props?.mileStoneData?.id,
            updateContext: props?.data?.update
        }

        if (!props?.data?.viewedBy?.includes(props?.userProfile?.id)) {
            props?.updateReadUpdate(payload)
        }
    }

    const handleDelete = (event) => {
        props?.deleteUpdate(event)
    }

    const filteredUsers = props?.mileStoneData?.users?.filter(user => user.id === props?.data?.postedBy);
    const imgUrl = useImageUrl(filteredUsers[0]?.avatarPath)
    const filterViews = props?.data?.viewedBy?.includes(props?.userProfile?.id)
    const filteredUpdates = props?.mileStoneData?.updates.filter(obj =>
        props?.mileStoneData?.updates.some(item => item.tagId === obj.id)
    );
    const filteredReply = filteredUpdates.filter(user => user.id === props?.data?.tagId);
    const resourceobject = props?.mileStoneData?.resources.find((item) => item.id === props.data.resourceId);

    return (
        <>
            <ReplyTimeLine data={props?.data} mileStoneData={props?.mileStoneData}
                postedBy={props?.data?.postedBy === props?.userProfile?.id ? "you" : filteredUsers?.[0]?.firstName}
                show={replyOpen} handleClose={handleReplyClose}
                addReply={(data) => props?.handleAddReply(data)}
            />
            {props?.data?.postedBy === props?.userProfile?.id ?
                open && <UpdateTimeLine mileStoneData={props?.mileStoneData} open={open} data={props?.data} handleClose={handleClose} /> :
                open && <ViewTimeLine data={props?.data} filterUser={filteredUsers && filteredUsers[0]} show={open} handleClose={handleClose} />
            }
            {loading && <Loader />}
            {!loading &&
                <div className="timeLineCard" style={{ border: `1px solid ${filterViews ? '#5C5C5C' : "#1C84EE"}` }}>
                    {filteredReply?.length > 0 &&
                        <div className="content-to-reply p-1" onClick={readUnread}>
                            {/* <p className="mb-0 postName">{filteredReply?.postedBy}</p> */}
                            <p className="mb-0 postContent">{filteredReply ? stripHTMLTags(filteredReply[0]?.update) : ""}</p>
                        </div>
                    }
                    <div className="timeLineCard__topSec"  onClick={readUnread}>
                        { imgUrl ? 
                            <img alt="profile" style={{ width: '34.9167px', borderRadius: '50%', height:'34.9167px', objectFit:'cover' }} src={imgUrl} loading="lazy" /> 
                            : 
                            <AccountCircleOutlinedIcon sx={{ fontSize: '2.2rem', fill: '#7a7979' }} />
                        }
                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "start" }}>
                            <div className="timeLineCard__topSec__updateDetails">
                                <p className="timeLineCard__topSec__updateDetails__userName">
                                    {props?.data?.postedBy === props?.userProfile?.id ? "You" : filteredUsers && filteredUsers[0]?.firstName}
                                </p>
                                <p className="timeLineCard__topSec__updateDetails__updated-time">
                                    {convertUTCTodateTimeZone(props?.data?.modifiedAt, props?.userProfile?.timezone)?.format('MMM DD YYYY hh:mm A')}
                                </p>
                            </div>
                            {!props?.StudentView &&
                                <div className="timeLineCard__topSec__iconSec">
                                    {props?.data?.postedBy !== props?.userProfile?.id &&
                                        <ReplyIcon id={`reply-${props?.id}`} style={{ fontSize: '1.5rem' }} sx={{ fill: '#5C5C5C', fontSize: '20px', cursor: "pointer" }} onClick={handleReplyOpen} />
                                    }
                                    {props?.data?.postedBy === props?.userProfile?.id &&
                                        <DeleteForeverOutlinedIcon id={`delete-${props?.id}`} onClick={handleDelete} style={{ fontSize: '1.5rem' }} sx={{ fill: '#E45D40', fontSize: '20px', cursor: "pointer" }} />}
                                </div>
                            }
                        </div>
                    </div>
                    <div className="timeLineCard__descp-sec" onClick={readUnread} >
                        <p className="descp" title={stripHTMLTags(props?.data?.update)}>{stripHTMLTags(props?.data?.update)}</p>
                    </div>
                    {resourceobject &&
                        <>
                            <div className="timeLineCard__descp-sec mt-1" id="readResource" onClick={readUnread}>
                                <p className="resourcename" title={resourceobject.name}>{resourceobject.name}</p>
                            </div>
                            <div className="timeLineCard__descp-sec">
                                <a href={resourceobject.resourceURL} title={resourceobject.resourceURL} target="_blank" rel="noopener noreferrer" className="resourcelink">{resourceobject.resourceURL}</a>
                            </div>
                        </>
                    }
                </div>
            }
        </>
    );
}
const mapStateToProps = ({ userProfileData, studentData }) => {
    const { userProfile } = userProfileData;
    const { deleteNotes, viewedUpdate, isReplyNotes, isMileStoneCourseStatus } = studentData;
    return { userProfile, deleteNotes, viewedUpdate, isReplyNotes, isMileStoneCourseStatus };
};

export default connect(mapStateToProps, { getUserProfile, updateReadUpdate, replyNotes, updateReadUpdateStatus, DeleteMileStoneNotes, GetMilestoneByActivity, resetActivityMilestoneData, GetMilestoneByCourse, DeleteMileStoneNotesStatus })(Index);
