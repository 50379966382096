import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { connect } from 'react-redux';
import { getDetailedUserProfile } from "../../../../../redux/actions";

const Index = (props) => {
    const [advisorname, setadvisorname] = useState("");
    const handleClose = () => {
        props?.onHide()
    };

    useEffect(() => {
        if (props?.data?.planInfo?.createdBy) {
            let userid = props?.data?.planInfo?.createdBy
            if (userid && advisorname === "") {
                let payload = {
                    userId: userid
                }
                props?.getDetailedUserProfile(payload)
            }
        }
        if (props?.detailedUserData) {
            setadvisorname(props?.detailedUserData?.userInfo?.fullName)
        }
    }, [props?.data, props?.detailedUserData, advisorname])

    const AcceptPlans = (e) => {
        e.preventDefault();
        handleClose()
        if(props?.title === "Accept Course"){
            props?.AcceptPlans(props?.data)
        }
        if(props?.title === "Reject Course"){
            props?.AcceptPlans(props?.data)
        }
        if(props?.title === "Accept Activity"){
            props?.AcceptPlans(props?.data)
        }
        if(props?.title === "Reject Activity"){
            props?.RejectPlans(props?.data)
        }
    }


    return (
        <>
            <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
                <Modal.Header closeButton onClick={handleClose} >
                    <Modal.Title id="contained-modal-title-vcenter">
                        {props?.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='modalContent'>
                    <div style={{ "display": "flex", "justifyContent": "center", "alignItems": "center", padding: "10px", "height": "100px", "textAlign": "center" }}>
                        {(props?.title === "Accept Course") &&
                            <h5>Please confirm that you wish to accept this {props?.data?.courseName ? props?.data?.courseName :props?.data?.courseInfo?.courseName } course suggestion from <span style={{ "color": "#1C84EE" }}>{advisorname ? advisorname : ""}</span></h5>
                        }
                        {(props?.title === "Reject Course") &&
                            <h5>Please confirm that you wish to reject this {props?.data?.courseName ? props?.data?.courseName :props?.data?.courseInfo?.courseName} course suggestion from <span style={{ "color": "#1C84EE" }}>{advisorname ? advisorname : ""}</span>. Course will be no longer accessible after this.</h5>
                        }
                        {(props?.title === "Accept Activity") &&
                            <h5>Please confirm that you wish to accept this {props?.data?.activityName ? props?.data?.activityName :props?.data?.activityInfo?.activityName } activity suggestion from <span style={{ "color": "#1C84EE" }}>{advisorname ? advisorname : ""}</span></h5>
                        }
                        {(props?.title === "Reject Activity") &&
                            <h5>Please confirm that you wish to reject this {props?.data?.activityName ? props?.data?.activityName :props?.data?.activityInfo?.activityName} activity suggestion from <span style={{ "color": "#1C84EE" }}>{advisorname ? advisorname : ""}</span>. Activity will be no longer accessible after this.</h5>
                        }
                    </div>
                </Modal.Body>
                <div style={{ "display": "flex", "justifyContent": "center", "alignItems": "center", "height": "60px", padding: "10px 10px 20px 10px" }}>
                    <Button style={{ "backgroundColor": "#1C84EE" }} onClick={(e) => AcceptPlans(e)} >Confirm</Button>
                </div>
            </Modal>
        </>
    )
}

const mapStateToProps = ({ studentData, advisorData, commonData }) => {
    const { } = studentData;
    const { } = advisorData
    const { detailedUserData } = commonData
    return { detailedUserData }
};

export default connect(mapStateToProps, { getDetailedUserProfile })(Index);

