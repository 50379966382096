import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Modal, Button } from 'react-bootstrap'
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import SemesterCard from '../../Cards/Common/PhaseCard';
import AddPhaseCourse from "../../../../pages/Advisor/MyWork/Modals/AddPhaseCourse";
import AddPhaseActivity from "../../../../pages/Advisor/MyWork/Modals/AddPhaseActivity";
import CustomAlert from "../../../../components/CustomAlert";
import {
  CreateActivity, CreateActivityErrorStatus, CreateActivityStatus, setPhaseTimeStatus,
  CreateCourse, CreateCourseErrorStatus, CreateCourseStatus, CreatePlanStatus, DeleteActivityStatus, GetStudentPlanStatus,
  DeleteCourseStatus, EditPhaseStatus, EditPlanStatus, GetStudentPlan, GetStudentPlanPhase, GetStudentPlanPhaseStatus,
  clearMessage, getActivities, getActivity, getCourse, getCourses, EditActivity, getPhasetime, getStudentGrades,
  EditCourse, EditCourseStatus, addCourse, resetStudentPlanPhaseData, GetStudentPlanOfParent 
} from "../../../../redux/actions";
import { withRouter } from "../../../../redux/store/navigate";
import "./phases.less";
import { useParams } from 'react-router-dom';
import Loader from '../../../../components/Loader';


const Index = (props) => {
  const [loading, setloading] = useState(false)
  const [showSemesters, setShowSemesters] = useState(false);
  const [addcourse, setAddcourse] = useState(false)
  const [addactivity, setAddactivity] = useState(false)
  const [semesterData, setSemesterData] = useState([])
  const [displayAlert, setDisplayAlert] = useState(false)
  const [footerClicked, setFooterClick] = useState(false)
  const { id } = useParams();



  useEffect(() => {
    setSemesterData([])
    if (props?.PlanId === "") {
      let value = {
        "searchString": "",
        "pageNo": 1,
        "limit": 10,
        "status": [],
        "sortOrder": "latest",
        "studentId": null
      }
      props?.GetStudentPlan(value)
    }
    else {
      let values = { "planId": props?.PlanId || props?.newPlanId }
      if (props?.PlanId || props?.newPlanId) {
        props?.GetStudentPlanPhase(values)
        props?.getPhasetime(props?.PlanId || props?.newPlanId);
      }
    }
    props?.clearMessage()
    setloading(true);
    return () => {
      props?.resetStudentPlanPhaseData([]);
      setSemesterData([])
    }
  }, [])

  useEffect(() => {
    if (props?.getstudentplan) {
      PhaseApiCall()
    }
  }, [props?.getstudentplan])

  useEffect(() => {
    if (showSemesters) {
      setSemesterData(props?.getstudentplanphase)
    }
    if (!showSemesters) {
      const filteredData = props?.getstudentplanphase?.length > 0 && props?.getstudentplanphase?.filter(phase => phase?.courses?.length > 0 || phase?.activities?.length > 0) || [];
      setSemesterData(filteredData)
    }
    if (props?.isCourseCreated) {
      Coursepopupshow(false);
      props?.CreateCourseStatus(false);
      setloading(true)
      studentgetplans()
    }
    if (props?.isActivityCreated) {
      activityPopupShow(false);
      setloading(true)
      props?.CreateActivityStatus(false);
      studentgetplans()
    }
    if (props.isCourseDeleted) {
      setloading(true)
      props.DeleteCourseStatus(false);
      studentgetplans()
    }
    if (props.isActivityDeleted) {
      setloading(true)
      props.DeleteActivityStatus(false);
      studentgetplans()
    }
    if (props?.studentplanstatus) {
      props.GetStudentPlanStatus(false)

    }
    if (props?.isgetplanphase && props?.phaseTimeStatus) {
      props?.setPhaseTimeStatus(false)
      props?.GetStudentPlanPhaseStatus(false)
      setloading(false)
    }
    if (props?.isEditPhase) {
      props?.EditPhaseStatus(false)
      studentgetplans()
    }
    if (props?.undoCourseSuggestionStatus) {
      studentgetplans();
    }
    if (props?.undoActivitySuggestionStatus) {
      studentgetplans()
    }
    // if (props?.undoCourseDropSuggestionStatus) {
    //   studentgetplans()
    // }
    // if (props?.activityDropSuggestionStatus) {
    //   studentgetplans()
    // }
    // if (props?.undoActivityDropSuggestionStatus) {
    //   studentgetplans()
    // }
    // if (props?.undoActivitySuggestionStatus) {
    //   studentgetplans()
    // }
  }, [showSemesters, props?.phaseTimeStatus, props?.isgetplanphase, props?.studentplanstatus,
    props?.getstudentplanphase, props?.isCourseCreated, props?.isActivityCreated,
    props.isCourseDeleted, props.isActivityDeleted, props?.isEditPhase, props?.undoCourseSuggestionStatus,
    props.undoCourseDropSuggestionStatus, props?.activityDropSuggestionStatus,
    props?.undoActivityDropSuggestionStatus, props?.undoActivitySuggestionStatus
  ])

  const PhaseApiCall = () => {
    if (props?.getstudentplan && props?.userProfile?.currentRole != "advisor") {
      let plans = props?.getstudentplan[0]?.planInfo
      let plansId = props?.PlanId ? props?.PlanId : plans?.id
      if (plansId != "") {
        let values = { "planId": plansId }
        props?.GetStudentPlanPhase(values)
        props?.getPhasetime(plansId);
      }
    } else {
      if (props?.newPlanId) {
        let values = { "planId": props?.newPlanId }
        props?.GetStudentPlanPhase(values)
        props?.getPhasetime(props?.newPlanId);
      }
    }
  }

  const studentgetplans = () => {
    let value = {
      "searchString": "",
      "pageNo": 1,
      "limit": 10,
      "status": [],
      "sortOrder": "latest",
      "studentId": localStorage.getItem("parentViaChild") ? localStorage.getItem("parentViaChild") : id
    }

    if (localStorage.getItem("parentViaChild")) {
      props?.GetStudentPlanOfParent(value)
    } else {
      props?.GetStudentPlan(value)
    }
  }

  // Popups
  const Coursepopupshow = (status) => {
    setAddcourse(status)
  }
  const activityPopupShow = (status) => {
    setAddactivity(status)
  }

  const handleSwitchChange = () => {
    setShowSemesters(!showSemesters);
  };

  const handleCourseSubmit = (data, editCourse) => {
    let plans = props?.getstudentplan[0]?.planInfo
    let plansId = plans?.id
    let payload = {
      planId: plansId,
      phaseId: data?.phaseId || "",
      courseName: data?.courseName || "",
      courseType: data?.courseType || "",
      description: data?.description || "",
      accessibleAdvisors: [],
      grade: data?.grade || "",
    };
    console.log(data, "----data");
    if (editCourse) {
      props.EditCourse(payload, editCourse?.id)
    } else {
      props.CreateCourse(payload)
    }
  }
  const handleActivitySubmit = (data, editCourse) => {
    let plans = props?.getstudentplan[0]?.planInfo
    let plansId = plans?.id
    let payload = {
      planId: plansId,
      phaseId: data?.phaseId || "",
      activityName: data?.activityName || "",
      activityType: data?.activityType || "",
      description: data?.description || "",
      accessibleAdvisors: [],
      grade: data?.grade || "",
    };
    if (editCourse) {
      props.EditActivity(payload, editCourse?.id)
    } else {
      props.CreateActivity(payload)
    }
  }

  const handleChangeDataPsw = (data) => {
  };

  return (
    <>
      {displayAlert && <div style={{ "width": "100%", "display": "flex", "justifyContent": "center", }}> {<CustomAlert />}</div>}
      {addcourse && <AddPhaseCourse showAddCourse={addcourse} handleSubmit={handleCourseSubmit} hideModel={() => Coursepopupshow(false)} />}
      {addactivity && <AddPhaseActivity showAddActivity={addactivity} handleSubmit={handleActivitySubmit} hideModel={() => activityPopupShow(false)} />}

      <div>
        <Modal size={'lg'} {...props} fullscreen={true} centered={true}>
          <Modal.Header closeButton ></Modal.Header>
          <Modal.Body>
            {loading && <Loader />}
            <div className="set_initial_plan myWorkPhase">
              <div className="role_card phasesection">
                {props?.userProfile?.currentRole === "advisor" ?
                  <h1 className="role_title text-center">Suggest courses and activities to plan</h1>
                  :
                  <h1 className="role_title text-center">Add courses and activities to plan</h1>
                }
                <p className='phase_helpertext'>Add courses and activities you have completed, currently taking and planning to take up.  We will connect you to advisors who can review your plan and suggest changes to successfully meet your goals, We can also connect you with mentors for specific courses and activities. </p>
                <div className="initial_plan">
                  <div className='Addcoursecnt'>
                    <div className='CourseBox'>
                      <div style={{ "display": "flex", "justifyContent": "center" }} >
                        <Button className='AddBtn mb-2' onClick={() => Coursepopupshow(true)} >Add a course</Button>
                      </div>
                      <div style={{ "display": "flex", "justifyContent": "center" }} >
                        <div className='greycard' >
                          <p>Add courses you plan to take and may need help with. You may also add completed and in progress courses for advisors to review and suggest future courses based on your current progress and future goals</p>
                        </div>
                      </div>
                    </div>
                    <div className='ActvityBox'>
                      <div style={{ "display": "flex", "justifyContent": "center" }} >
                        <Button className='AddBtn mb-2' onClick={() => activityPopupShow(true)} >Add an activity</Button>
                      </div>
                      <div style={{ "display": "flex", "justifyContent": "center" }} >
                        <div className='greycard' >
                          <p>Add activities like extra curriculors and College Prep (SAT, Apps etc)  </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className='mt-1'>
                  <div className='semesterSwitch' style={{ "display": "flex", "justifyContent": "flex-end" }}>
                    <FormControlLabel
                      value="start"
                      control={<Switch color="primary" checked={showSemesters} onChange={handleSwitchChange} />}
                      label="Show all semesters"
                      labelPlacement="start"
                    />
                  </div>
                  <div className='mt-2'>
                    <div className='semester-section mt-4'>
                      <SemesterCard data={semesterData} semesterCardData={footerClicked} handleFormValues={(data) => handleChangeDataPsw(data)} handleCourseSubmit={(data, editCourse) => handleCourseSubmit(data, editCourse)} handleActivitySubmit={(data, editCourse) => handleActivitySubmit(data, editCourse)} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

const mapStateToProps = ({ userProfileData, commonData, studentData, advisorData }) => {
  const { courseNames, course, getphasetime, studentGrades, activity, activityNames, phaseTimeStatus } = commonData
  const { userProfile } = userProfileData;
  const { createStudentPlanStatus, newPlanId, undoCourseSuggestionStatus, undoCourseDropSuggestionStatus, activityDropSuggestionStatus,
    undoActivityDropSuggestionStatus, undoActivitySuggestionStatus } = advisorData
  const { getstudentplanphase, getstudentplan, isCourseCreated, isCourseError, isActivityCreated, isActivityError,
    isEditPhase, isCourseDeleted, isActivityDeleted, isgetplanphase, isEditCourse, studentplanstatus } = studentData;

  return {
    userProfile, getstudentplanphase, getstudentplan, isCourseCreated, isCourseError, isEditCourse, isActivityCreated, isActivityError, isEditPhase, phaseTimeStatus,
    isCourseDeleted, isActivityDeleted, courseNames, course, getphasetime, studentGrades, activity, activityNames, isgetplanphase, createStudentPlanStatus, newPlanId,
    studentplanstatus, undoCourseSuggestionStatus, undoCourseDropSuggestionStatus, activityDropSuggestionStatus, undoActivityDropSuggestionStatus,
    undoActivitySuggestionStatus
  };
};

export default connect(mapStateToProps, {
  clearMessage, setPhaseTimeStatus, EditCourseStatus, DeleteCourseStatus, CreatePlanStatus, EditPlanStatus, getCourse, getCourses,
  getActivities, getActivity, CreateCourse, CreateActivity, GetStudentPlanPhase, GetStudentPlan, getStudentGrades, getPhasetime, CreateCourseStatus, GetStudentPlanStatus,
  CreateCourseErrorStatus, CreateActivityStatus, CreateActivityErrorStatus, EditPhaseStatus, DeleteActivityStatus, GetStudentPlanPhaseStatus, EditCourse,
  EditActivity, resetStudentPlanPhaseData, GetStudentPlanOfParent 
})(withRouter(Index));

