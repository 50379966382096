import React, { useState, useEffect } from "react";
import "../profile.less"
import { Button, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ConfirmPopup from "../confirmUserPopup";

export const ConnectedCardParent = (props) => {
    const [disconnectData, setDisconnectData] = useState([]);
    const [confirmopen, setOpen] = useState(false);

    const handleDisconnect = (data) => {
        props?.handleDisconnectUser(data)
    }

    const handleConfirmOpen = (data) => {
        setDisconnectData(data)
        console.log(data, "datassss")
        setOpen(true);
    }
    const handleClose = () => setOpen(false);

    console.log(props?.invitedParent, "props?.parentData")

    return (
        <>
            <ConfirmPopup show={confirmopen} data={disconnectData} handleClose={handleClose} handleDisconnectUser={(data) => props?.handleDisconnectUser(data)} />

            <p className="profile__form__sub-title">Your spouse accounts</p>

            {props && props?.parentData?.map((data) => (
                <div className="editrightcnt">
                    <div className="rightbtncnt mt-2 ">
                        <Card className="cardContent" sx={{ display: 'flex' }}>
                            <Grid container spacing={2} sx={{ padding: '0.5rem' }}>
                                <Grid  >
                                    {data?.avatarUrl ?
                                        <img src={data && data?.avatarUrl} alt="profileimage" className="profileimage" style={{ width: '112px', height: '112px', padding: '13px' }} />
                                        :
                                        <AccountBoxIcon sx={{ fontSize: '4rem', fill: 'grey' }} />
                                    }
                                </Grid>
                                <Grid >
                                    <p className="profile__form__sub-title childCard">{data?.firstName || data?.email}</p>
                                    {/* <p style={{ fontSize: '16px', color: '#5C5C5C' }}>{data?.currentRole}</p>
                                    <p style={{ fontSize: '14px', color: '#5C5C5C' }}>Last activity 13hrs ago </p> */}
                                </Grid>
                                <Grid item style={{ padding: '0px' }} className="actionBtns">
                                    <div className="textRight"><Button variant="outlined" onClick={() => handleConfirmOpen(data)} className="outLineBtnRed" sx={{ marginTop: '1rem' }}>Disconnect</Button></div>
                                </Grid>
                            </Grid>
                        </Card>
                    </div>
                </div>
            ))}


            {
                props && props?.parentData?.length <= 0 && (
                    <>
                        {props && props?.invitedParent?.length > 0 ? (
                            <>
                                <p className="profile__form__sub-title">Invited Spouse Accounts</p>
                                {props?.invitedParent.map((data) => (
                                    <div className="editrightcnt" key={data?.id || data?.avatarUrl || data?.name}>
                                        <div className="rightbtncnt mt-2">
                                            <Card className="cardContent" sx={{ display: 'flex' }}>
                                                <Grid container spacing={2} sx={{ padding: '0.5rem' }}>
                                                    <Grid>
                                                        {data?.avatarUrl ? (
                                                            <img src={data.avatarUrl} alt="profileimage" className="profileimage" style={{ width: '112px', height: '112px', padding: '13px' }} />
                                                        ) : (
                                                            <AccountBoxIcon sx={{ fontSize: '4rem', fill: 'grey' }} />
                                                        )}
                                                    </Grid>
                                                    <Grid>
                                                        <p className="profile__form__sub-title" style={{ marginTop: '1rem' }}>{data || 'Unnamed'}</p>
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                        </div>
                                    </div>
                                ))}
                            </>
                        ) : (
                            <div className="Already_Connect_Account">
                                <div className="Already_Connect_Account_Title">You have not connected with spouse</div>
                            </div>
                        )}
                    </>
                )
            }




        </>
    )
}
