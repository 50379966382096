import React, {useEffect} from 'react';
import {Route, Routes} from "react-router-dom";

import StudentDefaultLayout from "../layoutsNew/Student/DefaultLayout";
import ParentDefaultLayout from "../layoutsNew/Parent/defaultLayout";
import { DefaultLayout as AdvisorDefaultLayout} from "../layoutsNew/Advisor/Default";

import { LBChatProvider, ChatRouter } from "leapbrains-chat-messaging";
import "leapbrains-chat-messaging/dist/style.css";
import { useSelector, useDispatch} from "react-redux";
import { getUserProfile } from '../redux/actions';
import { RequireAuth} from "../utils/AuthUtils";

function ChatComponent() {
  return <div className="lbc">
    <LBChatProvider>
      <ChatRouter></ChatRouter>
    </LBChatProvider>
  </div>;
}

function MultiRoleChatComponent() {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.userProfileData.userProfile);
  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);
  if (userProfile?.currentRole === "student") {
    return <StudentDefaultLayout compact={true} />;
  }
  if (userProfile?.currentRole === "parent") {
    return <ParentDefaultLayout compact={true} />;
  }
  if (userProfile?.currentRole === "advisor") {
    return <AdvisorDefaultLayout />;
  }
  return null;
}

function MessagingRoutes() {
  return (
      <Routes>
          <Route path="inbox/*" element={<RequireAuth><MultiRoleChatComponent /></RequireAuth>}>
            <Route path="*" element={<ChatComponent />} />
          </Route>
      </Routes>
  );
}

export default MessagingRoutes;