import React from "react";
import "./ratingSec.less";
import { LinearProgress } from "@mui/material";

const Index = ({ reviewSummary }) => {
    const getPercentage = (stars) => {
        const totalReviews = reviewSummary?.totalNoofReviews || 0;
        return totalReviews ? Math.round((stars / totalReviews) * 100) : 0;
    };
    console.log(reviewSummary, "reviewSummary")
    return (
        <div className="rating-sec">
            <div className="rating-sec__progressBar">
                <p className="rating-sec__progressBar__label">5 Stars</p>
                <LinearProgress
                    variant="determinate"
                    className="rating-sec__progressBar__linearRating"
                    value={reviewSummary !== null ? getPercentage(reviewSummary?.noof5StarReviews) : 0}
                />
                <span className="rating-sec__progressBar__percentage">
                    {getPercentage(reviewSummary?.noof5StarReviews)}%
                </span>
            </div>
            <div className="rating-sec__progressBar">
                <p className="rating-sec__progressBar__label">4 Stars</p>
                <LinearProgress
                    variant="determinate"
                    className="rating-sec__progressBar__linearRating"
                    value={reviewSummary !== null ? getPercentage(reviewSummary?.noof4StarReviews) : 0}
                />
                <span className="rating-sec__progressBar__percentage">
                    {getPercentage(reviewSummary?.noof4StarReviews)}%
                </span>
            </div>
            <div className="rating-sec__progressBar">
                <p className="rating-sec__progressBar__label">3 Stars</p>
                <LinearProgress
                    variant="determinate"
                    className="rating-sec__progressBar__linearRating"
                    value={reviewSummary !== null ? getPercentage(reviewSummary?.noof3StarReviews) : 0}
                />
                 <span className="rating-sec__progressBar__percentage">
                    {getPercentage(reviewSummary?.noof3StarReviews)}%
                </span>
            </div>
            <div className="rating-sec__progressBar">
                <p className="rating-sec__progressBar__label">2 Stars</p>
                <LinearProgress
                    variant="determinate"
                    className="rating-sec__progressBar__linearRating"
                    value={reviewSummary !== null ? getPercentage(reviewSummary?.noof2StarReviews) : 0}
                />
                 <span className="rating-sec__progressBar__percentage">
                    {getPercentage(reviewSummary?.noof2StarReviews)}%
                </span>
            </div>
            <div className="rating-sec__progressBar">
                <p className="rating-sec__progressBar__label">1 Star</p>
                <LinearProgress
                    variant="determinate"
                    className="rating-sec__progressBar__linearRating"
                    value={reviewSummary !== null ? getPercentage(reviewSummary?.noof1StarReviews) : 0}
                />
                 <span className="rating-sec__progressBar__percentage">
                    {getPercentage(reviewSummary?.noof1StarReviews)}%
                </span>
            </div>
        </div>
    );
};

export default Index;
