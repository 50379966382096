import React, { useEffect, useRef, useState } from "react";
import '../../../assets/css/schedule.less';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import timeGridPlugin from "@fullcalendar/timegrid";
import momentPlugin from '@fullcalendar/moment';
import moment from "moment";
import Select from "react-select";
import { clearMessage, listScheduledEvents, confirmScheduledEventStatus, setListScheduledEventStatus, getplanAdvisor, resetScheduledEvents } from "../../../redux/actions"
import { connect } from "react-redux";
import { withRouter } from "../../../redux/store/navigate";
import ConfirmEvent from "../Modals/ConfirmEvent"
import Loader from '../../../components/Loader'
import { convertUTCTodateTimeZone, GetCurrentTimezone } from "../../../utils/CommonUtils";
import dayjs from 'dayjs';

const selectStyle = {
    menu: (provided) => ({
        ...provided,
        zIndex: 99,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected, }) => {
        return {
            ...styles,
            fontWeight: isFocused ? '600' : '400',
            color: isFocused ? 'rgba(0, 0, 0, 1)' : 'rgba(92, 92, 92, 1)',
            backgroundColor: "white",
            cursor: isDisabled ? 'not-allowed' : 'default',
        };
    },
}

const CalendarViews = [
    { value: 'dayGridMonth', label: 'Month' },
    { value: 'timeGridWeek', label: 'Week' },
    { value: 'timeGridDay', label: 'Day' }
];

const renderDayCell = (dayCellContent) => {
    return (
        <div className="daycell-head">
            <div>{dayCellContent.dayNumberText}</div>
        </div>
    );
}

const Index = (props) => {
    const [openEventModal, setOpenEventModal] = useState(false)
    const [eventData, setEventData] = useState({})
    const [differentUserIds, setDifferentUserIds] = useState([]);
    const [loading, setLoading] = useState(false)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    let calenderRef = useRef();

    useEffect(() => {
        props.listScheduledEvents()
        setLoading(true)

        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            props?.resetScheduledEvents();
            window.removeEventListener('resize', handleResize);
        };
    }, [])
      
    useEffect(() => {
        if(props.eventConfirmationStatus){
            props.listScheduledEvents()
            handleCloseEventModal()
            setLoading(false)
            props.confirmScheduledEventStatus(false)
        }
        if(props.listScheduledEventsStatus){
            props.setListScheduledEventStatus(false);
            setLoading(false)
        }else{
            setLoading(false)
        }
    }, [props.eventConfirmationStatus, props.listScheduledEventsStatus])

    const getDifferentUserIds = () => {
        const userProfileIds = props.userProfile.id;
        const differentIds = [];

        props.scheduledEvents?.forEach((event) => {
            event?.userInvitationStatuses?.forEach((invitationStatus) => {
                if (!userProfileIds?.includes(invitationStatus?.userId)) {
                    differentIds?.push(invitationStatus?.userId);
                }
            });
        });

        return differentIds;
    };

    useEffect(() => {
        if (Array.isArray(props.scheduledEvents) && props.userProfile?.id) {
            const differentIds = getDifferentUserIds();
            setDifferentUserIds(differentIds);
        }
    }, [props.scheduledEvents, props.userProfile]);

    useEffect(() => {
        if( differentUserIds?.length > 0 ){
            const removeDuplicates = (arr) => {
                return arr.filter((item, index) => arr.indexOf(item) === index);
            };
            
            const uniqueArray = removeDuplicates(differentUserIds);
            let payload = {
                userIds: uniqueArray
            }
            props?.getplanAdvisor(payload)
        }
    }, [differentUserIds])

    const handleSelect = (valObj, action) => {
        if (action === "clear") {
            return;
        } else {
            calenderRef.current.getApi().changeView(valObj.value);
        }
    }

    const handleOpenEventModal = () => {
        setOpenEventModal(true)
        props.clearMessage()
    };
    const handleCloseEventModal = () => {
        setOpenEventModal(false)
        props.clearMessage()
    };

    const renderEventContent = (arg) => {
        const { start, extendedProps } = arg.event;        
        const currentDate = GetCurrentTimezone(props?.userProfile?.timezone)
        const isPastEvent = (currentDate > dayjs(start).format('YYYY-MM-DDTHH:mm:ss'));
        let eventStatus = arg.event?.extendedProps?.invitationStatus?.find((status) => status?.userId === props.userProfile?.id)
        const backgroundColor = eventStatus?.status === "pending" ? '#00AE31' : eventStatus?.status === "rejected" ? '#FF6565' : eventStatus?.status === 'reScheduled' ? "#FDB560" : '#3297FF';
        let eventStyle = {};
        
        if (isPastEvent) {
            eventStyle['borderTop'] = `.3px solid ${backgroundColor}`;
            eventStyle['borderBottom'] = `.3px solid ${backgroundColor}`;
            eventStyle['backgroundColor'] = '#fff';
            eventStyle['color'] = backgroundColor;
        }else {
            eventStyle['backgroundColor'] = backgroundColor;
        }
        const eventTitle = arg.event?.extendedProps?.details?.eventType === "freeSession" ? `${arg.event.title} - Consultation session ` :  `${arg.event.title} - Paid session `
        return (
            <div className="event" style={eventStyle}>
                <div className="eventMonthview">
                    <div className="fc-event-time"  style={isPastEvent ? {color: backgroundColor} : {}} title={moment(arg.event.start)?.format("h:mm A")}>
                        {moment(arg.event.start)?.format("h:mm A")}
                    </div>
                    <div className="fc-event-title" style={isPastEvent ? {color: backgroundColor} : {}} title={eventTitle}>{eventTitle}</div>
                </div>
                <div className="eventTimeGridview">
                    <div className="eventTime" style={isPastEvent ? {color: backgroundColor} : {}} title={`${moment(arg.event.start).format("h:mm A")} - ${moment(arg.event.end).format("h:mm A")}`}>
                    {`${moment(arg.event.start)?.format("h:mm A")} - ${moment(arg.event.end)?.format("h:mm A")}`}
                    </div>
                    <div className="eventTitle" style={isPastEvent ? {color: backgroundColor} : {}}>{eventTitle}</div>
                </div>
            </div>
        );
    };

    let events = props.scheduledEvents?.map( event => { 
        let advisorNames = event.userIds.map(userId => {
            let advisor = props?.advisorlistdata?.length> 0 &&  props?.advisorlistdata?.find(advisor => advisor.id === userId);
            return advisor ? advisor.fullName : "";
        });
        return ({
            title: advisorNames ? advisorNames?.filter(name => name).join(', '): "",
            start: convertUTCTodateTimeZone(event.eventTime,props?.userProfile?.timezone)?.format('YYYY-MM-DD HH:mm:ss'),
            end: convertUTCTodateTimeZone(event.eventClosingTime,props?.userProfile?.timezone)?.format('YYYY-MM-DD HH:mm:ss'),
            details: {
                id: event?.id,
                createdBy: event?.createdBy,
                eventType: event?.eventType,
                eventDuration: event?.eventDuration,
                eventStatus: event?.eventStatus,
                meetingDescription: event?.meetingDescription,
                meetingLocation: event?.meetingLocation
            },
            invitationStatus: event.userInvitationStatuses

        }) 
    })

    const handleOpenEvent = (arg) => {
        const { title, start, end, extendedProps } = arg.event;
        console.log(start, "___________________abcd");
        handleOpenEventModal()
        setEventData({
            ['title'] : title, 
            ['start']: start,
            ['end']: end,
            ['inviteStatus']: extendedProps?.invitationStatus,
            ...extendedProps?.details,
        })
    }

    return (
        <>
            {loading && <Loader />}
            <div className="schedule-mobile-top">
                <div className="dashboardtop">
                    <div className="dashboard-top-text">
                        <h2>Schedule</h2>
                    </div>
                </div>
            </div>
            <div className="schedule">
                <div className="calendar-outer">
                    <div className="calendar-box">
                        <div className="schedule-head-box">
                            <div className="right">
                                <div className="cal-select-month">
                                    <Select
                                        closeMenuOnSelect={true}
                                        options={CalendarViews}
                                        placeholder={"Month"}
                                        styles={selectStyle}
                                        onChange={(value, { action }, name) => handleSelect(value, action)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="small-cal">
                            <div className="left">
                                <div className="cal-select-month">
                                    <Select
                                        closeMenuOnSelect={true}
                                        options={CalendarViews}
                                        placeholder={"Month"}
                                        styles={selectStyle}
                                        onChange={(value, { action }, name) => handleSelect(value, action)}
                                    />
                                </div>
                            </div>
                            {/* <div className="right">
                                <svg width="39" height="39" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" fill="#1B1C1E"
                                        d="M17 13C17 12.4477 17.4477 12 18 12H19C19.5523 12 20 12.4477 20 13V14H27C27.5523 14 28 14.4477 28 15C28 15.5523 27.5523 16 27 16H20V17C20 17.5523 19.5523 18 19 18H18C17.4477 18 17 17.5523 17 17V16H13C12.4477 16 12 15.5523 12 15C12 14.4477 12.4477 14 13 14H17V13ZM13 24C12.4477 24 12 24.4477 12 25C12 25.5523 12.4477 26 13 26H22V27C22 27.5523 22.4477 28 23 28H24C24.5523 28 25 27.5523 25 27V26H27C27.5523 26 28 25.5523 28 25C28 24.4477 27.5523 24 27 24H25V23C25 22.4477 24.5523 22 24 22H23C22.4477 22 22 22.4477 22 23V24H13Z"
                                    />
                                </svg>
                                <div className="cal-add-btn">
                                    <button className="btn cta--rounded cta-primary">+</button>
                                </div>
                            </div> */}
                        </div>
                        <FullCalendar
                            ref={calenderRef}
                            customButtons={{
                                myCustomButton: { icon: "calendar" },
                            }}
                            plugins={[ dayGridPlugin, interactionPlugin, timeGridPlugin, momentPlugin ]}
                            eventContent={renderEventContent}
                            dayCellContent={renderDayCell}
                            now={GetCurrentTimezone(props?.userProfile?.timezone)}
                            views={{
                                month: {
                                    titleFormat: "MMM['] YYYY",
                                    eventTimeFormat: "h:mm",
                                    eventClassNames: "monthview-event-class",
                                    dayMaxEventRows: screenWidth > 1000 ? 3 : 2
                                },
                                week: {
                                    type: "weekgrid",
                                    dayHeaderFormat: "ddd  D",
                                    dayHeaderContent: ({ date }) => {
                                        const fd = date.getDay();
                                        const fdate = date.getDate();
                                        const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
                                        return (
                                            <div>
                                                <div className="week-event-Day">
                                                    {weekdays[fd]}
                                                </div>
                                                <div className="week-event-Date">
                                                    {fdate}
                                                </div>
                                            </div>
                                        );
                                    },
                                    titleFormat: "MMM['] YYYY",
                                    slotDuration: "01:00:00",
                                    slotLabelClassNames: "time-hours",
                                    timeFormat: "H(:mm)",
                                    scrollTime: "09:00:00",
                                    slotLabelFormat: "h  A",
                                    allDaySlot: false,
                                    eventClassNames: "week-view-event-css",
                                    dayHeaderClassNames: "weekview-day-header",
                                    titleRangeSeparator: "-",
                                    dayMaxEventRows: 2
                                },
                                day: {
                                    titleFormat: "DD MMM['] YYYY",
                                    allDaySlot: false,
                                    scrollTime: "09:00:00",
                                    slotDuration: "01:00:00",
                                    slotLabelClassNames: "day-slot-class",
                                    slotLabelFormat: "h  A",
                                    type: "daygrid",
                                    eventTimeFormat: "H : mm a",
                                    dayHeaderClassNames: "dayview-header",
                                    slotLaneClassNames: "dayview-slot-class",
                                    eventClassNames: "day-view-event-css",
                                },
                            }}
                            events={events}
                            eventClick= {handleOpenEvent}
                            // datesSet={}
                            themeSystem="bootstrapFontAwesome"
                            height="930px"
                            editable={false}
                            selectable="true"
                            weekNumberCalculation="ISO"
                            titleFormat={{ year: "numeric", month: "short" }}
                            headerToolbar={{
                                left: "title,",
                                center: "prev,myCustomButton,next",
                                right: "",
                            }}
                        />
                    </div>
                </div>
            </div>
            { openEventModal &&
                <ConfirmEvent show={openEventModal} onHide={handleCloseEventModal} data={eventData}/>
            }
        </>
    );
};

const mapStateToProps = ({ commonData, advisorData, userProfileData, studentData }) => {
    const { scheduledEvents, listScheduledEventsStatus } = commonData;
    const { eventConfirmationStatus } = advisorData;
    const {advisorlistdata} = studentData;
    const { userProfile } = userProfileData;
    return { scheduledEvents, eventConfirmationStatus, listScheduledEventsStatus, userProfile, advisorlistdata };
};

export default connect( mapStateToProps, { getplanAdvisor, clearMessage, listScheduledEvents, confirmScheduledEventStatus, setListScheduledEventStatus, resetScheduledEvents } )( withRouter(Index) );
