import React, { useState } from 'react'
import Notification from '../../../componentsNew/Common/Notification'

const Index = (props) => {
    return (
        <>
            <Notification type="student"/>
        </>
    )
}

export default Index