import React, { useEffect, useState } from 'react'
import "./advisorcard.less"
import { Grid } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import PillSection from "../../../../../componentsNew/Common/PillSection"
import { useImageUrl } from '../../../../../utils/UseImageURL';

const Index = (props) => {
    const [courseNames, setCourseNames] = useState([]);

    useEffect(() => {
        if (props?.data?.requestReceivedUser?.advisorProfile) {
            const advisorCourses = props?.data?.requestReceivedUser?.advisorProfile?.advisorCourses || [];
            const advisorCoursesArray = advisorCourses.map(course => course.courseName);
            const advisorActivities = props?.data?.requestReceivedUser?.advisorProfile?.advisorActivities || [];
            const advisorActivitiesArray = advisorActivities.map(activity => activity.activityName);
            const combinedData = [...advisorCoursesArray, ...advisorActivitiesArray];

            const mappedData = combinedData.map(item => (item ? item : null));
            setCourseNames(mappedData);
        }

    }, [props.data]);

	const imageUrl = useImageUrl(props?.data?.requestReceivedUser?.avatarPath);

    return (

        <div className={`AdvisorCard ${props?.data?.requestInfo?.status === "pending" ? 'AdvisorCard__Pending' : ''}
                            ${props?.data?.requestInfo?.status === "accepted" ? 'AdvisorCard__Accepted' : ''}
                            ${props?.data?.requestInfo?.status === "rejected" ? 'AdvisorCard__Rejected' : ''}`}>
            <Grid container spacing={2}>
                <Grid item xs={3} >
                    {props?.data?.requestReceivedUser?.avatarPath ? (
                        <img className="profile-img" style={{ "width": "60px", "height": "60px", borderRadius: "6px" }}
                            src={imageUrl} alt="userprofile"
                        />
                    ) :
                        <PersonIcon className='personicon' color="action" sx={{ border: '1px solid grey', borderRadius: "6px", fontSize: '1rem', "width": "65px", "height": "65px !important" }} />
                    }
                </Grid>
                <Grid item xs={5} className="owlTitle">
                    <p className="profile-heading" style={{ "marginBottom": "2px", "fontSize": "16px", "fontWeight": "500" }} >{props?.data?.requestReceivedUser?.fullName}</p>
                    <p className="advisor-country" style={{ "marginBottom": "0px" }} >{props?.data?.requestReceivedUser?.country}</p>

                    <div className='PillStatus'>
                        {props?.data?.requestInfo?.status === "pending" &&
                            <div className='PillStatus__Pending'><p>Request Pending</p></div>
                        }
                        {props?.data?.requestInfo?.status === "accepted" &&
                            <div className='PillStatus__Accepted'><p>Request Accepted</p></div>
                        }
                        {props?.data?.requestInfo?.status === "rejected" &&
                            <div className='PillStatus__Rejected'><p>Request Rejected</p></div>
                        }
                    </div>
                </Grid>
                <Grid item xs={4} className="owlTitle">
                    <p className="rating-heading" style={{ "marginBottom": "0px" }} >
                        <StarBorderIcon sx={{ fontSize: '15px', fill: '#E64A19', }} />{props?.data?.requestReceivedUser?.advisorProfile?.advisorReviewSummary?.averageRating.toFixed(1) || 0}{"/5.0"}
                    </p>
                </Grid>
            </Grid>
            <Grid className='mt-2'>
                <div style={{ "height": "60px", "overflow": "hidden" }}>
                    {props?.data?.requestReceivedUser?.advisorProfile?.currentlyIn === "highschool" && (
                        <p className='advisor-subText'>  <HomeOutlinedIcon sx={{ fontSize: '14px', fill: "#212224" }} /> Attending  {props?.data?.requestReceivedUser?.advisorProfile?.highschoolName}</p>
                    )}
                    {props?.data?.requestReceivedUser?.advisorProfile?.currentlyIn === "university" && (
                        <>
                            <p className='advisor-subText'>  <SchoolOutlinedIcon sx={{ fontSize: '14px', fill: "#212224" }} /> Attending  {props?.data?.requestReceivedUser?.advisorProfile?.universityName}</p>
                        </>
                    )}
                    {props?.data?.requestReceivedUser?.advisorProfile?.currentlyIn === "professional" && (
                        <>
                            <p className='advisor-subText'>  <SchoolOutlinedIcon sx={{ fontSize: '14px', fill: "#212224" }} /> Went to  {props?.data?.requestReceivedUser?.advisorProfile?.universityName}</p>
                        </>
                    )}
                </div>
            </Grid>

            <Grid className='mt-2 '>
                <p className="service-heading" style={{ "marginBottom": "5px", "fontSize": "16px", }} >{"Service requested"}</p>
                <div className='advisor-card-content' style={{"height":"210px"}} >
                    <p className='advisor-subText'>  <StarRoundedIcon sx={{ fill: "#1C84EE", fontSize: '17px', }} />Create plan based on goals</p>
                    {props?.data?.requestReceivedUser?.advisorProfile?.helpWithPlan === true && (<p className='advisor-subText'>  <StarRoundedIcon sx={{ fill: "#1C84EE", fontSize: '17px' }} />Review existing plan based on goals</p>)}
                    {courseNames && courseNames?.length > 0 &&
                        <>
                            <p className='advisor-subText'>  <StarRoundedIcon sx={{ fill: "#1C84EE", fontSize: '17px' }} /> Mentoring for</p>
                            <PillSection data={courseNames} maxWidth={190} maxHeight={80} />
                        </>
                    }
                </div>
            </Grid>
            <Grid className='mt-2 '>
                <div style={{ height: "90px" }} >
                    {props?.data?.requestInfo?.message && (
                        <div className='advisor-card-description'>
                            <p>{props?.data?.requestInfo?.message}</p>
                        </div>
                    )
                    }
                </div>
            </Grid>
            <Grid>
                {props?.children}
            </Grid>
        </div>

    )
}

export default Index
