import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../../assets/css/sendInvite.less";
import { connect } from "react-redux";
import { withRouter } from "../../../redux/store/navigate";
import { setUserOnboard, setIsGoalSuccess } from "../../../redux/actions";
class Index extends Component {
    state = {}
    render() {
        return (
            <>
                <div className="sendInvite">
                    <div className="sendInvite__form">
                        <h1 className="sendInvite__form__title text-center">Connect with your child</h1>
                        <p className="role_desc ">If your child is already using LeapBrains, you can connect your accounts. Enter email linked to child’s account and we will notify them. </p>
                        <div className="sendInvite__form_inner">
                            <div className="row m-0">
                                <div className="col-md-12 col-sm-12 col-12 ps-0 p-mob-0">
                                    <div class="input-floating-label">
                                        <input
                                            refs="Email"
                                            type="text"
                                            className={"textbox--primary textbox--rounded input"}
                                            name="Email"
                                            placeholder="Email"
                                        />
                                        <label>Child's email</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-md-12 col-sm-12 col-12 ps-0 p-mob-0">
                                    <div className="text-center" >
                                        <Link to="">
                                            <Button className="btn cta--rounded cta-primary mb-4 w-100" disabled={this.state.loading} >
                                                {this.state.loading && (
                                                    <span className="spinner-border spinner-border-sm"></span>
                                                )}
                                                <span>Connect</span>
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <div className="footer">
                    <div className="row m-0">
                        <div className="footer__left col-md-4 col-sm-4 col-4 text-center p-0">
                            <p>
                                <Link to="/onboarding/goals/child" onClick={() => this.props.setIsGoalSuccess(false)} className="footer__left__cta">
                                    <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.80474 0.195262C5.54439 -0.0650874 5.12228 -0.0650874 4.86193 0.195262L0.195262 4.86193C-0.0650873 5.12228 -0.0650873 5.54439 0.195262 5.80474L4.86193 10.4714C5.12228 10.7318 5.54439 10.7318 5.80474 10.4714C6.06509 10.2111 6.06509 9.78894 5.80474 9.52859L1.60948 5.33333L5.80474 1.13807C6.06509 0.877722 6.06509 0.455612 5.80474 0.195262Z" fill="#1B1C1E" />
                                    </svg>
                                    <span>Go back</span>
                                </Link>
                            </p>
                        </div>
                        <div className="footer__center col-md-4 col-sm-4 col-4 text-center">
                            <p>2 out of 9 steps</p>
                        </div>
                        <div className="footer__right col-md-4 col-sm-4 col-4 text-center">
                            {/* <div onClick={this.handleClick()}><p  className="d-inline-block pe-3">Skip</p></div> */}
                            <Link className="d-inline-block footer__cta pe-3" to="/onboarding/profile">Skip</Link>

                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = ({ userProfileData }) => {
    const { isUserOnboarded } = userProfileData
    return { isUserOnboarded }
};

export default connect(mapStateToProps, { setUserOnboard, setIsGoalSuccess })(withRouter(Index));