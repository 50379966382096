import React, { Component } from "react";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: false
    };
  }
  render() {
    const docs = [


      {
        uri: require("../../../../assets/pdf/sample.pdf")
      }
    ];

    return (
      <>
        <div className="docs-view-popup">
          <DocViewer

            pluginRenderers={DocViewerRenderers}
            documents={docs}
            theme={{
              primary: "white",
              text_primary: "black",
              secondary: "pink",
              text_secondary: "green",
              tertiary: "#f8f9fa ",
              text_tertiary: "black",
              disableThemeScrollbar: false
            }} />
        </div>
      </>
    );
  }

}
export default Index;