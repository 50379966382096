import React, { useState, useCallback, useEffect } from "react";
import { Button, Modal, Box, Autocomplete, TextField, Chip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { userConnect, userConnectStatus } from "../../redux/actions";
import CloseIcon from '@mui/icons-material/Close';
import { connect } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PersonIcon from '@mui/icons-material/Person';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 400,
    minWidth: 270,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const validation = Yup.object({

});


const Index = (props) => {
    const { register, handleSubmit, watch, reset, setValue, clearErrors, control, formState: { errors } } = useForm({ mode: "onSubmit", resolver: yupResolver(validation), });

    const handleClose = () => {
        props?.handleClose()
        reset()
    }

    const onSubmit = (data) => {
        props?.handleConnectValues(data)
        handleClose()
    };

    useEffect(() => {
        if (props.userConnectSuccess) {
            reset()
            props?.userConnectStatus(false)
        }
    }, [props.userConnectSuccess])

    useEffect(() => {
        reset()
    }, [props])

    return (
        <Modal
            open={props?.show}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <form id='my-course' onSubmit={handleSubmit(onSubmit)}>
                    <div className="p-4 text-center">
                        <div style={{ textAlign: 'right' }}>
                            <div onClick={handleClose}><CloseIcon sx={{ fill: 'black', cursor: 'pointer' }} /></div>
                        </div>
                        <p className="profileAboutCard__title mb-1">Are you sure you want to connect with {props?.data?.userInfo?.fullName}</p>


                        {props?.data?.userInfo?.avatarUrl ? (
                            <div class=" " style={{ marginBottom: '15px' }}>
                                <img src={props?.data?.userInfo?.avatarUrl} alt="user" style={{ width: "100px", height: '100px', borderRadius: "10px" }} />
                            </div>
                        ) :
                            <div class="" style={{ marginBottom: '15px' }}>
                                <PersonIcon color="action" sx={{ width: "100px", height: '100px', borderRadius: "10px", border: '1px solid grey', }} />
                            </div>
                        }
                        <div className="row m-0">
                            <div className="col-md-12 col-sm-12 col-12 p-0">
                                <div className="input-floating-label mb-0">
                                    <textarea maxLength={255} {...register("description")}
                                        className={"textarea--primary textbox--rounded input"} name="description" placeholder=" " style={{ "minHeight": "100px", "resize": "none" }} />
                                    <label >Send Message(Optional)</label>
                                    <div className="subtext" style={{ float: 'right' }}><span>{watch("description")?.length || 0}</span>/255</div>

                                </div>
                            </div>
                        </div>
                        <div className="resConnect ">
                            <div className='netWork-card__button-sec profileConnect sendConnect mt-0' >
                                <button className='netWork-card__button-sec__primary-btn' style={{ width: '50%' }}>Connect</button>
                            </div>
                        </div>
                    </div>
                </form>
            </Box>
        </Modal>
    );
};

const mapStateToProps = ({ commonData }) => {
    const { userConnectSuccess } = commonData
    return { userConnectSuccess }
};

export default connect(mapStateToProps, { userConnect, userConnectStatus })(Index);