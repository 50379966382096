import React, { useState, useEffect } from "react";
import "./advMyNetwork.less";
import { connect } from "react-redux";
import Select, { components } from "react-select";
import { clearMessage, getNetworkUserCount } from '../../../redux/actions'
import { Box, Grid, Tab, Tabs } from "@mui/material";
import Requests from "../MyNetwork/Requests"
import { useLocation, useNavigate } from "react-router-dom";
import SentRequests from "./SentRequests";
import ConnectedStudents from "./ConnectedStudents";
import SubscribedUsers from "./SubscribedUsers";
import ViewStudentProfile from "../../../componentsNew/Common/ViewStudentProfile";
import { VIEW_PUBLIC_PROFILE } from "../../../redux/constants/CommonTypes";

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            className="Advisor-MyNetwork__tabs-sec"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
             {value === index && (
                <Box className="card-sec">
                    {children}
                </Box>
             )}
        </div>
    );
}

const a11yProps = (index) => {
    return { id: `scrollable-auto-tab-${index}`, "aria-controls": `scrollable-auto-tabpanel-${index}` };
}

const menulist = [
    { value: 0, label: "Connected Students" }, 
    { value: 1, label: "Subscribed Users" }, 
    { value: 2, label: "Sent Requests" }, 
    { value: 3, label: "Received Requests" }, 
]
const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder}</Placeholder>
            {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
        </ValueContainer>
    );
};
const Index = (props) => {
    const [tab, setTab] = useState(0);
    const [selectedOption, setSelectedOption] = useState({ value: 0, label: "Connected Students" });
    const [showProfile, setShowProfile] = useState(false);
    const [profileData, setProfileData] = useState({});

    let {state} = useLocation();
    let navigate = useNavigate();
    
    useEffect(() => {
        if (state?.type == "recieveRequest") {
            setTab(3);
            setSelectedOption({ value: 3, label: "Recieved Requests" })
        }
        if(state?.type === VIEW_PUBLIC_PROFILE){
            setProfileData({id: state.userId})
            showProfileModal(true);
        }
        props?.getNetworkUserCount()
    }, [])
    
    const handleSelectChange = (e) => {
        setTab(e.value);
        setSelectedOption(e);
    }
    const changeTab = (event, newValue) => {
        setTab(newValue);
        props.clearMessage();
        props?.getNetworkUserCount()
    };
    const showProfileModal = (status) => {
        if(!status) setProfileData({})
        setShowProfile(status)
    }
    let count = props?.networkcount
    
    return (
        <>
            <div className="Advisor-MyNetwork__mainCnt">
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={12} md={12} lg={11} xl={10}>
                        <div className="Advisor_Network_Sec">
                            <p className="Network_Title">My Network</p>
                        </div>
                        <div className="Advisor_Network_Sec_Mobile">
                            <p className="Network_Title">My Network</p>
                        </div>
                    </Grid>
                    
                    <Grid className="tab-Section" item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className="Advisor-MyNetwork__mainCnt__mobileTab">
                            <div className="input-floating-label">
                                <Select options={menulist} onChange={handleSelectChange}
                                    value={selectedOption}
                                    getOptionLabel={e => (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {e.icon}
                                            <span style={{ marginLeft: 10 }}>{e.label}</span>
                                        </div>
                                    )}
                                    placeholder=" " components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                    styles={{ option: (provided, state) => ({ ...provided, cursor: 'pointer', }), container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }}
                                />
                            </div>
                        </div>
                        <Box
                            className="tab_card"
                            sx={{
                                borderRadius: "10px",
                                maxWidth: "fit-content"
                            }}
                        >
                            <Tabs
                                value={tab}
                                onChange={changeTab}
                                variant="scrollable"
                                scrollButtons
                                allowScrollButtonsMobile
                                aria-label="scrollable force tabs example"
                            >
                                <Tab {...a11yProps(0)} label={`${(count.studentCount - props?.subscribedStudentData?.length) > 0 ? (count.studentCount - props?.subscribedStudentData?.length) : "" } Connected Students`} disableRipple />
                                <Tab {...a11yProps(1)} label={`${props?.subscribedStudentData?.length ?? ""} Subscribed Users`} disableRipple />
                                <Tab {...a11yProps(2)} label={`${count?.sentRequestCount ?? ""} Sent Requests`} disableRipple />
                                <Tab {...a11yProps(3)} label={`${count?.pendingRequestCount ?? "" } Received Requests`} disableRipple />
                            </Tabs>
                        </Box>
                        {tab === 0 &&
                            <div className="d-flex">
                                <button className="tab-Section__explore-btn" id="exploreStudents" onClick={() => navigate("/advisor/mynetwork/exploreStudents")}>Explore Students</button>
                            </div>
                        }
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box className="tab-Body">
                            <TabPanel value={tab} index={0}>
                                <ConnectedStudents/>
                            </TabPanel>
                            <TabPanel value={tab} index={1}>
                                <SubscribedUsers />
                            </TabPanel>
                            <TabPanel value={tab} index={2}>
                                <SentRequests/> 
                            </TabPanel>
                            <TabPanel value={tab} index={3}>
                                <Requests/> 
                            </TabPanel>
                        </Box>
                    </Grid>
                </Grid>
            </div>
            {showProfile &&
                <ViewStudentProfile show={showProfile} profileData={profileData} handleClose={() => showProfileModal(false)}/>
            }
        </>
    );
};

const mapStateToProps = ({ commonData, advisorData }) => {
    const { message, errorList, networkcount } = commonData;
    const { subscribedStudentData } = advisorData;
    return { message, errorList, networkcount, subscribedStudentData };
};
export default connect(mapStateToProps, { clearMessage, getNetworkUserCount })(Index);
