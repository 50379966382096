import React, { useEffect, useState } from 'react'
import "./confirmEvent.less"
import moment from 'moment/moment';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { connect } from 'react-redux';
import { withRouter } from '../../../../redux/store/navigate';
import { confirmScheduledEvent } from "../../../../redux/actions"
import Alert from '../../../../components/Alert';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import RescheduleEvent from '../RescheduleEvent';
import RejectReasonModal from "../../../Advisor/Modals/RejectReasonModal";
import { GetCurrentTimezone } from '../../../../utils/CommonUtils';
import dayjs from 'dayjs';

const Index = (props) => {
    const [show, setShow] = useState(false);
    const [showReasonModal, setShowReasonModal] = useState(false);
    const [scheduledata, setScheduledata] = useState({})

    useEffect(() => {
        if (props?.reScheduledEvents) {
            props.onHide();
        }
        if(props?.eventConfirmationStatus){
            setShowReasonModal(false);
        }
    }, [props?.reScheduledEvents, props.eventConfirmationStatus])
    
    const handleConfirmRequest = (status) => {
        let payload = {
            eventId : props.data?.id,
            status : status
        }
        if(status){
            props.confirmScheduledEvent(payload)
        }
    }
    const handleRejectRequest = (data) => {
        let payload = {
            eventId : props.data?.id,
            status : false,
            reason: data?.rejectReason
        }
        props.confirmScheduledEvent(payload)
    }
    const handleRescheduleRequest = (data) => {
        setScheduledata(data)
        setShow(!show)
    }

    const handleClose = () => {
        setShow(false);
        setScheduledata({});
    }
    const handleOpenRejectModal = (status) => {
        setShowReasonModal(status);
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
            year: 'numeric',
        };
        const formattedDate = date.toLocaleDateString('en-US', options);

        const day = date.getDate();
        const suffix = day === 1 || day === 21 || day === 31 ? 'st' :
                    day === 2 || day === 22 ? 'nd' :
                    day === 3 || day === 23 ? 'rd' : 'th';

        return [formattedDate.replace(`${day},`, `${day}${suffix}`), date];
    }

    const convertToTime = (timeString) => {
        const date = new Date(timeString);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const amOrPm = hours >= 12 ? 'pm' : 'am';
        const formattedHours = (hours % 12) || 12; // Convert to 12-hour format
    
        return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${amOrPm}`;
    }
    
    const scheduledTo = props.data.inviteStatus?.filter((user) => user.userId !== props.data.createdBy)
    let activeAdvisorEventStatus = props.data?.inviteStatus?.find((status) => status?.userId === props.userProfile?.id)    
    return (
        <>
            <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
                <Modal.Header closeButton >
                    <Modal.Title id="contained-modal-title-vcenter">
                        { props.data?.eventType === "freeSession" ?
                            "Consultation Session" : "Paid Session"
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='modalContent'>
                    { Object.keys(props.errorList).length === 0 &&
                        <Alert />
                    }
                    <div className="modalContent__section">
                        <div className="modalContent__section__textCont">
                            <p className="modalContent__section__textCont__label">Who</p>
                            <p className="value blue-text">{props.data?.title}</p>
                        </div>
                        <div className="modalContent__section__textCont">
                            <p className="modalContent__section__textCont__label">When</p>
                            <div className="modalContent__section__textCont__block-value">
                                <p className="modalContent__section__textCont__value">{formatDate(props.data?.start)[0]}</p>
                                <p className="modalContent__section__textCont__value">{`${convertToTime(formatDate(props.data?.start)?.[1])} to ${convertToTime(formatDate(props.data?.end)?.[1])}`} (<span>Duration :</span> {props?.data?.eventDuration} Mins)</p>
                            </div>
                        </div>
                        <div className="modalContent__section__textCont">
                            <p className="modalContent__section__textCont__label">Where</p>
                            <p className="blue-text">{props.data.meetingLocation}
                                <a href={props.data.meetingLocation} target="_blank" rel="noopener noreferrer" > 
                                    <OpenInNewOutlinedIcon sx={{ fontSize: '1rem', fill: '#7a7676', marginLeft: '4px' }}/>
                                </a>
                            </p>
                        </div>
                        <p className='modalContent__section__value'>{props.data?.meetingDescription}</p>
                        <div className="modalContent__section__bottom">
                            <div className="modalContent__section__bottom__confirmation-sec">
                                {(dayjs(props.data?.start).format('YYYY-MM-DDTHH:mm:ss') > GetCurrentTimezone(props?.userProfile?.timezone)) ?
                                    // upcomming
                                    <>
                                        { scheduledTo && scheduledTo[0]?.status !== "rejected" &&
                                            <Button className="reject-btn cta--rounded btn m-2" onClick={() => handleOpenRejectModal(true)}>
                                            Reject
                                            </Button>
                                        }
                                        { (scheduledTo && scheduledTo[0]?.status !== "accepted" && scheduledTo && scheduledTo[0]?.status !== "rejected") &&
                                            <Button className="accept-btn cta--rounded btn m-2" onClick={() => handleConfirmRequest(true)}>
                                            Accept
                                            </Button>
                                        }
                                        <Button className="reSchedule-btn cta--rounded btn m-2" onClick={() => handleRescheduleRequest(props.data)}>
                                            Re Schedule
                                        </Button>
                                    </> 
                                :
                                // past event
                                activeAdvisorEventStatus?.status !== "accepted" &&
                                    <Button className="reSchedule-btn cta--rounded btn m-2" onClick={() => handleRescheduleRequest(props.data)}>
                                        Re Schedule
                                    </Button>
                                }
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            { show && <RescheduleEvent show={show} onHide={handleClose} data={scheduledata} /> }
            { showReasonModal && <RejectReasonModal show={showReasonModal} onHide={() => handleOpenRejectModal(false)} data={scheduledata} rejectRequest={(data) => handleRejectRequest(data)}/>}
        </>
    )
}

const mapStateToProps = ({ commonData, userProfileData }) => {
    const { errorList, reScheduledEvents } = commonData;
    const { userProfile } = userProfileData;

    return { errorList, reScheduledEvents, userProfile };
};

export default connect( mapStateToProps, { confirmScheduledEvent } )( withRouter(Index) );
