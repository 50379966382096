import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import {GetCourseList, GetCourseListStatus} from "../../../../redux/actions";
import { connect } from 'react-redux';
import CourseCard from "../../../../componentsNew/Common/Cards/MyWork/CourseCard";
import "./currentCourses.less";
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import { CircularProgress } from '@mui/material';

const Index = (props) => {
    const [loadMoreCourses, setLoadMoreCourses] = useState(false);
    const [page, setPage] = useState(1);

    const { state } = useLocation();
    let navigate = useNavigate();


	useEffect(() => {
		let payload = { 
            searchString: '', 
            pageNo: page, 
            limit: 10, 
            planIds: [],
            studentId: state?.id
        }
		props?.GetCourseList(payload);
	}, [page])

	useEffect(() => {
		if (props?.getplancourseliststatus) {
			props?.GetCourseListStatus(false);
			if (loadMoreCourses) {
				setLoadMoreCourses(false);
			}
		}
	}, [props?.getplancourseliststatus])
	
	const getSuggestionDetail = (isApproved, isDropSuggested, type) => {
        if (isApproved && isDropSuggested) {
            return (
                <p className='myWork-courseCard__suggestionSec__dangerText'>
                    You suggested to drop this {type}. {state?.studentFirstName} can now review and accept or reject your proposal
                    <ChatBubbleOutlineOutlinedIcon className='myWork-courseCard__suggestionSec__chatIcon'/>
                </p>
            );
        } else if (!isApproved) {
            return (
                <p className='myWork-courseCard__suggestionSec__successText'>
                    You suggested this {type}. {state?.studentFirstName} can now review and accept or reject your proposal
                    <ChatBubbleOutlineOutlinedIcon className='myWork-courseCard__suggestionSec__chatIcon'/>
                </p>
            );
        }
    }

	const handleNavigateCourse = (data) => {
        navigate(`plan/${data?.planInfo?.id}/courseMilestone`, { state: { courseData: data?.courseInfo } })
    }
	const handleScroll = (e) => {
        e.stopPropagation();
		const bottom = Math.ceil(e.target.offsetHeight + e.target.scrollTop) >= e.target.scrollHeight;
        if((props?.pagination?.totalrecords > props?.getplancourselist?.length) && !loadMoreCourses){
			if(bottom){
				setLoadMoreCourses(true);
				setPage(page => page + 1)
			}
        }
		
    }
  
	return (
		<>
			<div className="myWork-studentCoursesSec" onScrollCapture={handleScroll}>
				{props?.getplancourselist?.map(data => {
					return(
						<CourseCard key={data?.courseInfo?.id} 
						planName={data?.planInfo?.planName}
						courseName={data?.courseInfo?.courseName} 
						courseType={data?.courseInfo?.courseType} 
						description={data?.courseInfo?.description}
						semester= {data?.courseInfo?.planPhase?.title}
						isApproved={data?.courseInfo?.isApproved}
						isDropsuggested={data?.courseInfo?.isDropsuggested}
						suggestionData={ !props?.semData?.isPassed && getSuggestionDetail(data?.courseInfo?.isApproved, data?.courseInfo?.isDropsuggested, 'Course')}
						>
							<div className="myWork-courseCard__ctaSec__primaryBtnSec">
								{!getSuggestionDetail(data?.courseInfo?.isApproved, data?.courseInfo?.isDropsuggested, 'Course') &&
									<>
										<p className='myWork-courseCard__ctaSec__primaryBtnSec__helperText'>3 Milestones, 11 milestone updates</p>
										<div className="myWork-courseCard__ctaSec__primaryBtnSec__primaryBtn" onClick={() => handleNavigateCourse(data)}>
											View Milestone
										</div>
									</>
								}
							</div>
						</CourseCard>
				)})}

				{props?.getplancourselist?.length === 0 &&
					<div className='myWork-studentActivitiesSec__noDataSec'>
						<p className='myWork-studentActivitiesSec__noDataSec__textBlack'>You don't have any Courses currently</p>
					</div>
				}
			</div>
			<div className='fadedSectionLoader'>
				{loadMoreCourses && <CircularProgress sx={{zIndex: 9999}}/>}
			</div>
		</>
	)
}


const mapStateToProps = ({ studentData, commonData }) => {
    const { message, errorList } = commonData;
    const { pagination, getplancourselist, getplancourseliststatus } = studentData;
    return {  message, errorList, pagination, getplancourselist, getplancourseliststatus }
}
export default connect(mapStateToProps, { GetCourseList, GetCourseListStatus })(Index);
