import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {getConnectedStudents, getSubscribedStudentStatus, getSubscribedStudents, setConnectedStudentStatus, 
    resetDetailedUserProfileStatus, disconnectUser, disconnectUserStatus, getScheduledSessionDetails} from "../../../../redux/actions"
import ConnectedStudents from "../../../../componentsNew/Common/Cards/Common/ConnectedStudents";
import ConfirmationPopup from "../../../../componentsNew/Common/ConfirmationPopup";
import ViewStudentProfile from '../../../../componentsNew/Common/ViewStudentProfile';
import Loader from '../../../../components/Loader';
import { Link } from 'react-router-dom';
import { convertUTCTodateTimeZone, convertUtcToEventTime } from '../../../../utils/CommonUtils';

const Index = (props) => {
    const [loading, setLoading] = useState(false)
    const [showDisconnectModal, setShowDisconnectModal] = useState(false);
    const [showProfile, setShowProfile] = useState(false);
    const [profileData, setProfileData] = useState({});
    const [subscribedUsers, setSubscribedUsers] = useState([]);


    useEffect(() => {
        setLoading(true)
        props?.getScheduledSessionDetails();
        props?.getSubscribedStudents()
        props.getConnectedStudents()
    }, [])
    useEffect(() => {
        if (props.connectedStudentsStatus){
            props.setConnectedStudentStatus(false)
            setLoading(false)
        }
        if (props?.isDisconnectUser) {
            openDisconnectModal(false);
            props?.disconnectUserStatus(false);
            props.getConnectedStudents()
        }
        if(props.getScribedStudentsStatus){
            let temp = props?.subscribedStudentData?.map(data => data?.userInfo?.id)
            setSubscribedUsers([...temp])
            props?.getSubscribedStudentStatus(false);
            setLoading(false);
        }
    }, [props.connectedStudentsStatus, props?.isDisconnectUser, props.getScribedStudentsStatus])

    const openDisconnectModal = (status, data = {}) => {
        setProfileData(data)
        setShowDisconnectModal(status);
    }
    const handleViewProfile = (data) => {
        setProfileData(data);
        showProfileModal(true);
    }
    const showProfileModal = (status) => {
        if(!status) {
            setProfileData({})
        }
        setShowProfile(status)
    }
    const handleDelete = () => {
        let payload = {
            userId: profileData?.id
        }
        props?.disconnectUser(payload)
    }
    const getScheduledTime = (userId) => {
        let eventDetails = props.scheduledSessionDetails?.find(data => (data?.userId == userId) && data?.userInvitationStatuses?.find(invite => invite?.userId == userId && invite?.status == "accepted"))
        let date = new Date(eventDetails?.eventTime)
        let formattedDate = date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit'
        });
        if(eventDetails){
            return `${formattedDate} at ${convertUTCTodateTimeZone(eventDetails?.eventTime, props?.userProfile?.timezone)?.format('hh:mm A')}`
        }else{
            return null;
        }
    }

    return (
        <>
            { loading && <Loader/> }
            { !loading && props.connectedStudents?.filter(data => !subscribedUsers?.includes(data?.id))?.map( (userInfo, index) => {
                return(
                    <div key={userInfo?.id}>
                        <ConnectedStudents 
                            name = {userInfo?.fullName}
                            country = {userInfo?.country}
                            avatarUrl= {userInfo?.avatarPath}
                            school = {userInfo?.studentProfile?.currrentSchoolName}
                            gpa = {userInfo?.studentProfile?.desiredGpa}
                            universities = {userInfo?.studentProfile?.universities?.sort((a, b) => {return a.length - b.length} )}
                            professions = {userInfo?.studentProfile?.professions?.sort((a, b) => {return a.length - b.length})}
                            meetTime= {getScheduledTime(userInfo?.id) ? `You are scheduled to meet on ${getScheduledTime(userInfo?.id)}` : ''}
                            // matchParam = {element?.matchingParams?.[0]}
                            courseAndActivities = {userInfo?.studentProfile?.courses?.concat(userInfo?.studentProfile?.activities)?.toSorted((a, b) => {return a.length - b.length})}
                            weighted= {userInfo?.studentProfile?.targetWeighted}
                            unWeighted= {userInfo?.studentProfile?.targetUnweighted}
                            recipientUrl='advisor/messenger'
                        >
                            <button className="connectedStudentCard__buttonSec__primary-btn" id={`viewProfile-${index}`} onClick={() => handleViewProfile({id: userInfo?.id})}>
                                View Profile
                            </button>
                            <button className="connectedStudentCard__buttonSec__danger-btn" id={`disconnect-${index}`} onClick={() => openDisconnectModal(true, userInfo)}>
                                Disconnect
                            </button>
                        </ConnectedStudents>
                    </div>
                )
            })}
            { !loading && props?.connectedStudents?.filter(data => !subscribedUsers?.includes(data?.id))?.length === 0 &&
                <div className="networkNoDataSec">
                    <div className="networkNoDataSec__nodataCard">
                        <p className="networkNoDataSec__nodataCard__textBlack">Let's get you connected with some Students who you can Collaborate with</p>
                        <span className="networkNoDataSec__nodataCard__cta">
                            <svg xmlns="http://www.w3.org/2000/svg" className="networkNoDataSec__nodataCard__cta__searchIcon" viewBox="0 0 24 24" fill="none">
                                <circle cx="11" cy="11" r="6" stroke="#222222"/>
                                <path d="M11 8C10.606 8 10.2159 8.0776 9.85195 8.22836C9.48797 8.37913 9.15726 8.6001 8.87868 8.87868C8.6001 9.15726 8.37913 9.48797 8.22836 9.85195C8.0776 10.2159 8 10.606 8 11" stroke="#222222" strokeLinecap="round"/>
                                <path d="M20 20L17 17" stroke="#222222" strokeLinecap="round"/>
                            </svg>
                            <Link to={'exploreStudents'}>
                                <p className="networkNoDataSec__nodataCard__cta__blueText" id='connect'>
                                    Explore Students
                                </p>
                            </Link>
                        </span>
                    </div>
                </div>
            }
            {showProfile &&
                <ViewStudentProfile show={showProfile} profileData={profileData} handleClose={() => showProfileModal(false)}/>
            }
            <ConfirmationPopup show={showDisconnectModal} handleSubmit={() => handleDelete()} message={`You and ${profileData?.fullName} will no longer be connected to each other`} btnText="Confirm" onHide={() => openDisconnectModal(false)} />
        </>
    )
}

const mapStateToProps = ({ commonData, userProfileData, advisorData }) => {
    const { message, errorList, connectedStudents, connectedStudentsStatus, scheduledSessionDetails } = commonData;
    const { isDisconnectUser, userProfile } = userProfileData
    const { subscribedStudentData, getScribedStudentsStatus} = advisorData
    return {  message, errorList, userProfile, connectedStudents, connectedStudentsStatus, scheduledSessionDetails, isDisconnectUser, subscribedStudentData, getScribedStudentsStatus };
  };
export default connect(mapStateToProps, { getConnectedStudents, setConnectedStudentStatus, getSubscribedStudents, 
    getSubscribedStudentStatus, resetDetailedUserProfileStatus, disconnectUser, disconnectUserStatus,
    getScheduledSessionDetails })(Index);