import React from 'react'
import "./pricingCard.less";

const Index = ({children, name, price, services, courses, activities, courseActivitySupport, status, toggleSwitch}) => {
    return (
        <div className="advisorPricingCard">
            <div className="advisorPricingCard__topSec">
                <p className="advisorPricingCard__topSec__title" title={name}>{name}</p>
                <div className='advisorPricingCard__topSec__cardgrid__dot'>
                    {children}
                </div>
            </div>
            <div className="advisorPricingCard__midSec">
                <div className="advisorPricingCard__midSec__content">
                    <p className="advisorPricingCard__midSec__content__label">Price</p>
                    <p className="advisorPricingCard__midSec__content__value">{price}</p>
                </div>
                <div className="advisorPricingCard__midSec__content">
                    <p className="advisorPricingCard__midSec__content__label">Services Included</p>
                    <p className="advisorPricingCard__midSec__content__value servicesIncluded" title= {services}>{services}</p>
                </div>
                <div className="advisorPricingCard__midSec__content">
                    {(courseActivitySupport && courses?.length > 0) &&
                        <>
                            <p className="advisorPricingCard__midSec__content__label">Courses</p>
                            <p className="advisorPricingCard__midSec__content__value">{courses}</p>
                        </>
                    }
                </div>
                <div className="advisorPricingCard__midSec__content">
                    {(courseActivitySupport && activities?.length > 0) &&
                        <>
                            <p className="advisorPricingCard__midSec__content__label">Activities</p>
                            <p className="advisorPricingCard__midSec__content__value">{activities}</p>
                        </>
                    }
                </div>
                <div className="advisorPricingCard__midSec__statusContent">
                    <p className="advisorPricingCard__midSec__statusContent__label">Status</p>
                    {toggleSwitch}
                    <p className={`advisorPricingCard__midSec__statusContent__value ${status}`}>{status == "ACTIVE" ? 'Active' : "InActive"}</p>
                </div>
            </div>
        </div>
    )
}

export default Index