import React from "react";
import Modal from "react-bootstrap/Modal";
import "./confirm.less"

const Index = (props) => {
    return (
        <Modal
            {...props} show={props?.show}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            style={{"zIndex":"2000", backgroundColor: "rgb(0,0,0, 0.5)"}}
            centered >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className="confirmationModal">
                <p className="confirmMsg">{props?.message}</p>
                <div className="col-12 d-flex justify-content-end">
                    <button className="confirmationModal__primary-btn" id="confirmBtn" onClick={(e) => props?.handleSubmit(e)}  style={{ width: 'fit-content'}}>
                        <p>{props?.btnText}</p>
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default Index;
