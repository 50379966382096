import React, { useState, useEffect } from "react";
// import "./profile.less"
import { Button, Modal, Typography, Box, Stack, Grid, TextField, Autocomplete, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import Select, { components } from "react-select";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
// import { useComponentDidMount, useComponentDidUpdate } from "../../../../utils/useEffectHooks";
import { COUNTRY_NAME_LABEL, FIRST_NAME_LABEL, GENDER_LABEL, GPA_LABEL, GRADUATION_YEAR_LABEL, High_SCHOOL_LABEL, LAST_NAME_LABEL, MY_STATUS_LABEL, ZIP_CODE_LABEL, EMAIL_LABEL } from "../../../Constants/commonLabels";
import { schoolApi } from "../../../utils/AutoComplete";
import { connect } from "react-redux";
import { deleteuser } from "../../../redux/actions";
import Loader from "../../../components/Loader";



const style = { position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", bgcolor: "background.paper", boxShadow: 24, p: 4, };

const validation = Yup.object({
  delete: Yup.string().required("*Required"),
});

const Index = (props) => {
  const [clickedYes, setclickedYes] = useState(false);
  const [typeError, settypeError] = useState(false);
  const [loading, setloading] = useState(false)

  const { register, handleSubmit, watch, reset, setValue, control, formState: { errors } } = useForm({ resolver: yupResolver(validation), });

  useEffect(() => {
    if (props.isUserdeleted === true) {
      setloading(false)
      localStorage.removeItem("leapBrainsWebApp");
      window.location.reload();
    }
  }, [props.isUserdeleted])

  const handleClose = () => {
    props?.handleClose();
    reset()
    setclickedYes(false)
    settypeError(false)
  };

  const handleClickedYes = () => {
    setclickedYes(true)
  };

  const onSubmit = (data) => {
    console.log(data, "submyt")
    if (data?.delete === "DELETE") {
      props.deleteuser();
      handleClose();
      setloading(true)
    } else {
      settypeError(true)
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Modal open={props?.show} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style} className="bulkheight">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row m-0">
              <div className="col-md-12 col-sm-12 col-12 p-0">
                <p>Are you sure you want to delete your account on LeapBrains ?</p>
              </div>
            </div>
            {clickedYes ? (
              <>
                <p>Please type DELETE to confirm</p>
                <div className="input-floating-label">
                  <input {...register("delete")} type="text" onClick={() => settypeError(false)} className={"textbox--primary textbox--rounded input"} name="delete" placeholder=" " autoComplete="off" />
                  <label>{"DELETE"}</label>
                  <div className="error-text" style={{position: 'absolute'}} >{errors.delete?.message}</div>
                  {typeError && (<div className="error-text">Please verify the data</div>)}
                </div>
                <Grid container className="justifyContent popupConfirm">
                  <Stack direction="row" className="flex-end btns--pay-stack" spacing={2}>
                    <Button variant="outlined" onClick={handleClose} className="outLineBtn" id="closepopup" >Close</Button>
                    <Button type="submit" variant="contained" className="blueBtn" id="confirmsubmit" style={{ textTransform: "none" }} size="small">
                      Confirm
                    </Button>
                  </Stack>
                </Grid>
              </>
            ) : (
              <Grid container className="justifyContent popupConfirm">
                <Stack direction="row" className="flex-end btns--pay-stack" spacing={2}>
                  <Button variant="outlined" onClick={handleClose} className="outLineBtn" >No</Button>
                  <Button variant="outlined" onClick={handleClickedYes} className="outLineBtn" >Yes</Button>
                </Stack>
              </Grid>
            )
            }
          </form>
        </Box>
      </Modal>
    </>
  );
}
const mapStateToProps = ({ userProfileData, commonData, auth }) => {
  const { userProfile, isProfileSuccess, childprofile, parentProfile } = userProfileData;
  const { status, gender, country, graduationyear, schools } = commonData;
  const { isUserdeleted } = auth;

  return { userProfile, isProfileSuccess, status, gender, schools, country, childprofile, parentProfile, graduationyear, isUserdeleted };
};

export default connect(mapStateToProps, { deleteuser })(Index);
