import React, { useState, useEffect } from "react";
import ReactQuill, { defaultProps } from 'react-quill';
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { connect } from "react-redux";
import { useComponentDidMount, useComponentDidUpdate } from "../../../utils/useEffectHooks";
import { CreateNotes, CreateNotesStatus, GetMilestoneByCourse, GetMilestoneByActivity, CreateNotesError } from "../../../redux/actions";
import "./notes.less";
import { Box, Grid } from '@mui/material';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from "react-bootstrap";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '5px',
    maxHeight: "90%"
};

const validation = Yup.object().shape({
    description: Yup.string().required('*Required').transform((value) => { return value.replace(/<[^>]+>/g, '').replace(/\&nbsp;+/g, ' '); })
        .max(750, 'Description should not exceed 750 characters').nullable(),
    name: Yup.string().when('url', {
        is: (url) => url && url.trim().length > 0,
        then: Yup.string().required('*Required').max(500, 'Description only allows 500 characters').matches(/^[a-zA-Z0-9\s]*$/, "Description can only allow alphabets and numbers"),
        otherwise: Yup.string().max(500, 'Description only allows 500 characters').matches(/^[a-zA-Z0-9\s]*$/, "Description can only allow alphabets and numbers")
    }).nullable(),
    url: Yup.string().url('Link must be a valid URL').nullable(),
});

const Index = (props) => {
    const params = useParams();
    const { state } = useLocation();

    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);


    const { handleSubmit, reset, setValue, register, watch, control, formState: { errors } } = useForm({ mode: "onSubmit", resolver: yupResolver(validation), });

    const onSubmit = (data) => {
        setIsButtonDisabled(true);
        if (data?.name || data?.url) {
            let payload = {
                planId: props?.data?.planId,
                milestoneId: props?.data?.id,
                updateContext: data?.description,
                resourceName: data?.name,
                resourceUrl: data?.url
            };
            props?.CreateNotes(payload);
        }
        else {
            let payload = {
                planId: props?.data?.planId,
                milestoneId: props?.data?.id,
                updateContext: data?.description,
            };
            props?.CreateNotes(payload);
        }

    };

    const handleClose = () => {
        if (watch('description') && !isButtonDisabled) {
            setConfirmModalOpen(true);
        } else {
            props?.handleClose();
            setIsButtonDisabled(false)
            reset();
        }
    };

    const handleConfirmClose = () => {
        setConfirmModalOpen(false);
        props?.handleClose();
        reset();
    };

    const handleCancelClose = () => {
        setConfirmModalOpen(false);
    };

    useComponentDidUpdate(() => {
        if (props?.isUpdateNotes) {
            let { currentRole } = props?.userProfile;
            handleClose()
            // props?.CreateNotesStatus(false)
            // if (state?.activityData?.id) {
            //     let payload = {
            //         "planId": state?.activityData?.planId || params.id,
            //         "activityId": state?.activityData?.id
            //     }
            //     props?.GetMilestoneByActivity(payload)
            // } else {
            //     let payload = {
            //         "planId": state?.courseData?.planId,
            //         "courseId": state?.courseData?.id
            //     }
            //     props?.GetMilestoneByCourse(payload)
            // }

        }
        if (props?.notesError) {
            handleClose()
            props?.CreateNotesError(false)
        }
    }, [props?.isUpdateNotes, props?.notesError])

    let resourceName = watch("name")

    return (
        <>
            <Modal
                open={props?.open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={style} className="notes-popup">
                        <Grid container spacing={2} className="milestonetopsections">
                            <Grid item xs={8} sm={11}>
                                <div className="milestoneTitle "> {"Create Update"} </div>
                            </Grid>
                            <Grid item xs={4} sm={1} className="text-end" style={{ cursor: 'pointer' }}>
                                <CloseIcon onClick={handleClose} />
                            </Grid>

                        </Grid>
                        <Grid className="mt-3 mb-3">
                            <Controller
                                name="description"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <ReactQuill
                                        value={field.value}
                                        style={{ height: '210px' }}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        className="textarea--primary textbox--rounded input" theme="snow" {...field}
                                    />

                                )}
                            />
                            <div className="error-text">{errors.description?.message}</div>
                        </Grid>
                        <p className='mt-2' style={{ "fontSize": "16px", "color": "#6C6C6C", "fontWeight": "500" }} >Resource</p>
                        <Grid item xs={12} className="mt-3 mb-3">
                            <div className="input-floating-label">
                                <textarea {...register("name")} maxLength={500} className={"textarea--primary textbox--rounded input"} name="name"
                                    placeholder=" " style={{ "minHeight": "100px", "resize": "none" }} id="resourceName" onKeyPress={(event) => { if (!/[A-Za-z0-9\s]/.test(event.key)) { event.preventDefault(); } }} />
                                <label >Description</label>
                                <div style={{"display":"flex","justifyContent":"space-between"}}>
                                    <p className="cta--text" style={{ "fontSize": "12px", "marginLeft": "20px", "marginBottom": "5px" }}>Optional</p>
                                    <div className="subtext" style={{ float: 'right' }}><span>{resourceName?.length || 0}</span>/500</div>
                                </div>
                                <div className="error-text" style={{ "position": "absolute" }} >{errors?.name?.message}</div>
                            </div>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} >
                            <div className="input-floating-label">
                                <input refs="resourceLink" {...register("url")} type="text" className="textbox--primary textbox--rounded input"
                                    name="url" placeholder="Link" id="resourceUrl" />
                                <label>Link</label>
                                <p className="cta--text" style={{ "fontSize": "12px", "marginLeft": "20px", "marginBottom": "5px" }}>Optional</p>
                                <div className="error-text" style={{ "position": "absolute" }} >{errors?.url?.message}</div>
                            </div>
                        </Grid>
                        {/* <Grid item xs={12} className="mt-3 mb-3">
                            {props?.data?.status !== "In Progress" &&
                                <div style={{ "display": "flex", "alignItems": "center", "padding": "2px", borderRadius: "3px" }} >
                                    <p style={{ "fontSize": "14px", marginBottom: "0px", fontStyle: 'italic', color: "#6C6C6C" }}>* When posting update milestone and course will be switched to In-Progress</p>
                                </div>
                            }
                        </Grid> */}
                        <Grid item xs={12} className="text-end">
                            <Button type="submit" disabled={isButtonDisabled} className="btn AddActivitybtn" style={{ position: 'relative', zIndex: '1' }}>Update</Button>
                        </Grid>
                    </Box>
                </form>
            </Modal>
            <Dialog open={confirmModalOpen} onClose={handleCancelClose} >
                <div className="notesConfirm" style={{ width: "20rem" }}>
                    <DialogTitle className="confirmationTitle">Confirmation</DialogTitle>
                    <DialogContent className="confirmationSubTitle">
                        Are you sure you want to close? Any unsaved changes will be lost.
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancelClose} variant="outline-secondary" style={{ "borderRadius": "10px" }}>Cancel</Button>
                        <Button onClick={handleConfirmClose} style={{ "borderRadius": "10px" }} >Close</Button>
                    </DialogActions>
                </div>
            </Dialog>
        </>
    );
};

const mapStateToProps = ({ studentData, userProfileData }) => {
    const { isUpdateNotes, notesError } = studentData;
    const { userProfile } = userProfileData;
    return { isUpdateNotes, notesError, userProfile };
};

export default connect(mapStateToProps, { CreateNotes, CreateNotesStatus, CreateNotesError, GetMilestoneByActivity, GetMilestoneByCourse })(Index);

