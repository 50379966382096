import React, { useState, useEffect } from "react";
import { Modal, Typography } from '@mui/material';
import { COURES_NAME_LABEL, } from "../../../../Constants/commonLabels"
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Loader from "../../../../components/Loader";
import CustomAlert from "../../../../components/Alert";
import { connect } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import { Button } from "react-bootstrap";
import CreatableSelect from 'react-select/creatable';
import Select, { components } from "react-select";
import { withRouter } from "../../../../redux/store/navigate";
import { getCourse, getCourses, getStudentGrades, clearMessage } from "../../../../redux/actions";
const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}><Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder} </Placeholder>
            {React.Children.map(children, (child) => child && child.type !== Placeholder ? child : null)}
        </ValueContainer>
    );
};

const stylesCourse = {
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none'
    },
    paper: {
        position: 'relative',
        backgroundColor: '#FBFBFB ',
        // boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
        border: 'none',
        padding: '0',
        borderRadius: '4px',
        width: '26%',
        maxHeight: "80%",
        minHeight: "70%",
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        border: "1px solid #efefef",

    },
    header: {
        height: '20px',
        backgroundColor: '#FBFBFB ',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 20px',
        boxSizing: 'border-box',
        paddingBottom: '15px',
        paddingTop: '31px',
    },
    content: {
        overflow: 'auto',
        padding: '20px',
        flexGrow: 1,
    },
    footer: {
        borderTop: "1px solid #efefef",
        height: 'fit-content',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 20px',
        paddingBottom: '15px',
        paddingTop: '15px',
        boxSizing: 'border-box',
        backgroundColor: '#FBFBFB ',
    },
    modalbtn: {
        borderRadius: '10px',
        width: '50%',
        backgroundColor: "#1C84EE"
    }
};

const validation = Yup.object({
    coursename: Yup.object().required("*Required").nullable(),
    courselevel: Yup.object().required("*Required").nullable(),
    semester: Yup.object().required("*Required").nullable(),
    // gradeachieved: Yup.object().required("*Required").nullable(),
    // helpwith: Yup.string().required("*Required").nullable(),
});

const Index = (props) => {
    const [loading, setloading] = useState(false)
    const [courseNameOpts, setcourseNameOpts] = useState();
    const [courseTypeOpts, setcourseTypeOpts] = useState();
    const [semesterOpts, setSemester] = useState();
    const [GradesOpts, setGrades] = useState();
    const [showAlert, setshowAlert] = useState(false);
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const { handleSubmit, setValue, control, watch, formState: { errors } } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(validation),
    });

    const selectedSemester = watch("semester");
    let currentSemester = props?.getstudentplan[0]?.planInfo?.activePhaseId === selectedSemester?.value
    const Description = watch("helpwith");
    const handleClose = () => {
        props?.handleClose()
    }

    const filterObject = (title, options, value, phaseId) => {
        if (value === "" || value === undefined) return;
        let filtered = options && options?.filter((option) => option?.label?.toString() === value?.toString());
        if (filtered[0]) {
            setValue(title, { label: filtered[0].label, value: filtered[0].value, isPassed: filtered[0]?.isPassed });
        } else {
            setValue(title, { label: value, value: phaseId });
        }
    };

    const onSubmit = (data) => {
        setButtonDisabled(true);
        props?.handleSubmit(data, props?.EditData)
    };

    useEffect(() => {
        if (props.courseNames && props.course && props.studentGrades) {
            setloading(true)
            setInitialValue();
            props?.clearMessage()
        }

    }, [props.courseNames, props.course, props.getphasetime, props.studentGrades])

    const setInitialValue = () => {
        let courseNameOptions = [];
        let courseOptions = [];
        let phasetime = [];
        let grades = [];

        props?.courseNames !== undefined && props?.courseNames?.length > 0 && props?.courseNames?.map((x, key) => {
            let temp = { label: x.courseName, value: x.courseName }
            courseNameOptions.push(temp);
        })
        setcourseNameOpts(courseNameOptions);

        props?.course !== undefined && props?.course.length > 0 && props?.course.map((x) => {
            let temp = { label: x.courseType, value: x.courseType }
            courseOptions.push(temp);
        })
        setcourseTypeOpts(courseOptions);

        props?.getphasetime !== undefined && props?.getphasetime.length > 0 && props?.getphasetime.map((x) => {
            let temp = { label: x.phaseTitle, value: x.phaseId, isPassed: x.isPassed }
            phasetime.push(temp);
        })
        setSemester(phasetime)

        props?.studentGrades !== undefined && props?.studentGrades.length > 0 && props?.studentGrades.map((x) => {
            let temp = { label: x.grade, value: x.grade }
            grades.push(temp);
        })
        setGrades(grades)
        setloading(false)
        setButtonDisabled(false)
    }

    useEffect(() => {
        if (semesterOpts != undefined) {
            filterObject("semester", semesterOpts, props?.semesterData?.title, props?.semesterData?.id);
        }
    }, [semesterOpts, props?.semesterData])

    useEffect(() => {
        if (props?.isAddCourseError) {
            setshowAlert(true)
            setButtonDisabled(false)
        }
    }, [props?.isAddCourseError])

    const filterObjectCourse = (title, options, value) => {
        if (value === "" || value === undefined) return;
        let filtered = options && options?.filter((options) => options?.courseName?.toString() === value?.toString());
        if (filtered && filtered[0]) {
            setValue(title, { label: filtered[0]?.courseName, value: filtered[0]?.courseName });
        } else {
            setValue(title, { label: value, value: value })
        }
    };

    const filterObjectType = (title, options, value) => {
        if (value === "" || value === undefined) return;
        let filtered = options?.filter(
                (options) => options?.courseType?.toString() === value?.toString()
            );
        if (filtered && filtered[0]) {
            setValue(title, { label: filtered[0]?.courseType, value: filtered[0]?.courseType });
        } else {
            setValue(title, { label: value?.label, value: value?.value })
        }
    };

    const filterObjectGrade = (title, options, value) => {
        if (value === "" || value === undefined) return;
        let filtered = options?.filter(
                (options) => options?.grade?.toString() === value?.toString()
            );
        if (filtered && filtered[0]) {
            setValue(title, { label: filtered[0]?.grade, value: filtered[0]?.id });
        } else {
            setValue(title, { label: filtered[0]?.grade, value: value?.value })
        }
    };

    useEffect(() => {
        if (props?.EditData) {
            filterObjectCourse("coursename", props?.courseNames, props?.EditData?.courseName);
            filterObjectType("courselevel", props?.course, props?.EditData?.courseType);
            filterObjectGrade("gradeachieved", props?.studentGrades, props?.EditData?.grade);
            setValue("helpwith", props?.EditData?.description)
        }
    }, [props?.EditData])

    return (
        <Modal style={stylesCourse.modal} open={props?.show} onClose={handleClose}>
            <div style={stylesCourse.paper} className="paperStyle-popup">
                {loading && <Loader />}
                <div style={stylesCourse.header}>
                    <Typography variant="h6" className="ModalName" >{props?.EditData ? "Edit Course" : "Add Course"}</Typography>
                    <div style={{ "position": "absolute", "right": "10px" }} onClick={handleClose}>
                        <CloseIcon sx={{ fill: 'grey', fontSize: '2rem', cursor: 'pointer' }} />
                    </div>
                </div>
                {showAlert && <div style={{ "width": "100%", "display": "flex", "justifyContent": "center", }}> {<CustomAlert />}</div>}
                <div style={stylesCourse.content}>
                    <form id='my-course' onSubmit={handleSubmit(onSubmit)}>
                        <div className="row m-0 mt-3">
                            <Controller control={control} name="coursename"
                                render={({ field }) => (
                                    <div className="input-floating-label"z>
                                        <CreatableSelect options={courseNameOpts} {...field} closeMenuOnSelect={true} isClearable={false} maxMenuHeight={200}
                                            placeholder={COURES_NAME_LABEL} classNamePrefix="mySelect" id="coursename" components={{
                                                ValueContainer: CustomValueContainer, IndicatorSeparator: () => null, Input: (props) => (
                                                    <components.Input {...props} maxLength={50} />),
                                            }}
                                            styles={{
                                                option: (provided, state) => ({ ...provided, cursor: 'pointer', }),
                                                container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }),
                                                valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', maxWidth: '380px' }),
                                                placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', })
                                            }} />
                                        {errors?.coursename && <div className="error-text" >{errors?.coursename?.message}</div>}
                                    </div>
                                )} />
                        </div>
                        <div className="row m-0 mt-3">
                            <Controller control={control} name="courselevel"
                                render={({ field }) => (
                                    <div className="input-floating-label">
                                        <Select name="courselevel" id="courselevel"  options={courseTypeOpts} {...field}
                                            placeholder="Course level" className="goals__form__select mb-0" classNamePrefix="mySelect"
                                            closeMenuOnSelect={true} isClearable={false} maxMenuHeight={200}
                                            components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                            styles={{
                                                option: (provided, state) => ({ ...provided, cursor: 'pointer', }),
                                                container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }),
                                                valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }),
                                                placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }),
                                                // menu: (provided, state) => ({...provided, minHeight: '50px',overflowY: 'auto', }),
                                                menu: (provided, state) => ({ ...provided, zIndex: 9999 }),
                                            }}
                                        />
                                        {errors?.courselevel && <div className="error-text" >{errors?.courselevel?.message}</div>}
                                    </div>
                                )} />
                        </div>
                        <div className="row m-0 mt-3">
                            <Controller control={control} name="semester"
                                render={({ field }) => (
                                    <div className="input-floating-label">
                                        <Select name="semester" id="semester" isDisabled={props?.EditData || props?.SemsterAdd} options={semesterOpts} {...field} placeholder="Semester" className="goals__form__select mb-0" classNamePrefix="mySelect"
                                            closeMenuOnSelect={true} isClearable={false} maxMenuHeight={130}
                                            components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                            styles={{
                                                option: (provided, state) => ({ ...provided, cursor: 'pointer', }),
                                                container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }),
                                                valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }),
                                                placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }),
                                                menu: (provided, state) => ({ ...provided, zIndex: 9999 }),
                                            }}
                                        />
                                        {errors?.semester && <div className="error-text" >{errors?.semester?.message}</div>}
                                    </div>
                                )} />
                        </div>
                        {(watch("semester")?.isPassed === true || selectedSemester === false) && (
                            <div className="row m-0 mt-3">
                                <Controller control={control} name="gradeachieved"
                                    render={({ field }) => (
                                        <div className="input-floating-label">
                                            <Select name="gradeachieved" id="gradeachieved" options={GradesOpts} {...field} placeholder="Grade Achieved" className="goals__form__select mb-0" classNamePrefix="mySelect"
                                                closeMenuOnSelect={true} isClearable={true}
                                                components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                                styles={{
                                                    container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible", zIndex: "2" }),
                                                    valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', }),
                                                    placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10 }),
                                                    menu: (provided, state) => ({ ...provided, zIndex: 9999 }),
                                                }}
                                            />
                                            <p className="gradeLabel mt-1 mb-0" >Optional. Visible only to advisors you have hired</p>
                                            {errors?.gradeachieved && <div className="error-text" >{errors?.gradeachieved?.message}</div>}
                                        </div>
                                    )} />

                            </div>
                        )}
                        {(watch("semester")?.isPassed !== true || currentSemester) && (
                            <div className="row m-0 mt-3">
                                <Controller control={control} name="helpwith"
                                    render={({ field }) => (
                                        <div className={screenWidth < 320 ? 'describer' : 'input-floating-label'}>
                                            <textarea {...field} refs="helpwith" maxLength={250} className={"textarea--primary textbox--rounded input"} name="Description"
                                                placeholder=" " style={{ "minHeight": "100px", "resize": "none" }} id="resourceName" />
                                            <label >What do you need help with ?</label>
                                            <div style={{ "display": "flex", "justifyContent": "space-between" }}>
                                                <p className="gradeLabel mt-1 mb-0" >Optional. Visible only to connected advisors</p>
                                                <p className="gradeLabel mt-1 mb-0" >{Description?.length ? Description?.length : 0} / 250</p>
                                            </div>
                                            {errors?.helpwith && <div className="error-text" >{errors?.helpwith?.message}</div>}
                                        </div>
                                    )} />
                            </div>
                        )}
                    </form>
                </div>
                <div style={stylesCourse.footer}>
                    <Button type="submit" form="my-course" disabled={isButtonDisabled} style={stylesCourse.modalbtn} className="popup-btn" id="submitcourse" >{props?.EditData ? "Edit Course" : "Add Course"}</Button>
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = ({ userProfileData, commonData, studentData }) => {
    const { courseNames, course, getphasetime, studentGrades } = commonData;
    const { isCourseError, getstudentplanphase, getstudentplan, isAddCourseError } = studentData;
    return { courseNames, course, getphasetime, studentGrades, isCourseError, getstudentplanphase, getstudentplan, isAddCourseError };
};

export default connect(mapStateToProps, { getCourse, getCourses, getStudentGrades, clearMessage })(withRouter(Index));
