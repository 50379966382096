import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Sidebar from "../../componentsNew/Student/Sidebar";
import ChatBox from "../../components/ChatBox";
import UpcommingSession from "../../components/UpCommingSession";
import CalendarComp from "../../components/Calendar";
import { connect } from "react-redux";
import {
    getUserProfile, getUserRoles, getUniversities, getProfessions, getGender, getCountry,
    getStatus, clearMessage, getCourse, getCourses, getActivities, getActivity, getStudentGrades,
    getPhasetime, getInviteAdvisor, getGraduationyear, ConnectParent, getUserGoals, getPlanStatus, getnotification,
    listScheduledEvents, getDashboardCount, getConnectedAdvisors, getExploreAdvisorCountForStudent, currentCoursesDashboard, currentActivitiesDashboard
} from "../../redux/actions";
import { withRouter } from "../../redux/store/navigate";

export const DashboardLayout = (props) => {
    let userProfile = useSelector((state) => state.userProfileData);

    useEffect(() => {
        props.getUserRoles();
        props.getProfessions();
        props.ConnectParent();
        props.getUserGoals();
        props.getPlanStatus();
        props?.getDashboardCount();
        props?.listScheduledEvents();
        props?.getConnectedAdvisors();
        props?.getExploreAdvisorCountForStudent();
        props.getUserProfile();

        let payload = {
            "pageNo": 1,
            "limit": 50
        }
        props.getnotification(payload);

        let currentDash = {
            // "studentId": props?.userProfile?.id,
            "userId": props?.userProfile?.id
        }
        props?.currentActivitiesDashboard(currentDash);
        window.scrollTo(0, 0);   // Scrolls to the top left corner
    }, [])

    useEffect(() => {
        let currentDash = {
            // "studentId": props?.userProfile?.id,
            "userId": props?.userProfile?.id
        }
        props?.currentCoursesDashboard(currentDash)
    }, [props?.userProfile?.id])

    useEffect(() => {
        console.log(props?.notificationdata,"notificationdata");
    }, [props?.notificationdata])
    

    return (
        <div id="dashboard-layout">
            <Sidebar userProfile={props?.userProfile} notification={props?.notificationdata} />
            <div className="center-wrap">{userProfile && <Outlet />}</div>
            <div className="right-wrap">
                <div className="minicnt">
                    <div class="" style={{ backgroundColor: "#F4F5F8", borderRadius: "1px solid #DBDBDB", width: '100%', height: '350px' }}></div>
                </div>
                <div className="upcomecnt">
                    <h5>Notifications</h5>
                    <UpcommingSession notification={props?.notificationdata}/>
                </div>
            </div>
            <CalendarComp />
            <ChatBox />
        </div>
    );
};
const mapStateToProps = ({ userProfileData, commonData }) => {
    const { isUserOnboarded, userProfile } = userProfileData;
    const { notificationdata } = commonData;
    return { isUserOnboarded, userProfile, notificationdata }
};

export default connect(mapStateToProps, {
    getUserProfile, getUserRoles, getUniversities, getProfessions, getGender, getCountry,
    getStatus, clearMessage, getCourse, getCourses, getActivities, getActivity, getStudentGrades,
    getPhasetime, getInviteAdvisor, getGraduationyear, ConnectParent, getUserGoals, getPlanStatus, getnotification,
    listScheduledEvents, getDashboardCount, getConnectedAdvisors, getExploreAdvisorCountForStudent, currentCoursesDashboard, currentActivitiesDashboard,
})(withRouter(DashboardLayout));