import React, { useEffect } from "react";
import { Modal, Box } from '@mui/material';
import { userConnect, userConnectStatus } from "../../redux/actions";
import CloseIcon from '@mui/icons-material/Close';
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PersonIcon from '@mui/icons-material/Person';
import { useImageUrl } from "../../utils/UseImageURL";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 400,
    minWidth: 270,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const validation = Yup.object({
    description: Yup.string().max(255, 'Description should not exceed 255 characters').nullable()
});


const Index = (props) => {
    useEffect(() => {
        reset()
    }, [])

    useEffect(() => {
        if (props.userConnectSuccess) {
            reset()
            props?.userConnectStatus(false)
        }
    }, [props.userConnectSuccess])

    const { register, handleSubmit, watch, reset, setValue, clearErrors, control, formState: { errors } } = useForm({ mode: "onSubmit", resolver: yupResolver(validation), });

    const handleClose = () => {
        props?.handleClose()
        reset()
    }
    const onSubmit = (data) => {
        props?.handleConnectValues(data, props?.data)
        handleClose()
    };
    const imgUrl = useImageUrl(props?.data?.requestSentUser?.avatarPath || props?.data?.userInfo?.avatarPath)
    return (
        <Modal
            open={props?.show}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <form id='my-course' onSubmit={handleSubmit(onSubmit)}>
                    <div className="p-2 text-center">
                        <div style={{ textAlign: 'right', marginBottom: '20px' }}>
                            <div onClick={handleClose}><CloseIcon id="closeIcon" sx={{ fill: 'black', cursor: 'pointer' }} /></div>
                        </div>
                        {props?.userProfile === "student" ? (
                            <p className="profileAboutCard__title mb-3">Please confirm that you would like to reject this connection request from this student</p>
                        ) : (
                            <p className="profileAboutCard__title mb-3">
                                Rejected connections cannot be undone. Would you like to specify a reason for rejecting this connection request ?
                            </p>)}
                        {props?.userProfile === "student" ? (
                            <>
                            </>
                        ) : (
                            <>
                                {(props?.data?.requestSentUser?.avatarPath || props?.data?.userInfo?.avatarPath) ? (
                                    <div style={{ marginBottom: '15px', width: "100px", height: '100px', borderRadius: "10px", marginInline: "auto" }}>
                                        <img src={imgUrl} alt="user" style={{ width: '100%', height:"100%", objectFit: "cover", borderRadius: '10px' }} />
                                    </div>
                                ) :
                                    <div class="" style={{ marginBottom: '15px' }}>
                                        <PersonIcon color="action" sx={{ width: "100px", height: '100px', borderRadius: "10px", border: '1px solid grey', }} />
                                    </div>
                                }
                                <div className="row m-0 mb-3">
                                    <div className="col-md-12 col-sm-12 col-12 p-0">
                                        <div className="input-floating-label mb-0">
                                            <textarea maxLength={255} {...register("description")} id="description"
                                                className={"textarea--primary textbox--rounded input"} name="description" placeholder=" " style={{ "minHeight": "100px", "resize": "none" }} />
                                            <label >Reject reason</label>
                                            <div className="subtext" style={{ float: 'right' }}><span>{watch("description")?.length || 0}</span>/255</div>
                                            <div style={{ "display": "flex", "alignItems": "flex-start" }}>
                                                <p className="cta--text">Optional</p>
                                                <p className="error-text">{errors.description?.message}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="resConnect ">
                            <div className='netWork-card__button-sec profileConnect sendConnect mt-0' >
                                <button className='netWork-card__button-sec__secondary-btn' id="rejectBtn" style={{ width: '50%' }}>Reject</button>
                            </div>
                        </div>
                    </div>
                </form>
            </Box>
        </Modal>
    );
};

const mapStateToProps = ({ commonData }) => {
    const { userConnectSuccess } = commonData
    return { userConnectSuccess }
};

export default connect(mapStateToProps, { userConnect, userConnectStatus })(Index);