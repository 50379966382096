import {combineReducers} from "redux";
import {routerReducer} from "react-router-redux";
import Auth from "./Auth";
import Common from "./Common";
import Advisor from "./Advisor";
import Parent from "./Parent";
import Student from "./Student";
import Profile from "./Profile";
import Explore from "./Explore";
import ExploreNetwork from "./ExploreNetworks";


const reducers = combineReducers({
  routing: routerReducer,
  auth: Auth,
  commonData: Common,
  userProfileData: Profile, 
  advisorData: Advisor,
  parentData: Parent,
  studentData: Student,
  exploreData: Explore,
  networkData: ExploreNetwork
});

export default reducers;
