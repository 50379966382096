import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap'
import { connect } from 'react-redux';
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import CancelIcon from '@mui/icons-material/Cancel';
import TextField from "@mui/material/TextField";
import { components } from "react-select";
import CreatableSelect from 'react-select/creatable';
import Loader from "../../../../components/Loader";
import { getUserGoals, getUserGoalsStatus, clearMessage, getGoalsFromStudent, getGoalsFromStudentStatus, CreateParentPlan, EditPlan } from "../../../../redux/actions";
import { universityApi } from "../../../../utils/AutoComplete";
import { useParams } from "react-router-dom";


const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder}</Placeholder>
            {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
        </ValueContainer>
    );
};
// Multi Value Remove
const MultiValueRemove = (props) => {
    return (
        <components.MultiValueRemove {...props} >
            <CancelIcon style={{ "fill": "rgba(34, 34, 34, 0.67)", "width": "23px", "height": "49px" }} />
        </components.MultiValueRemove>
    );
};

const Index = (props) => {
    const [loading, setloading] = useState(false)
    const [planname, setplanname] = useState("")
    const [plannameerror, setplannameerror] = useState("")
    const [universityCheckBox, setUniversityCheckBox] = useState(false);
    const [professionsCheckBox, setProfessionsCheckBox] = useState(false);
    const [gPACheckBox, setGPACheckBox] = useState(false);
    const [errorgoals, seterrorgoals] = useState("");
    // University Options
    const [selectedUniversityOption, setselectedUniversityOption] = useState([]);
    const [universityData, setuniversityData] = useState([]);
    const [universityError, setuniversityError] = useState("");
    // Profession Option
    const [selectedProfessionOption, setSelectedProfessionOption] = useState([]);
    const [professionOptionstions, setprofessionOptionstions] = useState([]);
    const [professionError, setprofessionError] = useState("");
    // Gpa Value
    const [Unweighted, setUnweighted] = useState("");
    const [Weighted, setWeighted] = useState("");
    const [UnweightedError, setUnweightedError] = useState("");
    const [WeightedError, setWeightedError] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        resetdata();
        setplanname("")
        if (props?.userProfile?.currentRole === "student") {
            setloading(true);
            props.getUserGoals();
        }
        if (props?.userProfile?.currentRole === "parent") {
            setloading(true);
            let getStudentId = {
                "studentId": localStorage.getItem("parentViaChild")
            }
            props.getGoalsFromStudent(getStudentId);
        }
        if (props?.userProfile?.currentRole === "advisor") {
            SetInitialvalue();
            let getStudentId = {
                "studentId": id
            }
            props.getGoalsFromStudent(getStudentId);
        }
        props.clearMessage();
    }, [])

    useEffect(() => {
        if (props?.isgetusergoals && props?.isgetusergoalsstatus) {
            setloading(false)
            props?.getUserGoalsStatus(false)
            SetInitialvalue();
        }
        if (props.professions) {
            setloading(false)
        }
    }, [props?.isgetusergoals, props?.isgetusergoalsstatus, props.professions])

    useEffect(() => {
        if (props?.data) {
            let proffessions = props.professions !== undefined && props.professions?.length > 0 && props.professions?.map((profession, key) => {
                let temp = { label: profession.profession, value: profession.profession }
                return temp;
            });
            setprofessionOptionstions(proffessions);
            setplanname(props?.data?.planInfo?.planName)
            setUniversityCheckBox(props?.data?.planInfo?.goals?.includes('university') ? true : false)
            setProfessionsCheckBox(props?.data?.planInfo?.goals?.includes('profession') ? true : false)
            setGPACheckBox(props?.data?.planInfo?.goals?.includes('gpa') ? true : false)
            let interestedUniversities = props?.data?.planInfo?.universities;
            let selectUniversity = [];
            interestedUniversities !== undefined && interestedUniversities.length > 0 && interestedUniversities.map((university, key) => {
                let temp = { label: university, value: university }
                selectUniversity.push(temp);
            });
            setselectedUniversityOption(selectUniversity)
            let interestedProfession = props?.data?.planInfo?.professions;
            let selectProffesion = [];
            interestedProfession !== undefined && interestedProfession.length > 0 && interestedProfession.map((profession, key) => {
                let temp = { label: profession, value: profession }
                selectProffesion.push(temp);
            });
            setSelectedProfessionOption(selectProffesion)

            // Gpa
            setUnweighted(props?.data?.planInfo?.unweighted ? props?.data?.planInfo?.unweighted : "")
            setWeighted(props?.data?.planInfo?.weighted ? props?.data?.planInfo?.weighted : "")
        }
    }, [props?.data])
    useEffect(() => {
        if (props?.isParentGoalStatus) {
            setloading(false)
            props?.getGoalsFromStudentStatus(false)
            if (!props?.data) {
                SetInitialvalue();
            }
        }
    }, [props?.isParentGoalStatus])

    const SetInitialvalue = () => {
        // setloading(true)
        let proffessions = props.professions !== undefined && props.professions?.length > 0 && props.professions?.map((profession, key) => {
            let temp = { label: profession.profession, value: profession.profession }
            return temp;
        });
        setprofessionOptionstions(proffessions);
        if (props?.isgetusergoals || (props?.parentGoal && !props?.EditPlan) || props?.parentGoal) {
            let goals = props?.isgetusergoals || props?.parentGoal
            setloading(false)
            // University
            let interestedUniversities = goals?.universities;
            let selectUniversity = [];
            interestedUniversities !== undefined && interestedUniversities.length > 0 && interestedUniversities?.map((university, key) => {
                let temp = { label: university, value: university }
                selectUniversity.push(temp);
            });
            setselectedUniversityOption(selectUniversity)
            // Profession
            let interestedProfession = goals?.professions;
            let selectProffesion = [];
            interestedProfession !== undefined && interestedProfession.length > 0 && interestedProfession.map((profession, key) => {
                let temp = { label: profession, value: profession }
                selectProffesion.push(temp);
            });
            setSelectedProfessionOption(selectProffesion)
            // Gpa
            setUnweighted(goals?.targetUnweighted ? goals?.targetUnweighted : "")
            setWeighted(goals?.targetWeighted ? goals?.targetWeighted : "")
        }
        if (props?.EditPlan && props?.data?.planInfo) {
            let goals = props?.data?.planInfo
            let interestedUniversities = goals?.universities;
            let selectUniversity = [];
            interestedUniversities !== undefined && interestedUniversities.length > 0 && interestedUniversities?.map((university, key) => {
                let temp = { label: university, value: university }
                selectUniversity.push(temp);
            });
            setselectedUniversityOption(selectUniversity)
            // Profession
            let interestedProfession = goals?.professions;
            let selectProffesion = [];
            interestedProfession !== undefined && interestedProfession.length > 0 && interestedProfession.map((profession, key) => {
                let temp = { label: profession, value: profession }
                selectProffesion.push(temp);
            });
            setSelectedProfessionOption(selectProffesion)
            // Gpa
            setUnweighted(goals?.unweighted ? goals?.unweighted : "")
            setWeighted(goals?.weighted ? goals?.weighted : "")
        }
    }

    // PlanName
    const PlanNameChange = (e) => {
        setplanname(e.target.value)
        setplannameerror("")
    }
    // Checkbox
    const handleCheckboxChange = (e, type) => {
        seterrorgoals("")
        if (type === 'universities') {
            setUniversityCheckBox(!universityCheckBox);
            setuniversityError("")
        } else if (type === 'profession') {
            setProfessionsCheckBox(!professionsCheckBox);
            setprofessionError("")
        } else if (type === 'GPA') {
            setGPACheckBox(!gPACheckBox);
            setUnweightedError("");
            setWeightedError("");
        }
        seterrorgoals("")
    }

    // University Tags
    const onTagsChange = (event, values) => {
        setuniversityError("")
        setselectedUniversityOption(values)
    }
    // University Search Tags
    const loadOptions = (value, callback) => {
        if (value === "") {
            setuniversityData([])
        }
        let list = []
        if (value !== "") {
            universityApi(value).then((res) => {
                if (res.status === 200) {
                    res?.data?.data.map((item, index) => {
                        list.push({
                            label: item.university,
                            value: item.university
                        });

                    });
                    setuniversityData(list)
                } else {
                    setuniversityData([])
                }
            })
        }
    }
    // Profession
    const handleSelectChange = (options, name) => {
        switch (name) {
            case "professions":
                if (options.length > 10) {
                    setprofessionError("Max upto 10 proffession allowed")
                }
                else {
                    if (options) {
                        if (options.at(-1)?.value?.length > 50 && options.at(-1)?.__isNew__ === true) {
                            setprofessionError("Should not exceed 50 characters")
                        }
                        else if (options.at(-1)?.value?.length < 50 && options.at(-1)?.__isNew__ === true) {
                            setSelectedProfessionOption(options)
                            setprofessionError("")
                        }
                        else {
                            setSelectedProfessionOption(options)
                            setprofessionError("")
                        }
                    }
                }
                break;
        }
    }
    // Set Gpa Value
    const GetGpa = (e, name) => {
        let value = e.target.value
        if (name === "Unweighted") {
            setUnweighted(value)
            setUnweightedError("")
        }
        if (name === "Weighted") {
            setWeighted(value)
            setWeightedError("")
        }
    }

    const resetdata = () => {
        setplanname("")
        setUniversityCheckBox(false)
        setProfessionsCheckBox(false)
        setGPACheckBox(false)
        setuniversityData([])
        setSelectedProfessionOption([])
        setUnweighted("")
        setWeighted("")
        setIsButtonDisabled(false);

    }

    const handleValidation = () => {
        let formIsValid = true
        if (planname === "") {
            formIsValid = false;
            setplannameerror("*Required")
        }
        if (planname !== "" && planname?.length > 50) {
            formIsValid = false;
            setplannameerror("Plan Name should not more than 50 character")
        }
        if (!universityCheckBox && !professionsCheckBox && !gPACheckBox) {
            seterrorgoals("Please select any goal")
            formIsValid = false;
        }
        if (universityCheckBox) {
            if (selectedUniversityOption === null) {
                formIsValid = false;
                setuniversityError("Please select any university")
            }
            else if (selectedUniversityOption.length === 0) {
                formIsValid = false;
                setuniversityError("Please select any university")
            }
        }
        if (professionsCheckBox) {
            if (selectedProfessionOption === null) {
                formIsValid = false;
                setprofessionError("Please select any profession")
            }
            else if (selectedProfessionOption.length === 0) {
                formIsValid = false;
                setprofessionError("Please select any profession")
            }
        }

        if (gPACheckBox) {
            if (Weighted === "") {
                formIsValid = false;
                setWeightedError("Please enter GPA")
            }
            if (Weighted !== "") {
                const decimalcaseRegExp = /^(?:[1-4](\.\d{1,2})?|5(\.0{1,2})?)$/;
                const floatcasenumber = decimalcaseRegExp.test(Weighted);
                if (!floatcasenumber) {
                    formIsValid = false;
                    setWeightedError("Invalid GPA")
                }
            }
            if (Unweighted === "") {
                formIsValid = false;
                setUnweightedError("Please enter GPA")
            }
            if (Unweighted !== "") {
                const decimalcaseRegExp = /^(?:[1-3](\.\d{1,2})?|4(\.0{1,2})?)$/;
                const floatcasenumber = decimalcaseRegExp.test(Unweighted);
                if (!floatcasenumber) {
                    formIsValid = false;
                    setUnweightedError("Invalid GPA")
                }
            }
        }
        return formIsValid;
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (handleValidation()) {
            setIsButtonDisabled(true);
            let selectedUniversityValues = selectedUniversityOption?.map(option => option?.value ? option?.value : option);
            let selectedProfessionValues = selectedProfessionOption.map(option => option?.value);
            let data = {
                planName: planname || "",
                specificUniversity: universityCheckBox || false,
                specificProfession: professionsCheckBox || false,
                improveGpa: gPACheckBox ? gPACheckBox : false,
                plannedUniversities: universityCheckBox === true ? selectedUniversityValues : [],
                plannedProfessions: professionsCheckBox === true ? selectedProfessionValues : [],
                weighted: gPACheckBox === true ? Weighted : null,
                unweighted: gPACheckBox === true ? Unweighted : null,
                accessibleAdvisors: []
            }
            if (props?.userProfile?.currentRole !== 'advisor') {
                if (props?.data?.planInfo?.id) {
                    let planId = props?.data?.planInfo?.id
                    props?.EditPlan(data, planId)
                } else if (props?.userProfile?.currentRole === 'parent') {
                    data["studentId"] = localStorage.getItem("parentViaChild")
                    props?.CreateParentPlan(data)
                }
                else if (!props?.data?.planInfo?.id) {
                    setloading(true);
                    props?.PlanApi(data)
                }
            } else {
                // setloading(true);
                props?.PlanApi(data)
            }
        }
    }

    return (
        <Modal size={'lg'} {...props} fullscreen={true} centered={true}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                {loading && <Loader />}
                <div className="role">
                    <div className="role_card" style={{ width: "430px !important", marginBottom: "100px" }}>
                        <h1 className="role_title text-center">
                            {props?.data ? "Edit new plan" : props?.userProfile?.currentRole === "advisor" ? "Suggest new plan" : "Create new plan"}
                        </h1>
                        <form className="login_card_form" autoComplete="off" >
                            <div className="goals__form">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="input-floating-label">
                                            <input type="text" onKeyPress={(e) => {
                                                if (e.key === 'Enter') { e.preventDefault() }
                                            }}
                                                onChange={(e) => PlanNameChange(e)} value={planname} className={"textbox--primary textbox--rounded input"} name="planname" placeholder="Plan Name" />
                                            <label>Plan Name</label>
                                            {plannameerror && <span className="error-text">{plannameerror}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* </form> */}
                            <div style={{ "display": "flex", "justifyContent": "center", "alignItems": "center", "flexDirection": "column", "textAlign": "center" }}>
                                <div className="goals__form">
                                    <p className="profile__form__sub-title_new" >Select one or more Goals for this Plan</p>
                                </div>
                                <p className="profile__form__sub-title_text mb-5" >Later you can create multiple plans with different sets of courses and activities for different goals</p>
                            </div>
                            <div className="goals_page">
                                <div className="goals_page">
                                    <div className="mb-2 role__btns">
                                        <input type="checkbox" checked={universityCheckBox ? true : false} onClick={(e) => handleCheckboxChange(e, 'universities')} className="btn-check" name="options1" id="btncheck1" autoComplete="off" />
                                        <label className="btn cta--role--btn w-mob-100" htmlFor="btncheck1">Get into specific universities</label>
                                    </div>
                                    <div className=" mb-2 role__btns">
                                        <input type="checkbox" checked={professionsCheckBox ? true : false} onClick={(e) => handleCheckboxChange(e, 'profession')} className="btn-check" name="options2" id="btncheck2" autoComplete="off" />
                                        <label className="btn cta--role--btn w-mob-100" htmlFor="btncheck2">Get into specific professions</label>
                                    </div>
                                    <div className=" mb-2 role__btns">
                                        <input type="checkbox" checked={gPACheckBox ? true : false} onClick={(e) => handleCheckboxChange(e, 'GPA')} className="btn-check" name="options3" id="btncheck3" autoComplete="off" />
                                        <label className="btn cta--role--btn w-mob-100" htmlFor="btncheck3">Improve GPA</label>
                                    </div>
                                </div>
                            </div>
                            {errorgoals && <span className="error-text text-center" style={{ display: "table", margin: "0 auto" }}>{errorgoals}</span>}
                            {/* <form className="" autoComplete="off"> */}
                            <div className="goals__form login_card_form mb-3">
                                {universityCheckBox &&
                                    <div className="row">
                                        <div className="col-md-12 mb-5 role__btns asyncmultiselect multinew">
                                            <Autocomplete multiple noOptionsText={'There is no university related to your search'} className="goals__form__select"
                                                id="tags-filled" name="universityDropdown" type='text' clearOnBlur={false} filterSelectedOptions clearIcon ChipProps forcePopupIcon={false} freeSolo={true} openOnFocus={true}
                                                options={universityData?.length > 0 ? universityData.map((option) => option.label) : []}
                                                renderTags={(value, getTagProps) => value.map((option, index) => (<Chip label={option} {...getTagProps({ index })} />))}
                                                filterOptions={(options, state) => options} defaultValue={selectedUniversityOption !== null ? selectedUniversityOption?.map((option) => option.label || option) : []}
                                                onChange={onTagsChange} onInputChange={async (event, value) => { loadOptions(value) }}
                                                renderInput={(params) => (<TextField {...params} label="Desired University" size="small"
                                                    inputProps={{ ...params.inputProps, onKeyDown: (e) => { if (e.key === 'Enter') { e.preventDefault(); } }, }} sx={{ '& .MuiOutlinedInput-root': { '&:hover fieldset': { borderColor: 'lightgrey', }, } }} />)}
                                            />
                                            {universityError && <span className="error-text">{universityError}</span>}
                                        </div>
                                    </div>
                                }
                                {professionsCheckBox &&
                                    <div className="row">
                                        <div className="col-md-12 mb-5 role__btns asyncmultiselect">
                                            <CreatableSelect onChange={(value) => handleSelectChange(value, 'professions')} className="goals__form__select" noOptionsMessage={() => null}
                                                value={selectedProfessionOption} options={professionOptionstions} placeholder="Professions" classNamePrefix="mySelect" id="professionsSelect" closeMenuOnSelect={true} isClearable={false} isMulti components={{ ValueContainer: CustomValueContainer, DropdownIndicator: () => null, IndicatorSeparator: () => null, MultiValueRemove }}
                                                styles={{
                                                    option: (provided, state) => ({ ...provided, cursor: 'pointer', }),
                                                    container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }),
                                                    valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', }),
                                                    placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10 })
                                                }}
                                            />
                                            {professionError && <span className="error-text">{professionError}</span>}
                                        </div>
                                    </div>
                                }
                                {gPACheckBox &&
                                    <div className="row">
                                        <div className="col-md-6 mb-3 role__btns">
                                            <div className="input-floating-label">
                                                <input value={Unweighted ? Unweighted : ""} onChange={(e) => GetGpa(e, "Unweighted")}
                                                    name="DesiredGPA" id="DesiredGPA" refs="DesiredGPA" className={"textbox--primary textbox--rounded input"} placeholder=" "
                                                    onKeyPress={(event) => { if (!/[0-9.]/.test(event.key)) { event.preventDefault(); } }}
                                                />
                                                <label>Target Unweighted GPA</label>
                                                {UnweightedError && <span className="error-text">{UnweightedError}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3 role__btns">
                                            <div className="input-floating-label">
                                                <input value={Weighted ? Weighted : ""} onChange={(e) => GetGpa(e, "Weighted")}
                                                    name="DesiredGPA" id="DesiredGPA" refs="DesiredGPA" className={"textbox--primary textbox--rounded input"} placeholder=" "
                                                    onKeyPress={(event) => { if (!/[0-9.]/.test(event.key)) { event.preventDefault(); } }}
                                                />
                                                <label>Target Weighted GPA</label>
                                                {WeightedError && <span className="error-text">{WeightedError}</span>}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer style={{ borderTop: 'none' }}>
                <Button className="PlanSaveBtn" style={{ "marginRight": "30px" }} disabled={isButtonDisabled} onClick={(e) => handleSubmit(e)} >Save</Button>
            </Modal.Footer>
        </Modal>
    )
}

const mapStateToProps = ({ studentData, advisorData, commonData, userProfileData, parentData }) => {
    const { professions } = commonData
    const { userProfile } = userProfileData
    const { isgetusergoals, isgetusergoalsstatus } = studentData;
    const { parentGoal, isParentGoalStatus } = parentData;
    return { isgetusergoals, isgetusergoalsstatus, professions, userProfile, parentGoal, isParentGoalStatus }
};

export default connect(mapStateToProps, { getUserGoals, getUserGoalsStatus, getGoalsFromStudentStatus, getGoalsFromStudent, EditPlan, clearMessage, CreateParentPlan })(Index);
