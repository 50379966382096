import React, { useEffect, useState } from "react";
// import Modal from "react-bootstrap/Modal";
import Rating from "@mui/material/Rating";
import { useForm, Controller } from "react-hook-form";
import "./feedback.less"
import { connect } from "react-redux";
import { FeedbackSubmit, clearMessage, FeedbackEdit, EditFeedbackReset, loadAdvisorReview,loadAdvisorReviewStatus } from "../../../redux/actions";
import CustomAlert from "../../../components/CustomAlert";
// import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';
// import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// import CloseIcon from '@mui/icons-material/Close';
import { useImageUrl } from '../../../utils/UseImageURL';
import dayjs from "dayjs";
import Modal from 'react-bootstrap/Modal';
import Loader from "../../../components/Loader";

const FeedbackForm = (props) => {
    const [loading, setLoading] = useState(false)
    const [rating, setRating] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [reviews, setReviews] = useState([]);
    const { handleSubmit, control, setValue, reset } = useForm();

    useEffect(() => {
        if (props?.feadbackSubmit || props?.editFeedBack) {
            handleClose()
            props?.clearMessage()
            let reviewPayload = {
                advisorId: props?.data ? props?.data?.advisorId : props?.advisorDetail?.id,
                "limit": 10,
                "pageNo": 1,
            studentId : localStorage?.getItem("parentViaChild")

            }
            props?.loadAdvisorReview(reviewPayload)
        }
        if (props?.editFeedBack) {
            props?.EditFeedbackReset(false)
        }

    }, [props?.feadbackSubmit, props?.editFeedBack])

    useEffect(()=>{
        if(props?.reviewDataStatus){
            props?.loadAdvisorReviewStatus(false)
            setLoading(false)
        }
    },[props?.reviewDataStatus])

    useEffect(() => {
        if (props?.data) {
          setRating(props?.data?.rating);
          if (pageNo === 1) {
            setReviews(props?.data); // Initial load of reviews
          } else {
            setReviews((prevReviews) => [...prevReviews, ...props?.data]);
          }
        }
      }, [props?.data, pageNo]);

   
    const handleClose = () => {
        reset();
        props.onHide();
        setRating(0);
        let reviewPayload = {
          advisorId: props?.advisorDetail?.id,
          limit: 10,
          pageNo: 1,
          studentId : localStorage?.getItem("parentViaChild")

        };
        props?.loadAdvisorReview(reviewPayload);
      };

      const handlePagination = () => {
        setLoading(true);
        const newPageNo = pageNo + 1;
        setPageNo(newPageNo);
        let reviewPayload = {
          advisorId: props?.advisorDetail?.id,
          limit: 10,
          pageNo: newPageNo
        };
        props?.loadAdvisorReview(reviewPayload);
      };
    

    useEffect(() => {
        if (props?.data) {
            setRating(props?.data?.rating);
        }
    }, [props?.data])

    const AvatarWithUrl = ({ avatarUrl }) => {
        const imageUrl = useImageUrl(avatarUrl);
        return <img className="user-Review__avatar" src={imageUrl} alt="profileimage" />;
    };
    const totalRecords = props?.totalreview?.data?.totalrecords || 0;

    console.log(reviews, "TotalRecords")
    return (
        <>
            <Modal {...props} style={{ zIndex: "9999", backgroundColor: "rgba(0, 0, 0, .5)", }} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title>What students say</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading && <Loader/>}
                    <div style={{ "height": "400px", overflowY: "scroll" }}>
                        {props?.data?.map((data, key) => (
                            <div className="userReviewCard" key={key}>
                                <div className="userReviewCard_cnt">
                                    <div className="userReviewCard_cnt_left">
                                        {data?.userImage ? (
                                            <AvatarWithUrl avatarUrl={data?.userImage} />
                                        ) : (
                                            <img className="avatarUrlimage" src={require("../../../assets/images/profileEdit.png")} alt="profile" />
                                        )}
                                    </div>
                                    <div className="userReviewCard_cnt_right">
                                        <div className="userReviewCard_cnt_right_top">
                                            <div className="userReviewCard_cnt_right_top_section">
                                                <div className="userReviewCard_cnt_right_top_section_cnt">
                                                    <p className="user-Review__reviewDetails__userName">
                                                        {data?.userName}
                                                    </p>
                                                    <Rating
                                                        max={1}
                                                        defaultValue={1}
                                                        readOnly
                                                        className="ms-2"
                                                    />
                                                    <p className="user-Review__reviewDetails__userName">
                                                        {data?.rating}
                                                    </p>
                                                </div>
                                            </div>
                                            <div>
                                                <p className="user-Review__reviewDetails__date">
                                                    {dayjs(data?.reviewedAt).format('MMMM, DD YYYY')}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="userReviewCard_cnt_right_bottom">
                                            <div className="userReviewCard_detail">
                                                <p className="user-Review__reviewDetails__review-msg">
                                                    {data?.reviewDescription}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {reviews.length < totalRecords  && 
                        <div className="review-sec__morebtn-sec">
                            <button className="review-sec__morebtn-sec__moreBtn mt-3" id="loadmorebtn" onClick={() => handlePagination()} >Load more</button>
                        </div>}
                    </div>
                </Modal.Body>

            </Modal>
        </>
    );
};

const mapStateToProps = ({ commonData }) => {
    const { feadbackSubmit, editFeedBack ,totalreview,reviewDataStatus} = commonData

    return { feadbackSubmit, editFeedBack,totalreview,reviewDataStatus}
};


export default connect(mapStateToProps, {
    FeedbackSubmit, clearMessage, FeedbackEdit, EditFeedbackReset, loadAdvisorReview,loadAdvisorReviewStatus
})(FeedbackForm);
