import {
	FETCH_ERROR,
	FETCH_START,
	FETCH_SUCCESS, CUSTOM_ALERT_SHOW, CUSTOM_ALERT_CLOSE
} from "../constants/CommonTypes";
import {
	INIT_URL,
	SIGNOUT_USER_SUCCESS,
	USER_TOKEN_SET, LOGIN_SUCESS,
	SIGNUP_USER_SUCCESS,
	EMAIL_VERIFICATION_SUCCESS,
	EMAIL_VERIFIED, NEW_REGISTER, NEWPASSWORD_RESET, SHARED_USER_DATA, REINVITE_EMAIL_VERIFICATION_SUCCESS, UPDATE_PASSWORD, VERIFY_PASSWORD, UPDATE_PASSWORD_STATUS, VERIFY_PASSWORD_STATUS, DELETE_USER, RESEND_EMAIL_VERIFICATION_SUCCESS, LOAD_PUBLIC_PROFILE_SUCCESS
} from "../constants/AuthActionTypes";
import axios from 'axios'
import { setSession, encryptData, getUserData, clearSession } from '../../utils/AuthUtils'
import { getUserRoles, getProfessions, getUniversities, getGender, getStatus, getCountry } from '../actions/Common'
import { getUserProfile } from "../actions/Profile";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const setInitUrl = (url) => {
	return {
		type: INIT_URL,
		payload: url
	};
};

export const userSignUp = requestParams => {
	return (dispatch) => {
		dispatch({ type: FETCH_START })
		axios.post(BASE_URL + '/user-service/v1/auth/register', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: SIGNUP_USER_SUCCESS });
				dispatch({ type: NEW_REGISTER, payload: true });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: NEW_REGISTER, payload: false });
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist } });
				dispatch({ type: NEW_REGISTER, payload: false });
				if (errorlist && errorlist?.length === 0 || errorlist === undefined) {
					dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
					setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
				}
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
				dispatch({ type: NEW_REGISTER, payload: false });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
			console.log("Error****:", error.message);
		});
	}
};

export const verifyEmail = requestParams => {
	return (dispatch) => {
		dispatch({ type: FETCH_START })
		axios.post(BASE_URL + '/user-service/v1/auth/email/verify', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: EMAIL_VERIFICATION_SUCCESS });
				dispatch({ type: EMAIL_VERIFIED, payload: true });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: EMAIL_VERIFIED, payload: false });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist, } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: EMAIL_VERIFIED, payload: false });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
				dispatch({ type: EMAIL_VERIFIED, payload: false });
			}
			console.log("Error****:", error.message);
			dispatch({ type: EMAIL_VERIFIED, payload: false });
			dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: "Token Expired", success: false } })
			setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
		});
	}
};
export const Emailverify = status => {
	return (dispatch) => {
		dispatch({ type: EMAIL_VERIFIED, payload: status });
	}
};

export const resendVerifyEmail = () => {
	return (dispatch) => {
		dispatch({ type: FETCH_START })
		axios.post(BASE_URL + '/user-service/v1/profile/resendverifyemail').then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: RESEND_EMAIL_VERIFICATION_SUCCESS, payload: true });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist, } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message || error?.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
			dispatch({ type: RESEND_EMAIL_VERIFICATION_SUCCESS, payload: false });
			dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: "Token Expired", success: false } })
			setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
		})
	}
}

export const setResendVerifyEmail = status => {
	return (dispatch) => {
		dispatch({ type: RESEND_EMAIL_VERIFICATION_SUCCESS, payload: status });
	}
};

export const userSignIn = requestParams => {
	return (dispatch) => {
		dispatch({ type: FETCH_START })
		axios.post(BASE_URL + '/user-service/v1/auth/login', requestParams).then(({ data }) => {
			const { headers: { statusCode, message }, accessToken, refreshKey } = data
			if (statusCode === "200") {
				setSession(encryptData(JSON.stringify({ accessToken, refreshKey })));
				axios.defaults.headers.common['Authorization'] = "Bearer " + accessToken;
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: LOGIN_SUCESS });
				dispatch({ type: USER_TOKEN_SET, payload: true });
				dispatch(getUserProfile());
				dispatch(getCountry());
				dispatch(getGender());
				dispatch(getStatus());
				dispatch(getUserRoles());
				dispatch(getProfessions());
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist } });
				console.log("errorlist", errorlist)
				console.log("errorlist?.length", errorlist?.length)
				if (errorlist && errorlist?.length === 0 || errorlist === undefined) {
					dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
					setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
				}
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			}
			console.log("Error****:", error.message);
		});
	}
};

export const socialLoginviaInvite = requestParams => {
	return (dispatch) => {
		dispatch({ type: FETCH_START })
		axios.post(BASE_URL + '/user-service/v1/user-invite/process/acceptinvite/viasocialmedia', requestParams).then(({ data }) => {
			const { headers: { statusCode, message }, accessToken, refreshKey } = data
			if (statusCode === "200") {
				setSession(encryptData(JSON.stringify({ accessToken, refreshKey })));
				axios.defaults.headers.common['Authorization'] = "Bearer " + accessToken;
				dispatch({ type: FETCH_SUCCESS });
				dispatch(getUserProfile());
				dispatch({ type: LOGIN_SUCESS, payload: true });
				dispatch({ type: USER_TOKEN_SET, payload: true });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
				//setTimeout(() => { dispatch({type:CUSTOM_ALERT_CLOSE})}, 3000)
			}
		})
	}
}
export const socialLoginViaInviteStatus = status => {
	return (dispatch) => {
		dispatch({ type: LOGIN_SUCESS, payload: status });
	}
};
export const googleLogin = requestParams => {
	return (dispatch) => {
		dispatch({ type: FETCH_START })
		axios.post(BASE_URL + '/user-service/v1/auth/socialmedia/google', requestParams).then(({ data }) => {
			const { headers: { statusCode, message }, accessToken, refreshKey } = data
			if (statusCode === "200") {
				setSession(encryptData(JSON.stringify({ accessToken, refreshKey })));
				axios.defaults.headers.common['Authorization'] = "Bearer " + accessToken;
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: LOGIN_SUCESS });
				dispatch({ type: USER_TOKEN_SET, payload: true });
				dispatch(getUserProfile());
				dispatch(getCountry());
				dispatch(getGender());
				dispatch(getStatus());
				dispatch(getUserRoles());
				dispatch(getProfessions());
				dispatch(getUniversities());
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				//setTimeout(() => { dispatch({type:CUSTOM_ALERT_CLOSE})}, 3000)
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
				//setTimeout(() => { dispatch({type:CUSTOM_ALERT_CLOSE})}, 3000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
				//setTimeout(() => { dispatch({type:CUSTOM_ALERT_CLOSE})}, 3000)
			}
			console.log("Error****:", error.message);
		});
	}
};
export const facebookLogin = requestParams => {
	return (dispatch) => {
		dispatch({ type: FETCH_START })
		axios.post(BASE_URL + '/user-service/v1/auth/socialmedia/facebook', requestParams).then(({ data }) => {
			const { headers: { statusCode, message }, accessToken, refreshKey } = data
			if (statusCode === "200") {
				setSession(encryptData(JSON.stringify({ accessToken, refreshKey })));
				axios.defaults.headers.common['Authorization'] = "Bearer " + accessToken;
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: LOGIN_SUCESS });
				dispatch({ type: USER_TOKEN_SET, payload: true });
				dispatch(getUserProfile());
				dispatch(getCountry());
				dispatch(getGender());
				dispatch(getStatus());
				dispatch(getUserRoles());
				dispatch(getProfessions());
				dispatch(getUniversities());
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				//setTimeout(() => { dispatch({type:CUSTOM_ALERT_CLOSE})}, 3000)
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
				//setTimeout(() => { dispatch({type:CUSTOM_ALERT_CLOSE})}, 3000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
				//setTimeout(() => { dispatch({type:CUSTOM_ALERT_CLOSE})}, 3000)
			}
			console.log("Error****:", error.message);
		});
	}
};

export const userSignOut = requestParams => {
	
	return (dispatch) => {
		axios.post(BASE_URL + '/user-service/v1/auth/logout', requestParams).then(({ data }) => {
			const { headers: { statusCode } } = data
			if (statusCode === "200") {
				localStorage.removeItem("token");
				localStorage?.clear()
				setSession(null)
				window.location.replace("/")
				dispatch({ type: SIGNOUT_USER_SUCCESS });
			}
		}).catch(function (error) {
			dispatch({ type: FETCH_ERROR, payload: error.message });
			console.log("Error****:", error.message);
		});
	}
};

export const refreshToken = () => {
	const refreshKey = getUserData('refreshKey')
	return (dispatch) => {
		axios.post(BASE_URL + '/user-service/v1/auth/refresh', { refreshKey }).then(({ data }) => {
			const { headers: { statusCode }, accessToken, refreshKey } = data
			if (statusCode !== "200") {
				clearSession()
			} else {
				setSession(encryptData(JSON.stringify({ accessToken, refreshKey })));
				axios.defaults.headers.common['Authorization'] = "Bearer " + accessToken;
			}
			dispatch({ type: FETCH_SUCCESS });
		}).catch(function (error) {
			clearSession()
			dispatch({ type: FETCH_ERROR, payload: error.message });
			console.log("Error****:", error.message);
		});
	}
}

export const forgetPassword = requestParams => {
	return (dispatch) => {
		axios.post(BASE_URL + '/user-service/v1/auth/forgotpassword/initate', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: NEWPASSWORD_RESET });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 10000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 10000)
				//NotificationManager.error(message, 'Error');
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist } });
				//NotificationManager.error(message ? message : error.message, 'Error');
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
				//NotificationManager.error(error.message, 'Error');
			}
			dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
			setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 10000)
			console.log("Error****:", error.message);
		});
	}
}
export const forgetPasswordstatus = status => {
	return (dispatch) => {
		dispatch({ type: NEWPASSWORD_RESET, payload: status });
	}
};
export const updatePassword = requestParams => {
	return (dispatch) => {
		axios.post(BASE_URL + '/user-service/v1/auth/forgotpassword/updatepassword', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: UPDATE_PASSWORD });
				dispatch({ type: UPDATE_PASSWORD_STATUS, payload: true });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 13000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist } });
				//NotificationManager.error(message ? message : error.message, 'Error');
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
				//NotificationManager.error(error.message, 'Error');
			}
			console.log("Error****:", error.message);
			dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
			setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
		});
	}
}
export const updatePasswordstatus = requestParams => {
	return (dispatch) => {
		dispatch({ type: UPDATE_PASSWORD_STATUS, payload: requestParams });
	}
}
export const verifyPassword = requestParams => {
	return (dispatch) => {
		axios.post(BASE_URL + '/user-service/v1/auth/forgotpassword/verify', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: VERIFY_PASSWORD });
				dispatch({ type: VERIFY_PASSWORD_STATUS, payload: true })
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: VERIFY_PASSWORD_STATUS, payload: false })
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { message: message, statusCode: statusCode, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist, } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
				dispatch({ type: VERIFY_PASSWORD_STATUS, payload: false })
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
				dispatch({ type: VERIFY_PASSWORD_STATUS, payload: false })
			}
			console.log("Error****:", error.message);
			dispatch({ type: VERIFY_PASSWORD_STATUS, payload: false })
			dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: "Invalid Request Initiated", success: false } })
			setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
		});
	}
};

export const verifyPasswordstatus = status => {
	return (dispatch) => {
		dispatch({ type: VERIFY_PASSWORD_STATUS, payload: status });
	}
};
export const acceptuserSignUp = requestParams => {
	return (dispatch) => {
		dispatch({ type: FETCH_START })
		axios.post(BASE_URL + '/user-service/v1/user-invite/process/acceptinvite', requestParams).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: SIGNUP_USER_SUCCESS });
				dispatch({ type: NEW_REGISTER, payload: true });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 6000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: NEW_REGISTER, payload: false });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 6000)
			}
		}).catch(function (error) {
			console.log("acceptuserSignUp", error)
			if (error.response) {
				if (error.response.data.headers.message !== " ") {
					console.log("error.response.data.message", error.response.data.headers.message)
					dispatch({ type: FETCH_ERROR, payload: { message: error.response.data.headers.message } });
					dispatch({ type: NEW_REGISTER, payload: false });
					dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.headers.message, success: false } })
					setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
				}
				const { headers: { message, statusCode }, errorlist, } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist.email } });
				dispatch({ type: NEW_REGISTER, payload: false });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.response.data.errorlist.email, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
				dispatch({ type: NEW_REGISTER, payload: false });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: error.message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 9000)
			}
			console.log("Error****:", error.message);
		});
	}
};
export const acceptuserSignUpstatus = status => {
	return (dispatch) => {
		dispatch({ type: NEW_REGISTER, payload: status });
	}
};
export const deleteuser = () => {
	return (dispatch) => {
		axios.post(BASE_URL + '/user-service/v1/profile/delete').then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: DELETE_USER, payload: true });
				localStorage.removeItem("token");
				localStorage.removeItem("leapBrainsWebApp");
				setSession(null)
				dispatch({ type: SIGNOUT_USER_SUCCESS });
			}
			else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: DELETE_USER, payload: false });
			}
		})
			.catch(function (error) {
				if (error.response) {
					const { headers: { message, statusCode }, errorlist, } = error.response.data;
					dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
					dispatch({ type: DELETE_USER, payload: false })
				}
				else {
					dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
					dispatch({ type: DELETE_USER, payload: false })
				}
				dispatch({ type: DELETE_USER, payload: false })
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: "Invalid Request Initiated", success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			});
	}
}

export const deleteUserStatus = status => {
	return (dispatch) => {
		dispatch({ type: DELETE_USER, payload: status });
	}
};

export const reInviteVerifyEmail = (id) => {
	return (dispatch) => {
		dispatch({ type: FETCH_START })
		axios.post(BASE_URL + '/user-service/v1/parent/reinvitechild/' + id ).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: REINVITE_EMAIL_VERIFICATION_SUCCESS, payload: true });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: true } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
				dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: message, success: false } })
				setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist, } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message || error?.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
			dispatch({ type: REINVITE_EMAIL_VERIFICATION_SUCCESS, payload: false });
			dispatch({ type: CUSTOM_ALERT_SHOW, payload: { msg: "Token Expired", success: false } })
			setTimeout(() => { dispatch({ type: CUSTOM_ALERT_CLOSE }) }, 3000)
		})
	}
}

export const setReInviteVerifyEmail = status => {
	return (dispatch) => {
		dispatch({ type: REINVITE_EMAIL_VERIFICATION_SUCCESS, payload: status });
	}
};
  

export const sharedUserProfile = (id )=> {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		await axios.get(BASE_URL + '/user-service/v1/userprofile/public/'+ id).then(({ data }) => {
			const { headers: { statusCode, message } } = data
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: SHARED_USER_DATA, payload: data?.recordInfo });
				dispatch({ type: LOAD_PUBLIC_PROFILE_SUCCESS, payload: true });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		}).catch(function (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error?.response?.data?.headers?.message } });
			}
		});
	}
}
export const resetPublicUserProfile = () => {
	return (dispatch) => {
		dispatch({ type: SHARED_USER_DATA, payload: {} });
	}
};
export const resetPublicProfileStatus = (status) => {
	return (dispatch) => {
		dispatch({ type: LOAD_PUBLIC_PROFILE_SUCCESS, payload: status });
	}
};