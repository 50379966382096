import React from "react";
import {Outlet} from "react-router-dom";
// import Sidebar from "../layouts/Block/Sidebar";
import Sidebar from "../componentsNew/Advisor/Sidebar"
import ChatBox from "../components/ChatBox";
import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { connect } from "react-redux";
import axios from 'axios';
import {  getUserData } from "../utils/AuthUtils";
import { getUserProfile, getUserRoles,  getUniversities, getProfessions, getGender, getCountry, 
         getStatus, clearMessage, getCourse, getCourses, getActivities, getActivity, getStudentGrades, 
         getChildProfile,getPhasetime,getInviteAdvisor, getParentProfile, getGraduationyear, getnotification
        } from "../redux/actions";
import { withRouter } from "../redux/store/navigate";

let style = {
    zIndex: 99, 
    position: "fixed", 
    top: 30,
    width: '80%',
    backgroundColor: '#EF6C00E5',
}

class DefaultLayout extends React.Component {
    componentDidMount(){
        this.props.clearMessage()
        if(getUserData('accessToken')){
            axios.defaults.headers.common['Authorization'] = "Bearer " + getUserData('accessToken');
            this.props.getGender()
            this.props.getStatus()
            this.props.getUserProfile()
            this.props.getChildProfile();
            this.props.getCountry()
            this.props.getActivity();
            this.props.getCourse();
            this.props.getActivities();
            this.props.getCourses();
            this.props.getProfessions();
            this.props.getStudentGrades();
            this.props.getParentProfile();
            this.props.getGraduationyear();
            let payload = {
                "pageNo":1,
                "limit":50
            }
            this.props.getnotification(payload)
        }
    }
    componentDidUpdate(){
        if (this.props.userProfile?.isOnboarded !== undefined && !this.props.userProfile?.isOnboarded) {
            this.props.navigate('/onboarding/roles');
        }
    }
    render(){
        const Alert = React.forwardRef(function Alert(props, ref) {
            return <MuiAlert style={style} elevation={8} ref={ref} variant="filled" {...props} />;
        });

        const action = (
            <Button style={{color: "#fff", border: '1px solid #fff', textTransform: "capitalize"}} variant="outlined" size="small">
                Turn On
            </Button>
        );
        return (
            <div>
                <div id="layoutSidenav">
                    <Sidebar notification= {this.props.notificationdata}/>
                    <div className="center-wrap-default">
                        {/* {!this.props.userProfile?.advisorProfile?.isServicesEnabled &&
                            <Alert className= "serviceInfoNotification" severity="warning" action={action}>
                                You will not be available for other users unless you turn on your services.
                            </Alert>
                        } */}
                        {this.props.userProfile && <Outlet/>}
                    </div>
                    <ChatBox />
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({userProfileData, commonData}) => {
    const {isUserOnboarded, userProfile} = userProfileData;
    const {notificationdata} = commonData;
    return {isUserOnboarded, userProfile, notificationdata}
};
  
export default connect(mapStateToProps, { getUserProfile, getUserRoles, getGraduationyear,  getUniversities, getProfessions, getGender, getCountry, getStatus, 
    getCourse, getActivity, getCourses, getActivities, getStudentGrades, getParentProfile, clearMessage,getChildProfile,getPhasetime,getInviteAdvisor, getnotification })(withRouter(DefaultLayout));

