//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'SIGNIN_GITHUB_USER_SUCCESS';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const USER_DATA = 'USER_DATA';
export const USER_TOKEN_SET = 'USER_TOKEN_SET';
export const USER_OTP_VERIFICATION_KEY = "USER_OTP_VERIFICATION_KEY";
export const OTP_VERIFICATION_SUCCESS = "OTP_VERIFICATION_SUCCESS";
export const EMAIL_VERIFICATION_SUCCESS = "EMAIL_VERIFICATION_SUCCESS";
export const RESEND_EMAIL_VERIFICATION_SUCCESS = "RESEND_EMAIL_VERIFICATION_SUCCESS";
export const EMAIL_VERIFIED = "EMAIL_VERIFIED";
export const LOGIN_SUCESS = 'LOGIN_SUCESS';
export const NEW_REGISTER = 'NEW_REGISTER';
export const NEWPASSWORD_RESET = 'NEWPASSWORD_RESET';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_STATUS = 'UPDATE_PASSWORD_STATUS';
export const VERIFY_PASSWORD = 'VERIFY_PASSWORD';
export const VERIFY_PASSWORD_STATUS = 'VERIFY_PASSWORD_STATUS';
export const DELETE_USER = 'DELETE_USER';
export const REINVITE_EMAIL_VERIFICATION_SUCCESS = "REINVITE_EMAIL_VERIFICATION_SUCCESS"
export const SHARED_USER_DATA = "SHARED_USER_DATA"
export const LOAD_PUBLIC_PROFILE_SUCCESS = "LOAD_PUBLIC_PROFILE_SUCCESS"
