import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getPendingRequestsParent, setConnectedStudentStatus } from "../../../../redux/actions"
import "../parentMyNetwork.less";
import RecievedRequest from "../../../../componentsNew/Common/RequestCards/RecievedRequest"

const Index = (props) => {
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        let childId = localStorage.getItem("parentViaChild")
        props?.getPendingRequestsParent(childId)
    }, [])

    useEffect(() => {
    
        if (props?.parentRequest) {
            let childId = localStorage.getItem("parentViaChild")
            props?.getPendingRequestsParent(childId)
        }
      }, [ props?.parentRequest])
    return (
        <RecievedRequest />
    )
}

const mapStateToProps = ({ commonData, userProfileData }) => {
    const { message, errorList, connectedStudents, connectedStudentsStatus, pendingList, parentRequest } = commonData;
    const { isDisconnectUser, childprofile } = userProfileData
    return { message, errorList, connectedStudents, connectedStudentsStatus, isDisconnectUser, childprofile,  pendingList, parentRequest};
};
export default connect(mapStateToProps, { getPendingRequestsParent, setConnectedStudentStatus })(Index);