import React, { useState } from "react";
import "./totalRating.less"
import { Rating } from '@mui/material'
import { Button } from 'bootstrap'
import FeedBackPopup from "../../../../../componentsNew/Common/FeedBackPopup";
import { connect } from "react-redux";
import { withRouter } from "../../../../../redux/store/navigate";
import { useLocation } from 'react-router-dom';

const Index = (props) => {
  const [feedBack, setFeedBack] = useState(false);
  const isOnboarding = window?.location?.href?.includes('onboarding');
  const exploreAdvisor = window?.location?.href?.includes('exploreAdvisor');

  const showFeedBack = (e) => {
    e.preventDefault();
    setFeedBack(true)
  }
  const location = useLocation();
  const userId = props?.userProfile?.currentRole === "parent" ? localStorage?.getItem("parentViaChild") : props?.userProfile?.id;
  const isUserReviewed = props?.review?.some(review => review.userId === userId);
  

  const averageRating = props?.averageRating || 0;
  const ReceivedRequest = sessionStorage.getItem("netvalue");

  console.log(props?.advisorData?.subscriptions?.length, "props?.advisorDetail")

  return (
    <>
      <FeedBackPopup size={"md"}
        dialogClassName=""
        fullscreen={true}
        centered={false}
        showFeedBack={feedBack}
        advisorDetail={props?.advisorDetail}
        onHide={() => setFeedBack(false)}
      // onsetFeedBack={(isfeedBack) => this.setFeedBack(isfeedBack)}
      />

      <div className='totalRating-sec'>
        <p className='totalRating-sec__rate-value'>{props?.averageRating?.toFixed(1) || 0}</p>
        <Rating name="size-medium" value={averageRating} readOnly precision={0.5} />
        {(props?.totalReviews > 1) ? (
          <p className='totalRating-sec__review-count'>{props?.totalReviews} reviews</p>
        ) :
          (
            <p className='totalRating-sec__review-count'>{props?.totalReviews} review</p>
          )
        }
        {(ReceivedRequest == 2 || ReceivedRequest == 3 || exploreAdvisor) ? (
          <p></p>
        ) :
          <>
            {(!isOnboarding && !isUserReviewed && props?.advisorData?.subscriptions?.length > 0) && <button onClick={(e) => showFeedBack(e)} className='totalRating-sec__feedBack-btn' id='feedBack-btn' ><p className='totalRating-sec__feedBack-btn__text'>Give Feedback</p></button>}
          </>
        }

      </div>
    </>
  )
}

const mapStateToProps = ({ userProfileData }) => {
  const { userProfile } = userProfileData;
  return { userProfile };
};

export default connect(mapStateToProps,)(withRouter(Index));


