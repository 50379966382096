import React from "react";
import { connect } from "react-redux";
import { uploadProfilePicture, deleteProfilePicture } from "../../../../../redux/actions";
import Avatar1 from "../../../../../assets/images/icons/user_icon.png";
import Button from '@mui/material/Button';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useImageUrl } from "../../../../../utils/UseImageURL";

const Index = (props) => {

    const handleCapture = (e) => {
        if (e.target.files !== undefined && e.target.files[0]) {
            const formData = new FormData();
            formData.append("profilePicture", e.target.files[0]);
            props.uploadProfilePicture(formData);
            e.target.value = "";
        }
    }

    const deleteProfileImg = () => {
        props.deleteProfilePicture();
    }

    const imgUrl = useImageUrl(props?.userProfile?.avatarPath)

    return (
        <>
            <div className="Profile_Image_Card">
                <div className="User_Image">
                    {props?.userProfile?.avatarPath ?
                        <>
                            <img src={imgUrl} alt="profileimage" className="profileimage" />
                            <span className="deletePic" onClick={() => deleteProfileImg()}>
                                <CancelOutlinedIcon className="deleteIcon" sx={{ fontSize: "22px", color: "#939292", bgcolor: "#fff", borderRadius: "50%" }} />
                            </span>
                        </>
                        :
                        <img src={Avatar1} alt="profileimage" className="profileimage" />
                    }
                </div>
                <div className="Upload_Btn_Cnt">
                    <div className="Upload_Btn_Cnt_Top">
                        <Button className="Upload_Btn" component="label" startIcon={
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M8.25 13C8.25 10.9289 9.92894 9.25 12 9.25C14.0711 9.25 15.75 10.9289 15.75 13C15.75 15.0711 14.0711 16.75 12 16.75C9.92894 16.75 8.25 15.0711 8.25 13ZM12 10.75C10.7574 10.75 9.75 11.7574 9.75 13C9.75 14.2426 10.7574 15.25 12 15.25C13.2426 15.25 14.25 14.2426 14.25 13C14.25 11.7574 13.2426 10.75 12 10.75Z" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.6161 5.75C9.57097 5.75 8.72369 6.59728 8.72369 7.64245C8.72369 8.30841 8.21246 8.86283 7.54869 8.91674L5.31909 9.09782C4.88132 9.13337 4.52129 9.45709 4.43958 9.88864C3.99084 12.2586 3.95738 14.6886 4.34069 17.0701L4.43794 17.6743C4.52979 18.2449 4.99942 18.679 5.57548 18.7258L7.5178 18.8835C10.501 19.1258 13.499 19.1258 16.4822 18.8835L18.4245 18.7258C19.0006 18.679 19.4702 18.2449 19.5621 17.6743L19.6593 17.0701C20.0426 14.6886 20.0092 12.2586 19.5604 9.88864C19.4787 9.45709 19.1187 9.13337 18.6809 9.09782L16.4513 8.91674C15.7875 8.86284 15.2763 8.30841 15.2763 7.64245C15.2763 6.59728 14.429 5.75 13.3839 5.75H10.6161ZM7.22976 7.4377C7.33562 5.65944 8.81128 4.25 10.6161 4.25H13.3839C15.1887 4.25 16.6644 5.65944 16.7702 7.4377L18.8023 7.60274C19.9133 7.69296 20.8269 8.51446 21.0342 9.60958C21.5153 12.1503 21.5512 14.7554 21.1403 17.3084L21.043 17.9126C20.8414 19.1652 19.8105 20.1182 18.5459 20.2209L16.6036 20.3786C13.5396 20.6274 10.4604 20.6274 7.39638 20.3786L5.45406 20.2209C4.18953 20.1182 3.15861 19.1652 2.957 17.9126L2.85975 17.3084C2.44882 14.7554 2.48469 12.1503 2.96577 9.60958C3.17312 8.51446 4.08675 7.69296 5.19767 7.60274L7.22976 7.4377Z" />
                            </svg>
                        } >
                            Edit photo
                            <input hidden accept="image/*" multiple type="file" onChange={(e) => handleCapture(e)} id="uploadbtninput"/>
                        </Button>
                        <div className="Upload_Btn_Cnt_Btm">
                            <p>Max size 100 Mb</p>
                        </div>
                    </div>
                    {/* <div className="Upload_Btn_Cnt__deletebtn">
                        <DeleteOutlineOutlinedIcon sx={{color: "#E64A19" }}/>
                    </div> */}
                </div>
            </div>
            {
                props.errorList?.profilePicture &&
                <span className="error-text">{props.errorList?.profilePicture}</span>
            }
        </>
    );
}
const mapStateToProps = ({ userProfileData, commonData }) => {
    const { message, errorList } = commonData;
    const { userProfile } = userProfileData;
    return { message, errorList, userProfile };
};
export default connect(mapStateToProps, { uploadProfilePicture, deleteProfilePicture })(Index);