import React, { useEffect, useState } from 'react';
import "./subscriptions.less";
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Select, { components } from "react-select";
import { Box, Grid, Tab, Tabs, Typography, } from '@mui/material';
import ConnectedStudents from '../../../../componentsNew/Common/Cards/Common/ConnectedStudents';
import { setCurrentSubscribersSuccessStatus, getCurrentSubscribers, getPastSubscribers, setPastSubscribersSuccessStatus, offerExtraSession, setOfferExtraSessionStatus } from "../../../../redux/actions";
import ViewStudentProfile from '../../../../componentsNew/Common/ViewStudentProfile';
import { convertUtcToEventTime } from '../../../../utils/CommonUtils';
import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ExtraSessionModal from '../../../../componentsNew/Advisor/ExtraSessionModal'; 
import CustomAlert from '../../../../components/CustomAlert';

const menulist = [
    { value: 0, label: "Current Subscribers" }, 
    { value: 1, label: "Past Subscribers" }, 
]
const validation = Yup.object({
	sessionLength: Yup.number().required('*Required').nullable()
})
const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder}</Placeholder>
            {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
        </ValueContainer>
    );
};
const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <Typography component="div" role="tabpanel" hidden={value !== index} id={`scrollable-auto-tabpanel-${index}`} aria-labelledby={`scrollable-auto-tab-${index}`} {...other} >
            {value === index && (
				<Box className="myWorkContentSec" p={3}>{children}</Box>
			)}
        </Typography>
    );
}
const a11yProps = (index) => {
    return { id: `scrollable-auto-tab-${index}`, "aria-controls": `scrollable-auto-tabpanel-${index}` };
}
const sessionLength = [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 }
]


const Index = (props) => {
    const [loading, setLoading] = useState(false);
    const [showProfile, setShowProfile] = useState(false);
    const [profileData, setProfileData] = useState({});
    const [selectedOption, setSelectedOption] = useState(menulist[0]);
    const [tabs, setTabs] = useState(0);
    const [showOfferSessionModal, setShowOfferSessionModal] = useState(false);
	const [extraSessionDetails, setExtraSessionDetails] = useState({});
    const navigate = useNavigate();

    const { handleSubmit, control, reset, formState: { errors } } = useForm({
		mode: "onSubmit",
		resolver: yupResolver(
            validation
        ),
    });

	useEffect(() => {
		setLoading(true)
		props.getCurrentSubscribers();
		props.getPastSubscribers();
	}, [])

    useEffect(() => {
		if(props?.offerExtraSessionStatus){
            props.setOfferExtraSessionStatus(false);
		    props.getCurrentSubscribers();
            reset()
            setShowOfferSessionModal(false);
            setExtraSessionDetails({});
		}
	}, [props.offerExtraSessionStatus])

    const findOptionByValue = (value) => {
        return menulist.find((option) => option.value === value);
    }
    const handleSwitchTabs = (event, newValue) => {
        setTabs(newValue);
        setSelectedOption(findOptionByValue(newValue));
    }
	const handleSelectTabs = (e) => {
        setTabs(e.value);
        setSelectedOption(e);
    }
    const showProfileModal = (status) => {
        if(!status) {
            setProfileData({})
        }
        setShowProfile(status)
    }
    const handleViewProfile = (e, data) => {
        e.stopPropagation();
        setProfileData(data);
        showProfileModal(true);
    }

    const handleNavigate = (data) => {
        navigate(data?.id, {state: {id: data?.id, studentName: data?.fullName, studentFirstName: data?.firstName}})
    }

    const getScheduledTime = (userId, packId) => {
        let eventDetails = props.scheduledSessionDetails
			?.find(data => (data?.userId == userId && packId === data?.packageId && data?.userInvitationStatuses?.find(invite => invite?.userId == userId && invite?.status == "accepted")))
		if(eventDetails){
			let date = new Date(eventDetails?.eventTime)
        	let formattedDate = date.toLocaleDateString('en-GB', {
				day: '2-digit',
            	month: '2-digit'
        	});
            return `${formattedDate} at ${convertUtcToEventTime(eventDetails?.eventTime, props?.userProfile?.timezone)?.format('hh:mm A')}`
        } else {
            return null;
        }
	}

    const handleOfferAnotherSession = (event, status, data, firstName) => {
		event?.stopPropagation();
		let sessionInfo = {
			...data, firstName
		}
		setShowOfferSessionModal(status);
		setExtraSessionDetails(sessionInfo);
		console.log(sessionInfo);
	}

    const onSubmit = data => {
		let payload = {
			noOfExtraSessions: data?.sessionLength,
			studentId: extraSessionDetails?.userId,
			paymentPackageId: extraSessionDetails?.packageId
		}
		props?.offerExtraSession(payload);
	}

    const packageDetails = (subscribedPackages, firstName, isExpired) => subscribedPackages?.map(packageInfo => {
        const subscribedAt = new Date(packageInfo?.subscribedAt);
        const formattedSubscribedDate = `${subscribedAt.getMonth() + 1}/${subscribedAt.getDate()}/${subscribedAt.getFullYear().toString().slice(-2)}`;
        let expiresAt;
        let formattedExpirationDate;
        if(packageInfo?.subscriptionEndsAt){
            expiresAt = new Date(packageInfo?.subscriptionEndsAt);
            formattedExpirationDate = `${expiresAt.getMonth() + 1}/${expiresAt.getDate()}/${expiresAt.getFullYear().toString().slice(-2)}`;
        }
        return(
            <div className="connectedStudentCard__midSec__packageSec" key={packageInfo?.packageInfo?.id}>
                <span className='connectedStudentCard__midSec__packageSec__section'>
                    <span className='connectedStudentCard__midSec__packageSec__section__packName'>
                        <DiamondOutlinedIcon className='connectedStudentCard__midSec__packageSec__section__packName__icon'/>
                        <p className='connectedStudentCard__midSec__packageSec__section__packName__primaryText'>{packageInfo?.packageInfo?.packageName}</p>
                    </span>
                    <p className='connectedStudentCard__midSec__packageSec__section__secondaryText'>{packageInfo?.subscriptionType} Package</p>
                </span>
                <span className='connectedStudentCard__midSec__packageSec__validitySection'>
                    <p className='connectedStudentCard__midSec__packageSec__validitySection__tertiaryText'>Purchased on {formattedSubscribedDate}</p>
                    {formattedExpirationDate &&
                        <p className='connectedStudentCard__midSec__packageSec__validitySection__tertiaryText'>{isExpired ? "Expired" : packageInfo?.subscriptionType == "monthly" ? 'Renews' : 'Expiring' } on {formattedExpirationDate}</p>
                    }
                </span>
                <span className='connectedStudentCard__midSec__packageSec__section'>
                    <p className='connectedStudentCard__midSec__packageSec__section__helperText'><span className='strongText'> {packageInfo?.noOfPaidSessions || 0}</span> sessions remaining</p>
                    <p className='connectedStudentCard__midSec__packageSec__section__helperText'><span className='strongText'>0</span> outgoing messages remaining</p>
                </span>
                {getScheduledTime(packageInfo?.userId, packageInfo?.packageInfo?.id) &&
                    <span className='connectedStudentCard__midSec__packageSec__scheduleDetailSec'>
                        <CalendarTodayOutlinedIcon className='connectedStudentCard__midSec__packageSec__scheduleDetailSec__calendarIcon'/>
                        <p className='connectedStudentCard__midSec__packageSec__scheduleDetailSec__meetTime'>You are scheduled to meet on {getScheduledTime(packageInfo?.userId, packageInfo?.packageInfo?.id)}</p>
                    </span>
                }
                {!isExpired && packageInfo?.noOfPaidSessions == 0 &&
                    <span className='connectedStudentCard__midSec__packageSec__section'>
                        <button className='connectedStudentCard__midSec__packageSec__section__sessionOfferBtn' onClick={(event) => handleOfferAnotherSession(event, true, packageInfo, firstName)}>
                            Offer another Session
                        </button>
                    </span>
                }
            </div>
        )
    })

    return (
        <>
            <Grid container spacing={5} className="Advisor-mySubscriptions__mainCnt" >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="advisor-mySubscriptions_Sec">
                        <p className="mySubscriptionsTitle">Your Students</p>
                    </div>
                    <div className="advisor-mySubscriptions_Mobile">
                        <p className="mySubscriptionsTitle">Your Students</p>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='Advisor-mySubscriptions__mainCnt__section'>
                    <div className="Advisor-mySubscriptions__mainCnt__section--mobileTabSec">
                        <Select options={menulist} className='selectMenu'
							value={selectedOption} isSearchable= {false} onChange={handleSelectTabs}
							getOptionLabel={e => (
								<div style={{ display: 'flex', alignItems: 'center' }}>
									{e.icon}
									<span style={{ marginLeft: 10 }}>{e.label}</span>
								</div>
							)}
							placeholder=" " components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
							styles={{ option: (provided, state) => ({ ...provided, cursor: 'pointer', }), container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }}
						/>
                    </div>
                    <div className="Advisor-mySubscriptions__mainCnt__section--desktopTabSec">
                        <Tabs value={tabs} onChange={handleSwitchTabs}>
							<Tab disableRipple label="Current Subscribers" className="Tab" {...a11yProps(0)} />
							<Tab disableRipple label="Past Subscribers" className="Tab" {...a11yProps(1)} />
						</Tabs>
                    </div>
                    <div className="Advisor-mySubscriptions__mainCnt__section--tabPannelSec">
                        <TabPanel value={tabs} index={0} className="Advisor-mySubscriptions__mainCnt__section--tabPannelSec__pannel">
                            {props?.currentSubscribers?.length > 0 ? 
                                <div className="Advisor-mySubscriptions__mainCnt__section--tabPannelSec__pannel__subscriptionSec">
                                    {props.currentSubscribers?.map((data) => {
                                        return(
                                            <div onClick={() => handleNavigate(data?.userInfo)} key={data?.userInfo?.id}>
                                                <ConnectedStudents isClickable= {true}
                                                    name = {data?.userInfo?.fullName}
                                                    firstName= {data?.userInfo?.firstName}
                                                    country = {data?.userInfo?.country}
                                                    avatarUrl= {data?.userInfo?.avatarPath}
                                                    school = {data?.userInfo?.studentProfile?.currrentSchoolName}
                                                    gpa = {data?.userInfo?.studentProfile?.desiredGpa}
                                                    universities = {data?.userInfo?.studentProfile?.universities?.toSorted((a, b) => {return a.length - b.length} )}
                                                    professions = {data?.userInfo?.studentProfile?.professions?.toSorted((a, b) => {return a.length - b.length})}
                                                    // matchParam = {element?.matchingParams?.[0]}
                                                    courseAndActivities = {data?.userInfo?.studentProfile?.courses?.concat(data?.userInfo?.studentProfile?.activities)?.toSorted((a,b) => a?.length - b?.length)}
                                                    weighted= {data?.userInfo?.studentProfile?.weighted}
                                                    unWeighted= {data?.userInfo?.studentProfile?.unweighted}
                                                    subscribedPackages= {packageDetails(data?.subscriptions, data?.userInfo?.firstName, false)}
                                                    recipientUrl
                                                    // offerExtraSessionData= {} 
                                                >
                                                    <button className="suggestedStudentCard__buttonSec__primary-btn" onClick={(e) => handleViewProfile(e, {id: data?.userInfo?.id})}>
                                                        View Profile    
                                                    </button>
                                                </ConnectedStudents>
                                            </div>
                                        )
                                    })}
                                </div>
                            : 
                                <div className="mySubscriptionsNoDataSec">
                                    <div className="mySubscriptionsNoDataSec__noDataCard">
                                        <p className="mySubscriptionsNoDataSec__noDataCard__textBlack">
                                            You currently have no subscribed students! 
                                        </p>
                                        <span className="mySubscriptionsNoDataSec__noDataCard__cta">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="mySubscriptionsNoDataSec__noDataCard__cta__searchIcon" viewBox="0 0 24 24" fill="none">
                                                <circle cx="11" cy="11" r="6" stroke="#222222"/>
                                                <path d="M11 8C10.606 8 10.2159 8.0776 9.85195 8.22836C9.48797 8.37913 9.15726 8.6001 8.87868 8.87868C8.6001 9.15726 8.37913 9.48797 8.22836 9.85195C8.0776 10.2159 8 10.606 8 11" stroke="#222222" stroke-linecap="round"/>
                                                <path d="M20 20L17 17" stroke="#222222" stroke-linecap="round"/>
                                            </svg>
                                            <Link to={'/advisor/mynetwork/exploreStudents'}>
                                                <p className="mySubscriptionsNoDataSec__noDataCard__cta__blueText">
                                                    Explore Students
                                                </p>
                                            </Link>
                                        </span>
                                    </div>
                                </div>
                            }
						</TabPanel>
						<TabPanel value={tabs} index={1} className="Advisor-mySubscriptions__mainCnt__section--tabPannelSec__pannel">
                            {props?.pastSubscribers?.length > 0 ? 
                                <div className="Advisor-mySubscriptions__mainCnt__section--tabPannelSec__pannel__subscriptionSec">
                                    {props.pastSubscribers?.map((data) => {
                                        return(
                                            <div key={data?.userInfo?.id}>
                                                <ConnectedStudents 
                                                    name = {data?.userInfo?.fullName}
                                                    firstName= {data?.userInfo?.firstName}
                                                    country = {data?.userInfo?.country}
                                                    avatarUrl= {data?.userInfo?.avatarPath}
                                                    school = {data?.userInfo?.studentProfile?.currrentSchoolName}
                                                    gpa = {data?.userInfo?.studentProfile?.desiredGpa}
                                                    universities = {data?.userInfo?.studentProfile?.universities?.toSorted((a, b) => {return a.length - b.length} )}
                                                    professions = {data?.userInfo?.studentProfile?.professions?.toSorted((a, b) => {return a.length - b.length})}
                                                    // matchParam = {element?.matchingParams?.[0]}
                                                    courseAndActivities = {data?.userInfo?.studentProfile?.courses?.concat(data?.userInfo?.studentProfile?.activities)?.toSorted((a,b) => a?.length - b?.length)}
                                                    weighted= {data?.userInfo?.studentProfile?.weighted}
                                                    unWeighted= {data?.userInfo?.studentProfile?.unweighted}
                                                    subscribedPackages= {packageDetails(data?.subscriptions, data?.userInfo?.firstName, true)}
                                                    recipientUrl
                                                >
                                                    <button className="suggestedStudentCard__buttonSec__primary-btn" onClick={(e) => handleViewProfile(e, {id: data?.userInfo?.id})}>
                                                        View Profile    
                                                    </button>
                                                </ConnectedStudents>
                                            </div>
                                        )
                                    })}
                                </div>
                            : 
                                <div className="mySubscriptionsNoDataSec">
                                    <div className="mySubscriptionsNoDataSec__noDataCard">
                                        <p className="mySubscriptionsNoDataSec__noDataCard__textBlack">
                                            You have no past subscribers 
                                        </p>
                                        <span className="mySubscriptionsNoDataSec__noDataCard__cta">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="mySubscriptionsNoDataSec__noDataCard__cta__searchIcon" viewBox="0 0 24 24" fill="none">
                                                <circle cx="11" cy="11" r="6" stroke="#222222"/>
                                                <path d="M11 8C10.606 8 10.2159 8.0776 9.85195 8.22836C9.48797 8.37913 9.15726 8.6001 8.87868 8.87868C8.6001 9.15726 8.37913 9.48797 8.22836 9.85195C8.0776 10.2159 8 10.606 8 11" stroke="#222222" stroke-linecap="round"/>
                                                <path d="M20 20L17 17" stroke="#222222" stroke-linecap="round"/>
                                            </svg>
                                            <Link to={'/advisor/mynetwork/exploreStudents'}>
                                                <p className="mySubscriptionsNoDataSec__noDataCard__cta__blueText">
                                                    Explore Students
                                                </p>
                                            </Link>
                                        </span>
                                    </div>
                                </div>
                            }
						</TabPanel>
                    </div>
                </Grid>
            </Grid>
            {showProfile &&
                <ViewStudentProfile show={showProfile} profileData={profileData} handleClose={() => showProfileModal(false)}/>
            }
            {showOfferSessionModal && 
                <ExtraSessionModal 
				show={showOfferSessionModal} 
				message={`This will add additional sessions to ${extraSessionDetails?.packageInfo?.packageName} for ${extraSessionDetails?.firstName} expiring in 10 days`} 
				btnText="confirm" 
				onHide={(event) => handleOfferAnotherSession(event, false, {}, '')} 
			>
				<div className="alertComponent">
					{Object.keys(props.errorList).length === 0 && <CustomAlert />}
				</div>
				<form className="extraSessionModal__bottomSec"  onSubmit={handleSubmit(onSubmit)} >
					<div className="input-floating-label extraSessionModal__bottomSec__extraSessionField">
						<Controller
							name="sessionLength"
							control={control}
							render={({field:{ onChange, value, ref }}) => (
								<Select className="goals__form__select max__messages mb-0"
									classNamePrefix="mySelect" name="sessionLength"
									options={sessionLength} closeMenuOnSelect={true}
									onChange={(val) => onChange(val?.value)}
									value={sessionLength.find((paidSession) => paidSession?.value === value)}
									isClearable={false} placeholder={"Session Length"}
									components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
									styles={{ 
										container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), 
										valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', }), 
										placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) 
									}} 
								/>
							)}
						/>
						<p className='error-text'>{errors?.sessionLength?.message}</p>
					</div>
					<button className="extraSessionModal__bottomSec__button">
						Confirm
					</button>
				</form>
			    </ExtraSessionModal>
            }
        </>
    )
}

const mapStateToProps = ({ commonData, advisorData }) => {
    const { message, errorList } = commonData;
    const { currentSubscribers, loadCurrentSubscribers, pastSubscribers, loadPastSubscribers, offerExtraSessionStatus } = advisorData;
    return { message, errorList, currentSubscribers, loadCurrentSubscribers, pastSubscribers, loadPastSubscribers, offerExtraSessionStatus };
};
export default connect(mapStateToProps, { getCurrentSubscribers, setCurrentSubscribersSuccessStatus, getPastSubscribers, setPastSubscribersSuccessStatus, offerExtraSession, setOfferExtraSessionStatus })(Index);
