
// Common Labels

export const FIRST_NAME_LABEL = 'First Name';
export const LAST_NAME_LABEL = 'Last Name';
export const EMAIL_LABEL = "Email"
export const COUNTRY_NAME_LABEL = 'Country';
export const ZIP_CODE_LABEL = 'Zip Code';
export const GENDER_LABEL = 'Select Gender';
export const STATUS_LABEL = 'Select Status';
export const High_SCHOOL_LABEL = 'High School Name';
export const GRADUATION_YEAR_LABEL = 'Graduation Year';
export const GPA_LABEL = 'Current GPA';
export const WEIGHTED_GPA_LABEL = 'Weighted GPA';
export const UnWEIGHTED_GPA_LABEL = 'Unweighted GPA';

export const START_DATE = 'Start Date';
export const END_DATE = 'End Date';

    // Goals Page
    export const SPECIFIC_UNIVERSITIES = "Get into specific universitiess"
    export const SPECIFIC_PROFESSION = "Get into a specific profession"
    export const IMPROVE_GPA = "Improve my GPA"
    export const DESIRED_UNIVERSITY_GPA = "Desired University"
    export const DESIRED_FINAL_GPA = "Desired final GPA"
    export const PROFESSIONS_LABEL = "Professions"
    
    // Price Setting
    export const PACKAGE_NAME_LABEL = "Package Name"
    export const ONE_TIME_SERVICE_LABEL = "One time service"
    export const MONTHLY_SERVICE_LABEL = "Monthly"
    export const DENOMINATION_LABEL = "$"
    export const RADIO_1_LABEL = 1
    export const RADIO_2_LABEL = 2
    export const RADIO_3_LABEL = 3
    export const DESCRIBE_ANOTHER_TYPE_LABEL = "Describe another type"



// auth

export const PASSWORD_LABEL = "Password"
export const NEW_PASSWORD_LABEL = "New password"
export const CONFIRM_PASSWORD_LABEL = "Confirm Password"
export const CURRENT_PASSWORD_LABEL = "Current password"
export const CREATE_NEW_PASSWORD_LABEL = "Create new password"


// Student

export const INTERESTED_IN_LABEL = 'Interested in';
export const STUDENT_INVITE_LABEL = 'Email';
export const Plan_Name = 'Plan Name';
export const MILESTONE_TYPE = 'Milestone Type';
export const ASSIGN_EXISTING_ADVISOR = 'Invite your current advisor';
// Advisor

export const MEET_URL = "Meeting Url"
export const MEET_PASSCODE = "Meeting Passcode"
export const RADIO_HIGH_SCHOOL_LABEL = "High School Student"
export const RADIO_UNIVERSITY_LABEL = "University Student"
export const RADIO_PROFESSION_LABEL = "Professional Advisor"
export const MY_STATUS_LABEL = "My Status"
export const SAT_SCORE_LABEL = "SAT Score"
export const ACT_SCORE_LABEL = "ACT Score"
export const UNIVERSITY_GPA_LABEL = "University GPA"
export const COURES_NAME_LABEL = "Course Name"
export const COURES_TYPE_LABEL = "Course Type"
export const ACTIVITY_NAME_LABEL = "Activity Name"
export const ACTIVITY_TYPE_LABEL = "Activity Type"
export const START_TIME_LABEL = "Start Time"
export const END_TIME_LABEL = "End Time"
export const COMPANY_NAME_LABEL = "Company Name"
export const OPERATING_SINCE_LABEL = "Operating Since"
export const COMPANY_WEBSITE_LABEL = "Company Website"
export const UNIVERSITY_NAME_LABEL = "University Name"
export const TARGET_UNIVERSITY_LABEL = "Target Universities"
export const UNIVERSITY_ATTENDED_LABEL = "University Attended"
export const UNIVERSITY_GRADUATION_LABEL = "University Graduation Year"
export const ACCOMPLISHMENT_LABEL = "More about me"

    // Services
    
    export const SELECT_DATE_LABEL = "Select Date"
    export const SELECT_TIME_LABEL = "Select time"
    
    
    
    
// Parent

    // Child Profile
    
    export const CHILD_STATUS_LABEL = "My Child's Status"
    export const CHILD_INTERESTED_IN_LABEL = "Interested in"
    
    // Goals
    export const GOALS_GPA_LABEL = "GPA"
