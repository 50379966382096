import React, { useEffect, useState } from 'react'
import "./connected.less";
import { Grid } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import PillSection from "../../../PillSection"
import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import BookSessionPopup from "../../../BookSession/BookSessionModal";
import { clearMessage, getConnectedAdvisors, setConnectedAdvisorStatus, bookSession, bookPaidSession, bookSessionStatus, getConnectedSessionDetails, bookPaidSessionErrorStatus, bookPaidSessionStatus, disconnectUser, unSubscription, packageUnsubscribeStatus } from '../../../../../redux/actions'
import { connect } from "react-redux";
import { Tooltip } from '@mui/material';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ConfirmationPopup from "../../../ConfirmationPopup";
import { convertToUTCTimeformat, convertUTCTodateTimeZone, convertUtcToEventTime } from '../../../../../utils/CommonUtils';
import { useImageUrl } from '../../../../../utils/UseImageURL';

const Index = (props) => {
  const [TimeSession, setTimeSession] = useState([]);
  const [advisorid, setAdvisorid] = useState("");
  const [advisorName, setAdvisorName] = useState("");
  const [bookshow, setbookshow] = useState(false);
  const [packageData, setPackageData] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [unsubscribeData, setUnsubscribeData] = useState([]);
  const [advisorTime, setAdvisorTime] = useState();



  const advisorActivities = props?.data?.userInfo?.advisorProfile?.advisorActivities || [];
  const advisorCourses = props?.data?.userInfo?.advisorProfile?.advisorCourses || [];

  const combinedData = [...advisorActivities, ...advisorCourses];

  const mappedData = combinedData.map(item => {
    if (item.activityName) {
      return item.activityName;
    } else if (item.courseName) {
      return item.courseName;
    }
    return null;
  });

  useEffect(() => {
    if (props?.paidSessionBooked) {
      CloseBookSession()
      props?.bookPaidSessionStatus(false)
    }
    if (props?.bookPaidSessionError) {
      CloseBookSession()
      props?.bookPaidSessionErrorStatus(false)
    }
    if (props?.unsubscribeStatus) {
      UnSubscribePopupClose()
      props?.packageUnsubscribeStatus(false)
      if (props?.userProfile?.currentRole === "student") {
        props?.getConnectedAdvisors()
      }
    }
  }, [props?.paidSessionBooked, props?.bookPaidSessionError, props?.unsubscribeStatus])

  const OpenBookSession = (e, data, value) => {
    e.preventDefault()
    setTimeSession(data)
    setAdvisorid(data?.userInfo?.id)
    setAdvisorTime([data?.userInfo?.advisorProfile?.advisorWorkingHours])
    setAdvisorName({ label: data?.userInfo?.fullName, value: data?.userInfo?.id })
    setPackageData(value?.id)
    setbookshow(true)
  }

  const CloseBookSession = () => {
    setbookshow(false)
  }

  function convertTo24HourFormat(time12h) {
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes] = time.split(':');
    hours = parseInt(hours, 10);
    if (modifier === 'PM' && hours !== 12) {
      hours += 12;
    } else if (modifier === 'AM' && hours === 12) {
      hours = 0;
    }
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}:00`;
  }

  const SessionBook = (data, value) => {
    const subscriptionDetails = props?.connectedAdvisors?.find(advisor => advisor.userInfo.id === advisorid)?.subscriptiondetails?.find(subscription => subscription.packageId === data?.package?.value);
    const moment = require('moment');
    const SelectHours = value
    const selectedDate = moment(data.Selectdate, "YYYY-MM-DD").format("YYYY-MM-DD");
    const startTime = SelectHours.split(' - ')[0];
    let currentTime = selectedDate + ' ' + convertTo24HourFormat(startTime)
    let payload = {
      "advisorId": advisorid,
      "eventTime": currentTime,
      "eventDuration": subscriptionDetails?.packageInfo?.sessionLength ?? '30',
      "meetingLocation": data?.Location,
      "meetingDescription": data?.Description,
      "studentId": localStorage.getItem("parentViaChild"),
      "password": data?.password,
      "packageId": data?.package?.value
    }
    props?.bookPaidSession(payload)
  }

  const getScheduledTime = (userId, packageId) => {
    let eventDetails = props?.scheduledSessionDetails?.find(data => data?.userIds?.includes(userId) && data?.packageId === packageId);
    if (eventDetails) {
      let date = new Date(convertToUTCTimeformat(eventDetails?.eventTime).toLocaleString());
      let formattedDate = date?.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
      return `${formattedDate} at ${convertUtcToEventTime(eventDetails?.eventTime, props?.userProfile?.timezone)?.format('hh:mm A')}`
    } else {
      return null;
    }
  }
  const UnSubscribePackage = (data) => {
    let payload = {
      "advisorId": unsubscribeData?.advisorId,
      "studentId": props?.userProfile?.currentRole === "student" ? props?.userProfile?.id : localStorage.getItem("parentViaChild"),
      "paymentPackageId": unsubscribeData?.packageId
    }
    props?.unSubscription(payload)
    if (props?.userProfile?.currentRole === "student") {
      props?.getConnectedAdvisors()
    }
  }

  const UnSubscribePopup = (data) => {
    setUnsubscribeData(data)
    setShowConfirmModal(true)
  }

  const UnSubscribePopupClose = () => {
    setUnsubscribeData([])
    setShowConfirmModal(false)
  }

  let endDate = new Date(unsubscribeData?.subscriptionEndsAt);
  let formattedDate = `${endDate.getMonth() + 1}/${endDate.getDate()}/${endDate.getFullYear()}`;
  let message = `Your current subscription will end on ${formattedDate} and will not auto renew. Please confirm if you would like to proceed`;
  const imgUrl = useImageUrl(props?.data?.userInfo?.avatarPath)
  return (
    <>
      {(bookshow && advisorTime) &&
        <BookSessionPopup childTimezone={props?.childIdTimeZone} userTime={advisorTime} show={bookshow} selectedAdvisor={advisorName} UserData={props?.userProfile} advisorList={props?.connectedAdvisors} onHide={CloseBookSession} Time={TimeSession} HandleSumit={SessionBook} Package={packageData} />
      }
      {showConfirmModal &&
        <ConfirmationPopup show={showConfirmModal} handleSubmit={() => UnSubscribePackage()} message={message} btnText="confirm" onHide={() => UnSubscribePopupClose()} />
      }
      <div className='advisorCard '>
        <Grid container spacing={2} className='advisorCardTop'>
          <Grid item xs={3} >
            {props?.data?.userInfo?.avatarPath ? (
              <img className="profile-img"  src={imgUrl} alt="profile" />
            ) :
              <PersonIcon color="action" sx={{ border: '1px solid grey', borderRadius: "6px", fontSize: '1rem', "width": "60px", "height": "60px !important" }} className='advisorprofileimage' />
            }
          </Grid>
          <Grid item xs={5} className="owlTitle">
            <div className='d-flex'>
              <p className="profile-heading" style={{ "marginBottom": "2px", "fontSize": "16px", "fontWeight": "500", paddingLeft: '2px' }} >{props?.data?.userInfo?.fullName}</p>
              <Tooltip title={`Send Message`} placement="top">
                <span className='connectedAdvisor__topSec__userInfo__nameSec__iconBg'>
                  <ChatBubbleOutlineOutlinedIcon className='connectedAdvisor__topSec__userInfo__nameSec__iconBg__chatIcon' />
                </span>
              </Tooltip>
            </div>
            <p className="advisor-country" style={{ "marginBottom": "0px", paddingLeft: '2px' }} > {props?.data?.userInfo?.country}
            </p>
            <div className='pill-status' style={{ marginLeft: '2px' }}>{props?.data?.subscriptiondetails?.length > 0 ? "Subscribed" : "connected"}</div>
          </Grid>
          <Grid item xs={4} className="owlTitle">
            <p className="rating-heading" style={{ "marginBottom": "0px" }} >
              <StarBorderIcon sx={{ fontSize: '15px', fill: '#E64A19', }} />{props?.data?.userInfo?.advisorProfile?.advisorReviewSummary?.averageRating.toFixed(1) || 0}{"/5.0"}
            </p>
          </Grid>
        </Grid>
        <div className='advisorCard__Top'>
          <div className='advisorCard__Top__image'>
            {props?.data?.userInfo?.avatarUrl ? (
              <img className="profile-img" style={{ "width": "60px", "height": "60px", borderRadius: "6px" }}
                src={props?.data?.userInfo?.avatarUrl} alt="profile"
              />
            ) :
              <PersonIcon color="action" sx={{ border: '1px solid grey', borderRadius: "6px", fontSize: '1rem', "width": "60px", "height": "60px !important" }} className='advisorprofileimage' />
            }
          </div>
          <div className='advisorCard__Top__namecnt'>
            <div className='d-flex'>
              <p className="profile-heading" style={{ "marginBottom": "2px", "fontSize": "16px", "fontWeight": "500", paddingLeft: '2px' }} >{props?.data?.userInfo?.fullName}</p>
              <Tooltip title={`Send Message`} placement="top">
                <span className='connectedAdvisor__topSec__userInfo__nameSec__iconBg'>
                  <ChatBubbleOutlineOutlinedIcon className='connectedAdvisor__topSec__userInfo__nameSec__iconBg__chatIcon' />
                </span>
              </Tooltip>
            </div>
            <p className="advisor-country" style={{ "marginBottom": "0px", paddingLeft: '2px' }} > {props?.data?.userInfo?.country}
            </p>
            <div className='pill-status' style={{ marginLeft: '2px' }}>{props?.data?.subscriptiondetails?.length > 0 ? "Subscribed" : "connected"}</div>
          </div>
          <div className='advisorCard__Top__ratingcnt'>
            <p className="rating-heading" style={{ "marginBottom": "0px" }} >
              <StarBorderIcon sx={{ fontSize: '15px', fill: '#E64A19', }} />{props?.data?.userInfo?.advisorProfile?.advisorReviewSummary?.averageRating.toFixed(1) || 0}{"/5.0"}
            </p>
          </div>
        </div>
        <Grid className='mt-2'>
          <div style={{ "height": "40px", "overflow": "hidden" }}>
            {props?.data?.userInfo?.advisorProfile?.currentlyIn === "highschool" && (
              <p className='advisor-subText advisor-subText-coll'>  <HomeOutlinedIcon sx={{ fontSize: '14px', fill: "#212224" }} /> Attending  {props?.data?.userInfo?.advisorProfile?.highschoolName}</p>
            )}
            {props?.data?.userInfo?.advisorProfile?.currentlyIn === "university" && (
              <p className='advisor-subText advisor-subText-coll'>  <SchoolOutlinedIcon sx={{ fontSize: '14px', fill: "#212224" }} /> Attending  {props?.data?.userInfo?.advisorProfile?.universityName}</p>
            )}
            {props?.data?.userInfo?.advisorProfile?.currentlyIn === "professional" && (
              <p className='advisor-subText advisor-subText-coll'>  <SchoolOutlinedIcon sx={{ fontSize: '14px', fill: "#212224" }} /> Went to  {props?.data?.userInfo?.advisorProfile?.universityName}</p>
            )}
          </div>
        </Grid>
        <Grid className='mt-3 '>
          <p className="profile-heading" style={{ "marginBottom": "10px", "fontSize": "16px", "fontWeight": "500" }} >{"Services offered"}</p>
          <div className='advisor-card-content'>
            {props?.data?.userInfo?.advisorProfile?.helpWithPlan === true && (<p className='advisor-subText'>  <StarRoundedIcon sx={{ fill: "#1C84EE", fontSize: '17px', }} />Create plan based on goals</p>)}
            {props?.data?.userInfo?.advisorProfile?.helpWithPlan === true && (<p className='advisor-subText'>  <StarRoundedIcon sx={{ fill: "#1C84EE", fontSize: '17px' }} />Review existing plan based on goals</p>)}
            {props?.data?.userInfo?.advisorProfile?.helpWithSpecificCourseActivity === true && (<p className='advisor-subText'>  <StarRoundedIcon sx={{ fill: "#1C84EE", fontSize: '17px' }} /> Mentoring for</p>)}
            <PillSection data={mappedData} maxWidth={193} maxHeight={80} />
          </div>
        </Grid>
        <Grid className='mt-3' >
          {props?.data?.subscriptiondetails?.length > 0 && (
            <div style={{ height: 'fitcontent' }}>
              {props?.data?.subscriptiondetails?.map((data) => {
                const formattedDate = convertUTCTodateTimeZone(data?.subscribedAt,props?.userProfile?.timezone).format('MM/DD/YY');
                const formattedExpiry = convertUTCTodateTimeZone(data?.subscriptionEndsAt,props?.userProfile?.timezone).format('MM/DD/YY');
                const matchingPackage = props?.data?.advisorFeePackages?.find(packageItem => packageItem?.id === data?.packageId);
                return (
                  <div className='advisor-card-content' key={data?.id} >
                    <div className='packageBlock'>
                      <div><DiamondOutlinedIcon className='diamondicon' /><span className='packageSessionTitle'>{data?.packageInfo?.packageName}</span></div>
                      <p className='packageSessionTitle mb-0' style={{ marginLeft: 'auto' }}>{data?.subscriptionType}</p>
                    </div>
                    <div className='packageBlock' style={{ gap: '22px' }}>
                      <p className='purchasedContent'>Purchased on {formattedDate}</p>
                      {data?.subscriptionType === "monthly" ? (
                        data?.isSubscriptionActive ? (
                          <p className='purchasedContent'>Renews on {formattedExpiry}</p>
                        ) : (
                          <p className='purchasedContent'>Ends on {formattedExpiry}</p>
                        )
                      ) : (
                        <p className='purchasedContent'>Expired on {formattedExpiry}</p>
                      )
                      }
                    </div>
                    <div className='packageBlock'>
                      <p className='sessionDetails'><span className='packageCount'>{data?.noOfPaidSessions}</span> sessions remaining</p>
                      <p className='sessionDetails'><span className='packageCount'>250</span> outgoing messages remaining</p>
                    </div>
                    {getScheduledTime(data?.userInfo?.id ? data?.userInfo?.id : data?.advisorId, data?.packageId) ? (
                      <span className="meetTimeZone">
                        <CalendarTodayOutlinedIcon sx={{ fontSize: '0.7rem' }} /> You are scheduled to meet on {getScheduledTime(data?.userInfo?.id ? data?.userInfo?.id : data?.userId, data?.packageId)}
                      </span>
                    ) : ''}
                    {data?.noOfPaidSessions !== 0 && data?.packageExpired === false && 
                      <div style={{ justifyContent: 'center', display: 'flex' }}>
                        <button className="netWork-card__button-sec__primes-btn" disabled={data?.noOfPaidSessions === 0} onClick={(e) => OpenBookSession(e, props?.data, data?.packageInfo)} style={{ "width": "90%" }} >
                          Book Session {Math.abs((data?.availablePaidSessions - data?.noOfPaidSessions) + 1)
                          } of {data?.availablePaidSessions}
                        </button>
                      </div>
                    }
                    {data?.subscriptionType === "monthly" && (
                      data?.isSubscriptionActive && (
                        <div style={{ justifyContent: 'center', display: 'flex' }}>
                          <button className="netWork-card__button-sec__secondary-btn mt-2" onClick={(e) => UnSubscribePopup(data)} style={{ "width": "90%", "backgroundColor": "#ffffff" }}>
                            Unsubscribe
                          </button>
                        </div>
                      )
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </Grid>
        <Grid style={{ marginTop: 'auto' }}>
          {props?.children}
        </Grid>
      </div>
    </>
  )
}
const mapStateToProps = ({ commonData, userProfileData }) => {
  const { message, errorList, connectedAdvisors, connectedAdvisorsStatus, paidSessionBooked, bookPaidSessionError, scheduledSessionDetails, sessionBooked, booksessionerror, unsubscribeStatus } = commonData;
  const { userProfile } = userProfileData
  return { message, errorList, connectedAdvisors, connectedAdvisorsStatus, paidSessionBooked, bookPaidSessionError, scheduledSessionDetails, sessionBooked, booksessionerror, unsubscribeStatus, userProfile };
};
export default connect(mapStateToProps, { clearMessage, getConnectedAdvisors, getConnectedSessionDetails, setConnectedAdvisorStatus, bookSession, bookPaidSession, bookSessionStatus, bookPaidSessionErrorStatus, bookPaidSessionStatus, disconnectUser, unSubscription, packageUnsubscribeStatus })(Index);
