import {
  INIT_URL, SIGNOUT_USER_SUCCESS,
  USER_TOKEN_SET, SIGNUP_USER_SUCCESS, EMAIL_VERIFICATION_SUCCESS, 
  USER_PROFILEL_SAVED, EMAIL_VERIFIED,LOGIN_SUCESS,
  NEW_REGISTER, NEWPASSWORD_RESET, VERIFY_PASSWORD, VERIFY_PASSWORD_STATUS, UPDATE_PASSWORD, UPDATE_PASSWORD_STATUS ,DELETE_USER, RESEND_EMAIL_VERIFICATION_SUCCESS
  ,REINVITE_EMAIL_VERIFICATION_SUCCESS, SHARED_USER_DATA, LOAD_PUBLIC_PROFILE_SUCCESS} from "../constants/AuthActionTypes";
import { isUserAuthenticated } from '../../utils/AuthUtils'

const INIT_STATE = {
  token: isUserAuthenticated(),
  verificationKey: '',
  initURL: '',
  islogedin: false,
  isRegistered: false,
  isEmailVerified: false,
  isResendEmailSuccess: false,
  isEmailConfirm: true,
  isNewRegister: false,
  isNewPassword: false,
  isverifyPassword:false,
  isverifyPasswordstatus:true,
  isUpdatePassword: false,
  isUpdatePasswordstatus:true,
  isUserdeleted: false,
  issociallogin: false,
  isReInvite: false,
  sharedData : {},
  loadPublicProfileSuccess: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    
	case INIT_URL: {
      return { ...state, initURL: action.payload };
    }
    
	case USER_TOKEN_SET: {
      return {
        ...state,
        token: action.payload,
        islogedin: true
      };
    }
    case LOGIN_SUCESS:{
      return {
        ...state,
        issociallogin: true
      }
    }
    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        isRegistered: true,
      };
    }

    case EMAIL_VERIFICATION_SUCCESS: {
      return {
        ...state,
        isEmailVerified: true,
      };
    }
    case RESEND_EMAIL_VERIFICATION_SUCCESS: {
      return {
        ...state,
        isResendEmailSuccess: action?.payload,
      };
    }
    case NEW_REGISTER:{
      return {
        ...state,
        isNewRegister: action.payload,
      };
    }
    case EMAIL_VERIFIED:{
      return {
        ...state,
        isEmailConfirm: action.payload,
      };
    }

	case SIGNOUT_USER_SUCCESS: {
		return {
		  ...state,
		  token: null,
		  initURL: ''
		}
	  }
    case NEWPASSWORD_RESET:{
		return {...state, isNewPassword: true,};
	}

	case VERIFY_PASSWORD:{
		return {...state, isverifyPassword: true,};
	}

	case VERIFY_PASSWORD_STATUS:{
		return {...state, isverifyPasswordstatus: action.payload,};
	}

	case UPDATE_PASSWORD:{
		return {...state, isUpdatePassword: true,};
	}

	case UPDATE_PASSWORD_STATUS:{
		return {...state, isUpdatePasswordstatus: action.payload,};
	}

  case DELETE_USER:{
		return {...state, isUserdeleted: action.payload,};
	}
  case REINVITE_EMAIL_VERIFICATION_SUCCESS: {
    return {
      ...state,
      isReInvite: action?.payload,
    };
  }
  case SHARED_USER_DATA : {
    return {
      ...state,
      sharedData: action?.payload,
    };
  }
  case LOAD_PUBLIC_PROFILE_SUCCESS : {
    return {
      ...state,
      loadPublicProfileSuccess: action?.payload,
    };
  }

    default:
      return state;
  }
}
