import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import PersonIcon from '@mui/icons-material/Person';
import { useImageUrl } from '../../../../../utils/UseImageURL';

const Index = (props) => {
    const handleClose = () => {
        props?.onHide()
    };
    const imgUrl = useImageUrl(props?.data?.requestReceivedUser?.avatarPath)
    return (
        <>
            <Modal size="md" {...props} dialogClassName="modal-90w" aria-labelledby="contained-modal-title-vcenter" centered >
                <Modal.Header closeButton onClick={handleClose} >
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='modalContent'>
                    <div>
                        <div style={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }}>
                            {props?.data?.requestReceivedUser?.avatarPath ? (
                                <img src={imgUrl} alt="userprofile" style={{"width":"100px","height":"100px",border: '1px solid #e3e3e3',"borderRadius":"10px"}} />
                            ) :
                                < PersonIcon color="action" sx={{ border: '1px solid grey', borderRadius: "6px", fontSize: '1rem', "width": "65px", "height": "65px !important" }} />
                            }
                        </div>
                        <div className='mt-3' style={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }}>
                            <p className='pB' style={{"fontSize":"20px"}}>{props?.data?.requestReceivedUser?.fullName}</p>
                        </div>
                        {props?.data?.requestInfo?.rejectedReason ? (
                            <div className='mb-4' style={{ "display": "flex", "justifyContent": "center", "alignItems": "center","wordBreak":"break-all" }}>
                                <p className='pR' style={{"fontSize":"16px"}} >{props?.data?.requestInfo?.rejectedReason}</p>
                            </div>
                        ) :
                            <div className='mb-4' style={{ "display": "flex", "justifyContent": "center", "alignItems": "center" }}>
                                <p className='pR' style={{"fontSize":"16px"}}>{props?.data?.requestReceivedUser?.fullName} rejected the Request</p>
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Index
