import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { clearMessage, getChildProfile, bookSession, bookPaidSession, bookSessionStatus, GetStudentPlan, GetStudentPlanStatus, getAdvisorCountUpdateStatus, bookPaidSessionErrorStatus, getUserCount, bookPaidSessionStatus, getGoalsFromStudent, setIsParentGoalSuccess, loadAdvisorReview, manageProfileServices, loadAdvisorReviewStatus, inviteStudentErrorStatus } from '../../../redux/actions'
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import CarouselComponent from './CarouselComponent';
import PillSection from "../../../componentsNew/Common/PillSection"
import SelectableTags from "../../../componentsNew/Common/dashboardSelectableTags"
import { Button } from "react-bootstrap";
import "./dashboard.less";
import ScheduleListCard from "../../../componentsNew/Dashboard/ScheduleListCard";
import ScheduleCard from "../../../componentsNew/Dashboard/SessionRemainingCard";
import { useNavigate } from "react-router-dom";
// import ConnectedGoals from "../ParentProfile/connectedGoalsPopup";
import { GetCurrentTimezone } from "../../../utils/CommonUtils";
import InviteModal from "../../../componentsNew/Common/InviteModal";
import dayjs from "dayjs";
import { Card, Rating, Stack, Tooltip } from '@mui/material';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SearchIcon from '@mui/icons-material/Search';
import PeopleIcon from '@mui/icons-material/People';
import MoneyStatsCard from "../../../componentsNew/Dashboard/MoneyStatsCard"
import ReviewPopup from "../../../componentsNew/Common/ReviewPopup/index"
import CustomAlert from "../../../components/CustomAlert";
const Index = (props) => {
    const [advisorCounts, setAdvisorCount] = useState([]);
    const [advisorReview, setReview] = useState([])
    const [exploreCountForStudent, setExploreStudentCount] = useState([])
    const [openAdvisorInvitePopUp, setOpenAdvisorInvitePopUp] = useState(false)
    const [editData, seteditData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [addOnopen, setAddOnOpen] = useState(false);
    const [inviteType, setInviteType] = useState("");
    const [openReviewPopUp, setOpenReviewPopUp] = useState(false);

    const navigate = useNavigate();

    const handleAddOnOpen = (data) => {
        let getStudentId = {
            "studentId": localStorage?.getItem("parentViaChild")
        }
        props.getGoalsFromStudent(getStudentId)
        seteditData(data)
        setAddOnOpen(true);
        navigate('/advisor/myprofile', { state: { type: "coursesAndActivities" } });

    }
    const handleAddOnOpenCareer = (data) => {
        let getStudentId = {
            "studentId": localStorage?.getItem("parentViaChild")
        }
        props.getGoalsFromStudent(getStudentId)
        seteditData(data)
        setAddOnOpen(true);
        navigate('/advisor/myprofile', { state: { type: "currentStatus" } });

    }
    const handleAddOnClose = () => setAddOnOpen(false);

    const aspiringBecome = props.userProfile?.advisorProfile?.aspiringToBecome || [];
    const targetMajor = props.userProfile?.advisorProfile?.targetMajor || [];
    const targetUniversities = props.userProfile?.advisorProfile?.targetUniversities || [];

    const combinedArray = [
        ...(typeof aspiringBecome === "string" ? aspiringBecome.split(',').map(major => major.trim()) : aspiringBecome || []),

        ...(typeof targetMajor === "string" ? targetMajor.split(',').map(major => major.trim()) : targetMajor), // Split and trim
    ];

    const currentActivities = props.userProfile?.advisorProfile?.advisorActivities || [];
    const currentCourses = props.userProfile?.advisorProfile?.advisorCourses || [];

    const mappedCourseData = props.userProfile
        ? currentCourses
            ?.filter(item => item?.courseName)
            .map(item => item.courseName)
        : [];


    const mappedActivityData = currentActivities.map(item => {
        if (item?.activityName) {
            return item?.activityName;
        }
        return null;
    });



    const handleNavigateToSchedule = () => {
        navigate('/advisor/schedule');
    }

    console.log(props?.advisorDashboardCount, "props?.advisorDashboardCount")
    useEffect(() => {

        if (props?.advisorDashboardCount) {
            let advisorCountData = [
                ...(Array.isArray(props?.advisorDashboardCount?.receivedRequests)
                    ? props?.advisorDashboardCount?.receivedRequests.map((request, index) => {
                        return {
                            type: request?.type,
                            count: request?.count,
                            id: `request-${index}`
                        };
                    })
                    : []),
                ...(Array.isArray(props?.advisorDashboardCount?.milestoneUpdates)
                    ? props?.advisorDashboardCount?.milestoneUpdates.map(update => {
                        console.log(update, "Milestone Updatadsde");
                        return {
                            count: update?.totalUpdates,
                            type: "milestone updates",
                            id: update?._id,
                            milestone: update?.milestones
                        };
                    })
                    : []),
                ...(Array.isArray(props?.advisorDashboardCount?.courseUpdates)
                    ? props?.advisorDashboardCount?.courseUpdates.map(update => {
                        console.log(update, "Milestone Update");
                        return {
                            count: update?.totalUpdates,
                            type: "course updates",
                            id: update?._id,
                            courses: update?.courses
                        };
                    })
                    : []),
                ...(Array.isArray(props?.advisorDashboardCount?.activityUpdates)
                    ? props?.advisorDashboardCount?.activityUpdates.map(update => {
                        return {
                            count: update?.totalUpdates,
                            type: "activity updates",
                            id: update?._id,
                            activity: update?.activities
                        };
                    })
                    : []),
            ];
            setAdvisorCount(advisorCountData)
            props?.getAdvisorCountUpdateStatus(false)
        }


        if (props.dashboardGoalsCount?.student) {
            let studentExploreData = [
                ...(Array.isArray(props.dashboardGoalsCount?.student?.universities)
                    ? props.dashboardGoalsCount?.student?.universities?.map((universities, index) => {
                        console.log(universities, "dfhbdhfb")
                        return {
                            count: universities?.count,
                            id: index,
                            goalName : universities?.goalName,
                            description: "Targeting your university"
                        };
                    })
                    : []),
                ...(Array.isArray(props.dashboardGoalsCount?.student?.professions)
                    ? props.dashboardGoalsCount?.student?.professions?.map((professions, index) => {
                        return {
                            count: professions?.count,
                            id: index,
                            goalName : professions?.goalName,
                            description: "Want to be  " + professions?.goalName
                        };
                    })
                    : []),
                ...(Array.isArray(props.dashboardGoalsCount?.student?.courses)
                    ? props.dashboardGoalsCount?.student?.courses?.map((courses, index) => {
                        return {
                            count: courses?.count,
                            id: index,
                            goalName : courses?.goalName,
                            description: "Need help with " + courses?.goalName
                        };
                    })
                    : []),
                ...(Array.isArray(props.dashboardGoalsCount?.student?.activities)
                    ? props.dashboardGoalsCount?.student?.activities?.map((activities, index) => {
                        return {
                            count: activities?.count,
                            id: index,
                            goalName : activities?.goalName,
                            description: "Need help with " + activities?.goalName
                        };
                    })
                    : []),

            ];
            setExploreStudentCount(studentExploreData)
        }

    }, [props?.ismilestoneCountDataStatus, props?.connectedAdvisors, props?.exploreAdvisorCount, props?.advisorDashboardCount])

    useEffect(() => {
        if (props?.userProfile?.id) {
            let reviewPayload = {
                advisorId: props?.userProfile?.id,
                "limit": 10,
                "pageNo": 1,

            }

            props?.loadAdvisorReview(reviewPayload)
            setReview([])
        }

    }, [props?.userProfile?.id])


    useEffect(() => {
        if (props?.studentplanstatus) {
            if (props?.getstudentplan?.length === 1) {
                console.log("props?.getstudentplan", props?.getstudentplan);
                let PlanId = props?.getstudentplan[0]?.planInfo?.id
                console.log("PlanId", PlanId);
                if (PlanId) {
                    navigate('/parent/mywork/plan/' + PlanId);
                }
            } else {
                navigate('/parent/mywork');
            }
            props?.GetStudentPlanStatus(false)
        }
        if (props?.reviewDataStatus) {

            props?.loadAdvisorReviewStatus(false)
        }
        console.log(advisorReview, "sdhasdjkh")

    }, [props?.studentplanstatus, props?.getstudentplan, props?.reviewDataStatus, props?.reviewData])
    console.log(props?.scheduledEvents?.eventStatus, "props?.scheduledEvents");

    const filteredData = props?.scheduledEvents?.filter((schedule) => {
        console.log(schedule?.eventStatus, "scheduleschedule");

        const currentDate = GetCurrentTimezone(props?.userProfile?.timezone);
        const eventStartTime = dayjs(schedule.eventTime); // Convert the eventTime to a dayjs object
        const isPastEvent = currentDate > eventStartTime.format('YYYY-MM-DDTHH:mm:ss');

        return !isPastEvent && schedule?.eventStatus !== "cancelled";
    });


    useEffect(() => {
        if (props?.isParentGoalSuccess === true) {
            let payload = {
                "studentId": localStorage.getItem("parentViaChild")

            }
            props?.getGoalsFromStudent(payload)
            props.setIsParentGoalSuccess(false)
            handleAddOnClose()
        }

    }, [props?.isParentGoalSuccess, !props?.createGoal, props?.inviteStudentError]);

    const handleInviteAdvisor = (type) => {
        setInviteType(type)
        setOpenAdvisorInvitePopUp(true)
    }

    const handleShow = () => {
        setInviteType("")
        setOpenAdvisorInvitePopUp(false);

    }

    const handleReviewPopUp = () => {
        setOpenReviewPopUp(true);
    }
    const handleCloseReview = () => {
        setOpenReviewPopUp(false);
    }

    const averageRating = props?.userProfile?.advisorProfile?.advisorReviewSummary?.averageRating || 0;
    const totalNoofReviews = props?.userProfile?.advisorProfile?.advisorReviewSummary?.totalNoofReviews || 0;

    console.log(props?.userProfile?.advisorProfile, "pricingData")

    const areAllTimesheetsEmpty = (data) => {
        // Return true if data is null or undefined to treat it as "empty"
        if (!data || typeof data !== 'object') return true;

        return Object.values(data).every(day => day.length === 0);
    };

    // Usage
    const allEmpty = areAllTimesheetsEmpty(props?.userProfile?.advisorProfile?.advisorWorkingHours);

    const convertTimeFormat = (timeStr) => {
        let parts = timeStr.split(':');
        let newTime = parts.slice(0, 2).join(':');
        return newTime;
    }

    const handleTurnOnServices = () => {
        let { mondayTimesheet, tuesdayTimesheet, wednesdayTimesheet, thursdayTimesheet, fridayTimesheet, saturdayTimesheet, sundayTimesheet } = props?.userProfile?.advisorProfile?.advisorWorkingHours;

        let payload = {
            isServicesEnabled: !props?.userProfile?.advisorProfile?.isServicesEnabled
        }
        payload.mondayAvailableTimes = mondayTimesheet?.map(date => { return { startTime: convertTimeFormat(date?.startingTime), endTime: convertTimeFormat(date?.closingTime) } })
        payload.tuesdayAvailableTimes = tuesdayTimesheet?.map(date => { return { startTime: convertTimeFormat(date?.startingTime), endTime: convertTimeFormat(date?.closingTime) } })
        payload.wednesdayAvailableTimes = wednesdayTimesheet?.map(date => { return { startTime: convertTimeFormat(date?.startingTime), endTime: convertTimeFormat(date?.closingTime) } })
        payload.thursdayAvailableTimes = thursdayTimesheet?.map(date => { return { startTime: convertTimeFormat(date?.startingTime), endTime: convertTimeFormat(date?.closingTime) } })
        payload.fridayAvailableTimes = fridayTimesheet?.map(date => { return { startTime: convertTimeFormat(date?.startingTime), endTime: convertTimeFormat(date?.closingTime) } })
        payload.saturdayAvailableTimes = saturdayTimesheet?.map(date => { return { startTime: convertTimeFormat(date?.startingTime), endTime: convertTimeFormat(date?.closingTime) } })
        payload.sundayAvailableTimes = sundayTimesheet?.map(date => { return { startTime: convertTimeFormat(date?.startingTime), endTime: convertTimeFormat(date?.closingTime) } })

        props?.manageProfileServices(payload);
    }

    let university = Array.isArray(targetUniversities)
        ? targetUniversities.flatMap(item => item.split(",").map(name => [name.trim()]))
        : targetUniversities.split(",").map(name => [name.trim()]);

    console.log(props?.getAdvisorCurrentState?.state, "getAdvisorCurrentState")

    const handleNavigateToProfile = () => {
        navigate('/advisor/myprofile',{ state: { type: "pricingPackages" } })
    }

    return (
        <>
            {/* {addOnopen && <ConnectedGoals errorList={props?.errorList} dashboard="true" openloader={loading} handleLoaderFalse={(data) => handleSetLoad(data)} show={addOnopen} data={editData} handleClose={handleAddOnClose} graduationyear={props?.graduationyear} status={props?.status} gender={props?.gender} countryOption={props?.countryOption} />} */}
            {openAdvisorInvitePopUp &&
                <InviteModal type={inviteType} openAdvisorInvitePopUp={openAdvisorInvitePopUp} handleShow={handleShow} />
            }
            {openReviewPopUp &&
                <ReviewPopup openReviewPopUp={openReviewPopUp} reviewData={props?.advisorReview} handleCloseReview={handleCloseReview} />
            }
            {!props?.inviteStudentError && <div style={{ "width": "100%", "display": "flex", "justifyContent": "center", }}> {<CustomAlert />}</div>}

            <div className="parentDashboard">
                <div className="studentDashboard_Mobile">
                    <div className="studentDashboard_Mobile_Section">
                        <div className="studentDashboard_Mobile_Section_Title">
                        </div>
                    </div>
                </div>
                <div className="top-sec" >
                    <div className="dashboard-top-text SECTIONA">
                        <h2 className="welcomeUser">Welcome {props.userProfile && props.userProfile.firstName}! </h2>
                        <p className="mb-4 dash-subText"><span>Your next step👇  </span></p>

                    </div>

                    <div className="star-review">
                        <h5>You are rated {props?.userProfile?.advisorProfile?.advisorReviewSummary?.averageRating}</h5>
                        <div className="ratingCont">
                            <Stack spacing={1} >
                                <Rating value={averageRating} precision={0.5} readOnly name="half-rating-read" />
                            </Stack>
                            <h6
                                style={{ cursor: totalNoofReviews > 0 ? 'pointer' : 'default' }}
                                onClick={() => totalNoofReviews > 0 && handleReviewPopUp()}
                            >
                                {totalNoofReviews} reviews
                            </h6>

                        </div>
                    </div>
                </div>

                <div className="SECTIONB">
                    {advisorCounts?.length > 0 && <h6 className="dashTitle mb-2">Build your network and follow updates  🕸️</h6>}

                    <div>
                        {advisorCounts?.length > 0 && <div> <CarouselComponent data={advisorCounts} /> </div>}
                    </div>
                    {exploreCountForStudent?.length > 0 &&
                        (
                            <div className="mt-3">
                                <div className="dashSubContainer">
                                    <h6 className="dashTitle" style={{ textAlign: 'left' }}>
                                        Connect with students who need your help 🎓
                                    </h6>
                                    {/* Invite a Friend Button */}
                                    <Button
                                        className="btn cta--rounded cta-primary mb-1 mt-2"
                                        style={{ borderRadius: '10px', marginRight: '1rem' }} onClick={() => handleInviteAdvisor("friend")}
                                    >
                                        <span className="buttonText">Invite a Student</span>
                                        <Tooltip title="Invite a Friend" placement="top">
                                            <span className="buttonIcon">
                                                <GroupAddIcon />
                                            </span>
                                        </Tooltip>
                                    </Button>


                                    {/* Your Friends Button */}
                                    <Button
                                        className="btn cta--rounded cta-primary mb-1 mt-2"
                                        style={{ borderRadius: '10px', marginRight: '1rem' }}
                                        onClick={() => navigate('/advisor/mynetwork', { state: { activeTab: 1 } })}
                                    >
                                        <span className="buttonText">Your Students</span>
                                        <Tooltip title="Your Friends" placement="top">
                                            <span className="buttonIcon">
                                                <PeopleIcon />
                                            </span>
                                        </Tooltip>
                                    </Button>

                                    {/* Explore Students Button */}
                                    <Button
                                        className="btn cta--rounded cta-primary mb-1 mt-2"
                                        style={{ borderRadius: '10px' }}
                                        onClick={() => navigate('/advisor/mynetwork/exploreStudents')}
                                    >
                                        <span className="buttonText">Explore Students</span>
                                        <span className="buttonIcon">
                                            <Tooltip title="Explore Students" placement="top">
                                                <SearchIcon />

                                            </Tooltip>
                                        </span>
                                    </Button>
                                </div>

                                <CarouselComponent data={exploreCountForStudent} exploreNavigate={"advisorProfile"} />
                            </div>
                        )
                    }
                    <div>
                        {(mappedCourseData?.length > 0 || mappedActivityData?.length > 0) && (
                            <h6 className="dashTitle mb-2">
                                Update your services! Helps us recommend the right students for you to work with 🎓
                            </h6>
                        )}

                        <div class="row g-4" >

                            {mappedCourseData?.length > 0 ? <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                <Card variant="outlined" sx={{ borderRadius: "10px" ,height:"10rem"}}>
                                    <div className="dashSubContainer" >
                                        <h6 className="dashTitle" style={{ paddingLeft: '15px' }}>
                                            What courses can you help with ?
                                        </h6>
                                        <Button className="btn cta--rounded cta-primary mt-1" onClick={() => handleAddOnOpen(props?.parentGoal)} style={{ borderRadius: '10px', marginRight: '1rem' }}>
                                            <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>Update
                                                <svg width="18" height="20" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.08058 12.4194C3.95466 13.2935 5.0683 13.8888 6.28069 14.1299C7.49307 14.3711 8.74973 14.2473 9.89177 13.7742C11.0338 13.3012 12.0099 12.5001 12.6967 11.4723C13.3834 10.4445 13.75 9.23613 13.75 8C13.75 6.76387 13.3834 5.55549 12.6967 4.52769C12.0099 3.49988 11.0338 2.6988 9.89177 2.22575C8.74973 1.75271 7.49307 1.62893 6.28069 1.87009C5.0683 2.11125 3.95466 2.7065 3.08058 3.58058" stroke="white" stroke-width="2" />
                                                    <path d="M9.375 8L10.1559 7.3753L10.6556 8L10.1559 8.6247L9.375 8ZM1.875 9C1.32271 9 0.875 8.55228 0.875 8C0.875 7.44772 1.32271 7 1.875 7V9ZM7.65587 4.2503L10.1559 7.3753L8.59413 8.6247L6.09413 5.4997L7.65587 4.2503ZM10.1559 8.6247L7.65587 11.7497L6.09413 10.5003L8.59413 7.3753L10.1559 8.6247ZM9.375 9H1.875V7H9.375V9Z" fill="white" />
                                                </svg>
                                            </span>
                                        </Button>
                                    </div>
                                    <div className="p-3">
                                        <PillSection data={mappedCourseData} maxWidth={400} maxHeight={75} />
                                    </div>
                                </Card>
                            </div> : <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                <Card variant="outlined" sx={{ borderRadius: "10px" }}>
                                    <div className="dashSubContainer" >
                                        <h6 className="dashTitle" style={{ paddingLeft: '15px' }}>
                                            What courses can you help with ?
                                        </h6>
                                        <Button className="btn cta--rounded cta-primary mt-1" onClick={() => handleAddOnOpen(props?.parentGoal)} style={{ borderRadius: '10px', marginRight: '1rem' }}>
                                            <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>Update
                                                <svg width="18" height="20" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.08058 12.4194C3.95466 13.2935 5.0683 13.8888 6.28069 14.1299C7.49307 14.3711 8.74973 14.2473 9.89177 13.7742C11.0338 13.3012 12.0099 12.5001 12.6967 11.4723C13.3834 10.4445 13.75 9.23613 13.75 8C13.75 6.76387 13.3834 5.55549 12.6967 4.52769C12.0099 3.49988 11.0338 2.6988 9.89177 2.22575C8.74973 1.75271 7.49307 1.62893 6.28069 1.87009C5.0683 2.11125 3.95466 2.7065 3.08058 3.58058" stroke="white" stroke-width="2" />
                                                    <path d="M9.375 8L10.1559 7.3753L10.6556 8L10.1559 8.6247L9.375 8ZM1.875 9C1.32271 9 0.875 8.55228 0.875 8C0.875 7.44772 1.32271 7 1.875 7V9ZM7.65587 4.2503L10.1559 7.3753L8.59413 8.6247L6.09413 5.4997L7.65587 4.2503ZM10.1559 8.6247L7.65587 11.7497L6.09413 10.5003L8.59413 7.3753L10.1559 8.6247ZM9.375 9H1.875V7H9.375V9Z" fill="white" />
                                                </svg>
                                            </span>
                                        </Button>
                                    </div>
                                    <div className="p-3 zeroStateFont" style={{ textAlign: 'center' }}>
                                        <p> Add courses you can help students with homework, test prep etc</p>
                                    </div>
                                </Card>
                            </div>}

                            {mappedActivityData?.length > 0 ? <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                <Card variant="outlined" sx={{ borderRadius: "10px",height:"10rem" }}>
                                    <div className="dashSubContainer" >
                                        <h6 className="dashTitle advisorActivityTitle" style={{ paddingLeft: '15px', paddingRight: '3px' }}>
                                            What activities can you help with ?
                                        </h6>
                                        <Button className="btn cta--rounded cta-primary mt-1" onClick={() => handleAddOnOpen(props?.parentGoal)} style={{ borderRadius: '10px', marginRight: '1rem' }}>
                                            <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>Update
                                                <svg width="18" height="20" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.08058 12.4194C3.95466 13.2935 5.0683 13.8888 6.28069 14.1299C7.49307 14.3711 8.74973 14.2473 9.89177 13.7742C11.0338 13.3012 12.0099 12.5001 12.6967 11.4723C13.3834 10.4445 13.75 9.23613 13.75 8C13.75 6.76387 13.3834 5.55549 12.6967 4.52769C12.0099 3.49988 11.0338 2.6988 9.89177 2.22575C8.74973 1.75271 7.49307 1.62893 6.28069 1.87009C5.0683 2.11125 3.95466 2.7065 3.08058 3.58058" stroke="white" stroke-width="2" />
                                                    <path d="M9.375 8L10.1559 7.3753L10.6556 8L10.1559 8.6247L9.375 8ZM1.875 9C1.32271 9 0.875 8.55228 0.875 8C0.875 7.44772 1.32271 7 1.875 7V9ZM7.65587 4.2503L10.1559 7.3753L8.59413 8.6247L6.09413 5.4997L7.65587 4.2503ZM10.1559 8.6247L7.65587 11.7497L6.09413 10.5003L8.59413 7.3753L10.1559 8.6247ZM9.375 9H1.875V7H9.375V9Z" fill="white" />
                                                </svg>
                                            </span>
                                        </Button>
                                    </div>
                                    <div className="p-3">
                                        <PillSection data={mappedActivityData} maxWidth={369} maxHeight={75} />
                                    </div>
                                </Card>
                            </div> : <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                <Card variant="outlined" sx={{ borderRadius: "10px" }}>
                                    <div className="dashSubContainer" >
                                        <h6 className="dashTitle advisorActivityTitle" style={{ paddingLeft: '15px', paddingRight: '3px' }}>
                                            What activities can you help with ?
                                        </h6>
                                        <Button className="btn cta--rounded cta-primary mt-1" onClick={() => handleAddOnOpen(props?.parentGoal)} style={{ borderRadius: '10px', marginRight: '1rem' }}>
                                            <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>Update
                                                <svg width="18" height="20" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.08058 12.4194C3.95466 13.2935 5.0683 13.8888 6.28069 14.1299C7.49307 14.3711 8.74973 14.2473 9.89177 13.7742C11.0338 13.3012 12.0099 12.5001 12.6967 11.4723C13.3834 10.4445 13.75 9.23613 13.75 8C13.75 6.76387 13.3834 5.55549 12.6967 4.52769C12.0099 3.49988 11.0338 2.6988 9.89177 2.22575C8.74973 1.75271 7.49307 1.62893 6.28069 1.87009C5.0683 2.11125 3.95466 2.7065 3.08058 3.58058" stroke="white" stroke-width="2" />
                                                    <path d="M9.375 8L10.1559 7.3753L10.6556 8L10.1559 8.6247L9.375 8ZM1.875 9C1.32271 9 0.875 8.55228 0.875 8C0.875 7.44772 1.32271 7 1.875 7V9ZM7.65587 4.2503L10.1559 7.3753L8.59413 8.6247L6.09413 5.4997L7.65587 4.2503ZM10.1559 8.6247L7.65587 11.7497L6.09413 10.5003L8.59413 7.3753L10.1559 8.6247ZM9.375 9H1.875V7H9.375V9Z" fill="white" />
                                                </svg>
                                            </span>
                                        </Button>
                                    </div>
                                    <div className="p-3 zeroStateFont" style={{ textAlign: 'center' }}>
                                        <p> Add activities you can help students with planning, tips for success etc</p>
                                    </div>
                                </Card>
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="SECTIONB mt-3">
                    <div>
                        <div class="row g-4" >
                            {combinedArray?.length > 0 ? <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                <Card variant="outlined" sx={{ borderRadius: "10px", height: "10rem" }}>
                                    <div className="dashSubContainer" >
                                        <h6 className="dashTitle" style={{ paddingLeft: '15px' }} >
                                            Set your career goals 🎯
                                        </h6>
                                        <Button className="btn cta--rounded cta-primary mt-1" onClick={() => handleAddOnOpenCareer(props?.parentGoal)} style={{ borderRadius: '10px', marginRight: '1rem' }}>
                                            <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>Update
                                                <svg width="18" height="20" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.08058 12.4194C3.95466 13.2935 5.0683 13.8888 6.28069 14.1299C7.49307 14.3711 8.74973 14.2473 9.89177 13.7742C11.0338 13.3012 12.0099 12.5001 12.6967 11.4723C13.3834 10.4445 13.75 9.23613 13.75 8C13.75 6.76387 13.3834 5.55549 12.6967 4.52769C12.0099 3.49988 11.0338 2.6988 9.89177 2.22575C8.74973 1.75271 7.49307 1.62893 6.28069 1.87009C5.0683 2.11125 3.95466 2.7065 3.08058 3.58058" stroke="white" stroke-width="2" />
                                                    <path d="M9.375 8L10.1559 7.3753L10.6556 8L10.1559 8.6247L9.375 8ZM1.875 9C1.32271 9 0.875 8.55228 0.875 8C0.875 7.44772 1.32271 7 1.875 7V9ZM7.65587 4.2503L10.1559 7.3753L8.59413 8.6247L6.09413 5.4997L7.65587 4.2503ZM10.1559 8.6247L7.65587 11.7497L6.09413 10.5003L8.59413 7.3753L10.1559 8.6247ZM9.375 9H1.875V7H9.375V9Z" fill="white" />
                                                </svg>
                                            </span>
                                        </Button>
                                    </div>
                                    <div className="p-3">
                                        <PillSection data={combinedArray} maxWidth={400} maxHeight={75} />
                                    </div>
                                </Card>
                            </div>
                                :
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                    <Card variant="outlined" sx={{ borderRadius: "10px", height: "9rem"}}>
                                        <div className="dashSubContainer" >
                                            <h6 className="dashTitle" style={{ paddingLeft: '15px' }} >
                                                Set your career goals 🎯
                                            </h6>
                                            <Button className="btn cta--rounded cta-primary mt-1" onClick={() => handleAddOnOpenCareer(props?.parentGoal)} style={{ borderRadius: '10px', marginRight: '1rem' }}>
                                                <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>Update
                                                    <svg width="18" height="20" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M3.08058 12.4194C3.95466 13.2935 5.0683 13.8888 6.28069 14.1299C7.49307 14.3711 8.74973 14.2473 9.89177 13.7742C11.0338 13.3012 12.0099 12.5001 12.6967 11.4723C13.3834 10.4445 13.75 9.23613 13.75 8C13.75 6.76387 13.3834 5.55549 12.6967 4.52769C12.0099 3.49988 11.0338 2.6988 9.89177 2.22575C8.74973 1.75271 7.49307 1.62893 6.28069 1.87009C5.0683 2.11125 3.95466 2.7065 3.08058 3.58058" stroke="white" stroke-width="2" />
                                                        <path d="M9.375 8L10.1559 7.3753L10.6556 8L10.1559 8.6247L9.375 8ZM1.875 9C1.32271 9 0.875 8.55228 0.875 8C0.875 7.44772 1.32271 7 1.875 7V9ZM7.65587 4.2503L10.1559 7.3753L8.59413 8.6247L6.09413 5.4997L7.65587 4.2503ZM10.1559 8.6247L7.65587 11.7497L6.09413 10.5003L8.59413 7.3753L10.1559 8.6247ZM9.375 9H1.875V7H9.375V9Z" fill="white" />
                                                    </svg>
                                                </span>
                                            </Button>
                                        </div>
                                        <div className="p-3 zeroStateFont" style={{ textAlign: 'center' }}>
                                            <p>Connect with students with similar career goals who you can help</p>
                                        </div>
                                    </Card>
                                </div>
                            }

                            {props?.userProfile?.advisorProfile?.currentlyIn === "highschool" && university?.length > 0 ?
                                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                    <Card variant="outlined" sx={{ borderRadius: "10px", height: "10rem" }}>
                                        <div className="dashSubContainer" >
                                            <h6 className="dashTitle" style={{ paddingLeft: '15px' }}>
                                                Set your target universities 🎯
                                            </h6>
                                            <Button className="btn cta--rounded cta-primary mt-1" onClick={() => handleAddOnOpenCareer(props?.parentGoal)} style={{ borderRadius: '10px', marginRight: '1rem' }}>
                                                <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>Update
                                                    <svg width="18" height="20" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M3.08058 12.4194C3.95466 13.2935 5.0683 13.8888 6.28069 14.1299C7.49307 14.3711 8.74973 14.2473 9.89177 13.7742C11.0338 13.3012 12.0099 12.5001 12.6967 11.4723C13.3834 10.4445 13.75 9.23613 13.75 8C13.75 6.76387 13.3834 5.55549 12.6967 4.52769C12.0099 3.49988 11.0338 2.6988 9.89177 2.22575C8.74973 1.75271 7.49307 1.62893 6.28069 1.87009C5.0683 2.11125 3.95466 2.7065 3.08058 3.58058" stroke="white" stroke-width="2" />
                                                        <path d="M9.375 8L10.1559 7.3753L10.6556 8L10.1559 8.6247L9.375 8ZM1.875 9C1.32271 9 0.875 8.55228 0.875 8C0.875 7.44772 1.32271 7 1.875 7V9ZM7.65587 4.2503L10.1559 7.3753L8.59413 8.6247L6.09413 5.4997L7.65587 4.2503ZM10.1559 8.6247L7.65587 11.7497L6.09413 10.5003L8.59413 7.3753L10.1559 8.6247ZM9.375 9H1.875V7H9.375V9Z" fill="white" />
                                                    </svg>
                                                </span>
                                            </Button>
                                        </div>
                                        <div className="p-3">
                                            <PillSection data={university} maxWidth={50} maxHeight={75} />
                                        </div>
                                    </Card>
                                </div>
                                :
                                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                    <Card variant="outlined" sx={{ borderRadius: "10px", height: "9rem" }}>
                                        <div className="dashSubContainer" >
                                            <h6 className="dashTitle" style={{ paddingLeft: '15px' }}>
                                                Set your target universities 🎯
                                            </h6>
                                            <h4 className="btn cta--rounded cta-primary mt-1" onClick={() => handleAddOnOpenCareer(props?.parentGoal)} style={{ borderRadius: '10px', marginRight: '1rem' }}>
                                                <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>Update
                                                    <svg width="18" height="20" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M3.08058 12.4194C3.95466 13.2935 5.0683 13.8888 6.28069 14.1299C7.49307 14.3711 8.74973 14.2473 9.89177 13.7742C11.0338 13.3012 12.0099 12.5001 12.6967 11.4723C13.3834 10.4445 13.75 9.23613 13.75 8C13.75 6.76387 13.3834 5.55549 12.6967 4.52769C12.0099 3.49988 11.0338 2.6988 9.89177 2.22575C8.74973 1.75271 7.49307 1.62893 6.28069 1.87009C5.0683 2.11125 3.95466 2.7065 3.08058 3.58058" stroke="white" stroke-width="2" />
                                                        <path d="M9.375 8L10.1559 7.3753L10.6556 8L10.1559 8.6247L9.375 8ZM1.875 9C1.32271 9 0.875 8.55228 0.875 8C0.875 7.44772 1.32271 7 1.875 7V9ZM7.65587 4.2503L10.1559 7.3753L8.59413 8.6247L6.09413 5.4997L7.65587 4.2503ZM10.1559 8.6247L7.65587 11.7497L6.09413 10.5003L8.59413 7.3753L10.1559 8.6247ZM9.375 9H1.875V7H9.375V9Z" fill="white" />
                                                    </svg>
                                                </span>
                                            </h4>
                                        </div>
                                        <div className="p-3 zeroStateFont" style={{ textAlign: 'center' }}>
                                            <p>Connect with students with similar target universities who you can help</p>
                                        </div>
                                    </Card>
                                </div>
                            }
                        </div>
                    </div>
                    {<div class="col-md-12 mt-3">
                        <h6 className="dashTitle mb-2 currentStatusDash" >Update your current status</h6>

                        <Card variant="outlined" sx={{ textAlign: 'center', width: '100% !important', justifyContent: 'center', alignItems: 'center', borderRadius: "10px", maxHeight: 90, height: 90, display: "flex" }}>
                            <div className="dashSubContainer mb-3" style={{ width: "100%" }} >

                                <h6 className="dashTitle" style={{ textAlign: 'center' }}>
                                    {props?.getAdvisorCurrentState?.state === 1 && "Set up your office hours and pricing packages to turn on services"}
                                    {props?.getAdvisorCurrentState?.state === 2 && "Your services are currently OFF and you are not visible to new users"}
                                    {props?.getAdvisorCurrentState?.state === 3 && "Your services are currently ON and you are visible to new users"}
                                </h6>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    {props?.getAdvisorCurrentState?.state === 1  &&
                                        <Button className="btn cta--rounded cta-primary mt-1" onClick={() => handleNavigateToProfile()} style={{ borderRadius: '10px', marginRight: '1rem' }}>
                                            <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>Set up
                                                <svg width="18" height="20" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.08058 12.4194C3.95466 13.2935 5.0683 13.8888 6.28069 14.1299C7.49307 14.3711 8.74973 14.2473 9.89177 13.7742C11.0338 13.3012 12.0099 12.5001 12.6967 11.4723C13.3834 10.4445 13.75 9.23613 13.75 8C13.75 6.76387 13.3834 5.55549 12.6967 4.52769C12.0099 3.49988 11.0338 2.6988 9.89177 2.22575C8.74973 1.75271 7.49307 1.62893 6.28069 1.87009C5.0683 2.11125 3.95466 2.7065 3.08058 3.58058" stroke="white" stroke-width="2" />
                                                    <path d="M9.375 8L10.1559 7.3753L10.6556 8L10.1559 8.6247L9.375 8ZM1.875 9C1.32271 9 0.875 8.55228 0.875 8C0.875 7.44772 1.32271 7 1.875 7V9ZM7.65587 4.2503L10.1559 7.3753L8.59413 8.6247L6.09413 5.4997L7.65587 4.2503ZM10.1559 8.6247L7.65587 11.7497L6.09413 10.5003L8.59413 7.3753L10.1559 8.6247ZM9.375 9H1.875V7H9.375V9Z" fill="white" />
                                                </svg>
                                            </span>
                                        </Button>}
                                    {props?.getAdvisorCurrentState?.state === 2 &&
                                        <Button className="btn cta--rounded cta-primary mt-1" onClick={() => handleTurnOnServices()} style={{ borderRadius: '10px', marginRight: '1rem' }}>
                                            <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>Turn on
                                                <svg width="18" height="20" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.08058 12.4194C3.95466 13.2935 5.0683 13.8888 6.28069 14.1299C7.49307 14.3711 8.74973 14.2473 9.89177 13.7742C11.0338 13.3012 12.0099 12.5001 12.6967 11.4723C13.3834 10.4445 13.75 9.23613 13.75 8C13.75 6.76387 13.3834 5.55549 12.6967 4.52769C12.0099 3.49988 11.0338 2.6988 9.89177 2.22575C8.74973 1.75271 7.49307 1.62893 6.28069 1.87009C5.0683 2.11125 3.95466 2.7065 3.08058 3.58058" stroke="white" stroke-width="2" />
                                                    <path d="M9.375 8L10.1559 7.3753L10.6556 8L10.1559 8.6247L9.375 8ZM1.875 9C1.32271 9 0.875 8.55228 0.875 8C0.875 7.44772 1.32271 7 1.875 7V9ZM7.65587 4.2503L10.1559 7.3753L8.59413 8.6247L6.09413 5.4997L7.65587 4.2503ZM10.1559 8.6247L7.65587 11.7497L6.09413 10.5003L8.59413 7.3753L10.1559 8.6247ZM9.375 9H1.875V7H9.375V9Z" fill="white" />
                                                </svg>
                                            </span>
                                        </Button>}
                                    {props?.getAdvisorCurrentState?.state === 3 &&
                                        <Button className="btn cta--rounded cta-primary mt-1" onClick={() => handleTurnOnServices()} style={{ borderRadius: '10px', marginRight: '1rem' }}>
                                            <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>Turn OFF
                                                <svg width="18" height="20" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.08058 12.4194C3.95466 13.2935 5.0683 13.8888 6.28069 14.1299C7.49307 14.3711 8.74973 14.2473 9.89177 13.7742C11.0338 13.3012 12.0099 12.5001 12.6967 11.4723C13.3834 10.4445 13.75 9.23613 13.75 8C13.75 6.76387 13.3834 5.55549 12.6967 4.52769C12.0099 3.49988 11.0338 2.6988 9.89177 2.22575C8.74973 1.75271 7.49307 1.62893 6.28069 1.87009C5.0683 2.11125 3.95466 2.7065 3.08058 3.58058" stroke="white" stroke-width="2" />
                                                    <path d="M9.375 8L10.1559 7.3753L10.6556 8L10.1559 8.6247L9.375 8ZM1.875 9C1.32271 9 0.875 8.55228 0.875 8C0.875 7.44772 1.32271 7 1.875 7V9ZM7.65587 4.2503L10.1559 7.3753L8.59413 8.6247L6.09413 5.4997L7.65587 4.2503ZM10.1559 8.6247L7.65587 11.7497L6.09413 10.5003L8.59413 7.3753L10.1559 8.6247ZM9.375 9H1.875V7H9.375V9Z" fill="white" />
                                                </svg>
                                            </span>
                                        </Button>}
                                </div>
                            </div>

                        </Card>
                    </div>}
                </div>
                <div >
                    <div className="row mt-3 SECTIONF" >
                        {props?.earningsCount &&
                            (<div className="col-md-12 col-lg-12 col-xl-6">
                                <Card variant="outlined" sx={{ textAlign: 'center', borderRadius: "10px", mt: { xs: 1, sm: 2, md: 3, lg: 0 } }}>
                                    <h6 className="dashTitle mt-3" style={{ textAlign: 'left', marginLeft: '17px' }}>
                                        Your money status 💰
                                    </h6>
                                    <div className="p-3" style={{ height: "370px", overflow: "auto" }}>

                                        <MoneyStatsCard earningsCount={props?.earningsCount} />
                                    </div>
                                </Card>
                            </div>)
                        }
                        {console.log(filteredData, "filteredData")}

                        {filteredData?.length > 0 && <div class="col-md-12 col-lg-12 col-xl-6">
                            <Card variant="outlined" sx={{ textAlign: 'center', borderRadius: "10px", mt: { xs: 1, sm: 2, md: 3, lg: 0 } }}>
                                <h6 className="dashTitle  mt-3" style={{ textAlign: 'left', marginLeft: '17px' }}>
                                    Scheduled sessions  🗓️
                                </h6>
                                <div className="p-3" style={{ height: "300px", overflow: "auto" }}>
                                    {filteredData?.map((schedule) => (
                                        <ScheduleListCard key={schedule.id} data={schedule} />
                                    ))}

                                </div>
                                <div className="d-flex justify-content-center align-items-center">
                                    <Button onClick={() => handleNavigateToSchedule()} className="btn cta--rounded cta-primary m-3" style={{ borderRadius: '10px', }}>
                                        <span >View schedule
                                            <svg width="18" height="20" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.08058 12.4194C3.95466 13.2935 5.0683 13.8888 6.28069 14.1299C7.49307 14.3711 8.74973 14.2473 9.89177 13.7742C11.0338 13.3012 12.0099 12.5001 12.6967 11.4723C13.3834 10.4445 13.75 9.23613 13.75 8C13.75 6.76387 13.3834 5.55549 12.6967 4.52769C12.0099 3.49988 11.0338 2.6988 9.89177 2.22575C8.74973 1.75271 7.49307 1.62893 6.28069 1.87009C5.0683 2.11125 3.95466 2.7065 3.08058 3.58058" stroke="white" stroke-width="2" />
                                                <path d="M9.375 8L10.1559 7.3753L10.6556 8L10.1559 8.6247L9.375 8ZM1.875 9C1.32271 9 0.875 8.55228 0.875 8C0.875 7.44772 1.32271 7 1.875 7V9ZM7.65587 4.2503L10.1559 7.3753L8.59413 8.6247L6.09413 5.4997L7.65587 4.2503ZM10.1559 8.6247L7.65587 11.7497L6.09413 10.5003L8.59413 7.3753L10.1559 8.6247ZM9.375 9H1.875V7H9.375V9Z" fill="white" />
                                            </svg>
                                        </span>
                                    </Button>
                                </div>
                            </Card>
                        </div>

                        }
                    </div>
                </div>


            </div>
        </>
    );
};

const mapStateToProps = ({ commonData, userProfileData, studentData, advisorData }) => {
    const { childprofile, userProfile } = userProfileData;
    const { isgetusergoals, getstudentplan, studentplanstatus } = studentData;
    const { dashboardGoalsCount, earningsCount, advisorDashboardCount, ismilestoneCountDataStatus, pricingData, getAdvisorCurrentState } = advisorData;
    const { message, errorList, connectedAdvisors, paidSessionBooked, bookPaidSessionError, sessionBooked, booksessionerror, totalUserCount, eventSwitchList, exploreAdvisorCount, currentDashboardCourses, currentDashboardActivity, scheduledEvents, advisorReview, reviewDataStatus, inviteStudentError } = commonData;
    return { message, errorList, connectedAdvisors, isgetusergoals, userProfile, paidSessionBooked, dashboardGoalsCount, studentplanstatus, bookPaidSessionError, getstudentplan, sessionBooked, booksessionerror, totalUserCount, childprofile, eventSwitchList, exploreAdvisorCount, reviewDataStatus, currentDashboardCourses, currentDashboardActivity, scheduledEvents, earningsCount, advisorDashboardCount, ismilestoneCountDataStatus, advisorReview, pricingData, inviteStudentError, getAdvisorCurrentState };
};
export default connect(mapStateToProps, { clearMessage, bookPaidSessionErrorStatus, GetStudentPlan, getChildProfile, GetStudentPlanStatus, getAdvisorCountUpdateStatus, getUserCount, bookSession, bookPaidSession, bookSessionStatus, bookPaidSessionStatus, getGoalsFromStudent, setIsParentGoalSuccess, loadAdvisorReview, manageProfileServices, loadAdvisorReviewStatus, inviteStudentErrorStatus })(Index);
