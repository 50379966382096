import React from "react";
import { Modal, Box, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { replyNotes, ReplyNotesStatus, GetMilestoneByCourse, GetMilestoneByActivity} from "../../../../redux/actions";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "react-bootstrap";
import ReactQuill from 'react-quill';
import { connect } from "react-redux";
import { useComponentDidUpdate,  } from "../../../../utils/useEffectHooks";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 800,
    minWidth: 270,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "5px"
};

const validation = Yup.object().shape({
    description: Yup.string().required('*Required'),
});

const stripHTMLTags = (html) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || "";
};

const Index = (props) => {
    const { handleSubmit, reset, setValue, control, formState: { errors } } = useForm({ mode: "onSubmit", resolver: yupResolver(validation), });

    const onSubmit = (data) => {
        let payload = {
            planId: props?.mileStoneData?.planId,
            milestoneId: props?.mileStoneData?.id,
            updateContext: data?.description,
            tagId: props?.data?.id
        }
        props?.addReply(payload)
    };

    useComponentDidUpdate(() => {
        if (props?.isReplyNotes) {
            props?.handleClose();
            props?.ReplyNotesStatus(false);
            reset();
        }
    }, [props?.isReplyNotes])

    function formatDate(dateTimeString) {
        const options = {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
        };
        const date = new Date(dateTimeString);
        const formattedDate = date.toLocaleDateString("en-US", options);
        return `${formattedDate} `;
    }

    return (
        <Modal
            open={props?.show}
            onClose={props?.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={style} className="notes-popup">
                    <Grid container spacing={2}>
                        <Grid item xs={11}>
                            <div className="milestoneTitle "> {"Post Update"} </div>
                        </Grid>
                        <Grid item xs={1} className="text-end" style={{ cursor: 'pointer' }}>
                            <CloseIcon onClick={props?.handleClose} />
                        </Grid>

                    </Grid>
                    <div className="reply-container">
                        <div className="content-to-reply">
                            <div className="d-flex " style={{gap: '10px'}}> <p className="mb-0 postName">{props?.postedBy}</p> <p className="ml-6 timeLineCard__topSec__updateDetails__updated-time"> {formatDate(props?.data?.modifiedAt)}</p></div>
                            <p className="mb-0 postContent">{stripHTMLTags(props?.data?.update)}</p>
                        </div>
                        <Grid className=" ">
                            <Controller
                                name="description"
                                control={control}
                                // defaultValue= {props?.data?.update}
                                render={({ field }) => (
                                    <ReactQuill
                                        value={field.value}
                                        style={{ minHeight: '300px', maxHeight: '400px', overflow: 'scroll', "border-top-left-radius": '0px', "border-top-right-radius": '0px', }}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        className="textarea--primary textbox--rounded input" theme="snow" {...field}
                                    />

                                )}
                            />
                            <div className="error-text">{errors.description?.message}</div>
                        </Grid>
                    </div>
                    <Grid item xs={12} className="text-end">
                        <Button type="submit" className="btn AddActivitybtn" >Update</Button>

                    </Grid>
                </Box>
            </form>
        </Modal>
    );
}
const mapStateToProps = ({ studentData,userProfileData }) => {
    const { userProfile } = userProfileData;
    const { isReplyNotes } = studentData;
    return { isReplyNotes,userProfile };
};

export default connect(mapStateToProps, { replyNotes, ReplyNotesStatus, GetMilestoneByCourse, GetMilestoneByActivity })(Index);

