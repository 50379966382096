import React from "react";
import './workingHours.less'
import Tags from '../../../../componentsNew/Common/Tags'

const index = (props) => {
    let { mondayTimesheet, tuesdayTimesheet, wednesdayTimesheet, thursdayTimesheet, fridayTimesheet, saturdayTimesheet, sundayTimesheet } = props?.data
    const dateConverter = (data) => {
        if (data) {
            const time = data.split(',')[0].trim();
            return time;
        }
    }
    const mondayAvailableTime = mondayTimesheet?.map((time, index) => {
        return(dateConverter(time.startingTime) + " - " + dateConverter(time.closingTime))
    })
    const tuesdayAvailableTime = tuesdayTimesheet?.map((time, index) => {
        return(dateConverter(time.startingTime) + " - " + dateConverter(time.closingTime))
    })
    const wednesdayAvailableTime = wednesdayTimesheet?.map((time, index) => {
        return(dateConverter(time.startingTime) + " - " + dateConverter(time.closingTime))
    })
    const thursdayAvailableTime = thursdayTimesheet?.map((time, index) => {
        return(dateConverter(time.startingTime) + " - " + dateConverter(time.closingTime))
    })
    const fridayAvailableTime = fridayTimesheet?.map((time, index) => {
        return(dateConverter(time.startingTime) + " - " + dateConverter(time.closingTime))
    })
    const saturdayAvailableTime = saturdayTimesheet?.map((time, index) => {
        return(dateConverter(time.startingTime) + " - " + dateConverter(time.closingTime))
    })
    const sundayAvailableTime = sundayTimesheet?.map((time, index) => {
        return(dateConverter(time.startingTime) + " - " + dateConverter(time.closingTime))
    })

    return (
        <div className="workingHoursCard advisorProfileCard ">
            {
                (mondayAvailableTime?.length || tuesdayAvailableTime?.length || wednesdayAvailableTime?.length ||
                    thursdayAvailableTime?.length || fridayAvailableTime?.length || saturdayAvailableTime?.length ||
                    sundayAvailableTime?.length) ?
                    <>
                        <div className="workingHoursCard__hoursSec">
                            {
                                mondayAvailableTime?.length > 0 &&
                                <div className="workingHoursCard__dayContent">
                                    <p className="workingHoursCard__dayContent__label">Monday</p>
                                    <div className="workingHoursCard__dayContent__tagsec">
                                        {mondayAvailableTime?.map(time => <Tags bold text={time} />)}
                                    </div>
                                </div>
                            }{
                                tuesdayAvailableTime?.length > 0 &&
                                <div className="workingHoursCard__dayContent">
                                    <p className="workingHoursCard__dayContent__label">Tuesday</p>
                                    <div className="workingHoursCard__dayContent__tagsec">
                                        {tuesdayAvailableTime?.map(time => <Tags bold text={time} />)}
                                    </div>
                                </div>
                            }{
                                wednesdayAvailableTime?.length > 0 &&
                                <div className="workingHoursCard__dayContent">
                                    <p className="workingHoursCard__dayContent__label">Wednesday</p>
                                    <div className="workingHoursCard__dayContent__tagsec">
                                        {wednesdayAvailableTime?.map(time => <Tags bold text={time} />)}
                                    </div>
                                </div>
                            }{
                                thursdayAvailableTime?.length > 0 &&
                                <div className="workingHoursCard__dayContent">
                                    <p className="workingHoursCard__dayContent__label">Thursday</p>
                                    <div className="workingHoursCard__dayContent__tagsec">
                                        {thursdayAvailableTime?.map(time => <Tags bold text={time} />)}
                                    </div>
                                </div>
                            }{
                                fridayAvailableTime?.length > 0 &&
                                <div className="workingHoursCard__dayContent">
                                    <p className="workingHoursCard__dayContent__label">Friday</p>
                                    <div className="workingHoursCard__dayContent__tagsec">
                                        {fridayAvailableTime?.map(time => <Tags bold text={time} />)}
                                    </div>
                                </div>
                            }{
                                saturdayAvailableTime?.length > 0 &&
                                <div className="workingHoursCard__dayContent">
                                    <p className="workingHoursCard__dayContent__label">Saturday</p>
                                    <div className="workingHoursCard__dayContent__tagsec">
                                        {saturdayAvailableTime?.map(time => <Tags bold text={time} />)}
                                    </div>
                                </div>
                            }{
                                sundayAvailableTime?.length > 0 &&
                                <div className="workingHoursCard__dayContent">
                                    <p className="workingHoursCard__dayContent__label">Sunday</p>
                                    <div className="workingHoursCard__dayContent__tagsec">
                                        {sundayAvailableTime?.map(time => <Tags bold text={time} />)}
                                    </div>
                                </div>
                            }</div>
                    </> :
                    <></>
            }
        </div>
    );
};


export default index;