import React, { Component } from "react";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillMount() {}

  render() {
    return (
      <>
        <div className="filter">
          <span className="filterIcon cursorshow">
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path d="M22.0007 7.83203H2.00065C1.82384 7.83203 1.65427 7.90227 1.52925 8.02729C1.40422 8.15232 1.33398 8.32189 1.33398 8.4987C1.33398 8.67551 1.40422 8.84508 1.52925 8.9701C1.65427 9.09513 1.82384 9.16536 2.00065 9.16536H22.0007C22.1775 9.16536 22.347 9.09513 22.4721 8.9701C22.5971 8.84508 22.6673 8.67551 22.6673 8.4987C22.6673 8.32189 22.5971 8.15232 22.4721 8.02729C22.347 7.90227 22.1775 7.83203 22.0007 7.83203Z" fill="black"/>
           <path d="M18.666 11.832H5.33268C5.15587 11.832 4.9863 11.9023 4.86128 12.0273C4.73625 12.1523 4.66602 12.3219 4.66602 12.4987C4.66602 12.6755 4.73625 12.8451 4.86128 12.9701C4.9863 13.0951 5.15587 13.1654 5.33268 13.1654H18.666C18.8428 13.1654 19.0124 13.0951 19.1374 12.9701C19.2624 12.8451 19.3327 12.6755 19.3327 12.4987C19.3327 12.3219 19.2624 12.1523 19.1374 12.0273C19.0124 11.9023 18.8428 11.832 18.666 11.832Z" fill="black"/>
           <path d="M15.3333 15.832H8.66667C8.48986 15.832 8.32029 15.9023 8.19526 16.0273C8.07024 16.1523 8 16.3219 8 16.4987C8 16.6755 8.07024 16.8451 8.19526 16.9701C8.32029 17.0951 8.48986 17.1654 8.66667 17.1654H15.3333C15.5101 17.1654 15.6797 17.0951 15.8047 16.9701C15.9298 16.8451 16 16.6755 16 16.4987C16 16.3219 15.9298 16.1523 15.8047 16.0273C15.6797 15.9023 15.5101 15.832 15.3333 15.832Z" fill="black"/>
           </svg>

            </span>
          <button className="filterTerm " >{this.props.placeholder ? this.props.placeholder : ""}</button>
        </div>
      </>
    );
  }
}
export default Index;
