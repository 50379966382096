import React, { useEffect, useState } from 'react'
import CourseCard from "../../../../../componentsNew/Common/Cards/MyWork/CourseCard"
import ActivityCard from "../../../../../componentsNew/Common/Cards/MyWork/ActivityCard"
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Loader from '../../../../../components/Loader';
import AddActivity from "../../../../../componentsNew/Common/PhaseModal/AddActivity";
import AddCourse from "../../../../../componentsNew/Common/PhaseModal/AddCourse";
import {
  getCourse, getCourses, getActivities, getActivity, getStudentGrades, clearMessage, GetStudentPlanId, getConnectedAdvisors, setConnectedAdvisorStatus,
  EditCourseStatus, DeleteCourseStatus, CreatePlanStatus, EditPlanStatus, CreateCourse, CreateActivity, GetStudentPlanPhase,
  GetStudentPlan, getPhasetime, CreateCourseStatus, CreateCourseErrorStatus, CreateActivityStatus, EditActivityStatus, bookPaidSession, bookSession,
  CreateActivityErrorStatus, EditPhaseStatus, DeleteActivityStatus, GetStudentPlanPhaseStatus, EditCourse, EditActivity, courseApprove, courseReject, courseRejectStatus, courseApproveStatus,
  DeleteCourse, DeleteActivity, courseDropConfirmation, courseDropConfirmationStatus, activityDropConfirmation, activityDropConfirmationStatus, activityDropErrorStatus, activityApproveErrorStatus,
  activitiesReject, activityRejectStatus, activitiesApprove, activityApproveStatus, archiveCourse, archiveCourseStatus, archiveCourseErrorStatus, unArchiveCourse, unArchiveCourseStatus,
  archiveActivity, archiveActivityStatus, archiveActivityErrorStatus, unArchiveActivity, unArchiveActivityStatus, unArchiveActivityError, bookSessionStatus, bookSessionErrorStatus, bookPaidSessionStatus, bookPaidSessionErrorStatus, DeleteMileStoneStatus
} from "../../../../../redux/actions";
import { withRouter } from "../../../../../redux/store/navigate";
import { connect } from "react-redux";
import { Dropdown } from "react-bootstrap";
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ManageAdvisorPopup from '../../../../../componentsNew/Common/MyworkModal/ManageAdvisor';
import ManageStudentPopup from '../../../../../componentsNew/Common/MyworkModal/ManageStudent';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { Badge, Tooltip } from '@mui/material';
import Rejectcourse from "../../Popups/acceptcourse";
import { useImageUrl } from '../../../../../utils/UseImageURL';
import BookSessionPopup from "../../../../../componentsNew/Common/BookSession/BookSessionPopup";
import CustomAlert from '../../../../../components/CustomAlert';

const Index = (props) => {
  const [loading, setloading] = useState(false)
  const [addcourse, setAddcourse] = useState(false)
  const [addactivity, setAddactivity] = useState(false)
  const [editCourse, setEdit] = useState(false)
  const [semesterData, setSemesterData] = useState(false)
  const [plandata, setplandata] = useState();
  const location = useLocation();
  const id = location?.pathname?.split('/').pop();
  const [showadvisor, setshowadvisor] = useState(false);
  const [showstudent, setshowstudent] = useState(false);
  const [showreject, setshowreject] = useState(false);
  const [activityReject, setshowActivityReject] = useState(false);
  const [detaildata, setDetaildata] = useState('');
  const [advisorid, setAdvisorid] = useState("");
  const [paidSession, setPaidSession] = useState(null);
  const [advisorName, setAdvisorName] = useState("");
  const [TimeSession, setTimeSession] = useState([]);
  const [bookshow, setbookshow] = useState(false);
  const [globalAdvisor, setGlobalAdvisor] = useState(null);

  const Newworks = /\/student\/[^\/]+\/mywork\//.test(window?.location?.pathname);
  let navigate = useNavigate();

  useEffect(() => {
    props?.clearMessage();
    setloading(true)
    props?.getConnectedAdvisors();
    
  }, [])

  const OpenMangeAdvisor = (e, data, value, accessibleGlobalAdvisors) => {
    e.preventDefault();
    setplandata(data)
    setGlobalAdvisor(accessibleGlobalAdvisors ?? null)
    setshowadvisor(true)
    setDetaildata(value)
  }
  const CloseMangeAdvisor = () => {
    setshowadvisor(false)
    setplandata({})
    setGlobalAdvisor(null)
    setDetaildata('')
  }

  const OpenManageStudents = (e, data, value) => {
    e.preventDefault();
    setplandata(data)
    setshowstudent(true)
    setDetaildata(value)
  }
  const CloseManageStudents = () => {
    setshowstudent(false)
    setplandata({})
    setDetaildata('')
  }

  useEffect(() => {
    if (props?.isCourseCreated || props?.isEditCourse) {
      Coursepopuphide()
      props?.CreateCourseStatus(false)
      props?.EditCourseStatus(false)
      if (id) {
        let plansId = id
        if (plansId != "") {
          props?.GetStudentPlanId(plansId)
        }
      }
    }
    if (props?.isCourseError) {
      props?.CreateCourseErrorStatus(false)
      Coursepopuphide()
      if (id) {
        let plansId = id
        if (plansId != "") {
          props?.GetStudentPlanId(plansId)
        }
      }
    }
    if (props?.isCourseApprove) {
      props?.courseApproveStatus(false)
      if (id) {
        let plansId = id
        if (plansId != "") {
          props?.GetStudentPlanId(plansId)
        }
      }
    }
    if (props?.isCourseDeleted) {
      props?.DeleteCourseStatus(false)
      setloading(false)
      let plansId = id
      if (plansId != "") {
        props?.GetStudentPlanId(plansId)
      }
    }
    if (props?.isCourseRejected) {
      props?.courseRejectStatus(false)
      setloading(false)
      let plansId = id
      if (plansId != "") {
        props?.GetStudentPlanId(plansId)
      }
    }
    if (props?.courseDropStatus) {
      setloading(false)
      props?.courseDropConfirmationStatus(false)
      let plansId = id
      if (plansId != "") {
        props?.GetStudentPlanId(plansId)
      }
    }
    if (props?.courseDropError) {
      setloading(false)
      props?.courseDropErrorStatus(false)
      let plansId = id
      if (plansId != "") {
        props?.GetStudentPlanId(plansId)
      }
    }
    if (props?.connectedAdvisorsStatus) {
      props?.setConnectedAdvisorStatus(false)
      setloading(false)
    }
  }, [props?.connectedAdvisorsStatus, props?.isCourseCreated, props?.isCourseError, props?.isEditCourse, id, props?.isCourseApprove, props?.isCourseDeleted, props?.isCourseRejected, props?.courseDropStatus, props?.courseDropError])

  useEffect(() => {
    if (props?.isActivityCreated || props?.isEditActivity) {
      Activitypopuphide()
      props?.CreateActivityStatus(false)
      props?.EditActivityStatus(false)
      if (id) {
        let plansId = id
        if (plansId != "") {
          props?.GetStudentPlanId(plansId)
        }
      }
    }
    if (props?.isActivityError) {
      props?.CreateActivityErrorStatus(false)
      if (id) {
        let plansId = id
        if (plansId != "") {
          props?.GetStudentPlanId(plansId)
        }
      }
    }
    if (props?.isActivityDeleted) {
      props?.DeleteActivityStatus(false)
      setloading(false)
      let plansId = id
      if (plansId != "") {
        props?.GetStudentPlanId(plansId)
      }
    }
    if (props?.isActivityAproved) {
      props?.activityApproveStatus(false)
      setloading(false)
      let plansId = id
      if (plansId != "") {
        props?.GetStudentPlanId(plansId)
      }
    }
    if (props?.isActivityAprovedError) {
      props?.activityApproveErrorStatus(false)
      setloading(false)
      let plansId = id
      if (plansId != "") {
        props?.GetStudentPlanId(plansId)
      }
    }
    if (props?.isactivityRejected) {
      props?.activityRejectStatus(false)
      setloading(false)
      let plansId = id
      if (plansId != "") {
        props?.GetStudentPlanId(plansId)
      }
    }
    if (props?.activityDropStatus) {
      props?.activityDropConfirmationStatus(false)
      setloading(false)
      let plansId = id
      if (plansId != "") {
        props?.GetStudentPlanId(plansId)
      }
    }
    if (props?.activityDropError) {
      props?.activityDropErrorStatus(false)
      setloading(false)
      let plansId = id
      if (plansId != "") {
        props?.GetStudentPlanId(plansId)
      }
    }
  }, [props?.isActivityCreated, props?.isActivityError, props?.isEditActivity, props?.isActivityDeleted, props?.isActivityAproved,
  props?.isActivityAprovedError, props?.isactivityRejected, props?.activityDropError, props?.activityDropStatus, props?.activityDropError])

  useEffect(() => {
    if (props?.archievedCourse) {
      if (id) {
        let plansId = id
        if (plansId != "") {
          props?.GetStudentPlanId(plansId)
        }
      }
      props?.archiveCourseStatus(false)
      setloading(false)
    }
    if (props?.archievedCourseError) {
      if (id) {
        let plansId = id
        if (plansId != "") {
          props?.GetStudentPlanId(plansId)
        }
      }
      props?.archiveCourseErrorStatus(false)
      setloading(false)
    }
    if (props?.unarchievedCourse) {
      if (id) {
        let plansId = id
        if (plansId != "") {
          props?.GetStudentPlanId(plansId)
        }
      }
      props?.unArchiveCourseStatus(false)
      setloading(false)
    }
    if (props?.unarchievedCourseError) {
      if (id) {
        let plansId = id
        if (plansId != "") {
          props?.GetStudentPlanId(plansId)
        }
      }
      props?.unArchiveCourseError(false)
      setloading(false)
    }
    if (props?.archievedActivity) {
      if (id) {
        let plansId = id
        if (plansId != "") {
          props?.GetStudentPlanId(plansId)
        }
      }
      props?.archiveActivityStatus(false)
      setloading(false)
    }
    if (props?.archievedActivityError) {
      if (id) {
        let plansId = id
        if (plansId != "") {
          props?.GetStudentPlanId(plansId)
        }
      }
      props?.archiveActivityErrorStatus(false)
      setloading(false)
    }
    if (props?.unarchievedActivity) {
      if (id) {
        let plansId = id
        if (plansId != "") {
          props?.GetStudentPlanId(plansId)
        }
      }
      props?.unArchiveActivityStatus(false)
      setloading(false)
    }
    if (props?.unarchievedActivityError) {
      if (id) {
        let plansId = id
        if (plansId != "") {
          props?.GetStudentPlanId(plansId)
        }
      }
      props?.unArchiveActivityError(false)
      setloading(false)
    }
  }, [props?.archievedCourse, props?.archievedCourseError, props?.unarchievedCourse, props?.unarchievedCourseError,
  props?.archievedActivity, props?.archievedActivityError, props?.unarchievedActivity, props?.unarchievedActivityError,
  ])
  useEffect(() => {
    if (props?.sessionBooked) {
      CloseBookSession()
      props?.bookSessionStatus(false)
    }
    if (props?.booksessionerror) {
      CloseBookSession()
      props?.bookSessionErrorStatus(false)
    }
    if (props?.paidSessionBooked) {
      CloseBookSession()
      props?.bookPaidSessionStatus(false)
    }
    if (props?.bookPaidSessionError) {
      CloseBookSession()
      props?.bookPaidSessionErrorStatus(false)
    }

  }, [props?.sessionBooked, props?.booksessionerror, props?.paidSessionBooked, props?.bookPaidSessionError])
  // Popups
  const Coursepopupshow = (data, edit) => {
    setAddcourse(true)
    setSemesterData(data)
    if (edit) {
      setEdit(edit)
    }
  }
  const Coursepopuphide = () => {
    setAddcourse(false)
    setEdit(false)
  }
  const Activitypopupshow = (data, edit) => {
    setAddactivity(true)
    setSemesterData(data)
    if (edit) {
      setEdit(edit)
    }
  }
  const Activitypopuphide = () => {
    setAddactivity(false)
    setEdit(false)
  }

  const handleCourseSubmit = (data, editCourse) => {
    let plansId = id
    let payload = {
      planId: plansId,
      phaseId: data?.semester?.value || "",
      courseName: data?.coursename?.value || "",
      courseType: data?.courselevel?.value || "",
      description: data?.helpwith || "",
      accessibleAdvisors: [],
      grade: data?.gradeachieved?.value || "",
    };
    if (editCourse) {
      props.EditCourse(payload, editCourse?.id)
    } else {
      props.CreateCourse(payload)
    }
  }
  const handleActivitySubmit = (data, editCourse) => {
    let plansId = id
    let payload = {
      planId: plansId,
      phaseId: data?.semester?.value || "",
      activityName: data?.activityname?.value || data?.activityname || "",
      activityType: data?.activitytype?.value || data?.activitytype?.label || "",
      description: data?.helpwith || data?.description || "",
      accessibleAdvisors: [],
      grade: data?.gradeachieved?.value || data?.grade || "",
    };
    if (editCourse) {
      props.EditActivity(payload, editCourse?.id)
    } else {
      props.CreateActivity(payload)
    }
  }
  const handleEdit = (data, edit, phaseData) => {
    setAddcourse(true)
    if (edit) {
      setEdit(data)
      setSemesterData(phaseData)
    }
  }
  const handleActivityEdit = (data, edit, phaseData) => {
    setAddactivity(true)
    if (edit) {
      setEdit(data)
      setSemesterData(phaseData)
    }
  }

  const handleDelete = (data, type) => {
    const DeleteId = data?.id
    let payload = {
      planId: data?.planId,
      phaseId: data?.phaseId
    }
    if (type === "Course") {
      setloading(true)
      props?.DeleteCourse(payload, DeleteId, "dashboard")
    }
    if (type === "Activity") {
      setloading(true)
      props?.DeleteActivity(payload, DeleteId, "dashboard")
    }
  }

  const getSuggestionDetail = (isApproved, isDropSuggested, type) => {
    if (isApproved && isDropSuggested) {
      return (
        <p className='myWork-courseCard__suggestionSec__dangerText'>
          Advisor suggested to drop this {type}. can now review and accept or reject Advisor proposal
          <ChatBubbleOutlineOutlinedIcon className='myWork-courseCard__suggestionSec__chatIcon' />
        </p>
      );
    } else if (!isApproved) {
      return (
        <p className='myWork-courseCard__suggestionSec__successText'>
          You can now review and accept or reject Advisor proposal
          <ChatBubbleOutlineOutlinedIcon className='myWork-courseCard__suggestionSec__chatIcon' />
        </p>
      );
    }
  }
  const handleArchievecourse = (data) => {
    if (data?.id) {
      setloading(true)
      let payload = {
        "phaseId": data?.phaseId,
        "planId": data?.planId
      }
      props?.archiveCourse(data?.id, payload)
    }
  }
  const handleUnArchievecourse = (data) => {
    if (data?.id) {
      setloading(true)
      let payload = {
        "phaseId": data?.phaseId,
        "planId": data?.planId
      }
      props?.unArchiveCourse(data?.id, payload)
    }
  }
  const handleArchieveactivity = (data) => {
    if (data?.id) {
      setloading(true)
      let payload = {
        "phaseId": data?.phaseId,
        "planId": data?.planId
      }
      props?.archiveActivity(data?.id, payload)
    }
  }
  const handleUnArchieveactivity = (data) => {
    if (data?.id) {
      setloading(true)
      let payload = {
        "phaseId": data?.phaseId,
        "planId": data?.planId
      }
      props?.unArchiveActivity(data?.id, payload)
    }
  }
  const showCourseMenu = (data, phaseData, accessibleAdvisorDatas, accessibleStudent) => {
    const advisorsWithSubscriptions = props?.connectedAdvisors?.filter(advisor => advisor?.subscriptiondetails?.length > 0);
    const isSubscribedAdvisor = advisorsWithSubscriptions?.some(advisor =>
      accessibleAdvisorDatas?.some(subscribedAdvisor => (subscribedAdvisor?.userInfo?.id || subscribedAdvisor?.id) === advisor?.userInfo?.id)
    );
    return (
      <div className="cardgrid__dot">
        <Dropdown className='cardgrid__dot__dropDownContainer'>
          <Dropdown.Toggle id="dropdown-basic">
            <svg width="16" height="6" viewBox="0 0 16 4" fill="#fff" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M1.6 3.2C0.716344 3.2 0 2.48366 0 1.6C0 0.716344 0.716344 0 1.6 0C2.48366 0 3.2 0.716344 3.2 1.6C3.2 2.48366 2.48366 3.2 1.6 3.2ZM7.99844 3.2C7.11478 3.2 6.39844 2.48366 6.39844 1.6C6.39844 0.716344 7.11478 0 7.99844 0C8.88209 0 9.59844 0.716344 9.59844 1.6C9.59844 2.48366 8.88209 3.2 7.99844 3.2ZM12.8 1.6C12.8 2.48366 13.5163 3.2 14.4 3.2C15.2836 3.2 16 2.48366 16 1.6C16 0.716344 15.2836 0 14.4 0C13.5163 0 12.8 0.716344 12.8 1.6Z" />
            </svg>
          </Dropdown.Toggle>
          <Dropdown.Menu className='cardgrid__dot__dropDownContainer__menu'>
            <Dropdown.Item className="cardgrid__dot__dropDownContainer__menu__item" onClick={(e) => handleEdit(data, "edit", phaseData)}>
              <svg width="14" height="14" style={{ cursor: 'pointer' }} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_257_4828)"><path d="M8.03363 0.0914958C7.97503 0.0329111 7.89555 0 7.81269 0C7.72983 0 7.65036 0.0329111 7.59175 0.0914958L6.56238 1.12087L8.87925 3.43775L9.90863 2.409C9.93773 2.37997 9.96082 2.34548 9.97657 2.30752C9.99233 2.26955 10.0004 2.22885 10.0004 2.18775C10.0004 2.14664 9.99233 2.10594 9.97657 2.06797C9.96082 2.03001 9.93773 1.99552 9.90863 1.9665L8.03363 0.0914958V0.0914958ZM8.43738 3.87962L6.1205 1.56275L2.058 5.62525H2.18738C2.27026 5.62525 2.34974 5.65817 2.40835 5.71677C2.46695 5.77538 2.49988 5.85487 2.49988 5.93775V6.25025H2.81238C2.89526 6.25025 2.97474 6.28317 3.03335 6.34177C3.09195 6.40038 3.12488 6.47987 3.12488 6.56275V6.87525H3.43738C3.52026 6.87525 3.59974 6.90817 3.65835 6.96677C3.71695 7.02538 3.74988 7.10487 3.74988 7.18775V7.50025H4.06238C4.14526 7.50025 4.22474 7.53317 4.28335 7.59177C4.34195 7.65038 4.37488 7.72987 4.37488 7.81275V7.94212L8.43738 3.87962V3.87962ZM3.76988 8.54712C3.75673 8.51215 3.74995 8.47511 3.74988 8.43775V8.12525H3.43738C3.3545 8.12525 3.27501 8.09232 3.21641 8.03372C3.1578 7.97511 3.12488 7.89563 3.12488 7.81275V7.50025H2.81238C2.7295 7.50025 2.65001 7.46732 2.59141 7.40872C2.5328 7.35011 2.49988 7.27063 2.49988 7.18775V6.87525H2.18738C2.1045 6.87525 2.02501 6.84232 1.96641 6.78372C1.9078 6.72511 1.87488 6.64563 1.87488 6.56275V6.25025H1.56238C1.52502 6.25018 1.48797 6.24341 1.453 6.23025L1.34113 6.3415C1.31135 6.37149 1.28796 6.40721 1.27238 6.4465L0.0223788 9.5715C-0.000352871 9.62829 -0.00591756 9.6905 0.0063746 9.75042C0.0186668 9.81034 0.0482752 9.86534 0.0915292 9.9086C0.134783 9.95185 0.189781 9.98146 0.249703 9.99375C0.309626 10.006 0.371839 10.0005 0.428629 9.97775L3.55363 8.72775C3.59292 8.71217 3.62864 8.68878 3.65863 8.659L3.76988 8.54775V8.54712Z" fill="#1C84EE" /></g><defs><clipPath id="clip0_257_4828"><rect width="10" height="10" fill="white" /></clipPath></defs></svg>
              {" "}<span>Edit</span>
            </Dropdown.Item>
            {(!data?.isArchived && !isSubscribedAdvisor) &&
              <Dropdown.Item className="cardgrid__dot__dropDownContainer__menu__item" onClick={(e) => handleArchievecourse(data)}>
                <svg width="14" height="14" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3 10.5947C3 7.7663 3 6.35209 3.87868 5.47341C4.75736 4.59473 6.17157 4.59473 9 4.59473H15C17.8284 4.59473 19.2426 4.59473 20.1213 5.47341C21 6.35209 21 7.7663 21 10.5947C21 13.4232 21 14.8374 20.1213 15.716C19.2426 16.5947 17.8284 16.5947 15 16.5947H9C6.17157 16.5947 4.75736 16.5947 3.87868 15.716C3 14.8374 3 13.4232 3 10.5947Z" fill="#7E869E" fill-opacity="0.25" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M5.56689 12.5947H4.8558C4.05238 12.5947 3.65068 12.5947 3.37733 12.8122C3.31819 12.8593 3.26457 12.9129 3.21751 12.9721C3 13.2454 3 13.6471 3 14.4505V15.5947C3 18.4232 3 19.8374 3.87868 20.716C4.75736 21.5947 6.17157 21.5947 9 21.5947H15C17.8284 21.5947 19.2426 21.5947 20.1213 20.716C21 19.8374 21 18.4232 21 15.5947V14.4505C21 13.6471 21 13.2454 20.7825 12.9721C20.7354 12.9129 20.6818 12.8593 20.6227 12.8122C20.3493 12.5947 19.9476 12.5947 19.1442 12.5947H18.4331C17.7346 12.5947 17.3853 12.5947 17.0908 12.6879C16.5803 12.8495 16.1559 13.209 15.9126 13.686C15.7723 13.9612 15.7148 14.3057 15.6 14.9947L15.6 14.9947L15.6 14.9947L15.6 14.9948C15.5713 15.167 15.5569 15.2531 15.5218 15.3219C15.461 15.4412 15.3549 15.531 15.2273 15.5714C15.1537 15.5947 15.0664 15.5947 14.8917 15.5947H9.10828C8.93365 15.5947 8.84633 15.5947 8.7727 15.5714C8.64508 15.531 8.53897 15.4412 8.47815 15.3219C8.44306 15.2531 8.42871 15.167 8.4 14.9947C8.28517 14.3057 8.22775 13.9612 8.08739 13.686C7.8441 13.209 7.4197 12.8495 6.90919 12.6879C6.61466 12.5947 6.26541 12.5947 5.56689 12.5947ZM9.5 17.5947C9.22386 17.5947 9 17.8186 9 18.0947C9 18.3709 9.22386 18.5947 9.5 18.5947H14.5C14.7761 18.5947 15 18.3709 15 18.0947C15 17.8186 14.7761 17.5947 14.5 17.5947H9.5Z" fill="#222222" />
                </svg>
                {" "}<span>Archive Course</span>
              </Dropdown.Item>
            }
            {(data?.isArchived && !isSubscribedAdvisor) &&
              <Dropdown.Item className="cardgrid__dot__dropDownContainer__menu__item" onClick={(e) => handleUnArchievecourse(data)}>
                <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3 10C3 7.17157 3 5.75736 3.87868 4.87868C4.75736 4 6.17157 4 9 4H15C17.8284 4 19.2426 4 20.1213 4.87868C21 5.75736 21 7.17157 21 10C21 12.8284 21 14.2426 20.1213 15.1213C19.2426 16 17.8284 16 15 16H9C6.17157 16 4.75736 16 3.87868 15.1213C3 14.2426 3 12.8284 3 10Z" fill="#7E869E" fill-opacity="0.25" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.56689 12H4.8558C4.05238 12 3.65068 12 3.37733 12.2175C3.31819 12.2646 3.26457 12.3182 3.21751 12.3773C3 12.6507 3 13.0524 3 13.8558V15C3 17.8284 3 19.2426 3.87868 20.1213C4.75736 21 6.17157 21 9 21H15C17.8284 21 19.2426 21 20.1213 20.1213C21 19.2426 21 17.8284 21 15V13.8558C21 13.0524 21 12.6507 20.7825 12.3773C20.7354 12.3182 20.6818 12.2646 20.6227 12.2175C20.3493 12 19.9476 12 19.1442 12H18.4331C17.7346 12 17.3853 12 17.0908 12.0932C16.5803 12.2548 16.1559 12.6143 15.9126 13.0913C15.7723 13.3665 15.7148 13.711 15.6 14.4L15.6 14.4L15.6 14.4L15.6 14.4001C15.5713 14.5723 15.5569 14.6584 15.5218 14.7272C15.461 14.8464 15.3549 14.9363 15.2273 14.9767C15.1537 15 15.0664 15 14.8917 15H9.10828C8.93365 15 8.84633 15 8.7727 14.9767C8.64508 14.9363 8.53897 14.8464 8.47815 14.7272C8.44306 14.6584 8.42871 14.5723 8.4 14.4C8.28517 13.711 8.22775 13.3665 8.08739 13.0913C7.8441 12.6143 7.4197 12.2548 6.90919 12.0932C6.61466 12 6.26541 12 5.56689 12ZM9.5 17C9.22386 17 9 17.2239 9 17.5C9 17.7761 9.22386 18 9.5 18H14.5C14.7761 18 15 17.7761 15 17.5C15 17.2239 14.7761 17 14.5 17H9.5Z" fill="#222222" />
                </svg>
                {" "}<span>UnArchive Course</span>
              </Dropdown.Item>
            }
            <Dropdown.Item className="cardgrid__dot__dropDownContainer__menu__item" onClick={(e) => handleDelete(data, "Course")}>
              <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.875 0.75C0.676088 0.75 0.485322 0.829018 0.34467 0.96967C0.204018 1.11032 0.125 1.30109 0.125 1.5V2.25C0.125 2.44891 0.204018 2.63968 0.34467 2.78033C0.485322 2.92098 0.676088 3 0.875 3H1.25V9.75C1.25 10.1478 1.40804 10.5294 1.68934 10.8107C1.97064 11.092 2.35218 11.25 2.75 11.25H7.25C7.64782 11.25 8.02936 11.092 8.31066 10.8107C8.59196 10.5294 8.75 10.1478 8.75 9.75V3H9.125C9.32391 3 9.51468 2.92098 9.65533 2.78033C9.79598 2.63968 9.875 2.44891 9.875 2.25V1.5C9.875 1.30109 9.79598 1.11032 9.65533 0.96967C9.51468 0.829018 9.32391 0.75 9.125 0.75H6.5C6.5 0.551088 6.42098 0.360322 6.28033 0.21967C6.13968 0.0790176 5.94891 0 5.75 0L4.25 0C4.05109 0 3.86032 0.0790176 3.71967 0.21967C3.57902 0.360322 3.5 0.551088 3.5 0.75H0.875ZM3.125 3.75C3.22446 3.75 3.31984 3.78951 3.39016 3.85984C3.46049 3.93016 3.5 4.02554 3.5 4.125V9.375C3.5 9.47446 3.46049 9.56984 3.39016 9.64017C3.31984 9.71049 3.22446 9.75 3.125 9.75C3.02554 9.75 2.93016 9.71049 2.85984 9.64017C2.78951 9.56984 2.75 9.47446 2.75 9.375V4.125C2.75 4.02554 2.78951 3.93016 2.85984 3.85984C2.93016 3.78951 3.02554 3.75 3.125 3.75ZM5 3.75C5.09946 3.75 5.19484 3.78951 5.26517 3.85984C5.33549 3.93016 5.375 4.02554 5.375 4.125V9.375C5.375 9.47446 5.33549 9.56984 5.26517 9.64017C5.19484 9.71049 5.09946 9.75 5 9.75C4.90054 9.75 4.80516 9.71049 4.73484 9.64017C4.66451 9.56984 4.625 9.47446 4.625 9.375V4.125C4.625 4.02554 4.66451 3.93016 4.73484 3.85984C4.80516 3.78951 4.90054 3.75 5 3.75ZM7.25 4.125V9.375C7.25 9.47446 7.21049 9.56984 7.14017 9.64017C7.06984 9.71049 6.97446 9.75 6.875 9.75C6.77554 9.75 6.68016 9.71049 6.60983 9.64017C6.53951 9.56984 6.5 9.47446 6.5 9.375V4.125C6.5 4.02554 6.53951 3.93016 6.60983 3.85984C6.68016 3.78951 6.77554 3.75 6.875 3.75C6.97446 3.75 7.06984 3.78951 7.14017 3.85984C7.21049 3.93016 7.25 4.02554 7.25 4.125Z" fill="#FF0000" />
              </svg>
              {" "}<span>Delete</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    )
  }
  const showActivityMenu = (data, phaseData, accessibleAdvisorDatas, accessibleStudent) => {
    const advisorsWithSubscriptions = props?.connectedAdvisors?.filter(advisor => advisor?.subscriptiondetails?.length > 0);
    const isSubscribedAdvisor = advisorsWithSubscriptions?.some(advisor =>
      accessibleAdvisorDatas?.some(subscribedAdvisor => (subscribedAdvisor?.userInfo?.id || subscribedAdvisor?.id) === advisor?.userInfo?.id)
    );
    return (
      <div className="cardgrid__dot">
        <Dropdown className='cardgrid__dot__dropDownContainer'>
          <Dropdown.Toggle id="dropdown-basic">
            <svg width="16" height="6" viewBox="0 0 16 4" fill="#fff" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M1.6 3.2C0.716344 3.2 0 2.48366 0 1.6C0 0.716344 0.716344 0 1.6 0C2.48366 0 3.2 0.716344 3.2 1.6C3.2 2.48366 2.48366 3.2 1.6 3.2ZM7.99844 3.2C7.11478 3.2 6.39844 2.48366 6.39844 1.6C6.39844 0.716344 7.11478 0 7.99844 0C8.88209 0 9.59844 0.716344 9.59844 1.6C9.59844 2.48366 8.88209 3.2 7.99844 3.2ZM12.8 1.6C12.8 2.48366 13.5163 3.2 14.4 3.2C15.2836 3.2 16 2.48366 16 1.6C16 0.716344 15.2836 0 14.4 0C13.5163 0 12.8 0.716344 12.8 1.6Z" />
            </svg>
          </Dropdown.Toggle>
          <Dropdown.Menu className='cardgrid__dot__dropDownContainer__menu'>
            <Dropdown.Item className="cardgrid__dot__dropDownContainer__menu__item" onClick={(e) => handleActivityEdit(data, "edit", phaseData)}>
              <svg width="14" height="14" style={{ cursor: 'pointer' }} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_257_4828)"><path d="M8.03363 0.0914958C7.97503 0.0329111 7.89555 0 7.81269 0C7.72983 0 7.65036 0.0329111 7.59175 0.0914958L6.56238 1.12087L8.87925 3.43775L9.90863 2.409C9.93773 2.37997 9.96082 2.34548 9.97657 2.30752C9.99233 2.26955 10.0004 2.22885 10.0004 2.18775C10.0004 2.14664 9.99233 2.10594 9.97657 2.06797C9.96082 2.03001 9.93773 1.99552 9.90863 1.9665L8.03363 0.0914958V0.0914958ZM8.43738 3.87962L6.1205 1.56275L2.058 5.62525H2.18738C2.27026 5.62525 2.34974 5.65817 2.40835 5.71677C2.46695 5.77538 2.49988 5.85487 2.49988 5.93775V6.25025H2.81238C2.89526 6.25025 2.97474 6.28317 3.03335 6.34177C3.09195 6.40038 3.12488 6.47987 3.12488 6.56275V6.87525H3.43738C3.52026 6.87525 3.59974 6.90817 3.65835 6.96677C3.71695 7.02538 3.74988 7.10487 3.74988 7.18775V7.50025H4.06238C4.14526 7.50025 4.22474 7.53317 4.28335 7.59177C4.34195 7.65038 4.37488 7.72987 4.37488 7.81275V7.94212L8.43738 3.87962V3.87962ZM3.76988 8.54712C3.75673 8.51215 3.74995 8.47511 3.74988 8.43775V8.12525H3.43738C3.3545 8.12525 3.27501 8.09232 3.21641 8.03372C3.1578 7.97511 3.12488 7.89563 3.12488 7.81275V7.50025H2.81238C2.7295 7.50025 2.65001 7.46732 2.59141 7.40872C2.5328 7.35011 2.49988 7.27063 2.49988 7.18775V6.87525H2.18738C2.1045 6.87525 2.02501 6.84232 1.96641 6.78372C1.9078 6.72511 1.87488 6.64563 1.87488 6.56275V6.25025H1.56238C1.52502 6.25018 1.48797 6.24341 1.453 6.23025L1.34113 6.3415C1.31135 6.37149 1.28796 6.40721 1.27238 6.4465L0.0223788 9.5715C-0.000352871 9.62829 -0.00591756 9.6905 0.0063746 9.75042C0.0186668 9.81034 0.0482752 9.86534 0.0915292 9.9086C0.134783 9.95185 0.189781 9.98146 0.249703 9.99375C0.309626 10.006 0.371839 10.0005 0.428629 9.97775L3.55363 8.72775C3.59292 8.71217 3.62864 8.68878 3.65863 8.659L3.76988 8.54775V8.54712Z" fill="#1C84EE" /></g><defs><clipPath id="clip0_257_4828"><rect width="10" height="10" fill="white" /></clipPath></defs></svg>
              {" "}<span>Edit</span>
            </Dropdown.Item>
            {(!data?.isArchived && !isSubscribedAdvisor) &&
              <Dropdown.Item className="cardgrid__dot__dropDownContainer__menu__item" onClick={(e) => handleArchieveactivity(data)}>
                <svg width="14" height="14" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3 10.5947C3 7.7663 3 6.35209 3.87868 5.47341C4.75736 4.59473 6.17157 4.59473 9 4.59473H15C17.8284 4.59473 19.2426 4.59473 20.1213 5.47341C21 6.35209 21 7.7663 21 10.5947C21 13.4232 21 14.8374 20.1213 15.716C19.2426 16.5947 17.8284 16.5947 15 16.5947H9C6.17157 16.5947 4.75736 16.5947 3.87868 15.716C3 14.8374 3 13.4232 3 10.5947Z" fill="#7E869E" fill-opacity="0.25" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M5.56689 12.5947H4.8558C4.05238 12.5947 3.65068 12.5947 3.37733 12.8122C3.31819 12.8593 3.26457 12.9129 3.21751 12.9721C3 13.2454 3 13.6471 3 14.4505V15.5947C3 18.4232 3 19.8374 3.87868 20.716C4.75736 21.5947 6.17157 21.5947 9 21.5947H15C17.8284 21.5947 19.2426 21.5947 20.1213 20.716C21 19.8374 21 18.4232 21 15.5947V14.4505C21 13.6471 21 13.2454 20.7825 12.9721C20.7354 12.9129 20.6818 12.8593 20.6227 12.8122C20.3493 12.5947 19.9476 12.5947 19.1442 12.5947H18.4331C17.7346 12.5947 17.3853 12.5947 17.0908 12.6879C16.5803 12.8495 16.1559 13.209 15.9126 13.686C15.7723 13.9612 15.7148 14.3057 15.6 14.9947L15.6 14.9947L15.6 14.9947L15.6 14.9948C15.5713 15.167 15.5569 15.2531 15.5218 15.3219C15.461 15.4412 15.3549 15.531 15.2273 15.5714C15.1537 15.5947 15.0664 15.5947 14.8917 15.5947H9.10828C8.93365 15.5947 8.84633 15.5947 8.7727 15.5714C8.64508 15.531 8.53897 15.4412 8.47815 15.3219C8.44306 15.2531 8.42871 15.167 8.4 14.9947C8.28517 14.3057 8.22775 13.9612 8.08739 13.686C7.8441 13.209 7.4197 12.8495 6.90919 12.6879C6.61466 12.5947 6.26541 12.5947 5.56689 12.5947ZM9.5 17.5947C9.22386 17.5947 9 17.8186 9 18.0947C9 18.3709 9.22386 18.5947 9.5 18.5947H14.5C14.7761 18.5947 15 18.3709 15 18.0947C15 17.8186 14.7761 17.5947 14.5 17.5947H9.5Z" fill="#222222" />
                </svg>
                {" "}<span>Archive Activity</span>
              </Dropdown.Item>
            }
            {(data?.isArchived && !isSubscribedAdvisor) &&
              <Dropdown.Item className="cardgrid__dot__dropDownContainer__menu__item" onClick={(e) => handleUnArchieveactivity(data)}>
                <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3 10C3 7.17157 3 5.75736 3.87868 4.87868C4.75736 4 6.17157 4 9 4H15C17.8284 4 19.2426 4 20.1213 4.87868C21 5.75736 21 7.17157 21 10C21 12.8284 21 14.2426 20.1213 15.1213C19.2426 16 17.8284 16 15 16H9C6.17157 16 4.75736 16 3.87868 15.1213C3 14.2426 3 12.8284 3 10Z" fill="#7E869E" fill-opacity="0.25" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.56689 12H4.8558C4.05238 12 3.65068 12 3.37733 12.2175C3.31819 12.2646 3.26457 12.3182 3.21751 12.3773C3 12.6507 3 13.0524 3 13.8558V15C3 17.8284 3 19.2426 3.87868 20.1213C4.75736 21 6.17157 21 9 21H15C17.8284 21 19.2426 21 20.1213 20.1213C21 19.2426 21 17.8284 21 15V13.8558C21 13.0524 21 12.6507 20.7825 12.3773C20.7354 12.3182 20.6818 12.2646 20.6227 12.2175C20.3493 12 19.9476 12 19.1442 12H18.4331C17.7346 12 17.3853 12 17.0908 12.0932C16.5803 12.2548 16.1559 12.6143 15.9126 13.0913C15.7723 13.3665 15.7148 13.711 15.6 14.4L15.6 14.4L15.6 14.4L15.6 14.4001C15.5713 14.5723 15.5569 14.6584 15.5218 14.7272C15.461 14.8464 15.3549 14.9363 15.2273 14.9767C15.1537 15 15.0664 15 14.8917 15H9.10828C8.93365 15 8.84633 15 8.7727 14.9767C8.64508 14.9363 8.53897 14.8464 8.47815 14.7272C8.44306 14.6584 8.42871 14.5723 8.4 14.4C8.28517 13.711 8.22775 13.3665 8.08739 13.0913C7.8441 12.6143 7.4197 12.2548 6.90919 12.0932C6.61466 12 6.26541 12 5.56689 12ZM9.5 17C9.22386 17 9 17.2239 9 17.5C9 17.7761 9.22386 18 9.5 18H14.5C14.7761 18 15 17.7761 15 17.5C15 17.2239 14.7761 17 14.5 17H9.5Z" fill="#222222" />
                </svg>
                {" "}<span>UnArchive Activity</span>
              </Dropdown.Item>
            }
            <Dropdown.Item className="cardgrid__dot__dropDownContainer__menu__item" onClick={(e) => handleDelete(data, "Activity")}>
              <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.875 0.75C0.676088 0.75 0.485322 0.829018 0.34467 0.96967C0.204018 1.11032 0.125 1.30109 0.125 1.5V2.25C0.125 2.44891 0.204018 2.63968 0.34467 2.78033C0.485322 2.92098 0.676088 3 0.875 3H1.25V9.75C1.25 10.1478 1.40804 10.5294 1.68934 10.8107C1.97064 11.092 2.35218 11.25 2.75 11.25H7.25C7.64782 11.25 8.02936 11.092 8.31066 10.8107C8.59196 10.5294 8.75 10.1478 8.75 9.75V3H9.125C9.32391 3 9.51468 2.92098 9.65533 2.78033C9.79598 2.63968 9.875 2.44891 9.875 2.25V1.5C9.875 1.30109 9.79598 1.11032 9.65533 0.96967C9.51468 0.829018 9.32391 0.75 9.125 0.75H6.5C6.5 0.551088 6.42098 0.360322 6.28033 0.21967C6.13968 0.0790176 5.94891 0 5.75 0L4.25 0C4.05109 0 3.86032 0.0790176 3.71967 0.21967C3.57902 0.360322 3.5 0.551088 3.5 0.75H0.875ZM3.125 3.75C3.22446 3.75 3.31984 3.78951 3.39016 3.85984C3.46049 3.93016 3.5 4.02554 3.5 4.125V9.375C3.5 9.47446 3.46049 9.56984 3.39016 9.64017C3.31984 9.71049 3.22446 9.75 3.125 9.75C3.02554 9.75 2.93016 9.71049 2.85984 9.64017C2.78951 9.56984 2.75 9.47446 2.75 9.375V4.125C2.75 4.02554 2.78951 3.93016 2.85984 3.85984C2.93016 3.78951 3.02554 3.75 3.125 3.75ZM5 3.75C5.09946 3.75 5.19484 3.78951 5.26517 3.85984C5.33549 3.93016 5.375 4.02554 5.375 4.125V9.375C5.375 9.47446 5.33549 9.56984 5.26517 9.64017C5.19484 9.71049 5.09946 9.75 5 9.75C4.90054 9.75 4.80516 9.71049 4.73484 9.64017C4.66451 9.56984 4.625 9.47446 4.625 9.375V4.125C4.625 4.02554 4.66451 3.93016 4.73484 3.85984C4.80516 3.78951 4.90054 3.75 5 3.75ZM7.25 4.125V9.375C7.25 9.47446 7.21049 9.56984 7.14017 9.64017C7.06984 9.71049 6.97446 9.75 6.875 9.75C6.77554 9.75 6.68016 9.71049 6.60983 9.64017C6.53951 9.56984 6.5 9.47446 6.5 9.375V4.125C6.5 4.02554 6.53951 3.93016 6.60983 3.85984C6.68016 3.78951 6.77554 3.75 6.875 3.75C6.97446 3.75 7.06984 3.78951 7.14017 3.85984C7.21049 3.93016 7.25 4.02554 7.25 4.125Z" fill="#FF0000" />
              </svg>
              {" "}<span>Delete</span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    )
  }
  const OpenRejectpopup = (e, data) => {
    e.preventDefault();
    setplandata(data)
    setshowreject(true)
  }
  const acceptCourse = (e, data) => {
    e.preventDefault();
    if (!data?.isApproved) {
      let payload = {
        phaseId: data?.phaseId,
        planId: data?.planId
      }
      props?.courseApprove(data?.id, payload)
    }
  }
  const Cloaseplanrejectpopup = () => {
    setshowreject(false)
  }
  const RejectCourse = (data) => {
    if (!data?.isApproved) {
      setloading(true)
      let payload = {
        phaseId: data?.phaseId,
        planId: data?.planId
      }
      props?.courseReject(data?.id, payload)
    }
  }
  const acceptDropCourse = (e, data) => {
    e.preventDefault();
    setloading(true)
    let payload = {
      "courseId": data?.id,
      "phaseId": data?.phaseId,
      "planId": data?.planId,
      "status": true
    }
    props?.courseDropConfirmation(payload)
  }

  const rejectDropCourse = (e, data) => {
    e.preventDefault();
    setloading(true)
    let payload = {
      "courseId": data?.id,
      "phaseId": data?.phaseId,
      "planId": data?.planId,
      "status": false
    }
    props?.courseDropConfirmation(payload)
  }
  const acceptDropActivity = (e, data) => {
    e.preventDefault();
    setloading(true)
    let payload = {
      "activityId": data?.id,
      "phaseId": data?.phaseId,
      "planId": data?.planId,
      "status": true
    }
    props?.activityDropConfirmation(payload)
  }

  const rejectDropActivity = (e, data) => {
    e.preventDefault();
    setloading(true)
    let payload = {
      "activityId": data?.id,
      "phaseId": data?.phaseId,
      "planId": data?.planId,
      "status": false
    }
    props?.activityDropConfirmation(payload)
  }
  const OpenRejectActivitypopup = (e, data) => {
    e.preventDefault();
    setplandata(data)
    setshowActivityReject(true)
  }
  const CloaseActivtyrejectpopup = () => {
    setshowreject(false)
    setshowActivityReject(false)
  }
  const acceptActivity = (e, data) => {
    e.preventDefault();
    setloading(true)
    let payload = {
      phaseId: data?.phaseId,
      planId: data?.planId,
    }
    props?.activitiesApprove(data?.id, payload)
  }
  const rejectActivity = (data) => {
    setloading(true)
    let payload = {
      phaseId: data?.phaseId,
      planId: data?.planId,
    }
    props?.activitiesReject(data?.id, payload)
  }
  const handleNavigate = (data) => {
    if (Newworks) {
      navigate(`courseMilestone/${data?.id}`, { state: { courseData: data, studentView: true } })
    }
    else {
      navigate(`courseMilestone/${data?.id}`, { state: { courseData: data } })
    }
  }
  const handleNavigateActivity = (data) => {
    if (Newworks) {
      navigate(`activityMilestone/${data?.id}`, { state: { activityData: data, studentView: true } })
    }
    else {
      navigate(`activityMilestone/${data?.id}`, { state: { activityData: data } })
    }
  }

  const AvatarWithUrl = ({ avatarUrl, onClick }) => {
    const imageUrl = useImageUrl(avatarUrl);
    return <Avatar alt={imageUrl} src={imageUrl} onClick={onClick} />;
  };
  const OpenBookSession = (e, data, value) => {
    props?.clearMessage()
    e.preventDefault()
    setAdvisorid(data[0]?.userdata?.userInfo?.id)
    if (value === "Paid") {
      setPaidSession(true ?? null)
    }
    const matchingAdvisor = props?.connectedAdvisors?.find(advisor => advisor?.userInfo?.id === data[0]?.userdata?.userInfo?.id);
    setAdvisorName({ label: data[0]?.userdata?.userInfo?.fullName, value: data[0]?.userdata?.userInfo?.id })
    if (matchingAdvisor) {
      setTimeSession(matchingAdvisor)
      setbookshow(true)
    }
  }

  const CloseBookSession = () => {
    setPaidSession(null)
    setbookshow(false)
  }

  const SessionBook = (data, value) => {
    const moment = require('moment');
    const SelectHours = value
    const selectedDate = moment(data.Selectdate, "YYYY-MM-DD").format("YYYY-MM-DD");
    const startTime = SelectHours.split(' - ')[0];
    const formattedDate = moment(`${selectedDate} ${startTime}`, "YYYY-MM-DD HH:mm A").format("YYYY-MM-DD HH:mm:ss");
    const [startTimes, endTime] = SelectHours.split(' - ');
    const parsedStartTime = moment(startTimes, 'h:mm A');
    const parsedEndTime = moment(endTime, 'h:mm A');
    const timeDiffInMillis = parsedEndTime.diff(parsedStartTime);
    const timeDiffInMinutes = moment.duration(timeDiffInMillis).asMinutes();

    let payload = {
      "advisorId": advisorid,
      "eventTime": formattedDate,
      "eventDuration": timeDiffInMinutes,
      "meetingLocation": data?.Location,
      "meetingDescription": data?.Description,
      "studentId": localStorage.getItem("parentViaChild"),
      "password": data?.password
    }
    if (data?.package) {
      payload["packageId"] = data?.package?.value;
      props?.bookPaidSession(payload)
    } else {
      props?.bookSession(payload)

    }
  }


  return (
    <>
      {bookshow && <BookSessionPopup show={bookshow} paidSession={paidSession} selectedAdvisor={advisorName} advisorList={props?.connectedAdvisors} onHide={CloseBookSession} Time={TimeSession} HandleSumit={SessionBook} />}
      {activityReject && <Rejectcourse show={activityReject} onHide={CloaseActivtyrejectpopup} data={plandata} title={"Reject Activity"} RejectPlans={(data) => rejectActivity(data)} />}
      {showreject && <Rejectcourse show={showreject} onHide={Cloaseplanrejectpopup} data={plandata} title={"Reject Course"} AcceptPlans={(data) => RejectCourse(data)} />}
      {addcourse && <AddCourse show={addcourse} EditData={editCourse} semesterData={semesterData} handleClose={Coursepopuphide} handleSubmit={handleCourseSubmit} SemsterAdd={true} />}
      {addactivity && <AddActivity show={addactivity} EditData={editCourse} semesterData={semesterData} handleClose={Activitypopuphide} handleSubmit={handleActivitySubmit} SemsterAdd={true} />}
      {showadvisor && <ManageAdvisorPopup show={showadvisor} onHide={CloseMangeAdvisor} data={plandata} text={detaildata} globalAdvisor={globalAdvisor ?? null} />}
      {showstudent && <ManageStudentPopup show={showstudent} onHide={CloseManageStudents} data={plandata} detaildata={detaildata} />}
      {loading && <Loader />}
      <div className="semesterCourseSec">
        <div className="semesterCourseSec__topSec">
          <p className='semesterCourseSec__topSec__title'>Courses</p>
          {!Newworks &&
            <div className="semesterCourseSec__topSec__primaryBtn">
              <Tooltip title={"Add Course"} placement="top" id='addcourse'>
                <AddOutlinedIcon className='semesterCourseSec__topSec__primaryBtn__icon' onClick={() => Coursepopupshow(props?.phaseData)} id='addcoursebtn' />
              </Tooltip>
            </div>
          }
        </div>
        <div className="semesterCourseSec__content" style={props?.phaseData?.courses?.length === 0 ? { "justifyContent": "center" } : {}}>
          {props?.phaseData?.courses?.length > 0 ? (
            <>
              {props?.phaseData?.courses?.map((data, index) => {
                let accessibleGlobalAdvisors = props?.subscribedAdvisor?.filter(access => access?.accessLevel === "global");
                let accessibleCourseAdvisors = props?.subscribedAdvisor?.filter(access => access?.courseIds?.includes(data?.id));
                let allAccessibleAdvisors = [...accessibleGlobalAdvisors, ...accessibleCourseAdvisors];
                let uniqueAccessibleAdvisors = allAccessibleAdvisors?.filter((advisor, index, self) => index === self?.findIndex((a) => a?.userId === advisor?.userId));
                let accessibleAdvisorDatas = props?.connectedAdvisors?.filter(data => uniqueAccessibleAdvisors?.some(advisor => advisor?.userId === data?.userInfo?.id));
                const avatars = accessibleAdvisorDatas?.map(connectedAdvisor => {
                  const avatarUrl = connectedAdvisor?.userInfo?.avatarPath;
                  const fullName = connectedAdvisor?.userInfo?.fullName;
                  const subscriptionDetails = connectedAdvisor?.subscriptiondetails;
                  const userInfo = connectedAdvisor?.userInfo;
                  const userdata = connectedAdvisor;
                  const displayValue = {
                    avatarUrl: avatarUrl || null,
                    fullName: fullName || null,
                    subscriptionDetails: subscriptionDetails || null,
                    userInfo: userInfo || null,
                    userdata: userdata || null,
                  };
                  return displayValue;
                });

                const matchingViewCounts = props?.updatesStudentViewCount?.recordInfo?.courses?.filter(
                  (update) => update.courseId === data?.id
                );
                console.log(props?.updatesStudentViewCount, "matchingViewCountsmatchingViewCounts");
                let accessibleStudent = props?.subscribedStudent?.filter(access => access?.courseIds?.includes(data?.id))?.map(data => data?.userId);
                return (
                  <>
                    <CourseCard key={data?.id}
                      courseName={data?.courseName}
                      courseType={data?.courseType}
                      description={data?.description}
                      semester={props?.phasename}
                      menu={!Newworks && (data?.isApproved && !data?.isDropsuggested) ? showCourseMenu(data, props?.phaseData, accessibleAdvisorDatas, accessibleStudent) : undefined}
                      isApproved={data?.isApproved}
                      isDropsuggested={data?.isDropsuggested}
                      isPassedSem={props?.phaseData?.isPassed}
                      suggestionData={getSuggestionDetail(data?.isApproved, data?.isDropsuggested, 'Course')}
                      planName={props?.planName}
                      isArchived={data?.isArchived ?? false}
                    >
                      {!Newworks &&
                        <>
                          {(!data?.isApproved) &&
                            <div className="myWork-planCard__btnSection">
                              <div className="myWork-planCard__btnSection__Cnt">
                                <div className="myWork-planCard__btnSection__Cnt__secondary-btn" onClick={(e) => OpenRejectpopup(e, data)} id={`OpenRejectpopup ${index}`}>
                                  Reject
                                </div>
                                <div className="myWork-planCard__btnSection__Cnt__primary-btn" onClick={(e) => acceptCourse(e, data)} id={`acceptCoursebtn ${index}`}>
                                  Accept
                                </div>
                              </div>
                            </div>
                          }
                          {(data?.isApproved && data?.isDropsuggested) &&
                            <div className="myWork-planCard__btnSection__Cnt">
                              <div className="myWork-planCard__btnSection__Cnt__secondary-btn" onClick={(e) => rejectDropCourse(e, data)} id={`rejectDropCoursebtn ${index}`}>
                                Reject
                              </div>
                              <div className="myWork-planCard__btnSection__Cnt__primary-btn" onClick={(e) => acceptDropCourse(e, data)} id={`acceptDropCoursebtn ${index}`}>
                                Accept
                              </div>
                            </div>
                          }
                          {(data?.isApproved && !data?.isDropsuggested) &&
                            <div className="myWork-courseCard__ctaSec__primaryBtnSec">
                              {matchingViewCounts && matchingViewCounts[0] && <p className='myWork-courseCard__ctaSec__primaryBtnSec__helperText'>  {matchingViewCounts[0]?.milestoneCount} Milestones, {matchingViewCounts && matchingViewCounts[0]?.updateCount} milestone updates</p>}
                              {/* <div className="myWork-courseCard__ctaSec__primaryBtnSec__primaryBtn" onClick={(e) => handleNavigate(data)} id={`CourseViewmilestonebtn ${index}`} >
                                View Milestone
                              </div> */}
                              <div className="mt-3" style={{ width: "85%", display: "flex", justifyContent: "center", position: "relative" }}>
                                <div style={{ width: "100%", position: "relative" }}>
                                  <div
                                    className="myWork-courseCard__ctaSec__primaryBtnSec__primaryBtn"
                                    onClick={(e) => handleNavigate(data)} id={`CourseViewmilestonebtn ${index}`}
                                    style={{ width: "100%", position: "relative", padding: "10px" }} // Full width button
                                  >
                                    View Milestone
                                  </div>
                                  {console.log(matchingViewCounts, "fjdfhdjfhdjfh")}

                                  <Badge
                                    badgeContent={matchingViewCounts && matchingViewCounts[0]?.updateCount}
                                    color="error"
                                    style={{ position: "absolute", right: "5px", top: "0px" }} // Position badge at the top-right
                                  />
                                </div>
                              </div>
                            </div>
                          }
                          {!data?.isArchived &&
                            <>
                              {(data?.isApproved && !data?.isDropsuggested) &&
                                <>
                                  <div className="myWork-courseCard__ctaSec__ManageAdvisorBtnSec mt-2">
                                    <div style={avatars.length > 0 ? { "display": "flex", "gap": "10px", "width": "85%", "alignItems": "end" } : { "width": "85%" }}>
                                      <AvatarGroup max={2} style={{ "marginRight": "10px" }}>
                                        {avatars.map((avatar, index) => (
                                          <AvatarWithUrl key={index} avatarUrl={avatar?.avatarUrl} fullName={avatar?.fullName} onClick={avatars?.length === 1 ? (e) => OpenMangeAdvisor(e, data, "Course", accessibleGlobalAdvisors) : undefined} />
                                        ))}
                                      </AvatarGroup>
                                      {avatars.length !== 1 && props?.connectedAdvisors?.length > 0 &&
                                        <div className="myWork-courseCard__ctaSec__ManageAdvisorBtnSec__outlineBtn" onClick={(e) => OpenMangeAdvisor(e, data, "Course", accessibleGlobalAdvisors)}
                                          style={{ width: avatars.length > 0 ? "90%" : "100%", "fontSize": avatars.length > 0 ? "15px" : "16px", "paddingInline": avatars.length > 0 ? "0em" : "1em", "marginTop": "20px" }}
                                          id={`CourseManageAdvisorsbtn ${index}`} >
                                          Manage Advisors
                                        </div>
                                      }
                                      {avatars.length === 1 &&
                                        <>
                                          {avatars[0]?.subscriptionDetails?.length > 0 ? (
                                            <div id={`bookpaid ${index}`} onClick={(e) => OpenBookSession(e, avatars, "Paid")} className="myWork-planCard__ctaSection__outline-btn" style={{ width: avatars.length > 0 ? "90%" : "100%", "fontSize": avatars.length > 0 ? "15px" : "16px", "paddingInline": avatars.length > 0 ? "0em" : "1em", }}>
                                              Book paid Session
                                            </div>
                                          ) : (
                                            <div id={`bookfree ${index}`} onClick={(e) => OpenBookSession(e, avatars)} className="myWork-planCard__ctaSection__outline-btn" style={{ width: avatars.length > 0 ? "90%" : "100%", "fontSize": avatars.length > 0 ? "15px" : "16px", "paddingInline": avatars.length > 0 ? "0em" : "1em", }}>
                                              Book free Session
                                            </div>
                                          )}
                                        </>
                                      }
                                    </div>
                                  </div>
                                  <div className="myWork-courseCard__ctaSec__ManageStudentBtnSec">
                                    <p onClick={(e) => OpenManageStudents(e, data, "Course")} id={`CourseManageStudentbtn ${index}`} >{accessibleStudent ? accessibleStudent?.length + " " + 'students have access' : 'No students have access'}</p>
                                  </div>
                                </>
                              }
                            </>
                          }
                        </>
                      }
                      {Newworks &&
                        <div className="myWork-courseCard__ctaSec__primaryBtnSec">
                          <div className="myWork-courseCard__ctaSec__primaryBtnSec__primaryBtn" onClick={(e) => handleNavigate(data)} id={`CourseViewmilestonebtn ${index}`} >
                            View Milestone
                          </div>
                        </div>
                      }
                    </CourseCard>
                  </>
                )
              })}
            </>
          ) : (
            <div className="semesterCourseSec__noDataSec">
              <div className="semesterCourseSec__noDataSec__noDataCard">
                <p className="semesterCourseSec__noDataSec__noDataCard__textContent">
                  No Courses Available
                </p>
              </div>
            </div>
          )}
        </div>

      </div>
      <div className="semesterCourseSec">
        <div className="semesterCourseSec__topSec">
          <p className='semesterCourseSec__topSec__title'>Activities</p>
          {!Newworks &&
            <div className="semesterCourseSec__topSec__primaryBtn">
              <Tooltip title={"Add Activity"} placement="top" id='addactivity'>
                <AddOutlinedIcon className='semesterCourseSec__topSec__primaryBtn__icon' onClick={() => Activitypopupshow(props?.phaseData)} id='addactivitybtn' />
              </Tooltip>
            </div>
          }
        </div>
        <div className="semesterCourseSec__content" style={props?.phaseData?.activities?.length === 0 ? { "justifyContent": "center" } : {}}>
          {props?.phaseData?.activities?.length > 0 ? (
            <>
              {props?.phaseData?.activities?.map((data, index) => {
                const matchingViewCounts = props?.updatesStudentViewCount?.recordInfo?.courses?.filter(
                  (update) => update.courseId === data?.id
                );
                const matchingViewActivityCounts = props?.updatesStudentViewCount?.recordInfo?.activities?.filter(
                  (update) => update.activityId === data?.id
                );
                console.log(matchingViewCounts, "matchingViewCounts", props?.updatesStudentViewCount);

                let accessibleGlobalAdvisors = props?.subscribedAdvisor?.filter(access => access?.accessLevel === "global");
                let accessibleActivityAdvisors = props?.subscribedAdvisor?.filter(access => access?.activityIds?.includes(data?.id));
                let allAccessibleAdvisors = [...accessibleGlobalAdvisors, ...accessibleActivityAdvisors];
                // Ensure unique advisors by userId
                let uniqueAccessibleAdvisors = allAccessibleAdvisors?.filter((advisor, index, self) =>
                  index === self?.findIndex((a) => a?.userId === advisor?.userId)
                );

                // Fetch the advisor data based on unique accessible advisors
                let accessibleAdvisorDatas = props?.connectedAdvisors?.filter(data =>
                  uniqueAccessibleAdvisors?.some(advisor => advisor?.userId === data?.userInfo?.id)
                );

                // Map to desired avatar display values
                const avatars = accessibleAdvisorDatas?.map(connectedAdvisor => {
                  const avatarUrl = connectedAdvisor?.userInfo?.avatarPath;
                  const fullName = connectedAdvisor?.userInfo?.fullName;
                  const subscriptionDetails = connectedAdvisor?.subscriptiondetails;
                  const userInfo = connectedAdvisor?.userInfo;
                  const userdata = connectedAdvisor;
                  const displayValue = {
                    avatarUrl: avatarUrl || null,
                    fullName: fullName || null,
                    subscriptionDetails: subscriptionDetails || null,
                    userInfo: userInfo || null,
                    userdata: userdata || null,
                  };
                  return displayValue;
                });

                // Get accessible students for the current activity
                let accessibleStudent = props?.subscribedStudent?.filter(access =>
                  access?.activityIds?.includes(data?.id)
                )?.map(data => data?.userId);

                return (
                  <ActivityCard key={data?.id}
                    activityName={data?.activityName}
                    menu={!Newworks && (data?.isApproved && !data?.isDropsuggested) ? showActivityMenu(data, props?.phaseData, accessibleAdvisorDatas, accessibleStudent) : undefined} activityType={data?.activityType}
                    description={data?.description}
                    semester={props?.phaseData?.title}
                    isApproved={data?.isApproved}
                    isDropsuggested={data?.isDropsuggested}
                    isPassedSem={props?.phaseData?.isPassed}
                    suggestionData={getSuggestionDetail(data?.isApproved, data?.isDropsuggested, 'Activity')}
                    planName={props?.planName}
                    isArchived={data?.isArchived ?? false}
                  >
                    {!Newworks &&
                      <>
                        {(!data?.isApproved) &&
                          <div className="myWork-planCard__btnSection">
                            <div className="myWork-planCard__btnSection__Cnt">
                              <div className="myWork-planCard__btnSection__Cnt__secondary-btn" onClick={(e) => OpenRejectActivitypopup(e, data)} id={`RejectActivitypopup ${index}`}>
                                Reject
                              </div>
                              <div className="myWork-planCard__btnSection__Cnt__primary-btn" onClick={(e) => acceptActivity(e, data)} id={`acceptActivitybtn ${index}`}>
                                Accept
                              </div>
                            </div>
                          </div>
                        }
                        {(data?.isApproved && data?.isDropsuggested) &&
                          <div className="myWork-planCard__btnSection__Cnt">
                            <div className="myWork-planCard__btnSection__Cnt__secondary-btn" onClick={(e) => rejectDropActivity(e, data)} id={`rejectDropActivitybtn ${index}`}>
                              Reject
                            </div>
                            <div className="myWork-planCard__btnSection__Cnt__primary-btn" onClick={(e) => acceptDropActivity(e, data)} id={`acceptDropActivitybtn ${index}`}>
                              Accept
                            </div>
                          </div>
                        }
                        {(data?.isApproved && !data?.isDropsuggested) &&
                          <div className="myWork-courseCard__ctaSec__primaryBtnSec">
                            {matchingViewCounts && matchingViewCounts[0] && <p className='myWork-courseCard__ctaSec__primaryBtnSec__helperText'>  {matchingViewCounts[0]?.milestoneCount} Milestones, {matchingViewCounts && matchingViewCounts[0]?.updateCount} milestone updates</p>}

                            <div className="mt-3" style={{ width: "85%", display: "flex", justifyContent: "center", position: "relative" }}>
                              <div style={{ width: "100%", position: "relative" }}>
                                <div
                                  className="myWork-courseCard__ctaSec__primaryBtnSec__primaryBtn"
                                  onClick={(e) => handleNavigateActivity(data)} id={`ViewMilestonebtn ${index}`}
                                  style={{ width: "100%", position: "relative", padding: "10px" }} // Full width button
                                >
                                  View Milestone
                                </div>

                                <Badge
                                  badgeContent={matchingViewActivityCounts && matchingViewActivityCounts[0]?.updateCount}
                                  color="error"
                                  style={{ position: "absolute", right: "5px", top: "0px" }} // Position badge at the top-right
                                />
                              </div>
                            </div>
                          </div>
                        }
                        {!data?.isArchived &&
                          <>
                            {(data?.isApproved && !data?.isDropsuggested) &&
                              <>
                                <div className="myWork-courseCard__ctaSec__ManageAdvisorBtnSec mt-2">
                                  <div style={avatars.length > 0 ? { "display": "flex", "gap": "10px", "width": "85%", "alignItems": "end" } : { "width": "85%" }}>
                                    <AvatarGroup max={2} style={{ "marginRight": "10px" }}>
                                      {avatars.map((avatar, index) => (
                                        <AvatarWithUrl key={index} avatarUrl={avatar?.avatarUrl} fullName={avatar?.fullName} onClick={avatars?.length === 1 ? (e) => OpenMangeAdvisor(e, data, "Activity", accessibleGlobalAdvisors) : undefined} />
                                      ))}
                                    </AvatarGroup>
                                    {avatars.length !== 1 && props?.connectedAdvisors?.length > 0 &&
                                      <div className="myWork-courseCard__ctaSec__ManageAdvisorBtnSec__outlineBtn" onClick={(e) => OpenMangeAdvisor(e, data, "Activity", accessibleGlobalAdvisors)}
                                        style={{ width: avatars.length > 0 ? "90%" : "100%", "fontSize": avatars.length > 0 ? "15px" : "16px", "paddingInline": avatars.length > 0 ? "0em" : "1em", "marginTop": "20px" }}
                                        id={`CourseManageAdvisorsbtn ${index}`} >
                                        Manage Advisors
                                      </div>
                                    }
                                    {avatars.length === 1 &&
                                      <>
                                        {avatars[0]?.subscriptionDetails?.length > 0 ? (
                                          <div id={`bookpaid ${index}`} onClick={(e) => OpenBookSession(e, avatars, "Paid")} className="myWork-planCard__ctaSection__outline-btn" style={{ width: avatars.length > 0 ? "90%" : "100%", "fontSize": avatars.length > 0 ? "15px" : "16px", "paddingInline": avatars.length > 0 ? "0em" : "1em", }}>
                                            Book paid Session
                                          </div>
                                        ) : (
                                          <div id={`bookfree ${index}`} onClick={(e) => OpenBookSession(e, avatars)} className="myWork-planCard__ctaSection__outline-btn" style={{ width: avatars.length > 0 ? "90%" : "100%", "fontSize": avatars.length > 0 ? "15px" : "16px", "paddingInline": avatars.length > 0 ? "0em" : "1em", }}>
                                            Book free Session
                                          </div>
                                        )}
                                      </>
                                    }
                                  </div>
                                </div>
                                <div className="myWork-courseCard__ctaSec__ManageStudentBtnSec">
                                  <p onClick={(e) => OpenManageStudents(e, data, "Activity")} id={`CourseManageStudentbtn ${index}`} >{accessibleStudent ? accessibleStudent?.length + " " + 'students have access' : 'No students have access'}</p>
                                </div>
                              </>
                            }
                          </>
                        }
                      </>
                    }
                    {Newworks &&
                      <div className="myWork-courseCard__ctaSec__primaryBtnSec">
                        <div className="myWork-courseCard__ctaSec__primaryBtnSec__primaryBtn" onClick={(e) => handleNavigateActivity(data)} id={`CourseViewmilestonebtn ${index}`} >
                          View Milestone
                        </div>
                      </div>
                    }
                  </ActivityCard>
                )
              })}
            </>
          ) : (
            <div className="semesterCourseSec__noDataSec">
              <div className="semesterCourseSec__noDataSec__noDataCard">
                <p className="semesterCourseSec__noDataSec__noDataCard__textContent">
                  No Activities Available
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({ userProfileData, commonData, studentData }) => {
  const { courseNames, course, getphasetime, studentGrades, activity, activityNames, connectedAdvisors, archievedCourse, archievedCourseError, unarchievedCourse, unarchievedCourseError, archievedActivity, archievedActivityError, unarchievedActivity, unarchievedActivityError, connectedAdvisorsStatus,
    sessionBooked, paidSessionBooked, freeSessionStatus, booksessionerror, bookPaidSessionError,
  } = commonData
  const { userProfile } = userProfileData;
  const { getstudentplanphase, getstudentplan, isCourseCreated, isCourseError, isActivityCreated, isActivityError, isEditActivity,
    isEditPhase, isCourseDeleted, isActivityDeleted, isgetplanphase, isEditCourse, isCourseApprove, isCourseRejected,
    courseDropStatus, courseDropError, isActivityAproved, isActivityAprovedError, isactivityRejected, activityDropStatus, activityDropError,
    updatesStudentViewCount, updatesViewCount, isMilestoneDeleted } = studentData;

  return {
    userProfile, getstudentplanphase, getstudentplan, isCourseCreated, isCourseError, isEditCourse, isActivityCreated, isActivityError,
    isEditPhase, isCourseDeleted, isActivityDeleted, courseNames, course, getphasetime, studentGrades, activity, activityNames,
    connectedAdvisors, isgetplanphase, isCourseApprove, isCourseRejected, courseDropStatus, courseDropError,
    isActivityAproved, isActivityAprovedError, isactivityRejected, activityDropStatus, activityDropError, isEditActivity,
    archievedCourse, archievedCourseError, unarchievedCourse, unarchievedCourseError, connectedAdvisorsStatus, bookPaidSessionError,
    archievedActivity, archievedActivityError, unarchievedActivity, unarchievedActivityError, sessionBooked, paidSessionBooked, freeSessionStatus, booksessionerror,
    updatesStudentViewCount, updatesViewCount, isMilestoneDeleted
  };
};

export default connect(mapStateToProps, {
  clearMessage, getCourse, getCourses, getActivities, getActivity, getStudentGrades, EditCourseStatus, DeleteCourseStatus, CreatePlanStatus, EditPlanStatus, getCourse, getCourses, EditActivityStatus, setConnectedAdvisorStatus,
  getActivities, getActivity, CreateCourse, CreateActivity, GetStudentPlanPhase, GetStudentPlan, getStudentGrades, getPhasetime, CreateCourseStatus, CreateCourseErrorStatus, CreateActivityStatus, CreateActivityErrorStatus, activityApproveErrorStatus,
  EditPhaseStatus, DeleteActivityStatus, GetStudentPlanPhaseStatus, EditCourse, EditActivity, GetStudentPlanId, courseApprove, DeleteCourse, DeleteActivity, courseReject, courseRejectStatus, courseApproveStatus, courseDropConfirmation,
  courseDropConfirmationStatus, activityDropConfirmation, activityDropConfirmationStatus, activityDropErrorStatus, activitiesReject, activityRejectStatus, activitiesApprove, activityApproveStatus,
  archiveCourse, archiveCourseStatus, archiveCourseErrorStatus, unArchiveCourse, unArchiveCourseStatus, getConnectedAdvisors, bookPaidSession, bookSession,
  archiveActivity, archiveActivityStatus, archiveActivityErrorStatus, unArchiveActivity, unArchiveActivityStatus, unArchiveActivityError, bookSessionStatus, bookSessionErrorStatus, bookPaidSessionStatus, bookPaidSessionErrorStatus, DeleteMileStoneStatus
})(withRouter(Index));

