import React from 'react'
import "./sentRequestCard.less";
import PersonIcon from '@mui/icons-material/Person';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import StarIcon from '@mui/icons-material/Star';
import PillSection from "../../../PillSection";
import { connect } from 'react-redux';
import { useImageUrl } from '../../../../../utils/UseImageURL';

const Index = (props) => {
	let {children} = props
    let { requestReceivedUser, requestInfo, courses, activities,
		requestInfo: { isViewed, message, status },
		requestReceivedUser: {fullName, country, avatarPath, studentProfile}, 
		requestSentUser: {advisorProfile}
	} = props?.data

	let rejectedPill = () => {
		return(
			<div className="sentRequestCard__topSec__userDetailSec__userInfo__rejectedStatus">
				<p>Request Rejected</p>
			</div>
		)
	}
	let successPill = () => {
		return(
			<div className="sentRequestCard__topSec__userDetailSec__userInfo__acceptedStatus">
				<p>Request Accepted</p>
			</div>
		)
	}
	let pendingPill = () => {
		return(
			<div className="sentRequestCard__topSec__userDetailSec__userInfo__pendingStatus">
				<p>Request Pending</p>
			</div>
		)
	}
	let requestStatus = {
		pending: pendingPill(),
		accepted: successPill(),
		rejected: rejectedPill()
	}
	const imgUrl = useImageUrl(requestReceivedUser?.avatarPath);
	return (
		<div className={`sentRequestCard ${status}`}>
			<div className="sentRequestCard__topSec">
				<div className="sentRequestCard__topSec__userDetailSec">
					{avatarPath ? 
						<span>
							<img className="avatarImage" src={imgUrl} alt="userprofile" />
						</span>
					:
						<PersonIcon color="action" className="sentRequestCard__topSec__userDetailSec__placeholder"/>
					}
					<div className="sentRequestCard__topSec__userDetailSec__userInfo">
						<p className="sentRequestCard__topSec__userDetailSec__userInfo__primaryText">{fullName}</p>
						<p className="sentRequestCard__topSec__userDetailSec__userInfo__secondaryText">{country}</p>
						{requestStatus[status]}
					</div>
				</div>
				
				{status !== "pending" && 
					props?.hideIcon()
				}
			</div>
			<div className="sentRequestCard__schoolDetail">
				<SchoolOutlinedIcon className='sentRequestCard__schoolDetail__schoolIcon'/>
				<p className='sentRequestCard__schoolDetail__schoolName'>{studentProfile?.currrentSchoolName}</p>
			</div>
			<div className="sentRequestCard__servicesOfferedSec">
				<p className="sentRequestCard__servicesOfferedSec__title">Services offered</p>
				<div className="sentRequestCard__servicesOfferedSec__services">
					{advisorProfile?.helpWithPlan &&
						<div className="sentRequestCard__servicesOfferedSec__services__serviceSec">
							<StarIcon className='sentRequestCard__servicesOfferedSec__services__serviceSec__starIcon'/>
							<p className='sentRequestCard__servicesOfferedSec__services__serviceSec__label'> Create plan based on goals </p>
						</div>
					}
					{advisorProfile?.helpWithSpecificCourseActivity &&
						<div className="sentRequestCard__servicesOfferedSec__services__serviceSec">
							<StarIcon className='sentRequestCard__servicesOfferedSec__services__serviceSec__starIcon'/>
							<p className='sentRequestCard__servicesOfferedSec__services__serviceSec__label'> Review existing plan based on goals</p>
						</div>
					}
					{courses?.concat(activities)?.length > 0 &&
						<>
							<div className="sentRequestCard__servicesOfferedSec__services__serviceSec">
									<StarIcon className='sentRequestCard__servicesOfferedSec__services__serviceSec__starIcon'/>
									<p className='sentRequestCard__servicesOfferedSec__services__serviceSec__label'> Mentoring for</p>
							</div>
							<PillSection data={courses?.concat(activities)} maxWidth={200} maxHeight={100} />
						</>
					}
				</div>
				{message &&
					<div className="sentRequestCard__servicesOfferedSec__otherServices">
						<p className="sentRequestCard__servicesOfferedSec__otherServices__description" title={message}>
							{message}
						</p>
					</div>
				}
			</div>
			<div className="sentRequestCard__btnSec">
				{children}
			</div>
		</div>
	)
}

const mapStateToProps = ({ userProfileData, commonData }) => {
    const { } = commonData
    const { userProfile } = userProfileData;
    return { userProfile }
};

export default connect(mapStateToProps, {  })(Index);