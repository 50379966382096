import React, { Component } from "react";
import  "../../assets/css/chatbox.less"
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isChatactive:false,
            isShowchatIcon:true,
            isShowCloseIcon:false,
        };
    }
    componentWillMount() {
    }
    onclickprime(e){
        // this.toggleFab()
        const currentState = this.state.isChatactive;
        const ShowchatIcon = this.state.isShowchatIcon;
        const ShowCloseIcon = this.state.isShowCloseIcon;
        this.setState({ isChatactive: !currentState,isShowchatIcon:!ShowchatIcon,isShowCloseIcon:!ShowCloseIcon });
    }
    

    hideChat(hide) {
        let prime = document.getElementsByclassName('prime');
        let chat_fullscreen_loader = document.getElementsByclassName('chat_fullscreen_loader');
        let chat_login = document.getElementsByclassName('chat_login');

        let chat_converse = document.getElementById("chat_converse");
        let chat_body = document.getElementById("chat_body");
        let chat_form = document.getElementById("chat_form");
        let chat_fullscreen = document.getElementById("chat_fullscreen");
        switch (hide) {
          case 0:
                chat_converse.css('display', 'none');
                chat_body.css('display', 'none');
                chat_form.css('display', 'none');
                chat_login.css('display', 'block');
                chat_fullscreen_loader.css('display', 'none');
                 chat_fullscreen.css('display', 'none');
                break;
          case 1:
                chat_converse.css('display', 'block');
                chat_body.css('display', 'none');
                chat_form.css('display', 'none');
                chat_login.css('display', 'none');
                chat_fullscreen_loader.css('display', 'block');
                break;
          case 2:
                chat_converse.css('display', 'none');
                chat_body.css('display', 'block');
                chat_form.css('display', 'none');
                chat_login.css('display', 'none');
                chat_fullscreen_loader.css('display', 'block');
                break;
          case 3:
                chat_converse.css('display', 'none');
                chat_body.css('display', 'none');
                chat_form.css('display', 'block');
                chat_login.css('display', 'none');
                chat_fullscreen_loader.css('display', 'block');
                break;
          case 4:
                chat_converse.css('display', 'none');
                chat_body.css('display', 'none');
                chat_form.css('display', 'none');
                chat_login.css('display', 'none');
                chat_fullscreen_loader.css('display', 'block');
                chat_fullscreen.css('display', 'block');
                break;
        }
    }
    render() { 
        
        return (
            <>      
                <div className="fabs">
                    <div className={this.state.isChatactive ? 'chat is-visible': "chat"} >
                        <div className="chat_header">
                            <div className="chat_option">
                                <div className="header_img">
                                <img src="https://picsum.photos/200/300?random=1" />
                                </div>
                                <span id="chat_head">Jane Doe</span> <span className="agent">Agent</span> <span
                                className="online">(Online)</span>
                            </div>
                        </div>
                        <div id="chat_converse" className="chat_conversion chat_converse">
                        <a id="chat_second_screen" onClick={(e) => this.onclickchat_second_screen(e)} className="fab"><i className="zmdi zmdi-arrow-right"></i></a>
                        <span className="chat_msg_item chat_msg_item_admin">
                            <div className="chat_avatar">
                            <img src="https://picsum.photos/200/300?random=2" />
                            </div>Hey there! Any question?
                        </span>
                        <span className="chat_msg_item chat_msg_item_user">
                            Hello!</span>
                        <span className="status">20m ago</span>
                        <span className="chat_msg_item chat_msg_item_admin">
                            <div className="chat_avatar">
                            <img src="https://picsum.photos/200/300?random=2" />
                            </div>Hey! Would you like to talk sales, support, or anyone?
                        </span>
                        <span className="chat_msg_item chat_msg_item_user">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                        <span className="status2">Just now. Not seen yet</span>
                        </div>
                        
                        <div className="fab_field">
                        <a id="fab_camera" className="fab">
                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M1 3C1 1.89543 1.89543 1 3 1H13C14.1046 1 15 1.89543 15 3V13C15 14.1046 14.1046 15 13 15H3C1.89543 15 1 14.1046 1 13V3Z" stroke="#959699" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M2 13.0711L5.51472 9L10.0711 15.0711" stroke="#959699" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M10 8C8.89513 8 8 7.1047 8 6C8 4.8953 8.89513 4 10 4C11.1043 4 12 4.8953 12 6C12 7.1047 11.1043 8 10 8Z" stroke="#959699" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </a>
                        <a id="fab_send" className="fab">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M17.8151 11.9983L6.39844 18.2051L6.39844 5.79492L17.8151 11.9983Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M6.39844 11.9992H10.3984" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            Send
                        </a>
                        <textarea id="chatSend" name="chat_message" placeholder="Send a message"
                            className="chat_field chat_message"></textarea>
                        </div>
                    </div>
                    <a id="prime"  onClick={(e) => this.onclickprime(e)} className="fab">
                        <span className={this.state.isShowchatIcon ? 'chat-icon chat-icons show': "chat-icon chat-icons "} >
                            <svg width="24" height="24" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M1.59998 3.59961C1.59998 2.49504 2.49541 1.59961 3.59998 1.59961H12.4C13.5045 1.59961 14.4 2.49504 14.4 3.59961V11.5552L10.1333 15.8218V11.5552H3.59998C2.49541 11.5552 1.59998 10.6597 1.59998 9.55517V3.59961Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </span>
                        <span className={this.state.isShowCloseIcon ? 'close-icon chat-icons show': "close-icon chat-icons "} >
                            <svg width="24" height="24" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M43.2938 6.70514C43.4393 6.85028 43.5547 7.0227 43.6335 7.21253C43.7123 7.40236 43.7528 7.60586 43.7528 7.81139C43.7528 8.01691 43.7123 8.22041 43.6335 8.41024C43.5547 8.60007 43.4393 8.77249 43.2938 8.91764L8.91877 43.2926C8.62538 43.586 8.22744 43.7509 7.81252 43.7509C7.3976 43.7509 6.99967 43.586 6.70627 43.2926C6.41287 42.9992 6.24805 42.6013 6.24805 42.1864C6.24805 41.7715 6.41287 41.3735 6.70627 41.0801L41.0813 6.70514C41.2264 6.55963 41.3988 6.44418 41.5887 6.36541C41.7785 6.28664 41.982 6.24609 42.1875 6.24609C42.393 6.24609 42.5965 6.28664 42.7864 6.36541C42.9762 6.44418 43.1486 6.55963 43.2938 6.70514V6.70514Z" fill="white"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M6.70611 6.70514C6.5606 6.85028 6.44516 7.0227 6.36639 7.21253C6.28762 7.40236 6.24707 7.60586 6.24707 7.81139C6.24707 8.01691 6.28762 8.22041 6.36639 8.41024C6.44516 8.60007 6.5606 8.77249 6.70611 8.91764L41.0811 43.2926C41.3745 43.586 41.7724 43.7509 42.1874 43.7509C42.6023 43.7509 43.0002 43.586 43.2936 43.2926C43.587 42.9992 43.7518 42.6013 43.7518 42.1864C43.7518 41.7715 43.587 41.3735 43.2936 41.0801L8.91861 6.70514C8.77347 6.55963 8.60105 6.44418 8.41122 6.36541C8.22139 6.28664 8.01789 6.24609 7.81236 6.24609C7.60684 6.24609 7.40334 6.28664 7.21351 6.36541C7.02368 6.44418 6.85126 6.55963 6.70611 6.70514V6.70514Z" fill="white"/>
                            </svg>
                        </span>
                    </a>
                </div>
                
            </>
        )
    }
}
export default Index;