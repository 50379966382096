import React, { useEffect, useState } from "react";
import PackageCard from "./PackageCard";
import WorkingHours from './WorkingHours'
import './userProfile.less'
import { Grid } from "@mui/material";
import Review from "./Review"
import { Button } from '@mui/material';
import { connect } from "react-redux";
import {
    getUserGoals, CreateParentPlan, CreatePlanStatus, GetStudentPlan, GetStudentPlanPhase,
    getAdvisorRecommendedstatus, getAdvisorRecommended, ClearPlanId, getProfessions, EditPlan,
    EditPlanStatus, GetLoadPhase, userConnect, userConnectStatus, userConnectErrorStatus, bookSessionStatus,
    getDetailedUserProfile, settDetailedUserProfileStatus, SubScribeAdvisor, clearMessage, bookSession, getChildProfile, loadAdvisorReview
} from "../../../redux/actions";
import PillSection from "../../../componentsNew/Common/PillSection"
import ConnectCard from "../../../components/connectPopup";
import PersonIcon from '@mui/icons-material/Person';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../../components/Loader";
import BookSessionPopup from "../../../componentsNew/Common/BookSession/BookSessionPopup";
import SubscribePopup from "../../../componentsNew/Common/BookSession/SubscriptionPopup";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import { Rating } from '@mui/material'

const styles = {
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'relative',
        backgroundColor: '#fff',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
        padding: '0',
        borderRadius: '4px',
        width: '100%',
        height: "100%",
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        overflow: 'auto',
        padding: '0 40px',
        backgroundColor: '#fff',
        flexGrow: 1,
        borderRadius: '10px',
    },
};


const Index = (props) => {
    const [connect, setConnect] = useState(false);
    const [loading, setLoading] = useState(false);
    const [bookshow, setbookshow] = useState(false);
    const [TimeSession, setTimeSession] = useState([]);
    const [subscribeshow, setsubscribeshow] = useState(false)
    const [SubscibeData, setSubscibeData] = useState([])

    const location = useLocation()

    const handleConnectOpen = () => setConnect(true);
    const handleConnectClose = () => setConnect(false);

    const OpenSubScribe = (e, data) => {
        e.preventDefault()
        setSubscibeData(data)
        setsubscribeshow(true)
    }
    const CloseSubScribe = () => {
        setsubscribeshow(false)
    }

    let navigate = useNavigate();
    const { id } = useParams();

    const handleBack = () => {
        navigate(-1)
    };

    useEffect(() => {
        setLoading(true)
        let payload = {
            userId: id
        }
        let reviewPayload = {
            advisorId: id,
            "limit": 10,
            "pageNo": 1,
            studentId : localStorage?.getItem("parentViaChild")

        }
        props?.getDetailedUserProfile(payload)
        props?.loadAdvisorReview(reviewPayload)
        props?.getChildProfile()
    }, [])

    useEffect(() => {
        if (props.detailedUserDataSuccess) {
            setLoading(false)
            props.settDetailedUserProfileStatus(false)
        }
    }, [props.detailedUserDataSuccess])

    const onSubmit = (data) => {            //Connect Popup Onsubmit
        let payload = {
            userId: id,
            message: data?.description
        }
        props?.userConnect(payload)
        setLoading(true)
    };

    const ConfirmSubScribe = (data) => {
        let payload = {
            "advisorId": data?.userId,
            "paymentPackageId": data?.id,
            "studentId": props?.userProfile?.id
        }
        props?.SubScribeAdvisor(payload)
        setLoading(true)
    }


    let { userInfo, userInfo: { advisorProfile } = {} } = props.detailedUserData;

    useEffect(() => {
        if (props.userConnectSuccess) {
            props?.handleCallApi()
            props?.userConnectStatus(false)
            setLoading(false)

        }
        if (props.userConnectError) {
            props?.userConnectErrorStatus(false)
            setLoading(false)
            props?.handleCallApi()
        }
    }, [props.userConnectSuccess, props.userConnectError])

    const findPackageCourse = id => {
        let data = advisorProfile?.advisorCourses?.filter(course =>
            course.id == id
        )
        return data[0]?.courseName
    }
    const findPackageActivity = id => {
        let data = advisorProfile?.advisorActivities?.filter(activity =>
            activity.id == id
        )
        return data[0]?.activityName
    }

    const OpenBookSession = (e, data) => {
        console.log("OpenBookSession", data);
        props?.clearMessage()
        e.preventDefault()
        setTimeSession(data)
        setbookshow(true)
    }
    const CloseBookSession = () => {
        props?.clearMessage()
        setbookshow(false)
        setLoading(false)
    }

    const SessionBook = (data, value) => {
        CloseBookSession()
        const moment = require('moment');
        const SelectHours = value
        const selectedDate = moment(data.Selectdate, "YYYY-MM-DD").format("YYYY-MM-DD");
        const startTime = SelectHours.split(' - ')[0];
        const formattedDate = moment(`${selectedDate} ${startTime}`, "YYYY-MM-DD HH:mm A").format("YYYY-MM-DD HH:mm:ss");
        // Time Gap
        const [startTimes, endTime] = SelectHours.split(' - ');
        const parsedStartTime = moment(startTimes, 'h:mm A');
        const parsedEndTime = moment(endTime, 'h:mm A');
        const timeDiffInMillis = parsedEndTime.diff(parsedStartTime);
        const timeDiffInMinutes = moment.duration(timeDiffInMillis).asMinutes();

        let payload = {
            "advisorId": location.pathname.split('/').at(-1),
            "eventTime": formattedDate,
            "eventDuration": timeDiffInMinutes,
            "meetingLocation": data?.Location,
            "password": data?.password,
            "meetingDescription": data?.Description,
            "studentId": data?.student?.value,
        }
        props?.bookSession(payload)
        setLoading(true)
    }

    const stripHTMLTags = (html) => {
        const tempElement = document.createElement("div");
        tempElement.innerHTML = html;
        return tempElement.textContent || tempElement.innerText || "";
    };

    useEffect(() => {
        if (props?.sessionBooked) {
            setLoading(false)
            CloseBookSession()
            props?.bookSessionStatus(false)
        }
        if (props?.booksessionerror) {
            setLoading(false)
            CloseBookSession()
            props?.bookSessionStatus(false)
        }
    }, [props?.sessionBooked, props?.booksessionerror])

    console.log(props.detailedUserData, "advisorProfile childprofile")

    let filtered = props?.recommendedAdvisor?.length && props?.recommendedAdvisor?.filter((data) => data.userInfo?.id === props?.data?.userInfo?.id);

    let owlPackageList =
        props.detailedUserData &&
        props.detailedUserData?.advisorFeePackages?.length > 0 &&
        props.detailedUserData?.advisorFeePackages?.map((data, key) => {
            let temp = [];
            return (
                <div className="owl__item">
                    <a>
                        <PackageCard name={data?.packageName} packagePrice={data?.price} services={data?.servicesProvided}>
                            {
                                data.courses.length > 0 ?
                                    <>
                                        <p className="pkgCardTag-Title">Courses</p>
                                        {data.courses?.map((id, index) => {
                                            temp.push(findPackageCourse(id));
                                        })}
                                    </> :
                                    <>
                                        <p className="pkgCardTag-Title">Activities</p>
                                        {data.activities?.map((id, index) => {
                                            temp.push(findPackageActivity(id));
                                        })}
                                    </>
                            }
                            <PillSection data={temp} maxWidth={240} maxHeight={75} />
                            <div className="pkgCard-btnSec">
                                <button className="pkgCard-btnSec__primary-btn" style={{ opacity: '0.5' }}>
                                    <p className="pkgCard-btnSec__primary-btn__text">Purchase</p>
                                </button>
                            </div>
                        </PackageCard>
                    </a>
                </div>
            );
        });

    console.log(advisorProfile, "advisorProfile")
    console.log(props?.reviewData, "whdjhd")


    return (
        <>
            <SubscribePopup show={subscribeshow} Close={CloseSubScribe} ConfirmSubmit={ConfirmSubScribe} Subscribe={SubscibeData} />
            <BookSessionPopup show={bookshow} studentList={props?.childprofile} onHide={CloseBookSession} Time={TimeSession} HandleSumit={SessionBook} />
            <ConnectCard show={connect} handleConnectValues={(data) => onSubmit(data)} data={props.data} handleClose={handleConnectClose} />
            {loading && <Loader />}
            {
                !loading &&
                <div style={styles.content} className="modal-body">
                    <div style={{ textAlign: 'left', marginBottom: "10px", marginTop: "20px" }}>
                        <Button onClick={handleBack}><ArrowBackOutlinedIcon sx={{ fill: 'black' }} /></Button>
                    </div>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div className="advisorProfileCard">
                                <Grid container className="advisorProfileCard__container">
                                    <div className="advisorProfileCard__info-sec">
                                        {props?.data?.userInfo?.avatarUrl ? (
                                            <div class="advisorProfileCard__info-sec__sub-img advisor-sub-img">
                                                <img src={props?.data?.userInfo?.avatarUrl} alt="user" style={{ "width": "60px", "height": "60px", borderRadius: '10px', width: "100%", height: '100%' }} />
                                            </div>
                                        ) :
                                            <div class="advisorProfileCard__info-sec__sub-img">
                                                <PersonIcon color="action" sx={{ border: '1px solid grey', width: "100%", height: "100% !important", borderRadius: '10px' }} />
                                            </div>
                                        }
                                        <div className="advisorProfileCard__info-sec__profileCont">
                                            <p className="profileName">
                                                {userInfo?.fullName}
                                            </p>
                                            <div className="advisorProfileCard__info-sec__profileCont__about-sec">
                                                <>
                                                    <span>
                                                        {/* <RoomOutlinedIcon color="#515050" fontSize="12px" /> */}
                                                        <p className="label">{userInfo?.country}</p>
                                                    </span>
                                                    {
                                                        advisorProfile?.advisorReviewSummary && (
                                                            <span>
                                                                <p className="label d-flex" style={{ gap: '1px !important' }}>{"Rating :"} <span >&nbsp; <Rating style={{ color: '#AEAEAE' }} name="size-small" size="small" defaultValue={advisorProfile?.advisorReviewSummary?.averageRating} readOnly /></span> </p>
                                                            </span>
                                                        )
                                                    }


                                                </>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="advisorProfileCard__connect-btn">
                                        {filtered && !filtered[0]?.isConnected ?
                                            <>
                                            </>
                                            : (
                                                <>
                                                    <button className='advisorProfileCard__connect-btn__bgprimary-btn'>Request to Create Plan</button>
                                                </>
                                            )
                                        }
                                        {filtered && !filtered[0]?.isConnected ?
                                            <>
                                                {filtered && filtered[0]?.recentRequestId === null ? (
                                                    <button className='advisorProfileCard__connect-btn__primary-btn' onClick={handleConnectOpen}>Connect</button>
                                                ) : (
                                                    <button className='advisorProfileCard__connect-btn__primary-btn' style={{ opacity: '0.5' }}>Request pending</button>
                                                )
                                                }
                                            </> :
                                            <button className='advisorProfileCard__connect-btn__primary-btn' onClick={(e) => OpenBookSession(e, advisorProfile)} >Book Session</button>
                                        }
                                    </div>
                                </Grid>
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="advisorCoursesCard">
                                <p className="advisorCoursesCard__heading">
                                    About {userInfo?.firstName}
                                </p>
                            </div>
                            <div className="advisorProfileCard">
                                <div className="advisorProfileCard__info-sec__profileCont__about-sec">
                                    {
                                        advisorProfile?.currentlyIn === "university" && (
                                            <>
                                                <span>
                                                    <SchoolOutlinedIcon color="#515050" fontSize="12px" />
                                                    <p className="label">
                                                        Enrolled in {advisorProfile?.universityName}
                                                    </p>
                                                </span>
                                                <span>
                                                    <SchoolOutlinedIcon color="#515050" fontSize="12px" />
                                                    <p className="label">
                                                        Went to {advisorProfile?.highschoolName}
                                                    </p>
                                                </span>
                                            </>
                                        )
                                    }
                                    {
                                        advisorProfile?.currentlyIn === "highschool" && (
                                            <>
                                                <span>
                                                    <SchoolOutlinedIcon color="#515050" fontSize="12px" />
                                                    <p className="label">
                                                        {advisorProfile?.highschoolStatus} in {advisorProfile?.highschoolName} with Current GPA of {advisorProfile?.highSchoolGpa}
                                                    </p>
                                                </span>
                                                <span>
                                                    <SchoolOutlinedIcon color="#515050" fontSize="12px" />
                                                    <p className="label">
                                                        Aspiring to attend {advisorProfile?.universityName}
                                                    </p>
                                                </span>
                                            </>
                                        )
                                    }
                                    {
                                        advisorProfile?.currentlyIn === "professional" && (
                                            <>
                                                <span>
                                                    <SchoolOutlinedIcon color="#515050" fontSize="12px" />
                                                    <p className="label">
                                                        Went to {advisorProfile?.highschoolName} and {advisorProfile?.universityName}
                                                    </p>
                                                </span>

                                            </>
                                        )
                                    }

                                    <span>
                                        <WorkspacePremiumOutlinedIcon
                                            color="#515050"
                                            fontSize="12px"
                                        />
                                        <p className="label">
                                            Scored {advisorProfile?.satScore} in SAT
                                        </p>
                                    </span>
                                    <span>
                                        <WorkspacePremiumOutlinedIcon
                                            color="#515050"
                                            fontSize="12px"
                                        />
                                        <p className="label">
                                            Scored {advisorProfile?.actScore} in ACT
                                        </p>
                                    </span>
                                    {
                                        advisorProfile?.currentlyIn === "professional" ? (
                                            <span>
                                                <WorkOutlineOutlinedIcon
                                                    color="#515050"
                                                    fontSize="12px"
                                                />
                                                <p className="label d-flex">
                                                    Operating as <span style={{ color: '#1C84EE' }}> &nbsp; {advisorProfile?.companyName}</span> &nbsp; since {advisorProfile?.operatingSince}
                                                </p>
                                            </span>
                                        ) : (
                                            <>
                                                <span>
                                                    <EmojiEventsOutlinedIcon
                                                        color="#515050"
                                                        fontSize="12px"
                                                    />
                                                    <p className="label">
                                                        Aspiring to become {advisorProfile?.targetProfession}
                                                    </p>
                                                </span>
                                            </>
                                        )
                                    }

                                    <span>
                                        <p className="descriptionDetails">
                                            {stripHTMLTags(advisorProfile?.accomplishments)}
                                        </p>
                                    </span>
                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="advisorCoursesCard">
                                <p className="advisorCoursesCard__heading">
                                    Services Provided
                                </p>
                            </div>
                            <div className="advisorProfileCard">
                                <div className="advisorProfileCard__info-sec__profileCont__about-sec">
                                    <span>
                                        <StarBorderIcon color="#515050" fontSize="12px" />
                                        <p className="label">
                                            {userInfo?.firstName} Offers {advisorProfile?.noOfFreeConsultation} free consultation session
                                        </p>
                                    </span>
                                    {
                                        advisorProfile?.helpWithPlan === true && (
                                            <span>
                                                <StarBorderIcon color="#515050" fontSize="12px" />
                                                <p className="label d-flex">
                                                    {userInfo?.firstName} can suggests courses and activities based on your
                                                    <span style={{ color: '#1C84EE' }}>&nbsp; goals</span>

                                                </p>
                                            </span>
                                        )
                                    }
                                    {
                                        advisorProfile?.helpWithSpecificCourseActivity === true && (
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={5}>
                                                    <span>
                                                        <StarBorderIcon color="#515050" fontSize="12px" />
                                                        <p className="label">
                                                            {userInfo?.firstName} can provide mentorship and support for{" "}

                                                        </p>

                                                    </span>
                                                </Grid>
                                                <Grid item xs={12} sm={7}>
                                                    <span>

                                                        <PillSection data={advisorProfile?.advisorCourses?.map(course => course.courseName)} maxWidth={1000} maxHeight={75} />

                                                    </span>
                                                </Grid>

                                            </Grid>

                                        )
                                    }
                                </div>
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="advisorCoursesCard">
                                <p className="advisorCoursesCard__heading">Course Details</p>
                                {advisorProfile?.advisorCourses?.map((data, key) => (
                                    <div className="advisorProfileCard mb-2" key={key}>
                                        <Grid container spacing={2}>
                                            <Grid className="courseCardTitle mt-0 pt-0" item xs={12}>{data?.courseName}</Grid>

                                            <Grid item xs={4} className="coursePara"><span className="courseSubCardTitle ">Type</span> {data?.courseType}</Grid>
                                            <Grid item xs={4} className="coursePara"><span className="courseSubCardTitle ">Grade Achieved</span> {data?.gradeAchieved}</Grid>

                                            <Grid item xs={4} className="coursePara"><span className="courseSubCardTitle ">High School Teacher Name</span> {data?.teacherName}</Grid>

                                        </Grid>
                                        <p className="mt-2 coursePara">
                                            {stripHTMLTags(data?.description)}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="advisorCoursesCard">
                                <p className="advisorCoursesCard__heading">Activity Details</p>
                                {advisorProfile?.advisorActivities?.map((data, key) => (
                                    <div className="advisorProfileCard mb-2" key={key}>
                                        <Grid container spacing={2}>
                                            <Grid className="courseCardTitle mt-0 pt-0" item xs={12}>{data?.activityName}</Grid>

                                            <Grid item xs={4} className="coursePara"><span className="courseSubCardTitle ">Type</span> {data?.activityType}</Grid>
                                            <Grid item xs={4} className="coursePara"><span className="courseSubCardTitle ">Grade Achieved</span> {data?.gradeAchieved}</Grid>

                                            <Grid item xs={4} className="coursePara"><span className="courseSubCardTitle ">High School Teacher Name</span> {data?.teacherName}</Grid>

                                        </Grid>
                                        <p className="mt-2 coursePara">
                                            {stripHTMLTags(data?.description)}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </Grid>

                        {owlPackageList?.length > 0 && (
                            <Grid item xs={12}>
                                <div className="advisorPackageCard">
                                    <p className="advisorPackageCard__heading">Pricing Packages</p>
                                    <div className="advisorPackageCard__packCard-sec">
                                        <Grid container spacing={2}>

                                            {props.detailedUserData?.advisorFeePackages.map((data, key) => {
                                                // Filter advisorCourses based on data?.packageCourses
                                                const filteredCourses = advisorProfile?.advisorCourses?.filter(course => data?.courses.includes(course.id));

                                                return (
                                                    <Grid item xs={4} key={data?.id}>
                                                        <div className="advisorProfileCard" style={{ height: '100%' }}>

                                                            <p className="packageTitle">{data?.packageName}</p>
                                                            <p className="subPackagePrice">Price  <span style={{ color: '#1C84EE' }}>{" "} {data?.price}$ / Month</span></p>
                                                            <p><CheckCircleOutlinedIcon sx={{ color: '#00AA11', fontSize: '16px' }} /> <span>Includes {data?.noOfPaidSessions} Sessions of {data?.sessionLength} mins duration</span></p>
                                                            <p><CheckCircleOutlinedIcon sx={{ color: '#00AA11', fontSize: '16px' }} /> <span>Includes {data?.messageLimit} Chat Messages</span></p>
                                                            {data?.helpWithPlan === true && (<p><CheckCircleOutlinedIcon sx={{ color: '#00AA11', fontSize: '16px' }} /> <span>Suggest courses and activities based on your goals</span></p>)}
                                                            {
                                                                data?.helpWithSpecificCourseActivity === true && (
                                                                    <p><CheckCircleOutlinedIcon sx={{ color: '#00AA11', fontSize: '16px' }} /> <span>Provide mentorship and support for</span>   <PillSection data={filteredCourses?.map(course => course.courseName)} maxWidth={400} maxHeight={75} /> </p>
                                                                )
                                                            }
                                                            <p>
                                                                {stripHTMLTags(data?.description)}
                                                            </p>
                                                            <div className="pkgCard-btnSec">
                                                                <button className="pkgCard-btnSec__primary-btn" style={{ opacity: '0.5' }}>
                                                                    <p className="pkgCard-btnSec__primary-btn__text">Purchase</p>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </Grid>

                                                );
                                            })}
                                        </Grid>


                                    </div>
                                </div>
                            </Grid>
                        )}


                        <Grid item xs={12}>
                            {
                                (advisorProfile?.advisorWorkingHours?.mondayTimesheet?.length || advisorProfile?.advisorWorkingHours?.tuesdayTimesheet.length || advisorProfile?.advisorWorkingHours?.wednesdayTimesheet.length ||
                                    advisorProfile?.advisorWorkingHours?.thursdayTimesheet.length || advisorProfile?.advisorWorkingHours?.fridayTimesheet?.length || advisorProfile?.advisorWorkingHours?.saturdayTimesheet?.length ||
                                    advisorProfile?.advisorWorkingHours?.sundayTimesheet?.length
                                ) ?
                                    <WorkingHours data={advisorProfile?.advisorWorkingHours} />
                                    : <></>
                            }
                        </Grid>

                        {advisorProfile?.advisorReviewSummary &&
                            <Grid item xs={12} lg={12} className="mb-5">
                                <Review data={props?.reviewData} />
                            </Grid>
                        }

                    </Grid>
                </div>
            }


        </>
    );
};

const mapStateToProps = ({ userProfileData, studentData, commonData }) => {
    const { message, errorList, loading, universities, professions, userConnectSuccess, userConnectError,
        recommendedAdvisor, detailedAdvisorData, detailedUserData, detailedUserDataSuccess, sessionBooked, reviewData } = commonData
    const { userProfile, childprofile } = userProfileData;
    const { isgetusergoals, isgetusergoalsstatus, iscreateplan, getstudentplan, iseditplanphase, parentPlanId, } = studentData;
    return {
        message, errorList, reviewData, loading, universities, professions, userConnectSuccess, isgetusergoals,
        isgetusergoalsstatus, recommendedAdvisor, iscreateplan, getstudentplan, iseditplanphase, parentPlanId,
        userProfile, userConnectError, detailedAdvisorData, detailedUserData, detailedUserDataSuccess, childprofile, sessionBooked
    }
};

export default connect(mapStateToProps, {
    getUserGoals, CreateParentPlan, CreatePlanStatus, getAdvisorRecommended,
    userConnectStatus, ClearPlanId, GetStudentPlan, GetStudentPlanPhase, getAdvisorRecommendedstatus, getProfessions,
    EditPlan, EditPlanStatus, GetLoadPhase, userConnect, userConnectErrorStatus, getDetailedUserProfile,
    settDetailedUserProfileStatus, SubScribeAdvisor, clearMessage, bookSession, getChildProfile, bookSessionStatus, loadAdvisorReview,
})(Index);