import React, { useState } from "react";
import { Button, Typography, Box, Grid, Tab, Tabs } from "@mui/material";
import "./profile.less";
import Select, { components } from "react-select";
import { setParentProfile, changePassword, clearErrorList, getParentProfile, SentInviteParentstatus, clearMessage, getUserProfile, getChildProfile, disconnectUserStatus, getCountry, disconnectUser, getGender, getStatus, resendVerifyEmail } from "../../../redux/actions";
import { withRouter } from "../../../redux/store/navigate";
import { connect } from "react-redux";
import CustomAlert from "../../../components/CustomAlert";
import { GeneralForm } from "./Forms";
import { AddBillingCard } from "./BillingCard";
import DeleteProfile from "./DeleteProfile";
import CopyInvite from "./CopyInvite";
import ConnectChildForm from "./connectedChildForm";
import ConnectParentForm from "./connectedParentForm";
import ConnectedGoals from "./connectedGoalsPopup";
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import UserImage from "../../../componentsNew/Common/Profile_UserImage_Upload";
import Billing from "./Billing";
import ConnectedCardTile from "./ConnectedCard";
import { ConnectedCardParent } from "./ConnectedCardParent";
import ResetPassword from "./Passwords";
import SocialBlock from "../../Common/Socialloginpassword";
import { LocalAtmOutlined, PersonOutlineOutlined } from "@mui/icons-material";
import { useComponentDidUpdate, useComponentDidMount } from "../../../utils/useEffectHooks";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const Index = (props) => {
    const [tab, setTab] = useState(0);
    const [errorApi, setErrorApi] = useState([]);
    const [addOnopen, setAddOnOpen] = useState(false);
    const handleAddOnOpen = () => setAddOnOpen(true);
    const handleAddOnClose = () => setAddOnOpen(false);
    const [inviteOpen, setInviteOpen] = useState(false);
    const handleInviteOpen = () => setInviteOpen(true);
    const handleInviteClose = () => setInviteOpen(false);

    const changeTab = (event, newValue) => {
        props.clearMessage()
        setTab(newValue);
    };

    useComponentDidMount(() => {
        props.getGender()
        props.getStatus()
        props?.getUserProfile()
        props?.getParentProfile()   
    }, []);

    useComponentDidUpdate(() => {
        if (props?.errorList !== errorApi) {
            setErrorApi(props?.errorList)
        } else {
            setErrorApi({})
        }
    }, [props?.errorList]);

    useComponentDidUpdate(() => {
        if (props?.isDisconnectUser) {
            props?.getChildProfile()
            props?.getParentProfile()
            props?.disconnectUserStatus(false)
        }
        if(props?.isInviteSentSucess){
            props?.getParentProfile()   
            props?.getUserProfile()
            props?.SentInviteParentstatus(false)
        }
    }, [props?.isDisconnectUser, props?.isInviteSentSucess]);

    const handleFormValues = (data) => {
        console.log(data, "timeZone")
        let payload = {
            firstName: data?.firstName,
            lastName: data?.lastName,
            email: data?.email,
            zipCode: data?.zipCode,
            country: data?.country?.isoCode2Digit,
            studentsCollab: false,
            counselingAllowed: false,
            subCouncelingServices: false,
            timeZone: data?.timeZone?.value
        };
        if (props?.errorList !== errorApi) {
            setErrorApi([])
        }
        props.setParentProfile(payload, "profile");
    };

    const handleChangePsw = (data) => {
        let payload = {
            password: data?.password,
            newPassword: data?.newPassword,
            confirmPassword: data?.confirmPassword,
        };
        props.changePassword(payload);

    };

    const handleDisconnectUser = (data) => {
        let payload = {
            userId: data?.id,
        };
        props.disconnectUser(payload);

    };



    const Menulists = [{
        value: 0,
        icon: <PersonOutlineOutlined />,
        label: "General"
    },
    {
        value: 1,
        icon: <LocalAtmOutlined />,
        label: "Billing info"
    },
    {
        value: 2,
        icon: <GroupOutlinedIcon />,
        label: "Connected accounts"
    },]

    const handleSelectChange = (e) => {
        setTab(e.value);
    }

    const { ValueContainer, Placeholder } = components;
    const CustomValueContainer = ({ children, ...props }) => {
        return (
            <ValueContainer {...props}>
                <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder}</Placeholder>
                {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
            </ValueContainer>
        );
    };
    const handleResendApi = () => {
props?.resendVerifyEmail()
    }
    return (
            <div className="Parentprofilecontainer">
                {/* {errorApi?.length === 0 && <div style={{ "width": "100%", "display": "flex", "justifyContent": "center", }}> {<CustomAlert />}</div>} */}
                { <div style={{ "width": "100%", "display": "flex", "justifyContent": "center", }}> {<CustomAlert />}</div>}
                <div className="networktab">
                  { addOnopen && <ConnectedGoals show={addOnopen} professions={props?.professions} errorList={props?.errorList} handleClose={(data) => handleAddOnClose(false)} createGoal={true} graduationyear={props?.graduationyear} status={props?.status} gender={props?.gender} countryOption={props?.country} />}
                    <ConnectParentForm show={inviteOpen} handleClose={handleInviteClose} />
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <div className="tab-heading toplayer">
                                <div className="profileTitle d-flex">
                                    <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19.7274 20.4471C19.2716 19.1713 18.2672 18.0439 16.8701 17.2399C15.4729 16.4358 13.7611 16 12 16C10.2389 16 8.52706 16.4358 7.12991 17.2399C5.73276 18.0439 4.72839 19.1713 4.27259 20.4471" stroke="#222222" stroke-width="1.2" stroke-linecap="round" />
                                        <circle cx="12" cy="8" r="4" fill="#7E869E" fill-opacity="0.25" stroke="#222222" stroke-width="1.2" stroke-linecap="round" />
                                    </svg>
                                    <h3>Parent profile</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-wrap profileLayout">
                    <Grid item xs={12} sm={12} md={12}>
                        <Box className="__Top_Mobile tab-container "
                            sx={{ maxWidth: { xs: "100%", sm: "100%", md: "100%" }, bgcolor: "#fff", borderRadius: "10px", border: "none", marginInline: "auto", }} >
                            <div className="input-floating-label mb-0">
                                <Select options={Menulists} isSearchable={false} value={Menulists[tab]} onChange={handleSelectChange}
                                    defaultValue={Menulists[0]}
                                    getOptionLabel={e => (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {e.icon}
                                            <span style={{ marginLeft: 10 }}>{e.label}</span>
                                        </div>)}
                                    placeholder=" " components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                    styles={{ option: (provided, state) => ({ ...provided, cursor: 'pointer', }), container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }}
                                />
                            </div>
                        </Box>
                    </Grid>
                    <Grid container spacing={2} >
                        <Grid item xs={12} md={12}>
                            <Box
                                sx={{
                                    border: "1px solid #CDCDCD",
                                    maxWidth: { xs: "90%", sm: "90%", md: "100%", lg: "100%" },
                                    bgcolor: "#fff", borderRadius: "10px", marginInline: "auto"
                                }}
                                className='tab-container tab_card' >
                                <Tabs
                                    value={tab}
                                    onChange={changeTab}
                                    variant="scrollable"
                                    scrollButtons
                                    className="Tab"
                                    allowScrollButtonsMobile
                                    aria-label="scrollable force tabs example" >
                                    <Tab icon={<PersonOutlineOutlined />} iconPosition="start" label="Personal Settings" />
                                    <Tab icon={<LocalAtmOutlined />} iconPosition="start" label="Billing info" />
                                    <Tab icon={<GroupOutlinedIcon />} iconPosition="start" label="Your connected accounts" />
                                </Tabs>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TabPanel value={tab} index={0}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6} >
                                        <div style={{ marginBottom: "1rem", paddingTop: '5px' }}>
                                            <UserImage avatarUrl={props?.userProfile && props?.userProfile?.avatarUrl} errorList={props?.errorList} />
                                        </div>
                                        <div className="commanCardCnt">
                                            <GeneralForm
                                                handleFormValues={(data, checked, checkedSenior, professional) => handleFormValues(data, checked, checkedSenior, professional)}
                                                countryOption={props?.country}
                                                profileData={props?.userProfile}
                                                setClick={(click) => click}
                                                handleResendApi = {handleResendApi}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={6} className="passwordContainer">
                                        {props.userProfile && props.userProfile && props.userProfile.signedupVia === "google" ? (
                                            <div className="commanCardCnt" style={{ height: '9rem' }}>
                                                <SocialBlock />
                                            </div>) : (
                                            <div className="commanCardCnt">
                                                <ResetPassword handleFormValues={(data) => handleChangePsw(data)} errorList={props?.errorList} />
                                            </div>
                                        )}
                                        <div className="commanCardCnt">
                                            <CopyInvite />
                                        </div>
                                        <div className="commanCardCnt" style={{ marginBottom: '93px' }}>
                                            <DeleteProfile />
                                        </div>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={tab} index={1}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <div className="commanCardCnt"> <Billing />  </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className="commanCardCnt"><AddBillingCard /></div>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={tab} index={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <div className="commanCardCnt">
                                            <ConnectedCardTile handleDisconnectUser={(data) => handleDisconnectUser(data)} errorList={props?.errorList} childData={props?.childprofile} graduationyear={props?.graduationyear} status={props?.status} gender={props?.gender} countryOption={props?.country} />
                                            <div style={{ textAlign: 'right' }}>
                                                <Button onClick={() => handleAddOnOpen()} variant="contained" sx={{ justifyContent: 'end', marginTop: '1rem', textAlign: 'right' }} className="blueBtn" >Add Another Child</Button>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <div className="commanCardCnt">
                                            <ConnectedCardParent parentData={props?.parentProfile} handleDisconnectUser={(data) => handleDisconnectUser(data)} invitedParent={props?.userProfile?.parentProfile?.parentEmails} />
                                            {props?.parentProfile?.length < 1 && (
                                                <div style={{ textAlign: 'right' }}>
                                                    <Button onClick={() => handleInviteOpen()} variant="contained" sx={{ justifyContent: 'end', marginTop: '1rem', textAlign: 'right' }} className="blueBtn" >Connect Another Parent</Button>
                                                </div>
                                            )}
                                        </div>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        </Grid>
                    </Grid>
                </div>
            </div> 
    );
};

const mapStateToProps = ({ userProfileData, commonData }) => {
    const { userProfile, isProfileSuccess, childprofile, parentProfile, isDisconnectUser, isInviteSentSucess } = userProfileData;
    const { status, gender, professions, country, graduationyear, schools, errorList } = commonData;
    return { userProfile, isProfileSuccess, professions, status, gender, schools, country, childprofile, isInviteSentSucess, parentProfile, graduationyear, errorList, isDisconnectUser };
};
export default connect(mapStateToProps, { getCountry, getStatus, getChildProfile, getParentProfile, getUserProfile, SentInviteParentstatus, disconnectUserStatus, setParentProfile, clearErrorList, getGender, disconnectUser, changePassword, clearMessage, resendVerifyEmail })(withRouter(Index));
