import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "../../../assets/css/sendInvite.less";
import { connect, useDispatch, useSelector } from "react-redux";
import { verifyInvite } from "../../../redux/actions";
import InviteSignup from "../Invite_Signup";
import { useImageUrl } from "../../../utils/UseImageURL";
import { withRouter } from "../../../redux/store/navigate";

const IDENTIFIER = process.env.REACT_APP_IDENTIFIER;

const Index = () => {
    const [loading, setLoading] = useState(false);
    const [invitepage, setInvitepage] = useState(true);
    const [paramskey, setParamskey] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { isInvitelink, inviteProfile } = useSelector(({ userProfileData }) => ({
        isInvitelink: userProfileData.isInvitelink,
        inviteProfile: userProfileData.inviteProfile,
    }));

    useEffect(() => {
        const paramskey = window.location.pathname.split("/").pop();
        setParamskey(paramskey);

        let values = {
            userInvitationKey: paramskey,
            identifier: IDENTIFIER
        };

        if (paramskey && (IDENTIFIER === null || IDENTIFIER === undefined)) {
            console.log("empty");
            // navigate('/');
        }

        dispatch(verifyInvite(values));
    }, [dispatch, navigate]);

    useEffect(() => {
        if (isInvitelink === false) {
            navigate('/');
        }
    }, [isInvitelink, navigate]);

    const changepage = (e) => {
        e.preventDefault();
        setInvitepage(false);
    };

    const imgUrl = useImageUrl(inviteProfile?.avatarUrl);

    return (
        <>
            {invitepage ? (
                <div>
                    <div className="sendInvite">
                        <div className="sendInvite__form">
                            <h1 className="sendInvite__form__title text-center mb-2">Invitation</h1>
                            <p className="sendInvite__form__para text-center mb-5">
                                {inviteProfile?.firstName} {inviteProfile?.lastName} is inviting you to LeapBrains. Enjoy your journey!
                            </p>
                        </div>
                    </div>

                    <div className="invitation d-flex align-items-center flex-column">
                        {inviteProfile && imgUrl && inviteProfile.avatarUrl ? (
                            <img className="invitation__invite-profileimage mb-2" src={imgUrl} alt="profileimage" style={{ "objectFit": "cover" }} />
                        ) : (
                            <img className="invitation__invite-profileimage mb-2" src={require("../../../assets/images/icons/customprofile.png")} alt="profileimage" />
                        )}
                        <h1 className="invitation__invite-name mb-4">
                            {inviteProfile?.firstName} {inviteProfile?.lastName}
                        </h1>
                        <Button className="invitation__invitation-button" onClick={changepage}>
                            <span>Register</span>
                        </Button>
                    </div>
                </div>
            ) : (
                <div><InviteSignup profile={inviteProfile} /></div>
            )}
        </>
    );
};

const mapStateToProps = ({ userProfileData }) => {
    const { isInvitelink, inviteProfile } = userProfileData;
    return { isInvitelink, inviteProfile }
};

export default connect(mapStateToProps, { verifyInvite, })(withRouter(Index));
