import React from 'react'
import "./footer.less";
import { Link } from 'react-router-dom';

const Index = ({children, currentStep, reverseLink, skipTo, skipText, goback}) => {

  return (
    <div className='footers'>
        <div className="footers__leftSec">
            { reverseLink && goback !== true  &&
                <Link to={reverseLink} >
                    <p className="footers__leftSec__cta">
                        <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M5.80474 0.195262C5.54439 -0.0650874 5.12228 -0.0650874 4.86193 0.195262L0.195262 4.86193C-0.0650873 5.12228 -0.0650873 5.54439 0.195262 5.80474L4.86193 10.4714C5.12228 10.7318 5.54439 10.7318 5.80474 10.4714C6.06509 10.2111 6.06509 9.78894 5.80474 9.52859L1.60948 5.33333L5.80474 1.13807C6.06509 0.877722 6.06509 0.455612 5.80474 0.195262Z" fill="#1B1C1E" />
                        </svg>
                        <span>Go back</span>
                    </p>
                </Link>
            }
        </div>
        <div className="footers__midSec">
            <p className="footers__midSec__cta">{currentStep}</p>
        </div>
        <div className="footers__rightSec">
            {skipTo &&
                <Link className="footers__rightSec__SkipButton" to={skipTo}>{skipText}</Link>
            }
            {children}
        </div>
    </div>
  )
}

export default Index