import React, { Component } from "react";
import { Link } from "react-router-dom";
import { components, } from 'react-select';
import "../../../../../assets/css/common.less";
import { connect } from "react-redux";
import { getUniversities, getProfessions, setUserGoals, getUserProfile, setIsGoalSuccess, getUserGoals, getUserGoalsStatus, clearMessage } from "../../../../../redux/actions";
import { Form } from "react-bootstrap";
import { withRouter } from "../../../../../redux/store/navigate";
import CustomAlert from "../../../../../components/Alert";
import Loader from "../../../../../components/Loader";
import CreatableSelect from 'react-select/creatable';
import { universityApi } from "../../../../../utils/AutoComplete";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import CancelIcon from '@mui/icons-material/Cancel';

const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder}</Placeholder>
            {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
        </ValueContainer>
    );
};
const MultiValueRemove = (props) => {
    return (
        <components.MultiValueRemove {...props} >
            <CancelIcon style={{ "fill": "rgba(34, 34, 34, 0.67)", "width": "23px", "height": "49px" }} />
        </components.MultiValueRemove>
    );
};

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            dataLoaded: false,
            universities: false,
            universityCheckBox: false,
            professionsCheckBox: false,
            gPACheckBox: false,
            fields: { GPA: "" },
            errors: {},
            selectedUniversityOption: null,
            selectedProfessionOption: null,
            universityData: [],
            noOptionLength: "",
        };
    }

    componentDidMount() {
        this.setState({ loading: true })
        this.setInitialValues();
        this.props.clearMessage();
        this.props.getUserGoals();
    }
    componentWillReceiveProps(newProps) {
        this.setState({
            errorList: newProps.errorList, listOfUniversities: newProps.universities,
        });
    }
    componentDidUpdate() {
        if (!this.state.universities) {
            this.setState({ loading: false, universities: true })
        }
        this.setInitialValues();
        if (this.props.isGoalSuccess) {
            this.setState({ loading: false })
            this.props.setIsGoalSuccess(false)
        }
        if (this.props.isgetusergoals && this.props.isgetusergoalsstatus) {
        }
    }
    mapOptionsToValues = options => {
        return options.map(option => ({
            value: option.university,
            label: option.university
        }));
    };
    getOptions(inputValue, callback) {
        let { listOfUniversities } = this.state;
        this.props.getUniversities({ "searchString": inputValue })
        callback(this.mapOptionsToValues(listOfUniversities))
    }

    loadOptions = (value, callback) => {
        // universityApi(inputText).then((res) => callback(res.data.data?.map((item) => ({ label: item.university, value: item.university }))));
        if (value === "") {
            this.setState({ universityData: [] });
        }
        this.setState({ isload: true });
        this.setState({ noOptionLength: value });
        let list = []
        if (value !== "") {
            universityApi(value).then((res) => {
                if (res.status === 200) {
                    res?.data?.data.map((item, index) => {
                        list.push({
                            label: item.university,
                            value: item.university
                        });

                    });
                    this.setState({ universityData: list });
                } else {
                    this.setState({ universityData: [] });
                }

                this.setState({ isload: false });
            })
        }

    }

    handleUniversityText = (e) => {
        let { errors, fields, listOfUniversities } = this.state;
        fields['universityName'] = e;

        this.setState({ fields });
        if (e) {
            this.props.getUniversities({ "searchString": e })
        } else {
            this.setState({ listOfUniversities: [] })
        }

    }

    handleSuggestChange = (value) => {
        let { fields, listOfUniversities } = this.state
        fields['universityName'] = value
        this.setState({ fields, listOfUniversities: [] })
    }

    renderSuggestions = () => {
        let { fields, listOfUniversities } = this.state
        if (listOfUniversities?.length === 0) {
            return null;
        }
        return (
            <ul className="px-1 py-2">
                {listOfUniversities?.map((item) => <li className="w-100 py-1 university-item" style={{ cursor: 'pointer', }} onClick={() => this.handleSuggestChange(item.university, 'universityName')}>{item.university}</li>)}
            </ul>

        );
    }
    onMenuOpen = () => {
        this.setState({ openSuggession: true })
    }
    onMenuClose = () => {
        this.setState({ openSuggession: false })
    }
    filterColors = (inputValue) => {
        let { listOfUniversities } = this.state
        listOfUniversities.filter((e) => e.university.toLowerCase().includes(inputValue.toLowerCase()));
        return listOfUniversities.map(item => ({ 'label': item.university, 'value': item.university }))
    };


    handleFocus = () => {
        const { selectedOption } = this.state;
        if (selectedOption && selectedOption.label) {
            this.setState({ inputValue: selectedOption.label });
        }
    };
    handleUnversityApi = (props) => {

        this.props.getUniversities({ "searchString": props })
    }
    setInitialValues() {
        let { universityCheckBox, professionsCheckBox, gPACheckBox, fields, selectedUniversityOption, selectedProfessionOption, universityData } = this.state
        if (!this.state.dataLoaded) {
            if (this.props.isgetusergoals && this.props.isgetusergoalsstatus && this.props.isgetusergoals.goals) {
                let goalesData = this.props.isgetusergoals.goals
                if (goalesData && goalesData.length > 0) {
                    goalesData && goalesData.map((data, key) => {
                        console.log("goalesData",data);
                        if (data == "university") {
                            universityCheckBox = true;
                            this.refs.universityCheckBox.checked = true;
                        } else if (data == "weighted" && "unweighted") {
                            gPACheckBox = true;
                            this.refs.gPACheckBox.checked = true;
                        } else if (data == "profession") {
                            professionsCheckBox = true;
                            this.refs.professionsCheckBox.checked = true;
                        }
                    })
                }
                // DATA VALUE MAPPING
                // GPA DATA
                let userProfileData = this.props.isgetusergoals;
                fields["weighted"] = userProfileData.targetWeighted ? userProfileData.targetWeighted : "";
                fields["unweighted"] = userProfileData.targetUnweighted ? userProfileData.targetUnweighted : "";
                // University DATA
                let interestedUniversities = userProfileData.universities;
                let universityOptionstions = [];
                interestedUniversities !== undefined && interestedUniversities.length > 0 && interestedUniversities.map((university, key) => {
                    var temp = { label: university, value: university }
                    universityOptionstions.push(temp);
                });
                selectedUniversityOption = universityOptionstions;
                // Profession DATA
                let interestedProfession = userProfileData.professions;
                let professionOptionstions = [];
                interestedProfession !== undefined && interestedProfession.length > 0 && interestedProfession.map((profession, key) => {
                    var temp = { label: profession, value: profession }
                    professionOptionstions.push(temp);
                });
                selectedProfessionOption = professionOptionstions;
                // DATA VALUE MAPPING
                this.setState({ universityCheckBox, professionsCheckBox, gPACheckBox, selectedUniversityOption, selectedProfessionOption, universityData });
                this.setState({ dataLoaded: true, loading: false })
            }
        }
    }

    handleCheckboxChange(e, buttonName) {
        let { universityCheckBox, professionsCheckBox, gPACheckBox, errors } = this.state;
        if (buttonName === "universities") {
            universityCheckBox = e.target.checked
            errors["goal"] = ""
        } else if (buttonName === "profession") {
            professionsCheckBox = e.target.checked
            errors["goal"] = ""
        } else if (buttonName === "GPA") {
            gPACheckBox = e.target.checked
            errors["goal"] = ""
        }
        this.setState({ universityCheckBox, professionsCheckBox, gPACheckBox, errors })
    }

    handleChange(field, e) {
        let { errors } = this.state;
        let SelectedFields = this.state.fields;
        SelectedFields[field] = e.target.value;
        if (e.target.value.length >= 0) {
            errors[field] = "";
        }
        this.setState({ SelectedFields, errors: errors });
    }

    handleSelectChange(options, name) {
        let { errors } = this.state;
        let { selectedUniversityOption, selectedProfessionOption } = this.state;
        switch (name) {
            case "university":
                selectedUniversityOption = options;
                errors['university'] = ""
                break;
            case "professions":
                if (options.length > 10) {
                    errors['profession'] = "Max upto 10 proffession allowed"
                }
                else {
                    if (options) {
                        if (options.at(-1)?.value?.length > 50 && options.at(-1)?.__isNew__ === true) {
                            errors['profession'] = "Should not exceed 50 characters"
                        }
                        else if (options.at(-1)?.value?.length < 50 && options.at(-1)?.__isNew__ === true) {
                            selectedProfessionOption = options
                            errors['profession'] = ""
                        }
                        else {
                            selectedProfessionOption = options
                            errors['profession'] = ""
                        }
                    }
                }
                break;
        }
        this.setState({ selectedUniversityOption, selectedProfessionOption });
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        let { selectedUniversityOption, selectedProfessionOption } = this.state;

        if (!this.state.universityCheckBox && !this.state.professionsCheckBox && !this.state.gPACheckBox) {
            formIsValid = false;
            errors["goal"] = "Please select any goal";
            this.setState({ selectedUniversityOption: null })
            this.setState({ selectedProfessionOption: null })
        }
        if (this.state.universityCheckBox) {
            if (selectedUniversityOption === null) {
                formIsValid = false;
                errors["university"] = "Please select any university";
            }
            else if (selectedUniversityOption.length === 0) {
                formIsValid = false;
                errors["university"] = "Please select any university";
            }

        }
        if (this.state.professionsCheckBox) {
            if (selectedProfessionOption === null) {
                formIsValid = false;
                errors["profession"] = "Please select any profession";
            }
            else if (selectedProfessionOption.length === 0) {
                formIsValid = false;
                errors["profession"] = "Please select any profession";
            }
        }
        if (this.state.gPACheckBox) {
            if (!fields["weighted"]) {
                formIsValid = false;
                errors["weighted"] = "*Required";
            }
            if (!fields["unweighted"]) {
                formIsValid = false;
                errors["unweighted"] = "*Required";
            }
            if (fields["weighted"] !== "") {
                if (typeof fields["weighted"] !== "undefined") {
                    const decimalcaseRegExp = (/^[1-5]\.\d{1,1}$/);
                    const digitcaseRegExp = (/^[1-5]$/);
                    const floatcasenumber = decimalcaseRegExp.test(fields["weighted"]);
                    const digitcasenumber = digitcaseRegExp.test(fields["weighted"]);
                    if (!digitcasenumber && !floatcasenumber) {
                        formIsValid = false;
                        errors["weighted"] = "Invalid GPA";
                    }
                }
            }
            if (fields["unweighted"] !== "") {
                if (typeof fields["unweighted"] !== "undefined") {
                    const decimalcaseRegExp = (/^[1-4]\.\d{1,2}$/);
                    const digitcaseRegExp = (/^[1-4]$/);
                    const floatcasenumber = decimalcaseRegExp.test(fields["unweighted"]);
                    const digitcasenumber = digitcaseRegExp.test(fields["unweighted"]);
                    if (!digitcasenumber && !floatcasenumber) {
                        formIsValid = false;
                        errors["unweighted"] = "Invalid GPA";
                    }
                }
            }
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    handleNext = async (e) => {
        if (this.handleValidation()) {
            let goals = [];
            let fields = this.state.fields;
            let { selectedUniversityOption, selectedProfessionOption } = this.state;
            let Unweightedgpa = fields["unweighted"];
            let weightedgpa = fields["weighted"];
            if (this.state.universityCheckBox)
                goals.push("university");
            else
                selectedUniversityOption = [];

            if (this.state.professionsCheckBox)
                goals.push("profession");
            else
                selectedProfessionOption = [];

            if (this.state.gPACheckBox)
                goals.push("GPA");
            let selectedUniversity = [];
            selectedUniversityOption.forEach(element => {
                selectedUniversity.push(element.label || element);
            });
            let selectedProfession = [];
            selectedProfessionOption.forEach(element => {
                selectedProfession.push(element.label);
            });
            let values = {
                improveGpa: this.state.gPACheckBox,
                specificProfession: this.state.professionsCheckBox,
                specificUniversity: this.state.universityCheckBox,
                goalsSelected: goals,
                interestedUniversities: selectedUniversity,
                interestedProfession: selectedProfession
            }
            if (values.improveGpa === true) {
                values["weighted"] = weightedgpa
                values["unweighted"] = Unweightedgpa
                values["desiredGpa"] = "5"
            }
            if (values.improveGpa === false) {
                values["weighted"] = null
                values["unweighted"] = null
                values["desiredGpa"] = ""
            }
            this.setState({ loading: true })
            this.props.setUserGoals(values, "profile")
            this.props.getUserGoalsStatus(false)
        }
    }
    onTagsChange = (event, values) => {
        let { errors } = this.state;
        this.setState({ selectedUniversityOption: values });
        errors['university'] = ""
    }
    render() {
        const { universities, professions } = this.props;
        let { universityCheckBox, professionsCheckBox, gPACheckBox, selectedUniversityOption, selectedProfessionOption, universityData } = this.state;
        let universityOptionstions = [];
        let professionOptionstions = [];
        universities !== undefined && universities.length > 0 && universities.map((university, key) => {
            var temp = { label: university.university, value: university.university }
            universityOptionstions.push(temp);
        });
        professions !== undefined && professions.length > 0 && professions.map((profession, key) => {
            var temp = { label: profession.profession, value: profession.profession }
            professionOptionstions.push(temp);
        });
        return (
            <>
                {this.state.loading && <Loader />}
                <div className="Goals_Card">
                    {/* <p className="Goals_Card_Title">Goals</p> */}
                    <div className="Goals_Mid mt-4">
                        <p className="Goals_Mid_Title">Specify one or more goals so we can connect you with advisors and students with similar goals</p>
                    </div>
                    <div className="role">
                        <div className="role_card" style={{ width: "430px !important" }}>
                            <div className="goals_page">
                                <div className="mb-2 role__btns">
                                    <input type="checkbox" ref='universityCheckBox' defaultChecked={universityCheckBox} onClick={(e) => this.handleCheckboxChange(e, 'universities')} className="btn-check" name="options1" id="btncheck1" autoComplete="off" />
                                    <label className="btn cta--role--btn w-mob-100" htmlFor="btncheck1">Get into specific universities</label>
                                </div>
                                <div className=" mb-2 role__btns">
                                    <input type="checkbox" ref='professionsCheckBox' defaultChecked={professionsCheckBox} onClick={(e) => this.handleCheckboxChange(e, 'profession')} className="btn-check" name="options2" id="btncheck2" autoComplete="off" />
                                    <label className="btn cta--role--btn w-mob-100" htmlFor="btncheck2">Get into specific professions</label>
                                </div>
                                <div className=" mb-2 role__btns">
                                    <input type="checkbox" ref='gPACheckBox' defaultChecked={gPACheckBox} onClick={(e) => this.handleCheckboxChange(e, 'GPA')} className="btn-check" name="options3" id="btncheck3" autoComplete="off" />
                                    <label className="btn cta--role--btn w-mob-100" htmlFor="btncheck3">Improve GPA</label>
                                </div>
                            </div>
                            {this.state.errors["goal"] && <span className="error-text text-center" style={{ display: "table", margin: "0 auto" }}>{this.state.errors["goal"]}</span>}
                            <Form className="login_card_form mb-3" autoComplete="off">
                                <div className="goals__form">
                                    {universityCheckBox &&
                                        <div className="row">
                                            <div className="col-md-12 mb-5 role__btns asyncmultiselect multinew">
                                                <Autocomplete multiple noOptionsText={'There is no university related to your search'} className="goals__form__select"
                                                    id="universityDropdown" name="universityDropdown" type='text' clearOnBlur={false} filterSelectedOptions clearIcon ChipProps forcePopupIcon={false} freeSolo={true} openOnFocus={true}
                                                    options={this.state.universityData?.length > 0 ? this.state.universityData.map((option) => option.label) : []}
                                                    renderTags={(value, getTagProps) => value.map((option, index) => (<Chip label={option} {...getTagProps({ index })} />))}
                                                    filterOptions={(options, state) => options} defaultValue={selectedUniversityOption !== null ? selectedUniversityOption?.map((option) => option.label || option) : []}
                                                    onChange={this.onTagsChange} onInputChange={async (event, value) => { this.loadOptions(value) }}
                                                    renderInput={(params) => (<TextField {...params} label="Desired University" size="small"
                                                        inputProps={{ ...params.inputProps, onKeyDown: (e) => { if (e.key === 'Enter') { e.preventDefault(); } }, }} sx={{ '& .MuiOutlinedInput-root': { '&:hover fieldset': { borderColor: 'lightgrey', }, } }} />)}
                                                />
                                                {this.state.errors["university"] && <span className="error-text">{this.state.errors["university"]}</span>}
                                            </div>
                                        </div>
                                    }
                                    {professionsCheckBox &&
                                        <>
                                            <div className="row">
                                                <div className="col-md-12 mb-5 role__btns asyncmultiselect">
                                                    <CreatableSelect className="goals__form__select" noOptionsMessage={() => null} classNamePrefix="mySelect" options={professionOptionstions} id="professionsSelect"
                                                        closeMenuOnSelect={true} isClearable={false} isMulti components={{ ValueContainer: CustomValueContainer, DropdownIndicator: () => null, IndicatorSeparator: () => null, MultiValueRemove }}
                                                        placeholder="Professions" value={selectedProfessionOption} onChange={(value) => this.handleSelectChange(value, 'professions')}
                                                        styles={{
                                                            option: (provided, state) => ({ ...provided, cursor: 'pointer', }),
                                                            container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', }),
                                                            placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10 })
                                                        }} />
                                                    {this.state.errors["profession"] && <span className="error-text">{this.state.errors["profession"]}</span>}
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {gPACheckBox &&
                                        <div className="row">
                                            <div className="col-md-6 col-sm-6 col-12" style={{ "padding": "0px" }}>
                                                <div className="input-floating-label" style={{ "width": "95%" }} >
                                                    <input refs="unweighted" type="text" className={"textbox--primary textbox--rounded input"} name="unweighted" id="unweighted"
                                                        placeholder="unweighted" onKeyPress={(event) => { if (!/[0-9.]/.test(event.key)) { event.preventDefault(); } }} onChange={this.handleChange.bind(this, "unweighted")} value={this.state.fields["unweighted"]} />
                                                    <label>Target Unweighted GPA</label>
                                                    {this.state.errors["unweighted"] && <span className="error-text">{this.state.errors["unweighted"]}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6 col-12" style={{ "padding": "0px" }}>
                                                <div className="input-floating-label">
                                                    <input refs="weighted" type="text" className={"textbox--primary textbox--rounded input"} name="weighted" id="weighted"
                                                        placeholder="weighted" onKeyPress={(event) => { if (!/[0-9.]/.test(event.key)) { event.preventDefault(); } }} onChange={this.handleChange.bind(this, "weighted")} value={this.state.fields["weighted"]} />
                                                    <label>Target Weighted GPA</label>
                                                    {this.state.errors["weighted"] && <span className="error-text">{this.state.errors["weighted"]}</span>}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Form>
                        </div>
                        <div className="Goals_Bottom">
                            <Link className="Goals_Save_Btn" to="#" onClick={e => this.handleNext(e)}>Save</Link>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}

const mapStateToProps = ({ userProfileData, studentData, commonData }) => {
    const { message, errorList, universities, professions, loading } = commonData
    const { userProfile } = userProfileData;
    const { isGoalSuccess, isgetusergoals, isgetusergoalsstatus } = studentData;
    return { message, errorList, universities, professions, isGoalSuccess, userProfile, userProfileData, loading, isgetusergoals, isgetusergoalsstatus }
};

export default connect(mapStateToProps, { getUniversities, getProfessions, setUserGoals, getUserProfile, setIsGoalSuccess, getUserGoals, getUserGoalsStatus, clearMessage })(withRouter(Index));