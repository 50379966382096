import React, { useEffect, useRef, useState } from 'react'
import "./studentProfile.less"
import { connect } from 'react-redux';
import { Button, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import CourseCard from "../Cards/Common/CourseCard";
import ActivityCard from "../Cards/Common/ActivityCard";
import AdvisorPlanAccessModal from "../../Advisor/AdvisorPlanAccessModal";
import StudentPlanAccessModal from "../../Student/StudentPlanAcessModal";
import PlanCard from "../../../componentsNew/Common/Cards/Common/PlanCard";
import CustomAlert from '../../../components/CustomAlert';
import {
    clearMessage, getStudentDetail, getStudentPlanDetails, getStudentPlanStatus, resetStudentPlansData, CancelRequest, CancelRequestStatus,
    getStudentDetailstatus, resetStudentDetails, getDetailedUserProfile, getUserProfile, settDetailedUserProfileStatus, disconnectUser, userConnectStatus,
    sendRespondToRequest, setPendingRequestStatus, setSendRespondToRequestStatus, sendRespondToParentRequest, respondToParentRequestStatus, disconnectUserStatus
} from "../../../redux/actions";
import ConfirmationPopup from "../../../componentsNew/Common/ConfirmationPopup";
import Loader from '../../../components/Loader';
import Alert from 'react-bootstrap/Alert';
import RejectPopup from '../../../components/rejectPopup';
import { useImageUrl } from '../../../utils/UseImageURL';

const styles = {
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'relative',
        backgroundColor: '#f4f5f8',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
        padding: '0',
        borderRadius: '4px',
        width: '100%',
        height: "100%",
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
        overflow: 'auto',
        padding: '30px 70px',
        flexGrow: 1,
    },
};

const Index = (props) => {
    const [loading, setLoading] = useState(false);
    const [loadPlans, setLoadPlans] = useState(true);
    const [showAccessModal, setShowAccessModal] = useState(false);
    const [showDisconnectModal, setShowDisconnectModal] = useState(false);
    const [disconnectUserData, setDisconnectUserData] = useState({});
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [rejectData, setRejectData] = useState({});
    const [acceptRequestData, setAcceptRequestData] = useState({});
    const [showAcceptRequestModal, setShowAcceptRequestModal] = useState(false);
    const [showRejectRequestModal, setShowRejectRequestModal] = useState(false);
    const [link, setLink] = useState('')
    const [alertVisible, setalertVisible] = useState(false)
    const [ShowAlert, setShowAlert] = useState(false)
    const [rejectConfirmation, setrejectConfirmation] = useState(false)
    const [rejectConfirmationData, setrejectConfirmationData] = useState({})

    const currentProtocol = window.location.protocol;
    const currentHost = window.location.host;
    const baseURL = `${currentProtocol}//${currentHost}`;
    const [userProfileUrl, setUserProfileUrl] = useState(`${baseURL}/view/profile/${props?.profileData?.id}`);
    useEffect(() => {
        let payload = {
            userId: props?.profileData?.id,
        }
        props?.clearMessage();
        setLoading(true);
        props.getUserProfile()
        if (props?.userProfile?.currentRole === "parent") {
            payload["pointOfViewStudentId"] = props?.userProfile?.isOnboarded === true ? localStorage.getItem("parentViaChild") : props?.childprofile && props?.childprofile[0]?.id
        }
        props.getDetailedUserProfile(payload);
        props.getStudentDetail(payload);
        props.getStudentPlanDetails(payload);

        return () => {
            props?.clearMessage();
        }
    }, [])

    useEffect(() => {
        if (props?.loadStudentPlanDataStatus) {
            props.getStudentPlanStatus(false);
            setLoadPlans(false);
        }
        if (props.studentDetailsStatus) {
            props.getStudentDetailstatus(false);
        }
        if (props?.detailedUserDataSuccess) {
            setLoading(false);
            props.settDetailedUserProfileStatus(false);
        }
        if (props?.userConnectSuccess || props?.isDisconnectUser) {
            openDisconnectModal(false);

            let payload = {
                userId: props?.profileData?.id
            }
            let payloadData = {
                userId: props?.profileData?.id
            }
            if (props?.userProfile?.currentRole === "parent") {
                payload["pointOfViewStudentId"] = props?.userProfile?.isOnboarded === true ? localStorage.getItem("parentViaChild") : props?.childprofile && props?.childprofile[0]?.id
            }
            props.getDetailedUserProfile(payload);
            props.getStudentDetail(payloadData);
            props.getStudentPlanDetails(payloadData);
            handleConnect(false)
            props?.userConnectStatus(false);
            props?.disconnectUserStatus(false)
        }
        if (props?.cancelrequest) {
            let payload = {
                userId: props?.profileData?.id
            }
            if (props?.userProfile?.currentRole === "parent") {
                payload["pointOfViewStudentId"] = props?.userProfile?.isOnboarded === true ? localStorage.getItem("parentViaChild") : props?.childprofile && props?.childprofile[0]?.id
            }
            props?.getDetailedUserProfile(payload);
            setShowCancelModal(false);
            setRejectData({});
            props?.CancelRequestStatus(false);

        }
        if (props.pendingRequestStatus) {
            props.setPendingRequestStatus(false);
            setLoading(false);
        }
        if (props.isRequestRespond) {
            let payload = {
                userId: props?.profileData?.id
            }
            props.getDetailedUserProfile(payload);
            props.setSendRespondToRequestStatus(false);
            openAcceptRequestModal({}, false);
            openRejectRequestModal({}, false)
            setLoading(false);
        }
        if (props?.parentRequest) {
            let payload = {
                userId: props?.profileData?.id,
                pointOfViewStudentId: props?.userProfile?.isOnboarded === true ? localStorage.getItem("parentViaChild") : props?.childprofile && props?.childprofile[0]?.id

            }
            props.getDetailedUserProfile(payload);
            props?.respondToParentRequestStatus(false)
            openAcceptRequestModal({}, false);
            openRejectRequestModal({}, false)
            setLoading(false);
        }
    }, [props.loadStudentPlanDataStatus, props.studentDetailsStatus, props?.detailedUserDataSuccess,
    props?.userConnectSuccess, props?.isDisconnectUser, props?.cancelrequest, props.pendingRequestStatus,
    props.isRequestRespond, props?.parentRequest])

    const handleConnect = (status) => {
        setShowAccessModal(status);
    };

    const handleClose = () => {
        props.resetStudentPlansData([]);
        props?.handleClose();
    };

    const gpaText = (weighted, unWeigted) => {
        if (props?.userProfile?.currentRole === "student") {
            if (weighted && unWeigted) {
                return `Aspiring to achieve unweighted ${weighted} and weighted ${unWeigted} GPA`
            } else if (weighted) {
                return `Achieved weighted ${weighted} GPA`
            } else if (unWeigted) {
                return `Achieved unweighted ${unWeigted} GPA`
            } else return null;
        }
        else {
            if (weighted && unWeigted) {
                return `Achieved unweighted ${weighted} and weighted ${unWeigted} GPA`
            } else if (weighted) {
                return `Achieved weighted ${weighted} GPA`
            } else if (unWeigted) {
                return `Achieved unweighted ${unWeigted} GPA`
            } else return null;
        }
    }
    const openDisconnectModal = (status, data = {}) => {
        setDisconnectUserData(data)
        setShowDisconnectModal(status);
    }
    const handleDisconnect = () => {
        let payload = {
            userId: profileData?.id
        }
        props?.disconnectUser(payload)
    }
    const handleShowCancelModal = (status, data = {}) => {
        setShowCancelModal(status);
        setRejectData(data);
    }
    const handleCancelRequest = () => {
        let payload = {
            requestId: rejectData?.id
        }
        if (props?.userProfile?.currentRole === "parent") {
            payload["studentId"] = props?.userProfile?.isOnboarded === true ? localStorage.getItem("parentViaChild") : props?.childprofile && props?.childprofile[0]?.id
        }

        props?.CancelRequest(payload);
    }
    let { profileData, detailedUserData, detailedUserData: { userInfo, isConnectedWithUser, recentRequest } = {} } = props;
    let { studentProfile } = userInfo || {};
    let buttonState;

    if (props?.userProfile?.currentRole === "parent") {
        if (props?.userProfile?.isOnboarded === false) {
            if (!isConnectedWithUser && recentRequest?.status === "pending") {
                if (props?.childprofile && props?.childprofile[0]?.id === recentRequest?.userId) buttonState = "PENDING";
                else if (props?.childprofile && props?.childprofile[0]?.id === recentRequest?.targetUserId) buttonState = "RECIEVED_REQUEST";
            } else if (!isConnectedWithUser && !recentRequest) buttonState = "CONNECT";

        } else {
            if (!isConnectedWithUser && recentRequest?.status === "pending") {
                if (localStorage.getItem("parentViaChild") === recentRequest?.userId) buttonState = "PENDING";
                else if (localStorage.getItem("parentViaChild") === recentRequest?.targetUserId) buttonState = "RECIEVED_REQUEST";
            } else if (!isConnectedWithUser && !recentRequest) buttonState = "CONNECT";

        }

    } else {
        if (!isConnectedWithUser && recentRequest?.status === "pending") {
            if (props?.userProfile?.id === recentRequest?.userId) buttonState = "PENDING";
            else if (props?.userProfile?.id === recentRequest?.targetUserId) buttonState = "RECIEVED_REQUEST";
        } else if (!isConnectedWithUser && !recentRequest) buttonState = "CONNECT";
    }

    function myFunction() {
        setShowAlert(true)
        let textfield = document.createElement("input");
        textfield.setAttribute('id', 'myInput')
        textfield.setAttribute('value', userProfileUrl)
        document.body.appendChild(textfield)
        let copyText = document.getElementById("myInput");
        copyText.focus();
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand('copy');
        textfield.remove()
        navigator.clipboard.writeText(userProfileUrl)
        setalertVisible(true)
        setTimeout(() => {
            setShowAlert(false);
            setalertVisible(false)
        }, 3000);
    }
    const copyToClipboard = async () => {
        myFunction()
        setLink('')
    };
    const openAcceptRequestModal = (data, status) => {
        setShowAcceptRequestModal(status);
        setAcceptRequestData(data);
    }
    const openRejectRequestModal = (data, status) => {
        setShowRejectRequestModal(status);
        setRejectData(data);
        console.log(data, status, "data, status");


    }

    const handleAcceptRequest = (rejectReason, data, status) => {
        let payload = {
            requestId: data?.recentRequest?.id,
            isAccepted: status,
        };
        if (!status) {
            payload['rejectReason'] = rejectReason?.description || ""
        }
        setLoading(true);
        if (props?.userProfile?.currentRole === "parent") {
            let studentId = props?.userProfile?.isOnboarded === true ? localStorage.getItem("parentViaChild") : props?.childprofile && props?.childprofile[0]?.id
            props?.sendRespondToParentRequest(studentId, payload)
        } else {
            props.sendRespondToRequest(payload);
        }
    };

    // const handleAcceptRequest = (rejectReason, data, status) => {
    //     console.log(rejectReason, data, status,"rejectReason");
    //     setrejectConfirmation(true)
    //     setrejectConfirmationData({ rejectReason, data })
    // };

    const RejectClose = () => {
        setrejectConfirmationData({});
        setrejectConfirmation(false);
    }
    const RejectConfirmtion = () => {
        console.log(rejectConfirmationData?.rejectReason, "rejectConfirmationData");
        setLoading(true)
        let payload = {
            requestId: rejectConfirmationData?.data?.recentRequest?.id,
            isAccepted: false,
        };
        if (!false) {
            payload['rejectReason'] = rejectConfirmationData?.rejectReason?.description || ""
        }
        setLoading(true);
        if (props?.userProfile?.currentRole === "parent") {
            let studentId = props?.userProfile?.isOnboarded === true ? localStorage.getItem("parentViaChild") : props?.childprofile && props?.childprofile[0]?.id
            props?.sendRespondToParentRequest(studentId, payload)
        } else {
            props.sendRespondToRequest(payload);
        }
        RejectClose()

    }

    const checkIfActivePhase = (phaseId) => {
        return !!(props.studentPlanData?.find(planData => planData?.planInfo?.activePhaseId == phaseId));
    }
    const getCourseStatus = (phaseData) => {
        console.log(phaseData, "phaseData")
        if (phaseData?.isPassed) {
            return <div className="courseFullViewCard__topSection__successStatus">Completed</div>
        } else if (checkIfActivePhase(phaseData?.id)) {
            return <div className="courseFullViewCard__topSection__inProgressStatus">Inprogress</div>
        } else {
            return <div className="courseFullViewCard__topSection__upCommingStatus">Up Coming</div>
        }
    }
    const imgUrl = useImageUrl(userInfo?.avatarPath)
    return (
        <>
            {rejectConfirmation &&
                <ConfirmationPopup show={rejectConfirmation} handleSubmit={() => RejectConfirmtion()}
                    message={"Please confirm that you would like to reject this connection request from this student"} btnText="confirm" onHide={() => RejectClose()} />
            }
            <Modal
                style={styles.modal}
                open={props.show}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                className="studentProfile"
            >
                <div style={styles.paper}>
                    {alertVisible &&
                        <div style={{ position: 'fixed', top: '10px', zIndex: '99999', left: '50%', transform: 'translateX(-50%)' }} >
                            <Alert style={{ position: 'sticky', marginTop: '10px', }}
                                variant="success" onClose={() => { setShowAlert(false); setalertVisible(false) }} dismissible>Link Copied to clipboard</Alert>
                        </div>
                    }
                    <div style={{ "width": "100%", "display": "flex", justifyContent: "center", marginTop: "10px" }}> {<CustomAlert />}</div>
                    <div style={styles.content} className="modal-body">
                        {loading ? <Loader />
                            :
                            <>
                                <div style={{ textAlign: 'right' }}>
                                    <Button onClick={handleClose}><CloseIcon sx={{ fill: 'black' }} /></Button>
                                </div>
                                <div className="studentProfile__studentProfileInfo">
                                    <div className="studentProfile__studentProfileInfo__userDetail">
                                        {userInfo?.avatarPath ?
                                            <div className='studentProfile__studentProfileInfo__userDetail__profileImageSec'>
                                                <img src={imgUrl} alt='UserProfile' className='studentProfile__studentProfileInfo__userDetail__profileImageSec--img' />
                                            </div>
                                            :
                                            <PersonIcon color="action" className='studentProfile__studentProfileInfo__userDetail__placeholderImg' />
                                        }
                                        <div className="studentProfile__studentProfileInfo__userDetail__info">
                                            <p className='studentProfile__studentProfileInfo__userDetail__info__userName'>{userInfo?.fullName}</p>
                                            <p className='studentProfile__studentProfileInfo__userDetail__info__country'>{userInfo?.country}</p>
                                        </div>
                                    </div>
                                    <div className="studentProfile__studentProfileInfo__ctaSection">
                                        {!isConnectedWithUser &&
                                            <>
                                                {buttonState === "PENDING" &&
                                                    <button className="studentProfile__studentProfileInfo__ctaSection__btn danger-btn" id='cancelBtn' onClick={() => handleShowCancelModal(true, recentRequest)}>
                                                        Cancel Request
                                                    </button>
                                                }{buttonState === "RECIEVED_REQUEST" &&
                                                    <div className="studentProfile__studentProfileInfo__ctaSection__inline" >
                                                        <button className="studentProfile__studentProfileInfo__ctaSection__inline__btn danger-btn" id='rejectBtn'
                                                            onClick={() => {
                                                                if (window.location.href.includes('advisor')) {
                                                                    openRejectRequestModal(detailedUserData, true);
                                                                } else {
                                                                    handleAcceptRequest(null, detailedUserData, false)
                                                                }
                                                            }}>
                                                            reject
                                                        </button>
                                                        <button className="studentProfile__studentProfileInfo__ctaSection__inline__btn primary-btn" id='acceptBtn' onClick={() => openAcceptRequestModal(detailedUserData, true)}>
                                                            Accept
                                                        </button>
                                                    </div>
                                                    // <button className="studentProfile__studentProfileInfo__ctaSection__btn primary-btn" onClick={() => handleConnect(true)}>
                                                    //     Connect
                                                    // </button>
                                                }{buttonState === "CONNECT" &&
                                                    <button className="studentProfile__studentProfileInfo__ctaSection__btn primary-btn" id='connectBtn' disabled={loadPlans} onClick={() => handleConnect(true)}>
                                                        Connect
                                                    </button>
                                                }
                                            </>
                                        }{(isConnectedWithUser && !props?.isSubscribed) &&
                                            <button className="studentProfile__studentProfileInfo__ctaSection__btn danger-btn" id='disconnectBtn' onClick={() => openDisconnectModal(true, userInfo)}>
                                                Disconnect
                                            </button>
                                        }
                                        <button className="studentProfile__studentProfileInfo__ctaSection__btn primary-btn" id='shareProfileBtn' onClick={(e) => copyToClipboard(e)} >
                                            Share
                                        </button>
                                    </div>
                                </div>
                                <div className="studentProfile__studentAboutSection">
                                    <p className='studentProfile__studentAboutSection__subTitle'>About {userInfo?.fullName}</p>
                                    <div className="studentProfile__studentAboutSection__studentAboutDescp">
                                        {studentProfile?.currrentSchoolName &&
                                            <span >
                                                <SchoolOutlinedIcon className='descpIcon' />
                                                <p className=''>Graduating from {studentProfile?.currrentSchoolName} in {studentProfile?.graduationYear} </p>
                                            </span>
                                        }
                                        {studentProfile?.universities?.length > 0 &&
                                            <span >
                                                <EmojiEventsOutlinedIcon className='descpIcon' />
                                                <p className=''>Aspiring to attend {studentProfile?.universities?.join(', ')}</p>
                                            </span>
                                        }
                                        {studentProfile?.professions?.length > 0 &&
                                            <span >
                                                <EmojiEventsOutlinedIcon className='descpIcon' />
                                                <p className=''>Aspiring to become {studentProfile?.professions?.join(', ')}</p>
                                            </span>
                                        }
                                        {gpaText(studentProfile?.weighted, studentProfile?.unweighted) &&
                                            <span >
                                                <EmojiEventsOutlinedIcon className='descpIcon' />
                                                <p className=''>{gpaText(studentProfile?.unweighted, studentProfile?.weighted)}</p>
                                            </span>
                                        }
                                    </div>
                                </div>
                                <div className="studentProfile__studentInterestSection">
                                    <p className='studentProfile__studentInterestSection__subTitle'>{userInfo?.fullName} is interested in</p>
                                    <div className="studentProfile__studentInterestSection__studentInterestDescp">
                                        {studentProfile?.studentsCollab &&
                                            <span >
                                                <StarBorderOutlinedIcon className='descpIcon' />
                                                <p className=''>Collaborating with Students with similar goals</p>
                                            </span>
                                        }
                                        {studentProfile?.helpWithCoursesAndActivities &&
                                            <span >
                                                <StarBorderOutlinedIcon className='descpIcon' />
                                                <p className=''>Hiring advisors to help select courses and activities to meet goals</p>
                                            </span>
                                        }
                                        {studentProfile?.helpWithMilestones &&
                                            <span >
                                                <StarBorderOutlinedIcon className='descpIcon' />
                                                <p className=''>Hiring advisors to help with course and activity milestones throughout the semester</p>
                                            </span>
                                        }
                                    </div>
                                </div>
                                {props.studentDetails?.courses?.length > 0 &&
                                    <div className="studentProfile__studentCoursesSection">
                                        <p className='studentProfile__studentCoursesSection__subTitle'>Courses</p>
                                        <div className="studentProfile__studentCoursesSection__courses">
                                            {props.studentDetails?.courses?.map(course => {
                                                console.log(course, "coursedjjh")
                                                return (
                                                    <CourseCard checkable data={course} key={course?.id}>
                                                        {getCourseStatus(course?.planPhase)}
                                                    </CourseCard>
                                                )
                                            })}
                                        </div>
                                    </div>
                                }
                                {props.studentDetails?.activities?.length > 0 &&
                                    <div className="studentProfile__studentCoursesSection">
                                        <p className='studentProfile__studentCoursesSection__subTitle'>Activities</p>
                                        <div className="studentProfile__studentCoursesSection__courses">
                                            {props.studentDetails?.activities?.map(activity => {
                                                return (
                                                    <ActivityCard data={activity} key={activity?.id}>
                                                        {getCourseStatus(activity?.planPhase)}
                                                    </ActivityCard>
                                                )
                                            })}
                                        </div>
                                    </div>
                                }
                                {props.studentPlanData?.length > 0 &&
                                    <div className="studentProfile__studentPlanSection">
                                        <p className='studentProfile__studentPlanSection__subTitle'>Plans</p>
                                        <div className="studentProfile__studentPlanSection__plans">
                                            {props.studentPlanData?.map(planData => {
                                                return (
                                                    <PlanCard planData={planData} profileView={true} />
                                                )
                                            })}
                                        </div>
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>
            </Modal>
            {showAccessModal &&
                <>
                    {props?.userProfile?.currentRole === "advisor" ? (
                        <AdvisorPlanAccessModal onClose={() => handleConnect(false)} show={showAccessModal} studentData={userInfo} />
                    ) :
                        (
                            <StudentPlanAccessModal onClose={() => handleConnect(false)} show={showAccessModal} studentData={userInfo} />
                        )
                    }
                </>
            }
            {showDisconnectModal &&
                <ConfirmationPopup show={showDisconnectModal} handleSubmit={() => handleDisconnect()} message={`You and ${disconnectUserData?.fullName} will no longer be connected to each other`} btnText="Confirm" onHide={() => openDisconnectModal(false)} />
            }
            <ConfirmationPopup show={showCancelModal} handleSubmit={() => handleCancelRequest()} message={`Please confirm that you would like to revoke this connection request for this student`} btnText="Confirm" onHide={() => handleShowCancelModal(false)} />

            <ConfirmationPopup show={showAcceptRequestModal} handleSubmit={() => handleAcceptRequest({}, acceptRequestData, true)} message={`Accepting this request will enable you to send messages to this student and access each others work`} btnText="Confirm" onHide={() => openAcceptRequestModal({}, false)} />
            <RejectPopup show={showRejectRequestModal} data={rejectData} handleConnectValues={(rejectReason, rejectdatas) => handleAcceptRequest(rejectReason, rejectdatas, false)} handleClose={(e) => setShowRejectRequestModal(false)} userProfile={props?.userProfile?.currentRole} />

        </>
    )
}

const mapStateToProps = ({ commonData, studentData, userProfileData }) => {
    const { userProfile, isDisconnectUser, childprofile } = userProfileData
    const { loadStudentPlanDataStatus, studentPlanData, detailedUserData, detailedUserDataSuccess, userConnectSuccess, cancelrequest, pendingRequestStatus, isRequestRespond, parentRequest } = commonData
    const { studentDetailsStatus, studentDetails, getstudentplan, studentplanstatus } = studentData
    return {
        loadStudentPlanDataStatus, studentPlanData, detailedUserData, detailedUserDataSuccess, userConnectSuccess, pendingRequestStatus,
        studentDetailsStatus, studentDetails, getstudentplan, studentplanstatus, userProfile, childprofile, isDisconnectUser, cancelrequest, isRequestRespond, parentRequest
    }
};

export default connect(mapStateToProps, {
    clearMessage, getStudentPlanDetails, resetStudentPlansData, getStudentPlanStatus, disconnectUser, CancelRequest, CancelRequestStatus,
    getStudentDetail, getStudentDetailstatus, resetStudentDetails, getDetailedUserProfile, settDetailedUserProfileStatus, userConnectStatus, disconnectUserStatus,
    sendRespondToRequest, setPendingRequestStatus, setSendRespondToRequestStatus, getUserProfile, sendRespondToParentRequest, respondToParentRequestStatus
})(Index);