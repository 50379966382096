import React, { useState, useEffect } from "react";
import "../profile.less"
import { Button, Modal, Typography, Box, Stack, Grid, TextField, Autocomplete, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';


const validation = Yup.object({
  cardNumber: Yup.string().required("* Required").nullable().max(16, " Max upto 16 numbers").min(16, " Min upto 16 numbers"),
  cardHolder: Yup.string().required("* Required").nullable(),
  monthData: Yup.string().required("* Required").nullable(),
  yearData: Yup.string().required("* Required").nullable(),
  cvvNumber: Yup.string().required("* Required").nullable(),
});


export const AddBillingCard = (props) => {
  const { register, handleSubmit, watch, control, formState: { errors } } = useForm({ resolver: yupResolver(validation), });
  const onSubmit = data => console.log(data);
  const [value, setValue] = useState(null);
  const [yearValue, setYear] = useState(null);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="">
          <p className="profile__form__sub-title" style={{ marginTop: '1rem', marginBottom: '2rem' }}>Add Another Card Info</p>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={6}>
              <p style={{ color: '#9B9B9B', marginBottom: '2rem', fontSize: '15px' }}>Card Number</p>
              <div className="input-non-floating-label ">
                <div style={{ display: 'flex', }}>
                  <CreditCardOutlinedIcon sx={{ fill: '#919293', marginTop: '10px', marginRight: '10px' }} />
                  <input type="number" onWheel={(e) => e.target.blur()} {...register("cardNumber")} className={"textbox--primary textbox--rounded input"}
                    name="cardNumber" placeholder="0000 0000 0000 0000" />
                </div>
                <div className="error-text error-text-billing">{errors.cardNumber?.message}</div>

              </div>
              <div className="input-non-floating-label ">
                <div style={{ display: 'flex' }}>
                  <GroupAddOutlinedIcon sx={{ fill: '#919293', marginTop: '10px', marginRight: '10px' }} />
                  <input type="text" {...register("cardHolder")} className={"textbox--primary textbox--rounded input"}
                    name="cardHolder" placeholder="Card holder name"
                  />

                </div>
                <div className="error-text error-text-billing">{errors.cardHolder?.message}</div>

              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <p style={{ color: '#9B9B9B', marginBottom: '2rem', fontSize: '15px' }}>Expiry Date</p>
              {/* <Grid container spacing={2}>
                <Grid item xs={1}>
                  <CalendarTodayOutlinedIcon sx={{ fill: '#919293', marginTop: '10px', marginRight: '10px' }} />
                </Grid>
                <Grid item xs={5.6}>

               
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack spacing={3}>
                      <DateTimePicker
                        // label="Responsive"
                        inputFormat="MM"
                        views={['month']}
                        renderInput={(params) => <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            value: value === null ? '' : value && value?.getMonth() + 1
                          }}
                        />}
                        value={value}
                        onChange={(newValue) => {
                          setValue(newValue);
                        }}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={5.4}>
                  <div className=" col-12 input-non-floating-label">
                    
                 
                  </div>
                </Grid>

              </Grid> */}
              <div className=" col-12 input-non-floating-label ">
                <div style={{ display: 'flex' }}>
                  <CalendarTodayOutlinedIcon sx={{ fill: '#919293', marginTop: '10px', marginRight: '10px' }} />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} className='billingFormParent'>
                      <LocalizationProvider dateAdapter={AdapterDateFns} className='billingMonth'>
                        <Stack spacing={3}>
                          <DateTimePicker
                            showToolbar={false}
                            name="monthData"
                            inputFormat="MM"
                            views={['month']}
                            renderInput={(params) => <TextField
                              {...params}
                              focused={false}
                              placeholder="mm"
                              inputProps={{
                                ...params.inputProps,
                                value: value === null ? '' : value && value?.getMonth() + 1
                              }}
                            />}
                            value={value}
                            onChange={(newValue) => {
                              setValue(newValue);
                            }}
                          />
                        </Stack>
                      </LocalizationProvider>
                      <div className="error-text">{errors.yearData?.message}</div>

                    </Grid>
                    <Grid item xs={12} sm={6} className='billingFormParent'>
                      <LocalizationProvider dateAdapter={AdapterDateFns} className='billingMonth'>
                        <Stack spacing={3}>
                          <DateTimePicker
                            showToolbar={false}
                            name="yearData"
                            inputFormat="yyyy"
                            views={['year']}
                            renderInput={(params) => <TextField
                              {...params}
                              placeholder="yyyy"
                            />}
                            focused={false}
                            value={yearValue}
                            onChange={(newValue) => {
                              setYear(newValue);
                            }}
                          />
                        </Stack>
                      </LocalizationProvider>
                      <div className="error-text">{errors.yearData?.message}</div>

                    </Grid>

                  </Grid>
                </div>
              </div>

              <div className=" col-12 input-non-floating-label ">
                <div style={{ display: 'flex' }}>
                  <LockOutlinedIcon sx={{ fill: '#919293', marginTop: '10px', marginRight: '10px' }} />
                  <input type="number" onWheel={(e) => e.target.blur()} {...register("cvvNumber")} className={"textbox--primary textbox--rounded input"}
                    name="cvvNumber" placeholder="CVV"
                  />
                </div>
                <div className="error-text error-text-billing">{errors.cvvNumber?.message}</div>
              </div>

              <div className=" col-12 ">
                <Button variant="contained" sx={{ float: 'right', marginTop: '1rem' ,width: '200px', borderRadius: '10px', height:"50px" ,fontSize: "18px" }} type="submit" className="blueBtn" >Save</Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </form>
    </>
  )
}
