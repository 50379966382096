import { GetObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";

const region = process.env.REACT_APP_S3_REGION;
const accesskey = process.env.REACT_APP_S3_ACCESSKEY;
const secretkey = process.env.REACT_APP_S3_ACCESSKEY_SECRETKEY;
const bucketName = process.env.REACT_APP_S3_ACCESSKEY_BUCKETNAME;

let s3Client = null

export const initializeClient = async () => {
    if (!s3Client) {
        s3Client = new S3Client({
            region: region,
            credentials: {
                accessKeyId: accesskey,
                secretAccessKey: secretkey
            },
            endpoint: 'https://s3.us-east-2.amazonaws.com'
        });
    }
    return s3Client;
};


export const generatePresignedUrl = async (key, expirationSeconds = 3800) => {
    const client = await initializeClient();
    const command = new GetObjectCommand({ Bucket: bucketName, Key: key });
    const url = await getSignedUrl(client, command, { expiresIn: expirationSeconds });
    return url;
}