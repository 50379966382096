import React, { useState } from 'react'
import "./courseAccessCard.less";

const Index = ({children, data}) => {
    const [viewMore, setViewMore] = useState(false);

	const stripHTMLTags = (html) => {
        const tempElement = document.createElement("div");
        tempElement.innerHTML = html;
        return tempElement.textContent || tempElement.innerText || "";
    };
	return (
		<div className='courseVerticalCard'>
			<div className="courseVerticalCard__topSec">
				<p className='courseVerticalCard__topSec__title'>{data?.courseName || data?.activityName}</p>
				{children}
			</div>
			<div className="courseVerticalCard__bottomSec">
				<p className="courseVerticalCard__bottomSec__label"> Type
					<span>{data?.courseType || data?.activityType}</span>
				</p>
				<p className="courseVerticalCard__bottomSec__label"> Semester
					<span>{data?.planPhase?.title}</span>
				</p>
				<p className={`courseVerticalCard__bottomSec__${viewMore ? 'description' : 'detailedDescription'}`} onClick={() => setViewMore(!viewMore)}>
					{stripHTMLTags(data?.description)}
				</p>
			</div>
		</div>
	)
}

export default Index