import React, { useState, useEffect } from "react";
import "../profile.less"
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "react-bootstrap";
import { useComponentDidUpdate } from "../../../../utils/useEffectHooks";
import { withRouter } from "../../../../redux/store/navigate";
import { connect } from "react-redux";
import { clearErrorList, changePasswordStatus} from "../../../../redux/actions";

const validation = Yup.object({
  password: Yup.string().required("* Required").nullable().min(6, 'Minimum 6 characters required')
    .matches(/[a-z]/, "Password requires atleast 1 lowercase")
    .matches(/[A-Z]/, "Password requires atleast 1 uppercase")
    .matches(/[0-9]/, "Password requires atleast 1 digit")
    .matches(/(?=.*?[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/, "Password requires 1 Special character"),
  newPassword: Yup.string().required("* Required").nullable()
    .matches(/[a-z]/, "Password requires atleast 1 lowercase")
    .matches(/[A-Z]/, "Password requires atleast 1 uppercase")
    .matches(/[0-9]/, "Password requires atleast 1 digit")
    .matches(/(?=.*?[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/, "Password requires 1 Special character")
    .min(6, 'Minimum 6 characters required')
    .test('passwords-match', 'New password must be different from current password', function (value) {
      return this.parent.password !== value;
    }),
  confirmPassword: Yup.string().required("* Required").nullable().oneOf([Yup.ref('newPassword'), null], 'Mismatch in confirm password with new password'),
});


const ResetPassword = (props) => {
  const [errorList, setErrorList] = useState([]);
  const { register, handleSubmit, watch, reset, control, clearErrors, formState: { errors } } = useForm({ resolver: yupResolver(validation), });

  const onSubmit = data => {
    props?.handleFormValues(data)
  }

  const onValidationChange = e => {
    clearErrors('newPassword')
    clearErrors('password')
    clearErrors('confirmPassword')
    if (props?.errorList) {
      setErrorList([])
      props?.clearErrorList()
    }
  }

  useEffect(() => {
    if (props?.isPasswordUpdate) {
      reset()
      props?.changePasswordStatus(false)
    }
  }, [props.isPasswordUpdate])


  useComponentDidUpdate(() => {
    if (props?.errorList) {
      setErrorList(props?.errorList)
    }
  }, [props?.errorList]);

  return (
  
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="">
          <p className="profile__form__sub-title" style={{ marginTop: '1rem', marginBottom: '2rem' }}>Change Password</p>
          <div className="formsection changePswForm">
            <div className="row">
              <div className=" col-12 ">
                <div className="input-floating-label">
                  <input type="password" {...register("password")} onChange={(e) => { onValidationChange(e); }} placeholder=" " className={"textbox--primary textbox--rounded input"} name="password" />
                  <label>{"Current password"}</label>
                  <div className="error-text">{errors.password?.message}</div>
                  {errorList?.password && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.password}</div>}

                </div>
              </div>
              <div className=" col-12 ">
                <div className="input-floating-label">
                  <input type="password" {...register("newPassword")} onChange={(e) => { onValidationChange(e); }} placeholder=" " className={"textbox--primary textbox--rounded input"} name="newPassword" />
                  <label>{"Create new password"}</label>
                  <p className="cta--text mb-1" style={{ "fontSize": "13px", }}>Must contain 1 uppercase, 1 lowercase, 1 digit, 1 special character and atleast 6 or more character</p>
                  <div className="error-text">{errors.newPassword?.message}</div>
                  {errorList?.newPassword && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.newPassword}</div>}
                </div>
              </div>
              <div className=" col-12 ">
                <div className="input-floating-label">
                  <input type="password"  {...register("confirmPassword")} onChange={(e) => { onValidationChange(e); }} placeholder=" " className={"textbox--primary textbox--rounded input"} name="confirmPassword" />
                  <label>{"Confirm password"}</label>
                  <div className="error-text">{errors.confirmPassword?.message}</div>
                  {errorList?.confirmPassword && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.confirmPassword}</div>}
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-11 col-11 ps-0 p-mob-0" style={{ display: "flex", justifyContent: "center", marginInline: 'auto', }}>
                <Button type="submit" className="btn cta--rounded cta-primary "  style={{borderRadius: '10px'}}> <span style={{paddingLeft: '20px', paddingRight: '20px'}}>Reset password</span> </Button>
              </div>
            </div>
          </div>
        </div>
      </form>
 
  )
}
const mapStateToProps = ({ userProfileData, commonData }) => {
  const { isPasswordUpdateerror, isPasswordUpdate } = userProfileData;
  return { isPasswordUpdateerror, isPasswordUpdate };
};
export default connect(mapStateToProps, {clearErrorList, changePasswordStatus})(withRouter(ResetPassword));
