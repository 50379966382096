import React, { Component } from "react";
import "../../../assets/css/profile.less";
import AdvisorProfile from "./AdvisorProfile"
import StudentProfile from "./StudentProfile"
import ParentProfile from "./ParentProfile";
import { getUserProfile } from "../../../redux/actions"
import { connect } from "react-redux";
import { withRouter } from "../../../redux/store/navigate";
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false,
            loading: false,
            student: false,
            advisor: false,
            parent: false
        };
    }
    componentDidMount() {
        this.setProfile();
        window.scrollTo(0, 0);
    }
    componentDidUpdate() {
        this.setProfile()
    }
    setProfile() {
        if (!this.state.dataLoaded) {
            if (this.props.userProfile && this.props.userProfile && this.props.userProfile.currentRole) {
                if (this.props.userProfile.currentRole === "student") {
                    this.setState({
                        student: true,
                        advisor: false,
                        parent: false,
                    });
                }
                else if (this.props.userProfile.currentRole === "advisor") {
                    this.setState({
                        student: false,
                        advisor: true,
                        parent: false,
                    });
                }
                else if (this.props.userProfile.currentRole === "parent") {
                    this.setState({
                        student: false,
                        advisor: false,
                        parent: true,
                    });
                }
                this.setState({ dataLoaded: true })
                // else if (this.props.userProfile.currentRole === "" || undefined) {
                //     window.history.go(-1)
                // }
            }

        }
    }
    render() {
        let { student, advisor, parent } = this.state;
        return (
            <>
                {student &&
                    <StudentProfile />
                }
                {advisor &&

                    <AdvisorProfile />
                }
                {parent &&
                    <ParentProfile />
                }

            </>
        );
    }
}

const mapStateToProps = ({ commonData, userProfileData }) => {
    const { message, errorList } = commonData
    const { userProfile } = userProfileData;
    return { message, errorList, userProfile }
};

export default connect(mapStateToProps, { getUserProfile })(withRouter(Index));
