import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getConnectedFriendSwitch, setChildSwichStatus, resetDetailedUserProfileStatus, disconnectSwitchFriend, disconnectUserSwitchStatus } from "../../../../redux/actions"
import ViewStudentProfile from '../../../../componentsNew/Common/ViewStudentProfile';
import ConnectedStudents from "../../../../componentsNew/Common/Cards/Common/ConnectedStudents";
import Loader from '../../../../components/Loader';
import { useNavigate } from 'react-router-dom';
import ConfirmationPopup from "../../../../componentsNew/Common/ConfirmationPopup";



const Index = (props) => {
    const [loading, setLoading] = useState(false)
    const [showProfile, setShowProfile] = useState(false);
    const [profileData, setProfileData] = useState({});
    const [showDisconnectModal, setShowDisconnectModal] = useState(false);
    let navigate = useNavigate();


    useEffect(() => {
        let childId = localStorage.getItem("parentViaChild")
        setLoading(true)
        props.getConnectedFriendSwitch(childId)

    }, [])
    useEffect(() => {
        if (props.connectedFriendStatus) {
            props.setChildSwichStatus(false)
            setLoading(false)
        }
        if (props?.isDisconnectFriend) {
            setShowDisconnectModal(false)
            setLoading(false)
            props?.disconnectUserSwitchStatus(false);
            let childId = localStorage.getItem("parentViaChild")
            props.getConnectedFriendSwitch(childId)

        }
    }, [props.connectedFriendStatus, props?.isDisconnectFriend])

    const handleViewProfile = (userInfo) => {
        setProfileData(userInfo);
        showProfileModal(true);
    }
    const showProfileModal = (status) => {
        if (!status) {
            setProfileData({})
            props?.resetDetailedUserProfileStatus();
        }
        setShowProfile(status)
    }

    const HandleNavigate = (e) => {
        e.preventDefault();
        navigate('/parent/mynetwork')
    }

    const openDisconnectModal = (status, data) => {
        setProfileData(data)
        setShowDisconnectModal(status);
    }

    const handleDelete = () => {
        let userdata = profileData
        if (userdata?.id) {
            setLoading(true)
            let childId = localStorage.getItem("parentViaChild")
            let payload = {
                userId: userdata?.id

            }
            props?.disconnectSwitchFriend(childId, payload)

        }
    }

    return (
        <>
            {loading && <Loader />}
            {showDisconnectModal &&
                <ConfirmationPopup show={showDisconnectModal} handleSubmit={() => handleDelete()} message={`You and ${profileData?.fullName} will no longer be connected to each other`} btnText="confirm" onHide={() => setShowDisconnectModal(false)} />
            }
            {showProfile && <ViewStudentProfile show={showProfile} profileData={profileData} handleClose={() => showProfileModal(false)} />}
            <div className='Network__Content'>
                {props?.connectedStudentSwitch?.length > 0 ? (
                    props.connectedStudentSwitch?.map(userInfo => {
                        return (
                            <div style={{ margin: "0 20px 20px 0" }}>
                                <ConnectedStudents key={userInfo?.id}
                                    name={userInfo?.fullName}
                                    country={userInfo?.country}
                                    school={userInfo?.studentProfile?.currrentSchoolName}
                                    gpa={userInfo?.studentProfile?.desiredGpa}
                                    universities={userInfo?.studentProfile?.universities?.sort((a, b) => { return a.length - b.length })}
                                    professions={userInfo?.studentProfile?.professions?.sort((a, b) => { return a.length - b.length })}
                                    // meetTime="You are scheduled to meet on dd/mm at hh:mi TZ"
                                    weighted={userInfo?.studentProfile?.weighted}
                                    unWeighted={userInfo?.studentProfile?.unweighted}
                                    recipientUrl='advisor/messenger'
                                    reDirectUrl='/parent/mywork/student'
                                    studentId={userInfo?.id}
                                    avatarUrl={userInfo?.avatarPath}
                                    courseAndActivities={userInfo?.studentProfile?.courses?.concat(userInfo?.studentProfile?.activities)}
                                >
                                    <button className="suggestedStudentCard__buttonSec__danger-btn" onClick={() => openDisconnectModal(true, userInfo)}>
                                        Disconnect
                                    </button>
                                    <button className="suggestedStudentCard__buttonSec__primary-btn" onClick={() => handleViewProfile({ id: userInfo?.id })}>
                                        View Profile
                                    </button>
                                </ConnectedStudents>
                            </div>
                        )
                    })
                ) :
                    <div className='Empty__Container'>
                        <div className='Backdrop__container__card'>
                            <div className='title_cnt'>
                                <p>Let’s get you connected with some students who can help you achieve your goals !</p>
                            </div>
                            <div className='btn_cnt'>
                                <p onClick={(e) => HandleNavigate(e)}>
                                    <span>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="11" cy="11" r="6" stroke="#222222" />
                                            <path d="M11 8C10.606 8 10.2159 8.0776 9.85195 8.22836C9.48797 8.37913 9.15726 8.6001 8.87868 8.87868C8.6001 9.15726 8.37913 9.48797 8.22836 9.85195C8.0776 10.2159 8 10.606 8 11" stroke="#222222" stroke-linecap="round" />
                                            <path d="M20 20L17 17" stroke="#222222" stroke-linecap="round" />
                                        </svg>
                                    </span>
                                    Explore Students
                                </p>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

const mapStateToProps = ({ commonData, userProfileData }) => {
    const { message, errorList, connectedStudentSwitch, connectedFriendStatus } = commonData;
    const { isDisconnectUser, isDisconnectFriend } = userProfileData
    return { message, errorList, connectedStudentSwitch, connectedFriendStatus, isDisconnectUser, isDisconnectFriend };
};
export default connect(mapStateToProps, { getConnectedFriendSwitch, setChildSwichStatus, resetDetailedUserProfileStatus, disconnectUserSwitchStatus, disconnectSwitchFriend, })(Index);