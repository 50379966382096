import React, { Component } from "react";
import { components } from "react-select";
import { Link } from "react-router-dom";
import "../../../../assets/css/plans.less";
import { connect } from "react-redux";
import { withRouter } from "../../../../redux/store/navigate";
import CustomAlert from "../../../../components/Alert";
import Loader from "../../../../components/Loader";
import { Form } from "react-bootstrap";
import { GetStudentPlanStatus, setIsUserProfileSuccess, ConnectParent, CreatePlan, GetStudentPlan, getUniversities, EditPlan, getUserGoals, AdvisorInvite, AdvisorInviteStatus, getInviteAdvisor, EditPlanStatus, CreatePlanStatus, clearMessage, getInviteAdvisorStatus, AdvisorInviteErrorStatus, clearErrorList } from "../../../../redux/actions";
import { Plan_Name, ASSIGN_EXISTING_ADVISOR } from "../../../../Constants/commonLabels";
import { universityApi } from "../../../../utils/AutoComplete";
import CreatableSelect from 'react-select/creatable';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import CancelIcon from '@mui/icons-material/Cancel';
import Footer from '../../../../componentsNew/Common/Footer'

const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder}</Placeholder>
            {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
        </ValueContainer>
    );
};
const MultiValueRemove = (props) => {
    return (
        <components.MultiValueRemove {...props} >
            <CancelIcon style={{ "fill": "rgba(34, 34, 34, 0.67)", "width": "23px", "height": "49px" }} />
        </components.MultiValueRemove>
    );
};
class Index extends Component {
    constructor(props) {
        super(props);
        this.refs = React.createRef();
        this.state = {
            loading: false,
            dataLoaded: false,
            universities: false,
            universityCheckBox: false,
            professionsCheckBox: false,
            gPACheckBox: false,
            fields: { planname: "" },
            errors: {},
            selectedUniversityOption: null,
            selectedProfessionOption: null,
            universityData: [],
            noOptionLength: "",
            selectedEmail: [],
            alertvisible: false,
            loadIntial: false,
            errorList:[]
        };
    }
    componentDidMount() {
        this.setState({ loading: true })
        let value = {
            "searchString": "",
            "pageNo": 1,
            "limit": 10,
            "status": [],
            "sortOrder": "latest",
            "studentId": null
        }
        this.props.GetStudentPlan(value)
        this.setInitialValues();
        this.props.getUserGoals();
        this.props.clearMessage();
        this.props.setIsUserProfileSuccess(false)
    }
    componentWillReceiveProps(newProps) {
        this.setState({
            errorList: newProps.errorList, listOfUniversities: newProps.universities,
        });
        if (newProps.studentplanstatus) {
            this.setState({ loadIntial: true, dataLoaded: false })
        }
    }
    componentDidUpdate() {
        if (!this.state.universities) {
            this.setState({ loading: false, universities: true })
        }
        this.setInitialValues();
        if (this.props.iscreateplan) {
            this.props.navigate('/onboarding/intialplans');
            this.props.CreatePlanStatus(false)
        }
        if (this.props.iseditplanphase) {
            this.props.navigate('/onboarding/intialplans');
            this.props.EditPlanStatus(false);
        }
    }

    loadOptions = (value, callback) => {
        // universityApi(inputText).then((res) => callback(res.data.data?.map((item) => ({ label: item.university, value: item.university }))));
        if (value === "") {
            this.setState({ universityData: [] });
        }
        this.setState({ isload: true });
        this.setState({ noOptionLength: value });
        let list = []
        if (value !== "") {
            universityApi(value).then((res) => {
                if (res.status === 200) {
                    res?.data?.data.map((item, index) => {
                        list.push({
                            label: item.university,
                            value: item.university
                        });

                    });
                    this.setState({ universityData: list });
                } else {
                    this.setState({ universityData: [] });
                }

                this.setState({ isload: false });
            })
        }

    }
    handleUniversityText = (e) => {
        let { errors, fields, listOfUniversities } = this.state;
        fields['universityName'] = e;

        this.setState({ fields });
        if (e) {
            this.props.getUniversities({ "searchString": e })
        } else {
            this.setState({ listOfUniversities: [] })
        }
    }
    handleSuggestChange = (value) => {
        let { fields, listOfUniversities } = this.state
        fields['universityName'] = value
        this.setState({ fields, listOfUniversities: [] })
    }
    renderSuggestions = () => {
        let { fields, listOfUniversities } = this.state
        if (listOfUniversities?.length === 0) {
            return null;
        }
        return (
            <ul className="px-1 py-2">
                {listOfUniversities?.map((item) => <li className="w-100 py-1 university-item" style={{ cursor: 'pointer', }} onClick={() => this.handleSuggestChange(item.university, 'universityName')}>{item.university}</li>)}
            </ul>

        );
    }
    onMenuOpen = () => {
        this.setState({ openSuggession: true })
    }
    onMenuClose = () => {
        this.setState({ openSuggession: false })
    }
    filterColors = (inputValue) => {
        let { listOfUniversities } = this.state
        listOfUniversities.filter((e) => e.university.toLowerCase().includes(inputValue.toLowerCase()));
        return listOfUniversities.map(item => ({ 'label': item.university, 'value': item.university }))
    };

    handleFocus = () => {
        const { selectedOption } = this.state;
        if (selectedOption && selectedOption.label) {
            this.setState({ inputValue: selectedOption.label });
        }
    };
    handleCheckboxChange(e, buttonName) {
        let { universityCheckBox, professionsCheckBox, gPACheckBox, errors } = this.state;
        if (buttonName === "universities") {
            universityCheckBox = e.target.checked
            errors["goal"] = ""
        } else if (buttonName === "profession") {
            professionsCheckBox = e.target.checked
            errors["goal"] = ""
        } else if (buttonName === "GPA") {
            gPACheckBox = e.target.checked
            errors["goal"] = ""
        }
        this.setState({ universityCheckBox, professionsCheckBox, gPACheckBox, errors })
    }
    handleChange(field, e) {
        let { errors, errorList } = this.state;
        let fields = this.state.fields;
        fields[field] = e.target.value;
        if (e.target.value.length >= 0) {
            errors[field] = "";
            this.props.clearErrorList()
            this.props.clearMessage()
        }
        this.setState({ fields, errors: errors });
    }
    handleSelectChange(options, name) {
        let { errors } = this.state;
        let { selectedUniversityOption, selectedProfessionOption } = this.state;
        switch (name) {
            case "university":
                selectedUniversityOption = options;
                errors['university'] = ""
                break;
            case "professions":
                if (options.length > 10) {
                    errors['profession'] = "Max upto 10 proffession allowed"
                }
                else {
                    if (options) {
                        if (options.at(-1)?.value?.length > 50 && options.at(-1)?.__isNew__ === true) {
                            errors['profession'] = "Should not exceed 50 characters"
                        }
                        else if (options.at(-1)?.value?.length < 50 && options.at(-1)?.__isNew__ === true) {
                            selectedProfessionOption = options
                            errors['profession'] = ""
                        }
                        else {
                            selectedProfessionOption = options
                            errors['profession'] = ""
                        }
                    }
                }
                break;
        }
        this.setState({ selectedUniversityOption, selectedProfessionOption });
    }
    handleScroll = () => {
        window.scrollTo(0, 400)
    }
    setInitialValues() {
        let { universityCheckBox, professionsCheckBox, gPACheckBox, fields, selectedUniversityOption, selectedProfessionOption, universityData, selectedEmail,errors } = this.state
        if (this.props.getstudentplan?.length === 0) {
            if (this.state.loadIntial && !this.state.dataLoaded) {
                if (this.props.isgetusergoals && this.props.isgetusergoalsstatus && this.props.userProfile && this.props.userProfile.studentProfile && this.props.userProfile.studentProfile.goals) {
                    let goalesData = this.props.isgetusergoals.goals
                    if (goalesData && goalesData.length > 0) {
                        goalesData && goalesData.map((data, key) => {    
                            if (data == "university") {
                                universityCheckBox = false;
                            } else if (data == "weighted" && "unweighted") {
                                gPACheckBox = false;
                            } else if (data == "profession") {
                                professionsCheckBox = false;
                            }
                        })
                    }
                    if (this.props.invitedAdvisorList && this.props.invitedAdvisorList?.length > 0) {
                        let { fields, selectedEmail } = this.state;
                        fields["advisorEmails"] = this.props?.invitedAdvisorList.join(", ");
                        if (this.props?.invitedAdvisorList != undefined) {
                            this.props?.invitedAdvisorList?.map((email) => {
                                selectedEmail.push(email)
                            })
                        }
                        this.setState({ selectedEmail: selectedEmail })
                    }
                    // DATA VALUE MAPPING
                    // GPA DATA
                    let userProfileData = this.props.isgetusergoals;
                    // fields["GPA"] = userProfileData.desiredGpa ? userProfileData.desiredGpa : "";
                    fields["weighted"] = userProfileData.targetWeighted ? userProfileData.targetWeighted : "";
                    fields["unweighted"] = userProfileData.targetUnweighted ? userProfileData.targetUnweighted : "";
                    // University DATA
                    let interestedUniversities = userProfileData.universities;
                    let universityOptionstions = [];
                    interestedUniversities !== undefined && interestedUniversities.length > 0 && interestedUniversities.map((university, key) => {
                        var temp = { label: university, value: university }
                        universityOptionstions.push(temp);
                    });
                    selectedUniversityOption = universityOptionstions;
                    // Profession DATA
                    let interestedProfession = userProfileData.professions;
                    let professionOptionstions = [];
                    interestedProfession !== undefined && interestedProfession.length > 0 && interestedProfession.map((profession, key) => {
                        var temp = { label: profession, value: profession }
                        professionOptionstions.push(temp);
                    });
                    selectedProfessionOption = professionOptionstions;
                    // DATA VALUE MAPPING
                    this.setState({ universityCheckBox, professionsCheckBox, gPACheckBox, selectedUniversityOption, selectedProfessionOption, universityData });
                    this.setState({ dataLoaded: true, loading: false,errors: {} })
                    this.props.GetStudentPlanStatus(false)
                }
            }
        }
        if (this.props.isgetusergoals && this.props.isgetusergoalsstatus && this.props.getstudentplan && this.props.getstudentplan.length > 0) {
            if (this.state.loadIntial && !this.state.dataLoaded) {
                if (this.props.getstudentplan && this.props.getstudentplan?.length > 0 && this.props.getstudentplan[0].planInfo) {
                    let userProfile = this.props.getstudentplan && this.props.getstudentplan?.length > 0 && this.props.getstudentplan[0]?.planInfo;
                    let goalesData = userProfile && userProfile.goals
                    if (goalesData && goalesData.length > 0) {
                        goalesData && goalesData.map((data, key) => {
                            if (data == "university") {
                                universityCheckBox = true;
                            } else if (data == "gpa") {
                                gPACheckBox = true;
                            } else if (data == "profession") {
                                professionsCheckBox = true;
                            }
                        })
                    }
                    let userProfileData = this.props.getstudentplan && this.props.getstudentplan?.length > 0 && this.props.getstudentplan[0]?.planInfo
                    // fields["GPA"] = userProfileData.desiredGpa ? userProfileData.desiredGpa : "";
                    fields["weighted"] = userProfileData.weighted ? userProfileData.weighted : "";
                    fields["unweighted"] = userProfileData.unweighted ? userProfileData.unweighted : "";
                    fields["planname"] = userProfileData.planName ? userProfileData.planName : "";
                    // University Data
                    let interestedUniversities = userProfile && userProfile.universities;
                    let universityOptionstions = [];
                    interestedUniversities !== undefined && interestedUniversities.length > 0 && interestedUniversities.map((university, key) => {
                        var temp = { label: university, value: university }
                        universityOptionstions.push(temp);
                    });
                    selectedUniversityOption = universityOptionstions;
                    // Interset Data
                    let interestedProfession = userProfile && userProfile.professions
                    let professionOptionstions = [];
                    interestedProfession !== undefined && interestedProfession.length > 0 && interestedProfession.map((profession, key) => {
                        var temp = { label: profession, value: profession }
                        professionOptionstions.push(temp);
                    });
                    selectedProfessionOption = professionOptionstions;

                    if (this.props.invitedAdvisorList) {
                        let { fields, selectedEmail } = this.state;
                        fields["advisorEmails"] = this.props?.invitedAdvisorList.join(", ");
                        if (this.props?.invitedAdvisorList != undefined) {
                            this.props?.invitedAdvisorList?.map((email) => {
                                selectedEmail.push(email)
                            })
                        }
                        // this.setState({ selectedEmail });
                        this.setState({ selectedEmail: selectedEmail })
                    }
                    this.setState({ universityCheckBox, professionsCheckBox, gPACheckBox, selectedUniversityOption, selectedProfessionOption, selectedEmail });
                    this.setState({ dataLoaded: true, loading: false,errors: {} })
                    this.props.GetStudentPlanStatus(false)
                }
            }
        }
        this.props.getInviteAdvisorStatus(false)
    }
    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        let { selectedUniversityOption, selectedProfessionOption } = this.state;

        if (fields["planname"] === "") {
            formIsValid = false;
            errors["planname"] = "*Required";
        }

        if (fields["advisorEmails"] && fields["advisorEmails"] !== "") {
            let emailLst = []
            let emailfield = fields["advisorEmails"]?.split(',')
            emailfield?.map(element => {
                if (element !== "") {
                    emailLst.push(element.trim())
                } else {
                    formIsValid = false;
                    errors["advisorEmails"] = "Invalid Email";
                }
            });

            if (emailLst?.length > 0) {
                emailLst?.map(email => {
                    if (email !== "") {
                        const textcaseRegExp = /^[\w-\.+]+@([\w-]+\.)+[\w-]{2,4}$/;
                        const emailvalid = textcaseRegExp.test(email);
                        if (!emailvalid) {
                            formIsValid = false;
                            errors["advisorEmails"] = "Invalid Email";
                        }
                        let lastAtPos = email?.lastIndexOf("@");
                        let lastDotPos = email?.lastIndexOf(".");
                        if (!(lastAtPos < lastDotPos &&
                            lastAtPos > 0 &&
                            email.indexOf("@@") === -1 &&
                            lastDotPos > 2 &&
                            email.length - lastDotPos > 2
                        )) {
                            formIsValid = false;
                            errors["advisorEmails"] = "Invalid Email";
                        }
                    }
                })
            } else {
                formIsValid = false;
                errors["advisorEmails"] = "Invalid Email";
            }
        }
        if (!this.state.universityCheckBox && !this.state.professionsCheckBox && !this.state.gPACheckBox) {
            formIsValid = false;
            errors["goal"] = "Please select any goal";
        }
        if (this.state.universityCheckBox) {
            if (selectedUniversityOption === null) {
                formIsValid = false;
                errors["university"] = "Please select any university";
            }
            else if (selectedUniversityOption.length === 0) {
                formIsValid = false;
                errors["university"] = "Please select any university";
            }

        }
        if (this.state.professionsCheckBox) {
            if (selectedProfessionOption === null) {
                formIsValid = false;
                errors["profession"] = "Please select any profession";
            }
            else if (selectedProfessionOption.length === 0) {
                formIsValid = false;
                errors["profession"] = "Please select any profession";
            }

        }
        if (this.state.gPACheckBox) {
            if (!fields["weighted"]) {
                formIsValid = false;
                errors["weighted"] = "*Required";
            }
            if (!fields["unweighted"]) {
                formIsValid = false;
                errors["unweighted"] = "*Required";
            }
            if (fields["weighted"] !== "") {
                if (typeof fields["weighted"] !== "undefined") {
                    const decimalcaseRegExp = /^(?:[1-4](\.\d{1})?|5(\.0{1})?)$/;
                    const floatcasenumber = decimalcaseRegExp.test(fields["weighted"]);
                    if (!floatcasenumber) {
                        formIsValid = false;
                        errors["weighted"] = "Invalid GPA";
                    }
                }
            }
            if (fields["unweighted"] !== "") {
                if (typeof fields["unweighted"] !== "undefined") {
                    const decimalcaseRegExp = /^(?:[1-3](\.\d{1})?|4(\.0{1})?)$/;
                    const floatcasenumber = decimalcaseRegExp.test(fields["unweighted"]);
            
                    if (!floatcasenumber) {
                        formIsValid = false;
                        errors["unweighted"] = "Invalid GPA";
                    }
                }
            }
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    handleNext = async (e) => {
        this.setState({ alertvisible: false })
        let userProfile = this.props.getstudentplan && this.props.getstudentplan[0]?.planInfo;
        let planId = userProfile && userProfile.id
        if (planId === undefined) {
            if (this.handleValidation()) {
                let goals = [];
                let fields = this.state.fields;
                let { selectedUniversityOption, selectedProfessionOption } = this.state;
                let Unweightedgpa = fields["unweighted"];
                let weightedgpa = fields["weighted"];
                if (this.state.universityCheckBox)
                    goals.push("university");
                else
                    selectedUniversityOption = [];

                if (this.state.professionsCheckBox)
                    goals.push("profession");
                else
                    selectedProfessionOption = [];
                if (this.state.gPACheckBox)
                    goals.push("GPA");
                let selectedUniversity = [];
                selectedUniversityOption.forEach(element => {
                    selectedUniversity.push(element.label || element);
                });
                let selectedProfession = [];
                selectedProfessionOption.forEach(element => {
                    selectedProfession.push(element.label);
                });

                let values = {
                    planName: fields.planname,
                    specificUniversity: this.state.universityCheckBox,
                    specificProfession: this.state.professionsCheckBox,
                    improveGpa: this.state.gPACheckBox,
                    plannedUniversities: selectedUniversity,
                    plannedProfessions: selectedProfession,
                    advisorId: null,
                    accessibleAdvisors: []
                }
                if (values.improveGpa === true) {
                    values["weighted"] = weightedgpa
                    values["unweighted"] = Unweightedgpa
                }
                if (values.improveGpa === false) {
                    values["weighted"] = null
                    values["unweighted"] = null
                }
                this.props.CreatePlan(values)
            }
        }
        if (planId !== undefined) {
            if (this.handleValidation()) {
                let goals = [];
                let fields = this.state.fields;
                let { selectedUniversityOption, selectedProfessionOption } = this.state;
                let Unweightedgpa = fields["unweighted"];
                let weightedgpa = fields["weighted"];
                if (this.state.universityCheckBox)
                    goals.push("university");
                else
                    selectedUniversityOption = [];

                if (this.state.professionsCheckBox)
                    goals.push("profession");
                else
                    selectedProfessionOption = [];

                if (this.state.gPACheckBox)
                    goals.push("GPA");
                let selectedUniversity = [];
                selectedUniversityOption.forEach(element => {
                    selectedUniversity.push(element.label || element);
                });
                let selectedProfession = [];
                selectedProfessionOption.forEach(element => {
                    selectedProfession.push(element.label);
                });

                let values = {
                    planName: fields.planname,
                    specificUniversity: this.state.universityCheckBox,
                    specificProfession: this.state.professionsCheckBox,
                    improveGpa: this.state.gPACheckBox,
                    plannedUniversities: selectedUniversity,
                    plannedProfessions: selectedProfession,
                    advisorId: null,
                    accessibleAdvisors: []
                }
                if (values.improveGpa === true) {
                    values["weighted"] = weightedgpa
                    values["unweighted"] = Unweightedgpa
                }
                if (values.improveGpa === false) {
                    values["weighted"] = null
                    values["unweighted"] = null
                }
                this.props.EditPlan(values, planId)
            }
        }
    }
    onTagsChange = (event, values) => {
        let { errors } = this.state;
        this.setState({ selectedUniversityOption: values });
        errors['university'] = ""
    }
    render() {
        let { errorList } = this.state;
        const { universities, professions } = this.props;
        let { universityCheckBox, professionsCheckBox, gPACheckBox, selectedUniversityOption, selectedProfessionOption } = this.state;
        let universityOptionstions = [];
        let professionOptionstions = [];
        universities !== undefined && universities.length > 0 && universities.map((university, key) => {
            var temp = { label: university.university, value: university.university }
            universityOptionstions.push(temp);

        });
        professions !== undefined && professions.length > 0 && professions.map((profession, key) => {
            var temp = { label: profession.profession, value: profession.profession }
            professionOptionstions.push(temp);
        });
        let isGetUserGoals = this.props.isgetusergoals?.goals
        let userProfile = this.props.getstudentplan && this.props.getstudentplan?.length > 0 && this.props.getstudentplan[0]?.planInfo && this.props.getstudentplan[0]?.planInfo?.goals
        return (
            <>
                {this.state.loading && <Loader />}
                {this.state.loading || this.props.loading && <Loader />}
                <div className="role">
                    <div className="role_card" style={{ width: "430px !important", marginBottom: "100px" }}>
                        <h1 className="role_title text-center">Setup initial plan </h1>
                        <Form className="login_card_form" autoComplete="off" >
                            <div className="goals__form">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="input-floating-label">
                                            <input refs="planname" type="text" className={"textbox--primary textbox--rounded input"} name="planname" id="planname"
                                                placeholder="planname" onChange={this.handleChange.bind(this, "planname")} value={this.state.fields["planname"]} />
                                            <label>{Plan_Name}</label>
                                            {this.state.errors["planname"] && <span className="error-text">{this.state.errors["planname"]}</span>}
                                            {errorList && errorList.planName && <span className="error-text">{errorList.planName}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                        <div className="goals__form">
                            <p className="profile__form__sub-title_new" >Select one or more Goals for this Plan</p>
                        </div>
                        <p className="profile__form__sub-title_text mb-5" >Later you can create multiple plans with different sets of courses and activities for different goals</p>
                        <div className="goals_page">
                            {isGetUserGoals?.includes("university") ?
                                isGetUserGoals?.map((val) => {
                                    return (val === "university" ?
                                        <div className="mb-2 role__btns">
                                            <input type="checkbox" ref='universityCheckBox' defaultChecked={universityCheckBox} checked={universityCheckBox} onClick={(e) => this.handleCheckboxChange(e, 'universities')} className="btn-check" name="options1" id="btncheck1" autoComplete="off" />
                                            <label className="btn cta--role--btn w-mob-100" htmlFor="btncheck1">Get into specific universities</label>
                                        </div>
                                        : <></>)
                                }) : userProfile && userProfile?.map((val) => {
                                    return (val === "university" ?
                                        <div className="mb-2 role__btns">
                                            <input type="checkbox" ref='universityCheckBox' defaultChecked={universityCheckBox} checked={universityCheckBox} onClick={(e) => this.handleCheckboxChange(e, 'universities')} className="btn-check" name="options1" id="btncheck1" autoComplete="off" />
                                            <label className="btn cta--role--btn w-mob-100" htmlFor="btncheck1">Get into specific universities</label>
                                        </div>
                                        : <></>)
                                })
                            }

                            {isGetUserGoals?.includes("profession") ?
                                isGetUserGoals?.map((val) => {
                                    return (val === "profession" ?
                                        <div className=" mb-2 role__btns">
                                            <input type="checkbox" ref='professionsCheckBox' defaultChecked={professionsCheckBox} checked={professionsCheckBox} onClick={(e) => this.handleCheckboxChange(e, 'profession')} className="btn-check" name="options2" id="btncheck2" autoComplete="off" />
                                            <label className="btn cta--role--btn w-mob-100" htmlFor="btncheck2">Get into specific professions</label>
                                        </div>
                                        : <></>)
                                }) : userProfile && userProfile?.map((val) => {
                                    return (val === "profession" ?
                                        <div className=" mb-2 role__btns">
                                            <input type="checkbox" ref='professionsCheckBox' defaultChecked={professionsCheckBox} checked={professionsCheckBox} onClick={(e) => this.handleCheckboxChange(e, 'profession')} className="btn-check" name="options2" id="btncheck2" autoComplete="off" />
                                            <label className="btn cta--role--btn w-mob-100" htmlFor="btncheck2">Get into specific professions</label>
                                        </div>
                                        : <></>)
                                })

                            }

                            {isGetUserGoals?.includes("weighted" && "unweighted") ?
                                isGetUserGoals?.map((val) => {
                                    return (val === "weighted" && "unweighted" ?
                                        <div className=" mb-2 role__btns">
                                            <input type="checkbox" ref='gPACheckBox' defaultChecked={gPACheckBox} checked={gPACheckBox} onClick={(e) => this.handleCheckboxChange(e, 'GPA')} className="btn-check" name="options3" id="btncheck3" autoComplete="off" />
                                            <label className="btn cta--role--btn w-mob-100" htmlFor="btncheck3">Improve GPA</label>
                                        </div>
                                        : <></>)
                                })
                                : userProfile && userProfile?.map((val) => {
                                    return (val === "gpa" ?
                                        <div className=" mb-2 role__btns">
                                            <input type="checkbox" ref='gPACheckBox' defaultChecked={gPACheckBox} checked={gPACheckBox} onClick={(e) => this.handleCheckboxChange(e, 'GPA')} className="btn-check" name="options3" id="btncheck3" autoComplete="off" />
                                            <label className="btn cta--role--btn w-mob-100" htmlFor="btncheck3">Improve GPA</label>
                                        </div>
                                        : <></>)
                                })

                            }
                        </div>
                        {this.state.errors["goal"] && <span className="error-text text-center" style={{ display: "table", margin: "0 auto" }}>{this.state.errors["goal"]}</span>}
                        <Form className="login_card_form mb-3" autoComplete="off">
                            <div className="goals__form">
                                {universityCheckBox &&
                                    <div className="row">
                                        <div className="col-md-12 mb-5 role__btns asyncmultiselect multinew" style={{ "padding": "0px" }} >
                                            <Autocomplete multiple noOptionsText={'There is no university related to your search'} className="goals__form__select"
                                                id="tags-filled" name="universityDropdown" type='text' clearOnBlur={false} filterSelectedOptions clearIcon ChipProps forcePopupIcon={false} freeSolo={true} openOnFocus={true}
                                                options={this.state.universityData?.length > 0 ? this.state.universityData.map((option) => option.label) : []}
                                                renderTags={(value, getTagProps) => value.map((option, index) => (<Chip label={option} {...getTagProps({ index })} />))}
                                                filterOptions={(options, state) => options} defaultValue={selectedUniversityOption !== null ? selectedUniversityOption?.map((option) => option.label || option) : []}
                                                onChange={this.onTagsChange} onInputChange={async (event, value) => { this.loadOptions(value) }}
                                                renderInput={(params) => (<TextField {...params} label="Desired University" size="small"
                                                    inputProps={{ ...params.inputProps, onKeyDown: (e) => { if (e.key === 'Enter') { e.preventDefault(); } }, }} sx={{ '& .MuiOutlinedInput-root': { '&:hover fieldset': { borderColor: 'lightgrey', }, } }} />)}
                                            />
                                            {this.state.errors["university"] && <span className="error-text">{this.state.errors["university"]}</span>}
                                        </div>
                                    </div>
                                }
                                {professionsCheckBox &&
                                    <div className="row">
                                        <div className="col-md-12 mb-5 role__btns asyncmultiselect" style={{ "padding": "0px" }}>
                                            <CreatableSelect className="goals__form__select" noOptionsMessage={() => null} classNamePrefix="mySelect" options={professionOptionstions} id="professionsSelect"
                                                closeMenuOnSelect={true} isClearable={false} isMulti components={{ ValueContainer: CustomValueContainer, DropdownIndicator: () => null, IndicatorSeparator: () => null, MultiValueRemove }}
                                                placeholder="Professions" value={selectedProfessionOption} onChange={(value) => this.handleSelectChange(value, 'professions')}
                                                styles={{
                                                    option: (provided, state) => ({ ...provided, cursor: 'pointer', }),
                                                    container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', }),
                                                    placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10 })
                                                }} />
                                            {this.state.errors["profession"] && <span className="error-text">{this.state.errors["profession"]}</span>}
                                        </div>
                                    </div>
                                }
                                {gPACheckBox &&
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 col-12" style={{ "padding": "0px" }}>
                                            <div className="input-floating-label" style={{"width":"95%"}} >
                                                <input refs="unweighted" type="text" className={"textbox--primary textbox--rounded input"} name="unweighted" id="unweighted"
                                                    placeholder="unweighted" onKeyPress={(event) => { if (!/[0-9.]/.test(event.key)) { event.preventDefault(); } }} onChange={this.handleChange.bind(this, "unweighted")} value={this.state.fields["unweighted"]} />
                                                <label>Target Unweighted GPA</label>
                                                {this.state.errors["unweighted"] && <span className="error-text">{this.state.errors["unweighted"]}</span>}
                                                {this.state.errorList["unweighted"] && <span className="error-text">{this.state.errorList["unweighted"]}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-12" style={{ "padding": "0px" }}>
                                            <div className="input-floating-label">
                                                <input refs="weighted" type="text" className={"textbox--primary textbox--rounded input"} name="weighted" id="weighted"
                                                    placeholder="weighted" onKeyPress={(event) => { if (!/[0-9.]/.test(event.key)) { event.preventDefault(); } }} onChange={this.handleChange.bind(this, "weighted")} value={this.state.fields["weighted"]} />
                                                <label>Target Weighted GPA</label>
                                                {this.state.errors["weighted"] && <span className="error-text">{this.state.errors["weighted"]}</span>}
                                                {this.state.errorList["weighted"] && <span className="error-text">{this.state.errorList["weighted"]}</span>}
                                            </div>
                                        </div>
                                    </div>
                                }
                                {this.state.alertvisible &&
                                    <CustomAlert />
                                }
                            </div>
                        </Form>

                    </div>
                </div>
                <Footer currentStep="" reverseLink={"/onboarding/profile"}>
                    <Link className="footers__rightSec__cta" id="nextbtn" to="#" onClick={(e) => this.handleNext(e)} >Add courses and activities
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.92893 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.0222 21.4135 8.08879 20.3147 6.4443C19.2159 4.79981 17.6541 3.51808 15.8268 2.76121C13.9996 2.00433 11.9889 1.8063 10.0491 2.19215C8.10929 2.578 6.32746 3.53041 4.92893 4.92893" strokeWidth="2" className="rolestrokes" />
                            <path d="M15 12L15.7809 11.3753L16.2806 12L15.7809 12.6247L15 12ZM3 13C2.44771 13 2 12.5523 2 12C2 11.4477 2.44771 11 3 11V13ZM11.7809 6.3753L15.7809 11.3753L14.2191 12.6247L10.2191 7.6247L11.7809 6.3753ZM15.7809 12.6247L11.7809 17.6247L10.2191 16.3753L14.2191 11.3753L15.7809 12.6247ZM15 13H3V11H15V13Z" className="rolefills" />
                        </svg>
                    </Link>
                </Footer>
            </>
        );
    }
}

const mapStateToProps = ({ userProfileData, studentData, commonData }) => {
    const { message, errorList, loading, universities, professions, } = commonData
    const { userProfile } = userProfileData;
    const { studentplanstatus, iscreateplan, getstudentplan, iseditplanphase, isgetusergoals, isgetusergoalsstatus, isInviteAdvisor, invitedAdvisorList, invitedAdvisorListstatus, isInviteAdvisorerror, connectedparent } = studentData;
    return { studentplanstatus, message, errorList, userProfile, loading, iscreateplan, getstudentplan, universities, professions, iseditplanphase, isgetusergoals, isgetusergoalsstatus, isInviteAdvisor, invitedAdvisorList, invitedAdvisorListstatus, isInviteAdvisorerror, connectedparent }
};

export default connect(mapStateToProps, { GetStudentPlanStatus, setIsUserProfileSuccess, ConnectParent, getUniversities, CreatePlan, GetStudentPlan, EditPlan, getUserGoals, AdvisorInvite, AdvisorInviteStatus, getInviteAdvisor, EditPlanStatus, CreatePlanStatus, clearMessage, getInviteAdvisorStatus, AdvisorInviteErrorStatus, clearErrorList })(withRouter(Index));