import React, { useState, useCallback, useEffect } from "react";
import { Button, Modal, Grid, Autocomplete, TextField, Chip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getUserGoals, CreateParentPlan, CreatePlanStatus, GetStudentPlan, GetStudentPlanPhase, ClearPlanId, getProfessions, EditPlan, EditPlanStatus, GetLoadPhase } from "../../../redux/actions";
import CloseIcon from '@mui/icons-material/Close';
import { connect } from "react-redux";

import Tags from "../../../componentsNew/Common/Tags";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";


const styles = {
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'relative',
        backgroundColor: '#fff',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
        padding: '0',
        borderRadius: '4px',
        width: '100%',
        height: "100%",
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        overflow: 'auto',
        padding: '20px 60px',
        flexGrow: 1,
    },
};

const Index = (props) => {
    const navigate = useNavigate();

    const handleClose = () => {
        props?.handleClose();
    };

    useEffect(() => {
        if (props.iscreateplan && props.parentPlanId !== "") {
            navigate(`plan/${props.parentPlanId}/course`)
            props.CreatePlanStatus(false)
            props?.ClearPlanId()
        }
    }, [props.iscreateplan, props.parentPlanId])

    let { userInfo, userInfo: { advisorProfile } = {} } = props.data;

    return (
        <Modal
            style={styles.modal}
            open={props.show}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            className="goalsModal"
        >
            <div style={styles.paper}>
                <div style={styles.content}>
                    <div style={{ textAlign: 'right', marginBottom: "20px" }}>
                        <Button onClick={handleClose}><CloseIcon sx={{ fill: 'black' }} /></Button>
                    </div>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div className="profile-card advisorProfileCard">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={10} md={10}>
                                        <div className="advisorProfileCard__info-sec">
                                            {props?.data?.userInfo?.avatarUrl ? (
                                                <div class="advisorProfileCard__info-sec__sub-img advisor-sub-img">
                                                    <img src={props?.data?.userInfo?.avatarUrl} alt="user" style={{ borderRadius: '50%', width: "100%", height: '100%' }} />
                                                </div>
                                            ) :
                                                <div class="advisorProfileCard__info-sec__sub-img">
                                                    <img src={require("../../../assets/images/profileEdit.png")} alt="user" style={{ borderRadius: '50%', width: "100%", height: '100%' }} />
                                                </div>
                                            }
                                            <div className="advisorProfileCard__info-sec__profileCont">
                                                <div className="profileName">
                                                    {userInfo?.fullName}
                                                </div>
                                                <div className="advisorProfileCard__info-sec__profileCont__address-sec">
                                                    <span className="advisorProfileCard__info-sec__profileCont__address-sec__addr-line">
                                                        <RoomOutlinedIcon color="#515050" fontSize="12px" />
                                                        <p className="location">{userInfo?.country}</p>
                                                    </span>
                                                    {
                                                        userInfo?.studentProfile?.currrentSchoolName && (
                                                            <span className="advisorProfileCard__info-sec__profileCont__address-sec__addr-line">
                                                                <HomeOutlinedIcon color="#515050" fontSize="12px" />
                                                                <p className="location"> {
                                                                    "Goes to " + userInfo?.studentProfile?.currrentSchoolName || ""}</p>
                                                            </span>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={2} md={2} className="resConnect">
                                        <div className='netWork-card__button-sec profileConnect ' >
                                            <button className='netWork-card__button-sec__primary-btn'>Connect</button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12} >
                            <div className="profile-card advisorProfileCard">
                                <Grid container spacing={5}>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <p className="profileAboutCard__title">About</p>
                                        <div className="profileAboutCard__details">
                                            <p className="profileAboutCard__details__label">My Status:</p>
                                            <p className="profileAboutCard__details__value"> {userInfo?.studentProfile?.myStatus} </p>
                                        </div>
                                        <div className="profileAboutCard__details">
                                            <p className="profileAboutCard__details__label">{userInfo?.studentProfile?.myStatus?.includes("College") ? "University :" : "High School :"}</p>
                                            <p className="profileAboutCard__details__value"> {userInfo?.studentProfile?.currrentSchoolName} </p>
                                        </div>
                                        {/* { advisorProfile?.universityName &&
                                            <div className="profileAboutCard__details">
                                                <p className="profileAboutCard__details__label">University:</p>
                                                <p className="profileAboutCard__details__value"> {advisorProfile?.universityName}</p>
                                            </div>
                                        } */}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <p className="profileAboutCard__title">Goals:</p>
                                        <div className="profileAboutCard__skills">
                                            {
                                                userInfo?.studentProfile?.goals?.map((course, index) => {
                                                    return (
                                                        <Tags text={course} />
                                                    )
                                                })
                                            }{
                                                advisorProfile?.advisorActivities?.map((activity, index) => {
                                                    return (
                                                        <Tags text={activity?.activityName} />
                                                    )
                                                })
                                            }
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>


                    </Grid>
                </div>
            </div>
        </Modal>
    );
};

const mapStateToProps = ({ userProfileData, studentData, commonData }) => {
    const { message, errorList, loading, universities, professions, } = commonData
    const { userProfile } = userProfileData;
    const { isgetusergoals, isgetusergoalsstatus, iscreateplan, getstudentplan, iseditplanphase, parentPlanId } = studentData;
    return { message, errorList, loading, universities, professions, isgetusergoals, isgetusergoalsstatus, iscreateplan, getstudentplan, iseditplanphase, parentPlanId }
};

export default connect(mapStateToProps, { getUserGoals, CreateParentPlan, CreatePlanStatus, ClearPlanId, GetStudentPlan, GetStudentPlanPhase, getProfessions, EditPlan, EditPlanStatus, GetLoadPhase })(Index);