import React, { useEffect, useState } from "react";
import "./reviewSec.less";
import { Rating } from "@mui/material";
import { useImageUrl } from '../../../../../utils/UseImageURL';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FeedBackPopup from "../../../../../componentsNew/Common/FeedBackPopup";
import { getCountry, FeedbackDelete, DeleteFeedbackReset, getDetailedUserProfile, resetDetailedUserProfileStatus, loadAdvisorReview, FeedbackParentDelete } from "../../../../../redux/actions";
import { withRouter } from "../../../../../redux/store/navigate";
import { connect } from "react-redux";
import ViewMoreFeedBack from "../../../../../componentsNew/Common/ViewFeedbackPopup";
import dayjs from "dayjs";

const Index = (props) => {
    const [feedBack, setFeedBack] = useState(false);
    const [loadMoreFeedBack, setLoadMoreFeedBack] = useState(false);
    const [editFeedBack, setEditFeedBack] = useState(null);

    const AvatarWithUrl = ({ avatarUrl }) => {
        const imageUrl = useImageUrl(avatarUrl);
        return <img className="avatarUrlimage" src={imageUrl} alt="profileimage" />;
    };

    const showFeedBack = (e, data) => {
        e.preventDefault();
        setFeedBack(true);
        setEditFeedBack(data);
    };
    const handleCloseFeedback = () => {
        setFeedBack(false);
        setEditFeedBack(null);
    }

    const viewMoreFeed = (e) => {
        e.preventDefault();
        setLoadMoreFeedBack(true);
    };

    const deleteFeedback = (data) => {
        if (props?.userProfile?.currentRole === "parent") {
            props?.FeedbackParentDelete(data?.advisorReviewId, localStorage?.getItem("parentViaChild"))
        } else {
            props?.FeedbackDelete(data?.advisorReviewId)
        }
    };

    if (props?.deleteFeedBack) {
        let payload = {
            userId: props?.data ? props?.data?.advisorId : props?.advisorDetail?.id,
            pointOfViewStudentId: props?.userProfile?.currentRole === "parent" && localStorage.getItem("parentViaChild")
        }
        let reviewPayload = {
            advisorId: props?.data ? props?.data?.advisorId : props?.advisorDetail?.id,
            "limit": 10,
            "pageNo": 1,
            studentId: localStorage?.getItem("parentViaChild")
        }
        props?.getDetailedUserProfile(payload)
        props?.resetDetailedUserProfileStatus(false)
        props?.DeleteFeedbackReset(false)
        props?.loadAdvisorReview(reviewPayload)
    }
    const { review, userProfile } = props;
    const sortedReviews = review?.slice()?.sort((a, b) => {
        if (a.userId === userProfile?.id) return -1;
        if (b.userId === userProfile?.id) return 1;
        return 0;
    });

    if (userProfile?.currentRole === "student") {
        const userReviewIndex = review.findIndex((data) => data.userId === userProfile?.id);
        if (userReviewIndex > 0) {
            const [userReview] = review.splice(userReviewIndex, 1);
            review.unshift(userReview);
        }
    }
    return (
        <div className="review-sec">
            {(feedBack && editFeedBack) &&
                <FeedBackPopup size="lg" dialogClassName="" fullscreen centered={false} showFeedBack={feedBack}
                    advisorDetail={props?.advisorDetail} onHide={() => handleCloseFeedback()} data={editFeedBack} />
            }
            {loadMoreFeedBack &&
                <ViewMoreFeedBack show={loadMoreFeedBack} onHide={() => setLoadMoreFeedBack(false)} data={props?.review} advisorDetail={props?.advisorDetail} />
            }
            <>
                {review?.slice(0, 2).map((data, key) => (
                    <div className="userReviewCard" key={key}>
                        <div className="userReviewCard_cnt">
                            <div className="userReviewCard_cnt_left">
                                {data?.userImage ? (
                                    <AvatarWithUrl avatarUrl={data?.userImage} />
                                ) : (
                                    <img className="avatarUrlimage" src={require("../../../../../assets/images/profileEdit.png")} alt="profile" />
                                )}
                            </div>
                            <>{console.log(props?.userProfile?.currentRole === "parent" ? localStorage?.getItem("parentViaChild") === data?.userId : props?.userProfile?.id === data?.userId)}</>
                            <div className="userReviewCard_cnt_right">
                                <div className="userReviewCard_cnt_right_top">
                                    <div className="userReviewCard_cnt_right_top_section">
                                        <div className="userReviewCard_cnt_right_top_section_cnt">
                                            <p className="user-Review__reviewDetails__userName">
                                                {data?.userName}
                                            </p>
                                            <Rating
                                                max={1}
                                                defaultValue={1}
                                                readOnly
                                                className="ms-2"
                                            />
                                            <p className="user-Review__reviewDetails__userName">
                                                {data?.rating}
                                            </p>
                                        </div>
                                        {(props?.userProfile?.currentRole === "parent" ? localStorage?.getItem("parentViaChild") === data?.userId : props?.userProfile?.id === data?.userId) && (
                                            <div>
                                                <EditIcon
                                                    className="ms-2"
                                                    id="editbtn"
                                                    onClick={(e) => showFeedBack(e, data)}
                                                    sx={{ fontSize: '20px', color: "#8A8A8A", cursor: "pointer" }}
                                                />
                                                <DeleteIcon
                                                    id="deletebtn"
                                                    className="ms-1"
                                                    onClick={(e) => deleteFeedback(data)}
                                                    sx={{ fontSize: '20px', color: "#E64A19", cursor: "pointer" }}
                                                />
                                            </div>
                                        )}

                                    </div>
                                    <div>
                                        <p className="user-Review__reviewDetails__date">
                                            {dayjs(data?.reviewedAt).format('MMMM, DD YYYY')}
                                        </p>
                                    </div>
                                </div>
                                <div className="userReviewCard_cnt_right_bottom">
                                    <div className="userReviewCard_detail">
                                        <p className="user-Review__reviewDetails__review-msg">
                                            {data?.reviewDescription}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </>
            {props?.review?.length > 2 && <div className="d-flex justify-content-center"> <button onClick={(e) => viewMoreFeed(e)} className='advisorProfileCard__connect-btn__primary-btn mt-3' id="viewmorebtn" >View More</button></div>}
        </div>
    );
};

const mapStateToProps = ({ userProfileData, commonData }) => {
    const { userProfile, isProfileSuccess, childprofile, parentProfile, isDisconnectUser, } = userProfileData;
    const { deleteFeedBack } = commonData
    return { userProfile, isProfileSuccess, childprofile, parentProfile, isDisconnectUser, deleteFeedBack };
};

const mapDispatchToProps = {
    getCountry, FeedbackDelete, DeleteFeedbackReset, getDetailedUserProfile, resetDetailedUserProfileStatus, loadAdvisorReview, FeedbackParentDelete
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Index));
