import React, { Component } from "react";
import Filter from "../Filter";
import SidebarFilter from "../SidebarFilter";
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
    }
    componentWillMount() {
    }
    handleClick() {
        if (this.state.show === true) {
            document.addEventListener('click', this.handleOutsideClick);
        } else {
            document.removeEventListener('click', this.handleOutsideClick);
        }
        this.setState(prevState => ({
            show: !prevState.show,
        }));
    }
    handleOutsideClick() {
        this.setState({
            show: false
        });
    }
    render() {
        return (
            <>
                <div className="filter-list " onClick={this.handleClick}>
                    <Filter placeholder="Filters" />
                </div>
                <div className="row">

                    <div className="col-12 accordion-filter mt-4">
                        {this.state.show === true ? <SidebarFilter /> : ""}
                    </div>
                </div>
            </>
        )
    }
}
export default Index;