import React, { useEffect, useState } from 'react'
import "./planCard.less";
import PillSection from "../../../../../componentsNew/Common/PillSection";

const Index = ({ children, planData, menu, suggestionData }) => {
    const [courseNames, setCourseNames] = useState([]);
    const [activityNames, setActivityNames] = useState([]);

    useEffect(() => {
        if (planData?.phases?.length > 0) {
            const extractedCourseNames = planData?.phases?.reduce((acc, phase) => {
                phase?.courses?.forEach((course) => {
                    acc?.push(course?.courseName);
                });
                return acc;
            }, []);
            setCourseNames(extractedCourseNames);
        }
        if (planData?.phases?.length > 0) {
            const extractedActivityNames = planData?.phases?.reduce((acc, phase) => {
                phase?.activities?.forEach((activity) => {
                    acc.push(activity?.activityName);
                });
                return acc;
            }, []);
            setActivityNames(extractedActivityNames);
        }
    }, [planData]);
    const checkIfSuggestionExist = (isApproved, isDropSuggested) => {
        if (isApproved && isDropSuggested) {
            return '-unApproved';
        }
        else if (!isApproved && !isDropSuggested) {
            return '-suggested'
        } else {
            return null;
        }
    }
    return (
        <div className={`myWork-planCard${checkIfSuggestionExist(planData?.planInfo?.isApproved, planData?.planInfo?.isDropsuggested) || ''}`}>
            <div className="myWork-planCard__topSec">
                <p className='myWork-planCard__topSec__title'>{planData?.planInfo?.planName}</p>

                <div className='myWork-planCard__topSec__menu'>{menu}</div>
            </div>
            {planData?.planInfo?.isArchived && 
            <div className="myWork-planCard__midtop">
                <div className='myWork-planCard__midtop__pill'>Archived</div>
            </div>
            }
            <div className="myWork-planCard__midSec">
                {planData?.planInfo?.universities?.length > 0 &&
                    <div className='ChipSections mb-1' >
                        <p className='ChipSections__header'>{planData?.planInfo?.universities?.length} Target Universities</p>
                        <PillSection data={planData?.planInfo?.universities} maxWidth={240} maxHeight={120} />
                    </div>
                }
                {planData?.planInfo?.professions?.length > 0 &&
                    <div className='ChipSections mb-1' >
                        <p className='ChipSections__header'>{planData?.planInfo?.professions?.length} Target Professions</p>
                        <PillSection data={planData?.planInfo?.professions?.sort((a, b) => { return a?.length - b?.length })} maxWidth={185} maxHeight={120} />
                    </div>
                }
                {planData?.planInfo?.weighted &&
                    <div className='ChipSections mb-1' >
                        <p className='ChipSections__header'>Target weighted GPA</p>
                        <PillSection data={[planData?.planInfo?.weighted]} maxWidth={260} maxHeight={120} />
                    </div>
                }
                {planData?.planInfo?.unweighted &&
                    <div className='ChipSections mb-1' >
                        <p className='ChipSections__header'>Target unweighted GPA</p>
                        <PillSection data={[planData?.planInfo?.unweighted]} maxWidth={260} maxHeight={120} />
                    </div>
                }
                {courseNames?.length > 0 &&
                    <div className='ChipSections mb-1' >
                        <p className='ChipSections__header'>Courses</p>
                        <PillSection data={courseNames?.toSorted((a, b) => { return a?.length - b?.length })} maxWidth={170} maxHeight={120} />
                    </div>
                }
                {activityNames?.length > 0 &&
                    <div className='ChipSections mb-1' >
                        <p className='ChipSections__header'>Activities</p>
                        <PillSection data={activityNames?.toSorted((a, b) => { return a?.length - b?.length })} maxWidth={170} maxHeight={120} />
                    </div>
                }
            </div>
            <div className="myWork-planCard__suggestionSec">
                {suggestionData}
            </div>
            {children}
        </div>
    )
}

export default Index