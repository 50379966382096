import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Rating from "@mui/material/Rating";
import { useForm, Controller } from "react-hook-form";
import "./feedback.less"
import { connect } from "react-redux";
import { FeedbackSubmit, clearMessage, FeedbackEdit, EditFeedbackReset, loadAdvisorReview, FeedbackReset, getDetailedUserProfile, resetDetailedUserProfileStatus } from "../../../redux/actions";
import CustomAlert from "../../../components/CustomAlert";
import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
};
const IconContainer = ({ value, ...other }) => {
    return <span {...other} id={`star-${value}`} />;
};
const FeedbackForm = (props) => {
    const [rating, setRating] = useState(0);
    const [show, setShow] = useState(false);
    const [ratingerror, setRatinError] = useState(false);
    const [discerror, setDiscError] = useState(false);
    const { handleSubmit, control, setValue, reset } = useForm();

    const onSubmit = (data) => {
        const hasRatingError = rating === 0;
        const hasMessageError = !data?.messageText;

        setRatinError(hasRatingError);
        setDiscError(hasMessageError);

        if (hasRatingError || hasMessageError) {
            return;
        }

        const payload = {
            advisorId: props?.data ? props?.data?.advisorId : props?.advisorDetail?.id,
            rating: rating,
            reviewDescription: data?.messageText,
            studentId: localStorage.getItem("parentViaChild")
        };

        if (props?.data) {
            props?.FeedbackEdit(props?.data?.advisorReviewId, payload);
        } else {
            props?.FeedbackSubmit(payload);
        }
    };

    console.log(props?.advisorDetail, "sjhjshjsh")

    useEffect(() => {
        console.log("props?.editFeedBack",props?.editFeedBack);
        if (props?.feadbackSubmit || props?.editFeedBack) {
            handleClose()
            props?.clearMessage()
            let reviewPayload = {
                advisorId: props?.data ? props?.data?.advisorId : props?.advisorDetail?.id,
                "limit": 10,
                "pageNo": 1,
                studentId: localStorage?.getItem("parentViaChild")

            }
            let payload = {
                userId: props?.data ? props?.data?.advisorId : props?.advisorDetail?.id,
                pointOfViewStudentId: localStorage.getItem("parentViaChild"),
            }
            props?.loadAdvisorReview(reviewPayload)
            props?.getDetailedUserProfile(payload)
            props?.FeedbackReset(false)
            props?.EditFeedbackReset(false)
            props?.resetDetailedUserProfileStatus(false)

        }

    }, [props?.feadbackSubmit, props?.editFeedBack])

    const handleRatingChange = (event, newValue) => {
        setRating(newValue);
        setRatinError(false);
    };

    const handleClose = () => {
        reset();
        props.onHide();
        setRating(0);
        setRatinError(false);
        setDiscError(false);
    };

    useEffect(() => {
        if (props?.data) {
            setRating(props?.data?.rating);
        }
    }, [props?.data])

    return (
        <>
            <Modal show={props.showFeedBack} onHide={handleClose} size={"lg"} style={{ "backgroundColor": "rgba(0,0,0,0.4)", zIndex: '999999', paddingLeft: '0px' }} centered={true} >
                <Modal.Header closeButton>
                    <Modal.Title>Tell us about your experience working with this advisor.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ "width": "100%", "display": "flex", "justifyContent": "center", }}> {<CustomAlert />}</div>
                    <div className="feedBackPopup mb-4" style={{ "padding": "0px" }}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group feedrating col-sm-12 col-lg-12 col-xl-12 text-center">
                                <Rating
                                    name="rating"
                                    value={rating}
                                    style={{ fontSize: '3rem' }}
                                    onChange={handleRatingChange}
                                    IconContainerComponent={IconContainer}
                                    id="starrating"
                                />
                            </div>
                            <div style={{ "display": "flex", "justifyContent": "center" }}>
                                {ratingerror && <p style={{ "color": "red", fontSize: "14px" }}>*Required</p>}
                            </div>
                            <div className="row m-0 ">
                                <div className="col-md-12 col-sm-12 col-12 p-0">
                                    <div className="role">
                                        <div className="role_card">
                                            <div className="goals_page">
                                                <div className="input-floating-label form-group" style={{ "marginBottom": "0px" }}>
                                                    <Controller
                                                        name="messageText"
                                                        control={control}
                                                        defaultValue={props.data?.reviewDescription}
                                                        render={({ field }) => (
                                                            <div>
                                                                <textarea
                                                                    {...field}
                                                                    className="textarea--primary textbox--rounded input"
                                                                    name="Message"
                                                                    placeholder="Message"
                                                                    id="message"
                                                                    maxLength={500}
                                                                    style={{ height: "150px", resize: 'none' }}
                                                                    onChange={(e) => {
                                                                        const value = e.target.value;
                                                                        if (value.length <= 5000) {
                                                                            field.onChange(value);
                                                                            setDiscError(false)
                                                                        }
                                                                    }}
                                                                />
                                                                <div style={{ "display": "flex", "justifyContent": "space-between" }}>
                                                                    <div style={{ "display": "flex", "justifyContent": "space-between" }}>
                                                                        {discerror && <p style={{ "color": "red", fontSize: "14px" }}>*Required</p>}
                                                                    </div>
                                                                    <div>
                                                                        <p>{field?.value?.length ? field?.value?.length : 0}/500</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button className="footer__cta cta--rounded feedBackPost" id="PostFeedback" type="submit" style={{ "cursor": "pointer" }}>Post Feedback</button>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

const mapStateToProps = ({ commonData }) => {
    const { feadbackSubmit, editFeedBack } = commonData

    return {
        feadbackSubmit, editFeedBack
    }
};


export default connect(mapStateToProps, {
    FeedbackSubmit, clearMessage, FeedbackEdit, EditFeedbackReset, loadAdvisorReview, FeedbackReset, getDetailedUserProfile, resetDetailedUserProfileStatus
})(FeedbackForm);
