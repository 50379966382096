export const SET_ISGOAL_SUCCESS_STATUS = "SET_ISGOAL_SUCCESS_STATUS"
export const SENT_INVITE_STATUS = "SENT_INVITE_STATUS";
export const VERIFY_INVITES = "VERIFY_INVITES";
export const VERIFY_INVITES_STATUS = "VERIFY_INVITES_STATUS";
export const VERIFY_INVITES_STATUS_DATA = "VERIFY_INVITES_STATUS_DATA";
export const COPY_INVITE_LINK = "COPY_INVITE_LINK";
export const CREATE_PLAN_STATUS = "CREATE_PLAN_STATUS";
export const CREATE_PLAN_PHASE_STATUS = "CREATE_PLAN_PHASE_STATUS"
export const GET_STUDENT_PLAN = "GET_STUDENT_PLAN"
export const GET_STUDENT_PLAN_PAGINATED = "GET_STUDENT_PLAN_PAGINATED"
export const RESET_STUDENT_PLAN = "RESET_STUDENT_PLAN"
export const GET_STUDENT_PLAN_STATUS = "GET_STUDENT_PLAN_STATUS"
export const GET_STUDENT_PLAN_PHASE = "GET_STUDENT_PLAN_PHASE"
export const Edit_PLAN_STATUS ="Edit_PLAN_STATUS"
export const GET_STUDENT_PLAN_PHASE_STATUS ="GET_STUDENT_PLAN_PHASE_STATUS"
export const GET_STUDENT_PLAN_DATA_ID = "GET_STUDENT_PLAN_DATA_ID"
export const GET_STUDENT_PLAN_DATA_ID_STATUS = "GET_STUDENT_PLAN_DATA_ID_STATUS"
export const GET_STUDENT_PLAN_LOAD = "GET_STUDENT_PLAN_LOAD"
export const GET_STUDENT_PLAN_ERROR_STATUS = "GET_STUDENT_PLAN_ERROR_STATUS"
export const GET_STUDENT_PLAN_LOAD_STATUS = "GET_STUDENT_PLAN_LOAD_STATUS"
export const GET_PHASE_COURSE_ID = "GET_PHASE_COURSE_ID"
export const GET_PHASE_COURSE_ID_STATUS = "GET_PHASE_COURSE_ID_STATUS"
export const GET_STUDENT_PLAN_DATA_ID_NEXT = "GET_STUDENT_PLAN_DATA_ID_NEXT"
export const GET_STUDENT_PLAN_DATA_ID_NEXT_STATUS ="GET_STUDENT_PLAN_DATA_ID_NEXT_STATUS"
export const GET_COURSE_LIST ="GET_COURSE_LIST"
export const GET_COURSE_LIST_PAGINATED ="GET_COURSE_LIST_PAGINATED"
export const GET_COURSE_LIST_STATUS = "GET_COURSE_LIST_STATUS"
export const GET_MILESTONE_LIST = "GET_MILESTONE_LIST"
export const GET_MILESTONE_STATUS = "GET_MILESTONE_STATUS"
export const GET_USER_GOALS = "GET_USER_GOALS"
export const GET_USER_GOALS_STATUS = "GET_USER_GOALS_STATUS"
export const GET_ACTIVITY_LIST = "GET_ACTIVITY_LIST"
export const GET_ACTIVITY_LIST_STATUS = "GET_ACTIVITY_LIST_STATUS"
export const CREATE_PLAN_COURSE = "CREATE_PLAN_COURSE"
export const EDIT_PLAN_COURSE ="EDIT_PLAN_COURSE"
export const CREATE_PLAN_ACTIVITY ="CREATE_PLAN_ACTIVITY"
export const EDIT_PLAN_ACTIVITY ="EDIT_PLAN_ACTIVITY"
export const EDIT_PLAN_PHASE ="EDIT_PLAN_PHASE"
export const EDIT_PLAN_PHASE_ERROR = "EDIT_PLAN_PHASE_ERROR"
export const DELETE_PLAN_PHASE ="DELETE_PLAN_PHASE"
export const DELETE_PLAN_COURSE ="DELETE_PLAN_COURSE"
export const DELETE_PLAN_ACTIVITY ="DELETE_PLAN_ACTIVITY"
export const EDIT_PLAN_COURSE_ERROR = "EDIT_PLAN_COURSE_ERROR"
export const INVITE_ADVISOR_BY = "INVITE_ADVISOR_BY"
export const GET_INVITE_ADVISOR = "GET_INVITE_ADVISOR";
export const GET_INVITE_ADVISOR_STATUS = "GET_INVITE_ADVISOR_STATUS"
export const INVITE_ADVISOR_ERROR = "INVITE_ADVISOR_ERROR"
export const CREATE_PLAN_PHASE_STATUS_ERROR = "CREATE_PLAN_PHASE_STATUS_ERROR"
export const GET_CONNECTED_PARENT = "GET_CONNECTED_PARENT"
export const NEW_PLAN_ID = "NEW_PLAN_ID"
export const NEW_PARENT_PLAN_ID = "NEW_PARENT_PLAN_ID"
export const GET_PHASE_ACTIVITY_ID = "GET_PHASE_ACTIVITY_ID"
export const GET_PHASE_ACTIVITY_ID_STATUS = "GET_PHASE_ACTIVITY_ID_STATUS"
export const GET_MILESTONE_TYPE = "GET_MILESTONE_TYPE"
export const GET_COURSE_BY_ID = "GET_COURSE_BY_ID"
export const GET_COURSE_BY_ID_STATUS = "GET_COURSE_BY_ID_STATUS"
export const CREATE_COURSE_MILESTONE = "CREATE_COURSE_MILESTONE"
export const CREATE_COURSE_MILESTONE_STATUS = "CREATE_COURSE_MILESTONE_STATUS"
export const UPDATE_COURSE_MILESTONE = "UPDATE_COURSE_MILESTONE"
export const UPDATE_COURSE_MILESTONE_STATUS = "UPDATE_COURSE_MILESTONE_STATUS"
export const GET_MILESTONE_BY_COURSE = "GET_MILESTONE_BY_COURSE"
export const GET_MILESTONE_BY_COURSE_STATUS = "GET_MILESTONE_BY_COURSE_STATUS"
export const UPDATE_MILESTONE_NOTES = "UPDATE_MILESTONE_NOTES"
export const READ_UPDATE_STATUS = "READ_UPDATE_STATUS"
export const DELETE_MILESTONE = "DELETE_MILESTONE"
export const EDIT_MILESTONE_NOTES = "EDIT_MILESTONE_NOTES"
export const DELETE_MILESTONE_NOTES = "DELETE_MILESTONE_NOTES"
export const GET_MILESTONE_BY_ACTIVITY = "GET_MILESTONE_BY_ACTIVITY"
export const GET_MILESTONE_BY_ACTIVITY_STATUS = "GET_MILESTONE_BY_ACTIVITY_STATUS"
export const CREATE_ACTIVITY_MILESTONE = "CREATE_ACTIVITY_MILESTONE"
export const ADD_NEW_MILESTONE_RESOURCE = "ADD_NEW_MILESTONE_RESOURCE"
export const UPDATE_MILESTONE_RESOURCE = "UPDATE_MILESTONE_RESOURCE"
export const DELETE_MILESTONE_RESOURCE = "DELETE_MILESTONE_RESOURCE"
export const CREATE_PLAN_COURSE_ERROR = "CREATE_PLAN_COURSE_ERROR"
export const CREATE_PLAN_ACTIVITY_ERROR = "CREATE_PLAN_ACTIVITY_ERROR"
export const ADD_RESOURCE_ERROR = "ADD_RESOURCE_ERROR"
export const GET_PLAN_STATUS = "GET_PLAN_STATUS"
export const DELETE_PLAN = "DELETE_PLAN"
export const ERROR_DELETE_PLAN = "ERROR_DELETE_PLAN"
export const UPDATE_PLAN_STATUS = "UPDATE_PLAN_STATUS"
export const  UPDATE_PHASE_STATUS = " UPDATE_PHASE_STATUS"
export const MILESTONE_STATUS_UPDATE = "MILESTONE_STATUS_UPDATE"
export const PHASE_ERROR = "PHASE_ERROR"
export const UPDATE_PLAN_ERROR_STATUS = "UPDATE_PLAN_ERROR_STATUS"
export const REPLY_MILESTONE_NOTES = "REPLY_MILESTONE_NOTES"
export const UPDATE_MILESTONE_ERROR = "UPDATE_MILESTONE_ERROR"
export const PLAN_ADVISOR_STATUS = "PLAN_ADVISOR_STATUS"
export const PLAN_ADVISOR_DATA = "PLAN_ADVISOR_DATA"
export const STUDENT_COURSE_STATUS = "STUDENT_COURSE_STATUS"
export const STUDENT_COURSE_DATA = "STUDENT_COURSE_DATA"
export const PLAN_APPROVE_STATUS = "PLAN_APPROVE_STATUS"
export const PLAN_APPROVE_ERROR = "PLAN_APPROVE_ERROR"
export const COURSE_APPROVE_STATUS = "COURSE_APPROVE_STATUS"
export const  COURSE_APPROVE_ERROR = "COURSE_APPROVE_ERROR"
export const PLAN_ACESS_ADVISOR_ERROR = "PLAN_ACESS_ADVISOR_ERROR"
export const PLAN_ACESS_ADVISOR = "PLAN_ACESS_ADVISOR"
export const ADD_PLAN_ADVISOR_ERROR = "ADD_PLAN_ADVISOR_ERROR"
export const ADD_PLAN_ADVISOR = "ADD_PLAN_ADVISOR"
export const REMOVE_PLAN_ADVISOR_ERROR = "REMOVE_PLAN_ADVISOR_ERROR"
export const REMOVE_PLAN_ADVISOR = "REMOVE_PLAN_ADVISOR"
export const PLAN_ACESS_STUDENT_ERROR = "PLAN_ACESS_STUDENT_ERROR"
export const PLAN_ACESS_STUDENT = "PLAN_ACESS_STUDENT"
export const PLAN_ACESS_STUDENT_STATUS = "PLAN_ACESS_STUDENT_STATUS"
export const PLAN_REJECT_STATUS = "PLAN_REJECT_STATUS"
export const PLAN_REJECT_ERROR = "PLAN_REJECT_ERROR"
export const PLAN_CONFIRM_DROP_STATUS = "PLAN_CONFIRM_DROP_STATUS"
export const PLAN_CONFIRM_DROP_ERROR = "PLAN_CONFIRM_DROP_ERROR"
export const ACTIVITIES_APPROVE_STATUS = "ACTIVITIES_APPROVE_STATUS"
export const ACTIVITIES_APPROVE_ERROR = "ACTIVITIES_APPROVE_ERROR"
export const COURSE_REJECT_STATUS = "COURSE_REJECT_STATUS"
export const ACTIVITY_REJECT_STATUS = "ACTIVITY_REJECT_STATUS"
export const COURSE_DROP_STATUS = "COURSE_DROP_STATUS"
export const COURSE_DROP_ERROR_STATUS = "COURSE_DROP_ERROR_STATUS"
export const ACTIVITY_DROP_STATUS = "ACTIVITY_DROP_STATUS"
export const ACTIVITY_DROP_ERROR_STATUS = "ACTIVITY_DROP_ERROR_STATUS"
export const GET_DASHBOARD_COUNT = "GET_DASHBOARD_COUNT"
export const GET_DASHBOARD_COUNT_STATUS = "GET_DASHBOARD_COUNT_STATUS"
export const GET_DASHBOARD_COUNT_ERROR_STATUS = "GET_DASHBOARD_COUNT_ERROR_STATUS"
export const GET_EXPLORE_ADVISOR_COUNT_FOR_STUDENT = "GET_EXPLORE_ADVISOR_COUNT_FOR_STUDENT"
export const GET_RESET_COUNTS_STUDENT = "GET_RESET_COUNTS_STUDENT"
export const UPDATES_VIEW_STUDENT_COUNT = "UPDATES_VIEW_STUDENT_COUNT"
