import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { connect } from 'react-redux';
import Reschedule from "../../../../componentsNew/Common/ReShedule/reschedulePopup";
import { withRouter } from '../../../../redux/store/navigate';
import "./Sessionpopup.less";
import { clearMessage, cancelEvent, setCancelStatus, listScheduledEvents, ConnectParent, getConnectedAdvisors } from "../../../../redux/actions"
import Confirmation from "../../../../componentsNew/Common/ConfirmationPopup";
import dayjs from 'dayjs';
import { GetCurrentTimezone } from '../../../../utils/CommonUtils';

const Index = (props) => {
    const [show, setShow] = useState(false);
    const [scheduledata, setscheduledata] = useState();
    const [open, setOpen] = useState(false);
    const [advisorTime, setAdvisorTime] = useState();
    const [advisordata, setAdvisorData] = useState();

    const handleOpen = () => setOpen(true);
    const handleConfirmClose = () => setOpen(false);

    const handleClose = () => {
        setShow(false);
        setscheduledata([])
    }
    const handleShow = () => {
        HandleRescheduleData()
        props?.clearMessage()
        setShow(true)

    };

    useEffect(() => {
        console.log("props.data?.start", props.data);
        if (props?.data) {
            let Payload = {
                Starttime: props.data?.start,
                Endtime: props?.data?.end,
                meeting: props?.data?.meetingLocation,
                description: props?.data?.meetingDescription,
                Invitation: props?.data?.invitationStatus,
                AdvisorData: props?.data ?? props?.data?.AdvisorData,
                EventId: props?.data?.id,
                meetingPassword: props?.data?.meetingPassword
            }
            if (props?.data && props?.data?.eventParticipents) {
                const advisor = props?.data?.eventParticipents.find(participant => participant.currentRole === 'advisor');
                if (advisor) {
                    const advisordata = props?.connectedAdvisors?.find((data) => data?.userInfo?.id === advisor?.id);
                    setAdvisorData(advisordata);
                    setAdvisorTime([advisordata?.userInfo?.advisorProfile?.advisorWorkingHours])

                }
            }
            let Data = []
            Data.push(Payload)
            setscheduledata(Data)
        }
    }, [props])

    useEffect(() => {
        if (props?.cancelSession) {
            handleConfirmClose()
            props.listScheduledEvents();
            props.ConnectParent();
            props?.getConnectedAdvisors()
            CloseDetailpopup()
            props?.setCancelStatus(false)
        }
    }, [props?.cancelSession])

    const CloseDetailpopup = () => {
        props?.onHide()
    }

    const startTime = dayjs(props?.data?.start);
    const endTime = dayjs(props?.data?.end);
    const duration = endTime?.diff(startTime, 'minute');

    const handleDelete = () => {
        props.cancelEvent(props?.data?.id);
    }

    const HandleRescheduleData = () => {
        if (props?.data) {
            let Payload = {
                Starttime: props.data?.start,
                Endtime: props?.data?.end,
                meeting: props?.data?.meetingLocation,
                description: props?.data?.meetingDescription,
                Invitation: props?.data?.invitationStatus,
                AdvisorData: props?.data?.eventParticipents ? props?.data?.eventParticipents : props?.data?.AdvisorData,
                EventId: props?.data?.id,
                EventType: props?.data?.eventType,
                Password: props?.data?.details?.meetingPassword
            }
            let Data = []
            Data.push(Payload)
            setscheduledata(Data)
        }
    }
    const AdvisorName = props?.data?.title?.split(',')[0].trim();
    let eventStatus = props?.data?.invitationStatus?.find((status) => status?.userId === props.userProfile?.id)
    let currentTime = GetCurrentTimezone(props?.userProfile?.timezone);
    const isPast = startTime.isBefore(dayjs(currentTime));
    return (
        <>
            {open && <Confirmation show={open} handleSubmit={() => handleDelete()} message={`Are you sure you want to cancel the session with ${AdvisorName}`} btnText="confirm" onHide={handleConfirmClose} />}
            {show && <Reschedule duration={duration} userTime={advisorTime} advisorData={advisordata} show={show} onHide={handleClose} Data={scheduledata} DetailClose={CloseDetailpopup} />}
            <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
                <Modal.Header closeButton >
                    <Modal.Title id="contained-modal-title-vcenter">
                        Consulting Session
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='modalContent'>
                    <div className="modalContent__section">
                        <div className="modalContent__section__textCont">
                            <p className="modalContent__section__textCont__label">Who</p>
                            <p className="value blue-text">{props?.data?.title}</p>
                        </div>
                        <div className="modalContent__section__textCont">
                            <p className="modalContent__section__textCont__label">When</p>
                            <div className="modalContent__section__textCont__block-value">
                                <p className="modalContent__section__textCont__value">{dayjs(props?.data?.start)?.format('hh:mm A')}</p>
                                <p className="modalContent__section__textCont__value">{`${dayjs(props?.data?.start)?.format('hh:mm A')} to ${dayjs(props?.data?.end)?.format('hh:mm A')}`} (<span>Duration :</span> {duration ? duration : ""} Mins)</p>
                            </div>
                        </div>
                        <div className="modalContent__section__textCont">
                            <p className="modalContent__section__textCont__label">Where</p>
                            <p className="blue-text">{props?.data?.meetingLocation}
                            </p>
                            <span style={{ "marginBottom": "1rem" }} >
                                <a href={props.data.meetingLocation} target="_blank" rel="noopener noreferrer" >
                                    <OpenInNewOutlinedIcon sx={{ fontSize: '1rem', fill: '#7a7676', marginLeft: '4px' }} />
                                </a>
                            </span>
                        </div>
                        <div className="modalContent__section__textCont">
                            <p className="modalContent__section__textCont__label">Passcode</p>
                            <p className="reg-text">{props?.data?.meetingPassword}
                            </p>
                        </div>
                        <p className='modalContent__section__value'>{props?.data?.meetingDescription}</p>
                        <div className="modalContent__section__bottom">
                            <div className="modalContent__section__bottom__reSchedule-sec" style={{ "display": "flex", "justifyContent": "end", }} >
                                {(eventStatus && eventStatus?.status !== 'rejected' && !isPast) &&
                                    <Button className="cancel-btn btn m-3" onClick={handleOpen}>
                                        Cancel Session
                                    </Button>
                                }
                                {!isPast &&
                                    <Button className="reschedulesave-btn btn m-3" onClick={handleShow} >
                                        Re Schedule
                                    </Button>
                                }
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

const mapStateToProps = ({ commonData, userProfileData }) => {
    const { errorList, cancelSession, connectedAdvisors } = commonData;
    const { userProfile } = userProfileData;
    return { errorList, cancelSession, userProfile, connectedAdvisors };
};

export default connect(mapStateToProps, { clearMessage, cancelEvent, listScheduledEvents, setCancelStatus, ConnectParent, getConnectedAdvisors })(withRouter(Index));
