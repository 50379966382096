import { yupResolver } from "@hookform/resolvers/yup";
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Grid, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { connect } from 'react-redux';
import * as Yup from "yup";
import { CreateActivityErrorStatus, CreateActivityStatus, CreateCourseErrorStatus, 
  EditCourseStatus, CreateCourseStatus,DeleteActivityStatus, EditActivityStatus, 
  DeleteCourse, DeleteCourseStatus, EditPhase, GetStudentPlanPhase, clearMessage, 
  getPhasetime, setUndoCourseDropSuggestionStatus, setUndoActivityDropSuggestionStatus } from "../../../../../redux/actions";
import AddActivity from "../../../../Common/PhaseModal/AddActivity";
import AddCourse from "../../../../Common/PhaseModal/AddCourse";
import Tags from "../../../SemesterPills";
import './phase.less';
import AddPhaseCourse from "../../../../../pages/Advisor/MyWork/Modals/AddPhaseCourse";
import AddPhaseActivity from "../../../../../pages/Advisor/MyWork/Modals/AddPhaseActivity";

const validation = Yup.object({})

const Index = (props) => {
  const [addcourse, setAddcourse] = useState(false)
  const [addactivity, setAddactivity] = useState(false)
  const [semesterData, setSemesterData] = useState(false)
  const [editCourse, setEdit] = useState(false)
  const [semesterDetails, setSemesterDetails] = useState([])
  const [semesterError, setsemesterError] = useState(false)


  const { getstudentplanphase } = props;

  useEffect(() => {
    let data = getstudentplanphase?.map(semData => {
      semData['startDateError'] = ""
      semData['endDateError'] = ""
      return semData;
    })
    setSemesterDetails(data)
  }, [props?.getstudentplanphase])

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window?.location?.search);
    const onboardingParam = urlSearchParams?.get('onboarding');
    if (onboardingParam) {
      props?.SemesterError(semesterError);
    }
    if (semesterError === false && addcourse || addactivity) {
      setAddcourse(false)
      setAddactivity(false)
    }
  }, [semesterError])

  useEffect(() => {
    if (props?.semesterCardData === true) {
      props?.handleFormValues(semesterDetails)
    }
  }, [props?.semesterCardData]);

  useEffect(() => {
    if (props?.isCourseCreated || props?.isEditCourse) {
      PhaseApiCall()
      Coursepopuphide()
      props?.CreateCourseStatus(false)
      props?.EditCourseStatus(false)
    }
    if (props?.isCourseError) {
      props?.CreateCourseErrorStatus(false)
    }
    if (props.isCourseDeleted) {
      PhaseApiCall()
      props.DeleteCourseStatus(false);
    }
    if (props.isActivityDeleted) {
      PhaseApiCall()
      props.DeleteActivityStatus(false);
    }
    if(props?.undoCourseDropSuggestionStatus){
      PhaseApiCall();
      props?.setUndoCourseDropSuggestionStatus(false);
    }
    if (props?.undoActivitySuggestionStatus) {
      PhaseApiCall();
    }
    if(props?.undoActivityDropSuggestionStatus){
      PhaseApiCall();
      props?.setUndoActivityDropSuggestionStatus(false);
    }
  }, [props?.isCourseCreated, props?.isCourseError, props?.isEditCourse, props.isCourseDeleted, 
    props.isActivityDeleted, props?.undoCourseDropSuggestionStatus, props?.undoActivityDropSuggestionStatus,
    props?.undoActivitySuggestionStatus])

  useEffect(() => {
    if (props?.isActivityCreated || props?.isEditActivity) {
      PhaseApiCall()
      Activitypopuphide()
      props?.CreateActivityStatus(false)
      props?.EditActivityStatus(false)
    }
    if (props?.isActivityError) {
      props?.CreateActivityErrorStatus(false)
    }
  }, [props?.isActivityCreated, props?.isActivityError, props?.isEditActivity])


  // Popups
  const Coursepopupshow = (data, edit) => {
    setAddcourse(true)
    setSemesterData(data)
    if (edit) {
      setEdit(edit)
    }
  }
  const Coursepopuphide = () => {
    setAddcourse(false)
    setEdit(false)
  }
  const Activitypopupshow = (data, edit) => {
    setAddactivity(true)
    setSemesterData(data)
    if (edit) {
      setEdit(edit)
    }
  }
  const Activitypopuphide = () => {
    setAddactivity(false)
    setEdit(false)
  }
  const { register, handleSubmit, reset, watch, control, formState: { errors }, setValue, clearErrors, } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(
      validation
    ),
  });

  const onSubmit = (data) => {
  };
  const handleCourseSubmit = (data) => {
    props?.handleCourseSubmit(data, editCourse)
  }
  const handleActivitySubmit = (data) => {
    props?.handleActivitySubmit(data, editCourse)
  }
  const checkIfPhaseInterfere = (date, phase) => {
    let currentPhaseStartDate = new Date(date);
    let otherPhaseStartDate = new Date(phase?.startDate);
    let otherPhaseEndDate = new Date(phase?.endDate);

    return (currentPhaseStartDate >= otherPhaseStartDate && currentPhaseStartDate <= otherPhaseEndDate);
  }
  const isAnyPhasesDateInterfere = () => {
    let inValidSemesters = semesterDetails?.filter(data => (data?.startDateError || data?.endDateError));
    inValidSemesters?.forEach(inValidSem => {
      if (inValidSem?.startDateError) {
        let startDateInterfere = semesterDetails?.some(semester => {
          if (inValidSem?.id !== semester?.id) {
            return checkIfPhaseInterfere(inValidSem?.startDate, semester);
          }
        });
        if (!startDateInterfere) {
          inValidSem.startDateError = "";
          savePhaseStartDate(inValidSem, inValidSem?.startDate);
        }
      }
      if (inValidSem?.endDateError) {
        let endDateInterfere = semesterDetails?.some(semester => {
          if (inValidSem?.id !== semester?.id) {
            return checkIfPhaseInterfere(inValidSem?.endDate, semester);
          }
        });
        if (!endDateInterfere) {
          inValidSem.endDateError = "";
          savePhaseEndDate(inValidSem, inValidSem?.endDate)
        }
      }
    });
  }
  const PhaseApiCall = () => {
    if (props?.getstudentplan) {
      let plans = props?.getstudentplan[0]?.planInfo
      let plansId = plans?.id
      if (plansId) {
        let values = { "planId": plansId }
        props?.GetStudentPlanPhase(values)
        props?.getPhasetime(plansId);
      }
    }
  }
  const isDateInvalid = (date, phase, isStartField) => {
    let newDate = new Date(date);
    let currentPhaseStartDate = new Date(phase?.startDate);
    let currentPhaseEndDate = new Date(phase?.endDate);
    if (isStartField) {
      return ((newDate?.toDateString() == currentPhaseEndDate?.toDateString()) || (newDate > currentPhaseEndDate));
    } else {
      return ((newDate?.toDateString() == currentPhaseStartDate?.toDateString()) || (newDate < currentPhaseStartDate));
    }
  }
  const handleDateChange = (date, phase, isStartDateField) => {
    let { id } = phase
    let newDate = new Date(date);
    let semester = semesterDetails.find(data => data?.id === id);
    let otherSemesters = semesterDetails.filter(data => data?.id !== id);
    const newFormatedDate = `${newDate.getFullYear()}-${(newDate.getMonth() + 1).toString()
      .padStart(2, '0')}-${newDate.getDate().toString()
        .padStart(2, '0')} ${newDate.getHours().toString()
          .padStart(2, '0')}:${newDate.getMinutes().toString().padStart(2, '0')}:${newDate.getSeconds().toString()
            .padStart(2, '0')}`;
    if (isStartDateField) {
      semester['startDate'] = newFormatedDate;
      if (isDateInvalid(newDate, phase, isStartDateField)) {
        semester.startDateError = "Invalid Start date"
        setsemesterError(true)
      } else {
        if (otherSemesters.some(semData => checkIfPhaseInterfere(newDate, semData))) {
          semester.startDateError = "Start date overlaps with other semesters";
          setsemesterError(true)
        } else {
          semester.startDateError = "";
          isAnyPhasesDateInterfere();
          setsemesterError(false)
          savePhaseStartDate(phase, newDate);
        }
      }
    } else {
      semester['endDate'] = newFormatedDate;
      if (isDateInvalid(newDate, phase, isStartDateField)) {
        semester.endDateError = "Invalid End date"
        setsemesterError(true)
      } else {
        if (otherSemesters.some(semData => checkIfPhaseInterfere(newDate, semData))) {
          semester.endDateError = "End date overlaps with other semesters";
          setsemesterError(true)
        } else {
          semester.endDateError = "";
          isAnyPhasesDateInterfere();
          setsemesterError(false)
          savePhaseEndDate(phase, newDate);
        }
      }
    }
    let obj = semesterDetails?.map(data => data?.id == semester.id ? semester : data)
    setSemesterDetails(obj)
  }
  const savePhaseStartDate = (phaseData, date) => {
    let newDate = new Date(date)
    let year = newDate?.getFullYear()
    let month = (newDate?.getMonth() + 1).toString().padStart(2, '0');
    let day = newDate?.getDate()
    const formattedDate = `${year}-${month}-${day}`;
    let payload = {
      planId: phaseData?.planId,
      phaseId: phaseData?.id,
      startDate: formattedDate,
      endDate: phaseData?.endDate,
      phaseTitle: phaseData?.title
    }
    props?.EditPhase(payload);

  }
  const savePhaseEndDate = (phaseData, date) => {
    let newDate = new Date(date)
    let year = newDate?.getFullYear()
    let month = (newDate?.getMonth() + 1).toString().padStart(2, '0');
    let day = newDate?.getDate()
    const formattedDate = `${year}-${month}-${day}`;
    let payload = {
      planId: phaseData?.planId,
      phaseId: phaseData?.id,
      startDate: phaseData?.startDate,
      endDate: formattedDate,
      phaseTitle: phaseData?.title
    }
    props?.EditPhase(payload);
  }

  return (
    <>
      {(props?.userProfile?.isOnboarded) ?
        <>
          {addcourse && <AddPhaseCourse showAddCourse={addcourse} EditData={editCourse} semesterData={semesterData} hideModel={Coursepopuphide} handleSubmit={handleCourseSubmit} />}
          {addactivity && <AddPhaseActivity showAddActivity={addactivity} EditData={editCourse} semesterData={semesterData} hideModel={Activitypopuphide} handleSubmit={handleActivitySubmit} />}
        </> :
        <>
          {(!semesterError && addcourse) && <AddCourse show={addcourse} EditData={editCourse} semesterData={semesterData} handleClose={Coursepopuphide} handleSubmit={handleCourseSubmit} />}
          {(!semesterError && addactivity) && <AddActivity show={addactivity} EditData={editCourse} semesterData={semesterData} handleClose={Activitypopuphide} handleSubmit={handleActivitySubmit} />}
        </>
      }
      {props?.data?.map((data) => {
        const fieldId = `SelectStartdate-${data.id}`;
        const fieldEndId = `SelectEnddate-${data.id}`;
        const activePhaseId = props?.getstudentplan[0]?.planInfo?.activePhaseId;
        const isActive = data?.id === activePhaseId
        const cardClass = isActive ? 'semesterCard__active' : 'semesterCard';
        const disablePassedSembtns = data?.isPassed && (props?.userProfile?.currentRole === "advisor");
        return (
          <div className={cardClass} key={data?.id}>
            <div className='semTopSection' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><p className='mb-0'>{data?.title}</p></div>
            <div className='SemesterDataSection'>
              <form onSubmit={handleSubmit(onSubmit)}  >
                <div className="semseterfield__section">
                  <div style={{ "width": "170px" }} >
                    <div className="input-floating-label">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Controller name={fieldId} control={control} defaultValue={data?.startDate}
                          render={({ field }) => (
                            <DateTimePicker value={field.value} views={['year', 'month', 'day']} id="startdate"
                              inputFormat="dd MMM yyyy" format="dd MMM yyyy" disableEntry sx={{ fontSize: "8px" }}
                              onChange={(date) => {
                                if (date instanceof Date && !isNaN(date.getTime())) {
                                  field.onChange(date);
                                  handleDateChange(date, data, true)
                                }
                              }}
                              renderInput={(params) => (
                                <TextField {...params} id="startdatetext" 
                                  inputProps={{ ...params.inputProps, readOnly: true }} 
                                  sx={{ width: '100%', '& .MuiOutlinedInput-root': { border: 'none', '&:hover fieldset': { border: 'none' } } }} 
                                />
                              )}
                            />
                          )}
                        />
                      </LocalizationProvider>
                      {semesterDetails.find(sem => sem?.id == data?.id)?.startDateError && (
                        <div className="error-text ps-0">{semesterDetails.find(sem => sem?.id == data?.id)?.startDateError}</div>
                      )}
                    </div>
                  </div>
                  <div style={{ "width": "2px" }}>
                    <p style={{ "marginTop": "8px", "marginBottom": "0px" }}>-</p>
                  </div>
                  <div style={{ "width": "170px" }}>
                    <div className="input-floating-label">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Controller name={fieldEndId} control={control} defaultValue={data?.endDate}
                          render={({ field }) => (
                            <DateTimePicker value={field.value} views={['year', 'month', 'day']} id="enddate"
                              inputFormat="dd MMM yyyy" format="dd MMM yyyy"
                              onChange={(date) => {
                                if (date instanceof Date && !isNaN(date.getTime())) {
                                  field.onChange(date);
                                  handleDateChange(date, data, false)
                                }
                              }}
                              renderInput={(params) => (
                                <TextField {...params} id="enddatetext" inputProps={{ ...params.inputProps, readOnly: true }} sx={{ width: '100%', '& .MuiOutlinedInput-root': { border: 'none', '&:hover fieldset': { border: 'none', }, }, }} />
                              )}
                            />
                          )}
                        />
                      </LocalizationProvider>
                      {semesterDetails?.find(sem => sem?.id == data?.id)?.endDateError && (
                        <div className="error-text ps-0">{semesterDetails?.find(sem => sem?.id == data?.id)?.endDateError}</div>
                      )}
                    </div>
                  </div>
                </div>
                <Grid>
                  {data?.courses?.length > 0 ? (
                    <>
                      <p className='subSemHeading mt-1'>Courses 
                        {data?.courses?.length < 25 &&
                          <AddBoxIcon sx={{ fill: '#1C84EE', cursor: 'pointer' }} id="addcourse" onClick={(e) => Coursepopupshow(data)} />
                        }
                      </p>
                      <div className='d-flex ' style={{ flexWrap: 'wrap', "padding": "10px 15px", justifyContent: 'center' }}>
                        {data?.courses?.map((course) => { 
                          return (
                            <div id="addcourse" key={course?.id}> 
                              <Tags bold key={course?.id} text={course?.courseName} deleteId={course?.id} courseData={course} totalData={data} close={true} coursepopupshow={Coursepopupshow}/> 
                            </div>
                        )})}
                      </div>
                    </>
                  ) : (
                    <>
                      <p className='subSemHeading mt-1'>Courses</p>
                      <div className='d-flex justify-content-center mb-3'>
                        <button className={`netWork-card__button-sec__primary-btn phaseNewBtn${disablePassedSembtns ? '--disabled' : ""}`}
                          disabled={disablePassedSembtns} onClick={(e) => Coursepopupshow(data)}
                          id="addcourse" style={{ borderRadius: '20px' }}>
                          Add Course
                        </button>
                      </div>
                    </>
                  )}
                </Grid>
                <Grid>
                  {data?.activities?.length > 0 ? (<>  <p className='subSemHeading mt-1'>Activities 
                    {data?.activities?.length < 25 &&
                      <span> <AddBoxIcon sx={{ fill: '#1C84EE', cursor: 'pointer' }} id="addactivity" onClick={() => Activitypopupshow(data)} /> </span>
                    }
                    </p>
                    <div className='d-flex ' style={{ flexWrap: 'wrap', "padding": "10px 15px", justifyContent: 'center' }}>
                      {data?.activities?.map((course) => (
                        <div id="addactivity" key={course?.id}>
                          <Tags bold text={course?.activityName} deleteId={course?.id} courseData={course} totalData={data} close={true} activityDelete={true} coursepopupshow={Activitypopupshow}/> 
                        </div>
                      ))}
                    </div>
                    {/* <PillSection data={data?.activities?.map(activities => activities?.activityName)} totalData={data} activityDelete={true} ids={data?.activities?.map(course => { return { courseId: course.id, courseName: course?.activityName } })} maxWidth={240} maxHeight={80} close="true" /> */}
                  </>) : (
                    <>
                      <p className='subSemHeading mt-1'>Activities</p>
                      <div className='d-flex justify-content-center mb-3'>
                        <button className={`netWork-card__button-sec__primary-btn phaseNewBtn${disablePassedSembtns ? '--disabled' : ""}`}
                          id="addactivity" disabled={disablePassedSembtns}
                          onClick={() => Activitypopupshow(data)}
                          style={{ borderRadius: '20px' }}>
                          Add Activity
                        </button>
                      </div>
                    </>
                  )}
                </Grid>
              </form>
            </div>
          </div>
        );
      })}
    </>
  )
}

const mapStateToProps = ({ studentData, userProfileData, advisorData }) => {
  const { getstudentplan, getplancourseliststatus, getphasecourse, getstudentplanphase, isgetplanphase,
    isCourseCreated, isCourseError, isActivityCreated, isActivityError, isEditCourse, isEditActivity, isCourseDeleted,isActivityDeleted } = studentData;
  const { userProfile } = userProfileData;
  const { undoCourseDropSuggestionStatus, undoActivityDropSuggestionStatus, undoActivitySuggestionStatus } = advisorData;
  return {
    getstudentplan, getplancourseliststatus, getphasecourse, getstudentplanphase, isgetplanphase, isCourseDeleted,
    isCourseCreated, isCourseError, isActivityCreated, isActivityError, isEditCourse, isEditActivity, userProfile,
    isActivityDeleted, undoCourseDropSuggestionStatus, undoActivityDropSuggestionStatus, undoActivitySuggestionStatus
  }
};

export default connect(mapStateToProps, { clearMessage, DeleteCourse, DeleteCourseStatus,DeleteActivityStatus, EditCourseStatus, EditPhase, CreateCourseStatus, 
  CreateActivityStatus, GetStudentPlanPhase, getPhasetime, CreateActivityErrorStatus, CreateCourseErrorStatus, EditActivityStatus, setUndoCourseDropSuggestionStatus,
  setUndoActivityDropSuggestionStatus })(Index);
