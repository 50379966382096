import React, { useState, useEffect } from "react";
import "../../../../../assets/css/New_Profile.less"
import { connect } from "react-redux";
import { SentInviteParent, clearMessage, GetInviteParent, GetInviteParentstatus, CopyInviteLinkstatus, SentInviteParentstatus, SentInviteParentErrorstatus, CopyInviteLink, getUserProfile,setCopyInviteFlag } from "../../../../../../src/redux/actions";
import { useForm, } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { EMAIL_LABEL } from "../../../../../Constants/commonLabels";
import { Button } from "@mui/material";
import Alert from 'react-bootstrap/Alert';

const validation = Yup.object().shape({
    email: Yup.string().required("* Required")
      .matches(
        /^([\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64})(,\s*[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64})*$/,
        'Please enter valid email addresses'
      )
  });

  
const Index = (props) => {
    const [open, setOpen] = useState(false);
    const [ErrorEmail, setErrorEmail] = useState("");
    const [loading, setloading] = useState(false);
    // Copylink
    const [link, setLink] = useState('')
    const [alertVisible, setalertVisible] = useState(false)
    const [ShowAlert, setShowAlert] = useState(false)


    const { register, handleSubmit, watch, control, formState: { errors, isDirty, dirtyFields }, getValues, setValue, reset } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(validation),
    });

    // Component Did Update
    useEffect(() => {
        props.getUserProfile();
        props.clearMessage();
        props.CopyInviteLinkstatus(false);
    }, []);

    useEffect(() => {
        if (props.sentinviteerror) {
            props.SentInviteParentErrorstatus(false)
        }
        if (props.isInviteSentSucess) {
            props.getUserProfile()
            props.SentInviteParentstatus(false)
        }
    }, [props.sentinviteerror, props.isInviteSentSucess])


    const onSubmit = (data) => {
          let emailLst = []
          let Emailfield
          if (data?.email) {
              Emailfield = data?.email?.split(',')
              Emailfield?.map(element => {
                  if(element.trim() !== ""){
                      emailLst.push(element.trim())
                  }
              });
          }
          let values = {
              emails: emailLst,
          }
        
          props.SentInviteParent(values);
          setloading(true);
          reset()
      };
    
    // const onSubmit = (data) => {
    //     if (data.email !== "") {
    //         let lastAtPos = data.email.lastIndexOf("@");
    //         let lastDotPos = data.email.lastIndexOf(".");
    //         if (
    //             !(
    //                 lastAtPos < lastDotPos &&
    //                 lastAtPos > 0 &&
    //                 data.email.indexOf("@@") === -1 &&
    //                 lastDotPos > 2 &&
    //                 data.email.length - lastDotPos > 2
    //             )
    //         ) {
    //             setErrorEmail("Not a valid email");
    //         } else {
    //             let emailLst = [];
    //             let Emailfield;
    //             if (data.email) {
    //                 Emailfield = data.email?.split(",");
    //                 Emailfield?.map((element) => {
    //                     if (element.trim() !== "") {
    //                         emailLst.push(element.trim());
    //                     }
    //                 });
    //             }
    //             let values = {
    //                 emails: emailLst,
    //             };
    //             props.SentInviteParent(values);
    //             setloading(true);
    //         }
    //     } else {
    //         setErrorEmail("*Required");
    //     }
    // };
    // Copy to Clipboard
    useEffect(() => {
        let values = {
            "role": "parent"
        }
        if (props.copyInviteFlag) {
            props.CopyInviteLink(values)
        }
    }, [])

    useEffect(() => {
        setLink(props.copyInvitelink ? props.copyInvitelink : '')
        props.setCopyInviteFlag(false)
    }, [props.copyInvitelink])

    useEffect(() => {
        if (props.copyInvitelink !== link) {
            let values = {
                "role": "parent"
            }
            if (props.copyInviteFlag || alertVisible) {
                props.CopyInviteLink(values)
            }
        }
    }, [link])

    function myFunction() {
        setShowAlert(true)
        let textfield = document.createElement("input");
        textfield.setAttribute('id', 'myInput')
        textfield.setAttribute('value', link)
        document.body.appendChild(textfield)
        let copyText = document.getElementById("myInput");
        copyText.focus();
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand('copy');
        textfield.remove()
        setalertVisible(true)
        setTimeout(() => {
            setShowAlert(false);
            setalertVisible(false)
        }, 3000);
    }

    const copyToClipboard = async () => {
        myFunction()
        setLink('')
    };

    return (
        <>
            {alertVisible &&
                <div style={{ position: 'fixed', top: '10px', zIndex: '99999', left: '50%', transform: 'translateX(-50%)' }} >
                    <Alert dismissible variant="success"
                        style={{ position: 'sticky', marginTop: '10px', }}
                        onClose={() => { setShowAlert(false); setalertVisible(false) }} >Copied to clipboard</Alert>
                </div>
            }
            <div className="Connected_Account_Page">
                <div className="Connected_Account_Card">
                    <div className="Connected_Account_Card_Top">
                        <p className="Connected_Account_Card_Title">Invite your Parent</p>
                    </div>
                    <div className="sendInvite__form_inner">
                        {props?.connectedparent && props?.connectedparent?.length < 2 ? (
                            <>
                                < form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row m-0">
                                        <div className="col-md-12 col-sm-12 col-12 ps-0 p-mob-0">
                                            <div className="input-floating-label">
                                                <input refs="email" type="text"  {...register("email")}
                                                    className={"textbox--primary textbox--rounded input"} name="email" placeholder=" " />
                                                <label>{EMAIL_LABEL}</label>
                                                <div className="error-text" style={{ position: "absolute", }}>{errors.email?.message}</div>
                                                <div className="error-text" style={{ position: "absolute", }}>{ErrorEmail}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row m-0">
                                        <div className="col-md-12 col-sm-12 col-12 ps-0 p-mob-0">
                                            <div className="text-center">
                                                <Button className="btn cta--rounded cta-primary mb-4 w-100" type="onSubmit" style={{ textTransform: "none", borderRadius:"10px" }} >
                                                    <span>Send Invite</span>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className="row m-0">
                                    <div className="col-md-12 col-sm-12 col-12 ps-0 p-mob-0">
                                        <p data-tip data-for="copy" className="text-center" style={{ cursor: "pointer" }} onClick={(e) => copyToClipboard(link)}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                                <path d="M13.5441 10.456C12.7249 9.6372 11.6142 9.17725 10.4561 9.17725C9.2979 9.17725 8.18716 9.6372 7.36805 10.456L4.27905 13.544C3.45993 14.3631 2.99976 15.474 2.99976 16.6325C2.99976 17.7909 3.45993 18.9018 4.27905 19.721C5.09818 20.5401 6.20914 21.0003 7.36755 21.0003C8.52597 21.0003 9.63693 20.5401 10.4561 19.721L12.0001 18.177" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M10.4561 13.5438C11.2752 14.3626 12.3859 14.8225 13.5441 14.8225C14.7022 14.8225 15.8129 14.3626 16.6321 13.5438L19.7211 10.4558C20.5402 9.63669 21.0004 8.52572 21.0004 7.36731C21.0004 6.2089 20.5402 5.09793 19.7211 4.27881C18.9019 3.45969 17.791 2.99951 16.6326 2.99951C15.4741 2.99951 14.3632 3.45969 13.5441 4.27881L12.0001 5.82281" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            Copy invite link
                                        </p>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="Already_Connect_Account">
                                    <div className="Already_Connect_Account_Title">You have connected with 2 parent</div>
                                </div>
                            </>
                        )
                        }
                    </div>

                </div>
            </div>
        </>
    );
}
const mapStateToProps = ({ userProfileData, studentData, commonData }) => {
    const { loading,copyInviteFlag } = commonData;
    const { isUserOnboarded, } = userProfileData;
    const { connectedparent } = studentData;
    const { copyInvitelinkSucess, copyInvitelink, getparentliststatus, getparentlist, isInviteSentSucess, sentinviteerror, copyinvitestatus, } = userProfileData;
    return { copyInviteFlag,isUserOnboarded, copyInvitelinkSucess, copyInvitelink, loading, getparentliststatus, getparentlist, isInviteSentSucess, sentinviteerror, connectedparent, copyinvitestatus, };
};

export default connect(mapStateToProps, { CopyInviteLink, CopyInviteLinkstatus, clearMessage, CopyInviteLinkstatus, SentInviteParent, clearMessage, GetInviteParent, GetInviteParentstatus, SentInviteParentstatus, SentInviteParentErrorstatus, CopyInviteLink, getUserProfile,setCopyInviteFlag })(Index);
