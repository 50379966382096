import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { isUserAuthenticated } from "../utils/AuthUtils"
import DashboardLayot from '../layoutsNew/Parent/dashboardLayout';
import DefaultLayout from '../layoutsNew/Parent/defaultLayout';
import MyWork from '../pages/Parent/MyWork'
import PhaseDetails from "../pages/Parent/MyWork/Phase"
import MilestoneDetails from "../pages/Parent/MyWork/MilesSection/index"
import MilestoneActivityDetails from "../pages/Parent/MyWork/MilesActivityDetails/index"
import Schedule from '../pages/Parent/Schedule'
import MyNetwork from '../pages/Parent/MyNetwork'
import UserProfile from "../pages/Parent/UserProfile"
import Library from '../pages/Parent/Library'
import ParentProfile from '../pages/Parent/ParentProfile'
import Notification from '../pages/Parent/Notification'
import ExploreStudentsNew from "../pages/Parent/MyNetwork/ExploreStudentsNew";
import ExploreAdvisor from "../pages/Parent/MyNetwork/ExploreAdvisorNew";

import PublicStudentProfile from "../pages/Common/PublicStudentProfile";
import DashboardOld from "../pages/Parent/Dashboard-old";


/*import child pages */
import Dashboard from "../pages/Parent/Dashboard";
import { RequireAuth } from "../utils/AuthUtils"

const Require = () => {
  let location = useLocation();
  let navigate = useNavigate();
}




const ParentRoutes = () => (

  <Routes>
    {Require()}
    {/* Parent DASHBOARD */}

    <Route exact path={"parent"} element={<DashboardLayot />} >
      <Route path="dashboard" element={<RequireAuth><Dashboard /></RequireAuth>} />
    </Route>

    {/* Parent otherpages */}
    <Route exact path={"parent"} element={<DefaultLayout />} >
      <Route path="mywork" key={"location.pathname"}  element={<RequireAuth><MyWork /></RequireAuth>} />
      <Route path="mywork/student/:id" element={<RequireAuth><MyWork /></RequireAuth>} />
      <Route path="mywork/student/:studentid/plan/:id" element={<RequireAuth><PhaseDetails /></RequireAuth>} />
      <Route path="mywork/student/:studentid/plan/:id/milestones/:courseId" element={<RequireAuth><MilestoneDetails /></RequireAuth>} />
      <Route path="mywork/student/:studentid/plan/:id/activity/milestone" element={<RequireAuth><MilestoneActivityDetails /></RequireAuth>} />
      <Route exact path="schedule" element={<Schedule />} />
      <Route path="mynetwork" element={<RequireAuth><MyNetwork /></RequireAuth>} />
      <Route path="library" element={<RequireAuth><Library /></RequireAuth>} />
      <Route path="myprofile" element={<RequireAuth><ParentProfile /></RequireAuth>} />
      <Route exact path="notification" element={<Notification />} />
      <Route path="view/profile/:studentId" element={<PublicStudentProfile />} ></Route>
      <Route path="profile/:id" element={<RequireAuth><UserProfile /></RequireAuth>} />
      <Route path="mywork/plan/:id" element={<RequireAuth><PhaseDetails /></RequireAuth>} />
      <Route path="mywork/plan/:id/milestones/:courseId" element={<RequireAuth><MilestoneDetails /></RequireAuth>} />
      <Route path="mywork/plan/:id/activity/milestone" element={<RequireAuth><MilestoneActivityDetails /></RequireAuth>} />

      <Route path="mynetwork/exploreStudents" element={<RequireAuth><ExploreStudentsNew /></RequireAuth>} />
      <Route path="mynetwork/exploreAdvisor" element={<RequireAuth><ExploreAdvisor /></RequireAuth>} />
    </Route>
  </Routes>
);

export default ParentRoutes;