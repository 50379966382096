import React from "react";
import { Routes, Route } from "react-router-dom";
import LoginLayout from '../layouts/Login';
import { RequireAuth } from "../utils/AuthUtils"

// Common pages
import Verify from "../pages/Common/Verify";
import Roles from "../pages/Common/Roles";
import Newprofile from "../pages/Common/CreateProfile";
// Student pages
import Goals from "../pages/Common/Goals";
import Sendinvites from "../pages/Common/SendInvites";
import Intialplans from "../pages/Common/Plans/Createnewplan";
import IntialplansParent from "../pages/Common/Plans/initalplansParent";
import ParentPlans from "../pages/Common/Plans/parentPlans";

import Plans from "../pages/Common/Plans";
import SelectFriend from "../pages/Common/SelectFriend";
import NewSelectAdvisor from "../pages/Common/NewSelectAdvisor";
// Advisor pages
import PriceSetting from "../pages/Common/PriceSetting"
import SelectStudent from "../pages/Common/SelectStudent"
import Pricing from "../pages/Common/Pricing";

// Parent pages
import ParentGoals from "../pages/Common/Goals/Parent_Goals"
import ConnectChild from "../pages/Common/Connect_with_Child";
import ParentProfile from "../pages/Common/CreateProfile/ParentProfile";
import SendInviteParent from "../pages/Common/SendInvites/SendInviteParent";
import Childprofile from "../pages/Common/CreateProfile/ParentProfile/Childprofile";
import InviteChild from "../pages/Common/SendInvites/InviteChildPlatform"
import Parentchildplan from "../pages/Common/Plans/Parentchildplan";
import SelectAdvisorChild from "../pages/Common/SelectAdvisorChild";
import SelectParent from "../pages/Common/SelectParent"



const OnboardingRoutes = () => (
  <Routes>
    <Route exact path="onboarding" element={<LoginLayout />} >
      {/* Common pages */}
      <Route path="roles" element={<RequireAuth><Roles /></RequireAuth>} />
      <Route path="invite/:key" element={<Verify />} />
      <Route path="profile" element={<RequireAuth><Newprofile /></RequireAuth>} />
      {/* Student onboard */}
      <Route path="goals" element={<RequireAuth><Goals /></RequireAuth>} />
      <Route path="sendinvites" element={<RequireAuth><Sendinvites /></RequireAuth>} />
      <Route path="plans" element={<RequireAuth><Intialplans /></RequireAuth>} />
      <Route path="intialplans" element={<RequireAuth><Plans /></RequireAuth>} />
      <Route path="selectadvisor" element={<RequireAuth><NewSelectAdvisor /></RequireAuth>} />
      <Route path="selectfriend" element={<RequireAuth><SelectFriend /></RequireAuth>} />
      <Route path="newSelectAdvisor" element={<RequireAuth>< NewSelectAdvisor/></RequireAuth>} />
      {/* Advisor onboard */}
      {/* <Route path="pricesetting" element={<RequireAuth><PriceSetting /></RequireAuth>} /> */}
      <Route path="pricing" element={<RequireAuth><Pricing /></RequireAuth>} />
      <Route path="selectstudent" element={<RequireAuth><SelectStudent /></RequireAuth>} />

      {/* Parent onboard */}
      <Route path="goals/child" element={<RequireAuth><ParentGoals /></RequireAuth>} />
      <Route path="connect/child" element={<RequireAuth><ConnectChild /></RequireAuth>} />
      <Route path="spouseinvite" element={<RequireAuth><SendInviteParent /></RequireAuth>} />
      <Route path="intialplansParent" element={<RequireAuth><IntialplansParent /></RequireAuth>} />
      <Route path="parent-plans" element={<RequireAuth><ParentPlans /></RequireAuth>} />
      <Route path="profile/parent" element={<RequireAuth><ParentProfile /></RequireAuth>} />
      <Route path="profile/child" element={<RequireAuth><Childprofile /></RequireAuth>} />
      <Route path="invite/child" element={<RequireAuth><InviteChild /></RequireAuth>} />
      <Route path="plan/child" element={<RequireAuth><Parentchildplan /></RequireAuth>} />
      <Route path="selectadvisor/child" element={<RequireAuth><SelectAdvisorChild /></RequireAuth>} />
      <Route path="selectparent" element={<RequireAuth><SelectParent /></RequireAuth>} />
    </Route>
  </Routes>
);
export default OnboardingRoutes;