import React from 'react'
import "./recomendedStudents.less";
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import StarIcon from '@mui/icons-material/Star';
import PersonIcon from '@mui/icons-material/Person';
import PillSection from "../../../PillSection"
import { useImageUrl } from '../../../../../utils/UseImageURL';

const Index = (props) => {
    let {name, country, avatarUrl, school, universities, professions, createPlan, helpWithMilestones, courseAndActivities, saveProfileIcon, children} = props
    const imgUrl = useImageUrl(avatarUrl)
    return (
        <>  
            {/* <p className='recommendedStudentCard-matchParam' style={{"minHeight":"40px", }}>{matchParam || ''}</p> */}
            <div className='recommendedStudentCard' style={{backgroundColor: 'white' }}>
                <div className="recommendedStudentCard__topSec">
                    {avatarUrl ?
                        <img className="recommendedStudentCard__topSec__avatarImage"
                            src={imgUrl} alt="userprofile" loading='lazy'
                        />
                    :
                        <PersonIcon className="recommendedStudentCard__topSec__userIcon" color="action"/>
                    }
                    <div className='recommendedStudentCard__topSec__userInfo'>
                        <p className='recommendedStudentCard__topSec__userInfo__primaryText'>{name}</p>
                        <p className='recommendedStudentCard__topSec__userInfo__secondaryText'>{country}</p>
                    </div>
                    <div className='bookmark' style={{marginLeft: 'auto', cursor: "pointer"}} >{saveProfileIcon}</div>
                </div>
                <div className="recommendedStudentCard__midSec">
                    <div className="recommendedStudentCard__midSec__educationSec">
                        <SchoolOutlinedIcon className='recommendedStudentCard__midSec__educationSec__schoolIcon'/>
                        <p className='recommendedStudentCard__midSec__educationSec__schoolName'>{school}</p>
                    </div>
                    <p className="recommendedStudentCard__midSec__label">
                        Needs help with
                    </p>
                    <div className="recommendedStudentCard__midSec__goalsSec">
                        { createPlan &&
                            <div className="recommendedStudentCard__midSec__goalsSec__targetUniversitySec">
                                <StarIcon className='recommendedStudentCard__midSec__goalsSec__targetUniversitySec__starIcon'/>
                                <p className='recommendedStudentCard__midSec__goalsSec__targetUniversitySec__label'> Creating plan based on goals </p>
                            </div>
                        }
                        {helpWithMilestones &&
                            <div className="recommendedStudentCard__midSec__goalsSec__targetUniversitySec">
                                <StarIcon className='recommendedStudentCard__midSec__goalsSec__targetUniversitySec__starIcon'/>
                                <p className='recommendedStudentCard__midSec__goalsSec__targetUniversitySec__label'> Review existing plan based on goals </p>
                            </div>
                        }
                        {courseAndActivities?.length > 0 &&
                            <>
                                <div className="recommendedStudentCard__midSec__goalsSec__targetUniversitySec">
                                    <StarIcon className='recommendedStudentCard__midSec__goalsSec__targetUniversitySec__starIcon'/>
                                    <p className='recommendedStudentCard__midSec__goalsSec__targetUniversitySec__label'>Mentoring for</p>
                                </div>
                                <PillSection data={courseAndActivities?.toSorted((a, b) => { return a.length - b.length })} maxWidth={192}  maxHeight={100} />
                            </>
                        }
                    </div> 
                </div>
                <div className="recommendedStudentCard__buttonSec">
                    {children}
                </div>
            </div>
        </>
    )
}

export default Index