import { GET_EXPLORE_ADVISOR_STATUS, GET_EXPLORE_ADVISOR,EXPLORE_ADVISOR_BY_SECTION, EXPLORE_ADVISOR_BY_UNIVERSITY, EXPLORE_ADVISOR_BY_UNIVERSITY_STATUS, EXPLORE_ADVISOR_BY_PROFESSION, EXPLORE_ADVISOR_BY_PROFESSION_STATUS, EXPLORE_ADVISOR_BY_COURSES, EXPLORE_ADVISOR_BY_COURSES_STATUS, GET_EXPLORE_ADVISOR_BY_PROFESSION_SECTION, EXPLORE_ADVISOR_BY_ACTIVITY, EXPLORE_ADVISOR_BY_ACTIVITY_STATUS,GET_EXPLORE_ADVISOR_BY_UNIVERSITY_SECTION, GET_EXPLORE_ADVISOR_BY_COURSE_SECTION, EXPLORE_STUDENT_BY_UNIVERSITY, EXPLORE_STUDENT_BY_UNIVERSITY_STATUS, EXPLORE_STUDENT_BY_PROFESSION, EXPLORE_STUDENT_BY_PROFESSION_STATUS, EXPLORE_STUDENT_BY_COURSES, EXPLORE_STUDENT_BY_COURSES_STATUS, GET_EXPLORE_ADVISOR_BY_ACTIVITY_SECTION, EXPLORE_STUDENT_BY_ACTIVITY, EXPLORE_STUDENT_BY_ACTIVITY_STATUS, GET_EXPLORE_STUDENT_BY_PROFESSION_SECTION, GET_EXPLORE_STUDENT_BY_COURSE_SECTION, GET_EXPLORE_STUDENT_BY_UNIVERSITY_SECTION, GET_EXPLORE_STUDENT_BY_ACTIVITY_SECTION, EXPLORE_MATCH_BY_SECTION, EXPLORE_MATCH_BY_SECTION_STATUS, GET_EXPLORE_STUDENT_BY_SECTION  } from "../constants/ExploreTypes";
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS  } from "../constants/CommonTypes";

import axios from 'axios'

const BASE_URL = process.env.REACT_APP_BASE_URL;



export const getExploreAdvisor = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		try {
			const { data } = await axios.post(BASE_URL + '/user-service/v1/search/suggestedadvisors/detailed', requestParams);
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: GET_EXPLORE_ADVISOR, payload: data.data });
				dispatch({ type: GET_EXPLORE_ADVISOR_STATUS, payload: true });
				console.log(data, "explore data");
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		}
	}
};

export const exploreAdvisorStatus = status => {
	return (dispatch) => {
		dispatch({ type: GET_EXPLORE_ADVISOR_STATUS, payload: status });
	}
}

export const exploreAdvisorByUniversity = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		try {
			const { data } = await axios.post(BASE_URL + '/user-service/v1/search/suggestedadvisors/categories/university', requestParams);
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: EXPLORE_ADVISOR_BY_UNIVERSITY, payload: data.data });
				dispatch({ type: EXPLORE_ADVISOR_BY_UNIVERSITY_STATUS, payload: true });
				return data?.data;
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message || error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		}
	}
};

export const setExploreAdvisorByUniversityStatus = status => {
	return (dispatch) => {
		dispatch({ type: EXPLORE_ADVISOR_BY_UNIVERSITY_STATUS, payload: status });
	}
}
export const resetExploreAdvisorByUniversity = () => {
	return (dispatch) => {
		dispatch({ type: EXPLORE_ADVISOR_BY_UNIVERSITY, payload: [] });
	}
}
export const getExploreAdvisorBySection = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		try {
			const { data } = await axios.post(BASE_URL + '/user-service/v1/search/suggestedadvisors/bysection', requestParams);
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				console.log("getExploreAdvisorBySection -- > 1");
				const sections = {
					profession: GET_EXPLORE_ADVISOR_BY_PROFESSION_SECTION,
					university: GET_EXPLORE_ADVISOR_BY_UNIVERSITY_SECTION,
					course: GET_EXPLORE_ADVISOR_BY_COURSE_SECTION,
					activity: GET_EXPLORE_ADVISOR_BY_ACTIVITY_SECTION
				}
				console.log("getExploreAdvisorBySection -- > 2");
				const sectionStatus = {
					profession: EXPLORE_ADVISOR_BY_PROFESSION_STATUS,
					university: EXPLORE_ADVISOR_BY_UNIVERSITY_STATUS,
					course: EXPLORE_ADVISOR_BY_COURSES_STATUS,
					activity: EXPLORE_ADVISOR_BY_ACTIVITY_STATUS
				}
				console.log("getExploreAdvisorBySection -- > 3");
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: sections[requestParams?.section], payload: {data: data.data} });
				dispatch({ type: sectionStatus[requestParams?.section], payload: true });
				dispatch({ type: EXPLORE_ADVISOR_BY_SECTION, payload: true });
				console.log("getExploreAdvisorBySection -- > 3");
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		}
	}
};
export const setExploreAdvisorBySectionStatus = status => {
	return (dispatch) => {
		dispatch({ type: EXPLORE_ADVISOR_BY_SECTION, payload: status });
	}
}
export const exploreAdvisorByProfession = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		try {
			const { data } = await axios.post(BASE_URL + '/user-service/v1/search/suggestedadvisors/categories/profession', requestParams);
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: EXPLORE_ADVISOR_BY_PROFESSION, payload: data.data });
				dispatch({ type: EXPLORE_ADVISOR_BY_PROFESSION_STATUS, payload: true });
				return data?.data;
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		}
	}
};

export const setExploreAdvisorByProfessionStatus = status => {
	return (dispatch) => {
		dispatch({ type: EXPLORE_ADVISOR_BY_PROFESSION_STATUS, payload: status });
	}
}
export const resetExploreAdvisorByProfession = () => {
	return (dispatch) => {
		dispatch({ type: EXPLORE_ADVISOR_BY_PROFESSION, payload: [] });
	}
}
export const exploreAdvisorByCourse = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		try {
			const { data } = await axios.post(BASE_URL + '/user-service/v1/search/suggestedadvisors/categories/course', requestParams);
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: EXPLORE_ADVISOR_BY_COURSES, payload: data.data });
				dispatch({ type: EXPLORE_ADVISOR_BY_COURSES_STATUS, payload: true });
				return data?.data;
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		}
	}
};

export const setExploreAdvisorByCourseStatus = status => {
	return (dispatch) => {
		dispatch({ type: EXPLORE_ADVISOR_BY_COURSES_STATUS, payload: status });
	}
}
export const resetExploreAdvisorByCourse = () => {
	return (dispatch) => {
		dispatch({ type: EXPLORE_ADVISOR_BY_COURSES, payload: [] });
	}
}
export const exploreAdvisorByActivity = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		try {
			const { data } = await axios.post(BASE_URL + '/user-service/v1/search/suggestedadvisors/categories/activity', requestParams);
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: EXPLORE_ADVISOR_BY_ACTIVITY, payload: data.data });
				dispatch({ type: EXPLORE_ADVISOR_BY_ACTIVITY_STATUS, payload: true });
				return data?.data;
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		}
	}
};

export const setExploreAdvisorByActivityStatus = status => {
	return (dispatch) => {
		dispatch({ type: EXPLORE_ADVISOR_BY_ACTIVITY_STATUS, payload: status });
	}
}
export const resetExploreAdvisorByActivity = () => {
	return (dispatch) => {
		dispatch({ type: EXPLORE_ADVISOR_BY_ACTIVITY, payload: [] });
	}
}
// ************************************* Explore Students *****************************

export const exploreStudentsByUniversity = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		try {
			const { data } = await axios.post(BASE_URL + '/user-service/v1/search/suggestedstudents/categories/university', requestParams);
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: EXPLORE_STUDENT_BY_UNIVERSITY, payload: data.data });
				dispatch({ type: EXPLORE_STUDENT_BY_UNIVERSITY_STATUS, payload: true });
				console.log(data?.data, "explore university data");
				return data?.data;
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		}
	}
};
export const setExploreStudentsByUniversityStatus = status => {
	return (dispatch) => {
		dispatch({ type: EXPLORE_STUDENT_BY_UNIVERSITY_STATUS, payload: status });
	}
}
export const resetExploreStudentsByUniversity = () => {
	return (dispatch) => {
		dispatch({ type: EXPLORE_STUDENT_BY_UNIVERSITY, payload: [] });
	}
}
export const exploreStudentsByProfession = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		try {
			const { data } = await axios.post(BASE_URL + '/user-service/v1/search/suggestedstudents/categories/profession', requestParams);
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: EXPLORE_STUDENT_BY_PROFESSION_STATUS, payload: true });
				dispatch({ type: EXPLORE_STUDENT_BY_PROFESSION, payload: data.data });
				return data?.data;
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		}
	}
};
export const setExploreStudentsByProfessionStatus = status => {
	return (dispatch) => {
		dispatch({ type: EXPLORE_STUDENT_BY_PROFESSION_STATUS, payload: status });
	}
}
export const resetExploreStudentsByProfession = () => {
	return (dispatch) => {
		dispatch({ type: EXPLORE_STUDENT_BY_PROFESSION, payload: [] });
	}
}
export const exploreStudentsByCourse = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		try {
			const { data } = await axios.post(BASE_URL + '/user-service/v1/search/suggestedstudents/categories/course', requestParams);
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: EXPLORE_STUDENT_BY_COURSES_STATUS, payload: true });
				dispatch({ type: EXPLORE_STUDENT_BY_COURSES, payload: data.data });
				return data?.data;
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		}
	}
};
export const setExploreStudentsByCourseStatus = status => {
	return (dispatch) => {
		dispatch({ type: EXPLORE_STUDENT_BY_COURSES_STATUS, payload: status });
	}
}
export const resetExploreStudentsByCourse = () => {
	return (dispatch) => {
		dispatch({ type: EXPLORE_STUDENT_BY_COURSES, payload: [] });
	}
}
export const exploreStudentsByActivity = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		try {
			const { data } = await axios.post(BASE_URL + '/user-service/v1/search/suggestedstudents/categories/activity', requestParams);
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: EXPLORE_STUDENT_BY_ACTIVITY_STATUS, payload: true });
				dispatch({ type: EXPLORE_STUDENT_BY_ACTIVITY, payload: data.data });
				return data?.data;
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		}
	}
};
export const setExploreStudentsByActivityStatus = status => {
	return (dispatch) => {
		dispatch({ type: EXPLORE_STUDENT_BY_ACTIVITY_STATUS, payload: status });
	}
}
export const resetExploreStudentsByActivity = () => {
	return (dispatch) => {
		dispatch({ type: EXPLORE_STUDENT_BY_ACTIVITY, payload: [] });
	}
}
export const getExploreStudentsBySection = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		try {
			const { data } = await axios.post(BASE_URL + '/user-service/v1/search/suggestedstudents/bysection', requestParams);
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				const sections = {
					profession: GET_EXPLORE_STUDENT_BY_PROFESSION_SECTION,
					university: GET_EXPLORE_STUDENT_BY_UNIVERSITY_SECTION,
					course: GET_EXPLORE_STUDENT_BY_COURSE_SECTION,
					activity: GET_EXPLORE_STUDENT_BY_ACTIVITY_SECTION
				}
				const sectionStatus = {
					profession: EXPLORE_STUDENT_BY_PROFESSION_STATUS,
					university: EXPLORE_STUDENT_BY_UNIVERSITY_STATUS,
					course: EXPLORE_STUDENT_BY_COURSES_STATUS,
					activity: EXPLORE_STUDENT_BY_ACTIVITY_STATUS
				}
				dispatch({ type: sections[requestParams?.section], payload: {data: data.data} });
				dispatch({ type: sectionStatus[requestParams?.section], payload: true });
				dispatch({ type: GET_EXPLORE_STUDENT_BY_SECTION, payload: true });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		}
	}
};

export const getExploreStudentsFromParentBySection = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		try {
			const { data } = await axios.post(BASE_URL + '/user-service/v1/search/suggestedstudentsforparents/bysection', requestParams);
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				const sections = {
					profession: GET_EXPLORE_STUDENT_BY_PROFESSION_SECTION,
					university: GET_EXPLORE_STUDENT_BY_UNIVERSITY_SECTION,
					course: GET_EXPLORE_STUDENT_BY_COURSE_SECTION,
					activity: GET_EXPLORE_STUDENT_BY_ACTIVITY_SECTION
				}
				const sectionStatus = {
					profession: EXPLORE_STUDENT_BY_PROFESSION_STATUS,
					university: EXPLORE_STUDENT_BY_UNIVERSITY_STATUS,
					course: EXPLORE_STUDENT_BY_COURSES_STATUS,
					activity: EXPLORE_STUDENT_BY_ACTIVITY_STATUS
				}
				dispatch({ type: sections[requestParams?.section], payload: {data: data.data} });
				dispatch({ type: sectionStatus[requestParams?.section], payload: true });
				dispatch({ type: GET_EXPLORE_STUDENT_BY_SECTION, payload: true });
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		}
	}
};

export const setExploreStudentsBySectionStatus = status => {
	return (dispatch) => {
		dispatch({ type: GET_EXPLORE_STUDENT_BY_SECTION, payload: status });
	}
}


// ************************************* Explore Students student and parent  *****************************

export const exploreFromStudentUniversity = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		try {
			const { data } = await axios.post(BASE_URL + '/user-service/v1/search/suggestedstudentsforparent/categories/university', requestParams);
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: EXPLORE_STUDENT_BY_UNIVERSITY, payload: data.data });
				dispatch({ type: EXPLORE_STUDENT_BY_UNIVERSITY_STATUS, payload: true });
				console.log(data?.data, "explore university data");
				return data?.data;
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		}
	}
};

export const exploreFromStudentProfession = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		try {
			const { data } = await axios.post(BASE_URL + '/user-service/v1/search/suggestedstudentsforparent/categories/profession', requestParams);
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: EXPLORE_STUDENT_BY_PROFESSION_STATUS, payload: true });
				dispatch({ type: EXPLORE_STUDENT_BY_PROFESSION, payload: data.data });
				return data?.data;
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		}
	}
};

export const exploreFromStudentCourse = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		try {
			const { data } = await axios.post(BASE_URL + '/user-service/v1/search/suggestedstudentsforparent/categories/course', requestParams);
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: EXPLORE_STUDENT_BY_COURSES_STATUS, payload: true });
				dispatch({ type: EXPLORE_STUDENT_BY_COURSES, payload: data.data });
				return data?.data;
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		}
	}
};

export const exploreFromStudentActivity = requestParams => {
	return async (dispatch) => {
		dispatch({ type: FETCH_START });
		try {
			const { data } = await axios.post(BASE_URL + '/user-service/v1/search/suggestedstudentsforparent/categories/activity', requestParams);
			const { headers: { statusCode, message } } = data;
			if (statusCode === "200") {
				dispatch({ type: FETCH_SUCCESS });
				dispatch({ type: EXPLORE_STUDENT_BY_ACTIVITY_STATUS, payload: true });
				dispatch({ type: EXPLORE_STUDENT_BY_ACTIVITY, payload: data.data });
				return data?.data;
			} else {
				dispatch({ type: FETCH_ERROR, payload: message });
			}
		} catch (error) {
			if (error.response) {
				const { headers: { message, statusCode }, errorlist } = error.response.data;
				dispatch({ type: FETCH_ERROR, payload: { message: message ? message : error.message, errorList: errorlist, statusCode: statusCode } });
			} else {
				dispatch({ type: FETCH_ERROR, payload: { message: error.message } });
			}
			console.log("Error****:", error.message);
		}
	}
};
