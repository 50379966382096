import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Box, Grid } from "@mui/material";
import 'react-quill/dist/quill.snow.css';
import { RADIO_HIGH_SCHOOL_LABEL, RADIO_PROFESSION_LABEL, RADIO_UNIVERSITY_LABEL } from "../../../../Constants/commonLabels";
import "./currentStatus.less"
import { getUserProfile } from "../../../../redux/actions";
import HighSchoolStatus from "./HighSchoolStatus"
import UniversityStatus from "./UniversityStatus"
import ProfessionalStatus from "./ProfessionalStatus"

const FreeConsultation = [
    { id: 0, value: 1 },
    { id: 1, value: 2 },
    { id: 2, value: 3 },
];

const Index = (props) => {
    const [loading, setLoading] = useState(false)
    const [myStatusOpts, setMyStatusOpts] = useState([])
    const [graduationOpts, setGraduationOpts] = useState([])
    const [selectedOption, setSelectedOption] = useState('')
    const [helpsWith, setHelpsWith] = useState({
        specificCourseAndActivities: false,
        helpWithPlan: false
    })
    const [noOfFreeConsultations, setNoOfFreeConsultations] = useState(1)


    useEffect(() => {
        setLoading(true)
        setInitialValues()
    }, [])

    useEffect(() => {
        setInitialValues()
    }, [props.userProfile, props.status, props.graduationyear, loading])
    
    const handleRadioChange = (e) => {
        const names = e.currentTarget.value
        setSelectedOption(names)
    }

    const setInitialValues = () => {
        let statusOptions = [];
        let graduationOptions = [];

        if (props?.userProfile !== undefined && props?.status !== undefined && props?.graduationyear !== undefined ){
            let userProfileData = props?.userProfile?.advisorProfile;

            props?.status?.length > 0 && props?.status?.map((x, key) => {
                if (x.role === "highschool") {
                    let temp = { label: x.status, value: x.status }
                    statusOptions.push(temp);
                }
            });
            helpsWith.helpWithPlan = userProfileData?.helpWithPlan
            helpsWith.specificCourseAndActivities = userProfileData?.helpWithSpecificCourseActivity

            props?.graduationyear?.length > 0 && props?.graduationyear?.map((x, key) => {
                let temp = { label: x, value: x}
                graduationOptions.push(temp);
            })

            if (userProfileData?.currentlyIn === "highschool") {
                setSelectedOption("highschool")
            }
            else if (userProfileData?.currentlyIn === "university") {
                setSelectedOption("university")
            }
            else {
                setSelectedOption("proffesional")
            }
            setNoOfFreeConsultations(props?.userProfile?.advisorProfile?.noOfFreeConsultation ?? 1)
            setHelpsWith({...helpsWith})
            setMyStatusOpts(statusOptions)
            setGraduationOpts(graduationOptions)
            setLoading(false)
        }
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container >
                <div className="curentStatusSec">
                    <div className="curentStatusSec__titleSec">
                      <p className="curentStatusSec__titleSec__formSubTitle">
                            I am currently in..
                        </p>
                    </div>
                    <div className="curentStatusSec__radioSec">
                        <div className="styled-radio-flex">
                            <div className="styled-radio">
                                <input id="radio-1" name="currentlyIn" type="radio" value="highschool" checked= {selectedOption === 'highschool'} onChange= {(e) => handleRadioChange(e)}/>
                                <label htmlFor="radio-1" className="radio-label">{RADIO_HIGH_SCHOOL_LABEL}</label>
                            </div>
                            <div className="styled-radio">
                                <input id="radio-2" name="currentlyIn" type="radio" value="university" checked= {selectedOption === 'university'} onChange= {(e) => handleRadioChange(e)}/>
                                <label htmlFor="radio-2" className="radio-label">{RADIO_UNIVERSITY_LABEL}</label>
                            </div>
                            <div className="styled-radio">
                                <input id="radio-3" name="currentlyIn" type="radio" value="proffesional" checked= {selectedOption === 'proffesional'} onChange= {(e) => handleRadioChange(e)} />
                                <label htmlFor="radio-3" className="radio-label">{RADIO_PROFESSION_LABEL}</label>
                            </div>
                        </div>
                    </div>
                    <div className="curentStatusSec__formSec">
                        {selectedOption === "highschool" && 
                            <HighSchoolStatus/>
                        }
                        {selectedOption === "university" &&
                            <UniversityStatus/>
                        }
                        {selectedOption === "proffesional" &&
                            <ProfessionalStatus/>
                        }
                    </div>
                </div>
            </Grid>
        </Box>
    );
};

const mapStateToProps = ({ userProfileData, commonData }) => {
    const { status, loading, graduationyear } = commonData;
    const { userProfile } = userProfileData;
    return { status, loading, graduationyear, userProfile };
  };
  export default connect(mapStateToProps, { getUserProfile  })(Index);