import React, { useState, useEffect } from "react";
import "./rescheduleEvent.less";
import { connect } from "react-redux";
import { Grid, Button, TextField } from '@mui/material';
import { TimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Select, { components } from "react-select";
import CustomAlert from "../../../../components/CustomAlert"
import Loader from "../../../../components/Loader";
import Modal from "react-bootstrap/Modal";
import ReactQuill from 'react-quill';
import { rescheduleEvent, rescheduleEventStatus, rescheduleEventErrorStatus, reSchedulePaidEvent, setReSchedulePaidEventStatus, listScheduledEvents } from "../../../../redux/actions"
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from "dayjs";

const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder}</Placeholder>
            {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
        </ValueContainer>
    );
};

const Hours = [
    { label: "30 Minutes", value: 30 },
    { label: "60 Minutes", value: 60 },
    { label: "90 Minutes", value: 90 },
    { label: "120 Minutes", value: 120 },
]

const validation = Yup.object({
    Selectdate: Yup.date().required('*Required').nullable(),
    startTime: Yup.date().required('*Required').nullable(),
    SelectHours: Yup.object().required("*Required").nullable(),
    Location: Yup.string().required("*Required").nullable(),
    Description: Yup.string().required("*Required").transform((value) => {
        return value.replace(/<[^>]+>/g, '').replace(/\&nbsp;+/g, ' ')
    }).max(750, "Description should not exceed 750 characters").nullable(),
})

const Index = (props) => {
    const [loader, setloader] = useState(false)
    const [selectedTimesheet, setSelectedTimesheet] = useState([]);
    const [advisorId, setAdvisorId] = useState("");
    const [EventId, setEventId] = useState("");

    const { register, handleSubmit, reset, watch, control, formState: { errors }, setValue, clearErrors, } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(
            validation
        ),
    });

    useEffect(() => {
        setloader(false)
        const { start, meetingLocation } = props?.data;
        const formattedDate = dayjs(start)?.format('YYYY-MM-DD');
        setValue('Selectdate', formattedDate);
        setValue('startTime', dayjs(start)?.format("YYYY-MM-DD hh:mm a"));
        setValue('Location', meetingLocation);
        setValue('SelectHours', Hours.find(hour => hour.value === props?.data?.eventDuration));
        setEventId(props?.data?.EventId)
    }, [])

    useEffect(() => {
        if (props?.reScheduledEvents) {
            props.listScheduledEvents()
            props?.onHide()
            handleClose()
            setloader(false)
            reset()
            props?.rescheduleEventStatus(false)
        }
        if (props?.reScheduledError) {
            props?.onHide()
            handleClose()
            setloader(false)
            reset()
            props?.rescheduleEventErrorStatus(false)
        }
        if (props?.reSchedulePaidEventStatus) {
            props?.onHide()
            handleClose()
            setloader(false)
            reset()
            props?.setReSchedulePaidEventStatus(false);
        } else {
            setloader(false)
        }

    }, [props?.reScheduledEvents, props?.reScheduledError, props?.reSchedulePaidEventStatus])

    const handleClose = () => {
        setloader(false)
    };

    const convertDateFormat = (dateStr) => {
        const dateObj = new Date(dateStr);
        const padZero = (num) => (num < 10 ? '0' : '') + num;
        
        const year = dateObj.getFullYear();
        const month = padZero(dateObj.getMonth() + 1); 
        const day = padZero(dateObj.getDate());
        const hours = padZero(dateObj.getHours());
        const minutes = padZero(dateObj.getMinutes());
        const seconds = padZero(dateObj.getSeconds());
        
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        
        return formattedDate;
    }

    const onSubmit = (data) => {
        console.log(data);
        let eventDate = convertDateFormat(data.Selectdate)?.split(" ")?.[0]
        let eventTime = convertDateFormat(data.startTime)?.split(" ")?.[1]
        console.log(eventDate, eventTime);
        let payload = {
            eventId: props?.data?.id,
            eventTime: `${eventDate} ${eventTime}`,
            reason: data.Description,
            eventDuration: data?.SelectHours?.value,
        }
        console.log(payload, "payload");
        props?.rescheduleEvent(payload)
    }

    return (
        <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
            <Modal.Header closeButton onClick={handleClose} >
                <Modal.Title id="contained-modal-title-vcenter">
                    Reschedule Session
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='modalContent'>
                {loader && <Loader />}
                <div style={{ "width": "100%", "display": "flex", "justifyContent": "center", "paddingTop": "5px" }}> {<CustomAlert />}</div>
                <form id="" onSubmit={handleSubmit(onSubmit)} >
                    <Grid container>
                        <Grid item xs={12} sx={{ width: '100%' }}>
                            <div className="input-floating-label">
                            <textarea {...register("Description")} refs="Description" maxLength={250} className={"textarea--primary textbox--rounded input"} name="Description"
                                        placeholder=" " style={{ "minHeight": "100px", "resize": "none" }} id="resourceName" />
                                <label >Reason for Reschedule </label>
                                <div className="subtext" style={{ float: 'right' }}><span>{watch("Description")?.replace(/<[^>]+>/g, '').replace(/\&nbsp;+/g, ' ')?.length || 0}</span>/750</div>
                                <div className="error-text" style={{ "position": "absolute" }} >{errors?.Description?.message}</div>
                            </div>
                        </Grid>
                        <Grid item sm={12} md={12} lg={12} sx={{ width: '100%' }}>
                            <div className="input-floating-label">
                                {/* <input ref="Selectdate" type="date" className="textbox--primary textbox--rounded input"
                                    name="Selectdate" placeholder="Selectdate" {...register('Selectdate')}
                                /> */}
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Controller
                                        name="Selectdate"
                                        control={control}
                                        defaultValue={null} // Set your initial value here
                                        render={({ field }) => (
                                            <DateTimePicker
                                                label="Select Date"
                                                value={field.value}
                                                views={['year', 'month', 'day']}
                                                inputFormat="dd/MM/yyyy"
                                                onChange={(date) => {
                                                    if (date instanceof Date && !isNaN(date.getTime())) {
                                                        field.onChange(date);
                                                    }
                                                }}
                                                minDate={dayjs()?.tz(props?.userProfile?.timezone)?.startOf('day')}
                                                renderInput={(params) => <TextField {...params}
                                                    sx={{
                                                        width: '100%',
                                                        '& .MuiOutlinedInput-root': {
                                                            border: 'none', // Remove the border

                                                            '&:hover fieldset': {
                                                                border: 'none', // Remove hover border

                                                            },
                                                        },
                                                    }}
                                                />}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                                <label>Selectdate</label>
                                <div className="error-text" style={{ "position": "absolute" }} >{errors?.Selectdate?.message}</div>
                            </div>
                        </Grid>
                        <Grid item sm={12} md={12} lg={12} xl={12} marginBottom={4} sx={{ width: '100%' }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Controller name="startTime" control={control} defaultValue={null}
                                    render={({ field }) => (
                                        <TimePicker
                                            {...field}
                                            className="timePicker"
                                            label="Start Time"
                                            placeholder="Start Time"
                                            DialogProps={{ sx: { '& .MuiPickersToolbar-penIconButton': { display: 'none' } } }}
                                            renderInput={(params) => <TextField {...params} sx={{ width: "100%" }} />}
                                            minutesStep={30}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                            <div className="error-text" style={{ "position": "absolute" }} >{errors?.startTime?.message}</div>
                        </Grid>
                        <Grid item sm={12} md={12} lg={12} marginBottom={4} sx={{ width: '100%' }}>
                            <Controller
                                name="SelectHours"
                                control={control}
                                render={({ field }) =>
                                    <Select name="Select Hours" {...field}
                                        placeholder="Select Hours" className="goals__form__select mb-0" classNamePrefix="mySelect"
                                        closeMenuOnSelect={true} isClearable={false} options={Hours}
                                        components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                        styles={{
                                            container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible", zIndex: "2" }),
                                            valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', }),
                                            placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10 })
                                        }}
                                    />
                                }
                            />
                            <div className="error-text" style={{ "position": "absolute" }} >{errors?.SelectHours?.message}</div>
                        </Grid>
                        <Grid item sm={12} md={12} lg={12} sx={{ width: '100%' }}>
                            <div className="input-floating-label">
                                <input refs="Location" type="text" className="textbox--primary textbox--rounded input"
                                    placeholder="Location" {...register('Location')}
                                />
                                <label>Meeting Location</label>
                                <div className="error-text" style={{ "position": "absolute" }} >{errors?.Location?.message}</div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sx={{ width: '100%' }}>
                            <div className="BookSessionModal__footer">
                                <Button variant="contained" className="bookBtn" type="submit">Re Schedule</Button>
                            </div>
                        </Grid>
                    </Grid>
                </form>
            </Modal.Body>
        </Modal>
    );
};

const mapStateToProps = ({ commonData, advisorData, userProfileData, studentData }) => {
    const { scheduledEvents, reScheduledEvents, reScheduledError } = commonData;
    const { eventConfirmationStatus } = advisorData;
    const { advisorlistdata } = studentData
    const { userProfile } = userProfileData;
    return { scheduledEvents, eventConfirmationStatus, userProfile, advisorlistdata, reScheduledEvents, reScheduledError };
};

export default connect(mapStateToProps, { rescheduleEvent, rescheduleEventStatus, rescheduleEventErrorStatus, reSchedulePaidEvent, setReSchedulePaidEventStatus, listScheduledEvents })(Index);

