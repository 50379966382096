import React, { useEffect, useState } from "react";
import "../advMyNetwork.less";
import { connect } from "react-redux";
import Loader from "../../../../components/Loader";
import RejectPopup from "../../../../components/rejectPopup";
import { getPendingRequests, sendRespondToRequest, setPendingRequestsStatus, 
    setSendRespondToRequestStatus, resetDetailedUserProfileStatus, getNetworkUserCount } from "../../../../redux/actions";
import ConnectedStudents from "../../../../componentsNew/Common/Cards/Common/ConnectedStudents";
import ViewStudentProfile from '../../../../componentsNew/Common/ViewStudentProfile';
import ConfirmationPopup from "../../../../componentsNew/Common/ConfirmationPopup";

const Index = (props) => {
    const [loading, setLoading] = useState(false);
    const [showProfile, setShowProfile] = useState(false);
    const [profileData, setProfileData] = useState({});
    const [showReject, setShowReject] = useState(false)
    const [showAcceptModal, setShowAcceptModal] = useState(false);
    const [acceptData, setAcceptData] = useState({})
    const [rejectdata, setRejectData] = useState({})

    useEffect(() => {
        setLoading(true);
        props.getPendingRequests();
    }, []);

    useEffect(() => {
        if (props.pendingRequestStatus) {
            props.setPendingRequestsStatus(false);
            setLoading(false);
        }
        if (props.isRequestRespond) {
            props.setSendRespondToRequestStatus(false);
            props?.getNetworkUserCount()
            openAcceptRequestModal({}, false);
            props.getPendingRequests();
            setLoading(false); 
        }
    }, [props.pendingRequestStatus, props.isRequestRespond]);
    
    const handleViewProfile = (data) => {
        setProfileData(data);
        showProfileModal(true);
    }
    const showProfileModal = (status) => {
        if(!status) {
            setProfileData({})
            props?.resetDetailedUserProfileStatus();
        }
        setShowProfile(status)
    }
    const handleAcceptRequest = (rejectReason, data, status) => {
        console.log(rejectReason, "---", data, status);
        let payload = {
            requestId: data?.requestInfo?.id || data?.id,
            isAccepted: status,
        };
        if (!status) {
            payload['rejectReason'] = rejectReason?.description || ""
        }
        setLoading(true);
        console.log(payload, "payload");
        props.sendRespondToRequest(payload);
    };
    const openAcceptRequestModal = (data, status) =>{
        console.log(data, "data");
        setShowAcceptModal(status);
        setAcceptData(data);
    } 
    const openRejectRequestModal = (data, status) =>{
        setShowReject(status);
        setRejectData(data);
    } 
    
    return (
        <>
            {loading && <Loader />}
            {props.pendingList?.students?.length > 0 ? (
                !loading &&
                props.pendingList?.students?.map((data, index) => {
                    let {requestInfo, requestSentUser, courses, activities} = data;
                    return (
                        <div key={requestSentUser?.id}>
                            <ConnectedStudents 
                                name = {requestSentUser?.fullName}
                                country = {requestSentUser?.country}
                                avatarUrl= {requestSentUser?.avatarPath}
                                school = {requestSentUser?.studentProfile?.currrentSchoolName}
                                gpa = {requestSentUser?.studentProfile?.desiredGpa}
                                universities = {requestSentUser?.studentProfile?.universities?.toSorted((a, b) => {return a.length - b.length} )}
                                professions = {requestSentUser?.studentProfile?.professions?.toSorted((a, b) => {return a.length - b.length})}
                                // matchParam = {element?.matchingParams?.[0]}
                                courseAndActivities = {courses?.concat(activities)?.toSorted((a, b) => {return a.length - b.length} )}
                                weighted= {requestSentUser?.studentProfile?.targetWeighted}
                                unWeighted= {requestSentUser?.studentProfile?.targetUnweighted}
                                recipientUrl = ""
                                message = {requestInfo?.message}
                            >
                                <>
                                <button className="suggestedStudentCard__buttonSec__primary-btn" id={`viewProfile-${index}`} onClick={() => handleViewProfile({id: requestSentUser?.id})}>
                                    View Profile    
                                </button>
                                <div className="connectedStudentCard__buttonSec__inline">
                                    <button className="connectedStudentCard__buttonSec__inline__danger-btn" id={`rejectBtn-${index}`} onClick={() => openRejectRequestModal(data, true)}>
                                        Reject
                                    </button>
                                    <button className="connectedStudentCard__buttonSec__inline__primary-btn" id={`acceptBtn-${index}`} onClick={() => openAcceptRequestModal(data, true)}>
                                        Accept  
                                    </button>
                                </div>
                                </>
                            </ConnectedStudents>
                        </div>
                    );
                })
            ) : (
                <div className="networkNoDataSec">
                    <div className="networkNoDataSec__nodataCard">
                        <p className="networkNoDataSec__nodataCard__textBlack">You have not recieved any requests yet</p>
                    </div>
                </div>
            )}
            {showProfile &&
                <ViewStudentProfile show={showProfile} profileData={profileData} handleClose={() => showProfileModal(false)}/>
            }
            {showAcceptModal &&
                <ConfirmationPopup show={showAcceptModal} handleSubmit={() => handleAcceptRequest({}, acceptData?.requestInfo, true )} message={`Accepting this connection will enable ${acceptData?.requestSentUser?.firstName} to book their free 30 min session on your calendar.`} btnText="confirm" onHide={() => openAcceptRequestModal({}, false)} />
            }
            {showReject &&
                <RejectPopup show={showReject} data={rejectdata} handleConnectValues={(rejectReason, rejectdatas) => handleAcceptRequest(rejectReason, rejectdata, false)}  handleClose={(e) => setShowReject(false)}  />
            }
        </>
    );
};

const mapStateToProps = ({ commonData }) => {
    const { pendingList, pendingRequestStatus, isRequestRespond } = commonData;
    return { pendingList, pendingRequestStatus, isRequestRespond };
};
export default connect(mapStateToProps, {
    getPendingRequests,setPendingRequestsStatus,sendRespondToRequest, setSendRespondToRequestStatus, 
    resetDetailedUserProfileStatus, getNetworkUserCount
})(Index);
