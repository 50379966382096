import React, { useState } from 'react'
import { connect, useSelector } from "react-redux";
import { withRouter } from "../../redux/store/navigate";
import { NavLink, Link } from "react-router-dom";
import "./switchStyle.less"
import Profile from "../../assets/images/icons/customprofile.png"
import { userSignOut } from "../../redux/actions";
import { getUserData, setSession } from "../../utils/AuthUtils";
import Grid from '@mui/material/Grid';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from 'react-router-dom';

export const Topbar = (props) => {
    const navigate = useNavigate()
    const { value, childprofile } = useSelector((state) => state.userProfileData)
    const [isActive, setisActive] = useState(false)
    const [roles, setroles] = useState("parent/studentview")
    const [isshowprofileMenu, setisshowprofileMenu] = useState(false)

    const handleClear = () => {
        localStorage.removeItem("parentViaChild");
        navigate('/parent/dashboard');
        window.location.reload();
	}

    return (

            <div className='stickyPos'>
                <Grid container spacing={2} sx={{ background: '#1C84EE' }}>
                    <Grid item xs={7} sm={6} md={8}>
                        <div className='switchTitle' >{props?.userProfile} logged in as : {props?.name}</div>
                    </Grid>


                </Grid>
            </div>

    )
}
