import React from "react";
import "./ratingSec.less";
import { LinearProgress } from "@mui/material";

const Index = ({reviewSummary}) => {
    console.log("reviewSummary", reviewSummary);
    return (
        <div className="rating-sec">
            <div className="rating-sec__progressBar">
                <p className="rating-sec__progressBar__label">5 Stars</p>
                <LinearProgress
                    variant="determinate"
                    className="rating-sec__progressBar__linearRating"
                    value={reviewSummary?.noof5StarReviews * 10}
                />
            </div>
            <div className="rating-sec__progressBar">
                <p className="rating-sec__progressBar__label">4 Stars</p>
                <LinearProgress
                    variant="determinate"
                    className="rating-sec__progressBar__linearRating"
                    value={reviewSummary?.noof4StarReviews * 10}
                />
            </div>
            <div className="rating-sec__progressBar">
                <p className="rating-sec__progressBar__label">3 Stars</p>
                <LinearProgress
                    variant="determinate"
                    className="rating-sec__progressBar__linearRating"
                    value={reviewSummary?.noof3StarReviews * 10} 
                />
            </div>
            <div className="rating-sec__progressBar">
                <p className="rating-sec__progressBar__label">2 Stars</p>
                <LinearProgress
                    variant="determinate"
                    className="rating-sec__progressBar__linearRating"
                    value={reviewSummary?.noof2StarReviews * 10}
                />
            </div>
            <div className="rating-sec__progressBar">
                <p className="rating-sec__progressBar__label">1 Star</p>
                <LinearProgress
                    variant="determinate"
                    className="rating-sec__progressBar__linearRating"
                    value={reviewSummary?.noof1StarReviews * 10}
                />
            </div>
        </div>
    );
};

export default Index;
