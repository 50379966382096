import React from "react";
import { connect } from "react-redux";
import { Grid, Box } from "@mui/material";
import ProfileService from "../../../../componentsNew/Advisor/ProfileService";
import "./advPersonalInfo.less";
import PasswordSec from "./PasswordSec"
import GeneralForm from "./GeneralForm"
import SocialBlockSec from "./SocialBlockSec";
import DeleteProfileSec from "../DeleteProfileSec";
import CopyInviteSec from "./CopyInviteSec";
import ProfileUploadSec from "./ProfileUploadSec";



const Index = ( props ) => {
    return (
		<Box sx={{ flexGrow: 1 }} spacing={1}>
			<Grid container spacing={8} mb= {3}>
				<Grid item xs={12} sm= {12} md={6} lg={6}>
					<div className="advisor-personalInfo__profileSec">
						<ProfileUploadSec />
					</div>
				</Grid>
				<Grid item xs={12} sm= {12} md={6} lg={6}>
					<div className="advisor-personalInfo__serviceSec">
						<ProfileService />
					</div>
				</Grid>
			</Grid>
			<Grid container spacing={8} mb= {3} >
				<Grid item xs={12} sm= {12} md={6} lg={6}>
					<div className="advisor-personalInfo__generalSec">
						<GeneralForm />
					</div>
				</Grid>
				<Grid item xs={12} sm= {12} md={6} lg={6} >
					<div className="advisor-personalInfo__passwordSec">
						{props.userProfile?.signedupVia === "default" ?
							<PasswordSec /> 
							: 
							<SocialBlockSec />
						} 
					</div>
				</Grid>
			</Grid>
			<Grid container spacing={8} mb={3} >
				<Grid item xs={12} sm= {12} md={6} lg={6} >
					<div className="advisor-personalInfo__copyLinkSec" >
						<CopyInviteSec />
					</div>
				</Grid>
				<Grid item xs={12} sm= {12} md={6} lg={6} >
					<div className="advisor-personalInfo__deleteProfSec" >
						<DeleteProfileSec />
					</div>
				</Grid>
			</Grid>
		</Box>
    );
};

const mapStateToProps = ({ userProfileData }) => {
    const { userProfile } = userProfileData;
    return { userProfile };
  };
  export default connect(mapStateToProps, {  })(Index);
