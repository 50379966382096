import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "../../assets/css/sendInvite.less";
import { connect } from "react-redux";
import { withRouter } from "../../redux/store/navigate";
import Dropzone from "react-dropzone";
class Index extends Component {
    state = {};

    render() {
        return (
            <>
                <Modal
                    show={this.props.showDocument}
                    fullscreen={this.props.fullscreen}
                    onHide={this.props.onHide}
                >
                    <Modal.Header
                        className="border-0"
                        closeButton
                    ></Modal.Header>
                    <Modal.Body>
                        <div className="profile">
                            <div className="profile__form">
                                <h1 className="profile__form__title text-center">
                                    Add document
                                </h1>
                                <p className="profile__form__sub-title">
                                    File name
                                </p>
                                <Form>
                                    <div className="row m-0">
                                        <div className="col-md-12 col-sm-12 col-12 ps-0 p-mob-0">
                                            <div className="input-floating-label">
                                                <input
                                                    refs="name"
                                                    type="text"
                                                    className={
                                                        "textbox--primary textbox--rounded input"
                                                    }
                                                    name="name"
                                                    placeholder=" "
                                                />
                                                <label>Name</label>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="profile__form__sub-title">
                                        Description
                                    </p>
                                    <div className="row m-0">
                                        <div className="col-md-12 col-sm-12 col-12 ps-0 p-mob-0">
                                            <div className="input-floating-label">
                                                <textarea
                                                    refs="description"
                                                    style={{ height: "100px" }}
                                                    type="text"
                                                    className={
                                                        "textbox--primary textbox--rounded input"
                                                    }
                                                    name="description"
                                                    placeholder=" "
                                                />
                                                <label>Some text here...</label>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="profile__form__sub-title">
                                        External Source
                                        <span className="float-rights me-2">
                                            <svg
                                                width="36"
                                                height="24"
                                                viewBox="0 0 36 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <g clip-path="url(#clip0_1066_94902)">
                                                    <path
                                                        d="M13.7485 7.22901V7.2279L21.3079 11.7267L25.8123 9.84289C26.7279 9.44997 27.7148 9.24792 28.7121 9.24923C28.8787 9.24923 29.0429 9.25706 29.2061 9.26712C28.6648 7.16892 27.5216 5.27215 25.9156 3.80735C24.3096 2.34256 22.3099 1.37282 20.1596 1.01601C18.0093 0.65921 15.8009 0.930704 13.803 1.7975C11.8051 2.66429 10.1036 4.08905 8.9054 5.8986L9.02017 5.89525C10.6906 5.89308 12.3284 6.35506 13.7485 7.22901V7.22901Z"
                                                        fill="#0364B8"
                                                    />
                                                    <path
                                                        d="M13.7496 7.22915C12.3291 6.35539 10.6909 5.89378 9.02016 5.8965L8.90539 5.89985C7.2704 5.92017 5.67188 6.38272 4.28159 7.23779C2.89129 8.09287 1.76177 9.30815 1.01437 10.7531C0.266969 12.198 -0.0700518 13.8179 0.0395157 15.4389C0.149083 17.0598 0.701098 18.6204 1.63625 19.953L8.3 17.1659L11.2639 15.9271L17.8613 13.1679L21.3068 11.728L13.7496 7.22915Z"
                                                        fill="#0078D4"
                                                    />
                                                    <path
                                                        d="M29.2061 9.26789C29.0417 9.25646 28.877 9.2505 28.7121 9.25C27.7152 9.24919 26.7286 9.45162 25.8135 9.84478L21.308 11.7275L22.6144 12.5045L26.896 15.0524L28.7639 16.1637L35.152 19.9649C35.7324 18.8944 36.0332 17.6963 36.0268 16.4804C36.0204 15.2645 35.7071 14.0696 35.1154 13.0052C34.5238 11.9408 33.6728 11.0409 32.6404 10.388C31.608 9.73503 30.4272 9.34991 29.2061 9.26789V9.26789Z"
                                                        fill="#1490DF"
                                                    />
                                                    <path
                                                        d="M28.7639 16.1628L26.896 15.0515L22.6144 12.5025L21.3091 11.7266L17.8624 13.1665L11.2651 15.9258L8.30004 17.1645L1.63403 19.9517C2.46258 21.1351 3.56715 22.1017 4.85336 22.7689C6.13958 23.4361 7.56923 23.784 9.0202 23.783H28.7121C30.0326 23.7835 31.3286 23.4287 32.4624 22.7563C33.5963 22.0839 34.5257 21.1189 35.152 19.964L28.7639 16.1628Z"
                                                        fill="#28A8EA"
                                                    />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1066_94902">
                                                        <rect
                                                            width="36"
                                                            height="24"
                                                            fill="white"
                                                        />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <svg
                                                className="ms-3"
                                                width="26"
                                                height="24"
                                                viewBox="0 0 26 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <g clip-path="url(#clip0_1066_94907)">
                                                    <path
                                                        d="M1.96654 20.5674L3.11311 22.6132C3.35136 23.0439 3.69385 23.3823 4.09589 23.6284L8.19081 16.3066H0.000976562C0.000976562 16.7835 0.120101 17.2603 0.358351 17.691L1.96654 20.5674Z"
                                                        fill="#0066DA"
                                                    />
                                                    <path
                                                        d="M13.0005 7.69283L8.90557 0.371094C8.50352 0.617203 8.16103 0.955602 7.92278 1.38629L0.358351 14.9223C0.124483 15.3437 0.00128794 15.8209 0.000976562 16.3066H8.19082L13.0005 7.69283Z"
                                                        fill="#00AC47"
                                                    />
                                                    <path
                                                        d="M21.9051 23.6284C22.3071 23.3823 22.6496 23.0439 22.8879 22.6132L23.3644 21.7672L25.6426 17.691C25.8809 17.2603 26 16.7835 26 16.3066H17.8096L19.5524 19.8445L21.9051 23.6284Z"
                                                        fill="#EA4335"
                                                    />
                                                    <path
                                                        d="M13.0004 7.69286L17.0954 0.371117C16.6933 0.125008 16.2317 0.00195312 15.7552 0.00195312H10.2457C9.76917 0.00195312 9.30756 0.140389 8.90552 0.371117L13.0004 7.69286Z"
                                                        fill="#00832D"
                                                    />
                                                    <path
                                                        d="M17.8102 16.3066H8.19087L4.09595 23.6284C4.49799 23.8745 4.9596 23.9975 5.4361 23.9975H20.565C21.0415 23.9975 21.5031 23.8591 21.9051 23.6284L17.8102 16.3066Z"
                                                        fill="#2684FC"
                                                    />
                                                    <path
                                                        d="M21.8604 8.15428L18.0782 1.38629C17.8399 0.955602 17.4974 0.617203 17.0954 0.371094L13.0005 7.69283L17.8102 16.3066H25.9851C25.9851 15.8298 25.866 15.353 25.6277 14.9223L21.8604 8.15428Z"
                                                        fill="#FFBA00"
                                                    />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_1066_94907">
                                                        <rect
                                                            width="26"
                                                            height="24"
                                                            fill="white"
                                                        />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </span>
                                    </p>
                                    <div className="row m-0">
                                        <div className="col-md-12 col-sm-12 col-12 ps-0 p-mob-0">
                                            <div className="input-floating-label">
                                                <input
                                                    refs="source"
                                                    type="text"
                                                    className={
                                                        "textbox--primary textbox--rounded input"
                                                    }
                                                    name="source"
                                                    placeholder=" "
                                                />
                                                <label>https://...</label>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="profile__form__sub-title text-center">
                                        OR
                                    </p>
                                    <div className="dragdropcnt">
                                        <Dropzone
                                            onDrop={this.handleDrop}
                                            accept="image/jpeg"
                                            maxSize={1}
                                        >
                                            {({
                                                getRootProps,
                                                getInputProps,
                                                isDragActive,
                                                isDragReject,
                                            }) => (
                                                <div
                                                    {...getRootProps({
                                                        className: "dropzone",
                                                    })}
                                                >
                                                    <input
                                                        {...getInputProps()}
                                                    />
                                                    <div className="dropzoneinside">
                                                        <div className="dropzoneinsidetop">
                                                            <span>
                                                                <svg
                                                                    width="22"
                                                                    height="21"
                                                                    viewBox="0 0 22 21"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        clipRule="evenodd"
                                                                        d="M16.6602 20H5.26016C3.68615 20 2.41016 18.724 2.41016 17.15V3.85C2.41016 2.27599 3.68614 1 5.26016 1H14.7602L14.7703 1.01017C15.438 1.06674 16.0674 1.35729 16.5449 1.83475L18.6754 3.96526C19.1529 4.44271 19.4434 5.07213 19.5 5.73983L19.5102 5.75V17.15C19.5102 18.724 18.2342 20 16.6602 20ZM5.26016 18.1C4.73549 18.1 4.31016 17.6747 4.31016 17.15V3.85C4.31016 3.32533 4.73549 2.9 5.26016 2.9H13.8102V4.8C13.8102 5.84934 14.6608 6.7 15.7102 6.7H17.6102V17.15C17.6102 17.6747 17.1848 18.1 16.6602 18.1H5.26016Z"
                                                                        fill="#1B1C1E"
                                                                    />
                                                                </svg>
                                                            </span>
                                                        </div>
                                                        <div className="dropzoneinsidemid">
                                                            {!isDragActive &&
                                                                "Drag & Drop Or browse " }
                                                            {isDragActive &&
                                                                !isDragReject &&
                                                                "Drop it like it's hot!"}
                                                            {isDragReject &&
                                                                "File type not accepted, sorry!"}
                                                            {isDragReject &&
                                                                "File type not accepted, sorry!"}
                                                        </div>
                                                        {/* <div className="dropzoneinsidebtm">
                                                            <span></span>
                                                            <p>browse</p>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            )}
                                        </Dropzone>
                                        <p className="dropzonebtm">
                                            Accepted file type: .doc and .pdf
                                            only
                                        </p>
                                    </div>
                                    <p className="dropzonebtm">
                                        Uploaded files{" "}
                                        <svg
                                            width="18"
                                            height="18"
                                            viewBox="0 0 18 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9ZM8.25 6C8.25 5.58579 8.58579 5.25 9 5.25C9.41421 5.25 9.75 5.58579 9.75 6C9.75 6.41421 9.41421 6.75 9 6.75C8.58579 6.75 8.25 6.41421 8.25 6ZM9 8.25C9.41421 8.25 9.75 8.58579 9.75 9V12C9.75 12.4142 9.41421 12.75 9 12.75C8.58579 12.75 8.25 12.4142 8.25 12V9C8.25 8.58579 8.58579 8.25 9 8.25Z"
                                                fill="#919293"
                                            />
                                        </svg>
                                    </p>
                                    <button class="add__cta-button d-inline-block w-100"><span>Save Document</span></button>
                                </Form>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = ({ commonData }) => {
    const { isUserOnboarded } = commonData;
    return { isUserOnboarded };
};

export default connect(mapStateToProps, {  })(withRouter(Index));
