import React, { useState, useEffect } from "react";
import Tooltip from '@mui/material/Tooltip'
import { CopyInviteLink,CopyInviteLinkstatus,clearMessage } from "../../../../../src/redux/actions";
import { connect } from "react-redux";
import Alert from 'react-bootstrap/Alert';

const Index = (props) => {
    const [link, setLink] = useState('')
    const [alertVisible, setalertVisible] = useState(false)
    const [ShowAlert,setShowAlert] = useState(false)
    useEffect(() => {
        let values = {
            "role": null
        }
        props.CopyInviteLink(values)
    }, [])

    useEffect(() => {
        setLink(props.copyInvitelink ? props.copyInvitelink : '')
    }, [props.copyInvitelink])

    useEffect(() => {
        if (props.copyInvitelink !== link) {
            let values = {
                "role": null
            }
            props.CopyInviteLink(values)
        }
    }, [link])

    function myFunction() {
        setShowAlert(true)
        var textfield = document.createElement("input");
        textfield.setAttribute('id', 'myInput')
        textfield.setAttribute('value', link)
        document.body.appendChild(textfield)
        var copyText = document.getElementById("myInput");
        copyText.focus();
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand('copy');
        textfield.remove()
        setalertVisible(true)
        setTimeout(() => {
            setShowAlert(false);
            setalertVisible(false)
        }, 3000);
    }

    const copyToClipboard =  async () => {
        myFunction()
        setLink('')
    };
    return (
        <>
        {alertVisible && 
        <div style={{ position: 'fixed', top: '10px', zIndex: '99999',left: '50%', transform: 'translateX(-50%)' }} >
            <Alert style={{ position: 'sticky', marginTop: '10px', }} 
            variant="success" onClose={() => {setShowAlert(false);setalertVisible(false)}} dismissible>Copied to clipboard</Alert>
        </div>
        }
            <div className="Sent_Invite_Card">
                <p className="Sent_Invite_Card_Title">Sent Invite link</p>
                <div className="Sent_Invite_Card_Content">
                    <p data-tip data-for="copy" className="Sent_Invite_Text" >
                        <span className="Sent_Invite_First_Icon" onClick={(e) => copyToClipboard(link)}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.544 10.4565C12.7249 9.63768 11.6141 9.17773 10.456 9.17773C9.29784 9.17773 8.1871 9.63768 7.36799 10.4565L4.27899 13.5444C3.45987 14.3636 2.99969 15.4745 2.99969 16.6329C2.99969 17.7914 3.45987 18.9023 4.27899 19.7215C5.09811 20.5406 6.20908 21.0007 7.36749 21.0007C8.52591 21.0007 9.63687 20.5406 10.456 19.7215L12 18.1775" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M10.456 13.5443C11.2751 14.3631 12.3858 14.823 13.544 14.823C14.7021 14.823 15.8129 14.3631 16.632 13.5443L19.721 10.4563C20.5401 9.63718 21.0003 8.52621 21.0003 7.3678C21.0003 6.20939 20.5401 5.09842 19.721 4.2793C18.9019 3.46018 17.7909 3 16.6325 3C15.4741 3 14.3631 3.46018 13.544 4.2793L12 5.8233" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </span>
                        <span onClick={(e) => copyToClipboard(link)}>
                        Click to Copy invite link for friend
                        </span>
                        <span className="Sent_Invite_Second_Icon">
                            <Tooltip title="Invite friends to leapbrains" placement="top">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.99996 7.16699C8.2761 7.16699 8.49996 7.39085 8.49996 7.66699V11.0003C8.49996 11.2765 8.2761 11.5003 7.99996 11.5003C7.72382 11.5003 7.49996 11.2765 7.49996 11.0003V7.66699C7.49996 7.39085 7.72382 7.16699 7.99996 7.16699Z" fill="#919293" />
                                    <path d="M7.99996 6.00033C8.36815 6.00033 8.66663 5.70185 8.66663 5.33366C8.66663 4.96547 8.36815 4.66699 7.99996 4.66699C7.63177 4.66699 7.33329 4.96547 7.33329 5.33366C7.33329 5.70185 7.63177 6.00033 7.99996 6.00033Z" fill="#919293" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.16663 8.00033C2.16663 4.77866 4.7783 2.16699 7.99996 2.16699C11.2216 2.16699 13.8333 4.77866 13.8333 8.00033C13.8333 11.222 11.2216 13.8337 7.99996 13.8337C4.7783 13.8337 2.16663 11.222 2.16663 8.00033ZM7.99996 3.16699C5.33058 3.16699 3.16663 5.33095 3.16663 8.00033C3.16663 10.6697 5.33058 12.8337 7.99996 12.8337C10.6693 12.8337 12.8333 10.6697 12.8333 8.00033C12.8333 5.33095 10.6693 3.16699 7.99996 3.16699Z" fill="#919293" />
                                </svg>
                            </Tooltip>
                        </span>
                    </p>
                </div>
            </div>
        </>
    );
}
const mapStateToProps = ({ userProfileData, commonData }) => {
    const { } = commonData
    const { copyInvitelinkSucess, copyInvitelink } = userProfileData;
    return { copyInvitelinkSucess, copyInvitelink }
};

export default connect(mapStateToProps, { CopyInviteLink,CopyInviteLinkstatus,clearMessage })(Index);