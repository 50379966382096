import React, { useEffect, useState } from 'react'
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Checkbox, Chip, FormControlLabel, FormGroup, Grid, TextField, Tooltip } from '@mui/material';
import Select, { components } from "react-select";
import { Button } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import { ACCOMPLISHMENT_LABEL, ACT_SCORE_LABEL, MY_STATUS_LABEL, SAT_SCORE_LABEL, UnWEIGHTED_GPA_LABEL, WEIGHTED_GPA_LABEL } from '../../../../../Constants/commonLabels';
import { connect } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { getUniversities, updateCurrentStatus, setIsUpdateCurrentStatusError } from "../../../../../redux/actions";
import { schoolApi, universityApi } from '../../../../../utils/AutoComplete';
import Loader from '../../../../../components/Loader';
import CancelIcon from '@mui/icons-material/Cancel';

const targetMajors = [
    {label: "Major 1", value: "Major 1"},
    {label: "Major 2", value: "Major 2"},
    {label: "Major 3", value: "Major 3"},
    {label: "Major 4", value: "Major 4"},
]
const FreeConsultation = [
    { id: 0, value: 1 },
    { id: 1, value: 2 },
    { id: 2, value: 3 },
];

const validation = Yup.object({
    status: Yup.object().required('*Required').nullable(),
    weightedGPA: Yup.number().positive().transform(value => (isNaN(value) ? undefined : value)).test("is-decimal", "Invalid Gpa", (val) => { 
        if (val != undefined) {
          return /^\d+(\.\d{1,2})?$/.test(val);
        }
        return true;
        }
    ).min(1, "Invalid weighted GPA").max(5, "Invalid weighted GPA"),
    unWeightedGpa: Yup.number().positive().transform(value => (isNaN(value) ? undefined : value)).test("is-decimal", "Invalid Gpa", (val) => { 
        if (val != undefined) {
          return /^\d+(\.\d{1,2})?$/.test(val);
        }
        return true;
        }
    ).min(1, "Invalid unWeighted GPA").max(4, "Invalid unWeighted GPA"),
    satScore: Yup.number().positive().transform(value => (isNaN(value) ? undefined : value)).min(1, "SatScore should be in the range between 1 to 1600").max(1600, "SatScore should be in the range between 1 to 1600").nullable(),
    actScore: Yup.number().positive().transform(value => (isNaN(value) ? undefined : value)).min(1, "ActScore should be in the range between 1 to 36").max(36, "ActScore should be in the range between 1 to 36").nullable(),
    otherAccomplishment: Yup.string().transform(value => value?.replace(/<[^>]+>/g, '')?.replace(/\&nbsp;+/g, ' '))?.max(1500, "Maximum of 1500 characters allowed")?.nullable()
})

const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder} </Placeholder>
            {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
        </ValueContainer>
    );
};
const MultiValueRemove = (props) => {
    return (
        <components.MultiValueRemove {...props} >
            <CancelIcon style={{ "fill": "rgba(34, 34, 34, 0.67)", "width": "23px", "height": "49px" }} />
        </components.MultiValueRemove>
    );
};
const Index = (props) => {
    const [loading, setLoading] = useState(false)
    const [isload, setIsload] = useState(false)
    const [selectSchoolOption, setselectSchoolOption] = useState('');
    const [highSchoolData, setHighSchoolData] = useState(null)
    const [myStatusOpts, setMyStatusOpts] = useState([])
    const [professionOptions, setProfessionOptions] = useState([]);
    const [universityData, setUniversityData] = useState([])
    const [targetUniversities, setTargetUniversities] = useState([]);
    const [fieldErrors, setFieldErrors] = useState({})
    const [helpsWith, setHelpsWith] = useState({
        specificCourseAndActivities: false,
        helpWithPlan: false
    })
    const [noOfFreeConsultations, setNoOfFreeConsultations] = useState(1)


    const { register, handleSubmit, watch, control, setValue, formState: { errors } } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(
            validation
        ),
    });
    let otherAccomplishment = watch('otherAccomplishment')

    useEffect(() => {
        setLoading(true)
    }, [])

    useEffect(() => {
        if (fieldErrors.otherAccomplishment) {
            fieldErrors['otherAccomplishment'] = ""
        }
        setFieldErrors({ ...fieldErrors })
    }, [otherAccomplishment])

    useEffect(() => {
        if (props.userProfile !== undefined && props.status !== undefined) {
            setInitialValues()
        }
        if (props.isSetCurrentStatusError === true) {
            setLoading(false)
            props.setIsUpdateCurrentStatusError(false)
        }
    }, [props.userProfile, props.status, props.isProfileSuccess, props.isSetCurrentStatusError]);

    const loadUniversityOpt = (value, callback) => {
        if (value === "") {
            setUniversityData([]);
        }
        
        setIsload(true);
        let list = []
        if ( value ) {
            universityApi(value).then((res) => {
                res?.data?.data?.map((item, index) => {
                    list.push({
                        label: item?.university,
                        value: item?.university
                    });
                });
                setIsload(false);
            })
        }
        setUniversityData( list );
    }
    const loadOptions = (value, callback) => {
        if (value === "") {
            setHighSchoolData([]);
        }
        setIsload((isload) => !isload)
        let list = []
        if (value) {
            schoolApi(value).then((res) => {
                res?.data?.data?.map((item, index) => {
                    list.push({
                        label: item.school,
                        value: item.school
                    });
                });
                setIsload((isload) => !isload)
            })
        }
        setHighSchoolData(list);
    }

    const onTagsChange = (event, values) => {
        setFieldErrors({})
        setselectSchoolOption(values)
    }

    const setInitialValues = () => {
        let statusOptions = [];
        if (props?.userProfile !== undefined && props?.status !== undefined && props?.userProfile?.advisorProfile?.highschoolName !== undefined) {
            let userProfileData = props?.userProfile?.advisorProfile;
            let excludedStatus = ['High School Freshman', 'High School Sophomore']

            props?.status?.length > 0 && props?.status?.map((x, key) => {
                if (x.role === "highschool" && !excludedStatus?.includes(x.status)) {
                    let temp = { label: x.status, value: x.status }
                    statusOptions.push(temp);
                }
            });

            let tempProfessionOptions = []
            props.professions !== undefined && props.professions.length > 0 && props.professions.map((profession, key) => {
                let temp = { label: profession.profession, value: profession.profession }
                tempProfessionOptions.push(temp);
            });
            setProfessionOptions(tempProfessionOptions)

            let selectedStatusOption = props?.status?.map((statusItem) => { return { label: statusItem.status, value: statusItem.status } }
            ).find((x) => x.value === userProfileData.highschoolStatus)
            console.log(userProfileData, "userProfileData");
            setselectSchoolOption(userProfileData?.highschoolName)
            setValue("satScore", userProfileData?.satScore || "")
            setValue("actScore", userProfileData?.actScore || "")
            setValue("otherAccomplishment", userProfileData?.accomplishments || "")
            setValue("highSchoolGPA", userProfileData?.highSchoolGpa || "")

            if (userProfileData?.currentlyIn === "highschool") {
                setValue("status", selectedStatusOption || "")
                setValue("weightedGPA", userProfileData?.weighted)
                setValue("unWeightedGpa", userProfileData?.unWeighted)
                if(userProfileData?.targetMajor){
                    setValue("targetMajors", userProfileData?.targetMajor?.split(',')?.map(data => {return {label: data, value: data}}))
                }
                setTargetUniversities(userProfileData?.targetUniversities?.split(',')?.filter(data => data))
            }
            if (userProfileData?.currentlyIn !== "professional") {
                let selectedProfessions = userProfileData?.aspiringToBecome?.split(',')?.map(profession => (profession !== "" ? {label: profession, value: profession} : null))?.filter(data => data)
                setValue("workingAs", selectedProfessions)
            }
            setMyStatusOpts(statusOptions)
            let data = {
                specificCourseAndActivities: userProfileData?.helpWithSpecificCourseActivity,
                helpWithPlan: userProfileData?.helpWithPlan
            } 
            setHelpsWith(data)
            setNoOfFreeConsultations(userProfileData?.noOfFreeConsultation)
            setLoading(false)
        }
    }
    const handleValidation = () => {
        let isValid = true;
        let error = {};
        if (!selectSchoolOption) {
            error['highSchoolName'] = "*Required"
            isValid = false
        }
        if (otherAccomplishment?.replace(/<[^>]+>/g, '').replace(/\&nbsp;+/g, ' ')?.length > 1500) {
            error['otherAccomplishment'] = "Message only allow 1500 Characters"
            isValid = false
        } else {
            error['otherAccomplishment'] = ""
        }

        if(helpsWith?.helpWithPlan || helpsWith?.specificCourseAndActivities){
            isValid = true
        } else {
            isValid = false
            setHelpsWith({...helpsWith, helpsWithError: true})
        }
        setFieldErrors(error)
        return isValid
    }
    const onSubmit = data => {
        console.log(data, "data");
        let payload = {
            currentlyIn: "highschool",
            highSchoolName: selectSchoolOption,
            highSchoolStatus: data?.status?.label,
            workingAs: data?.workingAs?.map(profession => profession?.value)?.join(','),
            highSchoolGPA: data?.highSchoolGPA,
            weighted: data?.weightedGPA,
            unweighted: data?.unWeightedGpa,
            targetUniversities: targetUniversities,
            targetMajors: data?.targetMajors?.map(majors => majors?.value),
            aspiringToBecome: data?.workingAs?.map(val => val?.value),
            satScore: data?.satScore,
            actScore: data?.actScore,
            accomplishments: data?.otherAccomplishment,
            universityName: "",
            universityGraduationYear: "",
            universityGPA: "",
            studentsEmailList: [],
            specificCourseAndActivities: helpsWith?.specificCourseAndActivities,
            helpWithPlan: helpsWith?.helpWithPlan,
            noOfFreeConsultations: noOfFreeConsultations
        }
        console.log(payload, "payload");

        if (handleValidation()) {
            setLoading((loading) => !loading)
            props?.updateCurrentStatus(payload)
        }
    }
    const onTagsUnivChange = (event, values) => {
        setTargetUniversities(values)
    }
    const handleFreeConsultationChange = (e) => {
        setNoOfFreeConsultations(FreeConsultation[e.target.name].value)
    }
    const handleCheckBoxChange = (e, field) => {
        helpsWith[field] = e.target.checked
        if (helpsWith.helpsWithError) {
            delete helpsWith.helpsWithError
        }
        setHelpsWith({...helpsWith})

    }

    return (
        <form onSubmit={handleSubmit(onSubmit, handleValidation)} >
            {loading && <Loader />}
            <Grid container spacing={4} mt={4} columnSpacing={2} className='currentStatusGroup'>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} >
                    <Autocomplete
                        className="goals__form__select" id="tags-filled" name="highschool"
                        type='text' freeSolo clearOnBlur={false} clearIcon={false} 
                        options={highSchoolData?.length > 0 ? highSchoolData.map((option) => option.label) : []}
                        filterOptions={(options) => options}
                        value={selectSchoolOption?.label || selectSchoolOption}
                        isOptionEqualToValue={(option, value) => option === value}
                        getOptionLabel={(item) => (item ? `${item} ` : "")}
                        onChange={onTagsChange}
                        onInputChange={async (event, value) => { if (event) { loadOptions(value) } }}
                        renderInput={(params) => (
                            <div className="input-floating-label" ref={params.InputProps.ref} >
                                <input type="text" {...params.inputProps} id='highschool' className={"textbox--primary textbox--rounded input"} name="highSchool" placeholder=" " />
                                <label>{"High School Name"}</label>
                                <div className="error-text">{fieldErrors?.highSchoolName}</div>
                            </div>
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <div className="input-floating-label">
                        <Controller
                            name="status"
                            control={control}
                            render={({ field }) =>
                                <Select className="goals__form__select mb-0" classNamePrefix="mySelect" name="status" {...field} id='status'
                                    closeMenuOnSelect={true} isClearable={false} options={myStatusOpts}
                                    components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                    placeholder={MY_STATUS_LABEL}
                                    styles={{
                                        container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }),
                                        valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', }),
                                        placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10 })
                                    }}
                                />
                            }
                        />
                        <div className="error-text" style={{ "position": "absolute" }} >{errors?.status?.message}</div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className='role__btns asyncmultiselect'>
                    <Controller
                        name="workingAs"
                        control={control}
                        render={({ field }) =>
                            <CreatableSelect {...field} isMulti className="goals__form__select " noOptionsMessage={() => null} classNamePrefix="mySelect" options={professionOptions} isClearable
                                closeMenuOnSelect={true} components={{ ValueContainer: CustomValueContainer, DropdownIndicator: () => null, IndicatorSeparator: () => null, MultiValueRemove, ClearIndicator: () => null }}
                                placeholder="Aspiring to become" inputId='workingAs'
                                styles={{
                                    option: (provided, state) => ({ ...provided, cursor: 'pointer', }),
                                    container: (provided, state) => ({ ...provided, minHeight: '48px', overflow: "visible", maxWidth: '100%' }),
                                    valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', paddingTop: '10px'}),
                                    placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10 })
                                }}
                            />
                        }
                    />
                    <p className="cta--text" style={{ "fontSize": "12px", "marginLeft": "20px" }}>Optional</p>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className='asyncmultiselect multinew'>
                    <div className="asyncmultiselect multinew">
                        <Autocomplete multiple className="goals__form__select" id="targetUniversities" 
                            name="universityDropdown" type='text' clearOnBlur={false} filterSelectedOptions 
                            clearIcon ChipProps forcePopupIcon={false} freeSolo={true} openOnFocus={true}
                            options={universityData?.map(data => data?.label)} value={targetUniversities}
                            renderTags={(value, getTagProps) => value?.map((option, index) => (<Chip label={option} {...getTagProps({ index })} />))}
                            filterOptions={(options, state) => options} 
                            onChange={onTagsUnivChange} onInputChange={async (event, value) => { loadUniversityOpt(value) }}
                            renderInput={(params) => (<TextField {...params} label="Target Universities" size="small" autocomplete="off"
                            inputProps={{ ...params.inputProps, onKeyDown: (e) => { if (e.key === 'Enter') { e.preventDefault(); } }, }} sx={{ '& .MuiOutlinedInput-root': { '&:hover fieldset': { borderColor: 'lightgrey', }, } }} />)}
                        />
                    </div>
                    <p className="cta--text" style={{ "fontSize": "12px", "marginLeft": "20px" }}>Optional</p>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className='role__btns asyncmultiselect'>
                    <Controller
                        name="targetMajors"
                        control={control}
                        render={({ field }) =>
                            <CreatableSelect {...field} className="goals__form__select" isMulti noOptionsMessage={() => null} classNamePrefix="mySelect" options={targetMajors} isClearable
                                closeMenuOnSelect={true} components={{ ValueContainer: CustomValueContainer, DropdownIndicator: () => null, MultiValueRemove, IndicatorSeparator: () => null, ClearIndicator: () => null }}
                                placeholder="Target Majors" inputId="targetMajors"
                                styles={{
                                    option: (provided, state) => ({ ...provided, cursor: 'pointer', }),
                                    container: (provided, state) => ({ ...provided, overflow: "visible" }), 
                                    valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px',  paddingTop: (state.hasValue || state.selectProps.inputValue) && '10px'}),
                                    placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10 })
                                }} 
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                    <div className="input-floating-label">
                        <input refs="weightedGPA" type="text" {...register("weightedGPA")} id='weightedGPA'
                            className="textbox--primary textbox--rounded input" name="weightedGPA" placeholder=" "
                            onKeyPress={(event) => { if (!/[0-9.]/.test(event.key)) { event.preventDefault(); } }} />
                        <label>{WEIGHTED_GPA_LABEL}</label> <p className="cta--text" style={{ "fontSize": "12px", "marginLeft": "20px" }}>Optional</p>
                        <div className="error-text" style={{ "position": "absolute" }} >{errors?.weightedGPA?.message}</div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                    <div className="input-floating-label">
                        <input refs="unWeightedGpa" type="text" {...register("unWeightedGpa")} id='unWeightedGpa'
                            className="textbox--primary textbox--rounded input" name="unWeightedGpa" placeholder=" "
                            onKeyPress={(event) => { if (!/[0-9.]/.test(event.key)) { event.preventDefault(); } }} />
                        <label>{UnWEIGHTED_GPA_LABEL}</label> <p className="cta--text" style={{ "fontSize": "12px", "marginLeft": "20px" }}>Optional</p>
                        <div className="error-text" style={{ "position": "absolute" }} >{errors?.unWeightedGpa?.message}</div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
                    <div className="input-floating-label">
                        <input refs="satScore" type="text" {...register("satScore")} id='satScore'
                            className="textbox--primary textbox--rounded input" name="satScore" placeholder=" "
                            onKeyPress={(event) => { if (!/[0-9-]/.test(event.key)) { event.preventDefault(); } }} />
                        <label>{SAT_SCORE_LABEL}</label> <p className="cta--text" style={{ "fontSize": "12px", "marginLeft": "20px", marginBottom: 0 }}>Optional</p>
                        <div className="error-text" style={{ "position": "absolute" }} >{errors?.satScore?.message}</div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
                    <div className="input-floating-label">
                        <input refs="actScore" type="text" {...register("actScore")} id='actScore'
                            className="textbox--primary textbox--rounded input" name="actScore" placeholder=" "
                            onKeyPress={(event) => { if (!/[0-9-]/.test(event.key)) { event.preventDefault(); } }} />
                        <label>{ACT_SCORE_LABEL}</label> <p className="cta--text" style={{ "fontSize": "12px", "marginLeft": "20px", marginBottom: 0 }}>Optional</p>
                        <div className="error-text" style={{ "position": "absolute" }} >{errors?.actScore?.message}</div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <p className="curentStatusSec__titleSec__formSubTitle">
                        I can help with...
                    </p>
                    <div className="mb-4">
                        <FormGroup className= "helpsWith-Sec">
                            <FormControlLabel
                                className="helpsWith"
                                style={{ fontSize: '2rem' }}
                                control={
                                    <Checkbox
                                        id="helpsWith"
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 26, borderRadius: 10 } }}
                                        checked={helpsWith["helpWithPlan"]}
                                        value={helpsWith["helpWithPlan"]}
                                        onChange={(e) => handleCheckBoxChange(e, "helpWithPlan") }
                                    /> 
                                }
                                label={<>
                                        Creating a plan with courses and Activities based on student goals
                                        <span style={{ "marginLeft": "5px" }} data-tip data-for="registerTip">
                                            <Tooltip title="After first free consultation session with student/parent, you will create/update initial plan with suggested courses/activities for high school year(s) . You will also suggest changes to this plan based on progress towards goals" >
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"> 
                                                    <path d="M8.00008 7.16699C8.27622 7.16699 8.50008 7.39085 8.50008 7.66699V11.0003C8.50008 11.2765 8.27622 11.5003 8.00008 11.5003C7.72394 11.5003 7.50008 11.2765 7.50008 11.0003V7.66699C7.50008 7.39085 7.72394 7.16699 8.00008 7.16699Z" fill="#919293" /> 
                                                    <path d="M8.00008 6.00033C8.36827 6.00033 8.66675 5.70185 8.66675 5.33366C8.66675 4.96547 8.36827 4.66699 8.00008 4.66699C7.63189 4.66699 7.33341 4.96547 7.33341 5.33366C7.33341 5.70185 7.63189 6.00033 8.00008 6.00033Z" fill="#919293" /> 
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M2.16675 8.00033C2.16675 4.77866 4.77842 2.16699 8.00008 2.16699C11.2217 2.16699 13.8334 4.77866 13.8334 8.00033C13.8334 11.222 11.2217 13.8337 8.00008 13.8337C4.77842 13.8337 2.16675 11.222 2.16675 8.00033ZM8.00008 3.16699C5.3307 3.16699 3.16675 5.33095 3.16675 8.00033C3.16675 10.6697 5.3307 12.8337 8.00008 12.8337C10.6695 12.8337 12.8334 10.6697 12.8334 8.00033C12.8334 5.33095 10.6695 3.16699 8.00008 3.16699Z" fill="#919293" />
                                                </svg>
                                            </Tooltip>
                                        </span>
                                </>} 
                            />
                            <FormControlLabel
                                className="helpsWith"
                                style={{ fontSize: '2rem' }}
                                control={
                                    <Checkbox
                                        id="specificCourseAndActivities"
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 26, borderRadius: 10 } }}
                                        checked={helpsWith["specificCourseAndActivities"]}
                                        value={helpsWith["specificCourseAndActivities"]}
                                        onChange={(e) => handleCheckBoxChange(e, "specificCourseAndActivities") }
                                    />
                                }
                                label={
                                    <>
                                        Mentorship and support for specific courses and activities
                                        <span style={{ "marginLeft": "5px" }} data-tip data-for="registerTip">
                                            <Tooltip title="You will help advice student for assignments, homework, test prep etc through out school year(s)" >
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"> 
                                                    <path d="M8.00008 7.16699C8.27622 7.16699 8.50008 7.39085 8.50008 7.66699V11.0003C8.50008 11.2765 8.27622 11.5003 8.00008 11.5003C7.72394 11.5003 7.50008 11.2765 7.50008 11.0003V7.66699C7.50008 7.39085 7.72394 7.16699 8.00008 7.16699Z" fill="#919293" /> 
                                                    <path d="M8.00008 6.00033C8.36827 6.00033 8.66675 5.70185 8.66675 5.33366C8.66675 4.96547 8.36827 4.66699 8.00008 4.66699C7.63189 4.66699 7.33341 4.96547 7.33341 5.33366C7.33341 5.70185 7.63189 6.00033 8.00008 6.00033Z" fill="#919293" /> 
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M2.16675 8.00033C2.16675 4.77866 4.77842 2.16699 8.00008 2.16699C11.2217 2.16699 13.8334 4.77866 13.8334 8.00033C13.8334 11.222 11.2217 13.8337 8.00008 13.8337C4.77842 13.8337 2.16675 11.222 2.16675 8.00033ZM8.00008 3.16699C5.3307 3.16699 3.16675 5.33095 3.16675 8.00033C3.16675 10.6697 5.3307 12.8337 8.00008 12.8337C10.6695 12.8337 12.8334 10.6697 12.8334 8.00033C12.8334 5.33095 10.6695 3.16699 8.00008 3.16699Z" fill="#919293" />
                                                </svg>
                                            </Tooltip>
                                        </span>
                                    </>
                                }
                            />
                        </FormGroup>
                        { helpsWith?.helpsWithError && <div className="error-text" style={{ "position": "absolute" }} >*Required</div> }
                    </div>
                    <p className="curentStatusSec__titleSec__formSubTitle">
                        Number of initial free consultation sessions
                        <Tooltip title="At least one free session is required" enterTouchDelay={0}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"> 
                                <path d="M8.00008 7.16699C8.27622 7.16699 8.50008 7.39085 8.50008 7.66699V11.0003C8.50008 11.2765 8.27622 11.5003 8.00008 11.5003C7.72394 11.5003 7.50008 11.2765 7.50008 11.0003V7.66699C7.50008 7.39085 7.72394 7.16699 8.00008 7.16699Z" fill="#919293" /> 
                                <path d="M8.00008 6.00033C8.36827 6.00033 8.66675 5.70185 8.66675 5.33366C8.66675 4.96547 8.36827 4.66699 8.00008 4.66699C7.63189 4.66699 7.33341 4.96547 7.33341 5.33366C7.33341 5.70185 7.63189 6.00033 8.00008 6.00033Z" fill="#919293" /> 
                                <path fillRule="evenodd" clipRule="evenodd" d="M2.16675 8.00033C2.16675 4.77866 4.77842 2.16699 8.00008 2.16699C11.2217 2.16699 13.8334 4.77866 13.8334 8.00033C13.8334 11.222 11.2217 13.8337 8.00008 13.8337C4.77842 13.8337 2.16675 11.222 2.16675 8.00033ZM8.00008 3.16699C5.3307 3.16699 3.16675 5.33095 3.16675 8.00033C3.16675 10.6697 5.3307 12.8337 8.00008 12.8337C10.6695 12.8337 12.8334 10.6697 12.8334 8.00033C12.8334 5.33095 10.6695 3.16699 8.00008 3.16699Z" fill="#919293" />
                            </svg>
                        </Tooltip>
                    </p>
                    <div className="profile__form__checkboxcnt mb-2">
                        <div className="styled-radio-flex">
                            {FreeConsultation?.map((types, index) => {
                                return (
                                    <div className="styled-radio-flex" key={types?.id}>
                                        <div className="styled-radio">
                                            <input id={types.id} name={types.id} type="radio" value={types.value} checked={noOfFreeConsultations == types.value} onChange= {(e) => handleFreeConsultationChange(e)}/>
                                            <label htmlFor={types.id} className="radio-label">{types.value}</label>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        {/* {elements.freeConsultationError && <span className="error-text ps-0">*Required</span>} */}
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="input-floating-label">
                        <Controller name="otherAccomplishment" control={control}
                            render={({ field }) => <ReactQuill className="textarea--primary textbox--rounded input" id='otherAccomplishment' theme="snow" {...field} />}
                        />
                        <label>{ACCOMPLISHMENT_LABEL}
                            <span style={{ "marginLeft": "5px" }} data-tip data-for="registerTip">
                                <Tooltip title="Your intro, other accomplishments etc" >
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.00008 7.16699C8.27622 7.16699 8.50008 7.39085 8.50008 7.66699V11.0003C8.50008 11.2765 8.27622 11.5003 8.00008 11.5003C7.72394 11.5003 7.50008 11.2765 7.50008 11.0003V7.66699C7.50008 7.39085 7.72394 7.16699 8.00008 7.16699Z" fill="#919293" />
                                        <path d="M8.00008 6.00033C8.36827 6.00033 8.66675 5.70185 8.66675 5.33366C8.66675 4.96547 8.36827 4.66699 8.00008 4.66699C7.63189 4.66699 7.33341 4.96547 7.33341 5.33366C7.33341 5.70185 7.63189 6.00033 8.00008 6.00033Z" fill="#919293" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M2.16675 8.00033C2.16675 4.77866 4.77842 2.16699 8.00008 2.16699C11.2217 2.16699 13.8334 4.77866 13.8334 8.00033C13.8334 11.222 11.2217 13.8337 8.00008 13.8337C4.77842 13.8337 2.16675 11.222 2.16675 8.00033ZM8.00008 3.16699C5.3307 3.16699 3.16675 5.33095 3.16675 8.00033C3.16675 10.6697 5.3307 12.8337 8.00008 12.8337C10.6695 12.8337 12.8334 10.6697 12.8334 8.00033C12.8334 5.33095 10.6695 3.16699 8.00008 3.16699Z" fill="#919293" />
                                    </svg>
                                </Tooltip>
                            </span>
                        </label>
                        <div className="d-flex justify-content-between me-1">
                            {(fieldErrors?.otherAccomplishment && <div className="error-text">{fieldErrors?.otherAccomplishment}</div> || 
                                (errors?.otherAccomplishment && <div className="error-text">{errors?.otherAccomplishment?.message}</div>)
                            ) ||
                                <p className="cta--text mb-2" style={{ "fontSize": "12px", "marginLeft": "20px" }}>Optional</p>
                            }
                            <div className="subtext"><span>{otherAccomplishment?.replace(/<[^>]+>/g, '').replace(/\&nbsp;+/g, ' ')?.length || 0}</span>/1500</div>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid container justifyContent={"flex-end"} >
                <Button id='saveBtn' className="btn cta--rounded generalForm--sec__form--sec__dltprofilefcnt__profilesavebtn" type="submit">
                    Save
                </Button>
            </Grid>
        </form>
    )
}

const mapStateToProps = ({ userProfileData, commonData }) => {
    const { status, loading, professions, universities } = commonData;
    const { userProfile, isProfileSuccess, isSetCurrentStatusError } = userProfileData;
    return { status, loading, professions, universities, userProfile, isSetCurrentStatusError, isProfileSuccess };
};
export default connect(mapStateToProps, { getUniversities, updateCurrentStatus, setIsUpdateCurrentStatusError })(Index);