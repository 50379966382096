import React from 'react'
import { useImageUrl } from '../../../../utils/UseImageURL'
import ProfileEdit from "../../../../assets/images/profileEdit.png"


const RenderProfileImg = (props) => {
    const imgUrl = useImageUrl(props?.avatarUrl);
    return (
        <img alt='profile' className='profileImg' style={{ width: "100%", height: "100%", borderRadius: "50%","objectFit":"cover" }}
            src={imgUrl || ProfileEdit} />
    )
}

export default RenderProfileImg