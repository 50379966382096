import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import CustomAlert from '../../../components/CustomAlert';
import { Rating } from '@mui/material';
import dayjs from "dayjs";
import { useImageUrl } from '../../../utils/UseImageURL';


const index = (props) => {
    console.log(props?.reviewData, "props?.reviewData")
    const AvatarWithUrl = ({ avatarUrl }) => {
        const imageUrl = useImageUrl(avatarUrl);
        return <img className="avatarUrlimage" src={imageUrl} alt="profileimage" />;
    };
    return (
        <div>
            <Modal show={props?.openReviewPopUp} onHide={props?.handleCloseReview}
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Reviews
                    </Modal.Title>

                </Modal.Header>
                <Modal.Body style={{ padding: 40 }}>
                    <div className="" style={{ "width": "100%", "display": "flex", "justifyContent": "center", }}> {<CustomAlert />}</div>
                    <div style={{ "height": "400px", overflowY: "scroll" }}>

                        {props?.reviewData?.map((data, key) => (
                            <div className="userReviewCard" >
                                <div className="userReviewCard_cnt">
                                    <div className="userReviewCard_cnt_left">
                                        {data?.userImage ? (
                                            <AvatarWithUrl avatarUrl={data?.userImage} />
                                        ) : (
                                            <img className="avatarUrlimage" src={require("../../../assets/images/profileEdit.png")} alt="profile" />

                                        )}
                                    </div>
                                    <div className="userReviewCard_cnt_right">
                                        <div className="userReviewCard_cnt_right_top">
                                            <div className="userReviewCard_cnt_right_top_section">
                                                <div className="userReviewCard_cnt_right_top_section_cnt">
                                                    <p className="user-Review__reviewDetails__userName">
                                                        {data?.userName}
                                                    </p>
                                                    <Rating
                                                        max={1}
                                                        defaultValue={1}
                                                        readOnly
                                                        className="ms-2"
                                                    />
                                                    <p className="user-Review__reviewDetails__userName">
                                                        {data?.rating}
                                                    </p>
                                                </div>
                                            </div>
                                            <div>
                                                <p className="user-Review__reviewDetails__date">
                                                    {dayjs(data?.reviewedAt).format('MMMM, DD YYYY')}

                                                </p>
                                            </div>
                                        </div>
                                        <div className="userReviewCard_cnt_right_bottom">
                                            <div className="userReviewCard_detail">
                                                <p className="user-Review__reviewDetails__review-msg">
                                                    {data?.reviewDescription}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {/* {reviews.length < totalRecords  && 
                        <div className="review-sec__morebtn-sec">
                            <button className="review-sec__morebtn-sec__moreBtn mt-3" id="loadmorebtn" onClick={() => handlePagination()} >Load more</button>
                        </div>} */}
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default index
