import { useEffect, useRef } from "react";

export const useComponentDidMount = (handler) => {
  return useEffect(() => {
    return handler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useComponentDidUpdate = (handler, deps) => {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;

      return;
    }

    return handler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export const useComponentWillUnmount = (handler) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useEffect(() => handler, []);
};

export const paramsQuery = (value) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const data = params.get(value);
  return data;
};
