import React from 'react'
import Modal from "react-bootstrap/Modal";
import './extraSessionModal.less';

const Index = (props) => {
    let {children} = props
    return (
        <Modal
            {...props} show={props?.show}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            style={{"zIndex":"2000", backgroundColor: "rgb(0,0,0, 0.5)"}}
            centered >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className="extraSessionModal">
                <p className="confirmMsg">{props?.message}</p>
                {children}
            </Modal.Body>
        </Modal>
    )
}

export default Index