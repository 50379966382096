import React, { Component } from "react";
import { connect } from "react-redux";
import { verifyEmail,Emailverify } from "../../../redux/actions";
import { withRouter } from "../../../redux/store/navigate";
import { setSession } from "../../../utils/AuthUtils";
const IDENTIFIER = process.env.REACT_APP_IDENTIFIER;
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
        };
    }

    componentDidMount() {
        const paramskey = window.location.pathname.split("/").pop()
        let values = {
            userVerificationKey: paramskey,
            identifier: IDENTIFIER
        }
        this.props.verifyEmail(values)
        localStorage.removeItem("token");
		setSession(null)
    }
    componentDidUpdate(){
        if(this.props.isEmailVerified ==false){
            this.props.navigate('/')
            this.props.Emailverify(false)
        }
        else if(this.props.isEmailVerified){
            this.props.navigate('/')
            this.props.Emailverify(true)
        }
    }
    render() {
        return (
            <>
            </>
        );
    }
}

const mapStateToProps = ({ auth, commonData }) => {
    const { error, errorList } = commonData
    const { isEmailVerified } = auth;
    return { error, errorList, isEmailVerified }
};
export default connect(mapStateToProps, { verifyEmail,Emailverify })(withRouter(Index));
