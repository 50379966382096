import React from "react";
import './workingHours.less'
import Tags from '../../../../componentsNew/Common/Tags'
import moment from "moment";

const index = (props) => {
    let {mondayTimesheet, tuesdayTimesheet, wednesdayTimesheet, thursdayTimesheet, fridayTimesheet, saturdayTimesheet, sundayTimesheet} = props?.data

    const mondayAvailableTime = mondayTimesheet?.map((time, index) => {
        return moment(time.startingTime, 'HH:mm:ss').format('h:mm A')   + " - "+ moment(time.closingTime, 'HH:mm:ss').format('h:mm A')
    })
    const tuesdayAvailableTime = tuesdayTimesheet?.map((time, index) => {
        return moment(time.startingTime, 'HH:mm:ss').format('h:mm A')   + " - "+ moment(time.closingTime, 'HH:mm:ss').format('h:mm A')
    })
    const wednesdayAvailableTime = wednesdayTimesheet?.map((time, index) => {
        return moment(time.startingTime, 'HH:mm:ss').format('h:mm A')   + " - "+ moment(time.closingTime, 'HH:mm:ss').format('h:mm A')
    })
    const thursdayAvailableTime = thursdayTimesheet?.map((time, index) => {
        return moment(time.startingTime, 'HH:mm:ss').format('h:mm A')   + " - "+ moment(time.closingTime, 'HH:mm:ss').format('h:mm A')
    })
    const fridayAvailableTime = fridayTimesheet?.map((time, index) => {
        return moment(time.startingTime, 'HH:mm:ss').format('h:mm A')   + " - "+ moment(time.closingTime, 'HH:mm:ss').format('h:mm A')
    })
    const saturdayAvailableTime = saturdayTimesheet?.map((time, index) => {
        return moment(time.startingTime, 'HH:mm:ss').format('h:mm A')   + " - "+ moment(time.closingTime, 'HH:mm:ss').format('h:mm A')
    })
    const sundayAvailableTime = sundayTimesheet?.map((time, index) => {
        return moment(time.startingTime, 'HH:mm:ss').format('h:mm A')   + " - "+ moment(time.closingTime, 'HH:mm:ss').format('h:mm A')
    })

    return (
        <div className="workingHoursCard">
            {
                ( mondayAvailableTime?.length || tuesdayAvailableTime.length || wednesdayAvailableTime.length ||
                thursdayAvailableTime.length || fridayAvailableTime?.length || saturdayAvailableTime?.length ||
                sundayAvailableTime?.length ) ?
                <>
                    <div className="workingHoursCard__title">
                        Working Hours
                    </div>
                    <div className="workingHoursCard__hoursSec">
                    {
                        mondayAvailableTime?.length > 0 &&
                        <div className="workingHoursCard__dayContent">
                            <p className="workingHoursCard__dayContent__label">Monday</p>
                            <div className="workingHoursCard__dayContent__tagsec">
                                { mondayAvailableTime?.map( time => <Tags bold text= {time}/> )}
                            </div>
                        </div>
                    }{
                        tuesdayAvailableTime.length > 0 &&
                        <div className="workingHoursCard__dayContent">
                            <p className="workingHoursCard__dayContent__label">Tuesday</p>
                            <div className="workingHoursCard__dayContent__tagsec">
                                { tuesdayAvailableTime?.map( time => <Tags bold text= {time}/> )}
                            </div>
                        </div>
                    }{
                        wednesdayAvailableTime.length > 0 &&
                        <div className="workingHoursCard__dayContent">
                            <p className="workingHoursCard__dayContent__label">Wednesday</p>
                            <div className="workingHoursCard__dayContent__tagsec">
                                { wednesdayAvailableTime?.map( time => <Tags bold text= {time}/> )}
                            </div>
                        </div>
                    }{
                        thursdayAvailableTime.length > 0 && 
                        <div className="workingHoursCard__dayContent">
                            <p className="workingHoursCard__dayContent__label">Thursday</p>
                            <div className="workingHoursCard__dayContent__tagsec">
                                { thursdayAvailableTime?.map( time => <Tags bold text= {time}/> )}
                            </div>
                        </div>
                    }{
                        fridayAvailableTime?.length > 0 && 
                        <div className="workingHoursCard__dayContent">
                            <p className="workingHoursCard__dayContent__label">Friday</p>
                            <div className="workingHoursCard__dayContent__tagsec">
                                { fridayAvailableTime?.map( time => <Tags bold text= {time}/> )}
                            </div>
                        </div>
                    }{
                        saturdayAvailableTime?.length > 0 &&
                        <div className="workingHoursCard__dayContent">
                            <p className="workingHoursCard__dayContent__label">Saturday</p>
                            <div className="workingHoursCard__dayContent__tagsec">
                                { saturdayAvailableTime?.map( time => <Tags text= {time}/> )}
                            </div>
                        </div>
                    }{
                        sundayAvailableTime?.length > 0 && 
                        <div className="workingHoursCard__dayContent">
                            <p className="workingHoursCard__dayContent__label">Sunday</p>
                            <div className="workingHoursCard__dayContent__tagsec">
                                { sundayAvailableTime?.map( time => <Tags text= {time}/> )}
                            </div>
                        </div>
                    }</div>
                </> :
                <></>
            }
        </div>
    );
};


export default index;