import React, { Component } from "react";
import  "../../assets/css/calendar.less"
class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isChatactive:true,
            isShowchatIcon:true,
            isShowCloseIcon:false,
        };
    }
    componentWillMount() {
    }
    onclickprime(e){
        // this.toggleFab()
        const currentState = this.state.isChatactive;
        const ShowchatIcon = this.state.isShowchatIcon;
        const ShowCloseIcon = this.state.isShowCloseIcon;
        const testElements =  document.getElementsByClassName("right-wrap")[0];
        
        const fabs = document.getElementsByClassName("fabs");
        this.setState({ isChatactive: !currentState,isShowchatIcon:!ShowchatIcon,isShowCloseIcon:!ShowCloseIcon });
        if(currentState){
            testElements.className += " active";
            fabs[0].className += " hidden";
        } else {
            testElements.classList.remove("active");
            fabs[0].classList.remove("hidden");
        }
    }
    

    hideChat(hide) {
        let prime = document.getElementsByclassName('prime');
        let chat_fullscreen_loader = document.getElementsByclassName('chat_fullscreen_loader');
        let chat_login = document.getElementsByclassName('chat_login');

        let chat_converse = document.getElementById("chat_converse");
        let chat_body = document.getElementById("chat_body");
        let chat_form = document.getElementById("chat_form");
        let chat_fullscreen = document.getElementById("chat_fullscreen");
        switch (hide) {
          case 0:
                chat_converse.css('display', 'none');
                chat_body.css('display', 'none');
                chat_form.css('display', 'none');
                chat_login.css('display', 'block');
                chat_fullscreen_loader.css('display', 'none');
                 chat_fullscreen.css('display', 'none');
                break;
          case 1:
                chat_converse.css('display', 'block');
                chat_body.css('display', 'none');
                chat_form.css('display', 'none');
                chat_login.css('display', 'none');
                chat_fullscreen_loader.css('display', 'block');
                break;
          case 2:
                chat_converse.css('display', 'none');
                chat_body.css('display', 'block');
                chat_form.css('display', 'none');
                chat_login.css('display', 'none');
                chat_fullscreen_loader.css('display', 'block');
                break;
          case 3:
                chat_converse.css('display', 'none');
                chat_body.css('display', 'none');
                chat_form.css('display', 'block');
                chat_login.css('display', 'none');
                chat_fullscreen_loader.css('display', 'block');
                break;
          case 4:
                chat_converse.css('display', 'none');
                chat_body.css('display', 'none');
                chat_form.css('display', 'none');
                chat_login.css('display', 'none');
                chat_fullscreen_loader.css('display', 'block');
                chat_fullscreen.css('display', 'block');
                break;
        }
    }
    render() { 
        return (
            <>      
                <div className="calendar">
                    <a id="prime"  onClick={(e) => this.onclickprime(e)} className="fabcalendar">
                        <span className={this.state.isShowchatIcon ? 'chat-icon chat-icons show': "chat-icon chat-icons "} >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.625 4.3125H16.6875V2.8125C16.6875 2.70937 16.6031 2.625 16.5 2.625H15.1875C15.0844 2.625 15 2.70937 15 2.8125V4.3125H9V2.8125C9 2.70937 8.91563 2.625 8.8125 2.625H7.5C7.39687 2.625 7.3125 2.70937 7.3125 2.8125V4.3125H3.375C2.96016 4.3125 2.625 4.64766 2.625 5.0625V20.625C2.625 21.0398 2.96016 21.375 3.375 21.375H20.625C21.0398 21.375 21.375 21.0398 21.375 20.625V5.0625C21.375 4.64766 21.0398 4.3125 20.625 4.3125ZM19.6875 19.6875H4.3125V10.7812H19.6875V19.6875ZM4.3125 9.1875V6H7.3125V7.125C7.3125 7.22813 7.39687 7.3125 7.5 7.3125H8.8125C8.91563 7.3125 9 7.22813 9 7.125V6H15V7.125C15 7.22813 15.0844 7.3125 15.1875 7.3125H16.5C16.6031 7.3125 16.6875 7.22813 16.6875 7.125V6H19.6875V9.1875H4.3125Z" fill="#fff"/>
                            </svg>

                        </span>
                        <span className={this.state.isShowCloseIcon ? 'close-icon chat-icons show': "close-icon chat-icons "} >
                            <svg width="24" height="24" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M43.2938 6.70514C43.4393 6.85028 43.5547 7.0227 43.6335 7.21253C43.7123 7.40236 43.7528 7.60586 43.7528 7.81139C43.7528 8.01691 43.7123 8.22041 43.6335 8.41024C43.5547 8.60007 43.4393 8.77249 43.2938 8.91764L8.91877 43.2926C8.62538 43.586 8.22744 43.7509 7.81252 43.7509C7.3976 43.7509 6.99967 43.586 6.70627 43.2926C6.41287 42.9992 6.24805 42.6013 6.24805 42.1864C6.24805 41.7715 6.41287 41.3735 6.70627 41.0801L41.0813 6.70514C41.2264 6.55963 41.3988 6.44418 41.5887 6.36541C41.7785 6.28664 41.982 6.24609 42.1875 6.24609C42.393 6.24609 42.5965 6.28664 42.7864 6.36541C42.9762 6.44418 43.1486 6.55963 43.2938 6.70514V6.70514Z" fill="white"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M6.70611 6.70514C6.5606 6.85028 6.44516 7.0227 6.36639 7.21253C6.28762 7.40236 6.24707 7.60586 6.24707 7.81139C6.24707 8.01691 6.28762 8.22041 6.36639 8.41024C6.44516 8.60007 6.5606 8.77249 6.70611 8.91764L41.0811 43.2926C41.3745 43.586 41.7724 43.7509 42.1874 43.7509C42.6023 43.7509 43.0002 43.586 43.2936 43.2926C43.587 42.9992 43.7518 42.6013 43.7518 42.1864C43.7518 41.7715 43.587 41.3735 43.2936 41.0801L8.91861 6.70514C8.77347 6.55963 8.60105 6.44418 8.41122 6.36541C8.22139 6.28664 8.01789 6.24609 7.81236 6.24609C7.60684 6.24609 7.40334 6.28664 7.21351 6.36541C7.02368 6.44418 6.85126 6.55963 6.70611 6.70514V6.70514Z" fill="white"/>
                            </svg>
                        </span>
                    </a>
                </div>
            </>
        )
    }
}
export default Index;