import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../../assets/css/owlCarousel.less";
import { Grid } from "@mui/material";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import Profile from "../../componentsNew/Common/advisorProfile"
import ParentProfile from "./parentProfile"
import PersonIcon from '@mui/icons-material/Person';
import { getAdvisorSuggested, getAdvisorSuggestedStatus,getDetailedUserProfile, getAdvisorRecommendedstatus, getAdvisorRecommended } from "../../redux/actions";
import { connect } from "react-redux";
import PillSection from "../../componentsNew/Common/PillSection"
import AdvisorCard from "../../componentsNew/Common/Cards/Advisor/ExploreAdvisor/index"


const Index = (props) => {
    const [profilePopup, setProfiePopup] = useState(false);
    const [profileData, setProfileData] = useState({});
    const [parentProfile, setParentProfile] = useState(false);
    const [lastViewedIndex, setLastViewedIndex] = useState(0);
    //advisor Popup Open Close
    const profileShow = (data) => {
        setProfileData(data)
        setProfiePopup(true);

    }
    const isBelow420px = window.innerWidth <= 420;
    const profileHide = () => setProfiePopup(false);

    //parent Popup Open Close
    const parentProfileShow = (data) => {
        setProfileData(data)
        setParentProfile(true);
    }

    const parentProfileHide = () => setParentProfile(false);

    const getRecommendedAdvisors = (data) => {
   
        let payload = {
            "limit": 60,
            "pageNo": 1,
            "studentId": props.userProfile?.currentRole === "parent" ? props?.childprofile && props?.childprofile[0]?.id : null
          }
          props?.getAdvisorSuggested(payload)
    }

    useEffect(() => {
        if (props.suggestedAdvisor && props.getSuggestedStatus) {
            props?.getAdvisorSuggestedStatus(false)
        }
    }, [props?.suggestedAdvisor,props?.getSuggestedStatus])

    const options = {
        nav: !isBelow420px,
        navElement: ["ChevronLeftOutlinedIcon ", "ChevronRightIcon "],
        autoplay: false,
        slideBy: 1,
        dots: false,
        dotsEach: false,
        dotData: true,
        loop: false,
        margin: 10,
        startPosition: lastViewedIndex,
        onChanged: (event) => {
            if (event.item.index) {
                setLastViewedIndex(event.item.index);
            }
        },
        responsive: {
            0: {
                items: 1,
            },
            450: {
                items: 1,
            },
            575: {
                items: 1,
            },
            768: {
                items: 2,
            },
            1000: {
                items: props.OwlCarouselData?.length > 2 ? 4 : 3,
            },
            1024: {
                items:
                    props.OwlCarouselData?.length <= 2 && 2 ||
                    props.OwlCarouselData?.length === 3 && 3 ||
                    (props.OwlCarouselData?.length > 3 && 3),
            },
            1140: {
                items:
                    props.OwlCarouselData?.length <= 2 && 2 ||
                    props.OwlCarouselData?.length === 3 && 3 ||
                    (props.OwlCarouselData?.length > 3 && 3),
            },
            1366: {
                items:
                    props.OwlCarouselData?.length <= 2 && 2 ||
                    props.OwlCarouselData?.length === 3 && 3 ||
                    (props.OwlCarouselData?.length > 3 && 3),
                // autoWidth: true,
                // margin: -10,
                rewind: false
            },
            2000: {
                items: props.OwlCarouselData?.length > 2 ? 5 : 2,
                autoWidth: true,
                margin: -10,
                rewind: false
            },
        },
    };

    let OwlCarouselList =
        props.OwlCarouselData &&
        props.OwlCarouselData.length > 0 &&
        props.OwlCarouselData.map((element, key) => {
            return (
             
                    <div className="owl__item mt-1">
                        <a>
                        <AdvisorCard data={element}>
                          <div className="netWork-card__button-sec" style={{ width:"90%","position":"absolute","bottom":"10px" }}>
                            <button className="suggestedStudentCard__buttonSec__primary-btn" onClick={(e) => profileShow(element)}>
                              View profile
                            </button>
                          </div>
                        </AdvisorCard>
                        </a>
                    </div>
             
            );
        });
    return (
        <>
            {props?.OwlCarouselData && props?.OwlCarouselData.length > 0 && (
                <OwlCarousel
                    className="owl owl-OwlCarousel owl-theme"
                    {...options} >
                    {OwlCarouselList}
                </OwlCarousel>
            )}
            {
                profilePopup &&
                <Profile show={profilePopup} handleCallApi={(data) => getRecommendedAdvisors(data)} detailedInfo={props?.detailedAdvisorData} data={profileData} handleClose={profileHide} />
            }
            <ParentProfile show={parentProfile} data={profileData} handleClose={parentProfileHide} />

        </>
    );
}
const mapStateToProps = ({ studentData, commonData, userProfileData }) => {
    const { detailedAdvisorData,suggestedAdvisor,getSuggestedStatus } = commonData;
    const { getstudentplan, } = studentData;
    const { userProfile , childprofile} = userProfileData;
    return { detailedAdvisorData, getstudentplan,suggestedAdvisor,getSuggestedStatus, userProfile, childprofile };
};
export default connect(mapStateToProps, { getAdvisorSuggested, getAdvisorSuggestedStatus,getDetailedUserProfile, getAdvisorRecommendedstatus, getAdvisorRecommended })(Index);
