import React, { Component } from "react";
import "../../../../assets/css/profile.less";
import { Link } from "react-router-dom";
import Select, { components } from "react-select";
import { connect } from "react-redux";
import { getGender, getStatus, getCountry, updateUserProfile, getUserProfile, getParentProfile, setIsUserProfileSuccess, setIsParentGoalSuccess, setParentProfile, clearMessage } from "../../../../redux/actions";
import { withRouter } from "../../../../redux/store/navigate";
import { COUNTRY_NAME_LABEL, FIRST_NAME_LABEL, INTERESTED_IN_LABEL, EMAIL_LABEL, LAST_NAME_LABEL, ZIP_CODE_LABEL } from "../../../../Constants/commonLabels";
import Loader from "../../../../components/Loader";
import CustomAlert from "../../../../components/CustomAlert"
import Footer from "../../../../componentsNew/Common/Footer";
import Autocomplete from '@mui/material/Autocomplete';
import { loadZipcode } from "../../../../utils/AutoComplete";


const timezonesOptions = [
    { label: 'Hawaii Time', value: 'Pacific/Honolulu' },
    { label: 'Alaska Time', value: 'America/Anchorage' },
    { label: 'Pacific Time', value: 'America/Los_Angeles' },
    { label: 'Mountain Daylight Time', value: 'America/Denver' },
    { label: 'Mountain Standard Time', value: 'America/Phoenix' },
    { label: 'Central Time', value: 'America/Chicago' },
    { label: 'Eastern Time', value: 'America/New_York' },
];

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLoaded: false,
            loading: false,
            fields: { firstName: "", lastName: "" },
            errors: {},
            // selectedInterestOption: null,
            formData: {
                zipCode: ''
            },
            zipCodeOptions: [],
            selectedCountryOption: null,
            selectedZipOption: null,
            timeZone: null,
            studentsCollabCheckBox: false,
            counselingAllowedCheckBox: false,
            subCouncelingServicesCheckBox: false,
            interestOptionsvalue: [
                { label: "Profession", value: "Profession" },
                { label: "Engineering", value: "Engineering" },
                { label: "Developer", value: "Developer" },
            ],
        };
    }



    componentDidMount() {
        this.props.clearMessage()
        this.setState({ loading: true, dataLoaded: false })
        this.props.setIsParentGoalSuccess(false)
        this.props.getParentProfile()
    }

    componentWillUnmount() {
        this.setState({ timeZone: "", })
    }
    componentDidUpdate() {
        if (!this.state.status) {
            if (this.props.status !== undefined && this.props.status.length > 0) {
                this.setState({ loading: false, status: true })
            }
        }
        if (this.state.loading) {
            this.setInitialValues();
        }
        if (this.props.isProfileSuccess) {
            if (this.props.parentProfile.length >= 1) {
                this.props.navigate('/onboarding/profile/child');
                this.props.setIsUserProfileSuccess(false)

            } else {
                this.props.navigate('/onboarding/profile/child');
            }

        }
    }
    loadOptions = (value, callback) => {
        // universityApi(inputText).then((res) => callback(res.data.data?.map((item) => ({ label: item.university, value: item.university }))));
        if (value === "") {
            this.setState({ universityData: [] });
        }
        this.setState({ isload: true });
        this.setState({ noOptionLength: value });
        let list = []
        if (value !== "") {
            this.setState({ selectedZipOption: value })
            let payload = {
                searchString: value,
                pageNo: 1,
                limit: 80,
            };
            loadZipcode(payload).then((res) => {
                if (res.status === 200) {
                    res?.data?.data.map((item, index) => {
                        list.push({
                            label: item.zip,
                            value: item.zip
                        });

                    });
                    this.setState({ zipCodeOptions: list });
                } else {
                    this.setState({ zipCodeOptions: [] });
                }

                this.setState({ isload: false });
            })
        }

    }


    setInitialValues() {
        if (!this.state.dataLoaded) {
            let { fields, selectedCountryOption, studentsCollabCheckBox, counselingAllowedCheckBox, subCouncelingServicesCheckBox, selectedZipOption, timeZone } = this.state;
            const { country } = this.props;
            const { interestOptionsvalue } = this.state
            let userProfile = null;
            if (this.props.userProfile && this.props.userProfile && this.props.userProfile.email) {
                userProfile = this.props.userProfile;
                fields["email"] = userProfile.email ? userProfile.email : "";
            }

            if (this.props.userProfile && this.props.userProfile && this.props.userProfile.firstName) {
                userProfile = this.props.userProfile;
                fields["firstName"] = userProfile.firstName ? userProfile.firstName : "";
                fields["lastName"] = userProfile.lastName ? userProfile.lastName : "";
            }

            if (this.props.userProfile && this.props.userProfile && this.props.userProfile.parentProfile && this.props.userProfile.firstName && this.props.userProfile && country != undefined && this.props.userProfile.timezone) {
                let userProfileData = this.props.userProfile.parentProfile;
                let userProfile = this.props.userProfile;
                this.setState({ selectedZipOption: userProfile.zipCode })
                fields["zipCode"] = userProfile.zipCode ? userProfile.zipCode : "";
                const selectedTimeZone = timezonesOptions?.find(option => option?.value === userProfile?.timezone);
                if (selectedTimeZone) {
                    this.setState({ timeZone: selectedTimeZone });
                }
                selectedCountryOption = country.map((countryItem) => { return { label: countryItem.countryName, value: countryItem.isoCode2Digit } }
                ).find((x) => x.value === userProfile.country)

                this.setState({ selectedCountryOption, studentsCollabCheckBox, counselingAllowedCheckBox, subCouncelingServicesCheckBox });
                this.setState({ dataLoaded: true, loading: false })
            }
        }
    }
    handleChange(field, e) {
        let { errors } = this.state;
        let fields = this.state.fields;
        fields[field] = e.target.value;
        if (e.target.value.length >= 0) {
            errors[field] = "";
        }
        this.setState({ fields, errors: errors });
    }

    handleCheckboxChange(e, buttonName) {
        let { studentsCollabCheckBox, counselingAllowedCheckBox, subCouncelingServicesCheckBox } = this.state;
        if (buttonName === "studentsCollab") {
            studentsCollabCheckBox = e.target.checked
        } else if (buttonName === "counselingAllowed") {
            counselingAllowedCheckBox = e.target.checked
        } else if (buttonName === "subCouncelingServices") {
            subCouncelingServicesCheckBox = e.target.checked
        }
        this.setState({ studentsCollabCheckBox, counselingAllowedCheckBox, subCouncelingServicesCheckBox })
    }

    handleSelectChange(options, name) {
        let { selectedCountryOption, errors } = this.state;
        switch (name) {
            // case "interest":
            //     selectedInterestOption = options;
            //     errors["interest"] = "";
            //     break;
            case "country":
                selectedCountryOption = options;
                errors["country"] = "";
                break;
        }
        this.setState({ selectedCountryOption, errors: errors });
    }

    handleTimeZoneChange = (selectedOption) => {
        let { errors } = this.state;
        this.setState({ timeZone: selectedOption });
        errors["timeZone"] = "";
    }

    handleValidation = () => {
        let { selectedInterestOption, selectedCountryOption, selectedZipOption, timeZone } = this.state;
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (typeof fields["firstName"] !== "undefined") {
            const textcaseRegExp = /^[A-Z a-z]+$/;
            const trimmedValue = fields["firstName"].trim();
            const floatcasenumber = textcaseRegExp.test(fields["firstName"]);
            // if (!floatcasenumber) {
            //     formIsValid = false;
            //     errors["firstName"] = "FirstName should be Text";
            // }
            if (fields["firstName"].length < 2) {
                formIsValid = false;
                errors["firstName"] = "First name must contain 2 characters";
            }
            if (fields["firstName"].length > 50) {
                formIsValid = false;
                errors["firstName"] = "Max value upto 50 character";
            }
            if (!fields["firstName"]) {
                formIsValid = false;
                errors["firstName"] = "*Required";
            }
            if (trimmedValue.replace(/\s/g, '').length === 0) {
                formIsValid = false;
                errors["firstName"] = "*Required";
            }
        }


        if (typeof fields["lastName"] !== "undefined") {
            const textcaseRegExp = /^[A-Z a-z]+$/;
            const floatcasenumber = textcaseRegExp.test(fields["lastName"]);
            const trimmedValue = fields["lastName"].trim();
            // if (!floatcasenumber) {
            //     formIsValid = false;
            //     errors["lastName"] = "LastName should be Text";
            // }
            if (trimmedValue.replace(/\s/g, '').length === 0) {
                formIsValid = false;
                errors["lastName"] = "Last name cannot contain only spaces";
            }
            if (fields["lastName"].length < 1) {
                formIsValid = false;
                errors["lastName"] = "Last name must contain 1 characters";
            }
            if (fields["lastName"].length > 50) {
                formIsValid = false;
                errors["lastName"] = "Max value upto 50 character";
            }
            if (!fields["lastName"]) {
                formIsValid = false;
                errors["lastName"] = "*Required";
            }
        }
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "*Required";
        } else {
            const textcaseRegExp = /^[\w-\.+]+@([\w-]+\.)+[\w-]{2,4}$/;
            const emailvalid = textcaseRegExp.test(fields["email"]);
            if (!emailvalid) {
                formIsValid = false;
                errors["email"] = "Invalid Email";
            }
        }
        // if (!fields["zipCode"]) {
        //     formIsValid = false;
        //     errors["zipCode"] = "*Required";
        // }
        // else if (typeof fields["zipCode"] !== "undefined") {
        //     if (this.state.selectedCountryOption != null || undefined || "") {
        //         if (selectedCountryOption !== null && this.state.selectedCountryOption.value === "US") {
        //             const maxnumbercaseRegExp = /^[0-9]{0,5}$/;
        //             const minnumbercaseRegexp = /^.{5,}$/;
        //             const maxcasenumber = maxnumbercaseRegExp.test(fields["zipCode"]);
        //             const mincasenumber = minnumbercaseRegexp.test(fields["zipCode"]);
        //             if (!maxcasenumber) { formIsValid = false; errors["zipCode"] = "Zipcode should be 5 digit"; }
        //             if (!mincasenumber) { formIsValid = false; errors["zipCode"] = "Zipcode should be 5 digit"; }
        //         }
        //         else if (selectedCountryOption !== null && this.state.selectedCountryOption.value === "IN") {
        //             const maxnumbercaseRegExp = /^[0-9]{0,6}$/;
        //             const minnumbercaseRegexp = /^.{6,}$/;
        //             const maxcasenumber = maxnumbercaseRegExp.test(fields["zipCode"]);
        //             const mincasenumber = minnumbercaseRegexp.test(fields["zipCode"]);
        //             if (!maxcasenumber) { formIsValid = false; errors["zipCode"] = "Zipcode should be 6 digit"; }
        //             if (!mincasenumber) { formIsValid = false; errors["zipCode"] = "Zipcode should be 6 digit"; }
        //         }
        //     }
        // }
        if (selectedZipOption === undefined || selectedZipOption === null) {
            formIsValid = false;
            errors["zipCode"] = "*Required";
        }
        if (selectedCountryOption === null) {
            formIsValid = false;
            errors["country"] = "*Required";
        }
        if (selectedCountryOption === undefined) {
            formIsValid = false;
            errors["country"] = "*Required";
        }
        if (timeZone === undefined || timeZone === null) {
            formIsValid = false;
            errors["timeZone"] = "*Required";
        }
        // if (selectedInterestOption === null) {
        //     formIsValid = false;
        //     errors["interest"] = "* Required";
        // }
        this.setState({ errors: errors });
        return formIsValid;
    }

    handleNext = (e) => {
        e.preventDefault();
        if (this.handleValidation()) {
            let fields = this.state.fields;
            let { studentsCollabCheckBox, counselingAllowedCheckBox, subCouncelingServicesCheckBox,
                timeZone, selectedInterestOption, selectedCountryOption, selectedZipOption } = this.state;
            let values = {
                firstName: fields["firstName"],
                lastName: fields["lastName"],
                email: fields["email"],
                zipCode: selectedZipOption,
                country: selectedCountryOption.value,
                // interestedIn: [selectedInterestOption.label],
                timeZone: timeZone ? timeZone.value : null,
                studentsCollab: studentsCollabCheckBox,
                counselingAllowed: counselingAllowedCheckBox,
                subCouncelingServices: subCouncelingServicesCheckBox,
            }
            this.props.setParentProfile(values);
        }
    };

    onTagsChange = (event, values) => {
        console.log("values", values)
        let { errors } = this.state;
        this.setState({ selectedZipOption: values });
        errors['zipCode'] = ""
    }

    render() {
        const { country } = this.props;
        const { interestOptionsvalue } = this.state;
        const { selectedCountryOption, } = this.state;
        // const { selectedInterestOption, } = this.state;
        const { studentsCollabCheckBox, counselingAllowedCheckBox, subCouncelingServicesCheckBox, } = this.state;
        let countryOptions = [];
        country !== undefined && country.length > 0 && country.map((x, key) => {
            var temp = { label: x.countryName, value: x.isoCode2Digit }
            countryOptions.push(temp);
        })
        let interestOptions = [];
        interestOptionsvalue !== undefined && interestOptionsvalue.length > 0 && interestOptionsvalue.map((x, key) => {
            var temp = { label: x.label, value: x.value }
            interestOptions.push(temp);
        })
        const { ValueContainer, Placeholder } = components;
        const CustomValueContainer = ({ children, ...props }) => {
            return (
                <ValueContainer {...props}>
                    <Placeholder {...props} isFocused={props.isFocused}>
                        {props.selectProps.placeholder}
                    </Placeholder>
                    {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
                </ValueContainer>
            );
        };
        const styles = { placeholder: (base, state) => ({ ...base, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }), }

        const { formData, zipCodeOptions, selectedZipOption } = this.state;

        return (
            <>
                {this.state.loading && <Loader />}
                {this.state.loading || this.props.loading && <Loader />}

                <div className="profile">
                    <div className="d-flex">
                        <div className="mt-2">
                            <svg width="35" height="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.7274 20.4471C19.2716 19.1713 18.2672 18.0439 16.8701 17.2399C15.4729 16.4358 13.7611 16 12 16C10.2389 16 8.52706 16.4358 7.12991 17.2399C5.73276 18.0439 4.72839 19.1713 4.27259 20.4471" stroke="#222222" stroke-width="1.2" stroke-linecap="round" />
                                <circle cx="12" cy="8" r="4" fill="#7E869E" fill-opacity="0.25" stroke="#222222" stroke-width="1.2" stroke-linecap="round" />
                            </svg>
                        </div>
                        <h1 className="childtitle text-center" style={{ marginLeft: '10px' }}>Create Parent Profile</h1>
                    </div>
                    <div className="profile__form" >
                        <div className="d-flex justify-content-center">
                            {Object.keys(this.props.errorList).length === 0 && <CustomAlert />}
                        </div>

                        {/* <p className="profile__form__sub-title">General</p> */}
                        <div className="row m-0">
                            <div className="col-md-12 col-sm-12 col-12">
                                <div className="input-floating-label">
                                    <input refs="firstName" type="text" className={"textbox--primary textbox--rounded input"} value={this.state.fields["firstName"]} name="firstName" placeholder=" " onChange={this.handleChange.bind(this, "firstName")} />
                                    <label htmlFor='labelChange'>{FIRST_NAME_LABEL}</label>
                                    {this.state.errors["firstName"] && <span className="error-text ps-0">{this.state.errors["firstName"]}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="row m-0">
                            <div className="col-md-12 col-sm-12 col-12 ">
                                <div className="input-floating-label">
                                    <input refs="lastName" type="text" className={"textbox--primary textbox--rounded input"} name="lastName" placeholder=" " onChange={this.handleChange.bind(this, "lastName")} value={this.state.fields["lastName"]} />
                                    <label>{LAST_NAME_LABEL}</label>
                                    {this.state.errors["lastName"] && <span className="error-text ps-0">{this.state.errors["lastName"]}</span>}
                                </div>
                            </div>
                        </div>

                        <div className="row m-0">
                            <div className="col-md-12 col-sm-12 col-12 ">
                                <div className="input-floating-label">
                                    <input refs="email" type="email" className={"textbox--primary textbox--rounded input"} value={this.state.fields["email"]} name="email" placeholder=" " onChange={this.handleChange.bind(this, "email")} />
                                    <label>{EMAIL_LABEL}</label>
                                    {this.state.errors["email"] && <span className="error-text">{this.state.errors["email"]}</span>}
                                    {this.props?.errorList && this.props?.errorList.email && <span className="error-text">{this.props?.errorList.email}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="row m-0">
                            <div className="col-md-12 col-sm-12 col-12 ">
                                <div className="input-floating-label">
                                    <Select value={this.state.timeZone} options={timezonesOptions} placeholder="Time Zone" className="goals__form__select mb-0" classNamePrefix="mySelect" name="country" closeMenuOnSelect={true} isClearable={false} onChange={(value) => this.handleTimeZoneChange(value)} components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                     styles={{ container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }),
                                      valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', }), 
                                      menu: (provided) => ({
                                        ...provided,
                                        zIndex: 9999, // Ensure the menu is above other elements
                                    }),
                                    menuList: (provided) => ({
                                        ...provided,
                                        maxHeight: '200px', // Set a max height for the dropdown menu
                                        overflowY: 'auto', // Enable vertical scrolling
                                    }),
                                      placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }} />
                                    {this.state.errors["timeZone"] && <span className="error-text ps-0">{this.state.errors["timeZone"]}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="row m-0">
                            <div className="col-md-12 col-sm-12 col-12 ">
                                <div className="input-floating-label">
                                    <Select value={selectedCountryOption} options={countryOptions} placeholder={COUNTRY_NAME_LABEL} className="goals__form__select mb-0" classNamePrefix="mySelect" name="country" closeMenuOnSelect={true} isClearable={false} onChange={(value) => this.handleSelectChange(value, 'country')} components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }} styles={{ container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }} />
                                    {this.state.errors["country"] && <span className="error-text ps-0">{this.state.errors["country"]}</span>}
                                </div>
                            </div>
                        </div>

                        <div className="row m-0">
                            <div className="col-md-12 col-sm-12 col-12">
                                <Autocomplete
                                    noOptionsText={'There is no university related to your search'}
                                    className="goals__form__select"
                                    id="tags-filled"
                                    name="zipCode"
                                    type='text'
                                    clearOnBlur={false}
                                    filterSelectedOptions
                                    forcePopupIcon={false}
                                    freeSolo={true}
                                    openOnFocus={true}
                                    options={this.state.zipCodeOptions?.length > 0 ? this.state.zipCodeOptions.map((option) => option.label) : []}
                                    filterOptions={(options, state) => options}
                                    value={selectedZipOption !== null ? selectedZipOption : []}
                                    onChange={this.onTagsChange}
                                    onInputChange={async (event, value) => { this.loadOptions(value) }}
                                    renderInput={(params) => (
                                        <div className="input-floating-label" ref={params.InputProps.ref} style={{ marginBottom: '20px' }}>
                                            <input type="text" {...params.inputProps} className={"textbox--primary textbox--rounded input"} id="zipCode" name="zipCode" placeholder=" " />
                                            <label>{ZIP_CODE_LABEL}</label>
                                            {this.state.errors["zipCode"] && <span className="error-text ps-0">{this.state.errors["zipCode"]}</span>}
                                        </div>
                                    )}
                                />

                            </div>
                        </div>
                    </div>

                </div>
                <Footer reverseLink={"/onboarding/goals/child"}>
                    <Link className="footers__rightSec__cta" to="#" onClick={(e) => this.handleNext(e)} >Create Child profile
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.92893 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.0222 21.4135 8.08879 20.3147 6.4443C19.2159 4.79981 17.6541 3.51808 15.8268 2.76121C13.9996 2.00433 11.9889 1.8063 10.0491 2.19215C8.10929 2.578 6.32746 3.53041 4.92893 4.92893" stroke-width="2" className="rolestrokes" />
                            <path d="M15 12L15.7809 11.3753L16.2806 12L15.7809 12.6247L15 12ZM3 13C2.44771 13 2 12.5523 2 12C2 11.4477 2.44771 11 3 11V13ZM11.7809 6.3753L15.7809 11.3753L14.2191 12.6247L10.2191 7.6247L11.7809 6.3753ZM15.7809 12.6247L11.7809 17.6247L10.2191 16.3753L14.2191 11.3753L15.7809 12.6247ZM15 13H3V11H15V13Z" className="rolefills" />
                        </svg>
                    </Link>
                </Footer>
            </>
        );
    }
}

const mapStateToProps = ({ auth, commonData, userProfileData }) => {
    const { message, errorList, status, gender, country, loading } = commonData
    const { isProfileSuccess, userProfile, parentProfile } = userProfileData;
    return { message, errorList, status, gender, isProfileSuccess, userProfile, country, loading, parentProfile }
};
export default connect(mapStateToProps, { getGender, getStatus, updateUserProfile, getParentProfile, setIsUserProfileSuccess, setIsParentGoalSuccess, getUserProfile, getCountry, setParentProfile, clearMessage })(withRouter(Index));