import React, { useEffect, useState } from "react";
import "./profile.less"
import { Button, Modal, Grid, Autocomplete, TextField, Chip, Typography } from '@mui/material';
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useComponentDidUpdate } from "../../../utils/useEffectHooks";
import { universityApi } from "../../../utils/AutoComplete";
import { connect } from "react-redux";
import { setParentUserGoalsStudent, setIsChildProfileSuccess, setIsParentReset, getProfessions, clearErrorList, setParentUserGoalsProfile, setIsParentGoalSuccess, getGoalsFromStudent, editIsChildProfileSuccess, setIsChildProfileError } from "../../../redux/actions";
import Loader from "../../../components/Loader"
import CancelIcon from '@mui/icons-material/Cancel';
import CreatableSelect from 'react-select/creatable';
import Select, { components } from "react-select";
import ConnectChildForm from "./connectedChildForm";
import CloseIcon from '@mui/icons-material/Close';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';


const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder}</Placeholder>
      {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
    </ValueContainer>
  );
};

const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props} >
      <CancelIcon style={{ "fill": "rgba(34, 34, 34, 0.67)" }} />
    </components.MultiValueRemove>
  );
};

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'relative',
    backgroundColor: '#fff',
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
    padding: '0',
    borderRadius: '4px',
    width: '100%',
    height: "100%",
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    // height: '55px',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // padding: '0 20px',
    boxSizing: 'border-box',
    paddingTop: '10px'
  },
  content: {
    overflow: 'auto',
    padding: '20px',
    flexGrow: 1,
  },
  footer: {
    height: 'fit-content',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0 20px',
    paddingBottom: '20px',
    boxSizing: 'border-box',
  },
};

const validation = Yup.object({
  improveGpa: Yup.boolean('Select this checkbox please'),
  GPA: Yup.string().when("improveGpa", {
    is: (improveGpa) => improveGpa === true,
    then: Yup.string().typeError('Please enter GPA').nullable().required("Please enter GPA")
      .matches(/^[1-9](\.\d{1,2})?$/, 'Invalid GPA')
  }).nullable()
});

const Index = (props) => {
  const [universityCheckBox, setUniversityCheckBox] = useState(false);
  const [professionsCheckBox, setProfessionsCheckBox] = useState(false);
  const [gPACheckBox, setgPACheckBox] = useState(false);
  const [universityData, setUniversityData] = useState([]);
  const [selectedUniversityOption, setselectedUniversityOption] = useState(null);
  const [selectedProfessionOption, setselectedProfessionOption] = useState(null);
  const [professionOptionstions, setprofessionOptionstions] = useState(null);
  const [childOpen, setChildOpen] = useState(false);
  const [error, setError] = useState({});
  const [loadingGoal, setLoadingGoal] = useState(props?.openloader);
  const [errorList, setErrorList] = useState([]);



  const handleChildOpen = () => { setChildOpen(true); }
  const handleChildClose = () => { setChildOpen(false); }
  const handleCloseBoth = () => {
    props?.handleClose();
    setChildOpen(false);
    reset()
    setUniversityCheckBox(false)
    setProfessionsCheckBox(false)
    setgPACheckBox(false)
    setUniversityData([])
    setselectedUniversityOption(null)
    setselectedProfessionOption(null)
    setError({})
    setErrorList([])
    props?.clearErrorList()
  }
  const { register, handleSubmit, watch, reset, setValue, clearErrors, control, formState: { errors } } = useForm({ resolver: yupResolver(validation), });

  const handleClose = () => {
    props?.handleClose();
    reset()
    setUniversityCheckBox(false)
    setProfessionsCheckBox(false)
    setgPACheckBox(false)
    setUniversityData([])
    setselectedUniversityOption(null)
    setselectedProfessionOption(null)
    setError({})
    setErrorList([])
    props?.clearErrorList()
  };

  useComponentDidUpdate(() => {
    if (props?.createGoal) {
      reset()
      setUniversityCheckBox(false)
      setProfessionsCheckBox(false)
      setgPACheckBox(false)
      setUniversityData([])
      setselectedUniversityOption(null)
      setselectedProfessionOption(null)
    }
  }, [props?.createGoal]);

  useEffect(() => {
    props?.clearErrorList()
    setErrorList([])
    props.setIsParentReset(false)
    props.getProfessions();
    let professionOption = [];
    props?.professions !== undefined && props?.professions.length > 0 && props?.professions.map((profession, key) => {
      let temp = { label: profession.profession, value: profession.profession }
      professionOption.push(temp);
    });
    setprofessionOptionstions(professionOptionstions)
  }, []);

  useEffect(() => {
    let professionOption = [];
    props?.professions !== undefined && props?.professions.length > 0 && props?.professions.map((profession, key) => {
      let temp = { label: profession.profession, value: profession.profession }
      professionOption.push(temp);
    });
    setprofessionOptionstions(professionOption)
    if (props.show && props.openloader) {
      setLoadingGoal(true)
    }
  }, [props]);

  useComponentDidUpdate(() => {
    if (props?.data?.id && props?.isParentGoalSuccess === true && !props?.createGoal) {
      let payload = {
        "studentId": localStorage.getItem("parentViaChild")

      }
      props?.getGoalsFromStudent(payload)
      props.setIsParentGoalSuccess(false)
      if (props?.dashboard) {
        handleClose()
      } else {
        handleChildOpen()
      }
    }
  }, [props?.isParentGoalSuccess, !props?.createGoal]);

  useComponentDidUpdate(() => {
    if (props?.createGoal && props?.isParentAddSuccess === true) {
      props.setIsParentReset(false)
      if (!childOpen) {
        if (props?.dashboard) {
          handleClose()
        } else {
          handleChildOpen()
        }
      }
    }
  }, [props]);

  useComponentDidUpdate(() => {
    if (props?.data) {
      let goalesData = props?.parentGoal?.goals
      goalesData && goalesData.map((data, key) => {
        if (data == "university") {
          setUniversityCheckBox(true)
        } else if (data == "weighted" && "unweighted") {
          setgPACheckBox(true)
        } else if (data == "profession") {
          setProfessionsCheckBox(true)
        }
      })
      //prepopulate the datas
      let universityOptionstions = [];
      props?.parentGoal?.universities !== undefined && props?.parentGoal?.universities.length > 0 && props?.parentGoal?.universities.map((university, key) => {
        let temp = { label: university, value: university }
        universityOptionstions.push(temp);
      });
      setselectedUniversityOption(universityOptionstions)
      setValue("GPA", props?.parentGoal?.targetUnweighted);
      setValue("weighted", props?.parentGoal?.targetWeighted);
      let professionOptionstions = [];
      props?.parentGoal?.professions !== undefined && props?.parentGoal?.professions.length > 0 && props?.parentGoal?.professions.map((profession, key) => {
        let temps = { label: profession, value: profession }
        professionOptionstions.push(temps);
        setselectedProfessionOption(professionOptionstions)
      });
      setLoadingGoal(false)
      props?.handleLoaderFalse(false)
    }
  }, [props?.parentGoal])

  useComponentDidUpdate(() => {
    if (props?.errorList) {
      setErrorList(props?.errorList)
    }
  }, [props?.errorList]);

  console.log(props?.errorList, "errorList")


  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    if (!universityCheckBox && !professionsCheckBox && !gPACheckBox) {
      formIsValid = false;
      errors["goal"] = "Please select any goal";
      setselectedUniversityOption(null)
      setselectedProfessionOption(null)
    }
    if (universityCheckBox) {
      if (selectedUniversityOption === null || selectedUniversityOption.length === 0) {
        formIsValid = false;
        errors["university"] = "Please select any university";
      }
    }
    if (professionsCheckBox) {
      if (selectedProfessionOption === null || selectedProfessionOption.length === 0) {
        formIsValid = false;
        errors["profession"] = "Please select any profession";
      }
      else if (selectedProfessionOption.length > 10) {
        formIsValid = false;
        errors["profession"] = "Max upto 10 proffession allowed";
      }
    }
    if (gPACheckBox === undefined) {
      formIsValid = false;
      errors["GPA"] = "Please enter GPA";
    }
    setError(errors)
    return formIsValid;
  }

  const onSubmitGoals = (data) => {
    let goals = [];
    if (universityCheckBox)
      goals.push("university");
    else setselectedUniversityOption([])

    if (professionsCheckBox)
      goals.push("profession");
    else setselectedProfessionOption([])

    if (gPACheckBox)
      goals.push("GPA");
    let selectedUniversity = [];

    selectedUniversityOption && selectedUniversityOption.map((element) => {
      selectedUniversity.push(element.label || element);
    });

    let selectedProfession = [];
    selectedProfessionOption && selectedProfessionOption.map((element) => {
      selectedProfession.push(element.value);
    });

    let payload = {
      improveGpa: gPACheckBox,
      specificProfession: professionsCheckBox,
      specificUniversity: universityCheckBox,
      goalsSelected: goals,
      interestedUniversities: selectedUniversity,
      interestedProfession: selectedProfession
    }
    if (payload.improveGpa === true) {
      payload["unweighted"] = data?.GPA
      payload["weighted"] = data?.weighted
    }
    if (handleValidation()) {
      if (props?.data?.id || props?.dashboard) {
        payload["studentId"] = props?.dashboard ? localStorage?.getItem("parentViaChild") : props?.data?.id 
        props.setParentUserGoalsStudent(payload)
      } else {
        props.setParentUserGoalsProfile(payload)
      }
    }
  };
  console.log(props?.data, "props?.dataprops?.data")

  const onTagsChange = (event, values) => {
    if (values === "") { setUniversityData([]) }
    setselectedUniversityOption(values)
    setError(prevErrors => ({
      ...prevErrors,
      university: ""
    }));
  }

  const loadOptions = (value, callback) => {
    if (value === "") {
      setUniversityData([])
    }
    let list = []
    if (value !== "") {
      universityApi(value).then((res) => {
        if (res.status === 200) {
          res?.data?.data.map((item, index) => {
            list.push({
              label: item.university,
              value: item.university
            });

          });
          setUniversityData(list)
        } else {
          setUniversityData([])
        }
      })
    }
  }

  const handleCheckboxChange = (e, buttonName) => {
    if (buttonName === "universities") {
      setUniversityCheckBox(e.target.checked)
    } else if (buttonName === "profession") {
      setProfessionsCheckBox(e.target.checked)
    } else if (buttonName === "GPA") {
      setgPACheckBox(e.target.checked)
    }
    setError({})
  }

  const handleSelectChange = (options, name) => {
    let errors = {};
    switch (name) {
      case "university":
        setselectedUniversityOption(options)
        break;
      case "professions":
        setselectedProfessionOption(options.slice(0, 10))
        setError(prevErrors => ({
          ...prevErrors,
          profession: ""
        }));
        if (options.length > 10) {
          errors['profession'] = "Max upto 10 proffession allowed"
          setError(errors)
        }
        else {
          if (options) {
            if (options.at(-1)?.value?.length > 50 && options.at(-1)?.__isNew__ === true) {
              errors['profession'] = "Max value upto 50 character"
            }
            else if (options.at(-1)?.value?.length < 50 && options.at(-1)?.__isNew__ === true) {
              setselectedProfessionOption(options)
              errors['profession'] = ""
            }
            else {
              setselectedProfessionOption(options)
              errors['profession'] = ""
            }
          }
        }
        break;
    }
  }

  const handleInputChange = (event) => {
    setErrorList([])
    clearErrors()
  };

  console.log(props?.parentGoal?.goals, " props?.parentGoal?.goals")

  return (
    <>
      {childOpen && <ConnectChildForm show={childOpen} createGoal={props?.createGoal} data={props?.data} errorList={props?.errorList} handleBoth={handleCloseBoth} handleChildClose={handleChildClose} graduationyear={props?.graduationyear} status={props?.status} gender={props?.gender} countryOption={props?.country} />}
      <Modal
        style={styles.modal}
        open={props.show}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className="goalsModal"
      >
        <div style={styles.paper}>
          {loadingGoal && <Loader />}
          <div style={styles.header}>
            <Typography variant="h6" id="modal-title">{props.title}</Typography>
            <Button onClick={handleClose}><CloseIcon sx={{ fill: 'black' }} /></Button>
          </div>
          <div style={styles.content}>
            <form id='my-form' onSubmit={handleSubmit(onSubmitGoals, handleValidation)}>
              <div className="role connectedGoalsPage">
                <div className="role_card parent-goal" style={{ marginBottom: '100px' }}>
                  <h1 className="role_title text-center"><SchoolOutlinedIcon sx={{ fontSize: '3rem' }} /> {props?.data?.id ? `Edit ${props?.data?.fullName} Profile` : 'Add child profile'} </h1>
                  <p className="role_desc bluetext text-center">I want my child to...</p>
                  <div className="goals_page">
                    <div className="mb-2 role__btns">
                      <input type="checkbox" defaultChecked={universityCheckBox} checked={universityCheckBox} onClick={(e) => handleCheckboxChange(e, 'universities')} className="btn-check" name="options1" id="btncheck1" autoComplete="off" />
                      <label className="btn cta--role--btn w-mob-100" htmlFor="btncheck1">Get into specific universities</label>
                    </div>
                    <div className=" mb-2 role__btns">
                      <input type="checkbox" defaultChecked={professionsCheckBox} checked={professionsCheckBox} onClick={(e) => handleCheckboxChange(e, 'profession')} className="btn-check" name="options2" id="btncheck2" autoComplete="off" />
                      <label className="btn cta--role--btn w-mob-100" htmlFor="btncheck2">Get into specific professions</label>
                    </div>
                    <div className=" mb-2 role__btns">
                      <input type="checkbox" {...register("improveGpa")} name="improveGpa" defaultChecked={gPACheckBox} checked={gPACheckBox} className="btn-check" onClick={(e) => handleCheckboxChange(e, 'GPA')} id="improveGpa" autoComplete="off" />
                      <label className="btn cta--role--btn w-mob-100" htmlFor="improveGpa">Improve GPA</label>
                    </div>
                  </div>
                  <div className="error-text text-center">{error["goal"]}</div>
                  <div className="goals__form mb-2">
                    {universityCheckBox &&
                      <div className="row">
                        <div className="col-md-12 mb-5 role__btns asyncmultiselect multinew">
                          <Autocomplete
                            multiple
                            className="goals__form__select"
                            id="tags-filled"
                            name="universityDropdown"
                            type='text'
                            clearOnBlur={false}
                            filterSelectedOptions
                            clearIcon
                            ChipProps
                            forcePopupIcon={false}
                            freeSolo={true}
                            openOnFocus={true}
                            options={universityData?.length > 0 ? universityData.map((option) => option?.label) : []}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip label={option} {...getTagProps({ index })} deleteIcon={<CancelIcon />} />
                              ))
                            }
                            filterOptions={(options, state) => options}
                            defaultValue={selectedUniversityOption !== null ? selectedUniversityOption?.map((option) => option?.label || option) : []}
                            onChange={onTagsChange}
                            onInputChange={async (event, value) => {
                              loadOptions(value)
                            }}
                            renderInput={(params) => (
                              <>
                                <TextField
                                  {...params}
                                  label="Desired University"
                                  size="small"
                                  autoComplete='off'
                                  inputProps={{
                                    ...params.inputProps,
                                    onKeyDown: (e) => {
                                      if (e.key === 'Enter') {
                                        e.preventDefault();
                                      }
                                    },
                                  }}
                                />
                              </>
                            )}
                          />
                          <div className="error-text">{error["university"]}</div>
                        </div>
                      </div>
                    }
                    {professionsCheckBox &&
                      <div className="row">
                        <div className="col-md-12 mb-5 role__btns asyncmultiselect">
                          <CreatableSelect className="goals__form__select" onChange={(value) => handleSelectChange(value, 'professions')} noOptionsMessage={() => null} classNamePrefix="mySelect" options={professionOptionstions} id="professionsSelect"
                            closeMenuOnSelect={true} value={selectedProfessionOption} isClearable={false} isMulti components={{ ValueContainer: CustomValueContainer, DropdownIndicator: () => null, IndicatorSeparator: () => null, MultiValueRemove }}
                            placeholder="Professions"
                            styles={{
                              option: (provided, state) => ({ ...provided, cursor: 'pointer', }),
                              container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', }),
                              placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10 })
                            }} />
                          <div className="error-text">{error["profession"]}</div>
                        </div>
                      </div>
                    }
                    {gPACheckBox &&
                      <div className="row ">
                        <div className="col-md-6 role__btns mb-3">
                          <div className="input-floating-label">
                            <input  {...register("GPA")} type="text" onChange={handleInputChange} className={"textbox--primary textbox--rounded input"} name="GPA"
                              placeholder="GPA" onKeyPress={(event) => { if (!/[0-9.]/.test(event.key)) { event.preventDefault(); } }} />
                            <label>Target Unweighted GPA</label>
                            <div className="error-text">{errors.GPA?.message}</div>
                            {errorList?.unweighted && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.unweighted}</div>}
                          </div>
                        </div>
                        <div className="col-md-6 role__btns mb-3">
                          <div className="input-floating-label">
                            <input  {...register("weighted")} type="text" onChange={handleInputChange} className={"textbox--primary textbox--rounded input"} name="weighted"
                              placeholder="weighted" onKeyPress={(event) => { if (!/[0-9.]/.test(event.key)) { event.preventDefault(); } }} />
                            <label>Target Weighted GPA</label>
                            <div className="error-text">{errors.GPA?.message}</div>
                            {errorList?.weighted && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.weighted}</div>}
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div style={styles.footer} >
            <Grid container spacing={2} className="footers" style={{ zIndex: "0" }}>
              <Grid item xs={6} sm={4} md={4}> </Grid>
              <Grid item xs={12} sm={4} md={4} className='text-center'></Grid>
              <Grid item xs={12} sm={4} md={4} className='next-step-btn footers__rightSec'>
                <Button form='my-form' type="submit"  >
                  <div className="footerGoals">
                    {props?.dashboard ? "Update" : (props?.data?.id ? "Edit Child Profile" : "Create Child Profile")}

                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.92893 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.0222 21.4135 8.08879 20.3147 6.4443C19.2159 4.79981 17.6541 3.51808 15.8268 2.76121C13.9996 2.00433 11.9889 1.8063 10.0491 2.19215C8.10929 2.578 6.32746 3.53041 4.92893 4.92893" stroke="white" />
                      <path d="M15 12L15.3904 11.6877L15.6403 12L15.3904 12.3123L15 12ZM3 12.5C2.72386 12.5 2.5 12.2761 2.5 12C2.5 11.7239 2.72386 11.5 3 11.5V12.5ZM11.3904 6.68765L15.3904 11.6877L14.6096 12.3123L10.6096 7.31235L11.3904 6.68765ZM15.3904 12.3123L11.3904 17.3123L10.6096 16.6877L14.6096 11.6877L15.3904 12.3123ZM15 12.5H3V11.5H15V12.5Z" stroke="white" />
                    </svg>


                  </div>

                </Button>
              </Grid>
            </Grid>

          </div>
        </div>
      </Modal>
    </>
  );
}
const mapStateToProps = ({ userProfileData, commonData, parentData }) => {
  const { userProfile, isProfileSuccess, childprofile, parentProfile, isChildProfileSucess, isEditChildError, isCreateChildError, editChildProfileSucess } = userProfileData;
  const { status, gender, country, graduationyear, schools, professions, loading } = commonData;
  const { parentGoal, isParentGoalSuccess, isParentAddSuccess } = parentData;
  return { userProfile, isProfileSuccess, status, gender, parentGoal, isParentAddSuccess, schools, country, isParentGoalSuccess, loading, isCreateChildError, professions, isEditChildError, childprofile, isChildProfileSucess, editChildProfileSucess, parentProfile, graduationyear };
};

export default connect(mapStateToProps, { setParentUserGoalsProfile, getGoalsFromStudent, clearErrorList, getProfessions, setIsParentReset, setIsParentGoalSuccess, setParentUserGoalsStudent, editIsChildProfileSuccess, setIsChildProfileSuccess, setIsChildProfileError, })(Index);
