import React from "react";
import "../../../../assets/css/New_Profile.less"
import { Grid, Box } from "@mui/material";
import ConnectedParent from "./ConnectedParent"
import SentInvite from "./SentInviteParent"
const Index = (props) => {

    return (
        <>
            <div className="Connected_Account_Page">
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <ConnectedParent />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={5}>
                            <SentInvite />
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </>
    );
}
export default Index