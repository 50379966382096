import React from 'react'
import "../CourseInfoCard/courseInfoCard.less";

const Index = ({children, name, type, grade, teacher, description}) => {
    const stripHTMLTags = (html) => {
        const tempElement = document.createElement("div");
        tempElement.innerHTML = html;
        return tempElement.textContent || tempElement.innerText || "";
    };
    return (
        <div className="courseDetailCard">
            <div className="courseDetailCard__topSec">
                <p className="courseDetailCard__topSec__title" title={name}>{name}</p>
                <div className="courseDetailCard__topSec__cardgrid__dot" >
                    {children}
                </div>
            </div>
            <div className="courseDetailCard__midSec">
                <div className="courseDetailCard__midSec__content">
                    <p className="courseDetailCard__midSec__content__label">Activity Type</p>
                    <p className="courseDetailCard__midSec__content__value ">{type}</p>
                </div>
                { grade && 
                    <div className="courseDetailCard__midSec__content">
                        <p className="courseDetailCard__midSec__content__label">Grade Achieved</p>
                        <p className="courseDetailCard__midSec__content__value ">{grade}</p>
                    </div>
                }{ 
                teacher &&
                    <div className="courseDetailCard__midSec__content">
                        <p className="courseDetailCard__midSec__content__label">Teacher</p>
                        <p className="courseDetailCard__midSec__content__value ">{teacher}</p>
                    </div>
                }
            </div>
            <div className="courseDetailCard__bottomSec">
                <p className="courseDetailCard__bottomSec__description">
                    {stripHTMLTags(description) || ''}
                </p>
            </div>
        </div>
    );
};
export default Index;