import React from 'react'
import { connect } from "react-redux";

const Index = (props) => {
    return (
        <>
            <div className='SocialLoginCard'>
                <div className="socialloginpassword">
                    <div className="row">
                        <div className="col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1 col-xxl-1">
                            {props?.userProfile && props?.userProfile && props?.userProfile.signedupVia === "google" &&
                                <div className="googleicon">
                                    <svg width="35" height="35" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" width="47" height="47" rx="9.5" fill="white" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M35.04 24.2605C35.04 23.445 34.9668 22.6609 34.8309 21.9082H24V26.3566H30.1891C29.9225 27.7941 29.1123 29.0121 27.8943 29.8275V32.713H31.6109C33.7855 30.7109 35.04 27.7627 35.04 24.2605Z" fill="#4285F4" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M23.9995 35.5005C27.1045 35.5005 29.7077 34.4708 31.6104 32.7144L27.8938 29.829C26.864 30.519 25.5467 30.9267 23.9995 30.9267C21.0042 30.9267 18.469 28.9037 17.5647 26.1855H13.7227V29.1651C15.6149 32.9235 19.504 35.5005 23.9995 35.5005Z" fill="#34A853" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M17.5652 26.1855C17.3352 25.4955 17.2045 24.7584 17.2045 24.0005C17.2045 23.2425 17.3352 22.5055 17.5652 21.8155V18.8359H13.7232C12.9443 20.3884 12.5 22.1448 12.5 24.0005C12.5 25.8562 12.9443 27.6125 13.7232 29.165L17.5652 26.1855Z" fill="#FBBC05" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M23.9995 17.0739C25.6879 17.0739 27.2038 17.6541 28.3956 18.7936L31.694 15.4952C29.7024 13.6395 27.0992 12.5 23.9995 12.5C19.504 12.5 15.6149 15.077 13.7227 18.8355L17.5647 21.815C18.469 19.0968 21.0042 17.0739 23.9995 17.0739Z" fill="#EA4335" />
                                        <rect x="0.5" y="0.5" width="47" height="47" rx="9.5" stroke="#EBEBEB" />
                                    </svg>
                                </div>
                            }
                            {props?.userProfile && props?.userProfile && props?.userProfile.signedupVia === "facebook" &&
                                <div className="fbicon">
                                    <svg width="35" height="35" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" width="47" height="47" rx="9.5" fill="white" />
                                        <rect width="24" height="24" transform="translate(12 12)" fill="white" />
                                        <path d="M35.5 24.0694C35.5 17.7181 30.3513 12.5694 24 12.5694C17.6487 12.5694 12.5 17.7181 12.5 24.0694C12.5 29.8094 16.7054 34.567 22.2031 35.4297V27.3936H19.2832V24.0694H22.2031V21.5358C22.2031 18.6536 23.92 17.0616 26.5468 17.0616C27.805 17.0616 29.1211 17.2862 29.1211 17.2862V20.1163H27.671C26.2424 20.1163 25.7969 21.0027 25.7969 21.9122V24.0694H28.9863L28.4765 27.3936H25.7969V35.4297C31.2946 34.567 35.5 29.8094 35.5 24.0694Z" fill="#1877F2" />
                                        <rect x="0.5" y="0.5" width="47" height="47" rx="9.5" stroke="#EBEBEB" />
                                    </svg>
                                </div>
                            }
                        </div>
                        <div className="logpassword col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11 col-xxl-11"><p>Your LeapBrains account is managed by{" "}
                            {props?.userProfile && props?.userProfile && props?.userProfile.signedupVia === "google" ? "Google." : "Facebook."}
                            <span> Change settings</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = ({ userProfileData }) => {
    const { userProfile } = userProfileData;
    return { userProfile };
};
export default connect(mapStateToProps, {})(Index);
