import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Button, Modal } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import Select, { components } from "react-select";
import CreatableSelect from 'react-select/creatable';
import { withRouter } from "../../../../../redux/store/navigate";
import { useForm, Controller, useFormState } from "react-hook-form";
import { ACTIVITY_NAME_LABEL, ACTIVITY_TYPE_LABEL } from "../../../../../Constants/commonLabels";
import { yupResolver } from "@hookform/resolvers/yup";
import Loader from "../../../../../components/Loader";
import {  CreateActivity, clearMessage } from '../../../../../redux/actions'
import { ALERT_HIDE } from "../../../../../redux/constants/CommonTypes";
import Alert from 'react-bootstrap/Alert';
import ReactQuill from "react-quill";

const validation = Yup.object({
    activityName: Yup.object().required("*Required").nullable(),
    activityType: Yup.object().required("*Required").nullable(),
    // gradeAchieved: Yup.object().nullable(),
    description: Yup.string().transform((value) => {
        return value.replace(/<[^>]+>/g, '').replace(/\&nbsp;+/g, ' ')
    }).max(750, "Description should not exceed 750 characters").nullable()
})

const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder}</Placeholder>
            {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
        </ValueContainer>
    );
};

const Index = (props) => {
    const [loading, setLoading] = useState(false)
    const [errorList, setErrorList] = useState([])
    const [activityNameOpts, setActivityNameOpts] = useState([])
    const [activityTypeOpts, setActivityTypeOpts] = useState([])
    const [gradeOpts, setGradeOpts] = useState([])

    const dispatch = useDispatch()

    const { register,  handleSubmit, watch, reset, control, formState: { errors }, setValue, clearErrors } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(
            validation
        ),
    });
    const { dirtyFields } = useFormState({
        control
      });

    let descLength = watch("description")
    let activityTypeObserver = watch("activityType")
    let activityNameObserver = watch("activityName")

    useEffect(() => {
        setInitialValues()
    }, [])

    useEffect(() => {
        setInitialValues()
        if (activityTypeObserver && dirtyFields.activityType && activityNameObserver ){
            setValue('activityName', null)
        }
    }, [activityTypeObserver])

    useEffect(() => {
        clearErrors()
		if (errorList) {
			setErrorList([])
		}
    }, [activityTypeObserver, activityNameObserver ])

    useEffect(() => {
        if (props?.errorList !== errorList){
            setErrorList(props?.errorList)
        } else {
            setErrorList([])
        }
        
    }, [props?.errorList])

    const hideModel = (e) => {
        reset()
        props.clearMessage()
        dispatch({ type: ALERT_HIDE })
        setErrorList([])
        props?.hideModel();
    }
    
    const setInitialValues = () => {
        let activityNameOptions = [];
        let activityTypeOptions = [];
        // let gradeOptions = [];
        if (props?.errorList?.length === 0) {
            setErrorList([])
        }

        props?.activityNames?.length > 0 && props?.activityNames?.filter( x => x?.activityTypeId === activityTypeObserver?.id ).map((x, key) => {
            let temp = { label: x.activityName, value: x.activityName }
            activityNameOptions.push(temp);
        })

        props?.activity?.length > 0 && props?.activity?.map((x) => {
            let temp = { label: x.activityType, value: x.activityType, id: x.id }
            activityTypeOptions.push(temp);
        })
        // props?.studentGrades?.map( x => {
        //     let temp = { label: x.grade, value: x.grade }
        //     gradeOptions.push(temp);
        // })
        setActivityNameOpts(activityNameOptions)
        setActivityTypeOpts(activityTypeOptions)
        // setGradeOpts(gradeOptions)
    }
    
    const onSubmit = (data) => {
        let { activityName, activityType, description } = data;
        let payload = {
            accessibleAdvisors: [],
            activityName: activityName.value,
            activityType: activityType?.value,
            description: description,
            // grade: gradeAchieved?.value,
            phaseId: props?.semData?.id,
            planId: props?.semData?.planId
        };
        props?.CreateActivity(payload, true);
    }
    
    return (
        <Modal size={'md'} show={props?.showAddActivity} onHide = {(e) => hideModel(e)}  centered={true} dialogclassname={"mywork-popup"}>
            <Modal.Header closeButton>
                <Modal.Title>Add Activity</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {loading && <Loader />}
                {(loading || props?.loading) && <Loader />}

                { props?.alertShow ? 
                    ((!props?.AddActivity || !props?.EditActivity ) && Object.keys(props?.errorList || {}) == 0) && 
                        <Alert 
                            {...props}
                            variant={props.customAlertSuccess ? 'success' : 'danger'}
                            onClose= {() => dispatch({ type: ALERT_HIDE })}
                            dismissible
                        >
                            {props.customAlertMsg}
                            
                        </Alert> : <></>
                }
                
                <form id="advData" onSubmit={handleSubmit(onSubmit)}> 
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Controller
                                name="activityType"
                                control={control}
                                render={({ field }) =>
                                    <Select name="activityType" {...field} id="activityType"
                                        placeholder={ACTIVITY_TYPE_LABEL} className="goals__form__select mb-0" classNamePrefix="mySelect" closeMenuOnSelect={true} isClearable={false}
                                        options = {activityTypeOpts}
                                        components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                        styles={{
                                            container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible", zIndex: "3" }),
                                            valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', width: '170px' }),
                                            placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10 })
                                        }} 
                                    />
                                }
                            />
                            <div className="error-text" style={{ "position": "absolute" }} >{errors?.activityType?.message}</div>
                            { errorList?.activityType && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.activityType}</div> }
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Controller
                                name="activityName"
                                control={control}
                                render={({ field }) =>
                                    <CreatableSelect name="activityName" {...field} options= {activityNameOpts} id="activityName"
                                        placeholder={ACTIVITY_NAME_LABEL} className="goals__form__select mb-0" classNamePrefix="mySelect"  closeMenuOnSelect={true} isClearable={false}
                                        components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                        styles={{
                                            container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible", zIndex: "2" }),
                                            valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', width: '170px' }),
                                            placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10 })
                                        }} 
                                    />
                                }
                            />
                            <div className="error-text" style={{ "position": "absolute" }} >{errors?.activityName?.message}</div>
                            { errorList?.activityName && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.activityName}</div> }
                        </Grid>
                        {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Controller
                                name="gradeAchieved"
                                control={control}
                                render={({ field }) =>
                                    <>
                                        <Select name="gradeAchieved" {...field} options= {gradeOpts} id="gradeAchieved"
                                            placeholder="Grade Achieved" className="goals__form__select mb-0" classNamePrefix="mySelect"
                                            closeMenuOnSelect={true} isClearable={false}
                                            components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                            styles={{
                                                container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible", zIndex: "1" }),
                                                valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px', }),
                                                placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10 })
                                            }} 
                                        />
                                        <p className="cta--text" style={{ "fontSize": "12px", "marginLeft": "20px", "marginBottom": 0 }}>Optional</p>
                                    </>
                                }
                            />
                            { errorList?.gradeAchieved && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.gradeAchieved}</div> }
                        </Grid> */}
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="input-floating-label">
                                <Controller
                                    name="description"
                                    control={control}
                                    render={({ field }) =>
                                        <ReactQuill className="textarea--primary textbox--rounded input" theme="snow" {...field} id="description"
                                            onKeyPress = {(event) => { if (!/^[a-zA-Z0-9!@#\$%\^\ \&*\)\(+._-]+$/.test(event.key)) { event.preventDefault(); } }}
                                        />
                                    }
                                />
                                <label >Specify how you can help</label>
                                <div className="d-flex justify-content-between me-1">
                                    {(errors?.description && <span className="error-text">Description only allow 750 Characters</span>) ||
                                        <p className="cta--text mb-2" style={{ "fontSize": "12px", "marginLeft": "20px", marginBottom: 0 }}>Optional</p>
                                    } 
                                    <div className="subtext"><span>{descLength?.replace(/<[^>]+>/g, '').replace(/\&nbsp;+/g, ' ')?.length || 0}</span>/750</div> 
                                </div>
                                {errorList?.description && <div className="error-text" style={{ "position": "absolute" }} >{errorList?.description}</div>}
                            </div>
                        </Grid>
                    </Grid>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="modalBtn" type="submit" form="advData" >Add</Button>
            </Modal.Footer>
        </Modal>
    );
};

const mapStateToProps = ({ commonData }) => {
    const { message, errorList, activityNames, activity, studentGrades, alertShow, customAlertMsg, customAlertSuccess } = commonData;
    return { message, errorList, activityNames, activity, studentGrades, alertShow, customAlertMsg, customAlertSuccess };
};
export default connect( mapStateToProps, { CreateActivity, clearMessage } )( withRouter(Index) );
