import React from "react"
import { Outlet } from "react-router-dom";
import { connect } from "react-redux";
import axios from 'axios';
import { getUserData } from "../utils/AuthUtils";
import {
    getUserProfile, getUserRoles, getUniversities, getProfessions, getGender, getCountry, getActivities,
    getActivity, getCourse, getStudentGrades, getCourses, getStatus, clearMessage, getChildProfile, getPhasetime,
    getInviteAdvisor, getGraduationyear
} from "../redux/actions";
import { withRouter } from "../redux/store/navigate";
import Logo from "../assets/images/logo.png"

class LoginLayout extends React.Component {
    componentDidMount() {
        this.props.clearMessage()
        if (getUserData('accessToken')) {
            axios.defaults.headers.common['Authorization'] = "Bearer " + getUserData('accessToken');
            this.props.getUserRoles();
            this.props.getUserProfile();
            // this.props.getChildProfile();
            this.props.getProfessions();
            this.props.getGender();
            this.props.getCountry();
            this.props.getActivities();
            this.props.getCourses();
            this.props.getStatus();
            this.props.getActivity();
            this.props.getCourse();
            this.props.getStudentGrades();
            this.props.getGraduationyear();
        }
    }

    getUrlFromLocalstorage() {
        console.log("searching for url");
        let url = localStorage.getItem('url')
        if (url) {
            console.log("Url Found", url);
            return url
        }
        console.log("Url not Found");
        return false
    }

    componentDidUpdate() {
        if (this.props.userProfile && this.props.userProfile &&
            this.props.userProfile.isOnboarded !== undefined &&
            this.props.userProfile.isOnboarded
        ) {
            if (this.getUrlFromLocalstorage() && !this.getUrlFromLocalstorage().includes("onboarding")) {
                this.props.navigate(this.getUrlFromLocalstorage());
            } else {
                this.props.navigate(`/${this.props.userProfile.currentRole}/dashboard`);
            }
        }
    }
    render() {
        return (
            <>
                <div>
                    <img src={Logo} alt="user" style={{ paddingTop: '1rem', paddingLeft:'2rem', position:'absolute' }} />
                </div>
                <div className="grandParentContaniner">
                    <div className="parentContainer">
                        {this.props.userProfile && <Outlet />}
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = ({ userProfileData }) => {
    const { isUserOnboarded, userProfile } = userProfileData
    return { isUserOnboarded, userProfile }
};

export default connect(mapStateToProps, {
    getChildProfile, getUserProfile, getUserRoles, getUniversities, getProfessions,
    getActivity, getCourse, getStudentGrades, getGender, getCountry, getCourses, getActivities, getStatus, getPhasetime, clearMessage, getInviteAdvisor, getGraduationyear
})(withRouter(LoginLayout));