import React, { Component } from "react";
import SearchandFilter from "../../../../components/SearchandFilter";
import TableList from "../../../../components/TableViews/AdvisorLibTable"
import Avatar1 from "../../../../assets/images/icons/Avatar1.png";
import Avatar2 from "../../../../assets/images/icons/Avatar2.png";
import "../../../../assets/css/network.less";



class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const AvatarIconData = [
            {
                AvatarIcon: Avatar1,
                AvatarIconAlt: "Avatar1-icon",
            },
            {
                AvatarIcon: Avatar2,
                AvatarIconAlt: "Avatar2-icon",
            },
        ];
        let { ArchivedData } = this.props;
        const topHeader =
        {
            header1: "Name",
            header2: "LastUpdated",
            header3: "Description",
            header4: "Shared by",
            header5: "Shared with",
        }

        return (
            <>
               <SearchandFilter />
                <TableList
                 TableData={ArchivedData}
                 TableHeader={topHeader} 
                 AvatarIconData={AvatarIconData} 
                 Archived={true}/>
            </>
        );
    }
}
export default Index;
