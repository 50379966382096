import React from "react";
import {useSelector} from "react-redux";
import {Outlet} from "react-router-dom";
import {NewSidebar} from "../../layouts/Block/NewSidebar";
import ChatBox from "../../components/ChatBox";

export const DefaultLayout = (props) => {
  const userProfile = useSelector((state) => state.userProfileData.userProfile);
  const additionalStyle = {};
  if (props.compact) {
    additionalStyle.padding = "0px";
  }
  return (
      <div id="layoutSidenav">
        {console.log("userProfile", userProfile)}
        <NewSidebar/>
        <div className="center-wrap-default" style={additionalStyle}>
          {userProfile && <Outlet/>}
        </div>
        {!props.compact && <ChatBox/>}
      </div>
  );
};


