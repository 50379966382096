import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import {getUserProfile, manageProfileServices, setManageProfileServicesStatus} from "../../redux/actions";
import Loader from "../../components/Loader";

const Index = (props) => {
    const [loading, setLoading] = useState(false);
    const [openServiceModel, setOpenServiceModel] = useState(false)
    const [confirmServiceModel, setConfirmServiceModel] = useState(false)
    const [activecontract, setActiveContract] = useState(true)

    useEffect(() => {
        if (props.manageServiceStatus) {
            setLoading(false);
            props?.getUserProfile()
            props?.setManageProfileServicesStatus();
        }else{
            setLoading(false);
        }
    }, [props.manageServiceStatus])
    
    const showModel = (e, field) => {
        if ( field == 'service'){
            setOpenServiceModel( !openServiceModel )
        } else if ( field == 'confirmService') {
            setConfirmServiceModel( !confirmServiceModel )
        }
    }

	const convertTimeFormat = (timeStr) => {
		let parts = timeStr.split(':');
		let newTime = parts.slice(0, 2).join(':');
		return newTime;
	}

    const handleTurnOnServices = () => {
		let {mondayTimesheet, tuesdayTimesheet, wednesdayTimesheet, thursdayTimesheet, fridayTimesheet, saturdayTimesheet, sundayTimesheet} = props?.userProfile?.advisorProfile?.advisorWorkingHours;

        let payload = {
            isServicesEnabled: !props?.userProfile?.advisorProfile?.isServicesEnabled
        }
        payload.mondayAvailableTimes = mondayTimesheet?.map(date => {return {startTime: convertTimeFormat(date?.startingTime),endTime:convertTimeFormat(date?.closingTime)}})
		payload.tuesdayAvailableTimes = tuesdayTimesheet?.map(date => {return {startTime: convertTimeFormat(date?.startingTime),endTime:convertTimeFormat(date?.closingTime)}})
		payload.wednesdayAvailableTimes = wednesdayTimesheet?.map(date => {return {startTime: convertTimeFormat(date?.startingTime),endTime:convertTimeFormat(date?.closingTime)}})
		payload.thursdayAvailableTimes = thursdayTimesheet?.map(date => {return {startTime: convertTimeFormat(date?.startingTime),endTime:convertTimeFormat(date?.closingTime)}})
		payload.fridayAvailableTimes = fridayTimesheet?.map(date => {return {startTime: convertTimeFormat(date?.startingTime),endTime:convertTimeFormat(date?.closingTime)}})
		payload.saturdayAvailableTimes = saturdayTimesheet?.map(date => {return {startTime: convertTimeFormat(date?.startingTime),endTime:convertTimeFormat(date?.closingTime)}})
		payload.sundayAvailableTimes = sundayTimesheet?.map(date => {return {startTime: convertTimeFormat(date?.startingTime),endTime:convertTimeFormat(date?.closingTime)}})

        props?.manageProfileServices(payload);
        setLoading(true);
    }

    return (
        <>
            {loading && <Loader />}
            <div className="Servicecnt">
                <div className="Servicecnt__servicetop">
                    <p>Services</p>
                </div>
                <div className="Servicecnt__servicemid">
                    <p>
                        Services are now <span>{props?.userProfile?.advisorProfile?.isServicesEnabled ? 'ON' : 'OFF'}</span>
                    </p>
                </div>
                <div className="Servicecnt__serviceEnd" style={{maxWidth: '350px'}}>
                    <div
                        className="servicebtn"
                        // onClick={(e) => showModel(e, "service")}.
                        onClick={() => handleTurnOnServices()}
                    >
                        <p>Turn services {!props?.userProfile?.advisorProfile?.isServicesEnabled ? 'ON' : 'OFF'}</p>
                    </div>
                </div>
                {/* Turning service on off modal */}
                <Modal
                    size={"md"}
                    show={ openServiceModel }
                    onHide={(e) => showModel(e, "service")}
                    centered={true}
                    dialogclassName={"mywork-popup"}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <p style={{ fontSize: "15px" }}>
                                Do you want to specify return date?
                            </p>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="returntop">
                            <div className="row">
                                <div
                                    className="col-1"
                                    style={{ lineHeight: "40px" }}
                                >
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect 
                                            x="5" y="6.55859"
                                            width="13.7846" height="12.8"
                                            rx="1.5" stroke="#919293"
                                            strokeWidth="2" strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M18.7839 10.8267H4.99928"
                                            stroke="#919293" strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M8.44613 4V5.70667"
                                            stroke="#919293"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M15.3385 4V5.70667"
                                            stroke="#919293"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </div>
                                <div className="col-5">
                                    <div className="input-floating-label">
                                        <input
                                            refs="monStartTime" type="text"
                                            className= "textbox--primary textbox--rounded input" placeholder=" "
                                            onFocus={(e) => (e.target.type = "date") }
                                            onBlur={(e) => this.handleBlur(e)}
                                        />
                                        <label>Select date</label>
                                    </div>
                                </div>
                                <div className="col-1" style={{ lineHeight: "40px" }} >
                                    <svg
                                        width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z" fill="#919293" />
                                        <path d="M16.0945 14.9672L12.7523 12.5508V6.75C12.7523 6.64687 12.668 6.5625 12.5648 6.5625H11.4375C11.3344 6.5625 11.25 6.64687 11.25 6.75V13.2047C11.25 13.2656 11.2781 13.3219 11.3273 13.357L15.2039 16.1836C15.2883 16.2445 15.4055 16.2258 15.4664 16.1437L16.1367 15.2297C16.1976 15.1429 16.1789 15.0258 16.0945 14.9672Z" fill="#919293" />
                                    </svg>
                                </div>
                                <div className="col-5">
                                    <div className="input-floating-label">
                                        <input refs="monEndTime" type="text" className={
                                                "textbox--primary textbox--rounded input"
                                            }
                                            placeholder=" " onFocus={(e) => (e.target.type = "time") }
                                            onBlur={(e) => this.handleBlur(e)}
                                        />
                                        <label>Select time</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="returnbtm">
                            <div className="row">
                                <div className="col-1 col-sm-1 col-md-3 col-lg-1"></div>
                                <div className="modalbtmcnt col-3 col-sm-3 col-md-4 col-lg-6"
                                    onClick={(e) => showModel(e, "confirmService")}
                                    style={{
                                        cursor: "pointer",
                                        lineHeight: "33px",
                                        fontWeight: "500",
                                    }}
                                >
                                    Skip
                                </div>
                                <div className="modalbtmcnt col-8 col-sm-8 col-md-5 col-lg-5">
                                    <Button 
                                        onClick={(e) => {
                                            showModel(e, "service");
                                            showModel(e, "confirmService");
                                        }}
                                        className="btn cta--rounded cta-primary"
                                    >
                                        Turn services OFF
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Turning service on off confirming modal */}
                <Modal
                    size={"md"}
                    show={ confirmServiceModel }
                    onHide={(e) => showModel(e, "confirmService")}
                    centered={true}
                    dialogclassName={"mywork-popup"}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {activecontract === false ? (
                                <p style={{ fontSize: "16px" }}>
                                    Turning off services?
                                </p>
                            ) : (
                                <p style={{ fontSize: "16px" }}>Oooops!</p>
                            )}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="returntop">
                            {activecontract === false ? (
                                <p>
                                    If you turn services off now, you will be
                                    removed from the search and will not be able to
                                    accept new students requests till 29 March 2022.
                                </p>
                            ) : (
                                <p>
                                    We can not turn your services off due to active
                                    contracts.
                                </p>
                            )}
                        </div>
                        <br />
                        <div className="returnbtm">
                            {activecontract === false ? (
                                <div className="row">
                                    <div className="col-1 col-sm-1 col-md-1"></div>
                                    <div
                                        onClick={(e) => showModel(e, "confirmService")}
                                        className="modalbtmcnt col-7 col-sm-7 col-md-8"
                                        style={{
                                            cursor: "pointer",
                                            lineHeight: "33px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Cancel
                                    </div>
                                    <div
                                        onClick={(e) => showModel(e, "confirmService")}
                                        className="modalbtmcnt col-4 col-sm-4 col-md-3"
                                    >
                                        <Button className="btn cta--rounded cta-primary">
                                            Confirm
                                        </Button>
                                    </div>
                                </div>
                            ) : (
                                <div className="">
                                    <div className="row">
                                        <div className="col-1 col-sm-1 col-md-1"></div>
                                        <div
                                            onClick={(e) => showModel(e, "confirmService")}
                                            className="modalbtmcnt col-7 col-sm-7 col-md-8"
                                        >
                                            <p>Go to Contracts</p>
                                        </div>
                                        <div className="modalbtmcnt col-4 col-sm-4 col-md-3">
                                            <Button
                                                onClick={(e) =>
                                                    showModel(e, "confirmService")
                                                }
                                                className="btn cta--rounded cta-primary"
                                            >
                                                Got it
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
};

const mapStateToProps = ({ userProfileData, advisorData }) => {
    const { userProfile } = userProfileData;
    const {manageServiceStatus} = advisorData;
    return { userProfile, manageServiceStatus };
  };
  export default connect(mapStateToProps, { getUserProfile, manageProfileServices, setManageProfileServicesStatus })(Index);
