import React, { Component } from "react";
import Alert from 'react-bootstrap/Alert';
import { connect } from "react-redux";
import { CUSTOM_ALERT_CLOSE } from "../../redux/constants/CommonTypes";
import { withRouter } from "../../redux/store/navigate";

class CustomAlert extends Component {
    constructor(props) {
        super(props);

    }
    render() { 
        {console.log(this.props.customAlertShow, "this.props.customAlertShow")}
        return ( 
          
            this.props.customAlertShow &&

            <Alert 
                {...this.props}
                style = {{ 
                    position: 'fixed', 
                    zIndex: '999', 
                }}

                variant={this.props.customAlertSuccess ? 'success' : 'danger'}
                onClose={this.props.customAlertClose}
                dismissible
            >
                {this.props.customAlertMsg}
            </Alert>
         );
    }
}
 
const mapStateToProps = ({ auth, commonData }) => {
    const { customAlertMsg, customAlertShow, customAlertSuccess } = commonData
    return { customAlertMsg , customAlertShow, customAlertSuccess}
};
const mapDispatchToProps = (dispatch) => {
    return({
        customAlertClose: () => {dispatch({type:CUSTOM_ALERT_CLOSE})}
    })
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomAlert));
