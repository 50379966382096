import React, { Component } from "react";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../../../assets/css/studentdashboard.less';
import Search from "../../../components/Search";
import TwoUserIcons from "../../../components/TwoUserIcons";
import CircleAddCta from "../../../components/CircleAddCta";
import GetAdvisors from "../../../components/GetAdvisors";
import RecommendedFriends from "../../../components/RecommendedFriends";
import DocumentsList from "../../../components/DocumentsList";
import ModalPopup from "../../../components/ModalPopup";
import Avatar1 from "../../../assets/images/icons/Avatar1.png"
import Avatar2 from "../../../assets/images/icons/Avatar2.png"
import { userSignIn, getUserProfile } from "../../../redux/actions";
import { connect } from "react-redux";
import { withRouter } from "../../../redux/store/navigate";




class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            show: false,
            setDate: "",
            date: "",
            isActive: false
        };
    }
    componentDidMount() {
        const message = this.props.message;
        const errorList = this.props.errorList;
        this.props.getUserProfile();
    }
    handleShow(e) {
        e.preventDefault()
        this.setState({ show: true })
    }
    setShow(isshow) {
        console.log(isshow)
        this.setState({ show: isshow })
    }
    CircleAddCtaAction() {
        console.log("click Circle Add Cta btn")
    }
    handleClickMobilesearch(e) {
        e.preventDefault();
        const currentState = this.state.isActive;
        this.setState({ isActive: !currentState });
    }
    render() {
        let { isActive } = this.state;
        const ModalPopupData = [
            {
                'popuptitle': 'Modal Title here..',
                'popupbodycontent': 'Modal body content here...',
            }
        ];

        const AvatarIconData = [
            {
                'AvatarIcon': Avatar1,
                'AvatarIconAlt': 'Avatar1-icon'
            },
            {
                'AvatarIcon': Avatar2,
                'AvatarIconAlt': 'Avatar2-icon'
            }
        ];

        const GetAdvisorsData = [
            {
                'AvatarIcon': Avatar1,
                'AvatarIconAlt': 'Avatar1-icon',
                'GetAdvisorsTitle': "Joshua Ashiru",
                'GetAdvisorsRating': "3"
            },
            {
                'AvatarIcon': Avatar2,
                'AvatarIconAlt': 'Avatar2-icon',
                'GetAdvisorsTitle': "Joshua Ashiru",
                'GetAdvisorsRating': "4"
            },
            {
                'AvatarIcon': Avatar1,
                'AvatarIconAlt': 'Avatar1-icon',
                'GetAdvisorsTitle': "Joshua Ashiru",
                'GetAdvisorsRating': "4"
            },
            {
                'AvatarIcon': Avatar2,
                'AvatarIconAlt': 'Avatar2-icon',
                'GetAdvisorsTitle': "Joshua Ashiru",
                'GetAdvisorsRating': "4"
            },
            {
                'AvatarIcon': Avatar1,
                'AvatarIconAlt': 'Avatar1-icon',
                'GetAdvisorsTitle': "Joshua Ashiru",
                'GetAdvisorsRating': "4"
            },
            {
                'AvatarIcon': Avatar2,
                'AvatarIconAlt': 'Avatar2-icon',
                'GetAdvisorsTitle': "Joshua Ashiru",
                'GetAdvisorsRating': "4"
            }
        ];

        const RecommendedFriendsData = [
            {
                'AvatarIcon': Avatar1,
                'AvatarIconAlt': 'Avatar1-icon',
                'RecommendedFriendsTitle': "Joshua Ashiru",
                'RecommendedFriendsSubTitle': "Went to same school as you"
            },
            {
                'AvatarIcon': Avatar2,
                'AvatarIconAlt': 'Avatar2-icon',
                'RecommendedFriendsTitle': "Joshua Ashiru",
                'RecommendedFriendsSubTitle': "Went to same school as you"
            },
            {
                'AvatarIcon': Avatar1,
                'AvatarIconAlt': 'Avatar1-icon',
                'RecommendedFriendsTitle': "Joshua Ashiru",
                'RecommendedFriendsSubTitle': "Went to same school as you"
            }
        ];

        const DocumentsListData = [
            {
                'DocumentName': "Cambridge.doc",
                'DocumentExtension': 'doc',
                'DocumentDate': "1 October, 2021",
                'DocumentTime': "12:38"
            },
            {
                'DocumentName': "Cambridge.doc",
                'DocumentExtension': 'doc',
                'DocumentDate': "1 October, 2021",
                'DocumentTime': "12:38"
            },
            {
                'DocumentName': "Cambridge.zip",
                'DocumentExtension': 'zip',
                'DocumentDate': "1 October, 2021",
                'DocumentTime': "12:38"
            }
        ];

        const DocumentsModalPopupData = [
            {
                'popuptitle': 'All documents',
                'popupbodycontent': 'Modal body content here...',
                'DocumentsModalData': [
                    {
                        'DocumentName': "Cambridge.zip",
                        'DocumentExtension': 'zip',
                        'DocumentDate': "1 October, 2021",
                        'DocumentTime': "12:38",
                        'LastUpdated': '13 hours ago',
                        'Description': 'This is the document you should use for the ',
                        'fileSize': '2.3 MB'
                    },
                    {
                        'DocumentName': "Cambridge.doc",
                        'DocumentExtension': 'doc',
                        'DocumentDate': "1 October, 2021",
                        'DocumentTime': "12:38",
                        'LastUpdated': '13 hours ago',
                        'Description': 'This is the document you should use for the ',
                        'fileSize': '2.3 MB'
                    },
                    {
                        'DocumentName': "Cambridge.doc",
                        'DocumentExtension': 'doc',
                        'DocumentDate': "1 October, 2021",
                        'DocumentTime': "12:38",
                        'LastUpdated': '13 hours ago',
                        'Description': 'This is the document you should use for the ',
                        'fileSize': '2.3 MB'
                    },
                    {
                        'DocumentName': "Cambridge.doc",
                        'DocumentExtension': 'doc',
                        'DocumentDate': "1 October, 2021",
                        'DocumentTime': "12:38",
                        'LastUpdated': '13 hours ago',
                        'Description': 'This is the document you should use for the ',
                        'fileSize': '2.3 MB'
                    },
                    {
                        'DocumentName': "Cambridge.doc",
                        'DocumentExtension': 'doc',
                        'DocumentDate': "1 October, 2021",
                        'DocumentTime': "12:38",
                        'LastUpdated': '13 hours ago',
                        'Description': 'This is the document you should use for the ',
                        'fileSize': '2.3 MB'
                    },
                    {
                        'DocumentName': "Cambridge.doc",
                        'DocumentExtension': 'doc',
                        'DocumentDate': "1 October, 2021",
                        'DocumentTime': "12:38",
                        'LastUpdated': '13 hours ago',
                        'Description': 'This is the document you should use for the ',
                        'fileSize': '2.3 MB'
                    },
                    {
                        'DocumentName': "Cambridge.doc",
                        'DocumentExtension': 'doc',
                        'DocumentDate': "1 October, 2021",
                        'DocumentTime': "12:38",
                        'LastUpdated': '13 hours ago',
                        'Description': 'This is the document you should use for the ',
                        'fileSize': '2.3 MB'
                    },
                    {
                        'DocumentName': "Cambridge.doc",
                        'DocumentExtension': 'doc',
                        'DocumentDate': "1 October, 2021",
                        'DocumentTime': "12:38",
                        'LastUpdated': '13 hours ago',
                        'Description': 'This is the document you should use for the ',
                        'fileSize': '2.3 MB'
                    },
                    {
                        'DocumentName': "Cambridge.doc",
                        'DocumentExtension': 'doc',
                        'DocumentDate': "1 October, 2021",
                        'DocumentTime': "12:38",
                        'LastUpdated': '13 hours ago',
                        'Description': 'This is the document you should use for the ',
                        'fileSize': '2.3 MB'
                    },
                    {
                        'DocumentName': "Cambridge.doc",
                        'DocumentExtension': 'doc',
                        'DocumentDate': "1 October, 2021",
                        'DocumentTime': "12:38",
                        'LastUpdated': '13 hours ago',
                        'Description': 'This is the document you should use for the ',
                        'fileSize': '2.3 MB'
                    },
                    {
                        'DocumentName': "Cambridge.doc",
                        'DocumentExtension': 'doc',
                        'DocumentDate': "1 October, 2021",
                        'DocumentTime': "12:38",
                        'LastUpdated': '13 hours ago',
                        'Description': 'This is the document you should use for the ',
                        'fileSize': '2.3 MB'
                    },
                    {
                        'DocumentName': "Cambridge.doc",
                        'DocumentExtension': 'doc',
                        'DocumentDate': "1 October, 2021",
                        'DocumentTime': "12:38",
                        'LastUpdated': '13 hours ago',
                        'Description': 'This is the document you should use for the ',
                        'fileSize': '2.3 MB'
                    },
                    {
                        'DocumentName': "Cambridge.doc",
                        'DocumentExtension': 'doc',
                        'DocumentDate': "1 October, 2021",
                        'DocumentTime': "12:38",
                        'LastUpdated': '13 hours ago',
                        'Description': 'This is the document you should use for the ',
                        'fileSize': '2.3 MB'
                    },
                    {
                        'DocumentName': "Cambridge.doc",
                        'DocumentExtension': 'doc',
                        'DocumentDate': "1 October, 2021",
                        'DocumentTime': "12:38",
                        'LastUpdated': '13 hours ago',
                        'Description': 'This is the document you should use for the ',
                        'fileSize': '2.3 MB'
                    },
                    {
                        'DocumentName': "Cambridge.doc",
                        'DocumentExtension': 'doc',
                        'DocumentDate': "1 October, 2021",
                        'DocumentTime': "12:38",
                        'LastUpdated': '13 hours ago',
                        'Description': 'This is the document you should use for the ',
                        'fileSize': '2.3 MB'
                    },
                    {
                        'DocumentName': "Cambridge.zip",
                        'DocumentExtension': 'zip',
                        'DocumentDate': "1 October, 2021",
                        'DocumentTime': "12:38",
                        'LastUpdated': '13 hours ago',
                        'Description': 'This is the document you should use for the ',
                        'fileSize': '2.3 MB'
                    }
                ]
            }
        ];

        return (
            <>
                <div className="dashboardtop">
                    <div className="row">
                        <div className="col-md-8 col-sm-11 col-10">
                           
                            <div className="dashboard-top-text">
                                <h3>Hi,{this.props.userProfile && this.props.userProfile.profileInfo && this.props.userProfile.profileInfo.firstName} </h3>
                                {/* <p className="p-0 m-0"><span>It’s good to see you again.</span></p> */}
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-1 col-1 Search-content">
                            <Search placeholder="Search" />
                            <div className="mobile-search">
                                <div class="mobile-search-content">
                                    <a href="" onClick={(e) => this.handleClickMobilesearch(e)} class="mobile-search-button">
                                        <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.5046 3.22982C11.3749 0.183809 6.28112 0.183809 3.15144 3.22982C0.0224364 6.2765 0.0224364 11.2335 3.15144 14.2801C5.93851 16.992 10.2792 17.2826 13.4069 15.1649C13.4727 15.468 13.6233 15.7572 13.8655 15.993L18.4233 20.4289C19.0875 21.0741 20.1608 21.0741 20.8216 20.4289C21.4852 19.7832 21.4852 18.7385 20.8216 18.0947L16.2638 13.6575C16.023 13.4237 15.7251 13.2765 15.4137 13.2124C17.5909 10.1677 17.2923 5.9437 14.5046 3.22982ZM13.0656 12.8796C10.729 15.1537 6.92633 15.1537 4.59043 12.8796C2.25521 10.6055 2.25521 6.90511 4.59043 4.631C6.92633 2.35755 10.729 2.35755 13.0656 4.631C15.4022 6.90511 15.4022 10.6055 13.0656 12.8796Z" fill="#1C84EE" />
                                        </svg>
                                    </a>
                                    {isActive && <input type="text" class="mobile-search-input" placeholder="Search here..." />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-wrap">
                    <div className="row m-0 mt-4">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 p-0">
                            <div className="row m-0 mb-4">
                                <div className="col-sm-12 p-0">
                                    <div className="main-content__center">
                                        <h5>Goals <a href="" onClick={(e) => this.handleShow(e)} className="main-content__cta cta--underline">See all</a>
                                            {this.state.show &&
                                                <ModalPopup
                                                    size="lg"
                                                    dialogClassName=""
                                                    fullscreen={true}
                                                    centered={false}
                                                    show={this.state.show}
                                                    ModalPopupData={ModalPopupData}
                                                    onsetShow={(isshow) => this.setShow(isshow)}
                                                />
                                            }
                                        </h5>
                                        <div className="contents">
                                            <div className="row m-0">
                                                <div className="col-10 col-sm-11 col-md-11 ps-0 d-flex">
                                                    <div className="Two-Avatar-icon">
                                                        <TwoUserIcons
                                                            AvatarIconData={AvatarIconData}
                                                        />
                                                    </div>
                                                    <div className="contents__text">
                                                        <h2 className="contents__text__title">Joshua Ashiru</h2>
                                                        <p className="contents__text__sub-title">2021-2022</p>
                                                    </div>
                                                </div>
                                                <div className="col-2 col-sm-1 col-md-1 text-end">
                                                    <CircleAddCta
                                                        CircleAddCtaData={true}
                                                        CircleAddCtaAction={this.CircleAddCtaAction}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row m-0 mb-4">
                                <div className="col-lg-7 col-md-8 col-sm-12 ps-0 width-100 p-right-0 mb-4">
                                    <GetAdvisors
                                        GetAdvisorsTitle={'Need help in creating a plan?'}
                                        GetAdvisorsSubTitle={'Get an advisor to create it for you!'}
                                        GetAdvisorsData={GetAdvisorsData}
                                    />
                                </div>
                                <div className="col-lg-5 col-md-4 col-sm-12 pe-0 width-100 p-left-0">
                                    <div className="row">
                                        <div className="col-md-12 col-sm-6 col-12 width-50">
                                            <div className="RecommendedFriends mb-4">
                                                <RecommendedFriends
                                                    RecommendedFriendsTitle={'Recommended Friends'}
                                                    RecommendedFriendsSubTitle={''}
                                                    RecommendedFriendsData={RecommendedFriendsData}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-sm-6 col-12 width-50">
                                            <div className="DocumentsList mb-4">
                                                <DocumentsList
                                                    DocumentsListTitle={'Documents'}
                                                    DocumentsListSubTitle={''}
                                                    DocumentsListData={DocumentsListData}
                                                    DocumentsModalPopupData={DocumentsModalPopupData}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right-wrap">
                    <div className="minicnt">
                        <Calendar onChange={this.setDate} value={this.date} prev2Label="Schedule" />
                    </div>
                    <div className="upcomecnt">
                        <h5>Upcoming Sessions</h5>
                        <div className="upcomecnt_list">
                            <div className="upcomecnt_list_item">
                                <div class="upcomecnt_list_item-card">
                                    <div class="additional">
                                        <div class="user-card">
                                            <div class="upcomecnt_list_item_count">
                                                8
                                            </div>
                                        </div>
                                    </div>
                                    <div class="general">
                                        <h6>Life Contingency Tutorials</h6>
                                        <p>
                                            8th - 10th July 2021
                                            <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="3" cy="3" r="3" fill="#888888" />
                                            </svg>
                                            8 A.M - 9 A.M
                                        </p>
                                        <p>Edulog Tutorial College, Blk 56, Lagos State.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="upcomecnt_list_item">
                                <div class="upcomecnt_list_item-card">
                                    <div class="additional">
                                        <div class="user-card">
                                            <div class="upcomecnt_list_item_count">
                                                8
                                            </div>
                                        </div>
                                    </div>
                                    <div class="general">
                                        <h6>Life Contingency Tutorials</h6>
                                        <p>
                                            8th - 10th July 2021
                                            <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="3" cy="3" r="3" fill="#888888" />
                                            </svg>
                                            8 A.M - 9 A.M
                                        </p>
                                        <p>Edulog Tutorial College, Blk 56, Lagos State.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="upcomecnt_list_item">
                                <div class="upcomecnt_list_item-card">
                                    <div class="additional">
                                        <div class="user-card">
                                            <div class="upcomecnt_list_item_count">
                                                18
                                            </div>
                                        </div>
                                    </div>
                                    <div class="general">
                                        <h6>Life Contingency Tutorials</h6>
                                        <p>
                                            18th - 10th July 2021
                                            <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="3" cy="3" r="3" fill="#888888" />
                                            </svg>
                                            8 A.M - 9 A.M
                                        </p>
                                        <p>Edulog Tutorial College, Blk 56, Lagos State.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="upcomecnt_list_item">
                                <div class="upcomecnt_list_item-card">
                                    <div class="additional">
                                        <div class="user-card">
                                            <div class="upcomecnt_list_item_count">
                                                8
                                            </div>
                                        </div>
                                    </div>
                                    <div class="general">
                                        <h6>Life Contingency Tutorials</h6>
                                        <p>
                                            8th - 10th July 2021
                                            <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="3" cy="3" r="3" fill="#888888" />
                                            </svg>
                                            8 A.M - 9 A.M
                                        </p>
                                        <p>Edulog Tutorial College, Blk 56, Lagos State.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="upcomecnt_list_item">
                                <div class="upcomecnt_list_item-card">
                                    <div class="additional">
                                        <div class="user-card">
                                            <div class="upcomecnt_list_item_count">
                                                18
                                            </div>
                                        </div>
                                    </div>
                                    <div class="general">
                                        <h6>Life Contingency Tutorials</h6>
                                        <p>
                                            18th - 10th July 2021
                                            <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="3" cy="3" r="3" fill="#888888" />
                                            </svg>
                                            8 A.M - 9 A.M
                                        </p>
                                        <p>Edulog Tutoxrial College, Blk 56, Lagos State.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
const mapStateToProps = ({ auth, userProfileData, commonData }) => {
    const { message, errorList } = commonData
    const { userProfile } = userProfileData;
    return { message, errorList, userProfile }
};

export default connect(mapStateToProps, { userSignIn, getUserProfile })(withRouter(Index));