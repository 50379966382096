import React, { Component } from "react";
import Search from "../../components/Search";
import FilterList from "../../components/FilterList";
class Index  extends Component {
    constructor(props) {
        super(props);
    }
    state = {  }
    render() { 
        return ( 
            <>
            <div className="library">
                    <div className="library__search">
                        <div className="row">
                            <div className="col-6 col-sm-8 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-4 ">
                                <Search placeholder="Search" />
                            </div>
                            <div className="col-1 col-sm-1 col-md-5 col-lg-6 col-xl-6 col-xxl-6  mb-4 text-rights"> </div>
                            <div className="col-5 col-sm-3 col-md-3 col-lg-2 col-xl-2 col-xxl-2  mb-4 ">
                                <div className="library__filter">
                                    <FilterList />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </>
         );
    }
}
 
export default Index ;