import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
    getUserGoals,sendRespondToRequest,setSendRespondToRequestStatus, CreateParentPlan, CreatePlanStatus, GetStudentPlan, GetStudentPlanPhase, clearMessage, getConnectedAdvisors, getConnectedAdvisorsParents,
    getAdvisorRecommendedstatus, getAdvisorRecommended, ClearPlanId, getProfessions, EditPlan, getFreeSessionStatus, resetReview, sendRespondToParentRequest, respondToParentRequestStatus,
    EditPlanStatus, GetLoadPhase, userConnect, userConnectStatus, userConnectErrorStatus, disconnectUser, CancelRequest, CancelRequestStatus, getAdvisorSchedule,
    getDetailedUserProfile, settDetailedUserProfileStatus, loadAdvisorReview, getStudentPlanDetails, disconnectUserStatus, getFreeSessionRemaining, getStudentDetail, SubScribeAdvisor, SubScribeAdvisorStatus, SubScribeAdvisorErrorStatus, bookSession, bookPaidSession, bookPaidSessionStatus, bookPaidSessionErrorStatus, bookSessionStatus
} from "../../../redux/actions";
import CloseIcon from '@mui/icons-material/Close';
import { connect } from "react-redux";
import Review from "./Review"
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import WorkingHours from './WorkingHours'
import "./advisorProfile.less";
import PersonIcon from '@mui/icons-material/Person';
import SelectStudent from "../../../components/studentSelectionPopup";
import CustomAlert from "../../../components/CustomAlert";
import Loader from "../../../components/Loader"
import PillSection from "./minipills"
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import CourseCard from "../Cards/Common/CourseCard"
import ActivityCard from "../Cards/Common/ActivityCard"
import Rating from '@mui/material/Rating';
import PlanAccessModal from "../../../componentsNew/Common/PlanAccessModal";
import ConfirmationPopup from "../../../componentsNew/Common/ConfirmationPopup";
import Alert from 'react-bootstrap/Alert';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import BookSessionModal from "../../../pages/Student/MyNetwork/BookSessionModal";
import { useImageUrl } from '../../../utils/UseImageURL';
import { convertUTCTodateTimeZone, convertUtcToEventTime } from "../../../utils/CommonUtils";
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import dayjs from "dayjs";

const styles = {
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'relative',
        backgroundColor: '#f4f5f8',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
        padding: '0',
        borderRadius: '4px',
        width: '100%',
        height: "100%",
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        overflow: 'auto',
        padding: '20px 60px',
        flexGrow: 1,
    },
};

const Index = (props) => {
    const navigate = useNavigate();
    const currentProtocol = window.location.protocol;
    const currentHost = window.location.host;
    const baseURL = `${currentProtocol}//${currentHost}`;
    let userAdvisorId = props?.data?.userInfo?.id || props.data?.requestInfo?.userId || props?.data?.id
 
    const Network = sessionStorage?.getItem("netvalue")

    const [connect, setConnect] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openStudent, setOpenStudent] = useState(false);
    const [viewMore, setViewMore] = useState(false)
    const buttonRef = useRef(null);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [showAccessModal, setShowAccessModal] = useState(false);
    const [showDisconnectModal, setShowDisconnectModal] = useState(false);
    const [disconnectUserData, setDisconnectUserData] = useState({});
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [advisorTime, setAdvisorTime] = useState([]);
    const [rejectData, setRejectData] = useState({});
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [descriptions, setDescription] = useState(null);
    const [bookshow, setbookshow] = useState(false);
    const [advisorName, setAdvisorName] = useState("");
    const [TimeSession, setTimeSession] = useState([]);
    const [advisorData, setAdvisorData] = useState();
    const [advisorid, setAdvisorid] = useState("");
    const [conformsubscribe, setConformsubscribe] = useState(false);
    const [subscribedata, setSubscribedata] = useState();
    const [openConfirmation, setopenConfirmation] = useState(false)
    const [confirmationData,setConfirmationData] = useState({});
    const [rejectConfirmation, setrejectConfirmation] = useState(false)
    const [rejectConfirmationData, setrejectConfirmationData] = useState({})
    const [userProfileUrl, setUserProfileUrl] = useState(`${baseURL}/userProfile/${userAdvisorId}`);
    const [userdetails, setuserdetails] = useState([]);
    const [link, setLink] = useState('')
    const [alertVisible, setalertVisible] = useState(false)
    const [ShowAlert, setShowAlert] = useState(false)
    const [splitedTime, setSplitedTime] = useState([]);

    const open = Boolean(anchorEl);
    var Netvalue = sessionStorage.getItem('netvalue');
   

    const isMyNetworkPage = window.location.pathname === '/student/mynetwork' || window.location.pathname === '/parent/mynetwork';
    const isExploreAdvisor = window.location.pathname === '/student/mynetwork/exploreAdvisor' || window.location.pathname === '/parent/mynetwork/exploreAdvisor';

    const isOnboarding = window?.location?.href?.includes('onboarding');

    const handleSelectStudentOpen = () => setOpenStudent(true);
    const handleSelectStudentClose = () => setOpenStudent(false);

    const handleConnectOpen = () => {
        setConnect(true);
        if (Network === "2") {
            let value = { "searchString": "", "pageNo": 1, "limit": 40, "status": [], "sortOrder": "latest", "studentId": props?.userProfile?.currentRole === "student" ? props?.userProfile?.id : props?.userProfile?.isOnboarded === true ? localStorage.getItem("parentViaChild") : props?.childprofile && props?.childprofile[0]?.id }
            props?.GetStudentPlan(value)
        }
        if (isExploreAdvisor) {
            let value = { "searchString": "", "pageNo": 1, "limit": 40, "status": [], "sortOrder": "latest", "studentId": props?.userProfile?.currentRole === "student" ? props?.userProfile?.id : props?.userProfile?.isOnboarded === true ? localStorage.getItem("parentViaChild") : props?.childprofile && props?.childprofile[0]?.id }
            props?.GetStudentPlan(value)
            let payload = {
                userId: props?.userProfile?.id,
                pointOfViewStudentId: props?.userProfile?.isOnboarded === true ? localStorage.getItem("parentViaChild") : props?.childprofile && props?.childprofile[0]?.id
            }
            props.getStudentDetail(payload);
        }
    }
    const handleConnectClose = () => {
        setConnect(false);
    }

    let { userInfo, userInfo: { advisorProfile } = {} } = props?.data;
    let { profileData, detailedUserData, detailedUserData: { isConnectedWithUser, recentRequest } = {} } = props;

    let { studentProfile } = userInfo || {};
    let buttonState;
    if (props?.userProfile?.currentRole === "parent") {
        if (props?.userProfile?.isOnboarded === false) {
            if (!isConnectedWithUser && recentRequest?.status === "pending") {
                if (props?.childprofile && props?.childprofile[0]?.id === recentRequest?.userId) buttonState = "PENDING";
                else if (props?.childprofile && props?.childprofile[0]?.id === recentRequest?.targetUserId) buttonState = "RECIEVED_REQUEST";
            } else if (!isConnectedWithUser && !recentRequest) buttonState = "CONNECT";

        } else {
            if (!isConnectedWithUser && recentRequest?.status === "pending") {
                if (localStorage.getItem("parentViaChild") === recentRequest?.userId) buttonState = "PENDING";
                else if (localStorage.getItem("parentViaChild") === recentRequest?.targetUserId) buttonState = "RECIEVED_REQUEST";
            } else if (!isConnectedWithUser && !recentRequest) buttonState = "CONNECT";

        }

    } else {
        if (!isConnectedWithUser && recentRequest?.status === "pending") {
            if (props?.userProfile?.id === recentRequest?.userId) buttonState = "PENDING";
            else if (props?.userProfile?.id === recentRequest?.targetUserId) buttonState = "RECIEVED_REQUEST";
        } else if (!isConnectedWithUser && !recentRequest) buttonState = "CONNECT";

    }

    const handleClose = () => {
        props?.handleClose();
    };

    const openDisconnectModal = (status, data = {}) => {
        setDisconnectUserData(data)
        setShowDisconnectModal(status);
    }

    const handlePopoverOpen = (event, data) => {
        setAnchorEl(event.currentTarget);
        if (data !== undefined) {
            setDescription(data)
        }
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setDescription(null)
    };

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 700); // Adjust the threshold as needed
        };

        window.addEventListener('resize', handleResize);
        props?.resetReview();
        // Initial check
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const daysOfWeek = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

    const ConvertUTCtoTimezone = (userTime) => {
        console.log(userTime, "userTime")
        if (userTime) {
            return userTime?.map(timesheet => {
                let newTimesheet = {};
                for (let day in timesheet) {
                    let dayIndex = daysOfWeek?.indexOf(day?.replace("Timesheet", "")?.toLowerCase());
                    let startingTime = timesheet[day][0]?.startingTime;
                    let closingTime = timesheet[day][0]?.closingTime;

                    let startDay = daysOfWeek[dayIndex];
                    let closeDay = daysOfWeek[(dayIndex + 1) % 7];

                    newTimesheet[day] = [{
                        startingTime: `${startingTime} ${startDay}`,
                        closingTime: `${closingTime} ${closeDay}`
                    }];
                }
                if (newTimesheet) {
                    const daysOfWeek = {
                        sunday: 0,
                        monday: 1,
                        tuesday: 2,
                        wednesday: 3,
                        thursday: 4,
                        friday: 5,
                        saturday: 6,
                    };

                    const convertToPacificHonolulu = ({ startingTime, closingTime }) => {
                        const timezone = props?.userProfile?.timezone;
                        const [startHour, startDay] = startingTime?.split(' ');
                        const [closeHour, closeDay] = closingTime?.split(' ');
                    
                        const startDayOfWeek = daysOfWeek[startDay?.toLowerCase()];
                        const closeDayOfWeek = daysOfWeek[closeDay?.toLowerCase()];
                    
                        const startDateTime = dayjs.utc().day(startDayOfWeek)?.set('hour', parseInt(startHour.split(':')[0]))?.set('minute', parseInt(startHour?.split(':')[1]));
                        const closeDateTime = dayjs.utc().day(closeDayOfWeek)?.set('hour', parseInt(closeHour.split(':')[0]))?.set('minute', parseInt(closeHour?.split(':')[1]));
                    
                        // Convert to 24-hour format
                        const pacificStartingTime = startDateTime?.tz(timezone)?.format('HH:mm:ss dddd');
                        const pacificClosingTime = closeDateTime?.tz(timezone)?.format('HH:mm:ss dddd');
                    
                        return { pacificStartingTime, pacificClosingTime };
                    };
                    
                    // Function to process all timesheets in newTimesheet
                    let modifiedNewTimesheet = {};
                    Object?.keys(newTimesheet)?.forEach(day => {
                        modifiedNewTimesheet[day] = newTimesheet[day]?.map(timesheetEntry => {
                            const convertedTimes = convertToPacificHonolulu(timesheetEntry);
                            return {
                                startingTime: convertedTimes?.pacificStartingTime,
                                closingTime: convertedTimes?.pacificClosingTime
                            };
                        });
                    });
                    console.log("modifiedNewTimesheet",modifiedNewTimesheet);
                    
                    if (modifiedNewTimesheet) {
                        const splitTimesheet = {};

                        for (let day in modifiedNewTimesheet) {
                            const times = modifiedNewTimesheet[day];
                            splitTimesheet[day] = [];

                            times.forEach(time => {
                                const [startHour, startMinute, startSecond] = time.startingTime.split(' ')[0].split(':').map(Number);
                                const [closeHour, closeMinute, closeSecond] = time.closingTime.split(' ')[0].split(':').map(Number);

                                const startDay = time.startingTime.split(' ')[1];
                                const closeDay = time.closingTime.split(' ')[1];

                                if (startDay !== closeDay) {
                                    splitTimesheet[day].push({
                                        startingTime: `${startHour.toString().padStart(2, '0')}:${startMinute.toString().padStart(2, '0')}:${startSecond.toString().padStart(2, '0')} ${startDay}`,
                                        closingTime: `00:00:00 ${closeDay}`
                                    });

                                    const nextDay = `00:00:00 ${closeDay}`;
                                    splitTimesheet[day].push({
                                        startingTime: nextDay,
                                        closingTime: `${closeHour.toString().padStart(2, '0')}:${closeMinute.toString().padStart(2, '0')}:${closeSecond.toString().padStart(2, '0')} ${closeDay}`
                                    });
                                } else {
                                    splitTimesheet[day].push(time);
                                }
                            });
                        }
                        console.log("splitTimesheet", splitTimesheet);
                        if (splitTimesheet) {
                            let timesheet = splitTimesheet;
                            const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

                            days?.forEach(day => {
                                const currentDayTimesheet = timesheet[`${day}Timesheet`];
                                let i = 0;

                                while (i < currentDayTimesheet.length) {
                                    const entry = currentDayTimesheet[i];
                                    const startDay = entry.startingTime.split(" ")[1]?.toLowerCase(); // Convert to lowercase
                                    const endDay = entry.closingTime.split(" ")[1]?.toLowerCase(); // Convert to lowercase

                                    // Check if startDay exists in the days array to avoid undefined errors
                                    if (startDay && days.includes(startDay)) {
                                        if (startDay !== day) {
                                            // Ensure the startDay Timesheet exists in the object
                                            if (!timesheet[`${startDay}Timesheet`]) {
                                                timesheet[`${startDay}Timesheet`] = [];
                                            }
                                            timesheet[`${startDay}Timesheet`].unshift(entry);
                                            currentDayTimesheet.splice(i, 1);
                                        } else {
                                            i++;
                                        }
                                    } else {
                                        console.error(`Invalid startDay detected: ${startDay}`);
                                        i++; // Move forward to avoid infinite loops
                                    }
                                }
                            });
                            if (timesheet) {
                                const timesheetArray = [];
                                timesheetArray.push(timesheet);
                                if (timesheetArray && timesheetArray?.length > 0) {
                                    console.log("timesheet", timesheetArray, timesheetArray?.length);
                                    TimeSplitter(timesheetArray)
                                }
                            }
                        }
                    }
                }
            });
        }
    };


    useEffect(() => {
        const id = props?.data?.userInfo?.id;
        const data = props?.suggestedAdvisor?.find(obj => obj?.userInfo?.id === id);
        if (data) {
            setuserdetails(data);
        }
        if (props?.userProfile) {
            props?.getAdvisorSchedule(props?.userProfile?.id)
        }
        console.log(props?.data?.userInfo?.advisorProfile?.advisorWorkingHours  || props?.detailedUserData?.userInfo?.advisorProfile?.advisorWorkingHours, "dghsg")
        if (props?.data?.userInfo?.advisorProfile?.advisorWorkingHours  || props?.detailedUserData?.userInfo?.advisorProfile?.advisorWorkingHours) {
            ConvertUTCtoTimezone([props?.data?.userInfo?.advisorProfile?.advisorWorkingHours  || props?.detailedUserData?.userInfo?.advisorProfile?.advisorWorkingHours])
        }
        if(props?.isRequestRespond){
            props?.setSendRespondToRequestStatus(false)
            props?.handleClose()
        }
    }, [props?.suggestedAdvisor, props?.userProfile, props?.data?.advisorProfile, props?.data, props?.detailedUserData,props?.isRequestRespond]);

    useEffect(() => {
        setLoading(true)
        let payload = {
            userId: props?.data?.userInfo?.id || props.data?.requestInfo?.userId || props?.data?.id,
            pointOfViewStudentId: props?.userProfile?.isOnboarded === false && props?.userProfile?.currentRole === "parent" ? props?.childprofile && props?.childprofile[0]?.id : localStorage.getItem("parentViaChild")
        }
        props?.getDetailedUserProfile(payload)
        let reviewPayload = {
            advisorId: props?.data?.userInfo?.id || props.data?.requestInfo?.userId || props?.data?.id,
            "limit": 10,
            "pageNo": 1,
            studentId: localStorage?.getItem("parentViaChild")
        }
        props?.loadAdvisorReview(reviewPayload)
        if (props?.userProfile?.isOnboarded === true) {
            props?.getFreeSessionRemaining(localStorage.getItem("parentViaChild"), props?.data?.userInfo?.id || props.data?.requestInfo?.userId || props?.data?.id)
        }
        if (props?.userProfile?.currentRole === "student" && isMyNetworkPage) {
            const advisorId = [props?.data?.userInfo?.id]
            let payload = { "advisorIds": advisorId, studentId: props?.userProfile?.id }
            props?.getFreeSessionStatus(payload)
        }
    }, [])

    useEffect(() => {
        if (props.detailedUserDataSuccess) {
            setLoading(false)
            props.settDetailedUserProfileStatus(false);
            console.log(props?.purchaseSubscriptionFlag,"props?.purchaseSubscriptionFlag");
            
            if(props?.purchaseSubscriptionFlag){
                scrollToFooterSection();
            }
        }
        if (props?.sessionBooked) {
            CloseBookSession()
            props?.bookSessionStatus(false)
        }
        if (props?.booksessionerror) {
            CloseBookSession()
            props?.bookSessionStatus(false)
        }
        if (props?.paidSessionBooked) {
            CloseBookSession()
            props?.bookPaidSessionStatus(false)
        }
        if (props?.bookPaidSessionError) {
            CloseBookSession()
            props?.bookPaidSessionErrorStatus(false)
        }
    }, [props.detailedUserDataSuccess, props?.sessionBooked, props?.paidSessionBooked, props?.booksessionerror, props?.bookPaidSessionError,props?.purchaseSubscriptionFlag])

    useEffect(() => {
        if (props?.subscribeadvisor) {
            props?.SubScribeAdvisorStatus(false)
            let payload = {
                userId: props?.data?.userInfo?.id || props.data?.requestInfo?.userId || props?.data?.id,
                pointOfViewStudentId: props?.userProfile?.isOnboarded === false && props?.userProfile?.currentRole === "parent" ? props?.childprofile && props?.childprofile[0]?.id : localStorage.getItem("parentViaChild")
            }
            props?.getDetailedUserProfile(payload)
            let reviewPayload = {
                advisorId: props?.data?.userInfo?.id || props.data?.requestInfo?.userId || props?.data?.id,
                "limit": 10,
                "pageNo": 1,
                studentId: localStorage?.getItem("parentViaChild")
            }
            if (props?.userProfile?.currentRole === "parent") {
                let childId = localStorage.getItem("parentViaChild")
                props?.getConnectedAdvisorsParents(childId)
            } else {
                props?.getConnectedAdvisors()
            }
            props?.loadAdvisorReview(reviewPayload)
            setLoading(false)
        }
        if (props?.subscribeerror) {
            if (props?.userProfile?.currentRole === "parent") {
                let childId = localStorage.getItem("parentViaChild")
                props?.getConnectedAdvisorsParents(childId)
            } else {
                // props?.getConnectedAdvisors()
            }
            if (props?.userProfile?.currentRole === "student" && isMyNetworkPage) {
                const advisorId = [props?.data?.userInfo?.id]
                let payload = { "advisorIds": advisorId, studentId: props?.userProfile?.id }
                props?.getFreeSessionStatus(payload)
            }
            props?.SubScribeAdvisorErrorStatus(false)
            setLoading(false)

        }
        if (advisorTime) {
            setLoading(false)
        }
    }, [props?.subscribeadvisor, props?.subscribeerror, props?.userProfile, advisorTime])

    useEffect(() => {
        if (props.userConnectSuccess) {
            props?.userConnectStatus(false)
            setLoading(false)
        }
        if (props.userConnectError) {
            props?.userConnectErrorStatus(false)
            setLoading(false)
        }
    }, [props.userConnectSuccess, props.userConnectError])

    useEffect(() => {

        if (props?.isDisconnectUser) {
            setShowDisconnectModal(false);
            props?.disconnectUserStatus(false);
        }

    }, [props?.isDisconnectUser,])
    
    const scrollToFooterSection = () => {
        const footerSection = document.getElementById("pkgSection");
        if (footerSection) {
            footerSection.scrollIntoView({ behavior: "smooth" });
        }
    };

    const stripHTMLTags = (html) => {
        const tempElement = document.createElement("div");
        tempElement.innerHTML = html;
        return tempElement.textContent || tempElement.innerText || "";
    };

    const advisorActivities = advisorProfile?.advisorActivities || props?.data?.advisorProfile?.advisorActivities || [];
    const advisorCourses = advisorProfile?.advisorCourses || props?.data?.advisorProfile?.advisorCourses || [];

    const combinedData = [...advisorActivities, ...advisorCourses];

    const mappedData = combinedData.map(item => {
        if (item.activityName) {
            return item.activityName;
        } else if (item.courseName) {
            return item.courseName;
        }
        return null;
    });

    const ConfirmSubScribe = (data) => {
        setConformsubscribe(true)
        setSubscribedata(data)
    }
    const handleCloseSubscribe = () => {
        setConformsubscribe(false)
        setSubscribedata([])
    }
    const handleConfirmSubscribe = () => {
        let payload = {
            "advisorId": subscribedata?.userId,
            "paymentPackageId": subscribedata?.id,
            "studentId": props?.userProfile?.currentRole === "parent" ? localStorage?.getItem("parentViaChild") : props?.userProfile?.id
        }
        props?.SubScribeAdvisor(payload)
        setLoading(true)
        handleCloseSubscribe()
    }

    const handleConnect = (e,data) => {
        console.log(data,"accData");
        setopenConfirmation(true)
        setConfirmationData(data)
    };

    const RejectRequest = (e, data) => {
        console.log(e,data,"rejectt");
        
        setrejectConfirmation(true)
        setrejectConfirmationData(data);
    }

    const CloseConfirmation = () => {
        setConfirmationData({})
        setopenConfirmation(false);
    }

    const RejectClose = () => {
        setrejectConfirmationData({});
        setrejectConfirmation(false);
    }
    
    const ConfirmtionAdd = () => {
        console.log(confirmationData,"confirmationData");
        
        setLoading(true)
        let payload = {
            "requestId": confirmationData?.recentRequest?.id,
            "isAccepted": true
        }
        if (props?.userProfile?.currentRole === "parent") {
            props?.sendRespondToParentRequest(props?.childprofile && props?.childprofile[0]?.id, payload)
        } else {
            props?.sendRespondToRequest(payload)
        }
        CloseConfirmation()
    }

    const RejectConfirmtion = () => {
        console.log(rejectConfirmationData,"rejectConfirmationData");
        
        setLoading(true)
        let payload = {
            "requestId": rejectConfirmationData?.recentRequest?.id,
            "isAccepted": false
        }
        if (props?.userProfile?.currentRole === "parent") {
            props?.sendRespondToParentRequest(props?.childprofile && props?.childprofile[0]?.id, payload)
        } else {
            props?.sendRespondToRequest(payload)
        }
        RejectClose()

    }

    const handleDelete = () => {
        let payload = {
            userId: props?.data?.userInfo?.id || props.data?.requestInfo?.userId || props?.data?.id
        }
        props?.disconnectUser(payload)
        props?.handleClose()
    }
    const navigateToGoals = () => {
        if (props?.userProfile?.currentRole === 'parent') {
            if (props?.userProfile?.isOnboarded === false) {
                navigate("/onboarding/goals/child");
            } else {
                navigate("/parent/myprofile");
            }
        }
        if (props?.userProfile?.currentRole === 'student') {
            if (props?.userProfile?.isOnboarded === false) {
                navigate("/onboarding/goals");
            } else {
                navigate('/student/myprofile', { state: { type: "goals" } });
            }
        }
    };

    function myFunction() {
        setShowAlert(true)
        let textfield = document.createElement("input");
        textfield.setAttribute('id', 'myInput')
        textfield.setAttribute('value', userProfileUrl)
        document.body.appendChild(textfield)
        let copyText = document.getElementById("myInput");
        copyText.focus();
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand('copy');
        textfield.remove()
        navigator.clipboard.writeText(userProfileUrl)
        setalertVisible(true)
        setTimeout(() => {
            setShowAlert(false);
            setalertVisible(false)
        }, 3000);
    }

    const copyToClipboard = async () => {
        myFunction()
        setLink('')
    };

    const ProfileApi = () => {
        setLoading(true)
        let payload = {
            userId: props?.data?.userInfo?.id || props.data?.requestInfo?.userId || props?.data?.id,
            pointOfViewStudentId: props?.userProfile?.currentRole === "parent" && props?.childprofile && props?.childprofile[0]?.id
        }
        props?.getDetailedUserProfile(payload)
    }

    useEffect(() => {
        if (props?.cancelrequest) {
            ProfileApi()
            setShowCancelModal(false);
            setRejectData({});
            props?.CancelRequestStatus(false);
            setLoading(false)
        }
        if (props?.parentRequest) {
            props?.respondToParentRequestStatus(false)
            setLoading(false)
            ProfileApi()
        }
    }, [props?.cancelrequest, props?.parentRequest])

    const handleShowCancelModal = (status, data = {}) => {
        setShowCancelModal(status);
        setRejectData(data);
    }
    const handleCancelRequest = () => {
        setLoading(true)
        let payload = {
            requestId: rejectData?.id,
        }
        if (props?.userProfile?.currentRole === "parent") {
            payload["studentId"] = props?.userProfile?.isOnboarded === true ? localStorage.getItem("parentViaChild") : props?.childprofile && props?.childprofile[0]?.id
        }
        props?.CancelRequest(payload);
    }
    const OpenBookSession = (e, data) => {
        props?.clearMessage()
        e.preventDefault()
        setTimeSession(props?.detailedUserData ?? data?.userInfo?.advisorProfile)
        setAdvisorid(data?.userInfo?.id)
        setAdvisorData(data)
        setAdvisorTime([data?.userInfo?.advisorProfile?.advisorWorkingHours])
        setAdvisorName({ label: data?.userInfo?.fullName, value: data?.userInfo?.id })
        setbookshow(true)
    }

    const CloseBookSession = () => {
        // props?.clearMessage()
        setbookshow(false)
    }

    function convertTo24HourFormat(time12h) {
        const [time, modifier] = time12h.split(' ');
        let [hours, minutes] = time.split(':');
        hours = parseInt(hours, 10);
        if (modifier === 'PM' && hours !== 12) {
            hours += 12;
        } else if (modifier === 'AM' && hours === 12) {
            hours = 0;
        }
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.padStart(2, '0');
        return `${formattedHours}:${formattedMinutes}:00`;
    }

    const SessionBook = (data, value) => {
        const moment = require('moment');
        const SelectHours = value
        // const selectedDate = moment(data.Selectdate, "YYYY-MM-DD").format("YYYY-MM-DD");
        // const startTime = SelectHours.split(' - ')[0];
        // const formattedDate = moment(`${selectedDate} ${startTime}`, "YYYY-MM-DD HH:mm A").format("YYYY-MM-DD HH:mm:ss");

        const startTime = value.split(' - ')[0];
        const selectedDate = moment(data.Selectdate, "YYYY-MM-DD").format("YYYY-MM-DD");
        let currentTime = selectedDate + ' ' + convertTo24HourFormat(startTime)

        // Time Gap
        const [startTimes, endTime] = SelectHours.split(' - ');
        const parsedStartTime = moment(startTimes, 'h:mm A');
        const parsedEndTime = moment(endTime, 'h:mm A');
        const timeDiffInMillis = parsedEndTime.diff(parsedStartTime);
        const timeDiffInMinutes = moment.duration(timeDiffInMillis).asMinutes();

        let payload = {
            "advisorId": advisorid,
            "eventTime": currentTime,
            "eventDuration": timeDiffInMinutes,
            "meetingLocation": data?.Location,
            "meetingDescription": data?.Description,
            "studentId": localStorage.getItem("parentViaChild"),
            "password": data?.password
        }
        if (data?.package) {
            payload["packageId"] = data?.package?.value;
            props?.bookPaidSession(payload)
        } else {
            props?.bookSession(payload)

        }
    }
    const hasPaidSessions = (props?.data?.subscriptiondetails || []).some(
        subscription => subscription.noOfPaidSessions > 0 && !subscription?.packageExpired
    );
    const imageUrl = useImageUrl(props?.data?.userInfo?.avatarPath ? props?.data?.userInfo?.avatarPath : props?.data?.avatarPath);

    const handleOnClick = () => {
        if (props?.userProfile?.isOnboarded === true) {
            navigateToGoals();
        }
    };
    const getScheduledTime = (userId, packageId) => {
        let eventDetails = props?.scheduledSessionDetails?.find(data => data?.userId === userId && data?.packageId === packageId);
        if (eventDetails) {
            let date = convertUTCTodateTimeZone(eventDetails?.eventTime, props?.userProfile?.timezone);
            let formattedDate = date.format('DD/MM/YYYY')
            return `${formattedDate} at ${convertUtcToEventTime(eventDetails?.eventTime, props?.userProfile?.timezone)?.format('hh:mm A')}`
        } else {
            return null;
        }
    }
    const TimeSplitter = (newTime) => {
        console.log("newTime", newTime);

        if (newTime) {
            const advisorWorkingHours = newTime[0];  // Since it's an array of objects, access the first object
            const splitTimesheets = {
                "mondayTimesheet": [],
                "tuesdayTimesheet": [],
                "wednesdayTimesheet": [],
                "thursdayTimesheet": [],
                "fridayTimesheet": [],
                "saturdayTimesheet": [],
                "sundayTimesheet": []
            };

            const dayMapping = {
                "Monday": "mondayTimesheet",
                "Tuesday": "tuesdayTimesheet",
                "Wednesday": "wednesdayTimesheet",
                "Thursday": "thursdayTimesheet",
                "Friday": "fridayTimesheet",
                "Saturday": "saturdayTimesheet",
                "Sunday": "sundayTimesheet"
            };

            function parseTimeAndDay(timeAndDay) {
                const lastSpaceIndex = timeAndDay?.lastIndexOf(' ');
                const time = timeAndDay?.substring(0, lastSpaceIndex)?.trim();
                const day = timeAndDay?.substring(lastSpaceIndex + 1)?.trim();
                return [time, day];
            }

            const convertTimeFormat = (convertedTime) => {
                const [time, day] = convertedTime?.split(' ');
                const converted12HourTime = dayjs(time, 'HH:mm:ss')?.format('hh:mm A');  // Adjusted format to include seconds
                return `${converted12HourTime} ${day}`;
            };

            Object.keys(advisorWorkingHours)?.forEach(dayKey => {
                const dayTimeSheet = advisorWorkingHours[dayKey];

                if (Array.isArray(dayTimeSheet) && dayTimeSheet?.length > 0) {
                    dayTimeSheet.forEach(entry => {

                        const convertedStartTime = convertTimeFormat(entry?.startingTime);
                        const convertedCloseTime = convertTimeFormat(entry?.closingTime);

                        if (convertedStartTime && convertedCloseTime) {
                            const [startTime, startDay] = parseTimeAndDay(convertedStartTime);
                            const [endTime, endDay] = parseTimeAndDay(convertedCloseTime);

                            const startDayKey = dayMapping[startDay];
                            const endDayKey = dayMapping[endDay];

                            if (!startDayKey || !endDayKey) {
                                console.error(`Invalid day key mapping: startDay=${startDay}, endDay=${endDay}`);
                                return;
                            }

                            if (startDayKey === endDayKey) {
                                // Case 1: Same day
                                splitTimesheets[startDayKey].push({
                                    startingTime: `${startTime}, ${startDay}`,
                                    closingTime: `${endTime}, ${endDay}`
                                });
                            } else {
                                // Case 2: Different days
                                splitTimesheets[startDayKey].push({
                                    startingTime: `${startTime}, ${startDay}`,
                                    closingTime: `12:00 AM, ${startDay}`
                                });

                                splitTimesheets[endDayKey].push({
                                    startingTime: `12:00 AM, ${endDay}`,
                                    closingTime: `${endTime}, ${endDay}`
                                });
                            }
                        }

                    });
                }
            });
            console.log("splitTimesheetssplitTimesheets",splitTimesheets);
            
            if (splitTimesheets) {
                const updatedTimesheets = { ...splitTimesheets };
                Object.keys(updatedTimesheets).forEach(day => {
                    if (updatedTimesheets[day]?.length > 0 && updatedTimesheets[day][0]?.startingTime?.includes('PM')) {
                        const pmEntry = updatedTimesheets[day]?.shift();
                        updatedTimesheets[day]?.push(pmEntry);
                    }
                });
                console.log("updatedTimesheetsupdatedTimesheets",updatedTimesheets);
                
                setSplitedTime(updatedTimesheets);
            }
        }
    };


    const unmatchedPackages = props?.detailedUserData?.advisorFeePackages?.filter(item =>
        !props?.detailedUserData?.subscriptions.some(subscription => subscription.packageId === item.id)
    );

    const freesessionCompletedStatus = props?.freeSessionStatus && props?.freeSessionStatus?.filter(item =>
        item.advisorId === props?.detailedUserData?.userInfo?.id
    );

    return (
        <>
            {(bookshow && advisorTime) &&
                <BookSessionModal show={bookshow} advisorsData={advisorData} userTime={advisorTime} selectedAdvisor={advisorName} advisorList={props?.connectedAdvisors} onHide={CloseBookSession} Time={TimeSession} HandleSumit={SessionBook} />
            }
            <SelectStudent show={openStudent} title={"Are you sure you want to connect with"} data={props.data} handleClose={handleSelectStudentClose} />
            {connect &&
                <PlanAccessModal onClose={handleConnectClose} show={connect} data={props.data} callApi={ProfileApi} />
            }
            {showDisconnectModal &&
                <ConfirmationPopup show={showDisconnectModal} handleSubmit={() => handleDelete()} message={`You and ${disconnectUserData?.fullName} will no longer be connected to each other`} btnText="Confirm" onHide={() => openDisconnectModal(false)} />
            }
            {showCancelModal &&
                <ConfirmationPopup show={showCancelModal} handleSubmit={() => handleCancelRequest()} message={`Please confirm that you would like to revoke this connection request for this student`} btnText="Confirm" onHide={() => handleShowCancelModal(false)} />
            }
            {conformsubscribe &&
                <ConfirmationPopup show={conformsubscribe} handleSubmit={() => handleConfirmSubscribe()} message={`Please confirm that you would like to Subscribe this ${subscribedata?.packageName}`} btnText="Confirm" onHide={() => handleCloseSubscribe(false)} />
            }
            {openConfirmation &&
                <ConfirmationPopup show={openConfirmation} handleSubmit={() => ConfirmtionAdd()} 
                message={"Accepting this request will enable you send messages to this advisor and book your free consultation session"} 
                btnText="confirm" onHide={() => CloseConfirmation()} />
            }
              {rejectConfirmation &&
                <ConfirmationPopup show={rejectConfirmation} handleSubmit={() => RejectConfirmtion()} 
                message={"Please confirm that you would like to reject this connection request from this advisor"} btnText="confirm" onHide={() => RejectClose()} />
            }
            <Modal style={styles.modal} open={props.show} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description" className="goalsModal" >
                <div style={styles.paper}>
                    {loading && <Loader />}
                    <div style={{ "width": "100%", "display": "flex", "justifyContent": "center", }}> <CustomAlert /></div>
                    {alertVisible &&
                        <div style={{ position: 'fixed', top: '10px', zIndex: '99999', left: '50%', transform: 'translateX(-50%)' }} >
                            <Alert style={{ position: 'sticky', marginTop: '10px', }}
                                variant="success" onClose={() => { setShowAlert(false); setalertVisible(false) }} dismissible>Link Copied to clipboard</Alert>
                        </div>
                    }
                    <div style={styles.content} className="modal-body">
                        <div style={{ textAlign: 'right', marginBottom: "20px" }}>
                            <Button onClick={handleClose}><CloseIcon sx={{ fill: 'black' }} /></Button>
                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div className="advisorProfileCard">
                                    <Grid container className="advisorProfileCard__container">
                                        <div className="advisorProfileCard__info-sec">
                                            {(props?.data?.userInfo?.avatarPath ? props?.data?.userInfo?.avatarPath : props?.data?.avatarPath) ? (
                                                <div class="advisorProfileCard__info-sec__sub-img advisor-sub-img" >
                                                    <img src={imageUrl} alt="user" style={{ borderRadius: '10px', width: "100%", height: '100%', objectFit: 'cover' }} />
                                                </div>
                                            ) :
                                                <div class="advisorProfileCard__info-sec__sub-img" >
                                                    <PersonIcon color="action" sx={{ border: '1px solid grey', width: "100%", height: "100% !important", borderRadius: '10px' }} />
                                                </div>
                                            }
                                            <div className="advisorProfileCard__info-sec__profileCont">
                                                <p className="profileName">
                                                    {userInfo?.fullName ? userInfo?.fullName : props?.data?.fullName ? props?.data?.fullName : props?.data?.firstName}
                                                </p>
                                                <div className="advisorProfileCard__info-sec__profileCont__about-sec">
                                                    <span>
                                                        <RoomOutlinedIcon color="#515050" fontSize="12px" />
                                                        <p className="label">{userInfo?.country ? userInfo?.country : props?.data?.country}</p>
                                                    </span>
                                                </div>
                                                <div className="advisorProfileCard__info-sec__profileCont__about-sec mt-2">
                                                    <span>
                                                        <p className="label">{"Ratings :"}</p>
                                                        <Rating name="read-only" value={props?.data?.userInfo?.advisorProfile?.advisorReviewSummary?.averageRating ? props?.data?.userInfo?.advisorProfile?.advisorReviewSummary?.averageRating : props?.data?.advisorProfile?.advisorReviewSummary?.averageRating} precision={0.5} readOnly size="small" sx={{ color: '#AEAEAE' }} />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="advisorProfileCard__connect-btn d-grid">
                                            <div className="d-flex" style={{ "justifyContent": "center", "flexDirection": "column", "gap": "10px", "alignItems": "center" }}>
                                                {props?.userProfile?.currentRole === "parent" ? (
                                                    <>
                                                        {!props?.detailedUserData?.isConnectedWithUser &&
                                                            <>
                                                                {buttonState === "PENDING" &&
                                                                    <button className="advisorProfileCard__connect-btn__secondary-btn" onClick={() => handleShowCancelModal(true, recentRequest)}>
                                                                        Cancel Request
                                                                    </button>
                                                                }
                                                                {buttonState === "RECIEVED_REQUEST" &&
                                                                    // <button className="advisorProfileCard__connect-btn__primary-btn" onClick={() => handleConnect(true)}>
                                                                    //     Accept
                                                                    // </button>
                                                                    <div style={{ "display": "flex", "gap": "20px", "flexWrap": "wrap", "marginBottom": "10px", "justifyContent": "center" }} >

                                                                        <button className="advisorProfileCard__connect-btn__secondary-btn" onClick={(e) => RejectRequest(e, props?.detailedUserData)}>
                                                                            Reject
                                                                        </button>


                                                                        <button className="advisorProfileCard__connect-btn__primary-btn" onClick={(e) => handleConnect(e,props?.detailedUserData)}>
                                                                            Accept
                                                                        </button>
                                                                    </div>

                                                                }
                                                                {buttonState === "CONNECT" &&
                                                                    <button className="advisorProfileCard__connect-btn__primary-btn" onClick={props?.parent ? handleSelectStudentOpen : handleConnectOpen}>
                                                                        Connect
                                                                    </button>
                                                                }
                                                            </>
                                                        }
                                                        {/* {isMyNetworkPage && (Network !== "2" && Network !== "3") &&
                                                            <>
                                                                <p className="networkText mb-0">Connected Advisor</p>
                                                                {props?.getRemainingFreeSession?.availableFreeSession !== 0 && props?.getRemainingFreeSession?.availableFreeSession !== props?.getRemainingFreeSession?.totalFreeSession && props?.detailedUserData?.subscriptions?.length === 0 ? (
                                                                    <button className="advisorProfileCard__connect-btn__primary-btn" style={{ width: '222px' }} onClick={(e) => OpenBookSession(e, props?.data)}>
                                                                        Book Another Free Session
                                                                    </button>
                                                                ) :
                                                                    (
                                                                        (freesessionCompletedStatus?.freeSessionCompleted === true && freesessionCompletedStatus?.remainingCount === 0 && isMyNetworkPage && (Network !== "2" && Network !== "3")) ? (

                                                                            <>
                                                                          
                                                                                {getScheduledTime(props?.data?.userInfo?.id || props.data?.requestInfo?.userId || props?.data?.id) ? (
                                                                                    <span className="meetTimeZone" style={{ color: "#545454", fontSize: "14px" }}>
                                                                                        <CalendarTodayOutlinedIcon sx={{ fontSize: '0.7rem' }} /> You are scheduled to meet on {getScheduledTime(props?.data?.userInfo?.id || props.data?.requestInfo?.userId || props?.data?.id)}
                                                                                    </span>
                                                                                ) :
                                                                                    <button className="advisorProfileCard__connect-btn__primary-btn" onClick={(e) => OpenBookSession(e, props?.data)}>
                                                                                        Book free Session
                                                                                    </button>
                                                                                }
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {isMyNetworkPage && (Network !== "2" && Network !== "3") && hasPaidSessions &&
                                                                                    <button className="advisorProfileCard__connect-btn__primary-btn" onClick={(e) => OpenBookSession(e, props?.data)}>
                                                                                        Book paid Session
                                                                                    </button>
                                                                                }
                                                                            </>
                                                                        )
                                                                    )
                                                                }

                                                            </>

                                                        } */}
                                                        {props?.detailedUserData?.isConnectedWithUser &&
                                                            <p className="networkText mb-0">Connected Advisor</p>
                                                        }
                                                        {props?.detailedUserData?.isConnectedWithUser && props?.detailedUserData?.subscriptions?.length === 0 ? (
                                                            <>
                                                                {!isOnboarding &&
                                                                    <>
                                                                        {props?.detailedUserData?.subscriptions?.length == 0 && props?.freeSessionStatus[0]?.acceptedFreeSession === 1 && props?.freeSessionStatus[0]?.totalCount != props?.freeSessionStatus[0]?.remainingCount && props?.freeSessionStatus[0]?.remainingCount != 0 ? (
                                                                            <>
                                                                                <button className="advisorProfileCard__connect-btn__primary-btn" style={{ "width": "250px" }} onClick={(e) => OpenBookSession(e, props?.data)}>
                                                                                    Book another free Session
                                                                                </button>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {getScheduledTime(props?.data?.userInfo?.id || props.data?.requestInfo?.userId || props?.data?.id) ? (
                                                                                    <span className="meetTimeZone" style={{ color: "#545454", fontSize: "14px" }}>
                                                                                        <CalendarTodayOutlinedIcon sx={{ fontSize: '0.7rem' }} /> You are scheduled to meet on {getScheduledTime(props?.data?.userInfo?.id || props.data?.requestInfo?.userId || props?.data?.id)}
                                                                                    </span>
                                                                                ) : freesessionCompletedStatus[0]?.remainingCount !== 0 && (
                                                                                    <>
                                                                                        <button className="advisorProfileCard__connect-btn__primary-btn" onClick={(e) => OpenBookSession(e, props?.data)}>
                                                                                            Book free Session
                                                                                        </button>
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </>
                                                                }
                                                            </>
                                                        ) : (
                                                            <>
                                                                {isMyNetworkPage && (Network !== "2" && Network !== "3" && hasPaidSessions) &&
                                                                    <button className="advisorProfileCard__connect-btn__primary-btn" onClick={(e) => OpenBookSession(e, props?.data)}>
                                                                        Book paid Session
                                                                    </button>
                                                                }
                                                            </>
                                                        )}
                                                        {isConnectedWithUser &&
                                                            <button className="advisorProfileCard__connect-btn__secondary-btn" onClick={() => openDisconnectModal(true, userInfo)}>
                                                                Disconnect
                                                            </button>
                                                        }
                                                    </>
                                                ) : (

                                                    <>
                                                        {!props?.detailedUserData?.isConnectedWithUser &&
                                                            <>
                                                                {buttonState === "PENDING" &&
                                                                    <button className="advisorProfileCard__connect-btn__secondary-btn" onClick={() => handleShowCancelModal(true, recentRequest)}>
                                                                        Cancel Request
                                                                    </button>
                                                                }
                                                                {buttonState === "RECIEVED_REQUEST" &&
                                                                    <>
                                                                        <button className="advisorProfileCard__connect-btn__primary-btn" onClick={() => handleConnect(true,props?.detailedUserData)}>
                                                                            Accept
                                                                        </button>
                                                                        <button className="advisorProfileCard__connect-btn__secondary-btn" onClick={(e) => RejectRequest(e, props?.detailedUserData)}>
                                                                            Reject
                                                                        </button>
                                                                    </>
                                                                }
                                                                {buttonState === "CONNECT" &&
                                                                    <button className="advisorProfileCard__connect-btn__primary-btn" onClick={props?.parent ? handleSelectStudentOpen : handleConnectOpen}>
                                                                        Connect
                                                                    </button>
                                                                }
                                                            </>
                                                        }
                                                        {props?.detailedUserData?.isConnectedWithUser &&
                                                            <p className="networkText mb-0">Connected Advisor</p>
                                                        }
                                                        {props?.detailedUserData?.isConnectedWithUser && props?.detailedUserData?.subscriptions?.length === 0 ? (
                                                            <>
                                                            {console.log(props?.detailedUserData?.subscriptions?.length,"props?.detailedUserData?.subscriptions",props?.freeSessionStatus[0]?.acceptedFreeSession,props?.freeSessionStatus[0]?.totalCount == props?.freeSessionStatus[0]?.remainingCount,props?.freeSessionStatus[0]?.remainingCount)}
                                                            
                                                                {!isOnboarding &&
                                                                    <>
                                                                        {props?.detailedUserData?.subscriptions?.length == 0 && props?.freeSessionStatus[0]?.acceptedFreeSession === 1 && props?.freeSessionStatus[0]?.totalCount != props?.freeSessionStatus[0]?.remainingCount && props?.freeSessionStatus[0]?.remainingCount != 0 ? (
                                                                            <>
                                                                                <button className="advisorProfileCard__connect-btn__primary-btn" style={{ "width": "250px" }} onClick={(e) => OpenBookSession(e, props?.data)}>
                                                                                    Book another free Session
                                                                                </button>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {getScheduledTime(props?.data?.userInfo?.id || props.data?.requestInfo?.userId || props?.data?.id) ? (
                                                                                    <span className="meetTimeZone" style={{ color: "#545454", fontSize: "14px" }}>
                                                                                        <CalendarTodayOutlinedIcon sx={{ fontSize: '0.7rem' }} /> You are scheduled to meet on {getScheduledTime(props?.data?.userInfo?.id || props.data?.requestInfo?.userId || props?.data?.id)}
                                                                                    </span>
                                                                                ) : freesessionCompletedStatus[0]?.remainingCount !== 0 && (
                                                                                    <>
                                                                                        <button className="advisorProfileCard__connect-btn__primary-btn" onClick={(e) => OpenBookSession(e, props?.data)}>
                                                                                            Book free Session
                                                                                        </button>
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </>
                                                                }
                                                            </>
                                                        ) : (
                                                            <>
                                                                {isMyNetworkPage && (Network !== "2" && Network !== "3" && hasPaidSessions) &&
                                                                    <button className="advisorProfileCard__connect-btn__primary-btn" style={{width : '188px !important'}} onClick={(e) => OpenBookSession(e, props?.data)}>
                                                                        Book paid Session
                                                                    </button>
                                                                }
                                                            </>
                                                        )}
                                                        {props?.detailedUserData?.isConnectedWithUser &&
                                                            <button className="advisorProfileCard__connect-btn__secondary-btn" onClick={() => openDisconnectModal(true, userInfo)}>
                                                                Disconnect
                                                            </button>
                                                        }
                                                    </>
                                                )}
                                            </div>
                                            <div className="d-flex" style={{ "justifyContent": "center", "flexDirection": "column", "gap": "10px", "alignItems": "center", marginTop: '5px' }}>
                                                <Button className="advisorProfileCard__connect-btn__primary-btn" ref={buttonRef} onClick={copyToClipboard} >
                                                    Share
                                                </Button>
                                            </div>
                                            {isMyNetworkPage && (Network !== "2" && Network !== "3") && props?.userProfile?.currentRole !== "parent" &&
                                                <>
                                                    {(props?.detailedUserData?.subscriptions?.length == 0 && props?.freeSessionStatus[0]?.acceptedFreeSession === 1 && props?.freeSessionStatus[0]?.totalCount != props?.freeSessionStatus[0]?.remainingCount) && (
                                                        <p className="networkText mb-0">You may purchase a subscription below</p>
                                                    )}
                                                </>
                                            }
                                            {isMyNetworkPage && (Network !== "2" && Network !== "3") &&
                                                <>
                                                    {props?.getRemainingFreeSession?.availableFreeSession !== props?.getRemainingFreeSession?.totalFreeSession && props?.detailedUserData?.subscriptions?.length === 0 && (
                                                        <p className="networkText mb-0">You may purchase a subscription below</p>
                                                    )}
                                                </>
                                            }
                                        </div>
                                    </Grid>
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div className="advisorCoursesCard">
                                    <p className="advisorCoursesCard__heading">
                                        About {userInfo?.firstName ? userInfo?.firstName : props?.data?.firstName}
                                    </p>
                                </div>
                                <div className="advisorProfileCard">
                                    <div className="advisorProfileCard__info-sec__profileCont__about-sec">
                                        {(advisorProfile?.currentlyIn === "university" || props?.data?.advisorProfile?.currentlyIn === "university") && (
                                            <>
                                                <span>
                                                    <SchoolOutlinedIcon color="#515050" fontSize="12px" />
                                                    <p className="label">
                                                        Enrolled in {advisorProfile?.universityName || props?.data?.advisorProfile?.universityName} graduating in {advisorProfile?.universityGraduationYear || props?.data?.advisorProfile?.universityGraduationYear}
                                                    </p>
                                                </span>
                                                {(advisorProfile?.unWeighted && advisorProfile?.weighted || props?.data?.advisorProfile?.unWeighted && props?.data?.advisorProfile?.weighted) &&
                                                    <span>
                                                        <SchoolOutlinedIcon color="#515050" fontSize="12px" />
                                                        <p className="label">
                                                            Has a current unweighted GPA of {advisorProfile?.unWeighted || props?.data?.advisorProfile?.unWeighted} and weighted GPA of {advisorProfile?.weighted || props?.data?.advisorProfile?.weighted}
                                                        </p>
                                                    </span>
                                                }
                                                <span>
                                                    <SchoolOutlinedIcon color="#515050" fontSize="12px" />
                                                    <p className="label">
                                                        Went to {advisorProfile?.highschoolName || props?.data?.advisorProfile?.highschoolName}
                                                    </p>
                                                </span>
                                            </>
                                        )}
                                        {(advisorProfile?.currentlyIn === "highschool" || props?.data?.advisorProfile?.currentlyIn === "highschool") && (
                                            <>
                                                <span>
                                                    <SchoolOutlinedIcon color="#515050" fontSize="12px" />
                                                    <p className="label">
                                                        {advisorProfile?.highschoolStatus || props?.data?.advisorProfile?.highschoolStatus} in {advisorProfile?.highschoolName || props?.data?.advisorProfile?.highschoolName}
                                                    </p>
                                                </span>
                                                {(advisorProfile?.unWeighted && advisorProfile?.weighted || props?.data?.advisorProfile?.unWeighted && props?.data?.advisorProfile?.weighted) &&
                                                    <span>
                                                        <SchoolOutlinedIcon color="#515050" fontSize="12px" />
                                                        <p className="label">
                                                            Has a current unweighted GPA of {advisorProfile?.unWeighted || props?.data?.advisorProfile?.unWeighted} and weighted GPA of {advisorProfile?.weighted || props?.data?.advisorProfile?.weighted}
                                                        </p>
                                                    </span>
                                                }
                                                <span>
                                                    <SchoolOutlinedIcon color="#515050" fontSize="12px" />
                                                    <p className="label">
                                                        Aspiring to attend {advisorProfile?.targetUniversities || props?.data?.advisorProfile?.targetUniversities}
                                                    </p>
                                                </span>
                                            </>
                                        )}
                                        {(advisorProfile?.currentlyIn === "professional" || props?.data?.advisorProfile?.currentlyIn === "professional") && (
                                            <span>
                                                <SchoolOutlinedIcon color="#515050" fontSize="12px" />
                                                <p className="label">
                                                    Went to {advisorProfile?.highschoolName || props?.data?.advisorProfile?.highschoolName} and {advisorProfile?.universityName || props?.data?.advisorProfile?.universityName}
                                                </p>
                                            </span>
                                        )}
                                        {(advisorProfile?.satScore || props?.data?.advisorProfile?.satScore) && (
                                            <span>
                                                <WorkspacePremiumOutlinedIcon
                                                    color="#515050"
                                                    fontSize="12px"
                                                />
                                                <p className="label">
                                                    Scored {advisorProfile?.satScore || props?.data?.advisorProfile?.satScore} in SAT
                                                </p>
                                            </span>
                                        )}
                                        {(advisorProfile?.actScore || props?.data?.advisorProfile?.actScore) && (
                                            <span>
                                                <WorkspacePremiumOutlinedIcon
                                                    color="#515050"
                                                    fontSize="12px"
                                                />
                                                <p className="label">
                                                    Scored {advisorProfile?.actScore || props?.data?.advisorProfile?.actScore} in ACT
                                                </p>
                                            </span>
                                        )}
                                        {(advisorProfile?.currentlyIn === "professional" || props?.data?.advisorProfile?.currentlyIn === "professional") ? (
                                            <span>
                                                <WorkOutlineOutlinedIcon
                                                    color="#515050"
                                                    fontSize="12px"
                                                />
                                                <p className="label d-flex">
                                                    Operating as <span style={{ color: '#1C84EE' }}> &nbsp; {advisorProfile?.companyName || props?.data?.advisorProfile?.companyName}</span> &nbsp; since {advisorProfile?.operatingSince || props?.data?.advisorProfile?.operatingSince}
                                                </p>
                                            </span>
                                        ) : (
                                            <span>
                                                <EmojiEventsOutlinedIcon
                                                    color="#515050"
                                                    fontSize="12px"
                                                />
                                                <p className="label" style={{ wordBreak: 'break-all' }}>
                                                    Aspiring to become {advisorProfile?.aspiringToBecome || props?.data?.advisorProfile?.aspiringToBecome}
                                                </p>
                                            </span>
                                        )}
                                        {(advisorProfile?.accomplishments || props?.data?.advisorProfile?.accomplishments) &&
                                            <span className="courseFullViewCard__bottomSec">
                                                <p className={`courseFullViewCard__bottomSec${viewMore ? '__description' : '__detailedDescription'}`} onClick={() => setViewMore(!viewMore)}>
                                                    {(advisorProfile?.accomplishments || props?.data?.advisorProfile?.accomplishments) ? stripHTMLTags(advisorProfile?.accomplishments || props?.data?.advisorProfile?.accomplishments) : ""}
                                                </p>
                                            </span>
                                        }
                                    </div>
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div className="advisorCoursesCard">
                                    <p className="advisorCoursesCard__heading">
                                        Services offered
                                    </p>
                                </div>
                                <div className="advisorProfileCard__Services">
                                    <div className="servicelist">
                                        <StarBorderIcon color="#515050" fontSize="12px" />
                                        <p className="label">
                                            {userInfo?.firstName || props?.data?.firstName} Offers {advisorProfile?.noOfFreeConsultation || props?.data?.advisorProfile?.noOfFreeConsultation} free consultation session
                                        </p>
                                    </div>
                                    {(advisorProfile?.helpWithPlan === true || props?.data?.advisorProfile?.helpWithPlan === true) && (
                                        <div className="servicelist">
                                            <StarBorderIcon color="#515050" fontSize="12px" />
                                            <p className="label">
                                                {userInfo?.firstName || props?.data?.firstName} can create a plan with courses and activities based on your{" "} <span style={{ color: props?.userProfile?.isOnboarded === true && '#1C84EE', cursor: props?.userProfile?.isOnboarded === true ? 'pointer' : 'inherit', "marginLeft": "4px" }} onClick={handleOnClick}> goals</span>
                                            </p>
                                        </div>
                                    )}
                                    {(advisorProfile?.helpWithSpecificCourseActivity === true || props?.data?.advisorProfile?.helpWithSpecificCourseActivity === true) && (
                                        <div className="servicelist">
                                            <StarBorderIcon color="#515050" fontSize="12px" />
                                            <p className="label-pills">
                                                {userInfo?.firstName || props?.data?.firstName} can provide mentorship and support for{" "} &nbsp;<PillSection data={mappedData} />
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className="advisorCoursesCard">
                                    {(advisorProfile?.advisorCourses?.length > 0 || props?.data?.advisorProfile?.advisorCourses?.length > 0) && <p className="advisorCoursesCard__heading">Course Details</p>}
                                    {advisorProfile?.advisorCourses?.length > 0 ? (
                                        <>
                                            {advisorProfile?.advisorCourses?.map((data, key) => (
                                                <CourseCard data={data} student />
                                            ))}
                                        </>
                                    ) : (
                                        <>
                                            {props?.data?.advisorProfile?.advisorCourses?.map((data, key) => (
                                                <CourseCard data={data} student />
                                            ))}
                                        </>
                                    )}
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className="advisorCoursesCard">
                                    {(advisorProfile?.advisorActivities?.length > 0 || props?.data?.advisorProfile?.advisorActivities?.length > 0) && <p className="advisorCoursesCard__heading">Activity Details</p>}
                                    {advisorProfile?.advisorActivities?.length > 0 ? (
                                        <>
                                            {advisorProfile?.advisorActivities?.map((data, key) => (
                                                <ActivityCard data={data} student />
                                            ))}
                                        </>
                                    ) : (
                                        <>
                                            {props?.data?.advisorProfile?.advisorActivities?.map((data, key) => (
                                                <ActivityCard data={data} student />
                                            ))}
                                        </>
                                    )}
                                </div>
                            </Grid>
                            {props.detailedUserData?.advisorFeePackages?.length > 0 && (
                                <Grid item xs={12}>
                                    <div id="pkgSection" className="advisorPackageCard">
                                        <p className="advisorPackageCard__heading">Pricing Packages</p>
                                        <div className="advisorPackageCard__packCard-sec">
                                            <div className="packageAdvisor">
                                                {props.detailedUserData?.advisorFeePackages && props.detailedUserData?.advisorFeePackages?.map((data, key) => {
                                                    const filteredCourses = advisorProfile?.advisorCourses?.filter(course => data?.courses?.includes(course?.id));
                                                    const filteredAcyivity = advisorProfile?.advisorActivities?.filter(activity => data?.activities?.includes(activity?.id));
                                                    const subscriptionDetail = props?.detailedUserData?.subscriptions?.find(
                                                        (subscription) => subscription?.packageId === data?.id
                                                    );
                                                    const freeSessionAdvsiorStatus = props?.freeSessionStatus && props?.freeSessionStatus?.filter(datas => datas?.advisorId === data?.userId);
                                                    const monthstatus = data?.paymentType && data.paymentType === "monthly" ? "/Month" : "";
                                                    const dateString = subscriptionDetail?.subscribedAt;
                                                    const subscriptionCount = props?.detailedUserData?.packageSubscriptionCount?.[data.id] || 0;
                                                    // Format the date to MM/DD/YYYY
                                                    const Timezone = props?.userProfile?.timezone
                                                    const formattedDate = convertUTCTodateTimeZone(dateString, Timezone).format('MM/DD/YYYY')
                                                    const advisorActivities = filteredAcyivity || [];
                                                    const advisorCourses = filteredCourses || [];
                                                    const combinedData = [...advisorActivities, ...advisorCourses];
                                                    const mappedPackageData = combinedData.map(item => {
                                                        if (item.activityName) {
                                                            return item.activityName;
                                                        } else if (item.courseName) {
                                                            return item.courseName;
                                                        }
                                                        return null;
                                                    });
                                                    return (
                                                        <div className="Advisor_PackageCard" key={data?.id}>
                                                            <div className="Advisor_PackageCard_TopSection">
                                                                <div className="Advisor_PackageCard_TopSection_Title">
                                                                    <p className="Advisor_PackageCard_TopSection_Title_Name">{data?.packageName}</p>
                                                                </div>
                                                                <div className="Advisor_PackageCard_TopSection_subText">
                                                                    <p className="Advisor_PackageCard_TopSection_subText_Name">{subscriptionCount} {data.paymentType === "monthly" ? "Subscribed" : "Bought"}</p>
                                                                </div>
                                                            </div>
                                                            <div className="Advisor_PackageCard_MidSection">
                                                                <div className="Advisor_PackageCard_MidSection_Price">
                                                                    <p className="Advisor_PackageCard_MidSection_Price_Text">Price<span>{data?.price}${monthstatus}</span></p>
                                                                </div>
                                                                <div className="Advisor_PackageCard_MidSection_PackageList">
                                                                    <div className="Advisor_PackageCard_MidSection_PackageList_Section">
                                                                        <p className="Advisor_PackageCard_MidSection_PackageList_Section_Text">
                                                                            <span><CheckCircleOutlinedIcon sx={{ color: '#00AA11', fontSize: '16px' }} /></span>
                                                                            Includes {data?.noOfPaidSessions} Sessions of {data?.sessionLength} mins duration
                                                                        </p>
                                                                    </div>
                                                                    <div className="Advisor_PackageCard_MidSection_PackageList_Section">
                                                                        <p className="Advisor_PackageCard_MidSection_PackageList_Section_Text">
                                                                            <span><CheckCircleOutlinedIcon sx={{ color: '#00AA11', fontSize: '16px' }} /></span>
                                                                            Includes {data?.messageLimit === 0 ? "Unlimited" : data?.messageLimit} Chat Messages
                                                                        </p>
                                                                    </div>
                                                                    {data?.helpWithPlan === true &&
                                                                        <div className="Advisor_PackageCard_MidSection_PackageList_Section">
                                                                            <p className="Advisor_PackageCard_MidSection_PackageList_Section_Text">
                                                                                <span><CheckCircleOutlinedIcon sx={{ color: '#00AA11', fontSize: '16px' }} /></span>
                                                                                Suggest courses and activities based on your goals
                                                                            </p>
                                                                        </div>
                                                                    }
                                                                    {data?.helpWithSpecificCourseActivity === true && (
                                                                        <>
                                                                            <div className="Advisor_PackageCard_MidSection_PackageList_Section">
                                                                                <p className="Advisor_PackageCard_MidSection_PackageList_Section_Text">
                                                                                    <span><CheckCircleOutlinedIcon sx={{ color: '#00AA11', fontSize: '16px' }} /></span>
                                                                                    Provide mentorship and support for
                                                                                </p>
                                                                            </div>
                                                                            <div className="Advisor_PackageCard_MidSection_PackageList_Section">
                                                                                <div className="Advisor_PackageCard_MidSection_PackageList_Section_CourseContainer">
                                                                                    <PillSection data={mappedPackageData} maxWidth={240} maxHeight={80} />
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="Advisor_PackageCard_FooterSection mt-4">
                                                                <div className="Advisor_PackageCard_FooterSection_Description">
                                                                    <p className="Advisor_PackageCard_FooterSection_Description_Text" aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={(e) => handlePopoverOpen(e, data?.description !== "" ? data?.description : "")} onMouseLeave={handlePopoverClose}>{data?.description ? stripHTMLTags(data?.description) : ""}</p>
                                                                </div>
                                                                {isExploreAdvisor ? (
                                                                    <div className="Advisor_PackageCard_FooterSection_Container">
                                                                        <Button className="Advisor_PackageCard_FooterSection_Container_PurchaseBtn" disabled={true} style={{ opacity: 0.5 }}>
                                                                            Purchase
                                                                        </Button>
                                                                    </div>
                                                                ) : (
                                                                    <div className="Advisor_PackageCard_FooterSection_Container">
                                                                        {!(Network === "2" || Network === "3") ? (
                                                                            <>
                                                                           { console.log(subscriptionDetail,"subscriptionDetail")}
                                                                            
                                                                                {(freeSessionAdvsiorStatus[0]?.acceptedFreeSession >= 1 && freeSessionAdvsiorStatus[0]?.totalCount != freeSessionAdvsiorStatus[0]?.remainingCount) ? (
                                                                                    <Button className="Advisor_PackageCard_FooterSection_Container_PurchaseBtn" 
                                                                                        disabled={subscriptionDetail?.subscriptionType === "monthly" && !subscriptionDetail?.isSubscriptionActive}
                                                                                            onClick={() => ConfirmSubScribe(data)} style={{ opacity: isOnboarding ||subscriptionDetail?.subscriptionType === "monthly" || (subscriptionDetail !== undefined && subscriptionDetail?.isSubscriptionActive && !subscriptionDetail?.packageExpired) ? 0.5 : 1 }}>
                                                                                        {(subscriptionDetail !== undefined && subscriptionDetail?.isSubscriptionActive && !subscriptionDetail?.packageExpired) ? "Purchased on " + formattedDate : "Purchase"}
                                                                                    </Button>
                                                                                ) : (
                                                                                    <Button className="Advisor_PackageCard_FooterSection_Container_PurchaseBtn" disabled={true} style={{ opacity: 0.5 }}>
                                                                                        Purchase
                                                                                    </Button>
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <Button className="Advisor_PackageCard_FooterSection_Container_PurchaseBtn" disabled={true} style={{ opacity: 0.5 }}>
                                                                                Purchase    
                                                                            </Button>
                                                                        )}
                                                                        
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            {descriptions !== null &&
                                                <Popover id="mouse-over-popover" sx={{ pointerEvents: 'none', width: "60%" }} open={open} anchorEl={anchorEl} anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }} transformOrigin={{ vertical: 'top', horizontal: 'left', }} onClose={handlePopoverClose} disableRestoreFocus>
                                                    <Typography sx={{ p: 1 }} >{descriptions ? stripHTMLTags(descriptions) : ""}</Typography>
                                                </Popover>
                                            }
                                        </div>
                                    </div>
                                </Grid>
                            )}
                          {  console.log(advisorTime, "advisorTimesadsad")}
                            {(splitedTime) &&
                                <Grid item xs={12}>
                                    <>
                                        {
                                            (splitedTime) ? <>
                                                <p className="advisorPackageCard__heading">Working hours
                                                    <Tooltip arrow placement="top" title="Parents and students can schedule virtual sessions during these hours in your calendar">
                                                        <span style={{ "marginLeft": "10px" }}>
                                                            <svg width="16" height="16" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8.33333 0C3.73333 0 0 3.73333 0 8.33333C0 12.9333 3.73333 16.6667 8.33333 16.6667C12.9333 16.6667 16.6667 12.9333 16.6667 8.33333C16.6667 3.73333 12.9333 0 8.33333 0ZM8.33333 12.5C7.875 12.5 7.5 12.125 7.5 11.6667V8.33333C7.5 7.875 7.875 7.5 8.33333 7.5C8.79167 7.5 9.16667 7.875 9.16667 8.33333V11.6667C9.16667 12.125 8.79167 12.5 8.33333 12.5ZM9.16667 5.83333H7.5V4.16667H9.16667V5.83333Z" fill="black" fill-opacity="0.54" />
                                                            </svg>
                                                        </span>
                                                    </Tooltip>
                                                </p>
                                                <WorkingHours data={splitedTime} />
                                            </>
                                                : <></>
                                        }
                                    </>
                                </Grid>
                            }
                            {/* {(props?.data?.advisorProfile?.advisorWorkingHours) &&
                                <Grid item xs={12}>
                                    <>
                                        {
                                            (props?.data?.advisorProfile?.advisorWorkingHours?.mondayTimesheet?.length || props?.data?.advisorProfile?.advisorWorkingHours?.tuesdayTimesheet.length || props?.data?.advisorProfile?.advisorWorkingHours?.wednesdayTimesheet.length ||
                                                props?.data?.advisorProfile?.advisorWorkingHours?.thursdayTimesheet.length || props?.data?.advisorProfile?.advisorWorkingHours?.fridayTimesheet?.length || props?.data?.advisorProfile?.advisorWorkingHours?.saturdayTimesheet?.length ||
                                                props?.data?.advisorProfile?.advisorWorkingHours?.sundayTimesheet?.length
                                            ) ? <>
                                                <p className="advisorPackageCard__heading">Working hours
                                                    <Tooltip arrow placement="top" title="Parents and students can schedule virtual sessions during these hours in your calendar">
                                                        <span style={{ "marginLeft": "10px" }}>
                                                            <svg width="16" height="16" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8.33333 0C3.73333 0 0 3.73333 0 8.33333C0 12.9333 3.73333 16.6667 8.33333 16.6667C12.9333 16.6667 16.6667 12.9333 16.6667 8.33333C16.6667 3.73333 12.9333 0 8.33333 0ZM8.33333 12.5C7.875 12.5 7.5 12.125 7.5 11.6667V8.33333C7.5 7.875 7.875 7.5 8.33333 7.5C8.79167 7.5 9.16667 7.875 9.16667 8.33333V11.6667C9.16667 12.125 8.79167 12.5 8.33333 12.5ZM9.16667 5.83333H7.5V4.16667H9.16667V5.83333Z" fill="black" fill-opacity="0.54" />
                                                            </svg>
                                                        </span>
                                                    </Tooltip>
                                                </p>
                                                <WorkingHours data={props?.data?.advisorProfile?.advisorWorkingHours} />
                                            </>
                                                : <></>
                                        }
                                    </>
                                </Grid>
                            } */}
                            {
                                <Grid item xs={12} lg={12}>
                                    <Review />
                                </Grid>
                            }
                        </Grid>
                    </div>
                </div>
            </Modal >
        </>
    );
};

const mapStateToProps = ({ userProfileData, studentData, commonData, advisorData }) => {
    const { message, errorList, loading, universities, professions, userConnectSuccess, userConnectError, connectedAdvisors, sessionBooked, paidSessionBooked, booksessionerror, bookPaidSessionError,
        recommendedAdvisor, detailedAdvisorData, detailedUserData, detailedUserDataSuccess, reviewData, feadbackSubmit, suggestedAdvisor, cancelrequest, getRemainingFreeSession, freeSessionStatus, scheduledSessionDetails, parentRequest,isRequestRespond } = commonData
    const { userProfile, childprofile, isDisconnectUser } = userProfileData;
    const { isgetusergoals, isgetusergoalsstatus, iscreateplan, getstudentplan, iseditplanphase, parentPlanId, } = studentData;
    const { subscribeadvisor, subscribeerror } = advisorData
    return {
        message, errorList, loading, universities, professions, userConnectSuccess, isgetusergoals, connectedAdvisors,
        isgetusergoalsstatus, recommendedAdvisor, iscreateplan, getstudentplan, iseditplanphase, parentPlanId, sessionBooked, paidSessionBooked, booksessionerror, bookPaidSessionError,
        userProfile, userConnectError, detailedAdvisorData, detailedUserData, detailedUserDataSuccess, reviewData, feadbackSubmit, childprofile, cancelrequest,isRequestRespond
        , subscribeadvisor, subscribeerror, suggestedAdvisor, isDisconnectUser, getRemainingFreeSession, freeSessionStatus, scheduledSessionDetails, parentRequest
    }
};

export default connect(mapStateToProps, {
    getUserGoals, CreateParentPlan, CreatePlanStatus, getAdvisorRecommended,sendRespondToRequest, clearMessage, getConnectedAdvisors, getConnectedAdvisorsParents,
    userConnectStatus, ClearPlanId, GetStudentPlan, GetStudentPlanPhase, getAdvisorRecommendedstatus, getProfessions, resetReview, sendRespondToParentRequest,setSendRespondToRequestStatus,
    EditPlan, EditPlanStatus, GetLoadPhase, userConnect, userConnectErrorStatus, getDetailedUserProfile, disconnectUser, CancelRequest, CancelRequestStatus, getFreeSessionStatus, getAdvisorSchedule,
    settDetailedUserProfileStatus, loadAdvisorReview, disconnectUserStatus, getStudentPlanDetails, getStudentDetail, SubScribeAdvisor, SubScribeAdvisorStatus, SubScribeAdvisorErrorStatus, getFreeSessionRemaining, bookSession, bookPaidSession, bookPaidSessionStatus, bookPaidSessionErrorStatus, bookSessionStatus, respondToParentRequestStatus
})(Index);