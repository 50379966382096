import React from 'react'
import { Tooltip } from '@mui/material';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import "./activityCard.less";

const Index = ({ menu, children, activityName, activityType, planName,isArchived, semester, description, isApproved, isDropsuggested, suggestionData, isPassedSem }) => {
    const checkIfSuggestionExist = (isApproved, isDropSuggested) => {
        if (!isPassedSem) {
            if (isApproved && isDropSuggested) {
                return '-unApproved';
            }
            else if (!isApproved && !isDropSuggested) {
                return '-suggested'
            }
        } else {
            return null
        }
    }
    return (
        <div className={`myWork-activityCard${checkIfSuggestionExist(isApproved, isDropsuggested) || ''}`}>
            <div className="myWork-activityCard__topSec">
                <div className="myWork-activityCard__topSec__titleSec">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.2083 9.625C14.2083 9.625 14.2083 13.8206 14.2083 16.9598C14.2083 18.4786 12.9771 19.7083 11.4583 19.7083V19.7083C9.93956 19.7083 8.70834 18.4771 8.70834 16.9583V9.625" stroke="white" stroke-linejoin="round" />
                        <path d="M14.2083 7.79175H15.125C15.6313 7.79175 16.0417 8.20215 16.0417 8.70841V8.70841C16.0417 9.21468 15.6313 9.62508 15.125 9.62508H7.79167C7.28541 9.62508 6.875 9.21468 6.875 8.70841V8.70841C6.875 8.20215 7.28541 7.79175 7.79167 7.79175H10.5417" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M12.375 16.9583V14.2083H10.5417V16.9583C10.5417 17.4645 10.9521 17.8749 11.4583 17.8749C11.9646 17.8749 12.375 17.4645 12.375 16.9583Z" stroke="white" stroke-linejoin="round" />
                        <circle cx="14.6667" cy="3.66659" r="1.33333" stroke="white" stroke-linejoin="round" />
                        <circle cx="12.375" cy="7.79159" r="0.458333" fill="white" />
                        <circle cx="8.70834" cy="5.04175" r="0.875" stroke="white" stroke-linejoin="round" />
                    </svg>

                    <p className='myWork-activityCard__topSec__titleSec__title'>{activityName}</p>
                    <Tooltip title={planName} placement="top">
                        <LibraryBooksOutlinedIcon className='myWork-activityCard__topSec__titleSec__iconRight' />
                    </Tooltip>
                </div>
                {menu &&
                    <div className="myWork-activityCard__topSec__menuSec">
                        {menu}
                    </div>
                }
            </div>
            <div style={{ "marginTop": "5px", "marginBottom": "5px", "height": "10px" }}>
                {isArchived &&
                    <div className="myWork-planCard__midtop">
                        <div className='myWork-planCard__midtop__pill'>Archived</div>
                    </div>
                }
            </div>
            {isPassedSem &&
                <div className="myWork-activityCard__statusSec">
                    <div className="myWork-activityCard__statusSec__status">
                        <p className="myWork-activityCard__statusSec__status__successText">Completed</p>
                    </div>
                </div>
            }
            <div className="myWork-activityCard__midSec">
                <div className="myWork-activityCard__midSec__content">
                    <p className="myWork-activityCard__midSec__content__label">Activity Type</p>
                    <p className="myWork-activityCard__midSec__content__value">{activityType}</p>
                </div>
                <div className="myWork-activityCard__midSec__content">
                    <p className="myWork-activityCard__midSec__content__label">Semester</p>
                    <p className="myWork-activityCard__midSec__content__value">{semester}</p>
                </div>
                <p className="myWork-activityCard__midSec__value">{description}</p>
            </div>
            <div className="myWork-courseCard__suggestionSec" style={{ "marginTop": "auto", "marginBottom": "0px" }}>
                {suggestionData}
            </div>
            <div className="myWork-activityCard__ctaSec">
                {children}
            </div>
        </div>
    )
}

export default Index