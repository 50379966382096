import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Select, { components } from "react-select";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { connect } from "react-redux";
import { clearMessage, getChildProfile, bookSession, bookPaidSession, bookSessionStatus, GetStudentPlan, GetStudentPlanStatus, getAdvisorCountUpdateStatus, bookPaidSessionErrorStatus, getUserCount, bookPaidSessionStatus, getGoalsFromStudent, setIsParentGoalSuccess, inviteUserAdvisor, inviteUserStudent, inviteUserAdvisorStatus, inviteUserStudentStatus, inviteStudentErrorStatus } from '../../../redux/actions'
import CustomAlert from '../../../components/CustomAlert'

import { Button, Form, Modal } from 'react-bootstrap';



const schema = yup.object().shape({
    email: yup.string().email('Invalid email format').required('Email is required'),
});

const Index = (props) => {

    useEffect(() => {
        if (props.userInviteAdvisor) {
            reset();
            props?.handleShow();
            props?.inviteUserAdvisorStatus(false)
        }
        if (props?.userInviteStudent) {
            reset();
            props?.handleShow();
            props?.inviteUserStudentStatus(false)
        }
              if (props?.inviteStudentError){
            props?.inviteStudentErrorStatus(false)
        }
    }, [props.userInviteAdvisor, props?.userInviteStudent])


    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = (data) => {
        console.log('Form data:', data);
        let payload = {
            "emails": [
                data?.email
            ]
        }
        if (props?.type === "advisor") {
            props?.inviteUserAdvisor(payload)
        } else {
            props?.inviteUserStudent(payload)
        }

    };

    return (
        <div>
            <Modal show={props?.openAdvisorInvitePopUp} onHide={props?.handleShow}
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Invite
                    </Modal.Title>

                </Modal.Header>
                <Modal.Body style={{ padding: 40 }}>
                    <div className="" style={{ "width": "100%", "display": "flex", "justifyContent": "center", }}> {<CustomAlert />}</div>

                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row m-0">
                            <div className="col-md-12 col-sm-12 col-12 ps-0 p-mob-0">
                                <div className="input-floating-label">
                                    <input
                                        {...register('email')}
                                        type="text"
                                        className={`textbox--primary textbox--rounded input ${errors.email ? 'is-invalid' : ''}`}
                                        name="email"
                                        placeholder=" "
                                    />
                                    <label>Email</label>
                                    {errors.email && (
                                        <div className="error-text" style={{ position: "absolute" }}>
                                            {errors.email.message}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row m-0">
                            <div className="col-md-12 col-sm-12 col-12 ps-0 p-mob-0">
                                <div className="text-center" style={{ gap: 10 }}>
                                    <Button className="btn cta--rounded cta-primary mb-4 w-100" type="submit" style={{ textTransform: "none", borderRadius: "10px" }}>
                                        <span>Send Invite</span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
}
const mapStateToProps = ({ commonData, userProfileData, }) => {
    const { childprofile, userProfile } = userProfileData;
    const { message, errorList, connectedAdvisors, paidSessionBooked, bookPaidSessionError, sessionBooked, booksessionerror, totalUserCount, eventSwitchList, exploreAdvisorCount, currentDashboardCourses, currentDashboardActivity, userInviteAdvisor, userInviteStudent, inviteStudentError } = commonData;
    return { message, errorList, connectedAdvisors, userProfile, paidSessionBooked, bookPaidSessionError, sessionBooked, booksessionerror, totalUserCount, childprofile, eventSwitchList, exploreAdvisorCount, currentDashboardCourses, currentDashboardActivity, userInviteAdvisor, userInviteStudent, inviteStudentError };
};
export default connect(mapStateToProps, { clearMessage, bookPaidSessionErrorStatus, GetStudentPlan, getChildProfile, GetStudentPlanStatus, getAdvisorCountUpdateStatus, getUserCount, bookSession, bookPaidSession, bookSessionStatus, bookPaidSessionStatus, getGoalsFromStudent, setIsParentGoalSuccess, inviteUserAdvisor, inviteUserStudent, inviteUserAdvisorStatus, inviteUserStudentStatus, inviteStudentErrorStatus })(Index);
