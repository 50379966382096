import React, { useEffect, useState } from "react";
import "../../../assets/css/New_Profile.less"
import { Tabs, Grid } from '@mui/material';
import Tab from '@mui/material/Tab';
import PersonIcon from '@mui/icons-material/Person';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import General from "./General";
import Goals from "./Goals/GoalsClass";
import Billing from "./Billing";
import ConnectedAccount from "./ConnectedAccount"
import { AddBillingCard } from "../../Parent/ParentProfile/BillingCard"
import { connect } from "react-redux";
import CustomAlert from "../../../components/CustomAlert";
import Select, { components } from "react-select";
import { useLocation } from "react-router-dom";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div className="Advisor-MyProfile__mainCnt" role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (<Box><Typography>{children}</Typography></Box>)}
        </div>
    );
}
function a11yProps(index) { return { id: `simple-tab-${index}`, "aria-controls": `simple-tabpanel-${index}`, }; }

const Index = (props) => {
    const [tab, setTab] = useState(0);
    let {state} = useLocation();
    console.log(state,"statestate");
    
    const changeTab = (event, newValue) => {
        setTab(newValue);
    };

    useEffect(()=>{
        if (state?.type == "goals") {
            setTab(1);
        }
    },[])

    const Menulists = [
        {
            value: 0,
            icon: <PersonIcon />,
            label: "General"
        },
        {
            value: 1,
            icon: <SchoolOutlinedIcon />,
            label: "Goals"
        },
        {
            value: 2,
            icon: <LocalAtmIcon />,
            label: "Billing Info"
        },
        {
            value: 3,
            icon: <GroupOutlinedIcon />,
            label: "Connected accounts"
        }
    ]
    const handleSelectChange = (e) => {
        setTab(e.value);
    }
    const { ValueContainer, Placeholder } = components;
    const CustomValueContainer = ({ children, ...props }) => {
        return (
            <ValueContainer {...props}>
                <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder}</Placeholder>
                {React.Children.map(children, child => child && child.type !== Placeholder ? child : null)}
            </ValueContainer>
        );
    };
    return (
        <>
            {/* {props?.errorList === undefined  || props?.errorList === {} || !props?.errorList || Object.keys(props?.errorList)?.length === 0 && */}
            {!props?.errorList &&
                <div style={{ "width": "100%", "display": "flex", "justifyContent": "center", }}> {<CustomAlert />}</div>
            }
            {props?.errorList && Object.keys(props?.errorList)?.length === 0 &&
                <div style={{ "width": "100%", "display": "flex", "justifyContent": "center", }}> {<CustomAlert />}</div>
            }
            <div className="Student_Profile">
                <div className="Student_Profile_Sec">
                    <p className="Profile_Title">
                        <span style={{ "marginRight": "10px" }}>
                            <svg width="34" height="34" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.6572 3.32638C12.7646 3.27639 12.8816 3.25049 13 3.25049C13.1185 3.25049 13.2355 3.27639 13.3429 3.32638L25.5304 9.01388C25.6739 9.08087 25.7947 9.18834 25.878 9.32308C25.9612 9.45782 26.0033 9.61395 25.999 9.77227C25.9946 9.93059 25.9442 10.0842 25.8537 10.2142C25.7632 10.3442 25.6367 10.4449 25.4898 10.504L13.3023 15.379C13.1083 15.4568 12.8918 15.4568 12.6978 15.379L3.25004 11.6025V21.125C3.68102 21.125 4.09435 21.2962 4.39909 21.601C4.70384 21.9057 4.87504 22.319 4.87504 22.75V26H4.3869e-05V22.75C4.3869e-05 22.319 0.171249 21.9057 0.475996 21.601C0.780743 21.2962 1.19407 21.125 1.62504 21.125V10.9509L0.510294 10.504C0.363342 10.4449 0.236855 10.3442 0.146393 10.2142C0.0559311 10.0842 0.00543785 9.93059 0.00112152 9.77227C-0.00319481 9.61395 0.0388565 9.45782 0.122101 9.32308C0.205345 9.18834 0.326153 9.08087 0.469667 9.01388L12.6572 3.32638ZM13 13.7475L23.1433 9.69313L13 4.9595L2.85679 9.69313L13 13.7475Z" fill="#5C5C5C" />
                                <path d="M19.214 14.677C19.3186 14.6376 19.4302 14.6202 19.5419 14.6259C19.6536 14.6316 19.7628 14.6603 19.8629 14.7102C19.963 14.76 20.0516 14.83 20.1234 14.9157C20.1952 15.0015 20.2485 15.1011 20.28 15.2084L21.0925 17.9709C21.1494 18.1648 21.132 18.373 21.0436 18.5548C20.9553 18.7365 20.8024 18.8789 20.6147 18.954L13.3022 21.879C13.1082 21.9567 12.8917 21.9567 12.6977 21.879L5.38524 18.954C5.19759 18.8789 5.04467 18.7365 4.95633 18.5548C4.86799 18.373 4.85058 18.1648 4.90749 17.9709L5.71999 15.2084C5.7515 15.1011 5.8048 15.0015 5.87657 14.9157C5.94834 14.83 6.03703 14.76 6.13709 14.7102C6.23715 14.6603 6.34642 14.6316 6.45808 14.6259C6.56973 14.6202 6.68137 14.6376 6.78599 14.677L13 17.0072L19.214 14.677ZM19.3245 17.7206L18.967 16.5051L13.286 18.6355C13.1016 18.7048 12.8984 18.7048 12.714 18.6355L7.03299 16.5051L6.67549 17.7206L13 20.2475L19.3245 17.719V17.7206Z" fill="#5C5C5C" />
                            </svg>
                        </span>
                        Your student profile
                    </p>
                </div>
                <div className="Student_Profile_Sec_Mobile">
                    <p className="Profile_Title">
                        <span>
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.6572 3.32638C12.7646 3.27639 12.8816 3.25049 13 3.25049C13.1185 3.25049 13.2355 3.27639 13.3429 3.32638L25.5304 9.01388C25.6739 9.08087 25.7947 9.18834 25.878 9.32308C25.9612 9.45782 26.0033 9.61395 25.999 9.77227C25.9946 9.93059 25.9442 10.0842 25.8537 10.2142C25.7632 10.3442 25.6367 10.4449 25.4898 10.504L13.3023 15.379C13.1083 15.4568 12.8918 15.4568 12.6978 15.379L3.25004 11.6025V21.125C3.68102 21.125 4.09435 21.2962 4.39909 21.601C4.70384 21.9057 4.87504 22.319 4.87504 22.75V26H4.3869e-05V22.75C4.3869e-05 22.319 0.171249 21.9057 0.475996 21.601C0.780743 21.2962 1.19407 21.125 1.62504 21.125V10.9509L0.510294 10.504C0.363342 10.4449 0.236855 10.3442 0.146393 10.2142C0.0559311 10.0842 0.00543785 9.93059 0.00112152 9.77227C-0.00319481 9.61395 0.0388565 9.45782 0.122101 9.32308C0.205345 9.18834 0.326153 9.08087 0.469667 9.01388L12.6572 3.32638ZM13 13.7475L23.1433 9.69313L13 4.9595L2.85679 9.69313L13 13.7475Z" fill="#5C5C5C" />
                                <path d="M19.214 14.677C19.3186 14.6376 19.4302 14.6202 19.5419 14.6259C19.6536 14.6316 19.7628 14.6603 19.8629 14.7102C19.963 14.76 20.0516 14.83 20.1234 14.9157C20.1952 15.0015 20.2485 15.1011 20.28 15.2084L21.0925 17.9709C21.1494 18.1648 21.132 18.373 21.0436 18.5548C20.9553 18.7365 20.8024 18.8789 20.6147 18.954L13.3022 21.879C13.1082 21.9567 12.8917 21.9567 12.6977 21.879L5.38524 18.954C5.19759 18.8789 5.04467 18.7365 4.95633 18.5548C4.86799 18.373 4.85058 18.1648 4.90749 17.9709L5.71999 15.2084C5.7515 15.1011 5.8048 15.0015 5.87657 14.9157C5.94834 14.83 6.03703 14.76 6.13709 14.7102C6.23715 14.6603 6.34642 14.6316 6.45808 14.6259C6.56973 14.6202 6.68137 14.6376 6.78599 14.677L13 17.0072L19.214 14.677ZM19.3245 17.7206L18.967 16.5051L13.286 18.6355C13.1016 18.7048 12.8984 18.7048 12.714 18.6355L7.03299 16.5051L6.67549 17.7206L13 20.2475L19.3245 17.719V17.7206Z" fill="#5C5C5C" />
                            </svg>
                        </span>
                        Your student profile
                    </p>
                </div>
                <div className="Student_Profile_Mid_Section">
                    <div className="Student_Profile_Mid_Section_Top">
                        <div className="Student_Profile_Mid_Section_Top_Mobile">
                            <div className="input-floating-label">
                                <Select options={Menulists} onChange={handleSelectChange}
                                    defaultValue={Menulists[0]} value={Menulists[tab]} isSearchable={false}
                                    getOptionLabel={e => (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {e.icon}<span style={{ marginLeft: 10 }}>{e.label}</span>
                                        </div>
                                    )}
                                    placeholder=" " components={{ ValueContainer: CustomValueContainer, IndicatorSeparator: () => null }}
                                    styles={{ option: (provided, state) => ({ ...provided, cursor: 'pointer', }), container: (provided, state) => ({ ...provided, height: '48px', overflow: "visible" }), valueContainer: (provided, state) => ({ ...provided, overflow: "visible", height: '100%', minHeight: '48px' }), placeholder: (provided, state) => ({ ...provided, position: "absolute", top: state.hasValue || state.selectProps.inputValue ? -13 : "30%", fontSize: (state.hasValue || state.selectProps.inputValue) && 13, background: '#fff', paddingLeft: 10, paddingRight: 10, display: state.isFocused || state.isSelected || state.selectProps.inputValue ? 'none' : 'block', }) }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11">
                                <div className="tab_card">
                                    <Tabs value={tab} onChange={changeTab} variant="scrollable" scrollButtons allowScrollButtonsMobile aria-label="scrollable force tabs example" id="scrollabletab">
                                        <Tab disableRipple icon={<PersonIcon />} iconPosition="start" label="Personal Settings" className="Tab" {...a11yProps(0)} id="tabs1"/>
                                        <Tab disableRipple icon={
                                            <svg className="tabicon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M21 6L15.7071 11.2929C15.3166 11.6834 14.6834 11.6834 14.2929 11.2929L12.7071 9.70711C12.3166 9.31658 11.6834 9.31658 11.2929 9.70711L7 14" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M3 3V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21H21" strokeWidth="2" stroke-linecap="round" />
                                            </svg>
                                        } iconPosition="start" label="Your Goals" className="Tab" {...a11yProps(1)} id="tabs2" />
                                        <Tab disableRipple icon={<LocalAtmIcon />} iconPosition="start" label="Billing info" className="Tab" {...a11yProps(2)} id="tabs3"/>
                                        <Tab disableRipple icon={
                                            <svg width="25" height="25" className="tabicon2" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_4203_7030)">
                                                    <path d="M10.2917 13.2917C12.3021 13.2917 13.9375 11.6563 13.9375 9.64583C13.9375 7.63542 12.3021 6 10.2917 6C8.28125 6 6.64583 7.63542 6.64583 9.64583C6.64583 11.6563 8.28125 13.2917 10.2917 13.2917ZM10.2917 8.08333C11.1562 8.08333 11.8542 8.78125 11.8542 9.64583C11.8542 10.5104 11.1562 11.2083 10.2917 11.2083C9.42708 11.2083 8.72917 10.5104 8.72917 9.64583C8.72917 8.78125 9.42708 8.08333 10.2917 8.08333ZM10.3438 18.5H5.88542C6.91667 17.9792 8.69792 17.4583 10.2917 17.4583C10.4062 17.4583 10.5312 17.4688 10.6458 17.4688C11 16.7083 11.6146 16.0833 12.3542 15.5833C11.5937 15.4479 10.875 15.375 10.2917 15.375C7.85417 15.375 3 16.5938 3 19.0208V20.5833H10.2917V19.0208C10.2917 18.8438 10.3125 18.6667 10.3438 18.5ZM18.1042 15.8958C16.1875 15.8958 12.375 16.9479 12.375 19.0208V20.5833H23.8333V19.0208C23.8333 16.9479 20.0208 15.8958 18.1042 15.8958ZM19.3646 14C20.1562 13.5521 20.7083 12.7083 20.7083 11.7292C20.7083 10.2917 19.5417 9.125 18.1042 9.125C16.6667 9.125 15.5 10.2917 15.5 11.7292C15.5 12.7083 16.0521 13.5521 16.8437 14C17.2187 14.2083 17.6458 14.3333 18.1042 14.3333C18.5625 14.3333 18.9896 14.2083 19.3646 14Z" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_4203_7030">
                                                        <rect width="25" height="25" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        } iconPosition="start" label="Your Parents" className="Tab" {...a11yProps(3)} id="tabs4" />
                                    </Tabs>
                                </div>
                            </div>
                        </div>

                        <TabPanel value={tab} index={0}>
                            <General />
                        </TabPanel>
                        <TabPanel value={tab} index={1}>
                            <div className="GoalsSection">
                                <Goals />
                            </div>
                        </TabPanel>
                        <TabPanel value={tab} index={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div className="BillingSection">
                                        <Billing />
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="commanCardCnt">
                                        <AddBillingCard />
                                    </div>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={tab} index={3}>
                            <ConnectedAccount />
                        </TabPanel>
                    </div>

                </div>
            </div>
        </>
    );
}
const mapStateToProps = ({ commonData, userProfileData, studentData }) => {
    const { message, errorList, universities, professions, status, gender, country, loading, schools, graduationyear } = commonData;
    const { userProfile } = userProfileData;
    return { message, errorList, universities, professions, userProfile, status, gender, country, loading, schools, graduationyear };
};
export default connect(mapStateToProps, {})(Index);